import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";


function NotesDialog(props) {

    const { t } = useTranslation();


    const [formValue, setFormValue] = useState({ 'note': props.note });

    const onChange = (e) => {
        const { name, value } = e.target;

        setFormValue((formValue) => ({
            ...formValue,
            [name]: value,
        }));
    };

    return (
        <BasicDialog
            open={props.open}
            onClose={() => {
                props.onClose()
            }}
            titleAlign="center"
            contentAlign="center"
            title={props.title? props.title : t("dialog.notes_dialog.note")}
            maxWidth="sm"
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
            >
                {props.showFilename &&
                    <Grid item xs={12}>
                        <TextFieldFormControl
                            label={t("dialog.notes_dialog.filename")}
                            value={props.filename}
                            disabled
                        />
                    </Grid>
                }
        
                    <>
                        <Grid item xs={12}>
                            <TextFieldFormControl
                                name={"note"}
                                label={t("dialog.notes_dialog.note")}
                                value={formValue.note}
                                multiline={true}
                                rows={4}
                                disabled={props.readOnly}
                                onChange={(e) => onChange(e)}
                            />
                        </Grid>

                        {!props.readOnly &&
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => props.onSubmit(formValue)}
                                >
                                    {t("save")}
                                </Button>
                            </Grid>}
                    </>
                
            </Grid>
        </BasicDialog>
    )

}

NotesDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    note: PropTypes.string,
    showFilename: PropTypes.bool,
    filename: PropTypes.string,
    prefix: PropTypes.string,
    readOnly: PropTypes.bool,
    title: PropTypes.string,
};
NotesDialog.defaultProps = {
    open: false,
    note: "",
    showFilename: false,
    filename: "",

};

export default NotesDialog