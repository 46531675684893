import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

import "./index.css";

import "./i18n";

import { PublicClientApplication } from "@azure/msal-browser";

import { msalConfig } from "./msalConfig";

import moment from 'moment';

moment.locale('pl'); 

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App msalInstance={msalInstance} />
  </React.StrictMode>
);
