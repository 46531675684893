import { useState, useEffect, useRef } from "react";

import { useSearchParams } from "react-router-dom";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import BasicDialog from "../../base/BasicDialog";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useAsync } from "../../../hooks/useAsync";

import TicketCategoryTable from "../../table/TicketCategoryTable";

import TicketCategoryDialog from "../TicketCategoryDialog/TicketCategoryDialog";
import useDialogWithId from "../../../hooks/useDialogWithId";

import useTicketService from "../../../services/ticketService";

import { isSearchParamsObjectEmpty } from "../../../helpers/methods";

import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import {
  centerVericalAlignIconStyle,
  overflowButtonStyle,
} from "../../../helpers/styles";

import {
  DIALOG_CREATE_MODE,
  DIALOG_EDIT_MODE,
} from "../../../helpers/constants";

import {
  TICKET_CATEGORY_TABLE_CONFIG,
  TICKET_CATEGORY_TABLE_PREFIX,
} from "./TableConfig";

import useFilterSearchParams from "../../../hooks/useFilterSearchParams";

const TicketCategoryManagementDialog = (props) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefix,
  } = useFilterSearchParams(searchParams);

  const [ticketCategorySearchParams, setTicketCategorySearchParams] = useState(
    getSearchParamsByFilterPrefix(TICKET_CATEGORY_TABLE_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      TICKET_CATEGORY_TABLE_PREFIX,
      ticketCategorySearchParams,
      setTicketCategorySearchParams
    );
  }, [searchParams, TICKET_CATEGORY_TABLE_PREFIX]);

  const { getTicketCategoryAdminData, getTicketCategoryFilteringData } =
    useTicketService();

  const ticketCategoryFilteringData = useAsync(getTicketCategoryFilteringData);
  const ticketCategoryData = useAsync(
    () => getTicketCategoryAdminData(ticketCategorySearchParams),
    [ticketCategorySearchParams]
  );
  const [ticketCategoryDataLocaly, setTicketCategoryDataLocaly] = useState();

  useEffect(() => {
    if (ticketCategoryData.loading) {
      return;
    }
    setTicketCategoryDataLocaly(ticketCategoryData.value);
  }, [ticketCategoryData.loading]);

  const [existsChanges, setExistsChanges] = useState(false);
  const [
    openTicketCategoryDialog,
    ticketCategoryId,
    onOpenTicketCategoryDialog,
    onCloseTicketCategoryDialog,
  ] = useDialogWithId();

  const ticketCategoryDialogModeRef = useRef();
  const handleOpenTicketCategoryDialogInCreateMode = () => {
    ticketCategoryDialogModeRef.current = DIALOG_CREATE_MODE;
    onOpenTicketCategoryDialog(undefined);
  };

  const handleOpenTicketCategoryDialogInEditMode = (ticketCategoryId) => {
    ticketCategoryDialogModeRef.current = DIALOG_EDIT_MODE;
    onOpenTicketCategoryDialog(ticketCategoryId);
  };

  const getTicketCategoryDialogCallback = () => {
    setTicketCategoryDataLocaly(undefined);

    if (
      ticketCategoryDialogModeRef.current === DIALOG_CREATE_MODE &&
      !isSearchParamsObjectEmpty(ticketCategorySearchParams)
    ) {
      setSearchParams(
        clearSearchParamsByFilterPrefix(TICKET_CATEGORY_TABLE_PREFIX)
      );
    } else {
      ticketCategoryData.refetch();
    }

    setExistsChanges(true);
  };

  const isLoading =
    ticketCategoryDataLocaly === undefined ||
    ticketCategoryFilteringData.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid container spacing={1} justifyContent={"flex-end"}>
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            startIcon={
              <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
            }
            onClick={handleOpenTicketCategoryDialogInCreateMode}
            sx={overflowButtonStyle}
          >
            {t("dialog.ticket_category.add_category")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TicketCategoryTable
            showFilters={true}
            data={ticketCategoryDataLocaly}
            tableConfig={TICKET_CATEGORY_TABLE_CONFIG}
            hiddenColumns={[]}
            filteringData={ticketCategoryFilteringData}
            filterPrefix={TICKET_CATEGORY_TABLE_PREFIX}
            initialExpandedFilterHeader={
              !isSearchParamsObjectEmpty(ticketCategorySearchParams)
            }
            // style={{ maxHeight: "70vh" }}
            showCleanFilterIcon={true}
            showExportToFileButton={false}
            withPagination={false}
            onClickEdit={handleOpenTicketCategoryDialogInEditMode}
          />
        </Grid>
        {openTicketCategoryDialog && (
          <TicketCategoryDialog
            open={openTicketCategoryDialog}
            onClose={onCloseTicketCategoryDialog}
            dialogMode={ticketCategoryDialogModeRef.current}
            onSubmitCallback={getTicketCategoryDialogCallback}
            ticketCategoryId={ticketCategoryId}
          />
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => props.onClose(existsChanges)}
      titleAlign="center"
      maxWidth={"md"}
      title={t("dialog.ticket_category.category_management")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

TicketCategoryManagementDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

TicketCategoryManagementDialog.defaultProps = {};

export default TicketCategoryManagementDialog;
