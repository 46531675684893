import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import { useState } from "react";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function BasicDialog(props) {
  const { t } = useTranslation();
  const getLabelCloseButton = () => {
    if (props.closeButtonLabel) {
      return props.closeButtonLabel;
    }
    return t("close");
  };

  const [fullScreen, setFullScreen] = useState(props.allwaysFullScreen);
  const toggleFullScreen = () => {
    setFullScreen((prev) => !prev);
    if (props.onToggleFullScreen) {
      props.onToggleFullScreen(fullScreen);
    }
  };

  const handleScrollDialogContent = (e) => {
    if (props.onScrollDialogContent) {
      props.onScrollDialogContent(e);
    }
  };

  return (
    <Dialog
      fullWidth
      open={props.open}
      maxWidth={props.maxWidth}
      sx={{
        opacity: props.hide ? 0 : 1,
        display: props.open ? undefined : "none",
        backgroundColor: props.backgroundColor,
        ...props.dialogSx,
      }}
      PaperComponent={
        props.allwaysFullScreen || props.diableDraggable ? null : PaperComponent
      }
      fullScreen={fullScreen}
      slotProps={{
        backdrop: {
          style: { backgroundColor: props.backdropColor },
        },
      }}
    >
      {props.showTitle && (
        <DialogTitle
          align={props.titleAlign}
          id="draggable-dialog-title"
          style={{
            cursor: "move",
            color: "var(--primary)",
            fontSize: props.titleFontSize,
          }}
          sx={{
            "& .MuiDialogTitle-root": {
              color: "var(--primary)",
            },
            ...props.titleSx,
          }}
        >
          {props.title.charAt(0).toUpperCase() + props.title.slice(1)}
          {props.showTopActionButton && (
            <Grid
              item
              xs={12}
              style={{ padding: "10px" }}
              alignItems="flex-start"
            >
              {props.topActionButton}
            </Grid>
          )}
        </DialogTitle>
      )}
      {props.showTopCloseButton && (
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {props.showTopFullScreenButton && !props.allwaysFullScreen && (
        <IconButton
          aria-label="full_screen"
          onClick={toggleFullScreen}
          sx={{
            position: "absolute",
            right: 48,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {fullScreen ? (
            <FullscreenExitOutlinedIcon />
          ) : (
            <FullscreenOutlinedIcon />
          )}
        </IconButton>
      )}

      <DialogContent
        dividers={props.dividers}
        align={props.contentAlign}
        sx={{ ...props.contentSx }}
        onScroll={handleScrollDialogContent}
      >
        {props.children}
      </DialogContent>

      {props.showBottomActionButton && (
        <Grid
          container
          style={props.bottomActionStyle}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          {props.bottomActionButton}
        </Grid>
      )}
      {!props.showCustomFooter && props.showDialogActions && (
        <DialogActions style={{ cursor: "move" }} id="draggable-dialog-title">
          {props.showBottomCloseButton && (
            <Button
              variant="contained"
              size="small"
              onClick={props.onClose}
              sx={props.closeButtonSx}
              disabled={props.disableOnCloseButton}
            >
              {getLabelCloseButton()}
            </Button>
          )}
        </DialogActions>
      )}
      {props.showCustomFooter && (
        <DialogActions
          sx={{ alignItems: "flex-end" }}
          id="draggable-dialog-title"
          style={{ cursor: "move" }}
        >
          {props.customFooter}
          <Button
            variant="contained"
            size="small"
            onClick={props.onClose}
            sx={{ vertialAlign: "bottom" }}
          >
            {getLabelCloseButton()}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

BasicDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  titleFontSize: PropTypes.string,
  titleSx: PropTypes.object,
  content: PropTypes.any,
  onClose: PropTypes.func,
  titleAlign: PropTypes.oneOf(["right", "left", "center"]),
  closeButtonLabel: PropTypes.string,
  contentAlign: PropTypes.oneOf(["right", "left", "center"]),
  dividers: PropTypes.bool,
  showTitle: PropTypes.bool,
  maxWidth: PropTypes.string,
  allwaysFullScreen: PropTypes.bool,
  showDialogActions: PropTypes.bool,
  showCustomFooter: PropTypes.bool,
  customFooter: PropTypes.element,
  disableOnCloseButton: PropTypes.bool,
  closeButtonSx: PropTypes.object,
  showTopActionButton: PropTypes.bool,
  topActionButton: PropTypes.object,
  showBottomActionButton: PropTypes.bool,
  bottomActionButton: PropTypes.object,
  dialogSx: PropTypes.object,
  showTopCloseButton: PropTypes.bool,
  showTopFullScreenButton: PropTypes.bool,
  showBottomCloseButton: PropTypes.bool,
  onToggleFullScreen: PropTypes.bool,
  hide: PropTypes.bool,
  onScrollDialogContent: PropTypes.func,
  diableDraggable: PropTypes.bool,
};
BasicDialog.defaultProps = {
  open: false,
  titleAlign: "left",
  titleFontSize: "24px",
  titleSx: {},
  contentAlign: "center",
  dividers: true,
  showTitle: true,
  maxWidth: "lg",
  fullScreen: false,
  showDialogActions: true,
  customFooter: null,
  showTopActionButton: false,
  showBottomActionButton: false,
  dialogSx: {},
  showTopCloseButton: true,
  showTopFullScreenButton: true,
  showBottomCloseButton: false,
  hide: false,
  diableDraggable: false,
};
