import { useState, useEffect, useMemo } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync } from "../../../hooks/useAsync";

import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../hooks/useFilterSearchParams";

import useUserService from "../../../services/userService";

import TenantCreateUpdateDialog from "../../dialog/TenantCreateUpdateDialog";
import useDialogWithId from "../../../hooks/useDialogWithId";

import UniversalToolBarWithDialogs from "../../bar/UniversalToolBarWithDialogs";

import TableService from "../../../services/tableService";
import TenantTable from "../../table/TenantTable/TenantTable";
import { exportTenantRRDetailsToFileUrl } from "../../../helpers/apiUrls";
import { isSearchParamsObjectEmpty } from "../../../helpers/methods";
import {
  overflowButtonStyle,
  centerVericalAlignIconStyle,
} from "../../../helpers/styles";

import {
  TENANT_TABLE_NAME,
  TENANT_TABLE_CONFIG,
  TENANT_TABLE_FILTER_PREFIX,
} from "./TableConfig";

const TenantTableWithToolBar = (props) => {
  const { t } = useTranslation();

  const tableConfigLocaly = useMemo(() => {
    const tableConfigTemp = [...TENANT_TABLE_CONFIG];
    if (props.readOnly)
      return tableConfigTemp.filter((column) => column.name !== "edit");
    return tableConfigTemp;
  }, [props.readOnly]);

  const tableConfigForUserPreference = {
    name: TENANT_TABLE_NAME,
    config: tableConfigLocaly,
  };

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(
    TENANT_TABLE_FILTER_PREFIX,
    undefined,
    props.defaultPageSize
  );

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefix,
  } = useFilterSearchParams(searchParams);
  const [tenantsSearchParams, setTenantsSearchParams] = useState(
    getSearchParamsByFilterPrefix(TENANT_TABLE_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      TENANT_TABLE_FILTER_PREFIX,
      tenantsSearchParams,
      setTenantsSearchParams
    );
  }, [searchParams]);

  const { getTenantRRDetailsList, getTenantFilteringData } = useUserService();

  const tenantData = useAsync(
    () =>
      getTenantRRDetailsList({
        ...tenantsSearchParams,
        ...props.tenantDataSearchParams,
        page_size: tenantsSearchParams.page_size || props.defaultPageSize,
      }),
    [tenantsSearchParams]
  );
  const tenantFilteringData = useAsync(getTenantFilteringData);

  const [tenantDataLocaly, setTenantDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (tenantData.loading) {
      return;
    }
    setTenantDataLocaly(tenantData.value.results);
    setCountRecords(tenantData.value.count);
  }, [tenantData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setTenantDataLocaly(undefined);
    tenantData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setTenantDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const tenantsExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      tableConfigLocaly,
      hiddenColumnsForTables?.[TENANT_TABLE_NAME],
      columnsOrdersForTables?.[TENANT_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[TENANT_TABLE_NAME],
    columnsOrdersForTables?.[TENANT_TABLE_NAME],
    tableConfigLocaly,
  ]);

  const [
    openEditTenantDialog,
    tenantId,
    onOpenEditTenantDialog,
    onCloseEditTenantDialog,
  ] = useDialogWithId();

  const isTenantDialogInCreateMode = tenantId === null;
  const getEditTenantDialogCallback = () => {
    if (
      isTenantDialogInCreateMode &&
      !isSearchParamsObjectEmpty(tenantsSearchParams)
    ) {
      setSearchParams(
        clearSearchParamsByFilterPrefix(TENANT_TABLE_FILTER_PREFIX)
      );
    } else {
      tenantData.refetch();
    }
  };

  const isLoading =
    tenantDataLocaly === undefined || tenantFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <UniversalToolBarWithDialogs
          pageName={props.pageName}
          extraButtonList={
            props.showAddTenantButton && props.hasPermissionToEditTeams
              ? [
                  <Button
                    color="success"
                    onClick={() => onOpenEditTenantDialog(null)}
                    variant="contained"
                    size="small"
                    startIcon={
                      <ControlPointOutlinedIcon
                        sx={centerVericalAlignIconStyle}
                      />
                    }
                    sx={overflowButtonStyle}
                    fullWidth
                  >
                    {t("tabs.rr_admin.add_tenant")}
                  </Button>,
                ]
              : undefined
          }
          showOpenCloseFilterButton={false}
          tableConfig={tableConfigForUserPreference}
          filteringData={tenantFilteringData}
          refreshTable={refreshTable}
          onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
          resetPageNumber={resetPageNumber}
          hiddenColumnsForTables={hiddenColumnsForTables}
          setHiddenColumnsForTables={setHiddenColumnsForTables}
          columnsOrdersForTables={columnsOrdersForTables}
          setColumnsOrdersForTables={setColumnsOrdersForTables}
          filterPrefix={TENANT_TABLE_FILTER_PREFIX}
        />
      </Grid>
      <Grid item xs={12}>
        {hiddenColumnsForTables && (
          <TenantTable
            showCleanFilterIcon={true}
            style={{ maxHeight: "60vh" }}
            showFilters={true}
            data={tenantDataLocaly}
            filteringData={tenantFilteringData}
            filterPrefix={TENANT_TABLE_FILTER_PREFIX}
            countRecords={countRecords}
            page={page}
            onPageChange={handleChangePageWithSearchParams}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onClickEdit={onOpenEditTenantDialog}
            resetPageNumber={resetPageNumber}
            tableConfig={tableConfigLocaly}
            hiddenColumns={hiddenColumnsForTables[TENANT_TABLE_NAME]}
            columnsOrders={columnsOrdersForTables[TENANT_TABLE_NAME]}
            showExportToFileButton={true}
            exportToFileUrl={exportTenantRRDetailsToFileUrl}
            exportToFileSearchParams={{
              ...tenantsSearchParams,
              ...props.tenantDataSearchParams,
            }}
            exportToFileHeaders={tenantsExportHeaders}
            exportToFileFileName={`${t("tabs.rr_admin.tenants")}.xlsx`}
            initialExpandedFilterHeader={props.initialExpandedFilterHeader}
          />
        )}
      </Grid>
      {openEditTenantDialog && (
        <TenantCreateUpdateDialog
          open={openEditTenantDialog}
          onClose={onCloseEditTenantDialog}
          onSubmit={getEditTenantDialogCallback}
          tenantId={tenantId}
          fieldsToUpdate={props.fieldsToUpdate}
          fieldsInTenantDetailsFormReadOnly={
            !props.fullDataToUpdateInTenantDialog
          }
          showContacts={props.fullDataToUpdateInTenantDialog}
          showIban={props.fullDataToUpdateInTenantDialog}
          showKrs={props.fullDataToUpdateInTenantDialog}
          showFullAddress={props.showFullAddressInDialog}
          showLocalization={props.showLocalizationInDialog}
        />
      )}
    </Grid>
  );
};

TenantTableWithToolBar.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
  fieldsToUpdate: PropTypes.array,
  tenantDataSearchParams: PropTypes.object,
  fullDataToUpdateInTenantDialog: PropTypes.bool,
  showFullAddressInDialog: PropTypes.bool,
  showAddTenantButton: PropTypes.bool,
  showLocalizationInDialog: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  initialExpandedFilterHeader: PropTypes.bool,
  hasPermissionToEditTeams: PropTypes.bool,
};

TenantTableWithToolBar.defaultProps = {
  fullDataToUpdateInTenantDialog: false,
  showAddTenantButton: false,
  showLocalizationInDialog: false,
  defaultPageSize: 10,
  hasPermissionToEditTeams: false,
};

export default TenantTableWithToolBar;
