import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useForm } from "../../../hooks/useForm";

import TextFieldFormControl from "../../field/TextFieldFormControl";

import useLocationService from "../../../services/locationService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import { getErrorMsg, isEmptyValue } from "../../../helpers/methods";

import {
  DIALOG_PREVIEW_MODE,
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
} from "../../../helpers/constants";

const REQUIRED_FIELDS = ["objcat_name", "objcat_code"];

const ObjectCategoryDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const dialogTitle = useMemo(() => {
    if (props.title) return props.title;

    return props.dialogMode === DIALOG_CREATE_MODE
      ? t("dialog.object_category.add_new_category")
      : props.dialogMode === DIALOG_EDIT_MODE
      ? t("dialog.object_category.edit_category")
      : t("dialog.object_category.category_details");
  }, [props.dialogMode, props.title]);

  const isReadOnly = useMemo(
    () => props.readOnly || props.dialogMode === DIALOG_PREVIEW_MODE,
    [props.readOnly, props.dialogMode]
  );

  const fieldsToSave = useMemo(
    () => props.fieldsToSave || REQUIRED_FIELDS,
    [props.fieldsToSave]
  );
  const { formValue, setFormValue, onChange } = useForm();

  const {
    getObjectCategoryDetails,
    createObjectCategory,
    updateObjectCategory,
  } = useLocationService();

  const objectCategoryInitialData = useAsync(() => {
    if (props.dialogMode === DIALOG_CREATE_MODE) {
      return Promise.resolve({});
    }

    return getObjectCategoryDetails(props.objectCategoryId);
  }, [props.objectCategoryId, props.dialogMode]);

  useEffect(() => {
    setFormValue(objectCategoryInitialData.value);
  }, [objectCategoryInitialData.loading]);

  const prepareDataToSend = () => {
    const dataToSend = {};
    for (const [key, value] of Object.entries(formValue)) {
      if (fieldsToSave.includes(key)) {
        dataToSend[key] = value;
      }
    }
    dataToSend["objcat_subcategory"] = false;
    return dataToSend;
  };

  const updateObjectCategoryFn = useAsyncFn(updateObjectCategory);
  const addObjectCategoryFn = useAsyncFn(createObjectCategory);
  const submitFn = useMemo(
    () =>
      props.dialogMode === DIALOG_CREATE_MODE
        ? addObjectCategoryFn
        : updateObjectCategoryFn,
    [props.dialogMode]
  );

  const handleSubmit = (dataToSend) => {
    submitFn
      .execute(dataToSend, props.objectCategoryId)
      .then((res) => {
        const successMsg =
          props.dialogMode === DIALOG_CREATE_MODE
            ? t("snackbar_alert.object_category_added")
            : t("snackbar_alert.object_category_updated");
        snackbarAlert.openSuccessSnackbarAlert(successMsg);
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isFormValid =
    formValue &&
    REQUIRED_FIELDS.every((field) => !isEmptyValue(formValue[field]));

  const isLoading = formValue === undefined;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={2}
        columnSpacing={2}
      >
        <Grid item xs={12} sm={6}>
          <TextFieldFormControl
            name="objcat_code"
            required
            value={formValue.objcat_code}
            label={t("dialog.object_category.objcat_code")}
            readOnly={isReadOnly}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldFormControl
            name="objcat_name"
            required
            value={formValue.objcat_name}
            label={t("dialog.object_category.objcat_name")}
            readOnly={isReadOnly}
            onChange={onChange}
          />
        </Grid>
        {!isReadOnly && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={
                addObjectCategoryFn.loading || updateObjectCategoryFn.loading
              }
              disabled={!isFormValid}
              onClick={() => handleSubmit(prepareDataToSend())}
            >
              {t("save")}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      showTopFullScreenButton={false}
      title={dialogTitle}
      maxWidth="sm"
      showDialogActions
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

ObjectCategoryDialog.propTypes = {
  dialogMode: PropTypes.oneOf([
    DIALOG_CREATE_MODE,
    DIALOG_EDIT_MODE,
    DIALOG_PREVIEW_MODE,
  ]),
  readOnly: PropTypes.bool,
  objectCategoryId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onSubmitCallback: PropTypes.func,
  fieldsToSave: PropTypes.array,
};

ObjectCategoryDialog.defaultProps = {
  open: false,
};

export default ObjectCategoryDialog;
