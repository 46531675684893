import { useState, useEffect, useRef } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync } from "../../../hooks/useAsync";

import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../hooks/useFilterSearchParams";

import useLocationService from "../../../services/locationService";

import ObjectCategoryTable from "../../table/ObjectCategoryTable";

import ObjectCategoryDialog from "../ObjectCategoryDialog/ObjectCategoryDialog";
import useDialogWithId from "../../../hooks/useDialogWithId";

import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

import { isSearchParamsObjectEmpty } from "../../../helpers/methods";

import {
  overflowButtonStyle,
  centerVericalAlignIconStyle,
} from "../../../helpers/styles";

import {
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
} from "../../../helpers/constants";

const ObjectCategorySubTable = (props) => {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(props.tableFilterPrefix);

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefix,
  } = useFilterSearchParams(searchParams);

  const [objectCategorySearchParams, setObjectCategorySearchParams] = useState(
    getSearchParamsByFilterPrefix(props.tableFilterPrefix)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      props.tableFilterPrefix,
      objectCategorySearchParams,
      setObjectCategorySearchParams
    );
  }, [searchParams, props.tableFilterPrefix]);

  const { getObjectCategoryShortData, getObjectCategoryFilteringData } =
    useLocationService();

  const objectCategoryData = useAsync(() => {
    return getObjectCategoryShortData(objectCategorySearchParams);
  }, [objectCategorySearchParams]);

  const objectCategoryFilteringData = useAsync(getObjectCategoryFilteringData);

  const [objectCategoryDataLocaly, setObjectCategoryDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (objectCategoryData.loading) {
      return;
    }
    setObjectCategoryDataLocaly(objectCategoryData.value.results);
    setCountRecords(objectCategoryData.value.count);
  }, [objectCategoryData.loading]);

  const [
    openObjectCategoryDialog,
    objectCategoryId,
    onOpenObjectCategoryDialog,
    onCloseObjectCategoryDialog,
  ] = useDialogWithId();

  const objectCategoryDialogModeRef = useRef();
  const handleOpenObjectCategoryDialogInCreateMode = () => {
    objectCategoryDialogModeRef.current = DIALOG_CREATE_MODE;
    onOpenObjectCategoryDialog(undefined);
  };

  const handleOpenObjectCategoryDialogInEditMode = (objectCategoryId) => {
    objectCategoryDialogModeRef.current = DIALOG_EDIT_MODE;
    onOpenObjectCategoryDialog(objectCategoryId);
  };

  const getObjectCategoryDialogCallback = () => {
    setObjectCategoryDataLocaly(undefined);

    if (
      objectCategoryDialogModeRef.current === DIALOG_CREATE_MODE &&
      !isSearchParamsObjectEmpty(objectCategorySearchParams)
    ) {
      setSearchParams(clearSearchParamsByFilterPrefix(props.tableFilterPrefix));
    } else {
      objectCategoryData.refetch();
    }

    if (props.setExistsChanges) {
      props.setExistsChanges(true);
    }
  };

  const isLoading =
    objectCategoryDataLocaly === undefined ||
    objectCategoryFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12}>
        <ObjectCategoryTable
          showCleanFilterIcon={true}
          style={{ maxHeight: "60vh" }}
          showFilters={true}
          data={objectCategoryDataLocaly}
          filteringData={objectCategoryFilteringData}
          filterPrefix={props.tableFilterPrefix}
          countRecords={countRecords}
          page={page}
          onPageChange={handleChangePageWithSearchParams}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onClickEdit={handleOpenObjectCategoryDialogInEditMode}
          initialExpandedFilterHeader={
            !isSearchParamsObjectEmpty(objectCategorySearchParams)
          }
          resetPageNumber={resetPageNumber}
          tableConfig={props.tableConfig}
          hiddenColumns={[]}
          columnsOrders={undefined}
          showExportToFileButton={false}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          startIcon={
            <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
          }
          onClick={handleOpenObjectCategoryDialogInCreateMode}
          sx={overflowButtonStyle}
          color={"primary"}
        >
          {t("dialog.object_category.add_category")}
        </Button>
      </Grid>
      {openObjectCategoryDialog && (
        <ObjectCategoryDialog
          open={openObjectCategoryDialog}
          onClose={onCloseObjectCategoryDialog}
          dialogMode={objectCategoryDialogModeRef.current}
          onSubmitCallback={getObjectCategoryDialogCallback}
          objectCategoryId={objectCategoryId}
        />
      )}
    </Grid>
  );
};

ObjectCategorySubTable.propTypes = {
  tableName: PropTypes.string,
  tableConfig: PropTypes.object,
  tableFilterPrefix: PropTypes.string,
  setExistsChanges: PropTypes.func,
};

ObjectCategorySubTable.defaultProps = {};

export default ObjectCategorySubTable;
