import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import PropTypes from "prop-types";

export default function BaseBox(props) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        p: props.padding,
        border: 1,
        borderColor: props.error ? `${theme.palette.error.main}` : props.warning ?  `${theme.palette.warning.main}` :`${theme.palette.grey[400]}`,
        borderRadius: "4px",
        ...props.style,
      }}
    >
      {props.children}
    </Box>
  );
}

BaseBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.any,
  ]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  error : PropTypes.bool,
  warning : PropTypes.bool
};

BaseBox.defaultProps = {
  padding: 1,
  error: false,
  warning : false
};
