import React from "react";

import { Grid } from "@mui/material";

import { RcpScheduleTableCell } from "./RcpScheduleTableCell";

import { RCP_ACTIVITIES, RCP_SHIFTS } from "../../../../helpers/constants";

import {
  LEFT_PART_GRID_XS,
  CENTER_PART_GRID_XS,
  RIGHT_PART_GRID_XS,
  WORKED_HOURS_COL_XS,
  RBH_PER_MOHTH_COL_XS,
  OVERTIME_HOURS_COL_XS,
  FREE_DAY,
  WORK_DAY,
  WORK_AFTERNOON,
  WORK_NIGHT,
  EMPTY_CELL_COLOR,
  GROUP_NAME_ROW_COLOR,
  RCP_PLAN_COLORS,
  RCP_DONE_COLORS,
} from "../Config";

export const RcpDay = ({ day, rcpKind }) => {
  const getBgColorForRcp = (rcpModel, rcpColors) => {
    if (!rcpModel) {
      return day.is_free_day ? rcpColors[FREE_DAY] : EMPTY_CELL_COLOR;
    }

    if (rcpModel.activity === RCP_ACTIVITIES.WORK) {
      return rcpModel.shift === RCP_SHIFTS.DAY
        ? rcpColors[WORK_DAY]
        : rcpModel.shift === RCP_SHIFTS.AFTERNOON
        ? rcpColors[WORK_AFTERNOON]
        : rcpColors[WORK_NIGHT];
    }
    if (rcpModel.activity === RCP_ACTIVITIES.SICK_LEAVE) {
      return rcpColors[RCP_ACTIVITIES.SICK_LEAVE];
    }

    if (rcpModel.activity === RCP_ACTIVITIES.VACATION) {
      return rcpColors[RCP_ACTIVITIES.VACATION];
    }

    if (rcpModel.activity === RCP_ACTIVITIES.DELEGATION) {
      return rcpColors[RCP_ACTIVITIES.DELEGATION];
    }

    return EMPTY_CELL_COLOR;
  };

  const getBgColorForRcpPlan = () => {
    return getBgColorForRcp(day.rcp_plan, RCP_PLAN_COLORS);
  };

  const getBgColorForRcpDone = () => {
    return getBgColorForRcp(day.rcp_done, RCP_DONE_COLORS);
  };

  const isRcpPlan = rcpKind === "rcpplan";
  const isRcpDone = rcpKind === "rcpdone";

  return (
    <Grid item xs={1}>
      <Grid container>
        {!isRcpDone && (
          <Grid item xs={!isRcpPlan ? 6 : 12}>
            <RcpScheduleTableCell
              value={day.rcp_plan?.hours || 0}
              bgColor={getBgColorForRcpPlan()}
              fnColor={day.rcp_plan?.hours ? null : getBgColorForRcpPlan()}
            />
          </Grid>
        )}
        {!isRcpPlan && (
          <Grid item xs={!isRcpDone ? 6 : 12}>
            <RcpScheduleTableCell
              value={day.rcp_done?.hours || 0}
              bgColor={getBgColorForRcpDone()}
              fnColor={day.rcp_done?.hours ? null : getBgColorForRcpDone()}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const EmployeeRow = ({ employee, numDays, rcpKind }) => {
  return (
    <Grid item xs={12} container>
      <Grid item xs={LEFT_PART_GRID_XS}>
        <RcpScheduleTableCell
          value={employee.employee_name}
          style={{
            display: "block",
            overflowX: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        />
      </Grid>
      <Grid item xs={CENTER_PART_GRID_XS}>
        <Grid container columns={numDays}>
          {Object.values(employee.days).map((day) => (
            <RcpDay day={day} rcpKind={rcpKind} />
          ))}
        </Grid>
      </Grid>
      <Grid item container xs={RIGHT_PART_GRID_XS}>
        <Grid item xs={WORKED_HOURS_COL_XS}>
          <RcpScheduleTableCell value={employee.worked_hours} />
        </Grid>
        <Grid item xs={RBH_PER_MOHTH_COL_XS}>
          <RcpScheduleTableCell value={employee.rbh_per_month} />
        </Grid>
        <Grid item xs={OVERTIME_HOURS_COL_XS}>
          <RcpScheduleTableCell value={employee.overtime_hours} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const GroupNameRow = ({ gorupName }) => (
  <Grid item xs={12}>
    <RcpScheduleTableCell
      bgColor={GROUP_NAME_ROW_COLOR}
      fnColor={EMPTY_CELL_COLOR}
      value={gorupName}
    />
  </Grid>
);

export const SkillSection = ({ skill, numDays, rcpKind }) => {
  return (
    <>
      <GroupNameRow gorupName={skill.skill_name} />
      {skill.employees.map((employee) => (
        <EmployeeRow employee={employee} numDays={numDays} rcpKind={rcpKind} />
      ))}
    </>
  );
};

export const RcpScheduleTableBody = React.memo(
  ({ skills, numDays, rcpKind }) => {
    if (!skills) return null;

    return skills.map((skill) => (
      <SkillSection
        skill={skill}
        key={skill.name}
        numDays={numDays}
        rcpKind={rcpKind}
      />
    ));
  }
);
