import React from "react";
import PropTypes from "prop-types";
import { Grid, IconButton, Typography } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import DateField from "../../field/DateField/DateField";
import DateService from "../../../services/dateService";
import { useTranslation } from "react-i18next";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import MiniButton from "../../base/MiniButton/MiniButton";

const GuaranteeScopeForm = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      {props.showFormWithButtonsAndTitle && (
        <Grid item xs={12} marginBottom={1}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
              <Typography
                variant="subtitle2"
                className="capitalize-first-letter"
              >
                {t("form.guarantee_scope.guarantee_scope")}
                {props.showScopeNumber ? ` ${props.index + 1}` : ""}
              </Typography>
              {props.showButtonDeleteGuaranteeScope && (
                <IconButton
                  aria-label="delete"
                  size="small"
                  sx={{ padding: 0, paddingLeft: 0.5, color: "var(--alert)" }}
                  onClick={() => {
                    props.onDeleteGuaranteeScope(props.index);
                  }}
                >
                  <RemoveCircleIcon fontSize="inherit" />
                </IconButton>
              )}
            </Grid>
            <Grid item sm={6} xs={6} textAlign={"end"}>
              {props.showButtonAddGuarnateeScope && (
                <MiniButton
                  variant="contained"
                  size="small"
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  onClick={props.onAddGuarnateeScope}
                >
                  {t("form.guarantee_scope.add_guarantee_scope")}
                </MiniButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sm={3}>
        <DateField
          name="guascope_date_close"
          label={t("form.guarantee_scope.guascope_date_close")}
          value={DateService.convertDateToFormatYYYYMMDD(
            props.formValue.guascope_date_close
          )}
          readOnly={props.readOnly}
          onChange={props.onChangeDate}
          required
        />
      </Grid>
      <Grid item xs={12} sm={2.5}>
        <AutocompleteField
          name="guascope_kind"
          label={t("form.guarantee_scope.guascope_kind")}
          value={props.formValue.guascope_kind}
          options={props.filteringData.guascope_kind}
          isObjectOption={false}
          addNewValue={false}
          onChange={props.onChange}
          readOnly={props.readOnly}
          required
        />
      </Grid>
      <Grid item xs={12} sm={2.5}>
        <AutocompleteField
          name="guascope_responsible"
          label={t("form.guarantee_scope.guascope_responsible")}
          value={props.formValue.guascope_responsible}
          options={props.filteringData.guascope_responsible}
          isObjectOption={true}
          optionLabelKey={"full_name"}
          addNewValue={false}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          readOnly={props.readOnly}
          required
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFieldFormControl
          name={"guascope_desc"}
          label={t(`form.guarantee_scope.guascope_desc`)}
          value={props.formValue.guascope_desc}
          onChange={props.onChange}
          disabled={props.readOnly}
          multiline={true}
          rows={2}
        />
      </Grid>
    </Grid>
  );
});

GuaranteeScopeForm.propTypes = {
  index: PropTypes.number,
  formValue: PropTypes.object,
  filteringData: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  onChangeDate: PropTypes.func,
  showScopeNumber: PropTypes.bool,
  showButtonDeleteGuaranteeScope: PropTypes.bool,
  onDeleteGuaranteeScope: PropTypes.func,
  showButtonAddGuarnateeScope: PropTypes.bool,
  onAddGuarnateeScope: PropTypes.func,
  showFormWithButtonsAndTitle: PropTypes.bool,
};

GuaranteeScopeForm.defaultProps = {
  readOnly: false,
  showScopeNumber: true,
  showFormWithButtonsAndTitle: false,
};

export default GuaranteeScopeForm;
