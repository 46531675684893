import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import useUserService from "../../../services/userService";
import { useAsync } from "../../../hooks/useAsync";
import BaseTable from "../../base/BaseTable/BaseTable";
import { useEmptyArrayMemo } from "../../../hooks/useEmptyArrayMemo";
import TableService from "../../../services/tableService";
import { OFFER_LOGS_TABLE_FIELDS_CONFIG, OWNER_LOGS_TABLE_FIELDS_CONFIG, SETTLEMENT_LOGS_TABLE_FIELDS_CONFIG } from "./DialogTablesConfig";
import useOfferService from "../../../services/offerService";
import BoxWithTypography from "../../box/BoxWithTypography/BoxWithTypography";
import { Grid } from "@mui/material";

const OfferHistoryLogDialog = (props) => {
  const { t } = useTranslation();

  const {
    getOfferLogs,
    getSettlementLogs } = useOfferService();

  const offerLogData = useAsync(
    () => getOfferLogs(props.offerId),
    [props.offerId]
  );

  const settlementLogData = useAsync(
    () => getSettlementLogs(props.offerId),
    [props.offerId]
  );

  const { emptyArrayMemo } = useEmptyArrayMemo()

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={t(`dialog.offer_history_log_dialog.history_cr_log_changes`)}
      allwaysFullScreen = {true}
      showDialogActions
    >
      <LoaderWrapper showLoader={offerLogData.loading} >
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12}>
          <BoxWithTypography
            style={{ padding: "10px" }}
            label={t(`dialog.offer_history_log_dialog.offer_data_changes`)}
            component={"div"}
          >
            <BaseTable
              headers={TableService.getHeaderLables(
                OFFER_LOGS_TABLE_FIELDS_CONFIG
              )}
              rows={TableService.getPreparedDataForBaseTable(
                OFFER_LOGS_TABLE_FIELDS_CONFIG,
                offerLogData.value ? offerLogData.value : emptyArrayMemo
              )}
            />
          </BoxWithTypography>
        </Grid>

        <Grid item xs={12}>
          <BoxWithTypography
            style={{ padding: "10px" }}
            label={t(`dialog.offer_history_log_dialog.settlements_data_changes`)}
            component={"div"}
          >
            <BaseTable
              headers={TableService.getHeaderLables(
                SETTLEMENT_LOGS_TABLE_FIELDS_CONFIG
              )}
              rows={TableService.getPreparedDataForBaseTable(
                SETTLEMENT_LOGS_TABLE_FIELDS_CONFIG,
                settlementLogData.value ? settlementLogData.value : emptyArrayMemo
              )}
            />
          </BoxWithTypography>
        </Grid>
        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
};

OfferHistoryLogDialog.propTypes = {
  offrId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

OfferHistoryLogDialog.defaultProps = {
  open: false,
  readOnly: true
};

export default OfferHistoryLogDialog;
