import TicketsSaftyReportsPage from "../TicketsSaftyReportsPage";

const CHART_DESCRIPTION = (
  <div>
    Wykres pokazuje liczbę unikalnych zgłoszeń z możliwością filtracji po
    kategorii zgłoszenia, zakresu czasowego oraz wybranych wnioskodawców, którzy
    są twórcami zgłoszeń.
    <br />
    <br />W tabeli pod wykresem znajdują się 3 dodatkowe kolumny:
    <br />
    <br />
    <b>1. SLA</b> – zakładany czas realizacji zgłoszenia (na podstawie
    standardowego czasu realizacji, zaokrąglonego do pełnego dnia).
    <br />
    <b>2. Czas realizacji</b> – faktyczny czas od założenia ticketu do
    zamknięcia, zaokrąglenie do pełnego dnia <br />
    <b>3. Przekroczenie SLA</b> – różnica między czasem od utworzenia do
    zamknięcia ticketu, a wskaźnikiem SLA, zaokrąglenie do pełnego dnia <br />
  </div>
);

export default function TicketsPerTicketOwnerReportPage(props) {
  return (
    <TicketsSaftyReportsPage
      pageName={props.pageName}
      pageTitleKey={"tickets_per_owner_report"}
      groupBy={"ticket_category"}
      labelBy={"ticket_owner"}
      showSelectTimeRange={false}
      showTicketOwner={true}
      showSelectObjectLevel2={false}
      showChartInformationDialog={true}
      chartDescrtiption={CHART_DESCRIPTION}
    />
  );
}
