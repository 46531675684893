import React  from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import AutocompleteField from "../../field/AutocompleteField";
import FloatField from "../../base/FloatField/FloatField";
import { useTranslation } from "react-i18next";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import AddButton from "@mui/icons-material/Add";

const PartParameterForm = (props) => {

  const { t } = useTranslation();
 
  return (
    <Grid container spacing={1}>
         <Grid item xs={props.readOnly  || props.readOnlyParameter ? 12 : 11}>
        <AutocompleteField
          name="parameter"
          label={t(`form.part_parameter_form.parameter`)}
          value={props.formValue.parameter}
          options={props.parametersList?.value}
          isObjectOption={true}
          optionLabelKey={"param_name"}
          onChange={props.onChangeParameter}
          disabled={props.readOnly || props.readOnlyParameter}
          required
        />
      </Grid>
      {(!props.readOnly &&  !props.readOnlyParameter) &&
        <Grid item xs={1} style={{paddingLeft:"0"}}>
          <IconButtonWithTooltip
            style={{ border: "3px solid var(--secondary)" }}
            title={t(`form.part_parameter_form.create_parameter`)}
            onClick={props.onOpenAddNewParameter}
          >
            <AddButton
              fontSize="medium"
              sx={{
                color: "var(--secondary)",
              }}
            />
          </IconButtonWithTooltip> 
        </Grid>
      }

      <Grid item xs={12}>
        <FloatField
          name={"parval_value"}
          label={t(`form.part_parameter_form.value`)}
          value={props.formValue.parval_value}
          onChange={props.onChange}
          disabled={props.readOnly}
          required
          decimalPlaces={2}
          suffix={props.selectedUnit}
        />
      </Grid>


    </Grid>
  );
};

PartParameterForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  readOnlyParameter : PropTypes.bool
};

PartParameterForm.defaultProps = {
  readOnly: false,
  readOnlyParameter : false
};

export default PartParameterForm;
