import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {
  phoneDetailsUrl,
  createPhoneUrl,
  ownerPhoneUrl,
  subcontractorPhoneUrl
} from "../helpers/apiUrls";


export default function usePhoneService() {
  const axiosPrivate = useAxiosPrivate();


  const getPhoneDetails = (phoneId) => {
    if (phoneId){
      return makeRequest(axiosPrivate, phoneDetailsUrl(phoneId));
    }
    return Promise.resolve([]);
  };

  const createPhone = (phoneData) =>{
    return makeRequest(axiosPrivate, createPhoneUrl, {
      method: "POST",
      data: phoneData,
    });
  };

  const updatePhone = (phoneId, phoneData) => {
    return makeRequest(axiosPrivate, phoneDetailsUrl(phoneId), {
      method: "PATCH",
      data: phoneData,
    });
  };


  const removePhoneOwner = (ownerPhoneId) => {
    return makeRequest(axiosPrivate, ownerPhoneUrl(ownerPhoneId), {
      method: "PATCH",
      data: {owph_active : false},
    });
  };
  const removePhoneSubcontractor = (subcontractorPhoneId) => {
    return makeRequest(axiosPrivate, subcontractorPhoneUrl(subcontractorPhoneId), {
      method: "PATCH",
      data: {suph_active : false},
    });
  };

  return {
    getPhoneDetails,
    createPhone,
    updatePhone,
    removePhoneOwner,
    removePhoneSubcontractor 
  };
}
