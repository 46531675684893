import React, { useEffect } from "react";

import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";
import FloatField from "../../base/FloatField/FloatField";
import TimeDurationSelectField from "../../field/TimeDurationSelectField";

import useLocationService from "../../../services/locationService";
import useUserService from "../../../services/userService";
import useSkillService from "../../../services/skillService";
import useOrderService from "../../../services/orderService";
import useTicketService from "../../../services/ticketService";
import { useAsync } from "../../../hooks/useAsync";

import { useTranslation } from "react-i18next";
import { useForm } from "../../../hooks/useForm";

import TicketShortDetailsForm from "../TicketShortDetailsForm";
import LocationRecords from "../../other/LocationRecords";

import { isEmptyValue } from "../../../helpers/methods";

const getNewOrderNumber = (ticketNr, orderForTicketCount) => {
  return `${ticketNr}.${String(orderForTicketCount + 1).padStart(2, "0")}`;
};

const requiredFieldsForStdOrders = ["std_order", "order_priority", "objects"];

const requiredFieldsForNonStdOrders = [
  "order_priority",
  "objects",
  "order_name",
  "order_paid",
  "order_type",
  "order_critical",
  "order_need",
  "order_kt_need",
  "order_std_time_value",
];

const orderKtorSkillFields = ["order_kt", "skill"];

const CreateOrderForm = (props) => {
  const { t } = useTranslation();

  const {
    getChosenHighestLevelObject,
    prepareLocationDataFromBackendForLocationRecordsComponent,
  } = useLocationService();

  const prepareDataToSend = () => {
    const dataToSend = {
      ...formValue,
      ticket: props.ticketId,
      std_order: formValue.order_is_std ? formValue.std_order : null,
    };
    var objects = [];
    for (const locationLevels of dataToSend.objects) {
      let lastLocationObjectId = getChosenHighestLevelObject(locationLevels);
      if (lastLocationObjectId) objects.push(lastLocationObjectId);
    }
    return { ...dataToSend, objects };
  };

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm({ order_is_std: true });

  const isFormValid = () => {
    let requiredFields = null;

    if (formValue.order_is_std) requiredFields = requiredFieldsForStdOrders;
    else requiredFields = requiredFieldsForNonStdOrders;

    return (
      requiredFields.every(
        (fieldName) => !isEmptyValue(formValue[fieldName])
      ) &&
      orderKtorSkillFields.some(
        (fieldName) => !isEmptyValue(formValue[fieldName])
      )
    );
  };
  const { getTicketShortDetailsData } = useTicketService();
  const ticketShortDetailsData = useAsync(
    () => getTicketShortDetailsData(props.ticketId),
    [props.ticketId]
  );

  useEffect(() => {
    if (ticketShortDetailsData.loading) return;
    setFormValue((prev) => ({
      ...prev,
      objects: prepareLocationDataFromBackendForLocationRecordsComponent(
        ticketShortDetailsData.value.locations
      ),
    }));
  }, [ticketShortDetailsData.loading]);

  const handleChangeLocations = (locations) => {
    setFormValue((prev) => ({ ...prev, objects: locations }));
  };

  const {
    getOrderPrioritySelectList,
    getStdOrdersForTicketSelectList,
    getOrderTypeSelectList,
  } = useOrderService();
  const orderTypeSelectList = useAsync(getOrderTypeSelectList);
  const orderPrioritySelectList = useAsync(getOrderPrioritySelectList);
  const stdOrdersSelectList = useAsync(
    () => getStdOrdersForTicketSelectList(props.ticketId),
    [props.ticketId]
  );

  const { getUserSelectList } = useUserService();
  const { getSkillSelectList } = useSkillService();
  const userSelectList = useAsync(() =>
    getUserSelectList("roles=role_is_kt&roles=role_is_tb&roles=role_is_k")
  );
  const skillSelectList = useAsync(() =>
    getSkillSelectList({ all_skill: true })
  );

  const handleChangeOrderKt = (...inputProps) => {
    if (formValue.skill) formValue.skill = null;
    onChangeAutocompleteFieldWithObjectOptions(...inputProps);
  };

  const handleChangeSkill = (...inputProps) => {
    if (formValue.order_kt) formValue.order_kt = null;
    onChangeAutocompleteFieldWithObjectOptions(...inputProps);
  };

  const showLoading = () =>
    userSelectList.loading ||
    skillSelectList.loading ||
    ticketShortDetailsData.loading ||
    orderPrioritySelectList.loading ||
    orderTypeSelectList.loading ||
    stdOrdersSelectList.loading;
  if (showLoading()) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TicketShortDetailsForm formValue={ticketShortDetailsData.value} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TrueFalseSelectField
          name="order_is_std"
          label={t("form.order.order_is_std")}
          value={formValue.order_is_std}
          onChange={onChange}
          valuesAsBool={true}
          addEmptyOptions={false}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldFormControl
          label={t("form.order.order_nr")}
          value={getNewOrderNumber(
            ticketShortDetailsData.value.ticket_nr,
            ticketShortDetailsData.value.order_count
          )}
          readOnly
        />
      </Grid>
      {formValue.order_is_std ? (
        <Grid item xs={12} sm={6}>
          <AutocompleteField
            name="std_order"
            label={t("form.order.order_name")}
            value={formValue.std_order}
            options={stdOrdersSelectList.value}
            isObjectOption={true}
            optionLabelKey={"std_order_name"}
            addNewValue={false}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            required
          />
        </Grid>
      ) : (
        <Grid item xs={12} sm={6}>
          <TextFieldFormControl
            name="order_name"
            label={t("form.order.order_name")}
            value={formValue.order_name}
            onChange={onChange}
            required
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <AutocompleteField
          name="order_priority"
          label={t("form.order.order_priority")}
          value={formValue.order_priority}
          options={orderPrioritySelectList.value}
          isObjectOption={true}
          optionLabelKey={"ordpri_name"}
          addNewValue={false}
          onChange={onChangeAutocompleteFieldWithObjectOptions}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <LocationRecords
          locations={formValue.objects}
          onChangeLocations={handleChangeLocations}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <AutocompleteField
          name="order_kt"
          label={t("form.order.order_kt")}
          value={formValue.order_kt}
          options={userSelectList.value}
          disabled={formValue.skill}
          isObjectOption={true}
          optionLabelKey={"full_name"}
          addNewValue={false}
          onChange={handleChangeOrderKt}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutocompleteField
          name="skill"
          label={t("form.order.skill")}
          value={formValue.skill}
          disabled={formValue.order_kt}
          options={skillSelectList.value}
          isObjectOption={true}
          optionLabelKey={"skill_name"}
          addNewValue={false}
          onChange={handleChangeSkill}
        />
      </Grid>
      {!formValue.order_is_std && (
        <>
          <Grid item xs={12} sm={6}>
            <TimeDurationSelectField
              name="order_std_time_value"
              label={t("form.order.order_std_time_value")}
              value={formValue.order_std_time_value}
              onChange={onChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TrueFalseSelectField
              name="order_paid"
              label={t("form.order.order_paid")}
              value={formValue.order_paid}
              onChange={onChange}
              valuesAsBool={true}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutocompleteField
              name="order_type"
              label={t("form.order.order_type")}
              value={formValue.order_type}
              options={orderTypeSelectList.value}
              isObjectOption={true}
              optionLabelKey={"ordtyp_type"}
              addNewValue={false}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FloatField
              name="order_critical"
              label={t("form.order.order_critical")}
              value={formValue.order_critical}
              onChange={onChange}
              decimalPlaces={0}
              min={1}
              max={5}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TrueFalseSelectField
              name="order_kt_need"
              label={t("form.order.order_kt_need")}
              value={formValue.order_kt_need}
              onChange={onChange}
              valuesAsBool={true}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TrueFalseSelectField
              name="order_need"
              label={t("form.order.order_need")}
              value={formValue.order_need}
              onChange={onChange}
              valuesAsBool={true}
              required
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <TextFieldFormControl
          addEmptyOptions={false}
          name="order_desc_creator"
          label={t("form.order.comment")}
          value={formValue.order_desc_creator}
          onChange={onChange}
          multiline
          rows={2}
        />
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          loading={props.isLoading}
          disabled={!isFormValid()}
          onClick={() => props.onSubmit(prepareDataToSend())}
        >
          {t("create")}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

CreateOrderForm.propTypes = {
  onSubmit: PropTypes.func,
  ticketId: PropTypes.string,
  isLoading: PropTypes.bool,
};

CreateOrderForm.defaultProps = {};

export default CreateOrderForm;
