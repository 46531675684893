import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useForm } from "../../../hooks/useForm";
import { useEffect, useMemo } from "react";
import OfferSettlementsForm from "../../form/OfferSettlementsForm";
import useSettlementData from "../../../hooks/useSettlementData";
import { SETTLEMENT_TABLE_FIELDS_CONFIG } from "../../form/OfferSettlementsForm/FormTablesConfig";
import useSettlementTableService from "../../../services/settlementTableService";
import BoxWithTypography from "../../box/BoxWithTypography/BoxWithTypography";
import AutocompleteField from "../../field/AutocompleteField";
import { isEmptyValue } from "../../../helpers/methods";
import LoadingButton from "@mui/lab/LoadingButton";
import { OFFER_CHANGE_REQUEST_ACTION } from "../../../helpers/constants";
import DeleteIcon from "@mui/icons-material/Delete";


const OfferDataRequestDialog = (props) => {
  const { t } = useTranslation();
  const { setSettlementCalculatedData } = useSettlementTableService();
  
  const {
    formValue,
    onChangeAutocompleteFieldWithObjectOptions
  } = useForm({"id" :props.offerData?.id,  "owner": props.offerData?.owner });

  const {
    offerSettlementData,
    setSettlementData,
    onChangeSettlementAutocompleteField,
    onChangeSettlementTableData,
    onHandleSettlementAddEmptyRow,
    requiredSettlementFields,
    handleRemoveSettlementFromList,
    getSettlementsToSend,
    isSettlementTableValid,
    isSettlementSumRateValid,
    refetchSettlementData,
  } = useSettlementData(
    props.offerId,
    SETTLEMENT_TABLE_FIELDS_CONFIG,
    props.totalItemsCost,
    props.dialogMode === "preview",
    undefined,
    props.dialogMode
  );

  const settlementTableContextMenuActions = useMemo(
    () => [
      {
        label: t("dialog.offer_configurator_dialog.remove_element"),
        callback: handleRemoveSettlementFromList,
        icon: <DeleteIcon fontSize="small" />,
      },
    ],
    [handleRemoveSettlementFromList]
  );

  useEffect(() => {
    if (offerSettlementData && props.totalItemsCost) {
      let tempSetlements = setSettlementCalculatedData(
        offerSettlementData,
        props.totalItemsCost[0],
        requiredSettlementFields
      );
      setSettlementData([...tempSetlements]);

    }
  }, [props.totalItemsCost, offerSettlementData, props.open]);

  const onPrepareDataToSave = () => {
    let dataToSend = {};
    dataToSend.offer_details = formValue;
    dataToSend.action = OFFER_CHANGE_REQUEST_ACTION;
    dataToSend.settlements = getSettlementsToSend();
    return dataToSend;
  };

  const onSubmit = () =>{
    props.onSubmit(onPrepareDataToSave())
  }

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={t(`dialog.offer_data_request_dialog.change_request`)}
      maxWidth="xl"
      showDialogActions
    >

      <LoaderWrapper showLoader={offerSettlementData === undefined || props.filteringData.loading}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12}>
            <BoxWithTypography
              style={{ padding: "10px" }}
              label={t(`dialog.offer_data_request_dialog.offer_data_changes`)}
              component={"div"}
            >
              <Grid item xs={4}>
                <AutocompleteField
                  label={t("form.offer.owner")}
                  name={"owner"}
                  value={formValue?.owner}
                  options={props.offerFilteringData ? props.offerFilteringData.owner : []}
                  isObjectOption={true}
                  optionLabelKey={"owner_short_name"}
                  onChange={onChangeAutocompleteFieldWithObjectOptions}
                  addNewValue={false}
                  disabled={props.isOfferCRLoading}

                />
              </Grid>
            </BoxWithTypography>
          </Grid>
          <Grid item xs={12}>
            <BoxWithTypography
              style={{ padding: "10px" }}
              label={t(`dialog.offer_data_request_dialog.settlements_changes`)}
              component={"div"}
            >
              <OfferSettlementsForm
                data={offerSettlementData}
                readOnly={props.isOfferCRLoading}
                filteringData={props.filteringData}
                onChangeAutocomplete={onChangeSettlementAutocompleteField}
                onHandleAddEmptyRow={onHandleSettlementAddEmptyRow}
                contextMenuActions={settlementTableContextMenuActions }
                showContextMenu={!props.isOfferCRLoading}
                onDataChange={onChangeSettlementTableData}
                isTableValid={isSettlementTableValid}
                isSumValid={isSettlementSumRateValid}
                tableConfig={SETTLEMENT_TABLE_FIELDS_CONFIG}
                showConfirmationForm={false}
              />
            </BoxWithTypography>
          </Grid>

          <Grid item xs={6}>
            <LoadingButton
              variant="contained"
              color="success"
              fullWidth
              loading={props.isOfferCRLoading}
              disabled={!isSettlementTableValid || isEmptyValue(formValue?.owner)}
              onClick={() => onSubmit()}
            >
              {t(`dialog.offer_data_request_dialog.send_request_changes`)}
            </LoadingButton>
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={props.onClose}
              disabled={props.isOfferCRLoading}
            >
              {t("cancel")}
            </Button>
          </Grid>
        </Grid>

      </LoaderWrapper>

    </BasicDialog>
  );
};

OfferDataRequestDialog.propTypes = {
  agreementId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  isOfferCRLoading : PropTypes.bool
};

OfferDataRequestDialog.defaultProps = {
  open: false,
  isOfferCRLoading: false
};

export default OfferDataRequestDialog;
