import { useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import {
  DOCS_RESTRICTED_TABLE_NAME,
  DOCS_RESTRICTED_TABLE_FIELDS_CONFIG,
} from "./PageTableConfig";
import DocsRestrictedTable from "../../components/table/DocsRestrictedTable";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import useDocsService from "../../services/docsService";
import { useAsync } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import useDialogWithId from "../../hooks/useDialogWithId";
import useDialog from "../../hooks/useDialog";
import AddEditDocsRestrictedDialog from "../../components/dialog/AddEditDocsRestrictedDialog";
import { isSearchParamsForFilteringEmpty } from "../../helpers/methods";
import MultipleLocalizationDialog from "../../components/dialog/MutlipleLocalizationDialog/MultipleLocalizationDialog";
import { NAVIGATION_DRAWER_TYPE_DOCUMENTS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import { MANAGE_DOCS_RESTRICTED } from "../../helpers/constants";
import useCheckPermission from "../../hooks/usePermission";

export default function DoscObjectsPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();
  const [dataLocaly, setDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  const [hasEditPermission] = useCheckPermission(MANAGE_DOCS_RESTRICTED);
  const [tableConfigLocal, setTableConfigLocal] = useState(
    DOCS_RESTRICTED_TABLE_FIELDS_CONFIG
  );

  useEffect(() => {
    if (
      hasEditPermission &&
      tableConfigLocal.find((config) => config.name === "edit") === undefined
    ) {
      const tempConfig = [...DOCS_RESTRICTED_TABLE_FIELDS_CONFIG];
      tempConfig.push({
        name: "edit",
        getValueCallback: (
          rowData,
          onClickMultipleLocationAlert,
          onDownload,
          onPreview,
          onClickEdit
        ) =>
          rowData.is_allowed_to_edit ? (
            <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
          ) : null,
        label: t("edit"),
        filterType: null,
        getFilterFieldConfigCallback: (filteringData) => undefined,
        allowChangeVisibility: false,
        excludedFromExport: true,
      });
      setTableConfigLocal(tempConfig);
    }
  }, [hasEditPermission]);

  const TABLE_CONFIGS = [
    {
      name: DOCS_RESTRICTED_TABLE_NAME,
      config: tableConfigLocal,
    },
  ];

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const {
    getDocsRestrictedData,
    getDocsRestrictedFilteringData,
    handleDownloadDoc,
    handlePreviewDoc,
  } = useDocsService();

  const [
    openAddEditDocsDialog,
    docsId,
    onOpenAddEditDocsDialog,
    onCloseAddEditDocsDialog,
  ] = useDialogWithId();

  const docsRestrictedData = useAsync(
    () => getDocsRestrictedData(searchParams),
    [searchParams]
  );

  const DocsRestrictedFilteringData = useAsync(getDocsRestrictedFilteringData);

  useEffect(() => {
    if (docsRestrictedData.loading) {
      return;
    }
    setDataLocaly(docsRestrictedData.value.results);
    setCountRecords(docsRestrictedData.value.count);
  }, [docsRestrictedData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setDataLocaly(undefined);
    docsRestrictedData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const onSubmitDocsRestrictedCallback = () => {
    if (!isSearchParamsForFilteringEmpty(searchParams)) {
      setSearchParams({});
    } else {
      docsRestrictedData.refetch();
    }

    handleCloseAddEditDocsDialog();
  };

  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();
  const clickedMultipleLocalization = useRef();
  const isDoscRestrictedDialogReadOnlyRef = useRef(false);

  const handleCloseAddEditDocsDialog = () => {
    onCloseAddEditDocsDialog();
    isDoscRestrictedDialogReadOnlyRef.current = false;
  };

  const handleClickInfo = useCallback(
    (docsId) => {
      isDoscRestrictedDialogReadOnlyRef.current = true;
      onOpenAddEditDocsDialog(docsId);
    },
    [onOpenAddEditDocsDialog]
  );

  const handleOpenMultipleLocalizationDialog = useCallback(
    (e, docsId) => {
      e.stopPropagation();
      clickedMultipleLocalization.current = dataLocaly.find(
        (docs) => docs.id === docsId
      ).docs_restricted_object;
      onOpenMultipleLocalizationDialog();
    },
    [onOpenMultipleLocalizationDialog, dataLocaly]
  );

  const handleCloseMultipleLocalizationDialog = () => {
    clickedMultipleLocalization.current = null;
    onCloseMultipleLocalizationDialog();
  };

  const isLoading =
    DocsRestrictedFilteringData.loading ||
    dataLocaly === undefined ||
    countRecords === undefined;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_DOCUMENTS}
    >
      <DefaultPageWrapper titleKey={"docs_restricted"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <UniversalToolBarWithDialogs
              pageName={props.pageName}
              tableConfig={TABLE_CONFIGS}
              filteringData={DocsRestrictedFilteringData}
              refreshTable={refreshTable}
              onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
              resetPageNumber={resetPageNumber}
              hiddenColumnsForTables={hiddenColumnsForTables}
              setHiddenColumnsForTables={setHiddenColumnsForTables}
              columnsOrdersForTables={columnsOrdersForTables}
              setColumnsOrdersForTables={setColumnsOrdersForTables}
              showOpenCloseFilterButton={false}
              createButtonLabel={t("dialog.enclosures_dialog.add_docs")}
              onClickCreateItem={() => onOpenAddEditDocsDialog(null)}
              showCreateButton={hasEditPermission}
            />
          </Grid>
          <Grid item xs={12}>
            <LoaderWrapper showLoader={isLoading}>
              {hiddenColumnsForTables && columnsOrdersForTables && (
                <DocsRestrictedTable
                  data={dataLocaly}
                  showCheckbox={false}
                  showCleanFilterIcon={false}
                  countRecords={countRecords}
                  page={page}
                  onPageChange={handleChangePageWithSearchParams}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  filteringData={DocsRestrictedFilteringData}
                  style={{ maxHeight: "75vh" }}
                  tableConfig={tableConfigLocal}
                  hiddenColumns={
                    hiddenColumnsForTables[DOCS_RESTRICTED_TABLE_NAME]
                  }
                  columnsOrders={
                    columnsOrdersForTables[DOCS_RESTRICTED_TABLE_NAME]
                  }
                  onDownload={handleDownloadDoc}
                  onPreview={handlePreviewDoc}
                  onClickMultipleLocationAlert={
                    handleOpenMultipleLocalizationDialog
                  }
                  onClickInfo={handleClickInfo}
                  onClickEdit={onOpenAddEditDocsDialog}
                />
              )}
            </LoaderWrapper>
          </Grid>
        </Grid>
        {openAddEditDocsDialog && (
          <AddEditDocsRestrictedDialog
            open={openAddEditDocsDialog}
            onClose={handleCloseAddEditDocsDialog}
            docsId={docsId}
            submitButtonLabel={t("dialog.enclosures_dialog.add_docs")}
            onSubmit={onSubmitDocsRestrictedCallback}
            multiple={false}
            docsRestricted={true}
            readOnly={isDoscRestrictedDialogReadOnlyRef.current}
          />
        )}
        {openMultipleLocalizationDialog &&
          clickedMultipleLocalization.current && (
            <MultipleLocalizationDialog
              open={openMultipleLocalizationDialog}
              onClose={handleCloseMultipleLocalizationDialog}
              localizationData={clickedMultipleLocalization.current}
              itemType={"docs"}
            />
          )}
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
