export const overflowButtonStyle = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  display: "inline-block",
};

export const centerVericalAlignIconStyle = {
  verticalAlign: "sub",
};

export const GREEN_COLOR = "#509153";
export const RED_COLOR = "#d32f2f";
export const YELLOW_COLOR = "#EDBD01";

export const getStyleForSLACell = (sla, returnOnlyColorValue = false) => {
  let color = YELLOW_COLOR;
  if (sla > 2) color = RED_COLOR;
  else if (sla <= 0) color = GREEN_COLOR;
  return returnOnlyColorValue ? color : { backgroundColor: color };
};

export const getStyleForTicketResponseTimeCell = (
  responseTime,
  returnOnlyColorValue = false
) => {
  let color = YELLOW_COLOR;
  if (responseTime > 24) color = RED_COLOR;
  else if (responseTime <= 2) color = GREEN_COLOR;
  return returnOnlyColorValue ? color : { backgroundColor: color };
};

export const getNpsIndicatorColor = (npsIndicator) => {
  if (!npsIndicator) return null;

  const goodIndicatorValue = 50.0;
  const badIndicatorValue = 0.0;

  let color = YELLOW_COLOR;
  if (npsIndicator > goodIndicatorValue) color = GREEN_COLOR;
  else if (npsIndicator <= badIndicatorValue) color = RED_COLOR;
  return color;
};
