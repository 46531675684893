import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import DateField from "../../field/DateField/DateField";
import AutocompleteField from "../../field/AutocompleteField";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";

export default function TicketFeedbackChartToolBarForm(props) {
  const { t } = useTranslation();

  const getFormContent = () => (
    <>
      {props.showSelectTenant && (
        <Grid
          item
          xs={12}
          md={
            props.isVerticalForm
              ? 12
              : props.showSelectObjectLevel2
              ? 1.75
              : 3.5
          }
        >
          <AutocompleteField
            name="tenant"
            multiple={true}
            isObjectOption={true}
            optionLabelKey={"tenant_short_name"}
            value={props.formValue.tenant}
            options={props.filteringData.value?.tenant}
            label={t("table.ticket.tenant")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            limitTags={props.isVerticalForm ? undefined : 0}
          />
        </Grid>
      )}
      {props.showSelectObjectLevel2 && (
        <Grid
          item
          xs={12}
          md={props.isVerticalForm ? 12 : props.showSelectTenant ? 1.75 : 3.5}
        >
          <AutocompleteField
            name="objects_level_2_id"
            multiple={true}
            isObjectOption={true}
            optionLabelKey={"object_name"}
            value={props.formValue.objects_level_2_id}
            options={props.filteringData.value?.object_level_2}
            label={t("bar.ticket_chart_bar.object_level_2")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            limitTags={props.isVerticalForm ? undefined : 0}
            required
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={props.isVerticalForm ? 12 : 4}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <DateField
          name={"ticket_date_closeALL_real_start"}
          label={t("bar.ticket_chart_bar.start_close_date")}
          value={props.formValue.ticket_date_closeALL_real_start}
          onChange={props.onChangeDateConvertedToDateTimeWithZeroHourOfDate}
          showClearIcon={false}
          required
          maxDate={
            props.formValue.ticket_date_closeALL_real_end
              ? props.formValue.ticket_date_closeALL_real_end
              : undefined
          }
        />
        <div style={{ marginInline: "5px" }}>{"-"}</div>
        <DateField
          name={"ticket_date_closeALL_real_end"}
          label={t("bar.ticket_chart_bar.end_close_date")}
          value={props.formValue.ticket_date_closeALL_real_end}
          onChange={props.onChangeDateConvertedToDateTimeWithEndHourOfDate}
          showClearIcon={false}
          required
          minDate={
            props.formValue.ticket_date_closeALL_real_start
              ? props.formValue.ticket_date_closeALL_real_start
              : undefined
          }
        />
      </Grid>
      {props.isVerticalForm && props.showTenantVisibilityButton && (
        <Grid item xs={12}>
          <TrueFalseSelectField
            name="tenant_rr_visibility"
            label={t("page.feedback_management_page.tenat_rr_visibility")}
            value={props.formValue?.tenant_rr_visibility}
            valuesAsBool
            addEmptyOptions={true}
            onChange={props.onChange}
          />
        </Grid>
      )}
    </>
  );

  if (props.onlyFormContent) return getFormContent();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      rowSpacing={1}
    >
      {getFormContent()}
    </Grid>
  );
}

TicketFeedbackChartToolBarForm.propTypes = {
  formValue: PropTypes.object,
  filteringData: PropTypes.object,
  setFormValue: PropTypes.func,
  onChangeDateConvertedToDateTimeWithEndHourOfDate: PropTypes.func,
  onChangeDateConvertedToDateTimeWithZeroHourOfDate: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  isVerticalForm: PropTypes.bool,
  onlyFormContent: PropTypes.bool,
  showSelectObjectLevel2: PropTypes.bool,
  showSelectTenant: PropTypes.bool,
};

TicketFeedbackChartToolBarForm.defaultProps = {
  isVerticalForm: false,
  onlyFormContent: true,
  showSelectObjectLevel2: false,
  showSelectTenant: false,
  showClearFiltersIcon: true,
};
