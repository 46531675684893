import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import BasicDialog from "../../base/BasicDialog";
import AddFlagForm from "../../form/AddDeleteFlagForm";

const AddDeleteFlagDialog = (props) => {
  const { t } = useTranslation();

  const getDialogTitle = () => {
    let fromTo = props.dialogMode === "add" ? "to" : "from";
    return t(
      `dialog.add_flag.${props.dialogMode}_flags_${fromTo}_${props.itemType}s`
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={getDialogTitle()}
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        <Grid item xs={12}>
          <AddFlagForm
            itemType={props.itemType}
            onCancel={props.onClose}
            onSubmit={props.onSubmit}
            itemIds={props.itemIds}
            formMode={props.dialogMode}
          />
        </Grid>
      </Grid>
    </BasicDialog>
  );
};

AddDeleteFlagDialog.propTypes = {
  itemType: PropTypes.oneOf(["ticket", "order"]),
  itemIds: PropTypes.arrayOf(PropTypes.string),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  dialogMode: PropTypes.oneOf(["delete", "add"]),
};

AddDeleteFlagDialog.defaultProps = {
  itemType: "ticket",
  open: false,
  dialogMode: "add",
};

export default AddDeleteFlagDialog;
