import { AUTOCOMPLETE_FIELD_TYPE,  BOOLEAN_FIELD_TYPE,  TEXT_FIELD_TYPE } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import SelectFieldService from "../../../../services/selectFieldService";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import PrefixSuffixFieldTableWrapper from "../../../wrapper/PrefixSuffixFieldTableWrapper"
import WarningAlertIcon from "../../../other/WarningAlertIcon"
import { formatRackShellData } from "../../../../page/StockWarehousePage/PageTablesConfig";


export const WAREHOUSE_TABLE_NAME = "warehouse_table";
export const WAREHOUSE_TABLE_CONFIG = [
  {
    name: "warehouse_name",
    getValueCallback: (rowData) => rowData.warehouse_name,
    label: i18n.t("table.warehouse_table.warehouse_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "warehouse_parent",
    getValueCallback: (rowData) => rowData.warehouse_parent,
    label: i18n.t("table.warehouse_table.warehouse_parent"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.warehouses,
      isObjectOption: true,
      optionLabelKey: "warehouse_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "warehouse_scope",
    getValueCallback: (rowData) => rowData.warehouse_scope,
    label: i18n.t("table.warehouse_table.warehouse_scope"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },

  {
    name: "objects_level_1",
    getValueCallback: (rowData) => rowData.object?.level_1,
    label: i18n.t("table.ticket.object_level_1"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_2",
    getValueCallback: (rowData) => rowData.object?.level_2,
    label: i18n.t("table.ticket.object_level_2"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_3",
    getValueCallback: (rowData) => rowData.object?.level_3,
    label: i18n.t("table.ticket.object_level_3"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_4",
    getValueCallback: (rowData) => rowData.object?.level_4,
    label: i18n.t("table.ticket.object_level_4"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_5",
    getValueCallback: (rowData) => rowData.object?.level_5,
    label: i18n.t("table.ticket.object_level_5"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    getValueCallback: (rowData, onClickMultipleLocationAlert) => {
      const content = (
        <PrefixSuffixFieldTableWrapper
          prefix={
            <WarningAlertIcon
              onClick={(e) => onClickMultipleLocationAlert(e, rowData.id)}
            />
          }
        >
          {rowData.warehouse_location.length > 0 ? formatRackShellData(rowData.warehouse_location[0]) : ""}
        </PrefixSuffixFieldTableWrapper>
      );
      return rowData.is_multiple_locations
        ? content
        : rowData.warehouse_location.length > 0
          ?  formatRackShellData(rowData.warehouse_location[0])
          : "";
    },
    label: i18n.t("table.warehouse_table.warehouse_structure"),
    filterType: undefined,
  },

  {
    name: "owner",
    getValueCallback: (rowData) => rowData.owner,
    label: i18n.t("table.warehouse_table.owner"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.owners,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "warehouse_responsible",
    getValueCallback: (rowData) => rowData.warehouse_responsible,
    label: i18n.t("table.warehouse_table.warehouse_responsible"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.users,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "warehouse_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.warehouse_active),
    label: i18n.t("table.warehouse_table.warehouse_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickMultipleLocationAlert, onClickEdit) => {
      const content = (
        <ModeEditOutlineOutlined
          onClick={(e) => onClickEdit(e, rowData.id)}
        />
      );
      return rowData.warehouse_active ? content : "";
    },
    label: i18n.t("table.warehouse_table.edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }
];
