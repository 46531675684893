import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useForm } from "../../../../../hooks/useForm";
import useDialog from "../../../../../hooks/useDialog";
import { useTranslation } from "react-i18next";
import OwnerForm from "../../UniversalDetailsDrawerForms/OwnerForm";
import LoaderWrapper from "../../../../wrapper/LoaderWrapper";
import SelectDeactivateReasonDialog from "../../../../dialog/SelectDeactivateReasonDialog/SelectDeactivateReasonDialog";
import AddressDialog from "../../../../dialog/AddressDialog/AddressDialog";
import PhoneDialog from "../../../../dialog/PhoneDialog/PhoneDialog";
import MailDialog from "../../../../dialog/MailDialog";
import BoardMemberDialog from "../../../../dialog/BoardMemberDialog/BoardMemberDialog";
import useOwnerFormsService from "../../../../../hooks/useOwnerFormsService";
import BoardFormBox from "../../../../box/BoardFormBox";
import AddressFormBox from "../../../../box/AddressFormBox";
import PhoneFormBox from "../../../../box/PhoneFormBox/PhoneFormBox";
import MailFormBox from "../../../../box/MailFormBox/MailFormBox";
import OwnerHistoryLogDialog from "../../../../dialog/OwnerHistoryLogDialog/OwnerHistoryLogDialog";
import PartForm from "../../UniversalDetailsDrawerForms/PartForm";

const PartDrawerContent = (props) => {

  const { t } = useTranslation();


  if (!props.partData) {
    return <LoaderWrapper isLoading={true} />
  }


  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      paddingX={1}
      rowGap={1}
      marginTop={1}
      marginBottom={3}
    >
      <Grid item xs={12}>
        <PartForm
          partData={props.partData}
          readOnly= {props.readOnly}
          partChoiceSelectData = {props.partChoiceSelectData}
        />
      </Grid>
    </Grid>
  );
};

PartDrawerContent.propTypes = {
  partData: PropTypes.object,
  readOnly: PropTypes.bool,
};

PartDrawerContent.defaultProps = {
  readOnly: true,
};

export default PartDrawerContent;
