import { useState } from "react";
import useDialog from "./useDialog";

const useDialogWithId = (initialOpen = false, initialId = null, initialExportStatus = false) => {
  const [openDialog, handleOpenDialog, handleCloseDialog] =
    useDialog(initialOpen);
  const [id, setId] = useState(initialId);
  const [exportStatus, setExportStatus] = useState(initialExportStatus)

  const handleOpenDialogWithId = (id, exportStatus) => {
    setId(id);
    setExportStatus(exportStatus)
    handleOpenDialog();
  };

  const handleCloseDialogWithId = () => {
    setId(null);
    handleCloseDialog();
  };

  return [openDialog, id, handleOpenDialogWithId, handleCloseDialogWithId, exportStatus];
};

export default useDialogWithId;
