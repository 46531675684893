
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../context/snackbarAlert";
import { calculationScheduleUrl, createIcoUrl, defaultIcoParticipiantsUrl, defaultIcoProductUrl, earliestInterestDateUrl, icoDetailsUrl, icoFilteringDataUrl, icoRegistryListUrl, icoSelectDataUrl, relatedIcosUrl } from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import {
  prepareUrlWithQueryParams
} from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useDocsService from "./docsService";

export default function useIcoService() {
  const axiosPrivate = useAxiosPrivate();

  const { uploadFiles} = useDocsService();
  
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const getIcoRegistryList = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(icoRegistryListUrl, searchParams)
    );
  };

  const getRelatedIcos = (icoId) => {
    if (icoId) {
      return makeRequest(axiosPrivate, relatedIcosUrl(icoId));
    }
    return Promise.resolve([]);
  };

  const getIcoDetails = (icoId) => {
      return makeRequest(axiosPrivate, icoDetailsUrl(icoId));
  };


  const getIcoFilteringData = () => {
    return makeRequest(
      axiosPrivate,
      icoFilteringDataUrl
    );
  }
  const getIcoSelectData = () => {
    return makeRequest(
      axiosPrivate,
      icoSelectDataUrl
    );
  }

  const getDefaultIcoProductData = () => {
    return makeRequest(
      axiosPrivate,
      defaultIcoProductUrl
    );
  }

  const getDefaultUsersForBorrowerAndLender = (data) => {
    return makeRequest(axiosPrivate, defaultIcoParticipiantsUrl, {
      method: "POST",
      data: data,
    });
  };

  const calculateIcoSchedule = (data) => {
    return makeRequest(axiosPrivate, calculationScheduleUrl, {
      method: "POST",
      data: data,
    });
  };

  const getEarliestInterestDate = (interestId) => {
    return makeRequest(axiosPrivate, earliestInterestDateUrl(interestId));
  };

  const createNewIco = async (data) => {

    const files = data.files;
    delete data.files;

    let results = await uploadFiles(files, {});
    let uploadedData = results["uploadedData"];
    let showWaring = results["showWaring"];
    data.files = uploadedData;

    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, createIcoUrl, {
        method: "POST",
        data: data,
      })
        .then((result) => {
          resolve(result);
          if (showWaring) {
            snackbarAlert.openWarningSnackbarAlert(
              t("snackbar_alert.occurred_warning_during_upload_file")
            );
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const updateIco = (icoId, data) => {
    return makeRequest(axiosPrivate, icoDetailsUrl(icoId), {
      method: "PATCH",
      data: data,
    });
  };

  return {
    getIcoRegistryList,
    getIcoFilteringData,
    getIcoSelectData,
    getDefaultIcoProductData,
    getDefaultUsersForBorrowerAndLender,
    calculateIcoSchedule,
    getEarliestInterestDate,
    createNewIco,
    getRelatedIcos,
    getIcoDetails,
    updateIco
  };
}
