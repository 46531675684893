import React, { useCallback } from "react";
import AddButton from "../../button/AddButton";
import PropTypes from "prop-types";
import TableService from "../../../services/tableService";
import BaseTable from "../../base/BaseTable/BaseTable";
import { PO_TABLE_FIELDS_CONFIG } from "./TableConfig";

const PoSelectTable = React.memo(({ poList, readOnly, onHandleAddPo, poListContextMenuActions }) => {


  const getAddPoButton = useCallback(() => {
    if (!readOnly) {
      return (<AddButton onClick={onHandleAddPo} />)
    }
  }, [readOnly, onHandleAddPo])

  return (
    <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
      {getAddPoButton()}
      <BaseTable
        showContextMenu={!readOnly}
        contextMenuActions={poListContextMenuActions}
        showNoRecordsPaper={false}
        headers={TableService.getHeaderLables(
          PO_TABLE_FIELDS_CONFIG
        )}
        rows={TableService.getPreparedDataForBaseTable(
          PO_TABLE_FIELDS_CONFIG,
          poList
        )}
      />
    </div>
  );
})


PoSelectTable.propTypes = {
  poList: PropTypes.array,
  readOnly: PropTypes.bool,
  onHandleAddPo: PropTypes.func,
  poListContextMenuActions: PropTypes.array
};

PoSelectTable.defaultProps = {
  readOnly: false,
};

export default PoSelectTable