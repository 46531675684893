import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import TextFieldFormControl from "../../../field/TextFieldFormControl";
import i18n from "../../../../i18n";

const OfferStatusForm = ({ currentStatus }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography
          style={{ textTransform: "uppercase", color: "var(--primary)" }}
          variant="h6"
          display="block"
        >
          {i18n.t("form.offer_status_form.current_status")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          readOnly
          value={currentStatus}
          inputStyle={{ textAlign: "center", textTransform: "uppercase" }}
        />
      </Grid>
    </>
  );
};

OfferStatusForm.propTypes = {
  currentStatus: PropTypes.string,
};

OfferStatusForm.defaultProps = {};

export default OfferStatusForm;
