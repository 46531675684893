import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import React from "react";
import DateField from "../../field/DateField/DateField";
import FloatField from "../../base/FloatField/FloatField";

const TransferForm = React.memo(({ transferData,
   onChange, 
   onChangeDate,
    onChangeAutocomplete,
     lenderBankAccounts,
     borrowerBankAccounts }) => {

  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >


          <Grid item xs={12}>
            <DateField
              name="transfer_date"
              label={t("form.transfer_data_form.transfer_date")}
              value={transferData?.transfer_date}
              onChange={onChangeDate}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormControl
              name="transfer_payer_name"
              readOnly={true}
              value={transferData?.transfer_payer_name}
              label={t("form.transfer_data_form.transfer_payer_name")}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteField
              name={"transfer_payer_iban"}
              label={t("form.transfer_data_form.transfer_payer_iban")}
              value={transferData?.transfer_payer_iban}
              options={lenderBankAccounts?.value}
              isObjectOption={true}
              optionLabelKey={"iban_nr"}
              onChange={onChangeAutocomplete}
              addNewValue={false}
              multiple={false}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormControl
              name="transfer_receiver_name"
              readOnly={true}
              value={transferData?.transfer_receiver_name}
              label={t("form.transfer_data_form.transfer_receiver_name")}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteField
              name={"transfer_receiver_iban"}
              label={t("form.transfer_data_form.transfer_receiver_iban")}
              value={transferData?.transfer_receiver_iban}
              options={borrowerBankAccounts?.value}
              isObjectOption={true}
              optionLabelKey={"iban_nr"}
              onChange={onChangeAutocomplete}
              addNewValue={false}
              multiple={false}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormControl
              name="currency_name"
              readOnly={true}
              value={transferData?.currency_name}
              label={t("form.transfer_data_form.currency_name")}
            />
          </Grid>
          <Grid item xs={12}>
            <FloatField
              name="transfer_amount"
              suffix={transferData?.currency_name}
              value={transferData?.transfer_amount}
              label={t("form.transfer_data_form.transfer_amount")}
              decimalPlaces={2}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormControl
              name="transfer_title"
              value={transferData?.transfer_title}
              label={t("form.transfer_data_form.transfer_title")}
              decimalPlaces={2}
              onChange={onChange}
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs={12}>
            <DateField
              name="transfer_date_paid"
              label={t("form.transfer_data_form.transfer_date_paid")}
              value={transferData?.transfer_date_paid}
              onChange={onChangeDate}
              required
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

TransferForm.propTypes = {
  readOnly: PropTypes.bool,
  transferData: PropTypes.object,
  onChange: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeAutocomplete: PropTypes.func,
  lenderBankAccounts : PropTypes.array,
  borrowerBankAccounts : PropTypes.array
};

TransferForm.defaultProps = {
  readOnly: false,
  parentAgreementDisabled: true,
  lenderBankAccounts : [],
  borrowerBankAccounts : []
};

export default TransferForm;
