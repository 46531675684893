import PropTypes from "prop-types";
import FloatField from "../../base/FloatField/FloatField";
import { sxTextFieldTable } from "./../TextFieldTable/TextFieldTable";
import useFieldTable from "../../../hooks/useFieldTable";
import DivField from "../DivField/DivField";
import React, { useMemo } from "react";
import { FORMAT_NUMBER_SEPARATOR } from "../../../helpers/constants";
import useFloatField from "../../../hooks/useFloatField";
import useField from "../../../hooks/useField";
import { checkIfValueIsObject } from "../../../helpers/methods";
import AddIcon from "@mui/icons-material/Add";

const FloatFieldTable = React.memo((props) => {
  const { fieldTableRef, isSelected, onSelectField, onBlur } = useFieldTable();
  const onBlurLocal = (event) => {
    if (props.onBlur) {
      props.onBlur(event);
    }
    onBlur();
  };

  const { getValue } = useFloatField();

  const { getInputWidth, inputStyleTable } = useField();

  const inputStyleFloatField = useMemo(() => {
    return {
      minWidth: getInputWidth(
        getValue(props.value, props.decimalPlaces, FORMAT_NUMBER_SEPARATOR),
        false
      ),
      ...inputStyleTable,
    };
  }, [props.value, props.decimalPlaces]);

  const inputPropsFloatField = useMemo(() => {
    const inputProps = checkIfValueIsObject(props.inputProps)
      ? props.inputProps
      : {};
    if (!checkIfValueIsObject(inputProps.style)) {
      inputProps.style = {};
    }
    return {
      ...inputProps,
      style: { ...inputStyleFloatField, ...inputProps.style },
    };
  }, [inputStyleFloatField, props.inputProps]);

  return (
    <>
      {isSelected ? (

        <FloatField
          style={props.style}
          label=""
          fullWidth={props.fullWidth}
          value={props.value}
          disabled={props.disabled}
          size="small"
          name={props.name}
          onChange={props.onChange}
          readOnly={props.readOnly}
          min={props.min}
          max={props.max}
          sx={sxTextFieldTable}
          decimalPlaces={props.decimalPlaces}
          helperText={props.helperText}
          helperTextStyle={props.helperTextStyle}
          onBlur={onBlurLocal}
          inputRef={fieldTableRef}
          inputProps={inputPropsFloatField}
          allowNegative={props.allowNegative}
        />
 
      ) : (
        <DivField
          {...props}
          onSelectField={onSelectField}
          type={undefined}
          value={getValue(
            props.value,
            props.decimalPlaces,
            FORMAT_NUMBER_SEPARATOR
          )}
          onFocus={onSelectField}
        />
      )}
    </>
  );
});

FloatFieldTable.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  decimalPlaces: PropTypes.number,
  style: PropTypes.object,
  helperText: PropTypes.string,
  helperTextStyle: PropTypes.object,
  showBorder: PropTypes.bool,
  inputProps: PropTypes.object,
  allowNegative: PropTypes.bool,
  onBlur: PropTypes.func,
};

FloatFieldTable.defaultProps = {
  disabled: false,
  decimalPlaces: 2,
  helperText: "",
  helperTextStyle: {},
  allowNegative: false,
};

export default FloatFieldTable;
