import {
  getFilterSearchParamsKey,
  isDictsAreEqual,
  clearSearchParamsByFilterPrefixFn,
  clearSearchParamsByFilterPrefixesFn,
} from "../helpers/methods";

export default function useFilterSearchParams(searchParams) {
  const getSearchParamsByFilterPrefix = (filterPrefix, singleValuesArray) => {
    let filteredParams = {};
    const filterKey = getFilterSearchParamsKey(filterPrefix);
    searchParams.forEach((value, key) => {
      if (key.includes(filterKey)) {
        let newKey = key.replace(filterKey, "");
        if (filteredParams[newKey]) {
          filteredParams[newKey].push(value);
        } else {
          if (singleValuesArray && singleValuesArray.includes(newKey)) {
            filteredParams[newKey] = value;
          } else {
            filteredParams[newKey] = [value];
          }
        }
      }
    });
    return filteredParams;
  };

  const getOtherSearchParamsByFilterPrefix = (filterPrefix) => {
    let filteredParams = {};
    const filterKey = getFilterSearchParamsKey(filterPrefix);
    searchParams.forEach((value, key) => {
      if (!key.includes(filterKey)) {
        if (filteredParams[key]) {
          filteredParams[key].push(value);
        } else {
          filteredParams[key] = [value];
        }
      }
    });
    return filteredParams;
  };

  const getSearchParamsFromDictByFilterPrefix = (
    filterPrefix,
    filterDict,
    excludedFields = []
  ) => {
    let filteredParams = {};
    const filterKey = getFilterSearchParamsKey(filterPrefix);
    for (const [key, value] of Object.entries(filterDict)) {
      if (key.includes(filterKey) && !excludedFields.includes(key)) {
        let newKey = key.replace(filterKey, "");
        filteredParams[newKey] = value;
      }
    }
    return filteredParams;
  };

  const setNewItemSearchParamsIfAreChanged = (
    filterPrefix,
    params,
    setParams,
    excludedKeys
  ) => {
    let newParams = getSearchParamsByFilterPrefix(filterPrefix);
    if (!isDictsAreEqual(newParams, params, excludedKeys)) {
      setParams(newParams);
    }
  };

  const setPrefixForSearchDict = (filterPrefix, filterDict) => {
    let filteredParams = {};
    const filterKey = getFilterSearchParamsKey(filterPrefix);
    for (const [key, value] of Object.entries(filterDict)) {
      let newKey = `${filterKey}${key}`;
      filteredParams[newKey] = value;
    }
    return filteredParams;
  };

  const clearSearchParamsByFilterPrefix = (
    filterPrefix,
    excludedKeys,
    excludePageSize
  ) => {
    return clearSearchParamsByFilterPrefixFn(
      searchParams,
      filterPrefix,
      excludedKeys,
      excludePageSize
    );
  };

  const clearSearchParamsByFilterPrefixes = (filterPrefixes, excludedKeys) => {
    return clearSearchParamsByFilterPrefixesFn(
      searchParams,
      filterPrefixes,
      excludedKeys
    );
  };

  return {
    getSearchParamsByFilterPrefix,
    getSearchParamsFromDictByFilterPrefix,
    getOtherSearchParamsByFilterPrefix,
    setPrefixForSearchDict,
    setNewItemSearchParamsIfAreChanged,
    clearSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefixes,
  };
}
