import PropTypes from "prop-types";
import { changeLanguage } from "i18next";
import { Grid, IconButton } from "@mui/material";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { poland_flag, united_kingdom_flag, germany_flag } from "../../../media";
import { LANGUAGE_KEY_IN_LOCAL_STORAGE } from "../../../helpers/constants";
import "./ChooseLanguage.css";

function ChooseLanguage(props) {
  const [_, setLanguage] = useLocalStorage(LANGUAGE_KEY_IN_LOCAL_STORAGE, "pl");

  const onChangeLanguage = (language) => {
    changeLanguage(language);
    setLanguage(language);
    window.location.reload(true);
  };

  return (
    <Grid
      className={`grid-flags-${props.position}`}
      display={{ xs: "none", sm: "block" }}
    >
      <IconButton
        style={props.position === "relative" ? { paddingLeft: 0 } : {}}
        onClick={() => onChangeLanguage("pl")}
      >
        <img className="flag-img-normal" src={poland_flag} alt="" />
      </IconButton>
      <IconButton onClick={() => onChangeLanguage("en")}>
        <img className="flag-img-normal" src={united_kingdom_flag} alt="" />
      </IconButton>
      {/* <IconButton onClick={() => onChangeLanguage("de")}>
        <img className="flag-img-normal" src={germany_flag} alt="" />
      </IconButton> */}
    </Grid>
  );
}

ChooseLanguage.propTypes = {
  position: PropTypes.oneOf(["relative", "right-bottom", "right"]),
};

ChooseLanguage.defaultProps = {
  position: "relative",
};

export default ChooseLanguage;
