import InfoIcon from "@mui/icons-material/Info";
import i18n from "../../../i18n";
import { AUTOCOMPLETE_FIELD_TYPE, BUTTON_FIELD_TYPE, NUMERIC_FIELD_TYPE, SELECT_FIELD_TYPE, TEXT_FIELD_TYPE } from "../../../helpers/constants";
import PrefixSuffixFieldTableWrapper from "../../wrapper/PrefixSuffixFieldTableWrapper";
import { isEmptyValue } from "../../../helpers/methods";
import SearchIcon from '@mui/icons-material/Search';
import { Tooltip } from "chart.js";

export const OFFER_SERVICES_TABLE_NAME = "offer_services";

export const OFFER_SERVICES_TABLE_FIELDS_CONFIG = [
  {
    name: "item_identifactor",
    getValueCallback: (rowData) => rowData.item_identifactor,
    label: i18n.t("table.offer_services.item_identifactor"),
    dataType: TEXT_FIELD_TYPE,
    editable: false,
  },
  {
    name: "selectTree",
    getValueCallback: (rowData, index, onOpenServiceTree, onHasCommentToService) => {
      const content = (
        <PrefixSuffixFieldTableWrapper
          prefix={
            <SearchIcon
              onClick={(e) => onOpenServiceTree(index)}
            />
          }
        >
        </PrefixSuffixFieldTableWrapper>
      );
      return !rowData.read_only
        ? content
        : "";
    },
    label: "",
    dataType: BUTTON_FIELD_TYPE,
    editable: true,
    style: { borderRight: "hidden" }
  },
  {
    name: "service",
    getValueCallback: (rowData) => rowData.service,
    label: i18n.t("table.offer_services.service"),
    editable: true,
    required: true,
    dataType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.services,
      isObjectOption: true,
      optionLabelKey: "service_name",
      addNewValue: false,
    }),
  },
  {
    name: "seof_name",
    getValueCallback: (rowData) => rowData.seof_name,
    label: i18n.t("table.offer_services.service_name"),
    dataType: TEXT_FIELD_TYPE,
    editable: true,
    required: true
  },
  {
    name: "comments",
    getValueCallback: (rowData, index, onOpenServiceTree, onHasCommentToService) => {
      const content = (
        <PrefixSuffixFieldTableWrapper
          prefix={
            <InfoIcon
              onClick={(e) => onHasCommentToService(index)}
            />
          }
        >
        </PrefixSuffixFieldTableWrapper>
      );
      return !isEmptyValue(rowData.seof_note)
        ? content
        : "";
    },
    label: "",
    dataType: BUTTON_FIELD_TYPE,
    editable: true,
    style: {
      borderLeft: "hidden",
      minWidth: "initial"
    }
  },
  {
    name: "seof_suplier",
    getValueCallback: (rowData) => rowData.seof_suplier,
    label: i18n.t("table.offer_services.service_suplier"),
    editable: true,
    required: true,
    dataType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.subcontractors,
      isObjectOption: true,
      optionLabelKey: "subcontractor_short_name",
      addNewValue: false,
      showExternalButton: false,
    }),
  },
  {
    name: "account",
    getValueCallback: (rowData) => rowData.account,
    label: i18n.t("table.offer_services.account"),
    editable: true,
    required: true,
    dataType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.account,
      isObjectOption: true,
      optionLabelKey: "account_name",
      addNewValue: false,
      showExternalButton: false,
    }),
  },
  {
    name: "gtu",
    getValueCallback: (rowData) => rowData.gtu,
    label: i18n.t("table.offer_services.gtu"),
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "seof_quantity",
    getValueCallback: (rowData) => rowData.seof_quantity,
    label: i18n.t("table.offer_services.quantity"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true,
    required: true,
  },
  {
    name: "service_unit",
    getValueCallback: (rowData) =>rowData.service_unit ? i18n.t(`other.units.${rowData.service_unit}`) : "",
    label: i18n.t("table.offer_services.unit"),
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "seof_price",
    getValueCallback: (rowData) => rowData.seof_price,
    label: i18n.t("table.offer_services.price"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true,
  },
  {
    name: "seof_quotation",
    getValueCallback: (rowData) => rowData.seof_quotation,
    label: i18n.t("table.offer_services.price_total"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "seof_margin_proc",
    getValueCallback: (rowData) => rowData.seof_margin_proc,
    label: i18n.t("table.offer_services.margin_proc"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true,
  },
  {
    name: "seof_margin_pln",
    getValueCallback: (rowData) => rowData.seof_margin_pln,
    label: i18n.t("table.offer_services.unit_margin_pln"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true,
  },
  {
    name: "seof_quotation_margin",
    getValueCallback: (rowData) => rowData.seof_quotation_margin,
    label: i18n.t("table.offer_services.margin_pln"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: false,
  },
  {
    name: "seof_discount_proc",
    getValueCallback: (rowData) => rowData.seof_discount_proc,
    label: i18n.t("table.offer_services.discount_proc"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true,
  },
  {
    name: "seof_discount_pln",
    getValueCallback: (rowData) => rowData.seof_discount_pln,
    label: i18n.t("table.offer_services.unit_discount_pln"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true,
  },
  {
    name: "seof_quotation_discount",
    getValueCallback: (rowData) => rowData.seof_quotation_discount,
    label: i18n.t("table.offer_services.discount_pln"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: false,
  },
  {
    name: "seof_quotation_net",
    getValueCallback: (rowData) => rowData.seof_quotation_net,
    label: i18n.t("table.offer_services.quotation"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "seof_vat_proc",
    getValueCallback: (rowData) => rowData.seof_vat_proc,
    label: i18n.t("table.offer_services.vat_rate"),
    editable: false,
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "seof_quotation_vat",
    getValueCallback: (rowData) => rowData.seof_quotation_vat,
    label: i18n.t("table.offer_services.quotation_vat"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: false,
  },
  {
    name: "seof_quotation_gross",
    getValueCallback: (rowData) => rowData.seof_quotation_gross,
    label: i18n.t("table.offer_services.quotation_gross"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: false,
  },
];

export const OFFER_PARTS_TABLE_NAME = "offer_parts";

export const OFFER_PARTS_TABLE_FIELDS_CONFIG = [
  {
    name: "item_identifactor",
    getValueCallback: (rowData) => rowData.item_identifactor,
    label: i18n.t("table.offer_parts.item_identifactor"),
    dataType: TEXT_FIELD_TYPE,
    editable: false,
  },
  {
    name: "selectTree",
    getValueCallback: (rowData, index, onOpenPartTree, onHasCommentToService) => {
      const content = (
        <PrefixSuffixFieldTableWrapper
          prefix={
            <SearchIcon
              onClick={(e) => onOpenPartTree(index)}
            />
          }
        >
        </PrefixSuffixFieldTableWrapper>
      );
      return !rowData.read_only
        ? content
        : "";
    },
    label: "",
    dataType: BUTTON_FIELD_TYPE,
    editable: true,
    style: { borderRight: "hidden" }
  },
  {
    name: "part",
    getValueCallback: (rowData) => rowData.part,
    label: i18n.t("table.offer_parts.part"),
    editable: true,
    required: true,
    dataType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.parts,
      isObjectOption: true,
      optionLabelKey: "part_name",
      addNewValue: false,
    }),
  },
  {
    name: "paof_name",
    getValueCallback: (rowData) => rowData.paof_name,
    label: i18n.t("table.offer_parts.part_name"),
    dataType: TEXT_FIELD_TYPE,
    editable: true,
    required: true
  },
  {
    name: "comments",
    getValueCallback: (rowData, index, onOpenPartTree, onHasCommentToPart) => {
      const content = (
        <PrefixSuffixFieldTableWrapper
          prefix={
            <InfoIcon
              onClick={(e) => onHasCommentToPart(index)}
            />
          }
        >
        </PrefixSuffixFieldTableWrapper>
      );
      return !isEmptyValue(rowData.paof_note)
        ? content
        : "";
    },
    label: "",
    dataType: BUTTON_FIELD_TYPE,
    editable: true,
    style: {
      borderLeft: "hidden",
      minWidth: "initial"
    }
  },
  {
    name: "part_suplier",
    getValueCallback: (rowData) => rowData.part_suplier,
    label: i18n.t("table.offer_parts.part_producer"),
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "account",
    getValueCallback: (rowData) => rowData.account,
    label: i18n.t("table.offer_services.account"),
    editable: true,
    required: true,
    dataType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.account,
      isObjectOption: true,
      optionLabelKey: "account_name",
      addNewValue: false,
      showExternalButton: false,
    }),
  },
  {
    name: "gtu",
    getValueCallback: (rowData) => rowData.gtu,
    label: i18n.t("table.offer_services.gtu"),
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "paof_quantity",
    getValueCallback: (rowData) => rowData.paof_quantity,
    label: i18n.t("table.offer_services.quantity"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true,
    required: true,
  },
  {
    name: "part_unit",
    getValueCallback: (rowData) => rowData.part_unit ? i18n.t(`other.units.${rowData.part_unit}`) : "",
    label: i18n.t("table.offer_services.unit"),
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "paof_price",
    getValueCallback: (rowData) => rowData.paof_price,
    label: i18n.t("table.offer_services.price"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true,
  },
  {
    name: "paof_quotation",
    getValueCallback: (rowData) => rowData.paof_quotation,
    label: i18n.t("table.offer_services.price_total"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "paof_margin_proc",
    getValueCallback: (rowData) => rowData.paof_margin_proc,
    label: i18n.t("table.offer_services.margin_proc"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true,
  },
  {
    name: "paof_margin_pln",
    getValueCallback: (rowData) => rowData.paof_margin_pln,
    label: i18n.t("table.offer_services.unit_margin_pln"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true,
  },
  {
    name: "paof_quotation_margin",
    getValueCallback: (rowData) => rowData.paof_quotation_margin,
    label: i18n.t("table.offer_services.margin_pln"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: false,
  },
  {
    name: "paof_discount_proc",
    getValueCallback: (rowData) => rowData.paof_discount_proc,
    label: i18n.t("table.offer_services.discount_proc"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true,
  },
  {
    name: "paof_discount_pln",
    getValueCallback: (rowData) => rowData.paof_discount_pln,
    label: i18n.t("table.offer_services.unit_discount_pln"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true,
  },
  {
    name: "paof_quotation_discount",
    getValueCallback: (rowData) => rowData.paof_quotation_discount,
    label: i18n.t("table.offer_services.discount_pln"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: false,
  },
  {
    name: "paof_quotation_net",
    getValueCallback: (rowData) => rowData.paof_quotation_net,
    label: i18n.t("table.offer_services.quotation"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "paof_vat_proc",
    getValueCallback: (rowData) => rowData.paof_vat_proc,
    label: i18n.t("table.offer_services.vat_rate"),
    editable: false,
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "paof_quotation_vat",
    getValueCallback: (rowData) => rowData.paof_quotation_vat,
    label: i18n.t("table.offer_services.quotation_vat"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: false,
  },
  {
    name: "paof_quotation_gross",
    getValueCallback: (rowData) => rowData.paof_quotation_gross,
    label: i18n.t("table.offer_services.quotation_gross"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: false,
  },
];


export const OFFER_ITEM_SUMMARY_TABLE_FIELDS_CONFIG = [
  {
    name: "items_quotation",
    getValueCallback: (rowData) => rowData.items_quotation,
    label: i18n.t("table.offer_items.cost"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "items_margin_proc",
    getValueCallback: (rowData) => rowData.items_margin_proc,
    label: i18n.t("table.offer_services.margin_proc"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "items_quotation_margin",
    getValueCallback: (rowData) => rowData.items_quotation_margin,
    label: i18n.t("table.offer_services.margin_pln"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "items_discount_proc",
    getValueCallback: (rowData) => rowData.items_discount_proc,
    label: i18n.t("table.offer_services.discount_proc"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "items_quotation_discount",
    getValueCallback: (rowData) => rowData.items_quotation_discount,
    label: i18n.t("table.offer_services.discount_pln"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "items_quotation_net",
    getValueCallback: (rowData) => rowData.items_quotation_net,
    label: i18n.t("table.offer_items.netto_cost_to_pay"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "items_quotation_vat",
    getValueCallback: (rowData) => rowData.items_quotation_vat,
    label: i18n.t("table.offer_items.vat_cost_to_pay"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "items_quotation_gross",
    getValueCallback: (rowData) => rowData.items_quotation_gross,
    label: i18n.t("table.offer_items.gross_cost_to_pay"),
    dataType: NUMERIC_FIELD_TYPE,
  },
];
export const OFFER_CLAUSE_TABLE_FIELDS_CONFIG = [
  {
    name: "clause_name",
    getValueCallback: (rowData) => rowData.clause_name,
    label: i18n.t("table.clause_table.clause_name"),
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "clause_text",
    getValueCallback: (rowData) => rowData.clause_text,
    label: i18n.t("table.clause_table.clause_text"),
    dataType: TEXT_FIELD_TYPE,
  },
];