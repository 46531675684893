import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import AutocompleteField from "../../field/AutocompleteField";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import FloatField from "../../base/FloatField/FloatField";
import FieldWithLabel from "../../field/FieldWithLabel";
import DateField from "../../field/DateField/DateField";

import DateService from "../../../services/dateService";

import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import AddButton from "@mui/icons-material/Add";

const GuaranteeForm = (props) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      columnSpacing={6}
      spacing={1}
    >
      <Grid item xs={12} sm={6}>
        <FieldWithLabel
          label={t("form.guarantee.owner")}
          labelSm={4.2}
          fieldSm={7.8}
          required
          field={
            <AutocompleteField
              name="owner"
              value={props.formValue.owner}
              options={props.filteringData.owner}
              isObjectOption={true}
              optionLabelKey={"owner_full_name"}
              onChange={props.onChangeAutocompleteFieldWithObjectOptions}
              disableClearable
              readOnly={props.readOnly}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FieldWithLabel
          label={t("form.guarantee.subcontractor")}
          labelSm={2.5}
          fieldSm={9.5}
          required
          field={
            <Grid container>
              <Grid item xs={props.readOnly ? 12 : 11}>
                <AutocompleteField
                  name="subcontractor"
                  value={props.formValue.subcontractor}
                  options={props.filteringData.subcontractor}
                  isObjectOption={true}
                  optionLabelKey={"subcontractor_short_name"}
                  onChange={props.onChangeAutocompleteFieldWithObjectOptions}
                  disableClearable
                  readOnly={props.readOnly}
                  required
                />
              </Grid>
              {!props.readOnly && (
                <Grid item xs={1} style={{ paddingLeft: "0" }}>
                  <IconButtonWithTooltip
                    style={{ border: "3px solid var(--secondary)" }}
                    title={t(`form.guarantee.add_subcontractor`)}
                    onClick={props.onOpenSubcontractorDialog}
                  >
                    <AddButton
                      fontSize="medium"
                      sx={{
                        color: "var(--secondary)",
                      }}
                    />
                  </IconButtonWithTooltip>
                </Grid>
              )}
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FieldWithLabel
          label={t("form.guarantee.guarantee_kind")}
          labelSm={4.2}
          fieldSm={7.8}
          required
          field={
            <AutocompleteField
              name="guarantee_kind"
              value={props.formValue.guarantee_kind}
              onChange={props.onChange}
              disableClearable
              isObjectOption={false}
              options={props.filteringData.guarantee_kind}
              readOnly={props.readOnly}
              required
            />
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FieldWithLabel
          label={t("form.guarantee.guarantee_sector")}
          labelSm={2.5}
          fieldSm={9.5}
          required
          field={
            <AutocompleteField
              name="guarantee_sector"
              value={props.formValue.guarantee_sector}
              options={props.filteringData.guarantee_sector}
              isObjectOption={false}
              onChange={props.onChange}
              disableClearable
              readOnly={props.readOnly}
              required
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FieldWithLabel
          label={t("form.guarantee.guarantee_value")}
          labelSm={4.2}
          fieldSm={7.8}
          required
          field={
            <FloatField
              name={"guarantee_value"}
              value={props.formValue.guarantee_value}
              readOnly={props.readOnly}
              onChange={props.onChange}
              decimalPlaces={2}
              required
            />
          }
        />
      </Grid>
      <Grid item xs={6} display={{ xs: null, sm: "flex" }} />
      <Grid item xs={12} sm={6}>
        <FieldWithLabel
          label={t("form.guarantee.guarantee_date_contract")}
          labelSm={4.2}
          fieldSm={7.8}
          required
          field={
            <DateField
              name={"guarantee_date_contract"}
              value={DateService.convertDateToFormatYYYYMMDD(
                props.formValue.guarantee_date_contract
              )}
              showClearIcon={!props.readOnly}
              readOnly={props.readOnly}
              onChange={props.onChangeDate}
              required
            />
          }
        />
      </Grid>
      <Grid item xs={6} display={{ xs: null, sm: "flex" }} />
      <Grid item xs={12} sm={6}>
        <FieldWithLabel
          label={t("form.guarantee.guarantee_date_protocol")}
          labelSm={4.2}
          fieldSm={7.8}
          required
          field={
            <DateField
              name={"guarantee_date_protocol"}
              value={DateService.convertDateToFormatYYYYMMDD(
                props.formValue.guarantee_date_protocol
              )}
              readOnly={props.readOnly}
              showClearIcon={!props.readOnly}
              onChange={props.onChangeDate}
              required
            />
          }
        />
      </Grid>
      <Grid item xs={6} display={{ xs: null, sm: "flex" }} />
      <Grid item xs={12} sm={6}>
        <FieldWithLabel
          label={t("form.guarantee.guarantee_response_time")}
          labelSm={4.2}
          fieldSm={7.8}
          field={
            <TextFieldFormControl
              name={"guarantee_response_time"}
              value={props.formValue.guarantee_response_time}
              disabled={props.readOnly}
              onChange={props.onChange}
              multiline
              rows={1}
            />
          }
        />
      </Grid>
      <Grid item xs={6} display={{ xs: null, sm: "flex" }} />
      <Grid item xs={12} sm={6}>
        <FieldWithLabel
          label={t("form.guarantee.guarantee_realisation_time")}
          labelSm={4.2}
          fieldSm={7.8}
          field={
            <TextFieldFormControl
              name={"guarantee_realisation_time"}
              value={props.formValue.guarantee_realisation_time}
              disabled={props.readOnly}
              onChange={props.onChange}
              multiline
              rows={1}
            />
          }
        />
      </Grid>
      <Grid item xs={6} display={{ xs: null, sm: "flex" }} />
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.guarantee.guarantee_scope_installation")}
          labelSm={2}
          fieldSm={10}
          required
          field={
            <TextFieldFormControl
              name={"guarantee_scope_installation"}
              value={props.formValue.guarantee_scope_installation}
              disabled={props.readOnly}
              onChange={props.onChange}
              multiline
              rows={2}
              required
            />
          }
        />
      </Grid>

      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.guarantee.guarantee_warranty")}
          labelSm={2}
          fieldSm={10}
          required
          field={
            <TextFieldFormControl
              name={"guarantee_warranty"}
              value={props.formValue.guarantee_warranty}
              disabled={props.readOnly}
              onChange={props.onChange}
              multiline
              rows={2}
              required
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.guarantee.guarantee_pledge")}
          labelSm={2}
          fieldSm={10}
          field={
            <TextFieldFormControl
              name={"guarantee_pledge"}
              value={props.formValue.guarantee_pledge}
              disabled={props.readOnly}
              onChange={props.onChange}
              multiline
              rows={2}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.guarantee.guarantee_note")}
          labelSm={2}
          fieldSm={10}
          field={
            <TextFieldFormControl
              name={"guarantee_note"}
              value={props.formValue.guarantee_note}
              disabled={props.readOnly}
              onChange={props.onChange}
              multiline
              rows={2}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

GuaranteeForm.propTypes = {
  formValue: PropTypes.object,
  filteringData: PropTypes.object,
  onChange: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  onOpenSubcontractorDialog: PropTypes.func,
  readOnly: PropTypes.bool,
};

GuaranteeForm.defaultProps = {
  readOnly: false,
};

export default GuaranteeForm;
