import React from "react";
import PropTypes from "prop-types";

import i18n from "../../../i18n";

import {
  TEXT_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
} from "../../../helpers/constants";

import { useAsync } from "../../../hooks/useAsync";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import CollapsibleTable from "../CollapsibleTable";
import TableService from "../../../services/tableService";
import EnclosuresWindow from "../../window/EnclosuresWindow";
import useFileService from "../../../services/fileService";

export const TICKET_TABLE_FIELDS_CONFIG = [
  {
    name: "ticket_nr",
    getValueCallback: (rowData) => rowData.ticket_nr,
    label: i18n.t("table.ticket.ticket_nr"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "ticket_name",
    getValueCallback: (rowData) => rowData.ticket_name,
    label: i18n.t("table.ticket.ticket_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.ticket.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_2",
    getValueCallback: (rowData) => rowData.locations[0].level_2,
    label: i18n.t("table.ticket.object_level_2"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.objects_level_2,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_3",
    getValueCallback: (rowData) => rowData.locations[0].level_3,
    label: i18n.t("table.ticket.object_level_3"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.objects_level_3,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
];

const InnerEnclosureWindow = React.memo(
  ({ id, onDownload, onPreview, onCopy }) => {
    const { getEnclosuresForItemData } = useFileService();
    const enclosuresData = useAsync(
      () => getEnclosuresForItemData(id, {}, "ticket_order"),
      [id]
    );

    return (
      <LoaderWrapper showLoader={enclosuresData.loading}>
        {enclosuresData.value && (
          <EnclosuresWindow
            enclosures={enclosuresData.value}
            showEnclosureType={false}
            showSubcontractorSelect={false}
            enclosureWindowStyle={{ height: "auto" }}
            onDownload={onDownload}
            onPreview={onPreview}
            onCopy={onCopy}
            isLoading={enclosuresData.loading}
            readOnly={true}
            showAddToFavorite={false}
            showPublishButton={false}
          />
        )}
      </LoaderWrapper>
    );
  }
);

const EnclosureCollapsibleTable = React.memo((props) => {
  const data = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        TICKET_TABLE_FIELDS_CONFIG,
        props.data,
        props.hiddenColumns,
        props.columnsOrders,
        props.onClickMultipleLocationAlert
      ),
    [props.data, props.hiddenColumns, props.columnsOrders]
  );
  return (
    <CollapsibleTable
      showCheckbox={false}
      onClickRow={props.onClickRow}
      onClickCheck={props.onClickCheck}
      onClickCheckAll={props.onClickCheckAll}
      data={data}
      filteringData={props.filteringData}
      countRecords={props.countRecords}
      page={props.page}
      onPageChange={props.onPageChange}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      rowsPerPageOptions={props.rowsPerPageOptions}
      resetPageNumber={props.resetPageNumber}
      headersConfig={TableService.getHeadersConfigForCollapsibleTable(
        TICKET_TABLE_FIELDS_CONFIG,
        props.filteringData,
        props.hiddenColumns,
        props.columnsOrders
      )}
      CollapsibleElement={
        <InnerEnclosureWindow
          onDownload={props.onDownload}
          onPreview={props.onPreview}
          onCopy={props.onCopy}
        />
      }
      selectedItemId={props.selectedTicketId}
      style={props.style}
    />
  );
});

EnclosureCollapsibleTable.propTypes = {
  onClickRow: PropTypes.func,
  data: PropTypes.array,
  filteringData: PropTypes.object,
  countRecords: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  resetPageNumber: PropTypes.func,
  hiddenColumns: PropTypes.array,
  columnsOrders: PropTypes.array,
  selectedTicketId: PropTypes.string,
  style: PropTypes.object,
  onPreview: PropTypes.func,
  onDownload: PropTypes.func,
  onCopy: PropTypes.func,
};

EnclosureCollapsibleTable.defaultProps = {
  hiddenColumns: [],
};

export default EnclosureCollapsibleTable;
