
import { AUTOCOMPLETE_FIELD_TYPE, BOOLEAN_FIELD_TYPE, DATE_RANGE_FIELD_TYPE, TEXT_FIELD_TYPE } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import DateService from "../../../../services/dateService";
import SelectFieldService from "../../../../services/selectFieldService";


export const POST_TABLE_NAME = "correspondence";
export const POST_TABLE_FILTER_PREFIX = "correspondence_post_out";
export const POST_OUT_TABLE_CONFIG = [
  {
    name: "post_nr",
    getValueCallback: (rowData) => rowData.post_nr,
    label: i18n.t("table.correspondence.post_nr"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: true,
    sortable: true,
  },
  {
    name: "post_subcat_category",
    getValueCallback: (rowData) => rowData.post_subcat_category,
    label: i18n.t("table.correspondence.shipment_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.post_subcat_category,
      isObjectOption: true,
      optionLabelKey: "post_cat_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "post_subcat",
    getValueCallback: (rowData) => rowData.post_subcat,
    label: i18n.t("table.correspondence.post_subcat_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.post_subcat,
      isObjectOption: true,
      optionLabelKey: "post_subcat_name",
      addNewValue: false,
      multiple: true,
      sortable: true,
    }),
  },
  {
    name: "post_title",
    getValueCallback: (rowData) => rowData.post_title,
    label: i18n.t("table.correspondence.post_title"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "post_decree",
    getValueCallback: (rowData) => rowData.post_decree,
    label: i18n.t("table.correspondence.document_nr"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "post_parent",
    getValueCallback: (rowData) => rowData.post_parent,
    label: i18n.t("table.correspondence.reference"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.post_parent,
      isObjectOption: true,
      optionLabelKey: "post_title",
      addNewValue: false,
      multiple: true,
      sortable: true,
    }),
  },
  {
    name: "post_date_in",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.post_date_in
      ),
      label: i18n.t("table.correspondence.delivery_date"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "post_owner",
    getValueCallback: (rowData) => rowData.post_owner,
    label: i18n.t("table.correspondence.owner_short_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.post_owner,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "post_side_type",
    getValueCallback: (rowData) => rowData.post_side_type,
    label: i18n.t("table.correspondence.recipient_type"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.post_side_type,
      isObjectOption: true,
      optionLabelKey: "side_type_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "post_side_name",
    getValueCallback: (rowData) => rowData.post_side_name,
    label: i18n.t("table.correspondence.recipient_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "post_side_address",
    getValueCallback: (rowData) => rowData.post_side_address,
    label: i18n.t("table.correspondence.recipient_address"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "post_delivery",
    getValueCallback: (rowData) => rowData.post_delivery,
    label: i18n.t("table.correspondence.delivery_type"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.post_delivery,
      isObjectOption: true,
      optionLabelKey: "delivery_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "post_email_subject",
    getValueCallback: (rowData) => rowData.post_email_subject,
    label: i18n.t("table.correspondence.post_email_subject"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "post_zpo",
    getValueCallback: (rowData) =>
    SelectFieldService.getLabelOptionsTrueOrFalse(rowData.post_zpo),
    label: i18n.t("table.correspondence.ZPO"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "post_event_date",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.post_event_date
      ),
      label: i18n.t("table.correspondence.deadline"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "post_person",
    getValueCallback: (rowData) => rowData.post_person,
    label: i18n.t("table.correspondence.sender_person"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.users_list,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "post_note",
    getValueCallback: (rowData) => rowData.post_note,
    label: i18n.t("table.correspondence.note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "postrec_info_date",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.postrec_info_date
      ),
      label: i18n.t("table.correspondence.notification_date"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "registration_date",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.registration_date
      ),
      label: i18n.t("table.correspondence.registration_date"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "post_archives_location",
    getValueCallback: (rowData) => rowData.post_archives_location,
    label: i18n.t("table.correspondence.archiving_place"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.post_archives_location,
      isObjectOption: true,
      optionLabelKey: "post_arch_name",
      addNewValue: false,
      multiple: true,
      sortable: true,
    }),
  },
  {
    name: "post_status",
    getValueCallback: (rowData) => rowData.post_status,
    label: i18n.t("table.correspondence.status"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
]
 