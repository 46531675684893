
import i18n from "../../../i18n";
import DateService from "../../../services/dateService";


export const GUARANTEE_AGREEMENT_TABLE_FIELDS_CONFIG = [

  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.guarantee_agreements_table.agreement_tenant"),
  },
  {
    name: "guarantee_category",
    getValueCallback: (rowData) => rowData.guarantee_category,
    label: i18n.t("table.guarantee_agreements_table.guarantee_category"),

  },
  {
    name: "guarantee_date_contract",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.guarantee_date_contract),
    label: i18n.t("table.guarantee_agreements_table.guarantee_date_contract"),
  },
  {
    name: "guarantee_value",
    getValueCallback: (rowData) => rowData.guarantee_value,
    label: i18n.t("table.guarantee_agreements_table.guarantee_value"),

  },
  {
    name: "guarantee_validity",
    getValueCallback: (rowData) => rowData.guarantee_validity,
    label: i18n.t("table.guarantee_agreements_table.guarantee_validity"),

  },
  {
    name: "guarantee_date_close",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.guarantee_date_close),
    label: i18n.t("table.guarantee_agreements_table.guarantee_date_close"),
  },
];
