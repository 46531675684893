import React from "react";
import { Component } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PropTypes from "prop-types";
import { FormHelperText } from "@mui/material";
import { isEmptyValue } from "../../../helpers/methods";

class PasswordField extends Component {
  static get propTypes() {
    return {
      onChange: PropTypes.func,
      value: PropTypes.string,
      name: PropTypes.string,
      style: PropTypes.object,
      label: PropTypes.string,
      disabled: PropTypes.bool,
    };
  }

  state = {
    showPassword: false,
  };

  changeShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    return (
      <FormControl
        fullWidth
        size="small"
        style={this.props.style}
        sx={{ m: 0 }}
        variant="outlined"
      >
        <InputLabel
          htmlFor="outlined-adornment-password"
          className="text-input-label"
          error={this.props.error}
        >
          {this.props.label}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={this.state.showPassword ? "text" : "password"}
          value={this.props.value}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          endAdornment={
            !this.props.disabled ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.changeShowPassword}
                  edge="end"
                >
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ) : null
          }
          label={this.props.label}
          name={this.props.name}
          error={this.props.error}
        />
        {!isEmptyValue(this.props.helperText) &&
          <FormHelperText error={this.props.error}>
            {this.props.helperText}
          </FormHelperText>
        }
      </FormControl>
    );
  }
}

export default PasswordField;
