import { useState, useEffect, useMemo, useRef, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { NAVIGATION_DRAWER_TYPE_OFFERS, NAVIGATION_DRAWER_TYPE_RR } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import useTicketService from "../../services/ticketService";
import { useAsync } from "../../hooks/useAsync";

import RRPanelToolBar from "../RRPanelPage/RRPanelToolBar";
import TicketTable from "../../components/table/TicketTable";
import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";
import PieChart from "../../components/charts/PieChart/PieChart";
import OpenCloseFilterButton from "../../components/button/OpenCloseFilterButton";

import TableService from "../../services/tableService";

import MultipleLocalizationDialog from "../../components/dialog/MutlipleLocalizationDialog/MultipleLocalizationDialog";
import useDialog from "../../hooks/useDialog";

import { exportTicketsSlaToFileUrl } from "../../helpers/apiUrls";
import { BOOLEAN_FIELD_TYPE } from "../../helpers/constants";
import { getObjectFromSearchParams } from "../../helpers/methods";

import {
  TICKET_SLA_TABLE_FIELDS_CONFIG,
  TICKET_SLA_TABLE_NAME,
} from "../RRPanelPage/TableConfig";

const TABLE_CONFIGS = [
  { name: TICKET_SLA_TABLE_NAME, config: TICKET_SLA_TABLE_FIELDS_CONFIG },
];

export default function RRPanelComplainsPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams(undefined, undefined, 100);

  const { getTicketsWithSlaClaimDistribution, getTicketSlaFilteringData } =
    useTicketService();

  const ticketSlaData = useAsync(() => {
    let tempSearchParams = {
      ...getObjectFromSearchParams(searchParams),
    };
    tempSearchParams.page_size = tempSearchParams.page_size || 100;
    return getTicketsWithSlaClaimDistribution(tempSearchParams);
  }, [searchParams]);

  const ticketSlaFilteringData = useAsync(getTicketSlaFilteringData);

  const [ticketSlaDataLocaly, setTicketSlaDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  const [slaClaimsDistribution, setSlaClaimsDistribution] = useState(undefined);

  useEffect(() => {
    if (ticketSlaData.loading) {
      return;
    }
    setTicketSlaDataLocaly(ticketSlaData.value.results);
    setCountRecords(ticketSlaData.value.count);
    setSlaClaimsDistribution(ticketSlaData.value.claims_distribution);
  }, [ticketSlaData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setTicketSlaDataLocaly(undefined);
    setSlaClaimsDistribution(undefined);
    ticketSlaData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setTicketSlaDataLocaly(undefined);
    setSlaClaimsDistribution(undefined);
    setCountRecords(undefined);
  };

  const ticketSlaExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      TICKET_SLA_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[TICKET_SLA_TABLE_NAME],
      columnsOrdersForTables?.[TICKET_SLA_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[TICKET_SLA_TABLE_NAME],
    columnsOrdersForTables?.[TICKET_SLA_TABLE_NAME],
    TICKET_SLA_TABLE_FIELDS_CONFIG,
  ]);

  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();
  const clickedTicketMultipleLocalization = useRef();

  const handleOpenMultipleLocalizationDialog = useCallback(
    (e, ticketId) => {
      e.stopPropagation();
      clickedTicketMultipleLocalization.current = ticketSlaDataLocaly.find(
        (ticket) => ticket.id === ticketId
      ).locations;
      onOpenMultipleLocalizationDialog();
    },
    [onOpenMultipleLocalizationDialog, ticketSlaDataLocaly]
  );

  const handleCloseMultipleLocalizationDialog = () => {
    clickedTicketMultipleLocalization.current = null;
    onCloseMultipleLocalizationDialog();
  };

  const isLoading =
    ticketSlaDataLocaly === undefined ||
    slaClaimsDistribution === undefined ||
    ticketSlaFilteringData.loading;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_RR}
    >
      <DefaultPageWrapper titleKey={"rr_panel_complains"}>
        <LoaderWrapper showLoader={isLoading}>
          <Grid
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={1}
          >
            <Grid item xs={12}>
              <RRPanelToolBar />
            </Grid>
            {slaClaimsDistribution && (
              <Grid item xs={12} style={{ height: "50vh" }}>
                <PieChart
                  data={{
                    datasets: [
                      {
                        data: Object.values(slaClaimsDistribution).map(
                          (ticketType) => ticketType.count
                        ),
                        backgroundColor: Object.values(
                          slaClaimsDistribution
                        ).map((ticketType) => ticketType.color),
                      },
                    ],
                    labels: Object.keys(slaClaimsDistribution).map(
                      (ticketType) => t(`page.rr_panel_page.${ticketType}`)
                    ),
                  }}
                  legendDisplay={false}
                  dataLabelsUnit={t("page.rr_panel_page.ticket_count")}
                  titleDisplay={false}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <UniversalToolBarWithDialogs
                pageName={props.pageName}
                tableConfig={TABLE_CONFIGS}
                filteringData={ticketSlaFilteringData}
                refreshTable={refreshTable}
                onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
                resetPageNumber={resetPageNumber}
                hiddenColumnsForTables={hiddenColumnsForTables}
                setHiddenColumnsForTables={setHiddenColumnsForTables}
                columnsOrdersForTables={columnsOrdersForTables}
                setColumnsOrdersForTables={setColumnsOrdersForTables}
                mdGridExtraButton={2.5}
                extraButtonList={[
                  <OpenCloseFilterButton
                    openCloseFilterLabel={t(
                      "page.feedback_management_page.tenant_rr_all"
                    )}
                    closeFilterLabel={t(
                      "page.feedback_management_page.tenat_rr_not_visibility"
                    )}
                    openFilterLabel={t(
                      "page.feedback_management_page.tenat_rr_visibility"
                    )}
                    openFilterKey={"tenant_rr_visibility"}
                  />,
                ]}
                extraFilterOptions={{
                  includeExtraFilter: true,
                  extraFilterKey: "tenant_rr_visibility",
                  extraFilterName: t(
                    "page.feedback_management_page.tenat_rr_visibility"
                  ),
                  extraFilterType: BOOLEAN_FIELD_TYPE,
                  extraFilterFieldConfig: {
                    valuesAsBool: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {hiddenColumnsForTables && columnsOrdersForTables && (
                <TicketTable
                  showCleanFilterIcon={true}
                  showFilters={true}
                  showDetailsIcon={true}
                  data={ticketSlaDataLocaly}
                  filteringData={ticketSlaFilteringData}
                  countRecords={countRecords}
                  page={page}
                  onPageChange={handleChangePageWithSearchParams}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  tableConfig={TICKET_SLA_TABLE_FIELDS_CONFIG}
                  hiddenColumns={hiddenColumnsForTables[TICKET_SLA_TABLE_NAME]}
                  columnsOrders={columnsOrdersForTables[TICKET_SLA_TABLE_NAME]}
                  style={{ maxHeight: "75vh" }}
                  showExportToFileButton={true}
                  exportToFileUrl={exportTicketsSlaToFileUrl}
                  exportToFileSearchParams={searchParams}
                  exportToFileHeaders={ticketSlaExportHeaders}
                  exportToFileFileName={`${t(
                    "dialog.ticket_sla_dialog.tickets"
                  ).replace(" ", "_")}.xlsx`}
                  initialExpandedFilterHeader={true}
                  itemDetailsLinkPrefix={"ticket_details"}
                  onClickMultipleLocationAlert={
                    handleOpenMultipleLocalizationDialog
                  }
                />
              )}
            </Grid>
            {openMultipleLocalizationDialog &&
              clickedTicketMultipleLocalization.current && (
                <MultipleLocalizationDialog
                  open={openMultipleLocalizationDialog}
                  onClose={handleCloseMultipleLocalizationDialog}
                  localizationData={clickedTicketMultipleLocalization.current}
                  itemType={"ticket"}
                />
              )}
          </Grid>
        </LoaderWrapper>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
