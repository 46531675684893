import { useMemo, useState, useRef, useEffect } from "react";

import PropTypes from "prop-types";

import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import { useAsync } from "../../hooks/useAsync";

import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";

import useFilterSearchParams from "../../hooks/useFilterSearchParams";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import useDialog from "../../hooks/useDialog";
import useDialogWithId from "../../hooks/useDialogWithId";
import StdOrderDialog from "../../components/dialog/StdOrderDialog";
import StdTicketDialog from "../../components/dialog/StdTicketDialog";

import TicketCategoryManagementDialog from "../../components/dialog/TicketCategoryManagementDialog/TicketCategoryManagementDialog";

import TableService from "../../services/tableService";
import useTicketService from "../../services/ticketService";
import useOrderService from "../../services/orderService";

import StdTicketTable from "../../components/table/StdTicketTable/StdTicketTable";
import StdOrderTable from "../../components/table/StdOrderTable/StdOrderTable";

import DialpadIcon from "@mui/icons-material/Dialpad";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

import SelectStdTicketStdOrderTableButton from "../../components/button/SelectStdTicketStdOrderTableButton";

import {
  centerVericalAlignIconStyle,
  overflowButtonStyle,
} from "../../helpers/styles";

import { DIALOG_EDIT_MODE, DIALOG_CREATE_MODE } from "../../helpers/constants";

import { isSearchParamsObjectEmpty } from "../../helpers/methods";

import {
  STD_TICKET_TABLE_NAME,
  STD_ORDER_TABLE_NAME,
  STD_TICKET_TABLE_PREFIX,
  STD_ORDER_TABLE_PREFIX,
} from "./TableConfig";

import {
  exportStdTicketAdminDataToFileUrl,
  exportStdOrderAdminDataToFileUrl,
} from "../../helpers/apiUrls";

const StdTicketStdOrderTableWithToolBar = (props) => {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    setPageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(props.tableFilterPrefix);

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefixes,
  } = useFilterSearchParams(searchParams);

  const [adminSearchParams, setAdminSearchParams] = useState(
    getSearchParamsByFilterPrefix(props.tableFilterPrefix)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      props.tableFilterPrefix,
      adminSearchParams,
      setAdminSearchParams
    );
  }, [searchParams]);

  const { getStdTicketAdminData, getStdTicketAdminFilteringData } =
    useTicketService();

  const { getStdOrderAdminData, getStdOrderAdminFilteringData } =
    useOrderService();

  const getAdminDataFnByTableName = (adminSearchParams) => {
    if (props.tableName === STD_TICKET_TABLE_NAME)
      return getStdTicketAdminData(adminSearchParams);
    if (props.tableName === STD_ORDER_TABLE_NAME)
      return getStdOrderAdminData(adminSearchParams);
    return null;
  };

  const getAdminFilteringDataFnByTableName = () => {
    if (props.tableName === STD_TICKET_TABLE_NAME)
      return getStdTicketAdminFilteringData();
    if (props.tableName === STD_ORDER_TABLE_NAME)
      return getStdOrderAdminFilteringData();
    return null;
  };

  const adminData = useAsync(() => {
    return getAdminDataFnByTableName(adminSearchParams);
  }, [adminSearchParams, props.tableName]);

  const adminFilteringData = useAsync(
    () => getAdminFilteringDataFnByTableName(),
    [props.tableName]
  );

  const [adminDataLocaly, setAdminDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (adminData.loading) {
      return;
    }
    setAdminDataLocaly(adminData.value.results);
    setCountRecords(adminData.value.count);
  }, [adminData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setAdminDataLocaly(undefined);
    adminData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setAdminDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const exportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      props.tableConfig,
      hiddenColumnsForTables?.[props.tableName],
      columnsOrdersForTables?.[props.tableName]
    );
  }, [
    props.tableConfig,
    hiddenColumnsForTables?.[props.tableName],
    columnsOrdersForTables?.[props.tableName],
  ]);

  const [openCategoryDialog, onOpenCategoryDialog, onCloseCategoryDialog] =
    useDialog();

  const [
    openStdTicketDialog,
    stdTicketId,
    onOpenStdTicketDialog,
    onCloseStdTicketDialog,
  ] = useDialogWithId();

  const [
    openStdOrderDialog,
    stdOrderId,
    onOpenStdOrderDialog,
    onCloseStdOrderDialog,
  ] = useDialogWithId();

  const stdTicketDialogModeRef = useRef();
  const handleOpenStdTicketDialogInCreateMode = () => {
    stdTicketDialogModeRef.current = DIALOG_CREATE_MODE;
    onOpenStdTicketDialog(undefined);
  };

  const handleOpenStdTicketDialogInEditMode = (stdTicketId) => {
    stdTicketDialogModeRef.current = DIALOG_EDIT_MODE;
    onOpenStdTicketDialog(stdTicketId);
  };

  const stdOrderDialogModeRef = useRef();
  const handleOpenStdOrderDialogInCreateMode = () => {
    stdOrderDialogModeRef.current = DIALOG_CREATE_MODE;
    onOpenStdOrderDialog(undefined);
  };

  const handleOpenStdOrderDialogInEditMode = (stdOrderId) => {
    stdOrderDialogModeRef.current = DIALOG_EDIT_MODE;
    onOpenStdOrderDialog(stdOrderId);
  };

  const getStdTicketDialogCallback = () => {
    if (
      stdTicketDialogModeRef.current === DIALOG_CREATE_MODE &&
      !isSearchParamsObjectEmpty(adminSearchParams)
    ) {
      setSearchParams(clearSearchParamsByFilterPrefix(props.tableFilterPrefix));
    } else {
      adminData.refetch();
    }
  };

  const getStdOrderDialogCallback = () => {
    if (
      stdOrderDialogModeRef.current === DIALOG_CREATE_MODE &&
      !isSearchParamsObjectEmpty(adminSearchParams)
    ) {
      setSearchParams(clearSearchParamsByFilterPrefix(props.tableFilterPrefix));
    } else {
      adminData.refetch();
    }
  };

  const handleCloseCategoryDialog = (existsChanges) => {
    onCloseCategoryDialog();

    if (existsChanges) {
      adminData.refetch();
      adminFilteringData.refetch();
    }
  };

  const handleChangeSelectedTable = (filterValue) => {
    setAdminDataLocaly(undefined);
    setAdminSearchParams({});
    setHiddenColumnsForTables(undefined);
    setColumnsOrdersForTables(undefined);
    props.setSelectedTable(filterValue);
    setPageSize(10);
    setSearchParams({
      ...clearSearchParamsByFilterPrefixes([
        STD_ORDER_TABLE_PREFIX,
        STD_TICKET_TABLE_PREFIX,
      ]),
    });
  };

  const getExtraButtonListForToolBar = () => {
    return [
      <Button
        color="primary"
        onClick={handleOpenStdTicketDialogInCreateMode}
        variant="contained"
        size="small"
        startIcon={
          <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
        }
        sx={overflowButtonStyle}
        fullWidth
      >
        {t("page.admin_std_tickets_orders.add_std_ticket")}
      </Button>,
      <Button
        color="success"
        onClick={handleOpenStdOrderDialogInCreateMode}
        variant="contained"
        size="small"
        startIcon={
          <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
        }
        sx={overflowButtonStyle}
        fullWidth
      >
        {t("page.admin_std_tickets_orders.add_std_order")}
      </Button>,
      <Button
        color="primary"
        onClick={onOpenCategoryDialog}
        variant="contained"
        size="small"
        startIcon={<DialpadIcon sx={centerVericalAlignIconStyle} />}
        sx={overflowButtonStyle}
        fullWidth
      >
        {t("page.admin_std_tickets_orders.categories")}
      </Button>,
      <SelectStdTicketStdOrderTableButton
        onChangeFilterValue={handleChangeSelectedTable}
        stdStdTicketKey={STD_TICKET_TABLE_NAME}
        stdOrderKey={STD_ORDER_TABLE_NAME}
        selectedFilterKey={props.tableName}
      />,
    ];
  };

  const getSelectedTable = () => {
    return props.tableName === STD_TICKET_TABLE_NAME ? (
      <StdTicketTable
        data={adminDataLocaly}
        countRecords={countRecords}
        page={page}
        onPageChange={handleChangePageWithSearchParams}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
        resetPageNumber={resetPageNumber}
        filteringData={adminFilteringData}
        hiddenColumns={hiddenColumnsForTables}
        columnsOrders={columnsOrdersForTables}
        onClickEdit={handleOpenStdTicketDialogInEditMode}
        style={{ maxHeight: "80vh" }}
        showExportToFileButton={true}
        exportToFileUrl={exportStdTicketAdminDataToFileUrl}
        exportToFileSearchParams={adminSearchParams}
        exportToFileHeaders={exportHeaders}
        exportToFileFileName={"std_tickets.xlsx"}
        filterPrefix={props.tableFilterPrefix}
      />
    ) : (
      <StdOrderTable
        data={adminDataLocaly}
        countRecords={countRecords}
        page={page}
        onPageChange={handleChangePageWithSearchParams}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
        resetPageNumber={resetPageNumber}
        tableConfig={props.tableConfig}
        filteringData={adminFilteringData}
        hiddenColumns={hiddenColumnsForTables?.[STD_ORDER_TABLE_NAME]}
        columnsOrders={columnsOrdersForTables?.[STD_ORDER_TABLE_NAME]}
        onClickEdit={handleOpenStdOrderDialogInEditMode}
        style={{ maxHeight: "80vh" }}
        showExportToFileButton={true}
        exportToFileUrl={exportStdOrderAdminDataToFileUrl}
        exportToFileSearchParams={adminSearchParams}
        exportToFileHeaders={exportHeaders}
        exportToFileFileName={"std_orders.xlsx"}
        filterPrefix={props.tableFilterPrefix}
      />
    );
  };

  const isLoading = adminDataLocaly === undefined || adminFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={1}
      marginTop={1}
    >
      <Grid item xs={12}>
        <UniversalToolBarWithDialogs
          pageName={props.pageName}
          showMyFiltersButton={false}
          showOpenCloseFilterButton={false}
          tableConfig={props.tableConfigForUserPreferencesDialog}
          filteringData={adminFilteringData}
          refreshTable={refreshTable}
          onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
          resetPageNumber={resetPageNumber}
          hiddenColumnsForTables={hiddenColumnsForTables}
          setHiddenColumnsForTables={setHiddenColumnsForTables}
          columnsOrdersForTables={columnsOrdersForTables}
          setColumnsOrdersForTables={setColumnsOrdersForTables}
          filterPrefix={props.tableFilterPrefix}
          extraButtonList={getExtraButtonListForToolBar()}
        />
      </Grid>
      {hiddenColumnsForTables && (
        <Grid item xs={12}>
          {getSelectedTable()}
        </Grid>
      )}
      {openCategoryDialog && (
        <TicketCategoryManagementDialog
          open={openCategoryDialog}
          onClose={handleCloseCategoryDialog}
        />
      )}
      {openStdTicketDialog && (
        <StdTicketDialog
          open={openStdTicketDialog}
          onClose={onCloseStdTicketDialog}
          dialogMode={stdTicketDialogModeRef.current}
          onSubmitCallback={getStdTicketDialogCallback}
          stdTicketId={stdTicketId}
        />
      )}
      {openStdOrderDialog && (
        <StdOrderDialog
          open={openStdOrderDialog}
          onClose={onCloseStdOrderDialog}
          dialogMode={stdOrderDialogModeRef.current}
          onSubmitCallback={getStdOrderDialogCallback}
          stdOrderId={stdOrderId}
        />
      )}
    </Grid>
  );
};

StdTicketStdOrderTableWithToolBar.propTypes = {
  pageName: PropTypes.string,
  tableName: PropTypes.string,
  tableConfig: PropTypes.object,
  tableFilterPrefix: PropTypes.string,
  tableConfigForUserPreferencesDialog: PropTypes.array,
  setSelectedTable: PropTypes.func,
};

StdTicketStdOrderTableWithToolBar.defaultProps = {};

export default StdTicketStdOrderTableWithToolBar;
