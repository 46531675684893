import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SplitButton from "../SplitButton";

function AcceptOfferButton(props) {


  return (
    <SplitButton
      selectedItemKey={props.selectedView}
      withChangeSelectedItem={true}
      options={props.options}
    />
  );
}

AcceptOfferButton.propTypes = {
  selectedView : PropTypes.oneOf(["offer-accept", "all-offers", "ticket_offers"]),
  options: PropTypes.array
};

AcceptOfferButton.defaultProps = {
  selectedView : "offer-accept",
  options: []
};

export default AcceptOfferButton;
