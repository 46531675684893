import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import DateService from "../../services/dateService";
import WarningAlertIcon from "../../components/other/WarningAlertIcon";
import PrefixSuffixFieldTableWrapper from "../../components/wrapper/PrefixSuffixFieldTableWrapper";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export const DOCS_RESTRICTED_TABLE_NAME = "docs_restricted";

export const DOCS_RESTRICTED_TABLE_FIELDS_CONFIG = [
  {
    name: "docs_name",
    getValueCallback: (rowData) => rowData.docs_name,
    label: i18n.t("table.docs_restricted.docs_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "docs_note",
    getValueCallback: (rowData) => rowData.docs_note,
    label: i18n.t("table.docs_restricted.docs_note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "objects_level_1",
    getValueCallback: (rowData, onClickMultipleLocationAlert) => {
      return rowData.is_multiple_locations ? (
        <PrefixSuffixFieldTableWrapper
          prefix={
            <WarningAlertIcon
              style={{ cursor: "pointer" }}
              onClick={(e) => onClickMultipleLocationAlert(e, rowData.id)}
            />
          }
        >
          {rowData.docs_restricted_object[0]?.level_1}
        </PrefixSuffixFieldTableWrapper>
      ) : (
        rowData.docs_restricted_object[0]?.level_1
      );
    },
    label: i18n.t("table.docs_restricted.level_1"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_2",
    getValueCallback: (rowData) => rowData.docs_restricted_object[0]?.level_2,
    label: i18n.t("table.docs_restricted.level_2"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_3",
    getValueCallback: (rowData) => rowData.docs_restricted_object[0]?.level_3,
    label: i18n.t("table.docs_restricted.level_3"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_4",
    getValueCallback: (rowData) => rowData.docs_restricted_object[0]?.level_4,
    label: i18n.t("table.docs_restricted.level_4"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_5",
    getValueCallback: (rowData) => rowData.docs_restricted_object[0]?.level_5,
    label: i18n.t("table.docs_restricted.level_5"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },

  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.docs_restricted.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  {
    name: "docs_user",
    getValueCallback: (rowData) => rowData.docs_user,
    label: i18n.t("table.docs_restricted.docs_user"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.docs_user,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "docs_restricted_user",
    label: i18n.t("table.docs_restricted.docs_restricted_user"),
    getValueCallback: (rowData) => rowData.docs_restricted_user,
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.docs_restricted_user,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "docs_restricted_tenant",
    label: i18n.t("table.docs_restricted.docs_restricted_tenant"),
    getValueCallback: (rowData) => rowData.docs_restricted_tenant,
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.docs_restricted_tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "docs_restricted_owner",
    label: i18n.t("table.docs_restricted.docs_restricted_owner"),
    getValueCallback: (rowData) => rowData.docs_restricted_owner,
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.docs_restricted_owner,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "docs_restricted_role",
    label: i18n.t("table.docs_restricted.docs_restricted_role"),
    getValueCallback: (rowData) => rowData.docs_restricted_role,
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.docs_restricted_role,
      isObjectOption: true,
      optionLabelKey: "role_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "info",
    getValueCallback: (
      rowData,
      onClickMultipleLocationAlert,
      onDownload,
      onPreview,
      onClickEdit,
      onClickEditUserNote,
      onClickInfo
    ) => (
      <IconButton
        color="primary"
        onClick={() => {
          onClickInfo(rowData.id);
        }}
        sx={{ padding: "5px" }}
      >
        <InfoIcon fontSize="medium" />
      </IconButton>
    ),
    label: i18n.t("table.docs_restricted.docs_details"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { maxWidth: "100px" },
    }),
  },
  {
    name: "download",
    getValueCallback: (
      rowData,
      onClickMultipleLocationAlert,
      onDownload,
      onPreview,
      onClickEdit,
      onClickEditUserNote,
      onClickInfo
    ) => (
      <IconButton
        color="primary"
        onClick={() => {
          onDownload(rowData.id, rowData.docs_name);
        }}
        sx={{ padding: "5px" }}
      >
        <DownloadIcon fontSize="medium" />
      </IconButton>
    ),
    label: i18n.t("table.docs_restricted.download"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  {
    name: "preview",
    getValueCallback: (
      rowData,
      onClickMultipleLocationAlert,
      onDownload,
      onPreview,
      onClickEdit,
      onClickEditUserNote,
      onClickInfo
    ) =>
      rowData.content_type === "application/pdf" ||
      (rowData.content_type && rowData.content_type.includes("image")) ? (
        <IconButton
          color="primary"
          onClick={() => {
            onPreview(rowData.id);
          }}
          sx={{ padding: "5px" }}
        >
          <PreviewOutlinedIcon fontSize="medium" />
        </IconButton>
      ) : null,
    label: i18n.t("table.docs_restricted.preview"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { maxWidth: "100px" },
    }),
  },
];
