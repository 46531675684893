import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useAsync } from "../../../hooks/useAsync";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import AutocompleteField from "../../field/AutocompleteField";
import { Button, Grid } from "@mui/material";
import useAgreementService from "../../../services/agreementService";

const SelectAgreementDialog = (props) => {
  const { t } = useTranslation();

  const [selectedAgreement, setSelectedAgreement] = useState();
  const { getAgreementsSelectList } = useAgreementService();

  const agreementSelectList = useAsync(() => getAgreementsSelectList(), []);

  const onChangeAutocompleteFieldWithObjectOptions = (
    e,
    value_object,
    value_key,
    state_value_name
  ) => {
    const newFormValue = Array.isArray(value_object)
      ? value_object.map((option) =>
          typeof option === "object" ? option[value_key] : option
        )
      : value_object[value_key];
    setSelectedAgreement(newFormValue);
  };
  const isLoading = agreementSelectList.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <>
        <Grid item xs={12}>
          <AutocompleteField
            name="agreement"
            label={t("dialog.select_agreement_dialog.agreement")}
            value={selectedAgreement}
            options={agreementSelectList ? agreementSelectList.value : []}
            isObjectOption={true}
            optionLabelKey={"agreement_descriptive_name"}
            addNewValue={false}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            required
          />
        </Grid>
        <Button
          sx={{ marginTop: "20px" }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => props.onSubmit(selectedAgreement)}
          disabled={!selectedAgreement}
        >
          {t("confirm")}
        </Button>
      </>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      titleAlign="center"
      maxWidth="sm"
      title={t("dialog.select_agreement_dialog.select_agreement")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

SelectAgreementDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ticketId: PropTypes.string,
};

SelectAgreementDialog.defaultProps = {};

export default SelectAgreementDialog;
