import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import AutocompleteField from "../../field/AutocompleteField";
import DateField from "../../field/DateField/DateField";
import IcoParticipiantBox from "../../box/IcoParticipiantBox/IcoParticipiantBox";
import BoxWithTypography from "../../box/BoxWithTypography/BoxWithTypography";
import React, { useMemo, } from "react";


const IcoAcceptanceForm = React.memo( (props) => {
  console.log('ico acceptance form')

  const { t } = useTranslation();

  const participiantKinds = useMemo(() => {
    return ["accept", "informed", "doer"]
  }, [])

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={2}>
        <AutocompleteField
          name={"applicant"}
          label={t("form.ico_acceptance_form.applicant")}
          value={props.icoData?.applicant}
          options={props.filteringData?.users}
          isObjectOption={true}
          optionLabelKey={"full_name"}
          onChange={props.onChangeAutocomplete}
          addNewValue={false}
          multiple={false}
          readOnly={props.readOnly}
          disableClearable
        />
      </Grid>
      <Grid item xs={2}>
        <DateField
          name="ico_date_input"
          label={t("form.ico_acceptance_form.ico_date_input")}
          value={props.icoData?.ico_date_input}
          onChange={props.onChangeDate}
          showClearIcon={false}
          readOnly={props.readOnly}

        />
      </Grid>
      <Grid item xs={8} />

      {participiantKinds?.map((kind) => {
        return (
          <Grid item xs={4}>
            <BoxWithTypography
              style={{ padding: "10px" }}
              label={t(`form.ico_acceptance_form.${kind}`)}
              component={"div"}
            >
              <IcoParticipiantBox
                readOnly={props.readOnly}
                showDates={props.showDates}
                onAddData={props.onAddData}
                onEditData={props.onEditData}
                onRemoveData={props.onRemoveData}
                participiantKind={kind}
                participiants={props.icoParticipiants[kind]}
              /></BoxWithTypography>
          </Grid>
        )
      })
      }
    </Grid>

  );
});

IcoAcceptanceForm.propTypes = {
  readOnly: PropTypes.bool,
  icoData: PropTypes.object,
  icoId: PropTypes.string,
  onChangeDate: PropTypes.func,
  onChangeAutocomplete: PropTypes.func,
  icoParticipiants: PropTypes.array
};

IcoAcceptanceForm.defaultProps = {
  readOnly: false,
  icoParticipiants: []
};

export default IcoAcceptanceForm;
