import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SelectField from "../../field/SelectField";
import FloatField from "../../base/FloatField";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useTranslation } from "react-i18next";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import { PURCHASE_LINE_DELIVERED_STATUS } from "../../../helpers/constants";

const PurchaseLineStatusForm = (props
) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12}>
        <SelectField
          name="poli_status"
          label={t(`form.purchase_line_status_form.status`)}
          value={props.formValue?.poli_status}
          options={props.allowedStatuses}
          onChange={props.onChange}
          isObjectOption={false}
          readOnly={props.readOnly}
        />
      </Grid>
      {props.formValue?.poli_status === PURCHASE_LINE_DELIVERED_STATUS &&
      <Grid item xs={12}>
        <FloatField
          name="poli_price_real"
          label={t(`form.purchase_line_status_form.price_real`)}
          value={props.formValue?.poli_price_real}
          onChange={props.onChange}
          suffix={"PLN"}
        />
      </Grid>}
    </Grid>
  );
};

PurchaseLineStatusForm.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool
};

PurchaseLineStatusForm.defaultProps = {
  readOnly: false
};

export default PurchaseLineStatusForm;
