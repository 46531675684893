import { useState } from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

import TextFieldFormControl from "../../field/TextFieldFormControl";

import { useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useFeedbackService from "../../../services/feedbackService";

import { OliviaLogo } from "../../../media/logo";

export default function FeedbackForm(props) {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [feedbackNote, setFeedbackNote] = useState("");
  const [isFeedbackSend, setIsFeedbackSend] = useState(false);

  const { sendFeedbackNote } = useFeedbackService();
  const sendFeedbackNoteFn = useAsyncFn(sendFeedbackNote);

  const isFormValid = () => {
    return feedbackNote.length > 0;
  };

  const prepareDataToSend = () => ({
    ticket: props.ticketId,
    feedback_note: feedbackNote,
  });

  const isLoading = sendFeedbackNoteFn.loading;

  const onSubmit = () => {
    sendFeedbackNoteFn
      .execute(props.feedbackId, prepareDataToSend())
      .then(() => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.feedback_send")
        );
        setIsFeedbackSend(true);
      })
      .catch((error) => {
        if (error.status === 400) {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.error_occur_durring_sending_feedback")
          );
        } else {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.server_error")
          );
        }
      });
  };

  return (
    <Paper elevation={3} style={props.style}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={1}
      >
        <Grid item xs={12} marginTop={2}>
          <img
            className=""
            src={OliviaLogo}
            alt=""
            height={80}
            style={{ textAlign: "left" }}
          />
        </Grid>
        {!isFeedbackSend && (
          <>
            <Grid item xs={12} marginTop={3}>
              <Typography
                style={{ color: "var(--primary)" }}
                variant="h6"
                display="block"
                gutterBottom
              >
                {t("form.feedback.thank_you_for_your_rate")}
              </Typography>
            </Grid>
            <Grid item xs={12} marginTop={3}>
              <TextFieldFormControl
                label={""}
                value={feedbackNote}
                onChange={(e) => setFeedbackNote(e.target.value)}
                multiline={true}
                rows={8}
              />
            </Grid>
            <Grid item xs={12} marginTop={3} marginBottom={2}>
              <LoadingButton
                loading={isLoading}
                fullWidth
                type="submit"
                disabled={!isFormValid()}
                onClick={onSubmit}
                variant="contained"
              >
                {t("form.feedback.send_feedback")}
              </LoadingButton>
            </Grid>
          </>
        )}
        {isFeedbackSend && (
          <Grid item xs={12} marginTop={3}>
            <Typography
              style={{ color: "var(--primary)" }}
              variant="h6"
              display="block"
              gutterBottom
            >
              {t("form.feedback.thank_you_for_your_note")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

FeedbackForm.propTypes = {
  feedbackId: PropTypes.string,
  ticketId: PropTypes.string,
  style: PropTypes.object,
};
