import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import {
  statusesForOrdersUrl,
  statusesForTicketsUrl,
  statusesForTasksUrl,
  substatusesForTicketsUrl,
  substatusesForTasksUrl,
} from "../helpers/apiUrls";

import { prepareUrlWithQueryParams } from "../helpers/methods";

export default function useSatusService() {
  const axiosPrivate = useAxiosPrivate();

  const getStatusesForTickets = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(statusesForTicketsUrl, searchParams)
    );
  };

  const getStatusesForOrders = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(statusesForOrdersUrl, searchParams)
    );
  };

  const getStatusesForTasks = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(statusesForTasksUrl, searchParams)
    );
  };

  const getStatusesForItems = (itemType = "ticket", searchParams = {}) => {
    if (itemType === "ticket") return getStatusesForTickets(searchParams);
    if (itemType === "order") return getStatusesForOrders(searchParams);
    if (itemType === "task") return getStatusesForTasks(searchParams);
    return Promise.resolve([]);
  };

  const getSubStatusesForTickets = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(substatusesForTicketsUrl, searchParams)
    );
  };

  const getSubStatusesForTasks = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(substatusesForTasksUrl, searchParams)
    );
  };

  const getSubStatusesForItems = (itemType = "ticket", searchParams = {}) => {
    if (itemType === "ticket") return getSubStatusesForTickets(searchParams);
    if (itemType === "task") return getSubStatusesForTasks(searchParams);
    return Promise.resolve([]);
  };

  return {
    getStatusesForTickets,
    getStatusesForOrders,
    getSubStatusesForTickets,
    getSubStatusesForItems,
    getStatusesForItems,
  };
}
