import * as React from "react";

import PropTypes from "prop-types";

import IconButton from "@mui/material/IconButton";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import { TableSortLabel } from "@mui/material";

import {
  StyledTableCell,
  StyledTableRow,
} from "../../../base/BaseTable/BaseTable";

import HasChangesDotFilter from "../../../other/HasChangesDotFilter";
import CheckboxField from "../../../base/CheckboxField/checkboxField";

import usePaginationWithSearchParamsAndOrdering from "../../../../hooks/usePaginationWithSearchParamsAndOrdering";

import {
  ORDERING_DIRECTION_ASC,
  ORDERING_DIRECTION_DESC,
} from "../../../../helpers/constants";

const UniversalTableFirstHeader = React.memo(
  ({
    showFilters,
    showCheckbox,
    showDetailsIcon,
    checkedAll,
    onClickCheckAll,
    expanded,
    setExpanded,
    headersConfig,
    showChangesCircle,
    showChangesCircleFilter,
    filterPrefix,
  }) => {
    const { sortParams, handleSort } =
      usePaginationWithSearchParamsAndOrdering(filterPrefix);

    const getIsCellSortActive = (cellName) =>
      cellName === sortParams.sortColumn;

    const getCellCurrenSortDirection = (cellName) =>
      getIsCellSortActive(cellName)
        ? sortParams.sortOrder
        : ORDERING_DIRECTION_ASC;

    const getCellSortDirectionForSorting = (cellName) =>
      getIsCellSortActive(cellName) &&
      sortParams.sortOrder === ORDERING_DIRECTION_ASC
        ? ORDERING_DIRECTION_DESC
        : ORDERING_DIRECTION_ASC;

    const getTableSortLabelcolor = (cellName) =>
      getIsCellSortActive(cellName) ? "var(--brand-green)" : "var(--white)";

    return (
      <StyledTableRow>
        {showChangesCircle && !showChangesCircleFilter && <StyledTableCell />}
        {showChangesCircle && showChangesCircleFilter && (
          <StyledTableCell>
            <HasChangesDotFilter filterPrefix={filterPrefix} />
          </StyledTableCell>
        )}
        {showCheckbox && (
          <StyledTableCell>
            <CheckboxField
              size="small"
              labelPlacement={"bottom"}
              checked={checkedAll}
              onChange={onClickCheckAll}
              style={{ color: "var(--white)" }}
            />
          </StyledTableCell>
        )}
        {showDetailsIcon && !showFilters && <StyledTableCell />}
        {showFilters && (
          <StyledTableCell colSpan={showDetailsIcon ? 2 : 1} align="center">
            <IconButton
              size="small"
              onClick={() => setExpanded((prev) => !prev)}
            >
              {expanded ? (
                <FilterAltOffOutlinedIcon sx={{ color: "var(--white)" }} />
              ) : (
                <FilterAltOutlinedIcon sx={{ color: "var(--white)" }} />
              )}
            </IconButton>
          </StyledTableCell>
        )}
        {headersConfig.map((header, index) => (
          <StyledTableCell
            className="capitalize-first-letter"
            key={`${header.name}${index}`}
            align="center"
            sx={{ whiteSpace: "nowrap", ...header.headerSx }}
          >
            {showFilters && header.sortable ? (
              <TableSortLabel
                align={"left"}
                active={getIsCellSortActive(header.name)}
                direction={getCellCurrenSortDirection(header.name)}
                onClick={() =>
                  handleSort(
                    header.name,
                    getCellSortDirectionForSorting(header.name)
                  )
                }
                style={{
                  color: getTableSortLabelcolor(header.name),
                  textTransform: "capitalize",
                  marginLeft: "20px",
                }}
                sx={{
                  ".MuiTableSortLabel-icon": {
                    color: `${getTableSortLabelcolor(header.name)} !important`,
                  },
                }}
              >
                {header.label}
              </TableSortLabel>
            ) : (
              header.label
            )}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    );
  }
);

UniversalTableFirstHeader.propTypes = {
  showChangesCircle: PropTypes.bool,
  showChangesCircleFilter: PropTypes.bool,
  showFilters: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  showDetailsIcon: PropTypes.bool,
  checkedAll: PropTypes.bool,
  onClickCheckAll: PropTypes.func,
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func,
  headersConfig: PropTypes.arrayOf(
    PropTypes.shape({
      filterType: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string,
      fieldConfig: PropTypes.object,
    })
  ),
  filterPrefix: PropTypes.string,
};

UniversalTableFirstHeader.defaultProps = {
  showCheckbox: true,
  showFilters: true,
  showDetailsIcon: false,
  showChangesCircle: false,
  showChangesCircleFilter: true,
};

export default UniversalTableFirstHeader;
