import React, { useState } from "react";

import PropTypes from "prop-types";

import { Grid, Typography, IconButton } from "@mui/material";

import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import BaseBox from "../../../base/BaseBox/baseBox";

import useDialog from "../../../../hooks/useDialog";

import MessagesWindow from "../../../window/MessagesWindow/MessagesWindow";
import UniversalCommunicationLogDialog from "../../../dialog/UniversalCommunicationLogDialog";

import i18n from "../../../../i18n";
import { useSearchParams } from "react-router-dom";
import useFilterSearchParams from "../../../../hooks/useFilterSearchParams";

const CommunicationLogForm = ({
  lastMessages,
  itemId,
  itemType,
  showExtendedAddEnclosureDialog,
  onOpenDialogParamsAction,
  onRefetchData,
  isFinallyClosed,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { getSearchParamsByFilterPrefix } = useFilterSearchParams(searchParams);

  const [searchParamsFromPage, setSearchParamsFromPage] = useState(() => {
    return getSearchParamsByFilterPrefix();
  });

  const [
    openComunicationDialog,
    onOpenComunicationDialog,
    onCloseComunicationDialog,
  ] = useDialog();

  const onOpenCommmunicationDialogLocal = () => {
    onOpenComunicationDialog();
    onOpenDialogParamsAction(false);
    setSearchParamsFromPage(getSearchParamsByFilterPrefix());
    setSearchParams({});
  };

  const onCloseCommmunicationDialogLocal = (existsChanges) => {
    setSearchParams({ ...searchParamsFromPage });
    setSearchParamsFromPage({});
    onOpenDialogParamsAction(true);
    onCloseComunicationDialog();
    if (existsChanges) {
      onRefetchData();
    }
  };

  return (
    <>
      <div onClick={() => onOpenCommmunicationDialogLocal()}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <IconButton onClick={() => onOpenCommmunicationDialogLocal()}>
              <QuestionAnswerOutlinedIcon />
            </IconButton>

            <Typography
              variant="subtitle"
              className="capitalize-first-letter"
              color={"primary"}
              style={{ cursor: "pointer" }}
            >
              {i18n.t("form.communication_log_form.communication_log")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <BaseBox>
              <MessagesWindow
                messages={lastMessages}
                messageWindowHeight={{ height: 200, marginLeft: -30 }}
                showContextMenu={false}
              />
            </BaseBox>
          </Grid>
        </Grid>
      </div>
      {openComunicationDialog && (
        <UniversalCommunicationLogDialog
          open={openComunicationDialog}
          onClose={(existChanges) =>
            onCloseCommmunicationDialogLocal(existChanges)
          }
          itemId={itemId}
          itemType={itemType}
          showExtendedAddEnclosureDialog={showExtendedAddEnclosureDialog}
          isFinallyClosed={isFinallyClosed}
        />
      )}
    </>
  );
};

CommunicationLogForm.propTypes = {
  lastMessages: PropTypes.array,
  itemId: PropTypes.string,
  itemType: PropTypes.oneOf(["ticket", "ticket_order", "order"]),
  showExtendedAddEnclosureDialog: PropTypes.bool,
};

CommunicationLogForm.defaultProps = {
  showExtendedAddEnclosureDialog: false,
};

export default CommunicationLogForm;
