import i18n from "../../i18n";

import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import SelectFieldService from "../../services/selectFieldService";
import DateService from "../../services/dateService";

import {
  TEXT_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
  DATE_RANGE_FIELD_TYPE,
} from "../../helpers/constants";

import { convertFlatListFromBackendToTranslatedOptionObject } from "../../helpers/methods";

export const OVERRIDING_TABLE_NAME = "overriding";
export const OVERRIDING_TABLE_FILTER_PREFIX = "overriding";

export const OVERRIDING_TABLE_CONFIG = [
  {
    name: "overriding_nr",
    getValueCallback: (rowData) => rowData.overriding_nr,
    label: i18n.t("table.overriding.overriding_nr"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
    allowChangeVisibility: false,
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.overriding.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "overriding_date_plan",
    getValueCallback: (rowData) =>
      DateService.convertDateToFormatYYYYMMDD(rowData.overriding_date_plan),
    label: i18n.t("table.overriding.overriding_date_plan"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "overriding_subkind",
    getValueCallback: (rowData) =>
      i18n.t(`table.overriding.${rowData[`overriding_subkind`]}`),
    label: i18n.t("table.overriding.overriding_subkind"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: convertFlatListFromBackendToTranslatedOptionObject(
        filteringData.value.subkind
      ),
      isObjectOption: true,
      multiple: true,
    }),
  },
  {
    name: "objects_level_1",
    other_export_field_name: "level_1",
    getValueCallback: (rowData) => rowData.location?.level_1?.object_name,
    label: i18n.t("table.overriding.object_level_1"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_2",
    other_export_field_name: "level_2",
    getValueCallback: (rowData) => rowData.location?.level_2?.object_name,
    label: i18n.t("table.overriding.object_level_2"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_3",
    other_export_field_name: "level_3",
    getValueCallback: (rowData) => rowData.location?.level_3?.object_name,
    label: i18n.t("table.overriding.object_level_3"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_4",
    other_export_field_name: "level_4",
    getValueCallback: (rowData) => rowData.location?.level_4?.object_name,
    label: i18n.t("table.overriding.object_level_4"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "overriding_note",
    getValueCallback: (rowData) => rowData.overriding_note,
    label: i18n.t("table.overriding.overriding_note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  // {
  //   name: "is_closed",
  //   getValueCallback: (rowData) =>
  //     SelectFieldService.getLabelOptionsTrueOrFalse(rowData.is_closed),
  //   label: i18n.t("table.overriding.is_closed"),
  //   filterType: BOOLEAN_FIELD_TYPE,
  //   getFilterFieldConfigCallback: (filteringData) => undefined,
  // },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit, onClickPreview) => {
      if (rowData.is_closed)
        return <InfoOutlinedIcon onClick={(e) => onClickPreview(rowData.id)} />;
      return (
        <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
      );
    },
    label: i18n.t("edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
];

export const TICKET_TABLE_NAME = "ticket";

export const TICKET_TABLE_CONFIG = [
  {
    name: "ticket_nr",
    getValueCallback: (rowData) => rowData.ticket_nr,
    label: i18n.t("table.ticket.ticket_nr"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "ticket_name",
    getValueCallback: (rowData) => rowData.ticket_name,
    label: i18n.t("table.ticket.ticket_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "ticket_category",
    getValueCallback: (rowData) => rowData.ticket_category,
    label: i18n.t("table.ticket.ticket_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.ticket.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "ticket_status",
    getValueCallback: (rowData) => rowData.ticket_status,
    label: i18n.t("table.ticket.ticket_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_status,
      isObjectOption: true,
      optionLabelKey: "status_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_substatus",
    getValueCallback: (rowData) => rowData.ticket_substatus,
    label: i18n.t("table.ticket.ticket_substatus"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_substatus,
      isObjectOption: true,
      optionLabelKey: "substatus_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_owner",
    getValueCallback: (rowData) => rowData.ticket_owner,
    label: i18n.t("table.ticket.ticket_owner"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_owner,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_desc_creator",
    getValueCallback: (rowData) => rowData.ticket_desc_creator,
    label: i18n.t("table.ticket.ticket_desc_creator"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];
