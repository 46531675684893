import { useAsync } from "../../../hooks/useAsync";
import useTicketService from "../../../services/ticketService";
import BasicDialog from "../../base/BasicDialog";
import AutocompleteField from "../../field/AutocompleteField";
import PropTypes from "prop-types";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useForm } from "../../../hooks/useForm";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const SelectTicketToAddOrderDialog = (props) => {
  const { t } = useTranslation();

  const { formValue, onChangeAutocompleteFieldWithObjectOptions } = useForm({
    ticket: undefined,
  });

  const { getNotClosedTicketSelectList } = useTicketService();
  const ticketList = useAsync(getNotClosedTicketSelectList);

  return (
    <BasicDialog
      title={t("dialog.select_ticket_dialog.select_ticket")}
      open={props.open}
      onClose={props.onClose}
      maxWidth={"sm"}
      titleAlign="center"
    >
      <LoaderWrapper showLoader={ticketList.loading}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AutocompleteField
              label={t("dialog.select_ticket_dialog.ticket")}
              options={ticketList?.value || []}
              value={formValue.ticket}
              name="ticket"
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              optionLabelKey={"ticket_descriptive_name"}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              disabled={!formValue.ticket}
              onClick={() => {
                props.onSubmit(formValue.ticket);
              }}
            >
              {t("confirm")}
            </Button>
          </Grid>
        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
};

SelectTicketToAddOrderDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

SelectTicketToAddOrderDialog.defaultProps = {};

export default SelectTicketToAddOrderDialog;
