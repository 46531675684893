import { useTranslation } from "react-i18next";

import { useAuth } from "../context/auth";
import { useSnackbarAlert } from "../context/snackbarAlert";

import { useAsyncFn } from "./useAsync";

import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

function useLogout() {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const { logout, user } = useAuth();
  const logoutFn = useAsyncFn(logout);

  const { instance, inProgress } = useMsal();

  const onLogout = () => {
    return logoutFn
      .execute()
      .then(() => {
        snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.logged_out"));
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.server_error"));
      });
  };

  const onAadLogout = () => {
    onLogout()
      .then(() => {
        instance.logoutRedirect();
      })
      .catch((err) => console.log(err));
  };

  const isLoggingOut =
    logoutFn.loading || inProgress === InteractionStatus.Logout;

  const handleLogout = user.is_aad_user ? onAadLogout : onLogout;

  return {
    handleLogout,
    isLoggingOut,
  };
}

export default useLogout;
