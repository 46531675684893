import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SplitButton from "../SplitButton";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../../../context/auth";
import { getFieldValueFromSearchParams } from "../../../helpers/methods";

function TicketOfferButton(props) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuth();


  const selectedFilterKey = useMemo(() => {
    if (props.isTicketTableView) {
      return 'ticket_offer'
    } else {
      const filterValue = getFieldValueFromSearchParams( searchParams, 'creator');

      if (filterValue && filterValue === user.user_id) {
        return "my-offers"
      }
      return "all-offers"
    }

  }, [props.isTicketTableView, searchParams]);


  return (
    <SplitButton
      selectedItemKey={selectedFilterKey}
      withChangeSelectedItem={true}
      options={props.options}
    />
  );
}

TicketOfferButton.propTypes = {
  isTicketTableView: PropTypes.bool,
  options : PropTypes.array
};

TicketOfferButton.defaultProps = {
  isTicketTableView: true,
  options : []
};

export default TicketOfferButton;
