import React from "react";
import PropTypes from "prop-types";
import FullWidthTabs from "../FullWidthTabs/FullWidthTabs";
import { useTranslation } from "react-i18next";
import PartParamsTable from "../../table/PartParamsTable";
import FileUploadList from "../../other/FileUploadList";
import { INTERNAL_TYPE } from "../../../helpers/constants";
import BaseBox from "../../base/BaseBox/baseBox";


const ParamTableTab = React.memo(({ partsParamsData, readOnly, onHandleAddNewParameter, showParametersContextMenu, parametersContextMenuActions}) => {
  return (
    <PartParamsTable
      partsParamsData={partsParamsData}
      readOnly={readOnly}
      onHandleAddNewParameter={onHandleAddNewParameter}
      showParametersContextMenu = {showParametersContextMenu}
      parametersContextMenuActions ={parametersContextMenuActions}
    />
  );
});

const EnclosuresTab = React.memo((props) => {
  return (
    <BaseBox>
      <FileUploadList
        addEnclosureButtonProps={{ size: "mini" }}
        defaultEnclosureType={INTERNAL_TYPE}
        enclosures={props.enclosures}
        onPreviewEnclosure={props.previewEnclosure}
        onDownloadEnclosure={props.downloadEnclosure}
        onUpdateEnclosure={props.updateEnclosure}
        onDeleteEnclosure={props.deleteEnclosure}
        onAddEnclosure={props.addEnclosure}
        canRemoveEnclosures={true}
        readOnly={false}
        showExtendedAddEnclosureDialog={true}
        showSubcontractorSelect={false}
        showEnclosureType={false}
        showEnclosurePartKind = {true}
        allowCopyEnclosure = {false}
      />
    </BaseBox>
  );
});

const CertificatesTab = React.memo((props) => {
  return (
    <BaseBox>
      <FileUploadList
        addEnclosureButtonProps={{ size: "mini" }}
        enclosures={props.certificates}
        onPreviewEnclosure={props.previewCertificate}
        onDownloadEnclosure={props.downloadCertificate}
        onUpdateEnclosure={props.updateCertificate}
        onDeleteEnclosure={props.deleteCertificate}
        onAddEnclosure={props.addCertificate}
        canRemoveEnclosures={true}
        readOnly={false}
        fileType={"certs"}
      />
    </BaseBox>
  );
});


const MaterialParamRelatedTablesTabs = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <FullWidthTabs
      items={[
        {
          label: t("tabs.material_param_related_tables.params"),
          content: (
            <ParamTableTab
              partsParamsData={props.partsParamsData}
              readOnly={props.readOnly}
              onHandleAddNewParameter={props.onHandleAddNewParameter}
              showParametersContextMenu = {props.showParametersContextMenu}
              parametersContextMenuActions={props.parametersContextMenuActions}
            />
          ),
        },
        {
          label: t("tabs.material_param_related_tables.enclosures"),
          content: (
            <EnclosuresTab
              enclosures={props.enclosures}
              previewEnclosure={props.previewEnclosure}
              downloadEnclosure={props.downloadEnclosure}
              updateEnclosure={props.updateEnclosure}
              deleteEnclosure={props.deleteEnclosure}
              addEnclosure={props.addEnclosure}
            />
          ),
        },
        {
          label: t("tabs.material_param_related_tables.certificates"),
          content: (
            <CertificatesTab
              certificates={props.certificates}
              addCertificate={props.addCertificate}
              previewCertificate={props.previewCertificate}
              downloadCertificate={props.downloadCertificate}
              updateCertificate={props.updateCertificate}
              deleteCertificate={props.deleteCertificate}
            />
          ),
        },
      ]}
    />
  );
});

MaterialParamRelatedTablesTabs.propTypes = {
  partId: PropTypes.string,
  readOnly: PropTypes.bool,
};

MaterialParamRelatedTablesTabs.defaultProps = {
};

export default MaterialParamRelatedTablesTabs;
