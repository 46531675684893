import React from "react";
import PropTypes from "prop-types";
import TableService from "../../../services/tableService";
import FilterTable from "../FilterTable";

export default function MaterialReservationFilterTable(props) {
  const data = React.useMemo(
    () => {
        return TableService.getPreparedDataForCollapsibleTable(
          props.tableConfig,
          props.data,
          props.hiddenColumns,
          props.onClickMultipleLocationAlert
        );
    },
    [props.data, props.hiddenColumns, props.onClickMultipleLocationAlert]
  );

  return (
    <FilterTable
      checkedAll={props.checkedAll}
      onClickRow={props.onClickRow}
      showCheckbox={props.showCheckbox}
      onClickCheck={props.onClickCheck}
      onClickCheckAll={props.onClickCheckAll}
      showDetailsIcon={props.showDetailsIcon}
      showCounterRecords={props.showCounterRecords}
      data={data}
      showChangesCircle={props.showChangesCircle}
      hasChanges={data.hasChanges}
      filteringData={props.filteringData}
      countRecords={props.countRecords}
      page={props.page}
      onPageChange={props.onPageChange}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      rowsPerPageOptions={props.rowsPerPageOptions}
      resetPageNumber={props.resetPageNumber}
      headersConfig={TableService.getHeadersConfigForCollapsibleTable(
        props.tableConfig,
        props.filteringData,
        props.hiddenColumns
      )}
      selectedItemId={props.selectedOrderId}
      style={props.style}
      filterPrefix={props.filterPrefix}
      showCleanFilterIcon={props.showCleanFilterIcon}
      showFilters={props.showFilters}
      withPagination={props.withPagination}
      showExportToFileButton={props.showExportToFileButton}
      exportToFileUrl={props.exportToFileUrl}
      exportToFileSearchParams={props.exportToFileSearchParams}
      exportToFileHeaders={props.exportToFileHeaders}
      exportToFileFileName={props.exportToFileFileName}
    />
  );
}

MaterialReservationFilterTable.propTypes = {
  orderData: PropTypes.object,
  showCheckbox: PropTypes.bool,
  onClickCheck: PropTypes.func,
  onClickCheckAll: PropTypes.func,
  style: PropTypes.object,
  hiddenColumns: PropTypes.array,
  tableConfig: PropTypes.object,
  filterPrefix: PropTypes.string,
  showCleanFilterIcon: PropTypes.bool,
  showDetailsIcon: PropTypes.bool,
  showFilters: PropTypes.bool,
  withPagination: PropTypes.bool,
  showExportToFileButton: PropTypes.bool,
  exportToFileUrl: PropTypes.string,
  exportToFileSearchParams: PropTypes.object,
  exportToFileHeaders: PropTypes.array,
  exportToFileFileName: PropTypes.string,
  showCounterRecords: PropTypes.bool,
};

MaterialReservationFilterTable.defaultProps = {
  hiddenColumns: [],
  orderData: [],
  showCheckbox: false,
  showCleanFilterIcon: false,
  showDetailsIcon: false,
  showFilters: true,
  withPagination: true,
  showCounterRecords: true,
};
