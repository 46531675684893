import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextFieldFormControl from "../../field/TextFieldFormControl";



const OfferSummaryDetailsForm = (props) => {

  const { t } = useTranslation();

  const getServiceBaseCostRow = () => {
    return (
      <>
        <Grid item xs={10}>
          <TextFieldFormControl
            value={t("form.offer_decrement_form.services_base_price")}
            readOnly
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFormControl
            value={props.data?.offer_services_quatation}
            inputStyle={{ textAlign: "right"}}
            suffix={"zł"}
            readOnly
          />
        </Grid>
      </>
    )
  }

  const getMaterialsBaseCostRow = () => {
    return (
      <>
        <Grid item xs={10}>
          <TextFieldFormControl
            value={t("form.offer_decrement_form.materials_base_price")}
            readOnly
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFormControl
            value={props.data?.offer_materials_quatation}
            inputStyle={{ textAlign: "right"}}
            suffix={"zł"}
            readOnly
          />
        </Grid>
      </>
    )
  }

  const getMarginDataRow = () => {
    return (
      <>
        <Grid item xs={8}>
          <TextFieldFormControl
            value={t("form.offer_decrement_form.total_margin")}
            readOnly
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFormControl
            value={props.data?.offer_margin_proc}
            inputStyle={{ textAlign: "right"}}
            suffix={"%"}
            readOnly
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFormControl
            value={props.data?.offer_quatation_margin}
            inputStyle={{ textAlign: "right"}}
            suffix={"zł"}
            readOnly
          />
        </Grid>
      </>
    )
  }

  const getDiscountDataRow = () => {
    return (
      <>
        <Grid item xs={4}>
          <TextFieldFormControl
            label={t("form.offer_decrement_form.discount_kind")}
            value={props.data?.offer_discount_kind}
            readOnly
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFormControl
            label={t("form.offer_decrement_form.discount_visibility")}
            value={props.data?.offer_discount_visibility ? t("true") : t("false")}
            readOnly
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFormControl
            value={props.data?.offer_discount_proc}
            inputStyle={{ textAlign: "right"}}
            suffix={"%"}
            readOnly
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFormControl
            value={props.data?.offer_quatation_discount}
            inputStyle={{ textAlign: "right"}}
            prefix={"-"}
            suffix={"zł"}
            readOnly
          />
        </Grid>
      </>
    )
  }

  const getTotalNettoValueRow = () => {
    return (
      <>
        <Grid item xs={10}>
          <TextFieldFormControl
            value={t("form.offer_decrement_form.total_offer_value")}
            readOnly
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFormControl
            value={props.data?.offer_quatation_net}
            inputStyle={{ textAlign: "right"}}
            suffix={"zł"}
            readOnly
          />
        </Grid>
      </>
    )
  }

  const getOfferVatValueRow = () => {
    return (
      <>
        <Grid item xs={10}>
          <TextFieldFormControl
            value={t("form.offer_decrement_form.offer_vat_value")}
            readOnly
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFormControl
            value={props.data?.offer_quatation_vat}
            inputStyle={{ textAlign: "right"}}
            suffix={"zł"}
            readOnly
          />
        </Grid>
      </>
    )
  }

  const getOfferBruttoValueRow = () => {
    return (
      <>
        <Grid item xs={10}>
          <TextFieldFormControl
            value={t("form.offer_decrement_form.offer_brutto_value")}
            readOnly
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFormControl
            value={props.data?.offer_quatation_gross}
            inputStyle={{ textAlign: "right"}}
            suffix={"zł"}
            readOnly
          />
        </Grid>
      </>
    )
  }

  return (

    <Grid
      container spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="center">


      {getServiceBaseCostRow()}
      {getMaterialsBaseCostRow()}
      {getMarginDataRow()}
      {getDiscountDataRow()}
      {getTotalNettoValueRow()}
      {getOfferVatValueRow()}
      {getOfferBruttoValueRow()}
    </Grid>

  );
};

OfferSummaryDetailsForm.propTypes = {
  data: PropTypes.object
};

OfferSummaryDetailsForm.defaultProps = {
  data: {}
};

export default OfferSummaryDetailsForm;
