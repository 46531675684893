import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import DateField from "../../field/DateField/DateField";
import AutocompleteField from "../../field/AutocompleteField";
import FloatField from "../../base/FloatField/FloatField";
import MaskedTextFieldForm from "../../base/MaskedTextFieldForm";

const AgreementGuaranteeForm = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12}>
        <FloatField
          name="guarantee_value"
          label={t("form.agreements_guarantee_form.guarantee_value")}
          value={props.agreementGuaranteeData?.guarantee_value}
          readOnly={props.readOnly}
          onChange={props.onChange}
          suffix={"PLN"}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="guarantee_validity"
          label={t("form.agreements_guarantee_form.guarantee_validity")}
          value={props.agreementGuaranteeData?.guarantee_validity}
          readOnly={props.readOnly}
          onChange={props.onChange}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <MaskedTextFieldForm
          mask={"ZABEZ/****/99"}
          onChange={props.onChange}
          disabled={props.readOnly}
          value={props.agreementGuaranteeData?.guarantee_location}
          maskChar={"_"}
          name="guarantee_location"
          label={t("form.agreements_guarantee_form.guarantee_location")}
          showHelperText={!props.isLocationFormatValid}
          FormHelperTextProps={{ sx: { color: 'red' } }}
          helperText={t("form.agreements_details_form.uncorrect_location_format")}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteField
          name={"guarantee_category"}
          label={t("form.agreements_guarantee_form.guarantee_category")}
          value={props.agreementGuaranteeData?.guarantee_category}
          options={props.filteringData?.value?.guarantee_category}
          isObjectOption={false}
          onChange={props.onChange}
          addNewValue={false}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <DateField
          name="guarantee_date_contract"
          label={t("form.agreements_details_form.guarantee_date_contract")}
          value={props.agreementGuaranteeData?.guarantee_date_contract}
          readOnly={props.readOnly}
          onChange={props.onChangeDate}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <DateField
          name="guarantee_date_close"
          label={t("form.agreements_details_form.guarantee_date_close")}
          value={props.agreementGuaranteeData?.guarantee_date_close}
          readOnly={props.readOnly}
          onChange={props.onChangeDate}
          required
        />
      </Grid>
    </Grid>
  );
};

AgreementGuaranteeForm.propTypes = {
  agreementData: PropTypes.object,
  readOnly: PropTypes.bool,
  filteringData: PropTypes.object,
  agreementSubkindSelectList: PropTypes.array,
  isLocationFormatValid : PropTypes.bool
};

AgreementGuaranteeForm.defaultProps = {
  readOnly: false,
  filteringData: { value: [] },
  valueagreementSubkindSelectList: [],
  isLocationFormatValid : true
};

export default AgreementGuaranteeForm
