import {
  convertUndefinedValueToZero,
  roundPrice
} from "../helpers/methods";

export default function useItemCalculationService() {


  const calculateMarginItemProc = (price, margin_pln) => {
    if(price){
      return roundPrice(convertUndefinedValueToZero(margin_pln) / convertUndefinedValueToZero(price) * 100);
    }
    return roundPrice(0)
  };
  const calculateMarginItemPln = (price, margin_proc) => {
      return roundPrice(convertUndefinedValueToZero(margin_proc) * convertUndefinedValueToZero(price) / 100);
  };

  const onChangeCalculatedFields = (e, itemType, formValue, setFormValue) => {
    const { name, value } = e.target;
    let calculatedDict = {[name]: value}
    let calculated_field_name = ''
    let calculated_field_value = 0
    if (name === `${itemType}_margin_min_pln`){
        calculated_field_name = `${itemType}_margin_min_proc`
        calculated_field_value = calculateMarginItemProc(formValue[`${itemType}_price`], value)  
    } else if (name === `${itemType}_margin_min_proc`){
      calculated_field_name = `${itemType}_margin_min_pln`
      calculated_field_value = calculateMarginItemPln(formValue[`${itemType}_price`], value)      
    } else if (name === `${itemType}_price`){
      calculated_field_name = `${itemType}_margin_min_pln`
      calculated_field_value = calculateMarginItemPln(value, formValue[`${itemType}_margin_min_proc`])    
  }
    calculatedDict[calculated_field_name] = calculated_field_value 

    setFormValue((formValue) => ({ ...formValue, ...calculatedDict}));
  };

  const getVatRate = (itemType = undefined) =>{
    // if (itemType === 'service'){
    //   return 23
    // }
    return 23
  }

  const calculatePoLineValue = (element) => {
    let actual_price = parseFloat(element.poli_price_real) ? element.poli_price_real : element.poli_price_plan
    element.poli_price_value = convertUndefinedValueToZero(actual_price) * convertUndefinedValueToZero(element.poli_amount) 
    return element
  }

  const getTotalPoValueByLines = (poLines) =>{
    let sum = 0
    for (let poLine of poLines){
      sum = sum + parseFloat(convertUndefinedValueToZero(poLine.poli_price_value))
    }

    return sum
  }

  const getPoLinesFieldsNameToSave = () => {
    return [
      "id",
      "poli_amount",
      "poli_unit",
      "poli_note",
      'isDeleted'
    ];
  };

  const calculateVatGrossInFV = (fvElement) =>{
    fvElement.fv_vat = roundPrice(convertUndefinedValueToZero(fvElement.fv_net) * 0.23)
    fvElement.fv_gross = roundPrice(parseFloat(convertUndefinedValueToZero(fvElement.fv_net)) + parseFloat(fvElement.fv_vat))
    return fvElement
  }

  return {
    calculateMarginItemProc,
    calculateMarginItemPln,
    onChangeCalculatedFields,
    getVatRate,
    calculatePoLineValue,
    getTotalPoValueByLines,
    getPoLinesFieldsNameToSave,
    calculateVatGrossInFV
  };
}
