
import { hideParameterValueDetailsUrl, itemDataUrl, itemLocationRecordUrl, itemSelectListUrl, itemStructureUrl, itemUrl, parameterDataUrl, parameterValueDataUrl, parameterValueDetailsUrl, parametersSelectListUrl, partParameterDataUrl } from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import {
  isEmptyObject,
  prepareUrlWithQueryParams
} from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function useItemService() {
  const axiosPrivate = useAxiosPrivate();

  const getItemsSelectList = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(itemSelectListUrl, searchParams)
    );
  };


  const getEmptyItemLevel = (parent = null, level = 1) => {
    return {
      parent: parent,
      level: level,
    };
  };

  const getItemRecordById = (itemId) =>{
    return makeRequest(
      axiosPrivate,
      itemLocationRecordUrl(itemId)
    );
  }

  const getEmptyItemElement = (parent = null, level = 1) => {
    return [getEmptyItemLevel(parent, level)];
  };

  const getParametersList = () =>{
    return makeRequest( axiosPrivate, parametersSelectListUrl);
  };

  const getPartParametersList = (partId) => {
    return makeRequest(
      axiosPrivate,
      partParameterDataUrl(partId)
    );
  }

  const createParameter = (parameterData) => {
    return makeRequest(axiosPrivate, parameterDataUrl, {
      method: "POST",
      data: parameterData,
    });
  };

  const createParameterValue = (parameterData) => {
    return makeRequest(axiosPrivate, parameterValueDataUrl, {
      method: "POST",
      data: parameterData,
    });
  };

  const updateParameterValue = (parameterValueId, parameterData) => {
    return makeRequest(axiosPrivate, parameterValueDetailsUrl(parameterValueId), {
      method: "PATCH",
      data: parameterData,
    });
  };

  const removeParameterValue = (parameterValueId, parameterData) => {
    return makeRequest(axiosPrivate, hideParameterValueDetailsUrl(parameterValueId), {
      method: "PATCH",
      data: parameterData,
    });
  };

  
  const prepareItemDataFromBackendForItemRecordComponent = (
    item, addEmptyLevel = true
  ) => {

    if (!item || isEmptyObject(item)) return getEmptyItemElement();
 
    let preparedItem = item;
    preparedItem  = 
                    Object.values(item)
                      .filter((level) => level !== null)
                      .map((level, idx) => ({
                        id: level.id,
                        parent: level.item_parent,
                        level: idx,
                      }))
  
    if (addEmptyLevel && preparedItem.length ){
      preparedItem = [
        ...preparedItem,
        getEmptyItemLevel(
          preparedItem[preparedItem.length - 1].id,
          preparedItem.length
        ),
      ];
    }

    return preparedItem
  
  };

  

  const getBranchStructure = (searchParams = {}) => {
    return makeRequest( axiosPrivate, 
      prepareUrlWithQueryParams(itemStructureUrl, searchParams));
  };

  const getItemData =  (itemId) => {
    return makeRequest(
      axiosPrivate,
      itemDataUrl(itemId)
    );
  }
  
  const createItem = (itemData) => {
    return makeRequest(axiosPrivate, itemUrl, {
      method: "POST",
      data: itemData,
    });
  };

  const updateItem = (itemId, itemData) => {
    return makeRequest(axiosPrivate, itemDataUrl(itemId), {
      method: "PATCH",
      data: itemData,
    });
  };

  
  const removeItem = (itemId) => {
    return makeRequest(axiosPrivate, itemDataUrl(itemId), {
      method: "DELETE",
    });
  };


  return {
    getItemsSelectList,
    getEmptyItemElement,
    getParametersList,
    createParameter,
    getPartParametersList,
    createParameterValue,
    updateParameterValue,
    removeParameterValue,
    prepareItemDataFromBackendForItemRecordComponent,
    getItemRecordById,
    getBranchStructure,
    getItemData,
    createItem,
    updateItem,
    removeItem
  };
}
