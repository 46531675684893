import { useState } from "react";
import useDialog from "./useDialog";

const useDialogWithFormValue = ({
  initialOpen = false,
  initialFormValue = null,
  formValueConverter = null,
} = {}) => {
  const [openDialog, handleOpenDialog, handleCloseDialog] =
    useDialog(initialOpen);

  const [formValue, setFormValue] = useState(() => {
    if (initialFormValue && formValueConverter)
      return formValueConverter(initialFormValue);
    return initialFormValue;
  });

  const handleOpenDialogWithFormValue = (
    formValue,
    useFormValueConverter = true
  ) => {
    if (useFormValueConverter && formValue && formValueConverter)
      setFormValue(formValueConverter(formValue));
    else setFormValue(formValue);
    handleOpenDialog();
  };

  const handleCloseDialogWithFormValue = () => {
    setFormValue(null);
    handleCloseDialog();
  };

  return [
    openDialog,
    formValue,
    handleOpenDialogWithFormValue,
    handleCloseDialogWithFormValue,
  ];
};

export default useDialogWithFormValue;
