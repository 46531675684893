import * as React from "react";
import PropTypes from "prop-types";
import {
  StyledTableRow,
} from "../../base/BaseTable/BaseTable";
import FieldTable from "../../field/FieldTable/FieldTable";
import StyledCellEditableTableBody from "./StyledCellEditableTableBody"
import { useTranslation } from "react-i18next";
import { BUTTON_FIELD_TYPE, TEXT_FIELD_TYPE } from "../../../helpers/constants";
import DivField from "../../field/DivField/DivField";


const EditableTableSummaryRow = React.memo(
  ({
    showCheckbox,
    data,
  }) => {

    const { t } = useTranslation();
    return (
      <StyledTableRow  >
        {showCheckbox && (
         <StyledCellEditableTableBody readOnly = {false}  > 
           </StyledCellEditableTableBody>
        )}
        {data.map((field, index) => (
          <StyledCellEditableTableBody readOnly = {false}  > 
                   {field.dataType !== BUTTON_FIELD_TYPE ?
            <FieldTable
              type={field.dataType}
              name={field.name}
              value={index === 0 ? t("summary") : field.value}
              editable={false }
              fieldConfig={field?.fieldConfig}
            />:
            <DivField
            readOnly={true}
            inputProps={{style : {minWidth: "initial"}}}/>
            }
          </StyledCellEditableTableBody>
        ))}
      </StyledTableRow>
    );
  }
);

export default EditableTableSummaryRow;

EditableTableSummaryRow.propTypes = {
  showCheckbox: PropTypes.bool,
  fieldConfig: PropTypes.obj,
  data : PropTypes.obj
};

EditableTableSummaryRow.defaultProps = {
  showCheckbox: true
};
