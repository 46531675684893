import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { STOCK_WAREHOUSE_TABLE_FIELDS_CONFIG, STOCK_WAREHOUSE_TABLE_NAME } from "./PageTablesConfig";
import useUserPreferencesService from "../../services/userPreferencesService";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { useEmptyArrayMemo } from "../../hooks/useEmptyArrayMemo";
import useWarehouseService from "../../services/warehouseService";
import FilterTable from "../../components/table/FilterTable";
import TableService from "../../services/tableService";
import WarehouseManagmentToolBar from "../../components/bar/WarehouseManagmentToolBar/WarehouseManagmentToolBar";
import useDialog from "../../hooks/useDialog";
import UniversalFilterForm from "../../components/form/UniversalFilterForm";
import FilterDialog from "../../components/base/FilterDialog";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import { NAVIGATION_DRAWER_TYPE_WAREHOUSES } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import CreateItemDialog from "../../components/dialog/CreateItemDialog";
import DetailsDrawer from "../../components/drawer/UniversalDetailsDrawer/UniversalDetailsDrawer";
import useBasicDrawer from "../../hooks/useBasicDrawer";
import useOfferService from "../../services/offerService";
import { MANAGE_PARTS_PERMISSION } from "../../helpers/constants";
import useCheckPermission from "../../hooks/usePermission";
import UserFilterDialog from "../../components/dialog/UserFilterDialog";
import { exportStocksToFileUrl } from "../../helpers/apiUrls";

export default function StockWarehousePage(props) {
  const { pageName } = props;

  const { t } = useTranslation();


  const snackbarAlert = useSnackbarAlert();
  const [stocksLocal, setStocksLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState([]);
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState([]);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useBasicDrawer(false);
  const [partItem, setPartItem] = useState();
  const selectedPartIdRef = useRef();
  const selectedStockIdRef = useRef();
  const [hasPermissionToCreate] = useCheckPermission(MANAGE_PARTS_PERMISSION)


  const [
    openCreateItemDialog,
    onOpenCreateItemDialog,
    onCloseCreateItemDialog,
  ] = useDialog();

  const handleOpenCreateItemDialog = () => {
    onOpenCreateItemDialog();
  };

  const [openFilterDialog,
    onOpenFilterDialog,
    onCloseFilterDialog] =
    useDialog();

  const [
    openUserFiltersDialog,
    onOpenUserFiltersDialog,
    onCloseUserFiltersDialog,
  ] = useDialog();


  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const onCleanFlitersInFilterDialog = () => {
    setSearchParams({});
  };

  const {
    page: stockDataPage,
    pageSize: stockDataPageSize,
    handleChangePageWithSearchParams: handleChangeStockDataPage,
    handleChangePageSizeWithSearchParams: handleChangeStockDataPageSize,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const handleChangeRowsPerPage = (e) => {
    handleChangeStockDataPageSize(e, parseInt(e.target.value, 10));
  };

  const {
    getStockWarehouseList,
    getStockWarehouseFilteringData
  } = useWarehouseService()

  const {
    getPartDetailsData
  } = useOfferService()


  const partDetailsFn = useAsyncFn(getPartDetailsData)

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const stocksData = useAsync(
    () => getStockWarehouseList(searchParams),
    [searchParams]
  );

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  useEffect(() => {
    if (userPreferencesForPage.loading) {
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [STOCK_WAREHOUSE_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [STOCK_WAREHOUSE_TABLE_NAME],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);



  const stocksFilteringData = useAsync(getStockWarehouseFilteringData);

  const { emptyArrayMemo } = useEmptyArrayMemo()

  useEffect(() => {
    if (stocksData.loading) {
      return;
    }
    setStocksLocal(stocksData.value.results);
    setCountRecords(stocksData.value.count);
  }, [stocksData.loading]);

  const onRefetchDrawerData = () => {
    partDetailsFn.execute(selectedPartIdRef.current).then((data) => {
      setPartItem(data);
    });
  };

  const handleClickRow = (stockId) => {
    let part_id = stocksLocal.find(stock => stock.id === stockId)?.part_id

    if (!stockId || stockId === selectedStockIdRef.current) {
      selectedStockIdRef.current = null;
      setOpenDetailsDrawer(false);
      setPartItem(undefined);
    } else {
      selectedStockIdRef.current = stockId;
      setOpenDetailsDrawer(true);
    }
    if (!part_id || part_id === selectedPartIdRef.current) {
      selectedPartIdRef.current = null;
    } else {
      selectedPartIdRef.current = part_id;
      onRefetchDrawerData();
    }
  };

  const handleUpdateUserPreferences = (tablesHiddenColumns,
    tablesColumnOrders) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const stockExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      STOCK_WAREHOUSE_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[STOCK_WAREHOUSE_TABLE_NAME],
      columnsOrdersForTables?.[STOCK_WAREHOUSE_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[STOCK_WAREHOUSE_TABLE_NAME],
    columnsOrdersForTables?.[STOCK_WAREHOUSE_TABLE_NAME],
    STOCK_WAREHOUSE_TABLE_FIELDS_CONFIG,
  ]);

  const isLoading =
    stocksLocal === undefined||
    countRecords === undefined;

  const data = React.useMemo(
    () =>

      TableService.getPreparedDataForCollapsibleTable(
        STOCK_WAREHOUSE_TABLE_FIELDS_CONFIG,
        stocksLocal ? stocksLocal : [],
        hiddenColumnsForTables[STOCK_WAREHOUSE_TABLE_NAME] || emptyArrayMemo,
        columnsOrdersForTables[STOCK_WAREHOUSE_TABLE_NAME] || emptyArrayMemo,
      ),

    [stocksLocal, hiddenColumnsForTables[STOCK_WAREHOUSE_TABLE_NAME], columnsOrdersForTables[STOCK_WAREHOUSE_TABLE_NAME], STOCK_WAREHOUSE_TABLE_FIELDS_CONFIG, userPreferencesForPage.loading]
  );



  return (
    <DetailsDrawer
      openDrawer={openDetailsDrawer}
      setOpenDrawer={setOpenDetailsDrawer}
      itemData={partItem}
      itemType={"part"}
      readOnly={true}
      partChoiceSelectData={stocksFilteringData}
    >
      <NavigationDrawer
        pageName={pageName}
        drawerType={NAVIGATION_DRAWER_TYPE_WAREHOUSES}
      >
        <DefaultPageWrapper titleKey={"stocks"}>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={1}
          >

            <Grid item xs={12}>
              <WarehouseManagmentToolBar
                style={{ marginBlock: "5px" }}
                onClickCreateItemDialog={onOpenCreateItemDialog}
                onClickAdjustTable={onOpenTableColumnVisibilityDialog}
                onClickMyFilters={onOpenUserFiltersDialog}
                onClickSearch={onOpenFilterDialog}
                hasPermissionToCreate={hasPermissionToCreate}
              />
            </Grid>
            <Grid item xs={12}>
              <LoaderWrapper showLoader={isLoading} >
                <FilterTable
                  data={data}
                  onClickRow={handleClickRow}
                  selectedItemId={selectedStockIdRef.current}
                  showCheckbox={false}
                  showCleanFilterIcon={true}
                  countRecords={countRecords}
                  page={stockDataPage}
                  onPageChange={handleChangeStockDataPage}
                  rowsPerPage={stockDataPageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  filteringData={stocksFilteringData}
                  style={{ maxHeight: "vh" }}
                  tableConfig={STOCK_WAREHOUSE_TABLE_FIELDS_CONFIG}
                  showContextMenu={false}
                  headersConfig={TableService.getHeadersConfigForCollapsibleTable(
                    STOCK_WAREHOUSE_TABLE_FIELDS_CONFIG,
                    stocksFilteringData || emptyArrayMemo,
                    hiddenColumnsForTables[STOCK_WAREHOUSE_TABLE_NAME] || emptyArrayMemo,
                    columnsOrdersForTables[STOCK_WAREHOUSE_TABLE_NAME] || emptyArrayMemo,
                  )}
                  showExportToFileButton={true}
                  exportToFileUrl={exportStocksToFileUrl}
                  exportToFileSearchParams={searchParams}
                  exportToFileHeaders={stockExportHeaders}
                  exportToFileFileName={`${t(
                    "page.stock_warehouse_page.warehouse_stock"
                  ).replace(" ", "_")}.xlsx`}
                />
              </LoaderWrapper>

            </Grid>
          </Grid>

          <FilterDialog
            open={openFilterDialog}
            onCleanFilters={onCleanFlitersInFilterDialog}
            onClose={onCloseFilterDialog}
            resetPageNumber={resetPageNumber}
            filterForm={
              <UniversalFilterForm
                filteringData={stocksFilteringData || emptyArrayMemo}
                filtersConfig={STOCK_WAREHOUSE_TABLE_FIELDS_CONFIG}
                includeOpenCloseFilter={false}
              />
            }
          />
          {openCreateItemDialog && (
            <CreateItemDialog
              open={openCreateItemDialog}
              onClose={onCloseCreateItemDialog}
              onSubmit={handleOpenCreateItemDialog}
              isLoading={userPreferencesForPage.loading}
              itemType={'part'}

            />

          )}

          {openTableColumnVisibilityDialog && (
            <TableColumnVisibilityDialog
              open={openTableColumnVisibilityDialog}
              onClose={onCloseTableColumnVisibilityDialog}
              onSubmit={handleUpdateUserPreferences}
              tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
                [{ name: STOCK_WAREHOUSE_TABLE_NAME, config: STOCK_WAREHOUSE_TABLE_FIELDS_CONFIG }],
                columnsOrdersForTables
              )}
              tablesHiddenColumns={hiddenColumnsForTables}
              isLoading={userPreferencesForPage.loading}
            />
          )}


          {openUserFiltersDialog && (
            <UserFilterDialog
              open={openUserFiltersDialog}
              onClose={onCloseUserFiltersDialog}
              pageName={pageName}
              filterForm={
                <UniversalFilterForm
                filteringData={stocksFilteringData || emptyArrayMemo}
                  filtersConfig={ STOCK_WAREHOUSE_TABLE_FIELDS_CONFIG}
                  includeOpenCloseFilter={false}
                />
              }
            />
          )}
        </DefaultPageWrapper>
      </NavigationDrawer>
    </DetailsDrawer>
  );
}
