import React from "react";
import PropTypes from "prop-types";
import { Grid} from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import BaseBox from "../../base/BaseBox/baseBox";
import AutocompleteField from "../../field/AutocompleteField";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";
import SelectField from "../../field/SelectField";


const OfferDiscountForm = ({
  data,
  filteringData,
  onChange,
  onChangeAutocompletedOfferDetails,
  readOnly }) => {

    const onClear = (e, name) => {
      e = { ...e, target: { ...e.target, value: null, name: name } }
      onChange(e)
    }

  const { t } = useTranslation();

  return (
    <BaseBox>
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {
          readOnly ?
            <TextFieldFormControl
              label={t("form.offer.discount_kind")}
              value={data?.offer_discount_kind}
              disabled
            />
            :
            <SelectField
              label={t("form.offer.discount_kind")}
              name={"offer_discount_kind"}
              value={data?.offer_discount_kind}
              options={filteringData?.discount_types}
              isObjectOption={false}
              onChange={onChange}
              onClear={(e) => onClear(e, "offer_discount_kind")}
              addNewValue={false}
            />
        }
      </Grid>
      <Grid item xs={12}>
        {
          readOnly ?
            <TextFieldFormControl
              label={t("form.offer.discount_visibility")}
              value={data?.offer_discount_visibility ? t("true") : t("false")}
              disabled
            />
            :
            <TrueFalseSelectField
              name={"offer_discount_visibility"}
              label={t("form.offer.discount_visibility")}
              value={data?.offer_discount_visibility}
              onChange={onChange}
              valuesAsBool={true}
              addEmptyOptions={false}
            />
        }
      </Grid>
      </Grid>
    </BaseBox>
  );
};

OfferDiscountForm.propTypes = {
  onSubmit: PropTypes.func,
  ticketId: PropTypes.string,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  onOpenAddNewSupplier: PropTypes.func,
  subcontractorSelectList: PropTypes.array
};

OfferDiscountForm.defaultProps = {
  readOnly: false,
  subcontractorSelectList: []
};

export default OfferDiscountForm;
