import React from "react";
import FilterTable from "../FilterTable";
import TableService from "../../../services/tableService";
import PropTypes from "prop-types";

function OrderPlannerTable(props) {
  const orders = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        props.tableConfig,
        props.orders || [],
        props.hiddenColumns,
        props.columnsOrders,
        props.onClickMultipleLocationAlert
      ),
    [props.orders, props.hiddenColumns, props.columnsOrders, props.tableConfig]
  );

  return (
    <FilterTable
      data={orders || []}
      headersConfig={TableService.getHeadersConfigForCollapsibleTable(
        props.tableConfig,
        props.filteringData,
        props.hiddenColumns,
        props.columnsOrders
      )}
      draggableRows={props.draggableRows}
      showChangesCircle={props.showChangesCircle}
      onDragEndRow={props.onDropRow}
      onDragStartRow={props.onDragStartRow}
      withPagination={true}
      showCheckbox={false}
      filteringData={props.filteringData}
      style={{ maxHeight: "300px" }}
      filterPrefix={props.filterPrefix}
      onClickRow={props.onClickRow}
      contextMenuActions={props.contextMenuActions}
      showContextMenu={props.showContextMenu}
      selectedItemId={props.selectedItemId}
      showCleanFilterIcon={props.showCleanFilterIcon}
      countRecords={props.countRecords}
      page={props.page}
      onPageChange={props.onPageChange}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      resetPageNumber={props.resetPageNumber}
      showDetailsIcon={props.showDetailsIcon}
      itemDetailsLinkPrefix={"order_details"}
    />
  );
}

OrderPlannerTable.propTypes = {
  orders: PropTypes.array,
  tableConfig: PropTypes.object,
  filteringData: PropTypes.object,
  draggableRows: PropTypes.bool,
  onDropRow: PropTypes.func,
  hiddenColumns: PropTypes.array,
  columnsOrders: PropTypes.array,
  filterPrefix: PropTypes.string,
  onClickRow: PropTypes.func,
  contextMenuActions: PropTypes.array,
  showContextMenu: PropTypes.bool,
  selectedItemId: PropTypes.string,
  countRecords: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  resetPageNumber: PropTypes.func,
  onClickMultipleLocationAlert: PropTypes.func,
  showChangesCircle: PropTypes.bool,
  showDetailsIcon: PropTypes.bool,
};

OrderPlannerTable.defaultProps = {
  orders: [],
  tableConfig: {},
  filteringData: {},
  draggableRows: true,
  showDetailsIcon: true,
};

export default React.memo(OrderPlannerTable);
