import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import useUserService from "../../../services/userService";
import { useAsync } from "../../../hooks/useAsync";
import BaseTable from "../../base/BaseTable/BaseTable";
import { useEmptyArrayMemo } from "../../../hooks/useEmptyArrayMemo";
import TableService from "../../../services/tableService";
import {  OWNER_LOGS_TABLE_FIELDS_CONFIG } from "./DialogTablesConfig";

const OwnerHistoryLogDialog = (props) => {
  const { t } = useTranslation();

  const {
    getOwnerLogs } = useUserService();

  const ownerLogData = useAsync(
    () => getOwnerLogs(props.ownerId),
    [props.ownerId]
  );

  const { emptyArrayMemo } = useEmptyArrayMemo()

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={t(`dialog.owner_history_log_dialog.history_log_changes`)}
      maxWidth="xl"
      showDialogActions
    >
      <LoaderWrapper showLoader={ownerLogData.loading} >
      <BaseTable
        headers={TableService.getHeaderLables(
          OWNER_LOGS_TABLE_FIELDS_CONFIG
        )}
        rows={TableService.getPreparedDataForBaseTable(
          OWNER_LOGS_TABLE_FIELDS_CONFIG ,
          ownerLogData.value? ownerLogData.value : emptyArrayMemo
        )}
      />
      </LoaderWrapper>
    </BasicDialog>
  );
};

OwnerHistoryLogDialog.propTypes = {
  ownerId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool
};

OwnerHistoryLogDialog.defaultProps = {
  open: false,
  readOnly: true
};

export default OwnerHistoryLogDialog;
