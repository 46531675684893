import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TuneIcon from "@mui/icons-material/Tune";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { centerVericalAlignIconStyle, overflowButtonStyle } from "../../../helpers/styles";
import SearchButton from "../../button/SearchButton/SearchButton";
import { isSearchParamsForFilteringEmpty } from "../../../helpers/methods";
import { useSearchParams } from "react-router-dom";

export default function WarehouseManagmentToolBar(props) {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  
  const isFilterSet =
    props.isFilterSet !== undefined
      ? props.isFilterSet
      : !isSearchParamsForFilteringEmpty(searchParams);
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      columnSpacing={2}
      rowSpacing={2}
      style={props.style}
    >

      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<TuneIcon sx={centerVericalAlignIconStyle} />}
          onClick={props.onClickAdjustTable}
          sx={overflowButtonStyle}
        >
          {t("bar.tool_bar.adjust_table")}
        </Button>
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<FilterAltIcon sx={centerVericalAlignIconStyle} />}
          onClick={props.onClickMyFilters}
          sx={overflowButtonStyle}
          color={isFilterSet ? "success" : "primary"}
        >
          {t("bar.tool_bar.my_filters")}
        </Button>
      </Grid>
      <Grid item xs={12} sm={2}>
        {/* {props.hasPermissionToCreate &&
          <Button
            fullWidth
            variant="contained"
            size="small"
            startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
            onClick={props.onClickCreateItemDialog}
          >
            {t("bar.warehouse_tool_bar.add_item")}
          </Button>
        } */}
      </Grid>
      <Grid item xs={12} sm={5}></Grid>
      <Grid item xs={12} sm={1}>
        <SearchButton onClickSearch={props.onClickSearch} />
      </Grid>
    </Grid>
  );
}

WarehouseManagmentToolBar.propTypes = {
  style: PropTypes.object,
  onClickAdjustTable: PropTypes.func,
  onClickCreateAddItem: PropTypes.func,
  onClickCreateItemDialog: PropTypes.func,
  setVisibilityTicketTableView: PropTypes.func,
  isTicketTableView: PropTypes.bool,
  massActionButtonOptions: PropTypes.array,
  hasPermissionToCreate: PropTypes.bool
};

WarehouseManagmentToolBar.defaultProps = {
  isTicketTableView: true,
  massActionButtonOptions: [],
  hasPermissionToCreate: false
};
