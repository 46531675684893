import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid, IconButton, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import AutocompleteField from "../../field/AutocompleteField";

import MiniButton from "../../base/MiniButton/MiniButton";
import WindowService from "../../../services/windowService";

const MergedTicketRecord = React.memo((props) => {
  const { t } = useTranslation();

  const onChange = (e, value_object, value_key, state_value_name) => {
    props.onChange(props.index, value_object[value_key]);
  };

  return (
    <Grid container spacing={1} marginY={0.5}>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
            <Typography variant="subtitle2" className="capitalize-first-letter">
              {t("other.merged_ticket_record.ticket")}
              {props.showRelationNumber ? ` ${props.index + 1}` : ""}
            </Typography>
            {props.ticket && (
              <IconButton
                aria-label="info"
                size="small"
                sx={{ padding: 0, paddingLeft: 0.5, color: "var(--primary)" }}
                onClick={() => {
                  WindowService.openInNewTab(`/ticket_details/${props.ticket}`);
                }}
              >
                <InfoIcon fontSize="inherit" />
              </IconButton>
            )}
            {props.showButtonDeleteRelation && (
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ padding: 0, paddingLeft: 0.5, color: "var(--alert)" }}
                onClick={() => {
                  props.onDeleteRelation(props.index);
                }}
              >
                <RemoveCircleIcon fontSize="inherit" />
              </IconButton>
            )}
          </Grid>
          <Grid item sm={6} xs={6} textAlign={"end"}>
            {props.showButtonAddRelation && (
              <MiniButton
                variant="contained"
                size="small"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={props.onAddRelation}
              >
                {t("other.merged_ticket_record.add_ticket")}
              </MiniButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <AutocompleteField
          label={t("other.merged_ticket_record.ticket")}
          value={props.ticket}
          options={props.ticketSelectList}
          isObjectOption={true}
          optionLabelKey={"ticket_descriptive_name"}
          addNewValue={false}
          onChange={onChange}
          required
        />
      </Grid>
    </Grid>
  );
});

MergedTicketRecord.propTypes = {
  index: PropTypes.number,
  ticket: PropTypes.string,
  tictic_kind: PropTypes.string,
  tictic_note: PropTypes.string,
  onChange: PropTypes.func,
  showRelationNumber: PropTypes.bool,
  showButtonDeleteRelation: PropTypes.bool,
  onDeleteRelation: PropTypes.func,
  showButtonAddRelation: PropTypes.bool,
  onAddRelation: PropTypes.func,
  ticketSelectList: PropTypes.array,
};

MergedTicketRecord.defaultProps = {
  showRelationNumber: true,
  showButtonDeleteRelation: true,
  showButtonAddRelation: false,
};

export default MergedTicketRecord;
