import PropTypes from "prop-types";

import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import useUserService from "../../../services/userService";
import useSkillService from "../../../services/skillService";
import { useAsync } from "../../../hooks/useAsync";

import LoaderWrapper from "../../../components/wrapper/LoaderWrapper";
import SelectField from "../../field/SelectField";
import AutocompleteField from "../../../components/field/AutocompleteField";
import DateField from "../../field/DateField/DateField";

import { useForm } from "../../../hooks/useForm";

import useFilterSearchParams from "../../../hooks/useFilterSearchParams";
import DateService from "../../../services/dateService";

import {
  getSearchParamsObjectForFilterPrefix,
  getObjectWithoutKeys,
} from "../../../helpers/methods";

export default function RcpScheduleDialogSearchBar(props) {
  const { t } = useTranslation();

  const { getUserHasSkill } = useUserService();
  const { getSkillSelectList } = useSkillService();

  const userList = useAsync(() =>
    getUserHasSkill({ only_employee: true, roles: "role_is_t" })
  );
  const skillList = useAsync(getSkillSelectList);

  const [searchParams, setSearchParams] = useSearchParams();
  const { getSearchParamsByFilterPrefix, clearSearchParamsByFilterPrefixes } =
    useFilterSearchParams(searchParams);

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm(getSearchParamsByFilterPrefix(props.filterPrefix));

  const handleChangeSkill = (...inputProps) => {
    setFormValue((prev) => getObjectWithoutKeys(prev, ["employee"]));
    onChangeAutocompleteFieldWithObjectOptions(...inputProps);
  };

  const getEmployeeSelectList = () => {
    if (skillList.loading || userList.loading) return [];
    if (!formValue.skill) return userList.value;
    return userList.value.filter((user) =>
      user.skill.some((skill) => formValue.skill.includes(skill))
    );
  };

  const getSearchParamsObjectsForFilterPrefixes = () => {
    let searchParamsObject = {};
    for (let tableFilterPrefix of props.tableFilterPrefixes) {
      searchParamsObject = {
        ...searchParamsObject,
        ...getSearchParamsObjectForFilterPrefix(formValue, tableFilterPrefix),
      };
    }
    return searchParamsObject;
  };

  const handleClearRcpKind = () => {
    setFormValue((prev) => ({
      ...prev,
      rcp_kind: "",
    }));
  };

  const handleSearch = () => {
    setSearchParams(() => ({
      ...clearSearchParamsByFilterPrefixes(
        [props.filterPrefix, ...props.tableFilterPrefixes],
        props.excludedKeysForClearFilters
      ),
      ...getSearchParamsObjectForFilterPrefix(formValue, props.filterPrefix),
      ...getSearchParamsObjectsForFilterPrefixes(),
    }));
  };

  const handleCleanFilters = () => {
    setFormValue(() => ({}));
    setSearchParams(() =>
      clearSearchParamsByFilterPrefixes(
        [props.filterPrefix, ...props.tableFilterPrefixes],
        props.excludedKeysForClearFilters
      )
    );
  };

  const isSearchFormValid = formValue?.month;

  const isLoading = skillList.loading || userList.loading;

  return (
    <LoaderWrapper showLoader={isLoading}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        columnSpacing={2}
        rowSpacing={2}
      >
        <Grid item xs={12} md={3}>
          <AutocompleteField
            multiple={true}
            optionValueKey={"id"}
            optionLabelKey={"skill_name"}
            name="skill"
            value={formValue?.skill}
            options={skillList.value}
            label={t("bar.rcp_schedule_dialog.skill")}
            onChange={handleChangeSkill}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <AutocompleteField
            multiple={true}
            optionValueKey={"id"}
            optionLabelKey={"full_name"}
            name="employee"
            value={formValue?.employee}
            options={getEmployeeSelectList()}
            label={t("bar.rcp_schedule_dialog.employee")}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <SelectField
            name={"rcp_kind"}
            options={[
              { id: "rcpdone", name: t("bar.rcp_schedule_dialog.rcpdone") },
              { id: "rcpplan", name: t("bar.rcp_schedule_dialog.rcpplan") },
            ]}
            label={t("bar.rcp_schedule_dialog.schedule")}
            isObjectOption={true}
            value={
              Array.isArray(formValue?.rcp_kind)
                ? formValue?.rcp_kind[0]
                : formValue?.rcp_kind
            }
            onChange={onChange}
            onClear={handleClearRcpKind}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <DateField
            name={"month"}
            label={t("bar.rcp_schedule_dialog.month")}
            value={formValue?.month}
            onChange={onChangeDate}
            view={"month"}
            required
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button
            disabled={!isSearchFormValid}
            variant="contained"
            color="success"
            size="small"
            fullWidth
            onClick={handleSearch}
          >
            {t("search")}
          </Button>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            onClick={handleCleanFilters}
          >
            {t("clean_filters")}
          </Button>
        </Grid>
      </Grid>
    </LoaderWrapper>
  );
}

RcpScheduleDialogSearchBar.propTypes = {
  style: PropTypes.object,
  tableFilterPrefixes: PropTypes.array,
  filterPrefix: PropTypes.string,
  excludedKeysForClearFilters: PropTypes.array,
};

RcpScheduleDialogSearchBar.defaultProps = {
  filterPrefix: "rcp_search_bar",
  tableFilterPrefixes: ["rcp_schedule"],
  excludedKeysForClearFilters: [],
};
