import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import FullWidthTabs from "../FullWidthTabs/FullWidthTabs";
import { useTranslation } from "react-i18next";

import TaskTable from "../../table/TaskTable/TaskTable";
import { TASK_TABLE_FIELDS_CONFIG } from "../../../page/TaskPage/PageTablesConfig";
import CreateTaskDialog from "../../dialog/CreateTaskDialog/CreateTaskDialog";
import useTaskService from "../../../services/taskService";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import useDialog from "../../../hooks/useDialog";
import { Button } from "@mui/material";
import { useAsync } from "../../../hooks/useAsync";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import CreateOrderDialog from "../../dialog/CreateOrderDialog";

import {
  overflowButtonStyle,
  centerVericalAlignIconStyle,
} from "../../../helpers/styles";

const TaskTableTab = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { getTaskData } = useTaskService();
  const taskData = useAsync(
    () => getTaskData(`order=${props.orderId}&page_size=100`),
    [props.orderId]
  );

  const [
    openCreateTaskDialog,
    onOpenCreateTaskDialog,
    onCloseCreateTaskDialog,
  ] = useDialog();

  const [
    openCreateOrderDialog,
    onOpenCreateOrderDialog,
    onCloseCreateOrderDialog,
  ] = useDialog();

  const handleCreateOrder = (resData) => {
    navigate(`/order_details/${resData.id}`);
  };

  if (taskData.loading) return <LoaderWrapper showLoader={true} />;

  return (
    <div style={{ textAlign: "left" }}>
      {!props.readOnly && (
        <Button
          variant="contained"
          size="small"
          startIcon={
            <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
          }
          onClick={onOpenCreateOrderDialog}
          sx={{
            ...overflowButtonStyle,
            marginBottom: "10px",
            marginRight: "20px",
          }}
        >
          {t("tabs.order_related_tables.add_order")}
        </Button>
      )}
      {!props.readOnly && (
        <Button
          variant="contained"
          size="small"
          startIcon={
            <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
          }
          onClick={onOpenCreateTaskDialog}
          sx={{ ...overflowButtonStyle, marginBottom: "10px" }}
        >
          {t("tabs.order_related_tables.add_task")}
        </Button>
      )}
      <TaskTable
        showChangesCircle={false}
        showDetailsIcon={true}
        data={taskData.value.results}
        showCheckbox={false}
        showCleanFilterIcon={false}
        hiddenColumns={[]}
        tableConfig={TASK_TABLE_FIELDS_CONFIG}
        showFilters={false}
        withPagination={false}
      />
      {openCreateTaskDialog && (
        <CreateTaskDialog
          open={openCreateTaskDialog}
          onClose={onCloseCreateTaskDialog}
          orderId={props.orderId}
          onSubmit={taskData.refetch}
        />
      )}
      {openCreateOrderDialog && (
        <CreateOrderDialog
          open={openCreateOrderDialog}
          onClose={onCloseCreateOrderDialog}
          ticketId={props.ticketId}
          onSubmit={handleCreateOrder}
        />
      )}
    </div>
  );
};

const OrderRelatedTablesTabs = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <FullWidthTabs
      items={[
        {
          label: t("tabs.order_related_tables.tasks"),
          content: (
            <TaskTableTab
              orderId={props.orderId}
              readOnly={props.readOnly}
              ticketId={props.ticketId}
            />
          ),
        },
      ]}
    />
  );
});

OrderRelatedTablesTabs.propTypes = {
  orderId: PropTypes.string,
  ticketId: PropTypes.string,
  readOnly: PropTypes.bool,
};

OrderRelatedTablesTabs.defaultProps = {
  readOnly: false,
};

export default OrderRelatedTablesTabs;
