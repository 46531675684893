import { useState, useEffect, useRef } from "react";

import { useSnackbarAlert } from "../../context/snackbarAlert";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import TicketOrderDetailsForm from "../../components/form/TicketOrderDetailsForm";
import UniversalCommunicationLogDialog from "../../components/dialog/UniversalCommunicationLogDialog";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import useTicketService from "../../services/ticketService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import { useTranslation } from "react-i18next";
import TicketRelatedTablesTabs from "../../components/tabs/TicketRelatedTablesTabs";
import { useAuth } from "../../context/auth";
import { isUserOm } from "../../helpers/userPermissions";
import { Helmet } from "react-helmet";

function TicketDetailsPage(props) {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const { ticketId } = useParams();
  const {
    getTicketDetailsData,
    getTicketDetailsFilteringData,
    updateTicketData,
  } = useTicketService();

  const { user } = useAuth();
  const isOM = isUserOm(user);

  const ticketData = useAsync(() => getTicketDetailsData(ticketId), [ticketId]);
  const ticketFilteringData = useAsync(getTicketDetailsFilteringData);

  const isLoading = ticketData.loading || ticketFilteringData.loading;

  const updateTicketDataFn = useAsyncFn(updateTicketData);
  const onUpdateTicketDataPromise = (ticketId, dataToSend) => {
    return updateTicketDataFn
      .execute(ticketId, dataToSend)
      .then((data) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.ticket_data_updated")
        );
        return Promise.resolve(data);
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_updating_ticket_data")
        );
        return Promise.reject(error);
      });
  };

  const handleCloseEnclosureDialog = (existsChanges) => {
    if (existsChanges) ticketData.refetch();
  };

  const [comunicationDialogHeight, setComunnicationDialogHeight] =
    useState(445);
  const leftFormRef = useRef();

  useEffect(() => {
    if (
      leftFormRef.current &&
      !isLoading &&
      leftFormRef?.current.clientHeight > 0
    ) {
      setComunnicationDialogHeight(leftFormRef.current.clientHeight - 180);
    }
  }, [leftFormRef.current, isLoading]);

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  const isTicketClosed = ticketData.value.is_closed;
  const isTicketFinallyClosed = ticketData.value.is_finally_closed;
  const isTicketClaim = ticketData.value.is_claim;

  const tagTitle = `MySolvy | ${t("tag_title.ticket_details")} ${
    ticketData.value?.ticket_nr
  }`;

  return (
    <>
      <Helmet>
        <title>{tagTitle}</title>
      </Helmet>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        rowSpacing={1}
        columnSpacing={1}
        alignItems="stretch"
      >
        <Grid item xs={12} lg={7} ref={leftFormRef}>
          <TicketOrderDetailsForm
            itemType={"ticket"}
            filteringData={ticketFilteringData.value}
            itemData={ticketData.value}
            onUpdateDataPromise={onUpdateTicketDataPromise}
            onRefetchData={ticketData.refetch}
            readOnly={isTicketClosed || isOM}
            isOM={isOM}
            isItemFinallyClosed={isTicketFinallyClosed}
            isTicketClaim={isTicketClaim}
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <UniversalCommunicationLogDialog
            showDialogAsForm
            itemId={ticketData.value.id}
            itemType={"ticket"}
            showExtendedAddEnclosureDialog={true}
            onCloseEnclosureDialog={handleCloseEnclosureDialog}
            messageWindowHeight={comunicationDialogHeight}
            inputMessageFormShowOnlyAttachmentsIcon={true}
            inputMessageFormGridJustifyContent={"space-around"}
            inputMessageFormEnterButtonGridSmGrid={1.5}
            isFinallyClosed={isTicketFinallyClosed}
          />
        </Grid>
        {!isOM && (
          <Grid item xs={12}>
            <TicketRelatedTablesTabs
              ticketId={ticketData.value.id}
              readOnly={isTicketClosed || isTicketFinallyClosed}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

TicketDetailsPage.propTypes = {};

export default TicketDetailsPage;
