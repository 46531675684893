import { formatNumber, isEmptyValue, round } from "../helpers/methods";

const useFloatField = () => {

    const checkIsEndWithDot = ( value) => {
        return /\.$/.test(value)
      }

    const getValue = (value, decimalPlaces=2, formatNumberSeparator) => {
        if (!isEmptyValue(value) && decimalPlaces) {
            const isEndWithDot = checkIsEndWithDot(value)
            var roundValue = round(value, decimalPlaces)
            if (formatNumberSeparator !== undefined){
                roundValue = formatNumber(round(value, decimalPlaces), formatNumberSeparator)
            }
            if(isEndWithDot){
                roundValue = roundValue + '.'
            }
            return roundValue
        }
        return value
    }

    return {
        getValue
      };
}

export default useFloatField;
