import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import { Bar, getElementsAtEvent } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { isEmptyArray } from "../../../helpers/methods";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend,
  Colors
);

ChartJS.defaults.color = "var(--primary-light)";

const VerticalBarChart = (props) => {
  const defaultDataLabelsFormaterFn = (value, context) => {
    // is single chart
    if (props.data.datasets.length <= 1) {
      return undefined;
    }

    const datasetArray = [];
    context.chart.data.datasets.forEach((dataset) => {
      if (dataset.data[context.dataIndex] !== undefined) {
        datasetArray.push(dataset.data[context.dataIndex]);
      }
    });

    return context.datasetIndex === datasetArray.length - 1
      ? datasetArray.reduce((sum, curr) => sum + curr, 0)
      : "";
  };

  const options = {
    maxBarThickness: 50,
    maintainAspectRatio: false,
    responsive: props.responsive,
    scales: {
      x: {
        stacked: props.xScaleStacked,
      },
      y: {
        stacked: props.yScaleStacked,
      },
    },
    plugins: {
      datalabels: {
        display: props.dataLabelsDisplay,
        anchor: props.dataLabelsAnchor,
        color: props.dataLabelsColor,
        formatter: props.datalabelsFormaterFn || defaultDataLabelsFormaterFn,
        font: {
          size: 14,
          weight: "bold",
        },
      },
      legend: {
        position: props.legendPosition,
        display: props.legendDisplay,
      },
      title: {
        display: props.titleDisplay,
        text: props.titleText,
        position: props.titlePosition,
        align: props.titleAlign,
        font: {
          size: 20,
        },
      },
      colors: {
        enabled: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return props.tooltipFormaterFn
              ? props.tooltipFormaterFn(context.parsed, context)
              : undefined;
          },
        },
      },
    },
    color: "var(--primary)",
    interaction: {
      mode: "nearest",
    },
  };

  const chartRef = useRef();
  const onClick = (e) => {
    if (props.onClickBar) {
      const clickedBar = getElementsAtEvent(chartRef.current, e);
      if (!isEmptyArray(clickedBar)) {
        const idx = clickedBar[0].index;
        const label = props.data.labels[idx];
        props.onClickBar({ idx, label });
      }
    }
  };

  return (
    <Bar options={options} data={props.data} ref={chartRef} onClick={onClick} />
  );
};

VerticalBarChart.propTypes = {
  responsive: PropTypes.bool,
  legendDisplay: PropTypes.bool,
  legendPosition: PropTypes.string,
  titleDisplay: PropTypes.bool,
  titleText: PropTypes.string,
  titlePosition: PropTypes.string,
  titleAlign: PropTypes.string,
  dataLabelsDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dataLabelsAnchor: PropTypes.string,
  dataLabelsColor: PropTypes.string,
  data: PropTypes.object,
  onClickBar: PropTypes.func,
  xScaleStacked: PropTypes.bool,
  yScaleStacked: PropTypes.bool,
  datalabelsFormaterFn: PropTypes.func,
  tooltipFormaterFn: PropTypes.func,
};

VerticalBarChart.defaultProps = {
  responsive: true,
  legendDisplay: true,
  legendPosition: "top",
  titlePosition: "top",
  titleDisplay: true,
  titleAlign: "center",
  dataLabelsDisplay: "auto",
  dataLabelsAnchor: "end",
  dataLabelsColor: "#000000de",
  xScaleStacked: true,
  yScaleStacked: true,
};

export default VerticalBarChart;
