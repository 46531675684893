import React from "react";

import PropTypes from "prop-types";
import DateService from "../../../services/dateService";
import "./MyMessage.css";
import ContextMenu from "../../wrapper/ContextMenu";

function MyMessage(props) {
  const getMessageColor = () => {
    if (props.message?.is_log) {
      return "messageStyle loglMessageColor";
    }
    if (props.message?.message_external) {
      return "messageStyleRight externalMessageColor";
    }
    return "messageStyleRight internalMessageColor";
  };

  const messageContent = (
    <div>
      <div class="messageRowRight">
        <div class={getMessageColor()}>
          <div class="messageContentRight">{props.message?.message_content}</div>
          <p class="messageTimeStampRight">
            {DateService.convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss(
              props.message?.created
            )}{" "}
          </p>
        </div>
      </div>
    </div>
  );

  return props.showContextMenu ? (
    <ContextMenu
      key={props.message.id}
      showMenu={props.show}
      actions={props.contextMenuActions.map((action) => ({
        label: action.label,
        icon: action.icon,
        callback: () => action.callback(props.message.id),
      }))}
      Component={messageContent}
    />
  ) : (
    messageContent
  );
}

MyMessage.propTypes = {
  message: PropTypes.object,
  show: PropTypes.bool,
  showContextMenu: PropTypes.bool,
  contextMenuActions: PropTypes.object,
};

MyMessage.defaultProps = {
  message: {},
  show: false,
  contextMenuActions: {},
  showContextMenu: true,
};

export default MyMessage;
