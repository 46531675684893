import React, { useRef } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useDialog from "../../../hooks/useDialog";

import { Grid, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

import AutocompleteField from "../../field/AutocompleteField";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";

import UserDetailsDialog from "../../dialog/UserDetailsDialog";
import TenantDetailsDialog from "../../dialog/TenantDetailsDialog";
import DateField from "../../field/DateField/DateField";
import DateService from "../../../services/dateService";
import BaseBox from "../../base/BaseBox/baseBox";
import FieldWithLabel from "../../field/FieldWithLabel";

const TicketOrderLeftForm = (props) => {
  const { t } = useTranslation();

  const [
    openUserDetailsDialog,
    onOpenUserDetailsDialog,
    onCloseUserDetailsDialog,
  ] = useDialog();

  const [
    openTenantDetailsDialog,
    onOpenTenantDetailsDialog,
    onCloseTenantDetailsDialog,
  ] = useDialog();

  const selectedUserId = useRef();
  const userDetailsDialogTitle = useRef();
  const handleOpenUserDetailsDialog = (userId, title) => {
    selectedUserId.current = userId;
    userDetailsDialogTitle.current = title;

    onOpenUserDetailsDialog();
  };

  return (
    <BaseBox>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <Typography
            variant="h6"
            className="capitalize-first-letter"
            color={"primary"}
          >
            {t("form.ticket_order_details_form.details")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FieldWithLabel
            label={t("form.ticket_order_details_form.ticket_owner")}
            field={
              <TextFieldFormControl
                value={props.formValue.ticket_owner?.full_name}
                suffix={
                  props.formValue.ticket_owner?.id ? (
                    <PersonIcon
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleOpenUserDetailsDialog(
                          props.formValue.ticket_owner.id,
                          "applicant"
                        )
                      }
                    />
                  ) : null
                }
                readOnly
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FieldWithLabel
            label={t("form.ticket_order_details_form.ticket_om")}
            field={
              <TextFieldFormControl
                value={props.formValue.ticket_om?.full_name}
                suffix={
                  props.formValue.ticket_om?.id ? (
                    <PersonIcon
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleOpenUserDetailsDialog(
                          props.formValue.ticket_om.id,
                          "ticket_om"
                        )
                      }
                    />
                  ) : null
                }
                readOnly
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FieldWithLabel
            label={t("form.ticket_order_details_form.tenant")}
            field={
              <TextFieldFormControl
                value={props.formValue.tenant?.tenant_short_name}
                suffix={
                  props.formValue.tenant?.id ? (
                    <PersonIcon
                      style={{ cursor: "pointer" }}
                      onClick={onOpenTenantDetailsDialog}
                    />
                  ) : null
                }
                readOnly
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FieldWithLabel
            label={t("form.ticket_order_details_form.responsilbe_person")}
            field={
              <AutocompleteField
                name={props.itemType === "order" ? "order_kt" : "ticket_k"}
                value={
                  props.itemType === "order"
                    ? props.formValue.order_kt
                    : props.formValue.ticket_k
                }
                options={
                  props.itemType === "order"
                    ? props.filteringData.order_kt
                    : props.filteringData.ticket_k
                }
                isObjectOption={true}
                optionLabelKey={"full_name"}
                onChange={props.onChangeAutocompleteFieldWithObjectOptions}
                disableClearable
                readOnly={props.readOnly}
              />
            }
          />
        </Grid>
        {props.itemType === "order" && (
          <Grid item xs={12}>
            <FieldWithLabel
              label={t("table.order.order_t")}
              field={
                <AutocompleteField
                  name={"order_t"}
                  value={props.formValue.order_t}
                  options={props.filteringData.order_t}
                  isObjectOption={true}
                  optionLabelKey={"full_name"}
                  onChange={props.onChangeAutocompleteFieldWithObjectOptions}
                  disableClearable
                  readOnly={props.readOnly}
                />
              }
            />
          </Grid>
        )}
        {props.itemType === "order" && (
          <Grid item xs={12}>
            <FieldWithLabel
              label={t("form.order.skill")}
              field={
                <AutocompleteField
                  name={"skill"}
                  value={props.formValue.skill}
                  options={props.filteringData.skill}
                  isObjectOption={true}
                  optionLabelKey={"skill_name"}
                  onChange={props.onChangeAutocompleteFieldWithObjectOptions}
                  disableClearable
                  readOnly={props.readOnly}
                />
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FieldWithLabel
            label={t("form.ticket_order_details_form.paid")}
            field={
              <TrueFalseSelectField
                name={props.itemType === "order" ? "order_paid" : "ticket_paid"}
                valuesAsBool
                value={
                  props.itemType === "order"
                    ? props.formValue.order_paid
                    : props.formValue.ticket_paid
                }
                readOnly={props.readOnly || props.isTicketClaim}
                onChange={props.onChange}
                addEmptyOptions={false}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FieldWithLabel
            label={t("form.ticket_order_details_form.created")}
            field={
              <DateField
                value={DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
                  props.formValue.created
                )}
                showClearIcon={false}
                readOnly
              />
            }
          />
        </Grid>
        {!props.isOM && (
          <Grid item xs={12}>
            <FieldWithLabel
              label={t("form.ticket_order_details_form.deadline")}
              field={
                <DateField
                  name={
                    props.itemType === "order"
                      ? "order_deadlineKT"
                      : "ticket_deadlineK"
                  }
                  value={DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
                    props.itemType === "order"
                      ? props.formValue.order_deadlineKT
                      : props.formValue.ticket_deadlineK
                  )}
                  onChange={props.onChangeDate}
                  showClearIcon={false}
                  readOnly={props.readOnly}
                />
              }
            />
          </Grid>
        )}
        {openUserDetailsDialog && (
          <UserDetailsDialog
            open={openUserDetailsDialog}
            onClose={onCloseUserDetailsDialog}
            userId={selectedUserId.current}
            title={userDetailsDialogTitle.current}
          />
        )}
        {openTenantDetailsDialog && (
          <TenantDetailsDialog
            open={openTenantDetailsDialog}
            onClose={onCloseTenantDetailsDialog}
            tenantId={props.formValue.tenant?.id}
          />
        )}
      </Grid>
    </BaseBox>
  );
};

TicketOrderLeftForm.propTypes = {
  formValue: PropTypes.object,
  filteringData: PropTypes.object,
  onChange: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  itemType: PropTypes.oneOf(["ticket", "order"]),
  readOnly: PropTypes.bool,
  isTicketClaim: PropTypes.bool,
  isOM: PropTypes.bool,
};

TicketOrderLeftForm.defaultProps = {
  readOnly: false,
};

export default TicketOrderLeftForm;
