import PropTypes from "prop-types";

import TenantTableWithToolBar from "../../../other/TenantTableWithToolBar";

const RRAdminTenantsTab = (props) => {
  return (
    <TenantTableWithToolBar
      pageName={props.pageName}
      readOnly={props.readOnly}
      fieldsToUpdate={["tenant_priority"]}
      tenantDataSearchParams={{ tenant_rr_visibility: true }}
      defaultPageSize={100}
      initialExpandedFilterHeader={true}
    />
  );
};

RRAdminTenantsTab.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

RRAdminTenantsTab.defaultProps = {};

export default RRAdminTenantsTab;
