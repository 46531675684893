import { flagShortDetailsUrl } from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { isEmptyValue, prepareUrlWithQueryParams } from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useTicketService from "./ticketService";
import useOrderService from "./orderService";

export default function useFlagService() {
  const axiosPrivate = useAxiosPrivate();

  const getFlagsForItems = (itemType = "ticket") => {
    if (itemType === "ticket") return getFlagsForTickets();
    if (itemType === "order") return getFlagsForOrders();
    return null;
  };

  const getFlagsForTickets = () => {
    return makeRequest(axiosPrivate, flagShortDetailsUrl);
  };

  const getFlagsForOrders = () => {
    return makeRequest(axiosPrivate, flagShortDetailsUrl);
  };

  const { addFlagsToTickets, deleteFlagsfromTickets } = useTicketService();
  const { addFlagsToOrders, deleteFlagsfromOrders } = useOrderService();

  const addFlagForItems = (items, flags, itemType = "ticket") => {
    if (itemType === "ticket") return addFlagsToTickets(items, flags);
    if (itemType === "order") return addFlagsToOrders(items, flags);
    return null;
  };

  const deleteFlagFromItems = (items, flags, itemType = "ticket") => {
    if (itemType === "ticket") return deleteFlagsfromTickets(items, flags);
    if (itemType === "order") return deleteFlagsfromOrders(items, flags);
    return null;
  };

  return {
    getFlagsForItems,
    addFlagForItems,
    deleteFlagFromItems,
  };
}
