import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import AddEnclosureForm from "../../form/AddEnclosureForm";
import { INTERNAL_TYPE, MAX_ALLOWED_ENCLOSURE_SIZE_IN_BYTES, MAX_FILENAME_LENGTH_CHARS } from "../../../helpers/constants";
import AddEnclosureSimplyForm from "../../form/AddEnclosureSimplyForm/AddEnclosureSimplyForm";
import CopyEnclosureDialog from "../CopyEnclosureDialog";
import useFileService from "../../../services/fileService";
import { useAsync } from "../../../hooks/useAsync";
import { isEmptyObject } from "../../../helpers/methods";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useForm } from "../../../hooks/useForm";

function AddEnclosureDialog(props) {

    const { t } = useTranslation();

    const snackbarAlert = useSnackbarAlert();

    const defaultFormValue = { enclosure_type: props.defaultEnclosureType }

    const {
        formValue,
        setFormValue,
        onChange,
    } = useForm(defaultFormValue);

    const [selectedFiles, setSelectedFiles] = useState([])

    const [showCopyEnclosureDialog, setShowCopyEnclosureDialog] = useState(false)

    const [selectedEnclosureId, setSelectedEnclosureId] = useState(undefined)

    const { getEnclosureTOData, showFileByBlobUrl, getEnclosuresKindPartData } = useFileService()

    const enclosuresKindPartData = useAsync(
        () => {
            if (props.showEnclosurePartKind){
                return getEnclosuresKindPartData()
            }
            return Promise.resolve([])
        }, [props.showEnclosurePartKind]
        )

    const enclosureData = useAsync(
        () => getEnclosureTOData(selectedEnclosureId),
        [selectedEnclosureId]
    );

    const setNotAddedNames = (namesArr) => {
        let formattedNames = ''
        for (let name of namesArr) {
            formattedNames += '\n' + name
        }
        return t("snackbar_alert.max_allowed_file_size_or_name_is_exceeded_files_are_not_added") + formattedNames
    }

    useEffect(() => {
        if (!enclosureData.loading && !isEmptyObject(enclosureData.value)) {
            setSelectedFiles((enclosuresTemp) => {
                enclosuresTemp = [...enclosuresTemp]
                enclosuresTemp.push({
                    ...enclosureData.value,
                    ['created']: undefined,
                    ['copied']: true
                })
                return enclosuresTemp
            })
        }

    }, [enclosureData.loading]);



    const onChooseFile = (e) => {
        let selectedFiles = e.target.files;
        let enclosures = []
        let notAddedEnclosuresNames = []
        if (selectedFiles) {
            for (let file of selectedFiles) {
                let enclosure = { file: file, enclosure_name: file.name, content_type : file.type }

                if (file.size < MAX_ALLOWED_ENCLOSURE_SIZE_IN_BYTES && file.name.length < MAX_FILENAME_LENGTH_CHARS) {
                    enclosure.blob_url = window.URL.createObjectURL(new Blob([enclosure.file], { type: enclosure.file.type }));
                    enclosures.push(enclosure)
                } else {
                    notAddedEnclosuresNames.push(file.name)
                }
            }
            setSelectedFiles((enclosuresTemp) => {
                enclosuresTemp = [...enclosuresTemp, ...enclosures]
                return enclosuresTemp
            })
            if (notAddedEnclosuresNames.length > 0) {
                snackbarAlert.openWarningSnackbarAlert(
                    setNotAddedNames(notAddedEnclosuresNames)
                );
            }
        }
    }
    const onPreviewNewEnclosure = useCallback((enclosuerId, index) => {
        if (enclosuerId) {
            props.onPreview(enclosuerId)
        } else {
            let enclosure = selectedFiles[index]
            showFileByBlobUrl(enclosure.blob_url)
        }
    }, [selectedFiles])

    const onSetSelectedEnclosureToCopy = (enclosureTOId) => {
        setSelectedEnclosureId(enclosureTOId)
        setShowCopyEnclosureDialog(false)
    }

    const onDeleteSelectedEnclosure = (index) => {
        setSelectedFiles((enclosuresTemp) => {
            enclosuresTemp = [...enclosuresTemp]
            enclosuresTemp.splice(index, 1)
            return enclosuresTemp
        })
    }

    return (
        <>
            <BasicDialog
                open={props.open}
                onClose={() => {
                    props.onClose()
                    setFormValue(defaultFormValue)
                    setSelectedEnclosureId(undefined)
                }}
                titleAlign="center"
                contentAlign="center"
                title={t("dialog.add_new_enclosure_dialog.add_enclosure")}
                maxWidth="sm"
            >
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {props.showExtendedAddEnclosureDialog ?
                            <AddEnclosureForm
                                onChooseFile={onChooseFile}
                                formValue={formValue}
                                onChange={onChange}
                                onCopyFromOther={() => setShowCopyEnclosureDialog(true)}
                                onSubmit={() => {
                                    props.onSubmit(selectedFiles, formValue)
                                    setFormValue(defaultFormValue)
                                    setSelectedFiles([])
                                }}
                                submitButtonLabel={props.submitButtonLabel}
                                onDeleteEnclosure={onDeleteSelectedEnclosure}
                                enclosures={selectedFiles}
                                onPreview={onPreviewNewEnclosure}
                                showSubcontractorSelect={props.showSubcontractorSelect}
                                showEnclosureType={props.showEnclosureType}
                                showEnclosurePartKind={props.showEnclosurePartKind}
                                enclosurePartKindList= {enclosuresKindPartData?.value?.kinds}
                                allowCopyEnclosure = {props.allowCopyEnclosure}
                            />
                            :

                            <AddEnclosureSimplyForm
                                enclosureType={props.defaultEnclosureType}
                                onChooseFile={onChooseFile}
                                formValue={formValue}
                                onChange={onChange}
                                onSubmit={() => {
                                    props.onSubmit(selectedFiles, formValue)
                                    setFormValue({})
                                    setSelectedFiles([])
                                }}
                                submitButtonLabel={props.submitButtonLabel}
                                onDeleteEnclosure={onDeleteSelectedEnclosure}
                                enclosures={selectedFiles}
                                onPreview={onPreviewNewEnclosure}
                            />
                        }
                    </Grid>
                </Grid>
            </BasicDialog>
            {showCopyEnclosureDialog &&
                <CopyEnclosureDialog
                    open={showCopyEnclosureDialog}
                    onClose={() => {
                        setShowCopyEnclosureDialog(false)
                        setSelectedEnclosureId(undefined)
                    }}
                    onDownload={props.onDownload}
                    onPreview={props.onPreview}
                    onCopy={(id) => onSetSelectedEnclosureToCopy(id)}
                />

            }

        </>
    )

}

AddEnclosureDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    defaultEnclosureType: PropTypes.string,
    submitButtonLabel: PropTypes.string,
    showExtendedAddEnclosureDialog: PropTypes.bool,
    onDownload: PropTypes.func,
    onPreview: PropTypes.func, 
    showEnclosurePartKind : PropTypes.bool

};
AddEnclosureDialog.defaultProps = {
    open: false,
    defaultEnclosureType: INTERNAL_TYPE,
    showExtendedAddEnclosureDialog: false,
    showEnclosurePartKind : false
};

export default AddEnclosureDialog