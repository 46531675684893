import BasicDialog from "../../base/BasicDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React from "react";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import OfferValidationForm from "../../form/OfferValidationForm/OfferValidationForm";

function ValidationOfferDialog(props) {
  const { t } = useTranslation();

  const isLoading = props.validatedOffer === undefined

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      title={t("dialog.validation_offer_dialog.offer_validation")}
      maxWidth="sm"
      contentSx = {{overflow : "hidden", padding : "5px"}}
    >
      <LoaderWrapper showLoader={isLoading}>
        <OfferValidationForm
          offerCost={props.offerCost}
          offerValidatedFields={props.offerValidatedFields} />
      </LoaderWrapper>
    </BasicDialog>
  );
}

ValidationOfferDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  offerCost: PropTypes.number,
  offerValidatedFields : PropTypes.array
};

ValidationOfferDialog.defaultProps = {
  open: false,
};

export default ValidationOfferDialog;
