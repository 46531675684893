
const permissionTableService = {

  checkPermission : function(permissionList, transaction_kind){
    if (permissionList && transaction_kind){
      return  permissionList.includes(transaction_kind);
    }
    return false;
  }

}

  export default permissionTableService;

