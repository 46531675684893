import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  DATE_RANGE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import DateService from "../../services/dateService";

export const OFFER_TABLE_NAME = "offer";

export const OFFER_TABLE_FIELDS_CONFIG = [
  {
    name: "offer_nr",
    getValueCallback: (rowData) => rowData.offer_nr,
    label: i18n.t("table.offer.offer_nr"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "offer_status",
    getValueCallback: (rowData) => rowData.offer_status,
    label: i18n.t("table.offer.offer_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.offer_status,
      isObjectOption: true,
      optionLabelKey: "off_status_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "offer_quatation_net",
    getValueCallback: (rowData) => rowData.offer_quatation_net,
    label: i18n.t("table.offer.offer_quatation_net"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },

  {
    name: "offer_materials_quatation",
    getValueCallback: (rowData) => rowData.offer_materials_quatation,
    label: i18n.t("table.offer.offer_materials_cost"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "offer_materials_quatation_margin",
    getValueCallback: (rowData) => rowData.offer_materials_quatation_margin,
    label: i18n.t("table.offer.offer_materials_margin"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "offer_materials_quatation_discount",
    getValueCallback: (rowData) => rowData.offer_materials_quatation_discount,
    label: i18n.t("table.offer.offer_materials_discount"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "offer_materials_quatation_net",
    getValueCallback: (rowData) => rowData.offer_materials_quatation_net,
    label: i18n.t("table.offer.offer_materials_quatation"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "offer_services_quatation",
    getValueCallback: (rowData) => rowData.offer_services_quatation,
    label: i18n.t("table.offer.offer_services_cost"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "offer_services_quatation_margin",
    getValueCallback: (rowData) => rowData.offer_services_quatation_margin,
    label: i18n.t("table.offer.offer_services_margin"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "offer_services_quatation_discount",
    getValueCallback: (rowData) => rowData.offer_services_quatation_discount,
    label: i18n.t("table.offer.offer_services_discount"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "offer_services_quatation_net",
    getValueCallback: (rowData) => rowData.offer_services_quatation_net,
    label: i18n.t("table.offer.offer_services_quatation"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },

  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.offer.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "offer_date_send_om",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.offer_date_send_om
      ),
    label: i18n.t("table.offer.date_send_om"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "offer_date_terminated",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.offer_date_terminated
      ),
    label: i18n.t("table.offer.offer_date_terminated"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "inputer",
    getValueCallback: (rowData) => rowData.inputer,
    label: i18n.t("table.offer.inputer"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.inputer,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "creator",
    getValueCallback: (rowData) => rowData.creator,
    label: i18n.t("table.offer.creator"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.creator,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "account",
    getValueCallback: (rowData) => rowData.account,
    label: i18n.t("table.offer.account"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.account,
      isObjectOption: true,
      optionLabelKey: "account_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_nr",
    getValueCallback: (rowData) => rowData.ticket_nr,
    label: i18n.t("table.ticket.ticket_nr"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "ticket_owner",
    getValueCallback: (rowData) => rowData.ticket_owner,
    label: i18n.t("table.ticket.ticket_owner"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.ticket.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: false,
    }),
  },
  {
    name: "objects_level_2_id",
    getValueCallback: (rowData) =>
      rowData.locations.length > 0 ? rowData.locations[0].level_2 : null,
    label: i18n.t("table.ticket.object_level_2"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.object_level_2,
      isObjectOption: true,
      optionLabelKey: "object_name",
      addNewValue: false,
      multiple: true,
    }),
    other_export_field_name: "objects_level_2",
  },
  {
    name: "ticket_id",
    getValueCallback: (rowData) => rowData.ticket_id,
    onlyData: true,
  },
];
