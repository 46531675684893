import { blendHexWithOpacity } from "../../../helpers/methods";
import { RCP_ACTIVITIES } from "../../../helpers/constants";

export const FREE_DAY = "free_day";
export const WORK_DAY = `${RCP_ACTIVITIES.WORK}_day`;
export const WORK_AFTERNOON = `${RCP_ACTIVITIES.WORK}_afternoon`;
export const WORK_NIGHT = `${RCP_ACTIVITIES.WORK}_night`;

export const LEFT_PART_GRID_XS = 1.5;
export const CENTER_PART_GRID_XS = 8.5;
export const RIGHT_PART_GRID_XS = 2;

export const WORKED_HOURS_COL_XS = 4.5;
export const RBH_PER_MOHTH_COL_XS = 4;
export const OVERTIME_HOURS_COL_XS = 3.5;

export const BLEND_OPACITY = 0.5;

export const EMPTY_CELL_COLOR = "var(--white)";
export const FREE_DAY_COLOR_HEADER = "#818183";
export const FREE_DAY_COLOR_HEADER_RCP_PLAN = blendHexWithOpacity(
  FREE_DAY_COLOR_HEADER,
  BLEND_OPACITY
);
export const GROUP_NAME_ROW_COLOR = "var(--brand-green)";

export const RCP_DONE_COLORS = {
  [FREE_DAY]: "#818183",
  [WORK_DAY]: "#FFF605",
  [WORK_AFTERNOON]: "#91e6c4",
  [WORK_NIGHT]: "#9101B9",
  [RCP_ACTIVITIES.VACATION]: "#e30215",
  [RCP_ACTIVITIES.DELEGATION]: "#02f51f",
  [RCP_ACTIVITIES.SICK_LEAVE]: "#0A00FA",
};

export const RCP_PLAN_COLORS = {
  [FREE_DAY]: blendHexWithOpacity(RCP_DONE_COLORS[FREE_DAY], BLEND_OPACITY),
  [WORK_DAY]: blendHexWithOpacity(RCP_DONE_COLORS[WORK_DAY], BLEND_OPACITY),
  [WORK_AFTERNOON]: blendHexWithOpacity(
    RCP_DONE_COLORS[WORK_AFTERNOON],
    BLEND_OPACITY
  ),
  [WORK_NIGHT]: blendHexWithOpacity(RCP_DONE_COLORS[WORK_NIGHT], BLEND_OPACITY),
  [RCP_ACTIVITIES.VACATION]: blendHexWithOpacity(
    RCP_DONE_COLORS[RCP_ACTIVITIES.VACATION],
    BLEND_OPACITY
  ),
  [RCP_ACTIVITIES.DELEGATION]: blendHexWithOpacity(
    RCP_DONE_COLORS[RCP_ACTIVITIES.DELEGATION],
    BLEND_OPACITY
  ),
  [RCP_ACTIVITIES.SICK_LEAVE]: blendHexWithOpacity(
    RCP_DONE_COLORS[RCP_ACTIVITIES.SICK_LEAVE],
    BLEND_OPACITY
  ),
};
