import { useState } from "react";
import { useTranslation } from "react-i18next";
import FilterTable from "../FilterTable";
import TableService from "../../../services/tableService";
import SelectFieldService from "../../../services/selectFieldService";
import React from "react";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { convertFlatListFromBackendToTranslatedOptionObject } from "../../../helpers/methods";

import {
  NUMERIC_RANGE_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
  TIME_RANGE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
} from "../../../helpers/constants";

const ScheduleTable = ({
  scheduleData,
  tablePrefix = "rcpplan",
  filteringData,
  filterPrefix,
  countRecords,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  resetPageNumber,
  initialExpandedFilterHeader,
  onClickEdit,
  onClickDelete,
}) => {
  const { t } = useTranslation();

  const [tableConfig] = useState([
    {
      name: "employee",
      getValueCallback: (rowData) => rowData.employee.full_name,
      label: t("table.schedule.employee"),
      filterType: AUTOCOMPLETE_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => ({
        options: filteringData.employee,
        isObjectOption: true,
        optionLabelKey: "full_name",
        multiple: true,
      }),
      allowChangeVisibility: false,
      sortable: true,
    },
    {
      name: "day",
      getValueCallback: (rowData) => rowData.day,
      label: t("table.schedule.day"),
      filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => undefined,
      allowChangeVisibility: false,
      sortable: true,
    },
    {
      name: "time_start",
      getValueCallback: (rowData) => rowData.time_start,
      label: t("table.schedule.time_start"),
      filterType: TIME_RANGE_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => undefined,
      allowChangeVisibility: false,
      sortable: true,
    },

    {
      name: "time_end",
      getValueCallback: (rowData) => rowData.time_stop,
      label: t("table.schedule.time_end"),
      filterType: TIME_RANGE_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => undefined,
      allowChangeVisibility: false,
      sortable: true,
    },
    {
      name: "rcpplan_activity",
      getValueCallback: (rowData) =>
        t(`table.schedule.${rowData[`${tablePrefix}_activity`]}`),
      label: t("table.schedule.type"),
      filterType: AUTOCOMPLETE_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => ({
        options: convertFlatListFromBackendToTranslatedOptionObject(
          filteringData.rcpplan_activity
        ),
        isObjectOption: true,
        multiple: true,
      }),
      allowChangeVisibility: false,
    },
    {
      name: "rcpplan_shift",
      getValueCallback: (rowData) =>
        t(`table.schedule.${rowData[`${tablePrefix}_shift`]}`),
      label: t("table.schedule.shift"),
      filterType: AUTOCOMPLETE_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => ({
        options: convertFlatListFromBackendToTranslatedOptionObject(
          filteringData.rcpplan_shift
        ),
        isObjectOption: true,
        multiple: true,
      }),
      allowChangeVisibility: false,
    },
    {
      name: "rcpplan_hours",
      getValueCallback: (rowData) => rowData[`${tablePrefix}_hours`],
      label: t("table.schedule.amount_hours_per_day"),
      filterType: NUMERIC_RANGE_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => undefined,
      allowChangeVisibility: false,
      sortable: true,
    },
    {
      name: "edit",
      getValueCallback: (rowData, onClickEdit, onClickDelete) => (
        <ModeEditOutlineOutlinedIcon
          onClick={(e) => onClickEdit(e, rowData.id)}
        />
      ),
      label: t("table.schedule.edit"),
      filterType: null,
      getFilterFieldConfigCallback: (filteringData) => undefined,
    },
    {
      name: "delete",
      getValueCallback: (rowData, onClickEdit, onClickDelete) => (
        <DeleteOutlineOutlinedIcon
          onClick={(e) => onClickDelete(e, rowData.id)}
        />
      ),
      label: t("table.schedule.delete"),
      filterType: null,
      getFilterFieldConfigCallback: (filteringData) => undefined,
    },
  ]);

  const schedulTableData = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        tableConfig,
        scheduleData || [],
        [],
        undefined,
        onClickEdit,
        onClickDelete
      ),
    [scheduleData, tableConfig]
  );

  return (
    <>
      <FilterTable
        data={schedulTableData || []}
        headersConfig={TableService.getHeadersConfigForCollapsibleTable(
          tableConfig,
          filteringData,
          [],
          undefined
        )}
        withPagination={true}
        showCheckbox={false}
        filteringData={filteringData}
        filterPrefix={filterPrefix}
        showCleanFilterIcon={true}
        countRecords={countRecords}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        resetPageNumber={resetPageNumber}
        initialExpandedFilterHeader={initialExpandedFilterHeader}
      />
    </>
  );
};

export default ScheduleTable;
