import React, {useCallback} from "react";
import AddButton from "../../button/AddButton";
import PropTypes from "prop-types";
import TableService from "../../../services/tableService";
import BaseTable from "../../base/BaseTable/BaseTable";
import { PARAMS_TABLE_FIELDS_CONFIG } from "./TableParamsConfig";

const PartParamsTable = React.memo(({partsParamsData, readOnly, onHandleAddNewParameter, showParametersContextMenu, parametersContextMenuActions}) => {


  const getAddParameterButton = useCallback(() => {
    if (!readOnly) {
      return (<AddButton onClick={onHandleAddNewParameter} />)
    }
  }, [readOnly, onHandleAddNewParameter])

  return (
      <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
        {getAddParameterButton()}
        <BaseTable
        showContextMenu = {showParametersContextMenu}
        contextMenuActions ={parametersContextMenuActions}
        showNoRecordsPaper = {false}
        headers={TableService.getHeaderLables(
          PARAMS_TABLE_FIELDS_CONFIG
        )}
        rows={TableService.getPreparedDataForBaseTable(
          PARAMS_TABLE_FIELDS_CONFIG,
         partsParamsData
        )}
      />
      </div>
  );
})


PartParamsTable.propTypes = {
  partsParamsData: PropTypes.array,
  readOnly: PropTypes.bool,
  onHandleAddNewParameter: PropTypes.func,
};

PartParamsTable.defaultProps = {
  readOnly: true,
};

export default PartParamsTable