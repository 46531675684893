import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, Grid } from "@mui/material";

import PropTypes from "prop-types";

import BoxWithLabel from "../../box/BoxWithLabel";
import FilterEditForm from "../../form/FilterEditForm/FilterEditForm";
import BasicDialog from "../BasicDialog";

import ValidateDataService from "../../../services/validatateDataService";

import { useForm } from "../../../hooks/useForm";
import {
  getFilterSearchParamsKey,
  getObjectFromSearchParams,
} from "../../../helpers/methods";

export const FILTER_MODE = "filter_mode";
export const EDIT_MODE = "edit_mode";
export const CREATE_MODE = "create_mode";
export const PREVIEW_MODE = "preview_mode";

export default function FilterDialog(props) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions,
    onChangeDateConvertedToDateTimeWithZeroHourOfDate,
    onChangeDateConvertedToDateTimeWithEndHourOfDate,
    onChangeDatetimeToDate,
  } = useForm({});

  useEffect(() => {
    if (props.open) {
      if (
        props.dialogMode === FILTER_MODE ||
        props.dialogMode === CREATE_MODE
      ) {
        const filterKey = getFilterSearchParamsKey(props.filterPrefix);
        const currSearchParams = getObjectFromSearchParams(searchParams, [
          `${filterKey}page`,
          `${filterKey}page_size`,
          `${filterKey}ordering`,
        ]);
        setFormValue(currSearchParams);
      } else {
        setFormValue(props.formValue);
      }
    } else {
      setFormValue({});
    }
  }, [props.open]);

  const onSearch = () => {
    var formValueToFilter = {};
    for (const [key, value] of Object.entries(formValue)) {
      if (!ValidateDataService.isEmptyValue(value)) {
        formValueToFilter[key] = value;
      }
    }
    const filterKey = getFilterSearchParamsKey(props.filterPrefix);
    if (`${filterKey}page` in formValueToFilter) {
      formValueToFilter[`${filterKey}page`] = 1;
    }
    props.resetPageNumber();
    setSearchParamsAndCloseDialog(formValueToFilter);
  };

  const onCleanFilters = () => {
    props.onCleanFilters();
    props.resetPageNumber();
    setSearchParamsAndCloseDialog({});
  };

  const setSearchParamsAndCloseDialog = (searchParamsToSet) => {
    props.onClose();
    setSearchParams(searchParamsToSet);
  };

  const searchParamsIsNotEmpty = () => {
    return [...searchParams].length > 0;
  };

  const showCleanFiltersButton = () => {
    return searchParamsIsNotEmpty();
  };

  const getFliterModeContent = () => (
    <>
      {showCleanFiltersButton() && (
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            size="small"
            fullWidth
            onClick={onCleanFilters}
          >
            {t("clean_filters")}
          </Button>
        </Grid>
      )}
      <Grid item xs={12} sm={showCleanFiltersButton() ? 6 : 12}>
        <Button variant="contained" size="small" fullWidth onClick={onSearch}>
          {t("search")}
        </Button>
      </Grid>
    </>
  );

  const onSubmit = (filterDetails) => {
    props.onSubmit({ ...filterDetails, filter_value: formValue });
  };

  const getEditModeContent = () => (
    <FilterEditForm
      formValue={props.filterDetails}
      onCancel={props.onClose}
      onSubmit={onSubmit}
      readOnly={props.dialogMode === PREVIEW_MODE}
      isDataSaving={props.isDataSaving}
    />
  );

  const getDialogTitle = () => {
    if (props.title) return props.title;
    if (props.dialogMode === PREVIEW_MODE)
      return t("dialog.filter_dialog.filter_preview");
    if (props.dialogMode === EDIT_MODE)
      return t("dialog.filter_dialog.filter_edit");
    if (props.dialogMode === CREATE_MODE)
      return t("dialog.filter_dialog.filter_create");
    if (props.dialogMode === FILTER_MODE)
      return t("dialog.filter_dialog.filter_set");
    return undefined;
  };

  return (
    <BasicDialog
      open={props.open}
      showTitle={getDialogTitle() !== undefined}
      onClose={props.onClose}
      maxWidth={props.maxWidth}
      title={getDialogTitle()}
      titleAlign={props.titleAlign}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <BoxWithLabel
          label={t("dialog.filter_dialog.setted_filters")}
          boxStyle={{ width: "100%" }}
        >
          <Grid item xs={12}>
            {React.cloneElement(props.filterForm, {
              formValue: formValue,
              setFormValue: setFormValue,
              readOnly: props.dialogMode === PREVIEW_MODE,
              onChange: onChange,
              onChangeDate: onChangeDate,
              onChangeAutocompleteFieldWithObjectOptions:
                onChangeAutocompleteFieldWithObjectOptions,
              onChangeDateConvertedToDateTimeWithZeroHourOfDate:
                onChangeDateConvertedToDateTimeWithZeroHourOfDate,
              onChangeDatetimeToDate: onChangeDatetimeToDate,
              onChangeDateConvertedToDateTimeWithEndHourOfDate:
                onChangeDateConvertedToDateTimeWithEndHourOfDate,
              filterPrefix: props.filterPrefix,
            })}
          </Grid>
        </BoxWithLabel>
        <Grid item xs={12} marginTop={1}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            {props.dialogMode === FILTER_MODE
              ? getFliterModeContent()
              : getEditModeContent()}
          </Grid>
        </Grid>
      </Grid>
    </BasicDialog>
  );
}

FilterDialog.propTypes = {
  open: PropTypes.bool,
  filterForm: PropTypes.element.isRequired,
  formValue: PropTypes.object,
  filterDetails: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onCleanFilters: PropTypes.func,
  onSubmit: PropTypes.func,
  resetPageNumber: PropTypes.func,
  maxWidth: PropTypes.string,
  title: PropTypes.string,
  titleAlign: PropTypes.string,
  dialogMode: PropTypes.oneOf([
    FILTER_MODE,
    EDIT_MODE,
    CREATE_MODE,
    PREVIEW_MODE,
  ]),
  isDataSaving: PropTypes.bool,
  filterPrefix: PropTypes.string,
};

FilterDialog.defaultProps = {
  maxWidth: "sm",
  resetPageNumber: () => {},
  onCleanFilters: () => {},
  titleAlign: "center",
  dialogMode: FILTER_MODE,
  isSavingData: false,
  formValue: {},
};
