import InformationDialog from "../InformationDialog/informationDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function ChartInformationDialog(props) {
  const { t } = useTranslation();

  return (
    <InformationDialog
      open={props.open}
      titleSx={{
        color: "var(--primary)",
        fontSize: "24px",
        paddingBlock: "10px",
      }}
      onClose={props.onClose}
      maxWidth={"md"}
      title={t("description")}
      content={props.content}
      contentSx={{
        fontSize: "18px",
        fontWeight: "400",
        textAlign: "left",
      }}
      showTopCloseButton
      showDialogActions={false}
    />
  );
}

ChartInformationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  content: PropTypes.string,
};

ChartInformationDialog.defaultProps = {
  open: false,
};
