import { Outlet } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

function Layout() {
  return (
    <div className="main-container">
      <Outlet />
    </div>
  );
}

export default Layout;
