import hasUserPermission, {
  IS_K,
  IS_KT,
  IS_OM,
  IS_R,
  IS_ADMIN,
} from "../helpers/userPermissions";
import {
  ORDER_ITEM_TYPE,
  TICKET_ITEM_TYPE,
  TICKET_ORDER_ITEM_TYPE,
} from "../helpers/constants";
import { useAuth } from "../context/auth";

export const DRAWER_ACTION_BUTTONS = [
  "ticket_protocol",
  "change_status",
  "claim_ticket",
  "related_tickets",
  "send_offer",
  "copy_ticket",
  "enclosures",
  "questionnaire",
];

export const DRAWER_OM_TICKET_ACTION_BUTTONS = [
  "enclosures",
  "my_offers",
  "ticket_protocol",
];

export const DRAWER_ORDER_ACTION_BUTTONS = ["change_status", "enclosures"];

export default function useActionButtonsService() {
  const { user } = useAuth();

  const getActionButtonsForUserAndItemType = (itemType) => {
    if (itemType === ORDER_ITEM_TYPE) return DRAWER_ORDER_ACTION_BUTTONS;

    if (
      hasUserPermission([IS_ADMIN, IS_K], user) &&
      itemType !== ORDER_ITEM_TYPE
    )
      return DRAWER_ACTION_BUTTONS;

    return DRAWER_OM_TICKET_ACTION_BUTTONS;
  };

  return { getActionButtonsForUserAndItemType };
}
