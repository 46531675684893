import React, { useMemo } from "react";

import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";

import useSatusService from "../../../services/statusService";
import { useAsync } from "../../../hooks/useAsync";

import { useTranslation } from "react-i18next";
import { useForm } from "../../../hooks/useForm";
import { STATUS_KIND } from "../../../helpers/constants";

const ChangeTicketStatusForm = (props) => {
  const { t } = useTranslation();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm({});

  const { getStatusesForItems, getSubStatusesForItems } = useSatusService();
  const statuses = useAsync(
    () => getStatusesForItems(props.itemType),
    [props.itemType]
  );
  const substatuses = useAsync(
    () => getSubStatusesForItems(props.itemType),
    [props.itemType]
  );

  const getStatusOptions = () => {
    if (!statuses.value) return [];

    let statusesToShow = statuses.value.filter(
      (x) => x.status_kind !== STATUS_KIND.ALLOCATED
    );
    if (props.isItemClosed)
      return statusesToShow.filter((x) => x.status_kind === STATUS_KIND.CLOSE);

    return statusesToShow;
  };

  const getSubstatusesForItem = () => {
    if (!formValue.status_id) return [];
    return substatuses.value.filter(
      (substatus) =>
        substatus.status_id === formValue.status_id &&
        substatus.substatus_is_system !== true
    );
  };

  const selectedStatusHasSubstatuses = useMemo(() => {
    if (!formValue.status_id) return false;
    const selectedStatus = statuses.value.find(
      (status) => status.id === formValue.status_id
    );
    return selectedStatus.status_flag_sub;
  }, [formValue.status_id]);

  const handleChangeStatus = (...inputProps) => {
    setFormValue((prev) => ({ ...prev, substatus_id: null }));
    onChangeAutocompleteFieldWithObjectOptions(...inputProps);
  };

  const isFormValid = () =>
    formValue.status_id !== undefined &&
    (!selectedStatusHasSubstatuses || formValue.substatus_id) &&
    formValue.status_note;

  const showLoading = () => statuses.loading && substatuses.loading;

  if (showLoading()) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AutocompleteField
          name="status_id"
          label={t("form.change_status.status")}
          value={formValue.status_id}
          options={getStatusOptions()}
          isObjectOption={true}
          optionLabelKey={"status_name"}
          addNewValue={false}
          onChange={handleChangeStatus}
        />
      </Grid>
      {selectedStatusHasSubstatuses && (
        <Grid item xs={12}>
          <AutocompleteField
            name="substatus_id"
            label={
              props.itemType === "task"
                ? t("form.change_status.reason")
                : t("form.change_status.substatus")
            }
            value={selectedStatusHasSubstatuses ? formValue.substatus_id : null}
            options={getSubstatusesForItem()}
            isObjectOption={true}
            optionLabelKey={"substatus_name"}
            addNewValue={false}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextFieldFormControl
          name="status_note"
          label={t("form.change_status.status_note")}
          value={formValue.status_note}
          onChange={onChange}
          rows={2}
          multiline={2}
        />
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          disabled={!isFormValid()}
          onClick={() => props.onSubmit(formValue)}
          loading={props.isLoading}
        >
          {t("save")}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

ChangeTicketStatusForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  itemType: PropTypes.string,
  isItemClosed: PropTypes.bool,
};

ChangeTicketStatusForm.defaultProps = {
  isLoading: false,
  itemType: "ticket",
};

export default ChangeTicketStatusForm;
