import PropTypes from "prop-types";
import React from "react";

function PdfWindow(props) {
  return (
    <div>
      <iframe src={props.url} width="100%" height="800px" />
    </div>
  );
}

PdfWindow.propTypes = {
  url: PropTypes.string,
};

PdfWindow.defaultProps = {};

export default PdfWindow;
