import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import BasicDialog from "../../base/BasicDialog";
import ChangeStatusForm from "../../form/ChangeStatusForm";
import useDialog from "../../../hooks/useDialog";
import ConfirmationDialog from "../ConfirmationDialog/confirmationDialog";
import { useRef } from "react";

const ChangeStatusDialog = (props) => {
  const { t } = useTranslation();

  const [
    openConfirmationDialog,
    onOpenConfirmationDialog,
    onCloseConfirmationDialog,
  ] = useDialog();

  const dataToSendRef = useRef();
  const onSubmit = (dataToSend) => {
    if (props.isMassAction) {
      dataToSendRef.current = dataToSend;
      onOpenConfirmationDialog();
    } else {
      props.onSubmit(dataToSend, props.onClose);
    }
  };

  const handleCloseConfirmationDialog = (confirmChangeStatus) => {
    onCloseConfirmationDialog();

    if (confirmChangeStatus && dataToSendRef.current)
      props.onSubmit(dataToSendRef.current, props.onClose);
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={t("dialog.change_status.change_status")}
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        <Grid item xs={12}>
          <ChangeStatusForm
            onSubmit={onSubmit}
            isLoading={props.isLoading}
            itemType={props.itemType}
            isItemClosed={props.isItemClosed}
          />
        </Grid>
      </Grid>
      {openConfirmationDialog && (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onNo={() => handleCloseConfirmationDialog(false)}
          onYes={() => handleCloseConfirmationDialog(true)}
          title={t("dialog.change_status.alert")}
          content={t("dialog.change_status.are_you_sure_to_change_mass_status")}
        />
      )}
    </BasicDialog>
  );
};

ChangeStatusDialog.propTypes = {
  itemType: PropTypes.oneOf(["ticket", "order", "task"]),
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  isItemClosed: PropTypes.bool,
  isMassAction: PropTypes.bool,
};

ChangeStatusDialog.defaultProps = {
  itemType: "ticket",
  open: false,
  isLoading: false,
};

export default ChangeStatusDialog;
