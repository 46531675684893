import { IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandButton = ({ onClick, sx }) => {
  return (
    <IconButton
      sx={{ paddingBlock: 0, ...sx }}
      color="primary"
      size="small"
      onClick={onClick}
    >
      <ExpandMoreIcon fontSize="large" />
    </IconButton>
  );
};

export default ExpandButton;
