import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import {
  orderDataUrl,
  orderDetailsDataUrl,
  orderFilteringDataUrl,
  orderDataWithoutPaginationUrl,
  createOrderUrl,
  stdOrdersForTicketSelectListUrl,
  orderTypeSelectListUrl,
  orderPrioritySelectListUrl,
  orderAddFlagsUrl,
  orderAddFavorityUrl,
  orderDeleteFlagsUrl,
  orderMassActionUrl,
  orderDeleteFavorityUrl,
  myOrderDataUrl,
  initialOrderDataForCreateTaskUrl,
  orderSelectListUrl,
  orderPlannerDataUrl,
  stdOrderAdminDataUrl,
  stdOrderAdminDetailsDataUrl,
  stdOrderForStdTicketAdminDataUrl,
  stdOrderAdminFilteringDataUrl,
  stdOrderAutomatAdminDataUrl,
  stdOrderAutomatAdminDetailsDataUrl,
  stdOrderAutomatAdminFilteringDataUrl,
  orderSlaDataUrl,
  orderSlaFilteringDataUrlUrl,
  orderChartFilteringDataUrl,
} from "../helpers/apiUrls";

import { prepareUrlWithQueryParams } from "../helpers/methods";
import { makeRequestWithLanguage } from "../helpers/makeRequestWithLanguage";

export default function useOrderService() {
  const axiosPrivate = useAxiosPrivate();

  const getOrderData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(orderDataUrl, searchParams)
    );
  };

  const updateOrderData = (orderId, dataToSend) => {
    return makeRequest(axiosPrivate, orderDetailsDataUrl(orderId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getOrderWithoutPaginationData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(orderDataWithoutPaginationUrl, searchParams)
    );
  };

  const getOrderDetailsData = (orderId) => {
    return makeRequest(axiosPrivate, orderDetailsDataUrl(orderId));
  };

  const getOrderFilteringData = () => {
    return makeRequestWithLanguage(axiosPrivate, orderFilteringDataUrl);
  };

  const createOrder = (dataToSend) => {
    return makeRequest(axiosPrivate, createOrderUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const getOrderPrioritySelectList = () => {
    return makeRequest(axiosPrivate, orderPrioritySelectListUrl);
  };

  const getOrderTypeSelectList = () => {
    return makeRequest(axiosPrivate, orderTypeSelectListUrl);
  };

  const getStdOrdersForTicketSelectList = (ticketId) => {
    return makeRequest(axiosPrivate, stdOrdersForTicketSelectListUrl(ticketId));
  };

  const addOrderToFavority = (orders_ids) => {
    return makeRequest(axiosPrivate, orderAddFavorityUrl, {
      method: "POST",
      data: orders_ids,
    });
  };

  const deleteOrderFromFavority = (orders_ids) => {
    return makeRequest(axiosPrivate, orderDeleteFavorityUrl, {
      method: "POST",
      data: orders_ids,
    });
  };

  const addFlagsToOrders = (orders, flags) => {
    return makeRequest(axiosPrivate, orderAddFlagsUrl, {
      method: "POST",
      data: { orders, flags },
    });
  };

  const deleteFlagsfromOrders = (orders, flags) => {
    return makeRequest(axiosPrivate, orderDeleteFlagsUrl, {
      method: "POST",
      data: { orders, flags },
    });
  };
  const updateMassOrderData = (dataToSend) => {
    return makeRequest(axiosPrivate, orderMassActionUrl, {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getMyOrdersData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(myOrderDataUrl, searchParams)
    );
  };

  const getInitialOrderDataForCreateTask = (orderId) => {
    return makeRequest(axiosPrivate, initialOrderDataForCreateTaskUrl(orderId));
  };

  const getOrderSelectList = () => {
    return makeRequest(axiosPrivate, orderSelectListUrl);
  };

  const getOrderPlanerData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(orderPlannerDataUrl, searchParams)
    );
  };

  const getStdOrderAdminData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(stdOrderAdminDataUrl, searchParams)
    );
  };

  const getStdOrderAdminDetailsData = (stdOrderId) => {
    return makeRequest(axiosPrivate, stdOrderAdminDetailsDataUrl(stdOrderId));
  };

  const createStdOrder = (dataToSend) => {
    return makeRequest(axiosPrivate, stdOrderAdminDataUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateStdOrder = (dataToSend, stdOrderId) => {
    return makeRequest(axiosPrivate, stdOrderAdminDetailsDataUrl(stdOrderId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getStdOrderForStdTicketAdminData = (stdTicketId) => {
    return makeRequest(
      axiosPrivate,
      stdOrderForStdTicketAdminDataUrl(stdTicketId)
    );
  };

  const getStdOrderAdminFilteringData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(stdOrderAdminFilteringDataUrl, searchParams)
    );
  };

  const getStdOrderAutomatAdminData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(stdOrderAutomatAdminDataUrl, searchParams)
    );
  };

  const getStdOrderAutomatAdminDetailsData = (stdOrderId) => {
    return makeRequest(
      axiosPrivate,
      stdOrderAutomatAdminDetailsDataUrl(stdOrderId)
    );
  };

  const updateStdOrderAutomat = (dataToSend, stdOrderId) => {
    return makeRequest(
      axiosPrivate,
      stdOrderAutomatAdminDetailsDataUrl(stdOrderId),
      {
        method: "PATCH",
        data: dataToSend,
      }
    );
  };

  const getStdOrderAutomatAdminFilteringData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        stdOrderAutomatAdminFilteringDataUrl,
        searchParams
      )
    );
  };

  const getOrdersSlaDataWithDistribution = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(orderSlaDataUrl, searchParams)
    );
  };

  const getOrderSlaFilteringData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(orderSlaFilteringDataUrlUrl, searchParams)
    );
  };

  const getOrderChartFilteringData = () => {
    return makeRequestWithLanguage(axiosPrivate, orderChartFilteringDataUrl);
  };

  return {
    getOrderData,
    getOrderWithoutPaginationData,
    getOrderDetailsData,
    getOrderFilteringData,
    createOrder,
    getOrderPrioritySelectList,
    getStdOrdersForTicketSelectList,
    getOrderTypeSelectList,
    updateOrderData,
    addOrderToFavority,
    deleteOrderFromFavority,
    addFlagsToOrders,
    deleteFlagsfromOrders,
    updateMassOrderData,
    getMyOrdersData,
    getInitialOrderDataForCreateTask,
    getOrderSelectList,
    getOrderPlanerData,
    getStdOrderAdminData,
    getStdOrderAdminDetailsData,
    getStdOrderForStdTicketAdminData,
    getStdOrderAdminFilteringData,
    createStdOrder,
    updateStdOrder,
    getStdOrderAutomatAdminData,
    getStdOrderAutomatAdminDetailsData,
    getStdOrderAutomatAdminFilteringData,
    updateStdOrderAutomat,
    getOrdersSlaDataWithDistribution,
    getOrderSlaFilteringData,
    getOrderChartFilteringData,
  };
}
