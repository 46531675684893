import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import AutocompleteField from "../../field/AutocompleteField";
import { Button, Grid } from "@mui/material";
import useItemService from "../../../services/itemService";
import BoxWithTypography from "../../box/BoxWithTypography/BoxWithTypography";
import ItemRecord from "../../other/ItemRecord/ItemRecord";
import { MATERIAL_MAIN_BRANCH_NAME, SERVICE_MAIN_BRANCH_NAME } from "../../../helpers/constants";
import useDialog from "../../../hooks/useDialog";
import SelectItemDialog from "../SelectItemDialog";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

const SelectItemByTreeDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const {
    getEmptyItemElement,
    getItemRecordById,
    getItemsSelectList ,
    prepareItemDataFromBackendForItemRecordComponent,
  } = useItemService()

  const [selectedItem, setSelectedItem] = useState();
  const [items, setItems] = useState(getEmptyItemElement());
  const getItemRecordByIdFn = useAsyncFn(getItemRecordById) 

  const [
    openSelectItemDialog,
    onOpenSelectItemDialog,
    onCloseSelectItemDialog,
  ] = useDialog();

  const onChangeLevelItem = (item, index = 0) => {
    var items_temp = [...items];
    items_temp[index] = item;
    setItems(item);
    setSelectedItem(undefined)
  };

  const getItemRecord = useCallback((selectedItemId) => {
    getItemRecordByIdFn.execute(selectedItemId)
    .then((result) => {
      setItems(prepareItemDataFromBackendForItemRecordComponent(result.item))
      onCloseSelectItemDialog()
    })
    .catch((error) => {
      snackbarAlert.openErrorSnackbarAlert(
        t("snackbar_alert.occurred_error_during_get_item_data")
      );
    })
    
  }, [items])

  const onChangeAutocompleteFieldWithObjectOptions = (
    e,
    value_object,
    value_key, 
    state_value_name
  ) => {
    const newFormValue = Array.isArray(value_object)
      ? value_object.map((option) =>
          typeof option === "object" ? option[value_key] : option
        )
      : value_object[value_key];
    setSelectedItem(newFormValue);
  };
  
  const isLoading = false;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <>
        <Grid item xs={12}>
          <BoxWithTypography
            style={{ padding: "10px" }}
            label={t(`form.item_form.purchase_tree`)}
            component={"div"}
          >
            <ItemRecord
              itemLevels={items}
              onChangeItem={onChangeLevelItem}
              onClickSearchItem={onOpenSelectItemDialog}
              defaultFirstLevelItemName={props.itemType === 'service' ? SERVICE_MAIN_BRANCH_NAME : MATERIAL_MAIN_BRANCH_NAME}
              showServiceLabel={props.itemType === 'service'}
              showMaterialLabel={props.itemType === 'part'}
              onChangeAutocompleteFieldWithObjectOptions={onChangeAutocompleteFieldWithObjectOptions}
              serviceValue={selectedItem}
            />
          </BoxWithTypography>
        </Grid>
        <Button
          sx={{ marginTop: "20px" }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => props.onSubmit(selectedItem)}
          disabled={!selectedItem}
        >
          {t("confirm")}
        </Button>
      </>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      titleAlign="center"
      maxWidth="lg"
      title={t("dialog.select_item_dialog.select_item")}
    >
      {getDialogContent()}
      
      {openSelectItemDialog &&
          <SelectItemDialog
            open={openSelectItemDialog}
            onClose={onCloseSelectItemDialog}
            onSubmit={getItemRecord}
            treeIndex={props.itemType === 'service' ? 2 : 1}
          />
        }
    </BasicDialog>
  );
};

SelectItemByTreeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  itemType : PropTypes.oneOf(["service", "part"]),
  onSubmit : PropTypes.func
};

SelectItemByTreeDialog.defaultProps = {
  itemType : "service"
};

export default SelectItemByTreeDialog;
