import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import DateField from "../../field/DateField/DateField";
import AutocompleteField from "../../../components/field/AutocompleteField";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";

import DateService from "../../../services/dateService";

import { getObjectWithoutKeys } from "../../../helpers/methods";

export default function TicketChartToolBarForm(props) {
  const { t } = useTranslation();

  const getTimeRangeOptions = () => {
    return props.filteringData.value?.time_range.map((timeRange) => ({
      id: timeRange,
      name: t(`bar.ticket_chart_bar.${timeRange}`),
    }));
  };

  const handleChangeTimeRange = (...inputPros) => {
    props.setFormValue((prev) =>
      getObjectWithoutKeys(prev, [
        "month",
        "day",
        "start_settlement_range",
        "end_settlement_range",
      ])
    );
    props.onChangeAutocompleteFieldWithObjectOptions(...inputPros);
  };

  const handleChangeDay = (e) => {
    const { start: start_settlement_range, end: end_settlement_range } =
      DateService.getFilterDatetimeRangeForData(e.target.value);

    const { value: day } = props.onChangeDate(e);

    props.setFormValue((prev) => ({
      ...prev,
      day,
      start_settlement_range,
      end_settlement_range,
    }));
  };

  const handleChangeMonth = (e) => {
    const { zeroHour: start_settlement_range, endHour: end_settlement_range } =
      DateService.getISOStringZeroAndEndHoursOfMonthAndYear(e.target.value);

    const { value: month } = props.onChangeDate(e);

    props.setFormValue((prev) => ({
      ...prev,
      month,
      start_settlement_range,
      end_settlement_range,
    }));
  };

  const getFormContent = () => (
    <>
      {props.showSelectCategoryButton && (
        <Grid
          item
          xs={12}
          md={
            props.isVerticalForm
              ? 12
              : props.showClearFiltersIcon
              ? props.showSelectTimeRange
                ? 3.5
                : 3
              : props.showSelectTimeRange
              ? 4.5
              : 4
          }
        >
          <AutocompleteField
            name="ticket_category"
            multiple={true}
            optionLabelKey={"tc_name"}
            value={props.formValue.ticket_category}
            options={props.filteringData.value?.ticket_category}
            label={t("bar.ticket_chart_bar.ticket_category")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            limitTags={props.isVerticalForm ? undefined : 0}
          />
        </Grid>
      )}
      {props.showSelectTimeRange && (
        <Grid item xs={12} md={props.isVerticalForm ? 12 : 1.5}>
          <AutocompleteField
            name="time_range"
            value={props.formValue.time_range}
            multiple={false}
            isObjectOption={true}
            options={getTimeRangeOptions()}
            label={t("bar.ticket_chart_bar.time_range")}
            onChange={handleChangeTimeRange}
            required
          />
        </Grid>
      )}
      {props.showSelectObjectLevel2 && (
        <Grid item xs={12} md={props.isVerticalForm ? 12 : 2}>
          <AutocompleteField
            name="objects_level_2_id"
            multiple={true}
            isObjectOption={true}
            optionLabelKey={"object_name"}
            value={props.formValue.objects_level_2_id}
            options={props.filteringData.value?.object_level_2}
            label={t("bar.ticket_chart_bar.object_level_2")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            limitTags={props.isVerticalForm ? undefined : 0}
            required
          />
        </Grid>
      )}
      {props.showTicketOwner && (
        <Grid item xs={12} md={props.isVerticalForm ? 12 : 2}>
          <AutocompleteField
            name="ticket_owner"
            multiple={true}
            optionLabelKey={"full_name"}
            isObjectOption={true}
            value={props.formValue.ticket_owner}
            options={props.filteringData.value?.ticket_owner}
            label={t("bar.ticket_chart_bar.ticket_owner")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            limitTags={props.isVerticalForm ? undefined : 0}
            required
          />
        </Grid>
      )}
      {props.showSelectTicketK && (
        <Grid item xs={12} md={props.isVerticalForm ? 12 : 4}>
          <AutocompleteField
            name="ticket_k"
            multiple={true}
            optionLabelKey={"full_name"}
            isObjectOption={true}
            value={props.formValue.ticket_k}
            options={props.filteringData.value?.ticket_k}
            label={t("bar.ticket_chart_bar.ticket_k")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            limitTags={props.isVerticalForm ? undefined : 0}
            required
          />
        </Grid>
      )}
      {props.formValue.time_range === "month" && (
        <Grid item xs={12} md={props.isVerticalForm ? 12 : 2}>
          <DateField
            name={"month"}
            label={t("bar.ticket_chart_bar.month")}
            value={props.formValue.month}
            onChange={handleChangeMonth}
            view={"month"}
            showClearIcon={false}
            required
          />
        </Grid>
      )}
      {(props.showOnlySelectDay || props.formValue.time_range === "day") && (
        <Grid item xs={12} md={props.isVerticalForm ? 12 : 2}>
          <DateField
            name={"day"}
            label={t("bar.ticket_chart_bar.created")}
            value={props.formValue.day}
            onChange={handleChangeDay}
            showClearIcon={false}
            required
          />
        </Grid>
      )}
      {((!props.showOnlySelectDay &&
        !props.showSelectTimeRange &&
        !props.formValue.time_range) ||
        props.formValue.time_range === "date_range") && (
        <Grid
          item
          xs={12}
          md={props.isVerticalForm ? 12 : 3}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <DateField
            name={"start_settlement_range"}
            label={t("bar.ticket_chart_bar.start_settlement_date")}
            value={props.formValue.start_settlement_range}
            onChange={props.onChangeDateConvertedToDateTimeWithZeroHourOfDate}
            showClearIcon={false}
            required
            maxDate={
              props.formValue.end_settlement_range
                ? props.formValue.end_settlement_range
                : undefined
            }
          />
          <div style={{ marginInline: "5px" }}>{"-"}</div>
          <DateField
            name={"end_settlement_range"}
            label={t("bar.ticket_chart_bar.end_settlement_date")}
            value={props.formValue.end_settlement_range}
            onChange={props.onChangeDateConvertedToDateTimeWithEndHourOfDate}
            showClearIcon={false}
            required
            minDate={
              props.formValue.start_settlement_range
                ? props.formValue.start_settlement_range
                : undefined
            }
          />
        </Grid>
      )}
      {props.isVerticalForm && props.showOpenCloseFilterButton && (
        <Grid item xs={12}>
          <TrueFalseSelectField
            name="is_open"
            label={t("button.open_close_filter_button.open")}
            value={props.formValue?.is_open}
            valuesAsBool
            addEmptyOptions={false}
            onChange={props.onChange}
          />
        </Grid>
      )}
      {props.isVerticalForm && props.showTenantVisibilityButton && (
        <Grid item xs={12}>
          <TrueFalseSelectField
            name="tenant_rr_visibility"
            label={t("page.feedback_management_page.tenat_rr_visibility")}
            value={props.formValue?.tenant_rr_visibility}
            valuesAsBool
            addEmptyOptions={true}
            onChange={props.onChange}
          />
        </Grid>
      )}
    </>
  );

  if (props.onlyFormContent) return getFormContent();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      rowSpacing={1}
    >
      {getFormContent()}
    </Grid>
  );
}

TicketChartToolBarForm.propTypes = {
  formValue: PropTypes.object,
  filteringData: PropTypes.object,
  setFormValue: PropTypes.func,
  onChangeDateConvertedToDateTimeWithEndHourOfDate: PropTypes.func,
  onChangeDateConvertedToDateTimeWithZeroHourOfDate: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  isVerticalForm: PropTypes.bool,
  onlyFormContent: PropTypes.bool,
  showSelectCategoryButton: PropTypes.bool,
  showSelectTimeRange: PropTypes.bool,
  showSelectObjectLevel2: PropTypes.bool,
  showTicketOwner: PropTypes.bool,
  showTicketK: PropTypes.bool,
  showClearFiltersIcon: PropTypes.bool,
  showOnlySelectDay: PropTypes.bool,
  showOpenCloseFilterButton: PropTypes.bool,
  showTenantVisibilityButton: PropTypes.bool,
};

TicketChartToolBarForm.defaultProps = {
  isVerticalForm: false,
  onlyFormContent: true,
  showMyFiltersButton: true,
  showSelectCategoryButton: true,
  showSelectTimeRange: false,
  showSelectObjectLevel2: false,
  showTicketOwner: false,
  showClearFiltersIcon: true,
  showOnlySelectDay: false,
  showTicketK: false,
};
