import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import useOfferService from "../services/offerService";
import { useAsync } from "./useAsync";
import useSettlementTableService from "../services/settlementTableService";
import { getFieldsFromObject } from "../helpers/methods";



const useSettlementData = (
    offerId,
    settlementTableConfig,
    totalItemsCost,
    readOnly,
    viewType,
    dialogMode = 'existOffer'
) => {
   
    const [settlementLocal, setSettlementLocal] = useState(undefined)

    const [requiredSettlementFields, setRequiredSettlementFields] = useState([])

    const [isSettlementTableValid, setIsSettlementTableValid] = useState(true)

    const [isSettlementSumRateValid, setIsSettlementSumRateValid] = useState(true)

    const {
        getOffeSettlementData,
        getOMyffeSettlementData
    } = useOfferService()

    const {
        calulateSingleSettlementByRate,
        calulateSingleSettlementByNettCost,
        isElementValid,
        isTableValid,
        setSettlementDataAfterChange,
        checkIsRateSumIsValid,
        getFieldsNameToSave
    } = useSettlementTableService()

    const offerSettlementList = useAsync(
        () => getOfferSettlementDataByView(),
        [offerId, readOnly, viewType]
    );

    useEffect(() => {
        if (settlementLocal) {
            setIsSettlementTableValid(isTableValid(settlementLocal))
            setIsSettlementSumRateValid(checkIsRateSumIsValid(settlementLocal))
        }
    }, [settlementLocal]);

    useEffect(() => {
        if (offerSettlementList.loading) {
            return;
        }
        setSettlementLocal(offerSettlementList.value)
    }, [offerSettlementList.loading]);


    const getOfferSettlementDataByView = () =>{
        if (viewType === 'my-offers'){
            return getOMyffeSettlementData(offerId)
        }else{
          return  getOffeSettlementData(offerId, {'read_only' : readOnly})
        }
    }


    useEffect(() => {
        setRequiredSettlementFields(settlementTableConfig.filter((config) => config.required === true).map((c) => c.name))
    }, [settlementTableConfig]);

    const onChangeSettlementAutocompleteField = useCallback((
        e,
        value_object,
        value_key,
        state_value_name,
        index) => {
        let tempDataLocal = settlementLocal
        let element = tempDataLocal[index]

        element = setSettlementDataAfterChange(element, value_object)
        element.isEdited = true
        element.editDate = Date.now()
        isElementValid(element, requiredSettlementFields)
        tempDataLocal[index] = { ...element }
        setSettlementLocal([...tempDataLocal])
    }, [settlementLocal, requiredSettlementFields])

    const onChangeSettlementTableData = useCallback((name, value, idx) => {
        let tempDataLocal = settlementLocal
        let element = tempDataLocal[idx]
        element[name] = value
        element.isEdited = true
        element.editDate = Date.now()
        if (name === 'settlement_payment_rate') {
            element = calulateSingleSettlementByRate(element, totalItemsCost[0], requiredSettlementFields)
        }
        else if (name === 'settlement_netto'){
            element = calulateSingleSettlementByNettCost(element, totalItemsCost[0], requiredSettlementFields)
        }

        tempDataLocal[idx] = { ...element }
        setSettlementLocal([...tempDataLocal])
    }, [settlementLocal, requiredSettlementFields, totalItemsCost])

    const onHandleSettlementAddEmptyRow = useCallback(() => {
        let tempDataLocal = [...settlementLocal]
        tempDataLocal.push({ editable: true, isValid: false })
        setSettlementLocal([...tempDataLocal])
    }, [settlementLocal])

    const getSettlementsToSend = () => {
        let partSettlementDataToSend = []
        if (settlementLocal) {
            for (let s of settlementLocal) {
                if(s.isEdited || s.isDeleted || dialogMode==="newOffer"){
                    partSettlementDataToSend .push(prepareSettlementDataToSave(s));
                }
            }
        }
        return partSettlementDataToSend 
    }

    const prepareSettlementDataToSave = (element) => {
        return getFieldsFromObject(element, getFieldsNameToSave());
    };

    const handleRemoveSettlementFromList = useCallback((rowIndex) => {
        let tempDataLocal = settlementLocal
        let element = tempDataLocal[rowIndex]
        if (element.id === undefined) {
            tempDataLocal.splice(rowIndex, 1)
        } else {
            element.isDeleted = true
            tempDataLocal[rowIndex] = { ...element }
        }
        setSettlementLocal([...tempDataLocal])
    }, [settlementLocal])

    return {
        offerSettlementData: settlementLocal,
        setSettlementData: setSettlementLocal,
        onChangeSettlementAutocompleteField,
        onChangeSettlementTableData,
        onHandleSettlementAddEmptyRow,
        requiredSettlementFields,
        handleRemoveSettlementFromList,
        getSettlementsToSend,
        isSettlementTableValid,
        isSettlementSumRateValid,
        refetchSettlementData : offerSettlementList.refetch,
        settlementLoading :offerSettlementList.loading
    };
};

export default useSettlementData;
