import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useWarehouseService from "../../../services/warehouseService";
import WarLocationForm from "../../form/WarLocationForm";


const WarehouseLocationDetailsDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const { formValue, setFormValue, onChange } = useForm({});

  const {
    getWarehouseLocationDetails,
    createWarehouseLocation,
    updateWarehouseLocation
  } = useWarehouseService();

  const updateWarehouseLocationFn = useAsyncFn(updateWarehouseLocation);
  const createWarehouseLocationFn = useAsyncFn(createWarehouseLocation);

  const warehouseaddressDetailsData = useAsync(() => {
    if (props.warehouseLocationData) {
      return Promise.resolve(props.warehouseLocationData);
    }
    else if (props.warLocationId){
      return getWarehouseLocationDetails(props.warLocationId);
    }
    return Promise.resolve({});
  }, [props.warLocationId, props.warehouseLocationData]);


  useEffect(() => {
    if (warehouseaddressDetailsData.loading) {
      return;
    }

    setFormValue(warehouseaddressDetailsData.value);
  }, [warehouseaddressDetailsData.loading]);



  const prepareNewLocationData = () => {
    let tempLocation = formValue;
    tempLocation["warehouse"] = props.warehouseId;
    return tempLocation;
  };

  const onSubmit = useCallback(() => {

    if (props.warLocationId) {
      updateWarehouseLocationFn
        .execute(props.warLocationId, formValue)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.warehouse_structure_updated")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_warehouse_structure_updating")
          );
        });
    } else {
      if (props.warehouseId) {
        createWarehouseLocationFn
          .execute(prepareNewLocationData())
          .then((res) => {
            snackbarAlert.openSuccessSnackbarAlert(
              t("snackbar_alert.warehouse_structure_created")
            );
            props.onClose();
            props.onRefetch();
          })
          .catch((error) => {
            snackbarAlert.openErrorSnackbarAlert(
              t("snackbar_alert.occurred_error_during_warehouse_structure_creating")
            );
          });
      } else {
        props.onAddLocationForNewWarehouse(formValue, props.tableIndex);
        props.onClose();
      }
    }
  }, [formValue]);


  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={
        props.warLocationId || props.warehouseLocationData
          ? t(`dialog.warehouse_location_details_dialog.structure_details`)
          : t(`dialog.warehouse_location_details_dialog.create_structure`)
      }
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        {warehouseaddressDetailsData.loading  ? (
          <LoaderWrapper isLoading={true} />
        ) : (
          <>
            <Grid item xs={12}>
              <WarLocationForm
                locationData={formValue}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={updateWarehouseLocationFn.loading}
                onClick={onSubmit}
              >
                {props.warLocationId || props.warehouseLocationData ? t("save") : t("create")}
              </LoadingButton>
            </Grid>
          </>
        )}
      </Grid>
    </BasicDialog>
  );
};

WarehouseLocationDetailsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  warehouseLocationData : PropTypes.object,
  warLocationId : PropTypes.string,
  warehouseId : PropTypes.string,
  onSubmitReplacement : PropTypes.func,
  onRefetch : PropTypes.func,
  onAddLocationForNewWarehouse : PropTypes.func,

};

WarehouseLocationDetailsDialog.defaultProps = {
  open: false,
};

export default WarehouseLocationDetailsDialog;
