import InformationDialog from "../InformationDialog/informationDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useTimeout from "../../../hooks/useTimeout";

export default function NewTicketInformationDialog(props) {
  const { t } = useTranslation();

  const { clear } = useTimeout(props.onClose, 5000);

  const handleClose = () => {
    clear();
    props.onClose();
  };

  return (
    <InformationDialog
      open={props.open}
      titleSx={{
        color: "var(--brand-green)",
        fontSize: "25px",
        paddingBlock: "25px",
      }}
      btnColor={"success"}
      onClose={handleClose}
      title={t(
        "dialog.new_ticket_information_dialog.thank_you_for_adding_new_ticket"
      )}
      content={`${t(
        "dialog.new_ticket_information_dialog.registered_it_on_number"
      )} ${props.ticketNumber}.`}
      contentSx={{
        fontSize: "22px",
        fontWeight: "500",
        paddingBlock: "25px",
      }}
      btnSx={{
        paddingBlock: "8px",
        marginBlock: "20px",
        width: "500px",
        borderRadius: "6px",
      }}
    />
  );
}

NewTicketInformationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ticketNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

NewTicketInformationDialog.defaultProps = {
  open: false,
};
