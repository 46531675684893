import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import { useAsync } from "../../../../hooks/useAsync";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import useCorrespondenceService from "../../../../services/correspondenceService";
import useDialog from "../../../../hooks/useDialog";
import { DEPARTMENT_TABLE_CONFIG} from "./TableConfig";
import TableService from "../../../../services/tableService";
import FilterTable from "../../../table/FilterTable";
import { useEmptyArrayMemo } from "../../../../hooks/useEmptyArrayMemo";
import { useSearchParams } from "react-router-dom";
import useFilterSearchParams from "../../../../hooks/useFilterSearchParams";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import SearchButton from "../../../button/SearchButton/SearchButton";
import FilterDialog from "../../../base/FilterDialog";
import UniversalFilterForm from "../../../form/UniversalFilterForm";
import useDialogWithId from "../../../../hooks/useDialogWithId";
import CreateDepartmentDialog from "../../../dialog/CreateDepartmentDialog";

const DEPARTMENT_FILTER_PREFIX = "department";

const CorrespondenceAdminUserDepartmentTab = (props) => {
  const { t } = useTranslation();
  const [departmentsLocal, setDepartmentsLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [departmentTableSearchParams, setDepartmentTableSearchParams] = useState({});

  const { setNewItemSearchParamsIfAreChanged,
    clearSearchParamsByFilterPrefix
  } =
    useFilterSearchParams(searchParams);

  const { emptyArrayMemo } = useEmptyArrayMemo()
  const {
    getDepartmentsList
  } = useCorrespondenceService()

  const onCleanFlitersInFilterDialog = () => {
    setSearchParams(clearSearchParamsByFilterPrefix(DEPARTMENT_FILTER_PREFIX));
  };

  const [
    openDepartmentDialog,
    departmentChangeId,
    onOpenDepartmentDialog,
    onCloseDepartmentDialog,
    exportChangeStatusStatus
  ] = useDialogWithId();


  const [openFilterDialog,
    onOpenFilterDialog,
    onCloseFilterDialog] =
    useDialog();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
  } = usePaginationWithSearchParams(DEPARTMENT_FILTER_PREFIX);

  const departmentData = useAsync(
    () => getDepartmentsList(departmentTableSearchParams),
    [departmentTableSearchParams]
  );



  useEffect(() => {
    if (departmentData.loading) {
      return;
    }
    setDepartmentsLocal(departmentData.value.results);
    setCountRecords(departmentData.value.count);
  }, [departmentData.loading]);

  const isLoading = departmentsLocal=== undefined || props.filteringData.loading


  const handleOpenDepartmentDialog = useCallback(
    (e, departmentId) => {
      e.stopPropagation();
      onOpenDepartmentDialog(departmentId);
    },
    []
  )

  const refreshTable = () => {
    setDepartmentsLocal([]);
    departmentData.refetch();
  };

  const data = React.useMemo(
    () =>

      TableService.getPreparedDataForCollapsibleTable(
        DEPARTMENT_TABLE_CONFIG,
        departmentsLocal ? departmentsLocal : emptyArrayMemo,
        emptyArrayMemo,
        emptyArrayMemo,
        handleOpenDepartmentDialog,
      ),

    [departmentsLocal, DEPARTMENT_TABLE_CONFIG]
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      DEPARTMENT_FILTER_PREFIX,
      departmentTableSearchParams,
      setDepartmentTableSearchParams
    );
  }, [searchParams]);


  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={1}
    >
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
          onClick={(e) => onOpenDepartmentDialog(undefined)}
        >
          {t("tabs.correspondence_admin.add_department")}
        </Button>
      </Grid>
      <Grid item sm={9} />
      <Grid item xs={12} sm={1}>
        <SearchButton onClickSearch={onOpenFilterDialog} />
      </Grid>
      <Grid item xs={12}>
        <FilterTable
          data={data}
          showCheckbox={false}
          showCleanFilterIcon={true}
          countRecords={countRecords}
          page={page}
          onPageChange={handleChangePageWithSearchParams}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          filteringData={props.filteringData}
          style={{ maxHeight: "vh" }}
          tableConfig={DEPARTMENT_TABLE_CONFIG}
          showContextMenu={false}
          filterPrefix={DEPARTMENT_FILTER_PREFIX}
          headersConfig={TableService.getHeadersConfigForCollapsibleTable(
            DEPARTMENT_TABLE_CONFIG,
            props.filteringData || emptyArrayMemo,
            emptyArrayMemo,
            emptyArrayMemo,
          )}
        />
      </Grid>
      {openFilterDialog &&
        <FilterDialog
          open={openFilterDialog}
          onCleanFilters={onCleanFlitersInFilterDialog}
          onClose={onCloseFilterDialog}
          resetPageNumber={resetPageNumber}
          filterPrefix={DEPARTMENT_FILTER_PREFIX}
          filterForm={
            <UniversalFilterForm
              filteringData={props.filteringData || emptyArrayMemo}
              filtersConfig={DEPARTMENT_TABLE_CONFIG}
              includeOpenCloseFilter={false}
            />
          }
        />
      }
      {openDepartmentDialog &&
        <CreateDepartmentDialog
          departmentId={departmentChangeId}
          open={openDepartmentDialog}
          onClose={onCloseDepartmentDialog}
          onRefetchData={refreshTable}
          filteringData={props.filteringData}
        />
      }
    </Grid>
  );
};

CorrespondenceAdminUserDepartmentTab.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

CorrespondenceAdminUserDepartmentTab.defaultProps = {};

export default CorrespondenceAdminUserDepartmentTab;
