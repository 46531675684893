import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import DateService from "../../services/dateService";

import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";

export const ENCLOUSURE_TABLE_NAME = "enclosure";

export const ENCLOUSURE_TABLE_FIELDS_CONFIG = [
  {
    name: "enclosure_name",
    getValueCallback: (rowData) => rowData.enclosure_name,
    label: i18n.t("table.enclosure.enclosure_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "enclosure_note",
    getValueCallback: (rowData) => rowData.enclosure_note,
    label: i18n.t("table.enclosure.enclosure_note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "ticket_nr",
    getValueCallback: (rowData) => rowData.ticket_nr,
    label: i18n.t("table.enclosure.ticket_nr"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.enclosure.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  {
    name: "author",
    getValueCallback: (rowData) => rowData.user,
    label: i18n.t("table.enclosure.author"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.user,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "download",
    getValueCallback: (rowData, onDownload, onPreview) => (
      <IconButton
        color="primary"
        onClick={() => {
          onDownload(rowData.id, rowData.enclosure_name);
        }}
        sx={{ padding: "5px" }}
      >
        <DownloadIcon fontSize="medium" />
      </IconButton>
    ),
    label: i18n.t("table.enclosure.download"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },

  {
    name: "preview",
    getValueCallback: (rowData, onDownload, onPreview) =>
      rowData.content_type === "application/pdf" ||
      (rowData.content_type && rowData.content_type.includes("image")) ? (
        <IconButton
          color="primary"
          onClick={() => {
            onPreview(rowData.id);
          }}
          sx={{ padding: "5px" }}
        >
          <PreviewOutlinedIcon fontSize="medium" />
        </IconButton>
      ) : null,
    label: i18n.t("table.enclosure.preview"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { maxWidth: "100px" },
    }),
  },
];
