import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseBox from "../../base/BaseBox/baseBox";
import EditableTable from "../../table/EditableTable/EditableTable";
import TableService from "../../../services/tableService";
import ValidationForm from "../ValidationForm";


const OfferTableSummaryForm = (props) => {

  const { t } = useTranslation();

  const data = React.useMemo(
    () =>
      TableService.getFieldsConfigForEditableTable(
        props.tableConfig,
        props.data,
      ),
    [props.data, props.tableConfig]
  );

  return (
    <BaseBox
      error = {props.offerCostIsEqualZero}>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
          <Typography
            variant="subtitle2"
            className="capitalize-first-letter"
          >
            {t(`form.offer_summary_form.offer_summary`)}
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6} />
        <Grid item xs={12}>
          <EditableTable
            showCheckbox={false}
            showTitle={false}
            showPagination={false}
            data={data}
            readOnly={true}
            showContextMenu={false}
            tableConfig={props.tableConfig}


          />
        </Grid>
        <Grid item xs={12}>
          <ValidationForm
            offerCostIsEqualZero={props.offerCostIsEqualZero}
          />
        </Grid>
      </Grid>
    </BaseBox>
  );
};

OfferTableSummaryForm.propTypes = {
  onSubmit: PropTypes.func,
  ticketId: PropTypes.string,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  onOpenAddNewSupplier: PropTypes.func,
  subcontractorSelectList: PropTypes.array
};

OfferTableSummaryForm.defaultProps = {
  readOnly: false,
  subcontractorSelectList: []
};

export default OfferTableSummaryForm;
