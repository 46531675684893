import * as React from "react";
import PropTypes from "prop-types";
import {
  StyledTableRow,
} from "../../base/BaseTable/BaseTable";
import FieldTable from "../../field/FieldTable/FieldTable";
import CheckboxField from "../../base/CheckboxField/checkboxField";
import StyledCellEditableTableBody from "./StyledCellEditableTableBody"
import ContextMenu from "../../wrapper/ContextMenu";
import { BUTTON_FIELD_TYPE } from "../../../helpers/constants";


function areRowDataPropsEqual(oldProps, newProps) {
  return (
    oldProps.editDate === newProps.editDate &&
    oldProps.rowIndex === newProps.rowIndex &&
    oldProps.selected === newProps.selected  &&
    oldProps.readOnly === newProps.readOnly &&
    oldProps.id === newProps.id  
  );
}

const EditableTableDataRow = React.memo(
  ({
    rowIndex,
    showCheckbox,
    data,
    selected,
    onChange,
    onChangeDate,
    onChangeAutocomplete,
    onClickCheck,
    readOnly,
    id,
    showContextMenu,
    contextMenuActions,
    onClickAddItemToAutocompletedField,
    countDataRows,
    allowClickCheckbox ,
    editDate
  }) => {
    
    return (
      <React.Fragment>
        <ContextMenu
          key={id}
          showMenu={showContextMenu}
          actions={contextMenuActions.map((action) => ({
            label: action.label,
            icon: action.icon,
            visible: action.visible,
            callback: () => action.callback(rowIndex),
          }))}
          Component={
            <StyledTableRow hideLastBorder={false} >
              {showCheckbox && (
                <StyledCellEditableTableBody readOnly={readOnly && !allowClickCheckbox }>
                  <CheckboxField
                    size="small"
                    labelPlacement={"bottom"}
                    checked={selected}
                    onChange={() => onClickCheck(id)}
                  />
                </StyledCellEditableTableBody>
              )}

              {countDataRows && (
                <StyledCellEditableTableBody>
                  {rowIndex + 1}
                </StyledCellEditableTableBody>
              )}

              {data.map((field, index) => (
                
                <StyledCellEditableTableBody readOnly={!field.editable || readOnly} style={field.style} >
                  {field.dataType !== BUTTON_FIELD_TYPE ?
                    <FieldTable
                      type={field.dataType}
                      name={field.name}
                      value={field.value}
                      suffix={field.suffix ? field.suffix : undefined}
                      editable={field.editable !== undefined ? field.editable && !readOnly : false}
                      idx={rowIndex}
                      onChange={onChange}
                      onChangeAutocomplete={onChangeAutocomplete}
                      onClickAddItemToAutocompletedField={onClickAddItemToAutocompletedField}
                      fieldConfig={field?.fieldConfig}
                    />
                    :
                    <>{field.value}</>
                  }
                </StyledCellEditableTableBody>
              ))}
            </StyledTableRow>
          }
        />
      </React.Fragment>
    );
  },  areRowDataPropsEqual
);

export default EditableTableDataRow;

EditableTableDataRow.propTypes = {
  showCheckbox: PropTypes.bool,
  fieldConfig: PropTypes.obj,
  onClickCheck: PropTypes.func,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  onChangeAutocomplete: PropTypes.func,
  showContextMenu: PropTypes.bool,
  contextMenuActions: PropTypes.arrayOf(PropTypes.object),
  onClickAddItemToAutocompletedField: PropTypes.func,
  allowClickCheckbox : PropTypes.bool
};

EditableTableDataRow.defaultProps = {
  showCheckbox: true,
  readOnly: true,
  showContexMenu: false,
  contextMenuActions: [],
  allowClickCheckbox :false
};
