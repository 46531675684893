import OrdersReportBasePage from "../OrdersReportBasePage";

import DunReportsNavigationBar from "../../components/bar/DunReportsNavigationBar";
import DunReportsNavigationPeopleSubBar from "../../components/bar/DunReportsNavigationBar/DunReportsNavigationPeopleSubBar";

export default function OrdersByOrderTReportPage(props) {
  return (
    <OrdersReportBasePage
      pageName={props.pageName}
      pageTitleKey={"orders_per_tb"}
      groupBy={"none"}
      labelBy={"order_t"}
      showSelectOrderKt={false}
      showSelectTenant={false}
      showSelectTicketCategory={false}
      showSelectOrderT={true}
      showSelectSkill={false}
      NavigationBar={
        <>
          <DunReportsNavigationBar
            multipleLocationKey={"/tickets_by_ticket_k_report/"}
          />
          <DunReportsNavigationPeopleSubBar />
        </>
      }
      showNavigationBar={true}
      chartHeight={"40vh"}
      chartHeightInFullScreen={"67vh"}
    />
  );
}
