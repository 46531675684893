import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import TabsNavigationBar from "../TabsNavigationBar";

function SaftyReportsNavigationBar(props) {
  const { t } = useTranslation();
  return (
    <TabsNavigationBar
      pages={[
        {
          label: t("headers.tickets_per_time_report"),
          link: "/tickets_per_time_report/",
        },
        {
          label: t("headers.tickets_per_owner_report"),
          link: "/tickets_per_owner_report/",
        },
        {
          label: t("headers.tickets_per_location_report"),
          link: "/tickets_per_location_report/",
        },
        {
          label: t("headers.orders_by_ticket_category_report"),
          link: "/orders_by_ticket_category_report/",
        },
        {
          label: t("headers.orders_by_sla_report"),
          link: "/orders_by_sla_report/",
        },
      ]}
    />
  );
}

SaftyReportsNavigationBar.propTypes = {};

export default SaftyReportsNavigationBar;
