import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {
  addressDetailsUrl,
  addressFilteringDataUrl,
  createAddressUrl,
  ownerAddressUrl,
  subcontractorAddressUrl
} from "../helpers/apiUrls";


export default function useAddressService() {
  const axiosPrivate = useAxiosPrivate();


  const getAddressDetails = (addressId) => {
    if (addressId){
      return makeRequest(axiosPrivate, addressDetailsUrl(addressId));
    }
    return Promise.resolve([]);
  };

  const createAddress = (addressData) =>{
    return makeRequest(axiosPrivate, createAddressUrl, {
      method: "POST",
      data: addressData,
    });
  };

  const updateAddress = (addressId, addressData) => {
    return makeRequest(axiosPrivate, addressDetailsUrl(addressId), {
      method: "PATCH",
      data: addressData,
    });
  };

  const getAddressFilteringData  = () =>{
    return makeRequest(axiosPrivate, addressFilteringDataUrl);
  }

  const removeAddressOwner = (ownerAddressId) => {
    return makeRequest(axiosPrivate, ownerAddressUrl(ownerAddressId), {
      method: "PATCH",
      data: {owad_active : false},
    });
  };
  const removeAddressSubcontractor= (subcontractorAddressId) => {
    return makeRequest(axiosPrivate, subcontractorAddressUrl(subcontractorAddressId), {
      method: "PATCH",
      data: {suad_active : false},
    });
  };

  return {
    getAddressDetails,
    createAddress,
    updateAddress,
    getAddressFilteringData,
    removeAddressOwner,
    removeAddressSubcontractor
  };
}
