import { useTranslation } from "react-i18next";
import OfferReportBasePage, { CHART_TYPE_PIE } from "../OffersReportBasePage/";

export default function OffersPerStatusReportPage(props) {
  const { t } = useTranslation();
  return (
    <OfferReportBasePage
      chartType={CHART_TYPE_PIE}
      pageName={props.pageName}
      pageTitleKey={"offers_per_offer_status_report"}
      groupBy={"none"}
      labelBy={"offer_status"}
      showSelectObjectLevel2={false}
      showSelectTenant={false}
      showSelectReportType={false}
      showSelectOfferStatus={true}
      showTotalIndicator={false}
      chartDataLabelsUnit={t("headers.offers").toLocaleLowerCase()}
    />
  );
}

OffersPerStatusReportPage.propTypes = {};
