import { Button, Grid } from "@mui/material";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import useRcpService from "../../../services/rcpService";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import ScheduleTable from "../../table/ScheduleTable/ScheduleTable";
import { useEffect, useState, useRef } from "react";
import DateService from "../../../services/dateService";
import CreateScheduleDialog from "../../dialog/CreateScheduleDialog";
import RcpScheduleDialog from "../../dialog/RcpScheduleDialog";
import useDialog from "../../../hooks/useDialog";
import useDialogWithFormValue from "../../../hooks/useDialogWithFormValue";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { centerVericalAlignIconStyle } from "../../../helpers/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../hooks/useFilterSearchParams";
import {
  isSearchParamsObjectEmpty,
  getErrorMsg,
} from "../../../helpers/methods";
import { RCP_ACTIVITIES } from "../../../helpers/constants";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

const SCHEDULE_TABLE_FILTER_PREFIX = "schedule_done";

const ScheduleDone = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const { getRcpDone, deleteRcpDone, getRcpPlanFilteringData } =
    useRcpService();
  const {} = useSnackbarAlert();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams(SCHEDULE_TABLE_FILTER_PREFIX);
  const { setNewItemSearchParamsIfAreChanged, getSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);

  const [scheduleDoneSearchParams, setScheduleDoneSearchParams] = useState(
    getSearchParamsByFilterPrefix(SCHEDULE_TABLE_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      SCHEDULE_TABLE_FILTER_PREFIX,
      scheduleDoneSearchParams,
      setScheduleDoneSearchParams
    );
  }, [searchParams]);

  const rcpDone = useAsync(
    () => getRcpDone(scheduleDoneSearchParams),
    [scheduleDoneSearchParams]
  );

  const rcpFilteringData = useAsync(getRcpPlanFilteringData);

  const [rcpDoneData, setRcpDoneData] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (!rcpDone.loading && rcpDone.value.results) {
      let rcpDoneDataTemp;
      rcpDoneDataTemp = [];
      for (let rcpp of rcpDone.value.results) {
        const isWorkActivity = rcpp.rcpdone_activity === RCP_ACTIVITIES.WORK;
        const rcpdone_date_start = new Date(rcpp.rcpdone_date_start);
        const rcpdone_date_stop = new Date(rcpp.rcpdone_date_stop);
        rcpp.day = rcpdone_date_start.toLocaleDateString();
        rcpp.time_start = isWorkActivity
          ? DateService.getHoursAndMinutesStringFromData(rcpdone_date_start)
          : "-";
        rcpp.time_stop = isWorkActivity
          ? DateService.getHoursAndMinutesStringFromData(rcpdone_date_stop)
          : "-";
        rcpDoneDataTemp.push(rcpp);
      }
      setCountRecords(rcpDone.value.count);
      setRcpDoneData(rcpDoneDataTemp);
    }
  }, [rcpDone.loading]);

  const formValueConverterForCreateScheduleDialog = (formValue) => {
    return {
      id: formValue.id,
      rcpdone_date_start: formValue.rcpdone_date_start,
      rcpdone_date_stop: formValue.rcpdone_date_stop,
      rcpdone_hours: formValue.rcpdone_hours,
      rcpdone_note: formValue.rcpdone_note,
      rcpdone_activity: formValue.rcpdone_activity,
      rcpdone_shift: formValue.rcpdone_shift,
      employees: formValue.employee.id,
      hour_start: formValue.time_start,
      hour_stop: formValue.time_stop,
    };
  };

  const [
    openCreateScheduleDialog,
    createScheduleDialogFormValue,
    handleOpenCreateScheduleDialog,
    handleCloseCreateScheduleDialog,
  ] = useDialogWithFormValue({
    formValueConverter: formValueConverterForCreateScheduleDialog,
  });

  const [
    openRcpScheduleDialog,
    onOpenRcpScheduleDialog,
    onCloseRcpScheduleDialog,
  ] = useDialog();

  const onCloseCreateScheduleDialog = (refresh) => {
    if (refresh) {
      rcpDone.refetch();
      if (props.onChanges) {
        props.onChanges();
      }
    }
    handleCloseCreateScheduleDialog();
  };

  const deleteRcpDoneFn = useAsyncFn(deleteRcpDone);
  const handleDeteleRcpDone = (e, rcpDoneId) => {
    deleteRcpDoneFn
      .execute(rcpDoneId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.rcp_plan_deleted")
        );
        setRcpDoneData((prev) => prev.filter((rcp) => rcp.id !== rcpDoneId));
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_delete_rcp_plan")
          )
        );
      });
  };

  const createScheduleDialogModeRef = useRef("create_mass");
  const handleOpenCreateScheduleDialogInEditMode = (e, rcpDoneId) => {
    const rcpDoneToEdit = rcpDoneData.find(
      (rcpDone) => rcpDone.id === rcpDoneId
    );
    createScheduleDialogModeRef.current = "edit";
    handleOpenCreateScheduleDialog(rcpDoneToEdit);
  };

  const handleOpenCreateScheduleDialogInCreateMassMode = () => {
    createScheduleDialogModeRef.current = "create_mass";
    handleOpenCreateScheduleDialog();
  };

  const isLoading = rcpFilteringData.loading || rcpDoneData === undefined;

  return (
    <LoaderWrapper showLoader={isLoading}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={12} sm={4} textAlign={"left"}>
              <Button
                variant="contained"
                size="small"
                startIcon={
                  <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
                }
                onClick={handleOpenCreateScheduleDialogInCreateMassMode}
              >
                {t("other.schedule_plan.mass_adding_hours")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} textAlign={"right"}>
              <Button
                variant="contained"
                size="small"
                startIcon={
                  <CalendarMonthIcon sx={centerVericalAlignIconStyle} />
                }
                onClick={onOpenRcpScheduleDialog}
              >
                {t("other.schedule_plan.display_schedule")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ScheduleTable
            scheduleData={rcpDoneData}
            tablePrefix={"rcpdone"}
            filteringData={rcpFilteringData.value}
            filterPrefix={SCHEDULE_TABLE_FILTER_PREFIX}
            countRecords={countRecords}
            page={page}
            onPageChange={handleChangePageWithSearchParams}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            initialExpandedFilterHeader={
              !isSearchParamsObjectEmpty(scheduleDoneSearchParams)
            }
            resetPageNumber={resetPageNumber}
            onClickDelete={handleDeteleRcpDone}
            onClickEdit={handleOpenCreateScheduleDialogInEditMode}
          />
        </Grid>
      </Grid>
      {openCreateScheduleDialog && (
        <CreateScheduleDialog
          kind="done"
          open={openCreateScheduleDialog}
          onClose={onCloseCreateScheduleDialog}
          initialFormValue={createScheduleDialogFormValue}
          dialogMode={createScheduleDialogModeRef.current}
        />
      )}
      {openRcpScheduleDialog && (
        <RcpScheduleDialog
          open={openRcpScheduleDialog}
          onClose={onCloseRcpScheduleDialog}
        />
      )}
    </LoaderWrapper>
  );
};

export default ScheduleDone;
