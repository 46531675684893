import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";

import TextFieldFormControl from "../../../../components/field/TextFieldFormControl";

import DateService from "../../../../services/dateService";

const RRTenantForm = (props) => {
  const { t } = useTranslation();

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={4}>
        <TextFieldFormControl
          label={t("form.tenant.tenant_priority")}
          value={props.tenantData.tenant_priority}
          readOnly
        />
      </Grid>
      <Grid item xs={4}>
        <TextFieldFormControl
          label={t("form.tenant.end_date_of_rental_agreement")}
          value={DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
            props.tenantData.end_date_of_rental_agreement
          )}
          readOnly
        />
      </Grid>
      <Grid item xs={4}>
        <TextFieldFormControl
          label={t("form.tenant.tenant_collection")}
          value={props.tenantData.tenant_collection}
          readOnly
        />
      </Grid>
    </Grid>
  );
};

RRTenantForm.propTypes = {
  tenantData: PropTypes.object,
};

export default RRTenantForm;
