import { useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useForm } from "../../../hooks/useForm";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";

import useTicketService from "../../../services/ticketService";
import BasicDialog from "../../base/BasicDialog";
import MergedTicketRecord from "../../other/MergedTicketRecord";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import {
  isEmptyObject,
  convertObjectListFromBackendToTranslatedOptionObject,
} from "../../../helpers/methods";

const MergeTicketDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const { getTicketSelectList, getTicketTicketKindSelectList, mergeTickets } =
    useTicketService();

  const ticketSelectList = useAsync(() =>
    getTicketSelectList({ is_claim: false })
  );
  const ticketTicketKindSelectList = useAsync(getTicketTicketKindSelectList);
  const mergeTicketFn = useAsyncFn(mergeTickets);

  const prepareDataToSend = () => {
    const ticketIds = formValue.ticket_ids.filter(
      (ticketId) => !isEmptyObject(ticketId)
    );

    return { ...formValue, ticket_ids: ticketIds };
  };

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm({
    ticket_ids: props.ticketIds,
    tictic_kind: null,
    tictic_note: null,
  });

  const onSubmit = () => {
    mergeTicketFn
      .execute(prepareDataToSend())
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.merge_tickets")
        );
        props.onClose(true);
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_merge_tickets")
        );
      });
  };

  const handleDeleteRelation = useCallback((index) => {
    setFormValue((prev) => {
      const ticketIds = [...prev.ticket_ids];
      if (ticketIds.length !== 1) {
        ticketIds.splice(index, 1);
      } else {
        ticketIds.splice(index, 1, null);
      }

      return { ...prev, ticket_ids: ticketIds };
    });
  }, []);

  const handleAddRelation = useCallback(() => {
    setFormValue((prev) => {
      const ticketIds = [...prev.ticket_ids];
      return { ...prev, ticket_ids: [...ticketIds, null] };
    });
  }, []);

  const onChangeTicket = useCallback((index, value) => {
    setFormValue((prev) => {
      const ticketIds = [...prev.ticket_ids];
      ticketIds.splice(index, 1, value);
      return { ...prev, ticket_ids: ticketIds };
    });
  }, []);

  const isFormValid = () => {
    const ticketIds = formValue.ticket_ids.filter((ticketId) => ticketId);
    return (
      formValue.tictic_kind &&
      ticketIds.length > 1 &&
      ticketIds.length == new Set(ticketIds).size
    );
  };

  const isLoading =
    ticketSelectList.loading || ticketTicketKindSelectList.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} sm={6}>
          <AutocompleteField
            name="tictic_kind"
            label={t("dialog.merge_ticket_dialog.tictic_kind")}
            value={formValue.tictic_kind}
            options={convertObjectListFromBackendToTranslatedOptionObject(
              ticketTicketKindSelectList.value.filter(
                (ttk) => ttk.is_allowed_to_manual_edit === !props.readOnly
              ),
              "tictic_kind"
            )}
            isObjectOption={true}
            optionLabelKey={"tictic_kind"}
            addNewValue={false}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldFormControl
            name="tictic_note"
            label={t("dialog.merge_ticket_dialog.tictic_note")}
            value={formValue.tictic_note}
            onChange={onChange}
            multiline
            rows={2}
          />
        </Grid>
        <Grid item xs={12}>
          {formValue.ticket_ids?.map((ticketId, index) => (
            <MergedTicketRecord
              key={`${ticketId}_${index}`}
              ticket={ticketId}
              index={index}
              ticketSelectList={ticketSelectList.value}
              showButtonAddRelation={index === 0}
              onAddRelation={handleAddRelation}
              onDeleteRelation={handleDeleteRelation}
              onChange={onChangeTicket}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            sx={{ marginTop: "20px" }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={onSubmit}
            loading={mergeTicketFn.loading}
            disabled={!isFormValid()}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      maxWidth={"md"}
      titleAlign="center"
      title={t("dialog.merge_ticket_dialog.merge_ticket")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

MergeTicketDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ticketIds: PropTypes.string,
};

MergeTicketDialog.defaultProps = {};

export default MergeTicketDialog;
