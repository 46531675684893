import { Grid } from "@mui/material";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { NAVIGATION_DRAWER_TYPE_WAREHOUSES } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import WarehouseAdminTabs from "../../components/tabs/WarehouseAdminTabs/WarehouseAdminTabs";


export default function WarehouseAdminPage(props) {
  const { pageName } = props;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_WAREHOUSES}
    >
      <DefaultPageWrapper titleKey={"warehouse_admin"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <WarehouseAdminTabs pageName={pageName} />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
