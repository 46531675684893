import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { isEmptyObject, isEmptyValue } from "../../../helpers/methods";
import useUserService from "../../../services/userService";
import BoardMemberForm from "../../form/BoardMemberForm";
import DateService from "../../../services/dateService";


const BoardMemberDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions
  } = useForm({ "has_mysolvy_account": true });

  const {
    getBoardDetails,
    createBoard,
    updateBoard,
    getUserSelectList,
    getBoardFilteringData } = useUserService();

  const updateBoardFn = useAsyncFn(updateBoard)
  const createBoardFn = useAsyncFn(createBoard)

  const boardDetailsData = useAsync(
    () => getBoardDetails(props.boardId),
    [props.boardId]
  );

  const boardFilteringData = useAsync(
    () => getBoardFilteringData(),
    []
  );


  const userSelectList = useAsync(
    () => getUserSelectList({ 'is_active': true, 'is_external': false }));

  useEffect(() => {
    if (boardDetailsData.loading) {
      return;
    }
    if (!isEmptyObject(boardDetailsData.value)) {
      setFormValue(boardDetailsData.value);
    }
  }, [boardDetailsData.loading]);


  const prepareBoardData = () => {
    let tempBoard = formValue
    if (props.ownerId) {
      tempBoard['owner'] = props.ownerId
    } else{
    }
    if (isEmptyValue(tempBoard['board_member'])) {
      tempBoard['board_member'] = undefined
    }else{
      tempBoard['full_name'] = userSelectList.value.find((u) => u.id ===tempBoard['board_member'])?.full_name
    }
    if (isEmptyValue(tempBoard['board_company'])) {
      tempBoard['board_company'] = undefined
    }
    tempBoard['board_date_start'] = DateService.convertDateToFormatYYYYMMDD(tempBoard['board_date_start'])
    tempBoard['board_date_end'] = DateService.convertDateToFormatYYYYMMDD(tempBoard['board_date_end'])
    return tempBoard
  }

  const onSubmit = useCallback(() => {
    if (props.boardId) {
      updateBoardFn
        .execute(props.boardId, prepareBoardData())
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.board_updated")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_board_updating")
          );
        });
    } else {
      if (props.ownerId) {
        createBoardFn
          .execute(prepareBoardData())
          .then((res) => {
            snackbarAlert.openSuccessSnackbarAlert(
              t("snackbar_alert.board_created")
            );
            props.onClose();
            props.onRefetch();
          })
          .catch((error) => {
            snackbarAlert.openErrorSnackbarAlert(
              t("snackbar_alert.occurred_error_during_board_creating")
            );
          });
      } else {
        props.onAddBoardForNewOwner(prepareBoardData())
        props.onClose();
      }
    }

  }, [formValue]);

  const isValid = !isEmptyValue(formValue.board_member) || !isEmptyValue(formValue.board_company) || !isEmptyValue(formValue.board_person)


  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.boardId ? t(`dialog.board_details_dialog.board_details`) : t(`dialog.board_details_dialog.add_board`)}
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        {boardDetailsData.loading || boardFilteringData.loading || userSelectList.loading ?
          <LoaderWrapper isLoading={true} />
          :
          <>
            <Grid item xs={12}>
              <BoardMemberForm
                boardData={formValue}
                onChange={onChange}
                onChangeAutocomplete={onChangeAutocompleteFieldWithObjectOptions}
                boardRoles={boardFilteringData.value.board_roles}
                boardUsers={userSelectList.value}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={updateBoardFn.loading}
                disabled={!isValid}
                onClick={onSubmit}
              >
                {props.boardId ? t("save") : t("create")}
              </LoadingButton>
            </Grid>
          </>
        }
      </Grid>
    </BasicDialog>
  );
};

BoardMemberDialog.propTypes = {
  ownerId: PropTypes.string,
  boardId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

BoardMemberDialog.defaultProps = {
  open: false,
};

export default BoardMemberDialog;
