import { useState, useMemo } from "react";
import PropTypes from "prop-types";

import { Grid, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import { useTranslation } from "react-i18next";

import TableColumnVisibilityForm from "../../form/TableColumnVisibilityForm";

import { sortArrayOfObjectsByOrderArray } from "../../../helpers/methods";
import useUserPreferencesService from "../../../services/userPreferencesService"
const TableColumnVisibilityDialog = (props) => {
  const { t } = useTranslation();

  
  const {
    getDefaultHiddenColumnsToHiddenList,
  } = useUserPreferencesService();


  const [tablesHiddenColumns, setTablesHiddenColumns] = useState(
    () => props.tablesHiddenColumns
  );

  const [tablesColumnOrders, setTablesColumnOrders] = useState(() =>
    Object.fromEntries(
      Object.entries(props.tablesConfigs).map(([tableName, tableConfig]) => [
        tableName,
        tableConfig.map((tbConfing) => tbConfing.name),
      ])
    )
  );

  const [unHiddenDefaultColumns, setUnHiddenDefaultColumns] = useState(props.tablesUnhiddenDefaultColumns)

  const sortedTablesConfigs = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(props.tablesConfigs).map(([tableName, tableConfig]) => [
          tableName,
          sortArrayOfObjectsByOrderArray(
            tableConfig,
            tablesColumnOrders[tableName]
          ),
        ])
      ),

    [tablesColumnOrders]
  );

  const numtables = Object.keys(props.tablesConfigs).length;
  const gridColumns = numtables * 12;
  const dialogWidth =
    numtables === 1
      ? "xs"
      : numtables === 2
      ? "sm"
      : numtables === 3
      ? "md"
      : "lg";

  const handleChangeFieldVisibility = (tableName, fieldName, tableConfig) => {
    setTablesHiddenColumns((prev) => {
      let tableHiddenFields = [...prev[tableName]];
      let unhiddenDefaultFields = unHiddenDefaultColumns?  [...unHiddenDefaultColumns[tableName]] : []
      if (tableHiddenFields.includes(fieldName)) {
        tableHiddenFields = tableHiddenFields.filter(
          (hiddenField) => hiddenField !== fieldName
        );
        let configObj = tableConfig.find(config =>config.name === fieldName)
        if(configObj.defaultHidden){
          unhiddenDefaultFields = [...unhiddenDefaultFields, fieldName]
          setUnHiddenDefaultColumns((temp) => {return {...temp, [tableName]: unhiddenDefaultFields }})
          tableHiddenFields= getDefaultHiddenColumnsToHiddenList(tableHiddenFields,unhiddenDefaultFields, tableConfig)
        }
      } else {
        let configObj = tableConfig.find(config =>config.name === fieldName)

        if(configObj.defaultHidden){
          if(unhiddenDefaultFields.includes(fieldName)){
            unhiddenDefaultFields = unhiddenDefaultFields.filter(
              (unhiddenField) => unhiddenField !== fieldName
            );
            setUnHiddenDefaultColumns((temp) => {return {...temp, [tableName] : [...unhiddenDefaultFields]}})
            tableHiddenFields= getDefaultHiddenColumnsToHiddenList(tableHiddenFields,unhiddenDefaultFields, tableConfig)
          }else{
            unhiddenDefaultFields = [...unhiddenDefaultFields, fieldName]
            setUnHiddenDefaultColumns((temp) => {return {...temp, [tableName]: unhiddenDefaultFields }})
            tableHiddenFields= getDefaultHiddenColumnsToHiddenList(tableHiddenFields,unhiddenDefaultFields, tableConfig)
          }
        }else{
        tableHiddenFields.push(fieldName);

        }
      }

      return { ...prev, [tableName]: tableHiddenFields };
    });
  };

  const handleChangeColumnOrders = (tableName, sourceIdx, destinationIdx) => {
    setTablesColumnOrders((prev) => {
      let tableColumnOrders = [...prev[tableName]];

      const [reorderedItem] = tableColumnOrders.splice(sourceIdx, 1);
      tableColumnOrders.splice(destinationIdx, 0, reorderedItem);

      return { ...prev, [tableName]: tableColumnOrders };
    });
  };

  const [dialogContentScrollPosition, setDialogContentScrollPosition] =
    useState(false);
  const handleScrollDialogContent = (e) => {
    setDialogContentScrollPosition(e.target.scrollTop);
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="left"
      title={t("table.column_visibility_table.set_columns_visibility")}
      maxWidth={dialogWidth}
      margin={0}
      showTopFullScreenButton={false}
      onScrollDialogContent={handleScrollDialogContent}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
        columnSpacing={0}
        margin={0}
        columns={gridColumns}
      >
        {Object.entries(sortedTablesConfigs).map(([tableName, tableConfig]) => (
          <Grid item xs={gridColumns} sm={12} key={tableName}>
            <TableColumnVisibilityForm
              tableName={tableName}
              tableLabel={t(`table.${tableName}.table_name`)}
              tableConfig={tableConfig}
              hiddenColumns={tablesHiddenColumns[tableName]}
              onChangeColumnVisibility={handleChangeFieldVisibility}
              onChangeColumnOrders={handleChangeColumnOrders}
              dialogContentScrollPosition={dialogContentScrollPosition}
            />
          </Grid>
        ))}
        <Grid
          item
          container
          xs={gridColumns}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          columnSpacing={1}
          margin={0}
          columns={gridColumns}
        >
          <Grid item xs={gridColumns} sm={gridColumns / 2}>
            <LoadingButton
              variant="contained"
              size="small"
              fullWidth
              onClick={() =>
                props.onSubmit(tablesHiddenColumns, tablesColumnOrders, unHiddenDefaultColumns)
              }
              loading={props.isLoading}
            >
              {t("confirm")}
            </LoadingButton>
          </Grid>
          <Grid item xs={gridColumns} sm={gridColumns / 2}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              onClick={props.onClose}
              disabled={props.isLoading}
            >
              {t("cancel")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </BasicDialog>
  );
};

TableColumnVisibilityDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  tablesConfigs: PropTypes.array,
  tablesHiddenColumns: PropTypes.object,
  isLoading: PropTypes.bool,
  tablesUnhiddenDefaultColumns: PropTypes.object,
};

TableColumnVisibilityDialog.defaultProps = {
  open: false,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: false,
};

export default TableColumnVisibilityDialog;
