import { useState, useEffect, useCallback } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { NAVIGATION_DRAWER_TYPE_OFFERS, NAVIGATION_DRAWER_TYPE_WAREHOUSES } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import PropTypes from "prop-types";
import useItemService from "../../services/itemService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import PurchasingTree from "../../components/other/PurchasingTree";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import useDialog from "../../hooks/useDialog";
import PurchasingTreeItemDialog from "../../components/dialog/PurchasingTreeItemDialog";
import { sortedIemByNumers } from "../../helpers/methods";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import { MANAGE_ITEMS_TREE_PERMISSION } from "../../helpers/constants";
import useCheckPermission from "../../hooks/usePermission";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog/confirmationDialog";



export default function ItemTreePage(props) {
  const { pageName } = props;

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [
    openItemTreeBranchDialog,
    onOpenItemTreeBranchDialog,
    onCloseItemTreeBranchDialog
  ] = useDialog();

  const [
    openConfirmationDialog,
    onOpenConfirmationDialog,
    onCloseConfirmationDialog,
  ] = useDialog()

  const {
    getBranchStructure,
    removeItem
  } = useItemService();

  const removeItemFn = useAsyncFn(removeItem);

  const getBranchStructureFn = useAsyncFn(getBranchStructure)
  const [branchStucture, setBranchStructure] = useState(undefined)
  const [itemBranchToRemove, setItemBranchToRemove] = useState(undefined)
  const [purchasingTreeBranchDialogData, setPurchasingTreeBranchDialogData] = useState({})
  const [expandedBranch, setExpandedBranch] = useState()
  const [hasEditPermission] = useCheckPermission(MANAGE_ITEMS_TREE_PERMISSION)

  const branchStuctureData = useAsync(() => getBranchStructure({ 'main_branch': true }));

  useEffect(() => {
    if (branchStuctureData.loading) {
      return;
    }
    setBranchStructure(branchStuctureData.value);
  }, [branchStuctureData.loading]);

  const onClickEdit = useCallback((editBranchId) => {
    setPurchasingTreeBranchDialogData({ branchId: editBranchId, parentBranchId: undefined })
    onOpenItemTreeBranchDialog()
  }, [purchasingTreeBranchDialogData])


  const onClickAdd = useCallback((parentBranchId) => {
    setPurchasingTreeBranchDialogData({ branchId: undefined, parentBranchId: parentBranchId })
    onOpenItemTreeBranchDialog()
  }, [purchasingTreeBranchDialogData])


  const onClickRemove = useCallback(() => {
    if (itemBranchToRemove){
      removeItemFn
        .execute(itemBranchToRemove)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.item_removed")
          );
          branchStuctureData.refetch()
          handleCloseConfirmationDialog()
        })
        .catch((err) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_item_removing")
          );
        });
    }
  }, [purchasingTreeBranchDialogData, itemBranchToRemove])

  const hadleClickItem = (branchId) => {
    getBranchStructureFn.execute({ 'item_parent': branchId })
      .then((res) => {
        findItemById(branchStucture, branchId, res)
      })
  }

  const findItemById = (nodes, itemInternalId, childs) => {
    for (let node of nodes) {
      if (node.id === itemInternalId) {
        node.branch_childs = sortedIemByNumers(childs)
        return nodes;
      }
      if (node.has_childs) {
        const found = findItemById(node.branch_childs, itemInternalId, childs);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };


  const handleCloseItemBranchDialog = (dataAreChanged = false) => {
    onCloseItemTreeBranchDialog()
    setPurchasingTreeBranchDialogData({})
    if (dataAreChanged) {
      setExpandedBranch([])
      branchStuctureData.refetch()
    }
  }

  const onSelectBranchToRemove = useCallback((itemId) =>{
    setItemBranchToRemove(itemId)
    onOpenConfirmationDialog()
  }, itemBranchToRemove)

  const handleCloseConfirmationDialog = useCallback(() =>{
    setItemBranchToRemove(undefined)
    onCloseConfirmationDialog()
  }, [itemBranchToRemove])

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_WAREHOUSES}>
      <DefaultPageWrapper titleKey={"item_tree"}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          columnSpacing={2}
          rowSpacing={2}
          style={{ margin: "10px" }}

        >
          <LoaderWrapper showLoader={branchStuctureData.loading}>
            {branchStucture !== undefined &&
              <PurchasingTree branchStucture={branchStucture}
                onClickAdd={onClickAdd}
                onClickEdit={onClickEdit}
                onClickRemove={onSelectBranchToRemove}
                expandedList={expandedBranch}
                hadleClickItem={hadleClickItem}
                hasEditPermission={hasEditPermission} />}
          </LoaderWrapper>
        </Grid>
        {
          openItemTreeBranchDialog &&
          <PurchasingTreeItemDialog
            {...purchasingTreeBranchDialogData}
            open={openItemTreeBranchDialog}
            onClose={handleCloseItemBranchDialog}
          />
        }
        {
          <ConfirmationDialog
            open={openConfirmationDialog}
            onNo={handleCloseConfirmationDialog}
            onYes={onClickRemove}
            title={t("dialog.item_tree_confirmation_dialog.confirm_action")}
            content={t("dialog.item_tree_confirmation_dialog.are_you_sure_to_remove_item_branch")}
          />}
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
ItemTreePage.propTypes = {
  style: PropTypes.object,
};

ItemTreePage.defaultProps = {

};


