import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import SelectField from "../../field/SelectField";
import DateField from "../../field/DateField/DateField";
import DateService from "../../../services/dateService";
import { isEmptyValue } from "../../../helpers/methods";
import AutocompleteField from "../../field/AutocompleteField";

const BoardMemberForm = (props) => {
  const { t } = useTranslation();


  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={1}
    >
      <Grid item xs={12}>
        <Grid item xs={12}>
          {props.boardData?.has_mysolvy_account ?
          <AutocompleteField
            name={"board_member"}
            label={t("form.board_member_form.board_person")}
            value={props.boardData?.board_member}
            options={props.boardUsers}
            isObjectOption={true}
            optionLabelKey={"full_name"}
            onChange={props.onChangeAutocomplete}
            addNewValue={false}
            multiple={false}
            disabled={!isEmptyValue(props.boardData?.board_company)}
          /> :
          <TextFieldFormControl
          name="board_person"
          value={props.boardData?.board_person}
          label={t("form.board_member_form.board_person")}
          onChange={props.onChange}
          disabled={!isEmptyValue(props.boardData?.board_company)}
        />}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="board_company"
          value={props.boardData?.board_company}
          label={t("form.board_member_form.board_company")}
          onChange={props.onChange}
          disabled={!isEmptyValue(props.boardData?.board_member) || !isEmptyValue(props.boardData?.board_person)}
        />
      </Grid>
      <Grid item xs={12}>
        <DateField
          name="board_date_start"
          label={t("form.board_member_form.board_date_start")}
          value={DateService.convertDateToFormatYYYYMMDD(
            props.boardData?.board_date_start
          )}
          onChange={props.onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <DateField
          name="board_date_end"
          label={t("form.board_member_form.board_date_end")}
          value={DateService.convertDateToFormatYYYYMMDD(
            props.boardData?.board_date_end
          )}
          onChange={props.onChange}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectField
          label={t("form.board_member_form.board_role")}
          name="board_role"
          value={props.boardData?.board_role}
          options={props.boardRoles}
          isObjectOption={false}
          onChange={props.onChange}
        />
      </Grid>
    </Grid>
  );
};

BoardMemberForm.propTypes = {
  boardData: PropTypes.object,
  boardRoles: PropTypes.array,
  boardUsers : PropTypes.array,
  onChange: PropTypes.func,
  onChangeAutocomplete : PropTypes.func
};

BoardMemberForm.defaultProps = {
  boardKinds: [],
  boardUsers : []
};

export default BoardMemberForm;
