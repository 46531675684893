import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Pie, getElementsAtEvent } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { isEmptyArray } from "../../../helpers/methods";

ChartJS.register(
  CategoryScale,
  LinearScale,
  ChartDataLabels,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.color = "var(--primary-light)";

const PieChart = (props) => {
  const defaultDataLabelsFormater = (value, ctx) => {
    let sum = 0;
    let dataArr = ctx.chart.data.datasets[0].data;
    dataArr.map((data) => {
      sum += data;
    });

    if (sum === 0 || value === 0) return "";

    let dataLabelFirstRow = `${((value * 100) / sum).toFixed(2)}%`;
    let dataLabelSecondRow = `(${value} ${props.dataLabelsUnit})`;

    let dataLabelFirstRowPadStart =
      Math.ceil(dataLabelSecondRow.length - dataLabelFirstRow.length) - 2;
    if (dataLabelFirstRowPadStart > 0) {
      dataLabelFirstRow = dataLabelFirstRow.padStart(
        dataLabelFirstRow.length + dataLabelFirstRowPadStart,
        " "
      );
    }

    let dataLabel = `${dataLabelFirstRow}\n${dataLabelSecondRow}`;

    return dataLabel;
  };

  const datalabelsFormaterFn =
    props.dataLabelsFormater || defaultDataLabelsFormater;

  const options = {
    maintainAspectRatio: false,
    responsive: props.responsive,
    plugins: {
      datalabels: {
        display: props.dataLabelsDisplay,
        anchor: props.dataLabelsAnchor,
        color: props.dataLabelsColor,
        align: "center",
        formatter: datalabelsFormaterFn,
      },
      legend: {
        position: props.legendPosition,
        display: props.legendDisplay,
      },
      title: {
        display: props.titleDisplay,
        text: props.titleText,
        position: props.titlePosition,
        align: props.titleAlign,
        font: {
          size: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return ` ${datalabelsFormaterFn(
              context.parsed,
              context
            ).trimStart()}`;
          },
        },
      },
    },
    color: "var(--primary)",
    interaction: {
      mode: "nearest",
    },
  };

  const chartRef = useRef();
  const onClick = (e) => {
    if (props.onClick) {
      const clickedPieSector = getElementsAtEvent(chartRef.current, e);
      if (!isEmptyArray(clickedPieSector)) {
        const idx = clickedPieSector[0].index;
        const label = props.data.labels[idx];
        props.onClick({ idx, label });
      }
    }
  };

  return (
    <Pie options={options} data={props.data} ref={chartRef} onClick={onClick} />
  );
};

PieChart.propTypes = {
  responsive: PropTypes.bool,
  legendDisplay: PropTypes.bool,
  legendPosition: PropTypes.string,
  titleDisplay: PropTypes.bool,
  titleText: PropTypes.string,
  titlePosition: PropTypes.string,
  titleAlign: PropTypes.string,
  dataLabelsDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dataLabelsAnchor: PropTypes.string,
  dataLabelsColor: PropTypes.string,
  data: PropTypes.object,
  onClick: PropTypes.func,
  dataLabelsFormater: PropTypes.func,
  dataLabelsUnit: PropTypes.string,
};

PieChart.defaultProps = {
  responsive: true,
  legendDisplay: true,
  legendPosition: "top",
  titlePosition: "top",
  titleDisplay: true,
  titleAlign: "center",
  dataLabelsDisplay: "auto",
  dataLabelsColor: "#000000de",
  dataLabelsUnit: "",
};

export default PieChart;
