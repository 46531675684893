import { useMemo } from "react";

import { Grid } from "@mui/material";

import { useSearchParams } from "react-router-dom";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";

import { NAVIGATION_DRAWER_TYPE_ADMIN } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import ObjectTableWithToolBar from "../../components/other/ObjectTableWithToolBar";

import { getObjectTablePropsByLevel } from "./TableConfigs";

import { getFieldValueFromSearchParams } from "../../helpers/methods";

const DEFUALT_OBJECT_LEVEL = "5";
const OBJECT_LEVEL_KEY = "object_level";

export default function AdminLocationsPage(props) {
  const { pageName } = props;

  const [searchParams] = useSearchParams();

  const objectLevel = useMemo(
    () =>
      getFieldValueFromSearchParams(searchParams, OBJECT_LEVEL_KEY) ||
      DEFUALT_OBJECT_LEVEL,
    [searchParams]
  );

  const tableProps = useMemo(
    () => getObjectTablePropsByLevel(objectLevel),
    [objectLevel]
  );

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_ADMIN}
    >
      <DefaultPageWrapper titleKey={"admin_locations"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
          marginTop={1}
        >
          <Grid item xs={12}>
            <ObjectTableWithToolBar
              pageName={pageName}
              objectLevel={objectLevel}
              objectLevelKey={OBJECT_LEVEL_KEY}
              defaultObjectLevel={DEFUALT_OBJECT_LEVEL}
              tableName={tableProps.tableName}
              tableConfig={tableProps.tableConfig}
              tableFilterPrefix={tableProps.tableFilterPrefix}
            />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
