import { useRef, useState } from "react";

const useFieldTable = () => {
  const [isSelected, setIsSelected] = useState(false);
  const fieldTableRef = useRef(null);

  const onBlur = (event) => {
    setIsSelected(false);
  };

  const onSelectField = () => {
    setIsSelected(true);
    setTimeout(() => {
      if(fieldTableRef.current !== null){
        fieldTableRef.current.select();
      }
    }, 0);
  };

  return {
    fieldTableRef,
    isSelected,
    setIsSelected,
    onSelectField,
    onBlur,
  };
};

export default useFieldTable;
