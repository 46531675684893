import i18n from "../../../i18n";

import {
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
} from "../../../helpers/constants";

import SelectFieldService from "../../../services/selectFieldService";

import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

import { convertFlatListFromBackendToTranslatedOptionObject } from "../../../helpers/methods";

export const TICKET_CATEGORY_TABLE_NAME = "ticket_category";
export const TICKET_CATEGORY_TABLE_PREFIX = "ticket_category";

export const TICKET_CATEGORY_TABLE_CONFIG = [
  {
    name: "ticket_category",
    getValueCallback: (rowData) => rowData.tc_name,
    label: i18n.t("table.ticket_category.tc_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_category,
      isObjectOption: true,
      optionLabelKey: "tc_name",
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
  {
    name: "tc_kind",
    getValueCallback: (rowData) =>
      i18n.t(`backend_choices_list.${rowData.tc_kind}`),
    label: i18n.t("table.ticket_category.tc_kind"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: convertFlatListFromBackendToTranslatedOptionObject(
        filteringData.value.tc_kind
      ),
      isObjectOption: true,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "tc_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.tc_active),
    label: i18n.t("table.ticket_category.tc_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },

  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
    ),
    label: i18n.t("edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
];
