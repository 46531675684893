import i18n from "../../i18n";
import {
  BOOLEAN_FIELD_TYPE,
  DATETIME_TO_DATE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import SelectFieldService from "../../services/selectFieldService";
import InfoIcon from "@mui/icons-material/Info";
import DateService from "../../services/dateService";

export const SUBCONTRACTOR_TABLE_NAME = "subcontractor_table";

export const SUBCONTRACTOR_TABLE_FIELDS_CONFIG = [
  {
    name: "preview",
    getValueCallback: (rowData, onClickEdit) => {
      return (
        <InfoIcon onClick={(e) => onClickEdit(e, rowData.id)} />
      );
    },
    label: i18n.t("table.subcontractor_table.preview"),
    filterType: null,
    allowChangeVisibility: false,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "subcontractor_full_name",
    getValueCallback: (rowData) => rowData.subcontractor_full_name,
    label: i18n.t("table.subcontractor_table.subcontractor_full_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
    allowChangeVisibility: false,
  },
  {
    name: "subcontractor_short_name",
    getValueCallback: (rowData) => rowData.subcontractor_short_name,
    label: i18n.t("table.subcontractor_table.subcontractor_short_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "subcontractor_legal",
    getValueCallback: (rowData) => rowData.subcontractor_legal,
    label: i18n.t("table.subcontractor_table.subcontractor_legal"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "subcontractor_nip",
    getValueCallback: (rowData) => rowData.subcontractor_nip,
    label: i18n.t("table.subcontractor_table.nip"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "subcontractor_krs",
    getValueCallback: (rowData) => rowData.subcontractor_krs,
    label: i18n.t("table.subcontractor_table.krs"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "subcontractor_regon",
    getValueCallback: (rowData) => rowData.subcontractor_regon,
    label: i18n.t("table.subcontractor_table.regon"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "subcontractor_is_contractor",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.subcontractor_is_contractor),
    label: i18n.t("table.subcontractor_table.contractor"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "subcontractor_is_producer",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.subcontractor_is_producer),
    label: i18n.t("table.subcontractor_table.producer"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "subcontractor_is_supplier",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.subcontractor_is_supplier),
    label: i18n.t("table.subcontractor_table.supplier"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "subcontractor_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.subcontractor_active),
    label: i18n.t("table.subcontractor_table.subcontractor_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.created
      ),
    label: i18n.t("table.subcontractor_table.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    sortable: true
  },
];
