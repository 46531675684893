import PropTypes from "prop-types";
import { CircularProgress, Grid } from "@mui/material";

export default function LoaderWrapper(props) {
  return (
    <>
      {props.showLoader ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
}

LoaderWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired),
  ]),
  showLoader: PropTypes.bool,
};

LoaderWrapper.defaultProps = {
  showLoader: false,
};
