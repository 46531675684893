import { useTranslation } from "react-i18next";

import TabsNavigationBar from "../../TabsNavigationBar";

function DunReportsNavigationOffersSubBar(props) {
  const { t } = useTranslation();
  return (
    <TabsNavigationBar
      pages={[
        {
          label: t("headers.offers_per_location_report"),
          link: "/offers_per_location_report/",
        },
        {
          label: t("headers.offers_per_status_report"),
          link: "/offers_per_status_report/",
        },
        {
          label: t("headers.offers_per_report_type_report"),
          link: "/offers_per_report_type_report/",
        },
      ]}
    />
  );
}

DunReportsNavigationOffersSubBar.propTypes = {};

export default DunReportsNavigationOffersSubBar;
