import * as React from "react";
import { useCallback } from "react";
import PropTypes from "prop-types";

import {
  StyledTableCell,
  StyledTableRow,
} from "../../base/BaseTable/BaseTable";
import CheckboxField from "../../base/CheckboxField/checkboxField";


const EditableTableHeader = React.memo(
  ({
    showCheckbox,
    checkedAll,
    onClickCheckAll,
    headersConfig,
    countDataRows,
  }) => {
    const getHeaderName = (header) => {
      if (header.required) {
        return `${header.label}*`
      }
      return header.label
    }

    return (
      <StyledTableRow>
        {showCheckbox && (
          <StyledTableCell style={{ textAlign: "center" }}>
            <CheckboxField
              size="small"
              labelPlacement={"bottom"}
              checked={checkedAll}
              onChange={onClickCheckAll}
              style={{ color: "var(--white)" }}
            />
          </StyledTableCell>
        )}

        {countDataRows && (
          <StyledTableCell>
            Nr
          </StyledTableCell>
        )}
        {headersConfig.map((header, index) => (
          <StyledTableCell
            className="capitalize-first-letter"
            key={`${header.name}${index}`}
            align="center"
            sx={{ whiteSpace: "nowrap" }}
          >
            {getHeaderName(header)}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    );
  }
);

EditableTableHeader.propTypes = {
  showCheckbox: PropTypes.bool,
  showDetailsIcon: PropTypes.bool,
  checkedAll: PropTypes.bool,
  onClickCheckAll: PropTypes.func,
  headersConfig: PropTypes.arrayOf(
    PropTypes.shape({
      filterType: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string,
      fieldConfig: PropTypes.object,
    })
  ),
};

EditableTableHeader.defaultProps = {
};

export default EditableTableHeader;
