import DunReportsNavigationBar from "../../components/bar/DunReportsNavigationBar";
import DunReportsNavigationBuidlingSubBar from "../../components/bar/DunReportsNavigationBar/DunReportsNavigationBuildingSubBar";
import TicketsReportBasePage from "../TicketsReportBasePage";

const CHART_DESCRIPTION = (
  <div>
    Wykres pokazuje liczbę unikalnych zgłoszeń z możliwością filtracji po
    kategorii zgłoszenia, zakresu czasowego oraz wybranych budynków.
    <br />
    <br />W tabeli pod wykresem znajdują się 3 dodatkowe kolumny:
    <br />
    <br />
    <b>1. SLA</b> – zakładany czas realizacji zgłoszenia (na podstawie
    standardowego czasu realizacji, zaokrąglonego do pełnego dnia).
    <br />
    <b>2. Czas realizacji</b> – faktyczny czas od założenia ticketu do
    zamknięcia, zaokrąglenie do pełnego dnia <br />
    <b>3. Przekroczenie SLA</b> – różnica między czasem od utworzenia do
    zamknięcia ticketu, a wskaźnikiem SLA, zaokrąglenie do pełnego dnia <br />
  </div>
);

export default function TicketsPerLocationAndDayReportPage(props) {
  return (
    <TicketsReportBasePage
      pageName={props.pageName}
      pageTitleKey={"tickets_per_day"}
      groupBy={"ticket_category"}
      labelBy={"location"}
      showSelectTimeRange={false}
      showOnlySelectDay={true}
      showTicketOwner={false}
      showSelectObjectLevel2={true}
      NavigationBar={
        <>
          <DunReportsNavigationBar
            multipleLocationKey={"/tickets_per_location_day_report/"}
          />
          <DunReportsNavigationBuidlingSubBar />
        </>
      }
      showNavigationBar={true}
      showOpenCloseFilterButton={true}
      showTenantVisibilityButton={true}
      chartHeight={"40vh"}
      chartHeightInFullScreen={"67vh"}
      showChartInformationDialog={false}
      chartDescrtiption={CHART_DESCRIPTION}
    />
  );
}

TicketsPerLocationAndDayReportPage.propTypes = {};
