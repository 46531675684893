import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import DateField from "../../field/DateField/DateField";
import AutocompleteField from "../../field/AutocompleteField";

import { convertFlatListFromBackendToTranslatedOptionObject } from "../../../helpers/methods";

export default function OfferChartToolBarForm(props) {
  const { t } = useTranslation();

  const getFormContent = () => (
    <>
      {props.showSelectReportType && (
        <Grid item xs={12} md={props.isVerticalForm ? 12 : 1.5}>
          <AutocompleteField
            name="label_by_key"
            isObjectOption={true}
            value={
              Array.isArray(props.formValue.label_by_key)
                ? props.formValue.label_by_key[0]
                : props.formValue.label_by_key
            }
            options={convertFlatListFromBackendToTranslatedOptionObject(
              props.filteringData.value?.report_type
            )}
            label={t("bar.offer_chart_bar.report_type")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            limitTags={props.isVerticalForm ? undefined : 0}
          />
        </Grid>
      )}

      {props.showSelectTenant && (
        <Grid
          item
          xs={12}
          md={props.isVerticalForm ? 12 : props.showSelectReportType ? 1.75 : 2}
        >
          <AutocompleteField
            name="tenant"
            multiple={true}
            isObjectOption={true}
            optionLabelKey={"tenant_short_name"}
            value={props.formValue.tenant}
            options={props.filteringData.value?.tenant}
            label={t("bar.offer_chart_bar.tenant")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            limitTags={props.isVerticalForm ? undefined : 0}
          />
        </Grid>
      )}
      {props.showSelectObjectLevel2 && (
        <Grid item xs={12} md={props.isVerticalForm ? 12 : 2}>
          <AutocompleteField
            name="objects_level_2_id"
            multiple={true}
            isObjectOption={true}
            optionLabelKey={"object_name"}
            value={props.formValue.objects_level_2_id}
            options={props.filteringData.value?.object_level_2}
            label={t("bar.offer_chart_bar.object_level_2")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            limitTags={props.isVerticalForm ? undefined : 0}
            required
          />
        </Grid>
      )}
      {props.showSelectOfferStatus && (
        <Grid
          item
          xs={12}
          md={props.isVerticalForm ? 12 : props.showSelectReportType ? 1.75 : 2}
        >
          <AutocompleteField
            name="offer_status"
            multiple={true}
            isObjectOption={true}
            optionLabelKey={"off_status_name"}
            value={props.formValue.offer_status}
            options={props.filteringData.value?.offer_status}
            label={t("bar.offer_chart_bar.offer_status")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            limitTags={props.isVerticalForm ? undefined : 0}
            required
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
        md={props.isVerticalForm ? 12 : 3}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <DateField
          name={"created_start"}
          label={t("bar.offer_chart_bar.created_start")}
          value={props.formValue.created_start}
          onChange={props.onChangeDateConvertedToDateTimeWithZeroHourOfDate}
          showClearIcon={false}
          required
          maxDate={
            props.formValue.created_end
              ? props.formValue.created_end
              : undefined
          }
        />
        <div style={{ marginInline: "5px" }}>{"-"}</div>
        <DateField
          name={"created_end"}
          label={t("bar.offer_chart_bar.created_end")}
          value={props.formValue.created_end}
          onChange={props.onChangeDateConvertedToDateTimeWithEndHourOfDate}
          showClearIcon={false}
          required
          minDate={
            props.formValue.created_start
              ? props.formValue.created_start
              : undefined
          }
        />
      </Grid>
    </>
  );

  if (props.onlyFormContent) return getFormContent();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      rowSpacing={1}
    >
      {getFormContent()}
    </Grid>
  );
}

OfferChartToolBarForm.propTypes = {
  formValue: PropTypes.object,
  filteringData: PropTypes.object,
  setFormValue: PropTypes.func,
  onChangeDateConvertedToDateTimeWithEndHourOfDate: PropTypes.func,
  onChangeDateConvertedToDateTimeWithZeroHourOfDate: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  isVerticalForm: PropTypes.bool,
  onlyFormContent: PropTypes.bool,
  showSelectObjectLevel2: PropTypes.bool,
  showSelectTenant: PropTypes.bool,
  showSelectOfferStatus: PropTypes.bool,
  showSelectReportType: PropTypes.bool,
};

OfferChartToolBarForm.defaultProps = {
  isVerticalForm: false,
  onlyFormContent: true,
  showSelectObjectLevel2: false,
  showSelectTenant: false,
  showSelectOfferStatus: false,
  showSelectReportType: false,
  showClearFiltersIcon: true,
};
