import { getKeysFromFilterDict } from "../../../helpers/methods";
import useEnclosuresData from "../../../hooks/useEnclosuresData";
import usePermissionService from "../../../services/permissionService";
import EnclosuresDialog from "../EnclosuresDialog";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";

function EnclosuresByItemDialog(props) {
  const { availableEnclosuresFilters } = usePermissionService();

  const {
    enclosures,
    saveEnclosures,
    refetchEnclosures,
    updateEnclosure,
    deleteEnclosureForItem,
    chooseEnclosuresFilterGroup,
    chosenEnclosuresFilterGroup,
    isEnclosuresLoading,
    onDownloadEnclosure,
    onPreviewEnclosure,
    onAddEnclosureToFavorite,
    defaultEnclosureType,
    showExtendedAddEnclosureDialog,
    showFilters,
    showEnclosureType,
    showSubcontractorSelect,
    canHideEnclosures,
    showPublishButton,
  } = useEnclosuresData(
    props.itemId,
    getKeysFromFilterDict(availableEnclosuresFilters),
    props.itemType
  );

  const [existsChanges, setExistsChanges] = useState(false);

  const downloadEnclosure = useCallback((enclosureId, enclosureName) => {
    onDownloadEnclosure(enclosureId, enclosureName);
  }, []);

  const previewEnclosure = useCallback((enclosureId, index) => {
    onPreviewEnclosure(enclosureId, index);
  }, []);

  const addNewEnclosureFromUser = useCallback(
    (enclosures, commonData) => {
      saveEnclosures(enclosures, commonData);
      setExistsChanges(true);
    },
    [saveEnclosures]
  );

  const updateEnclosureCallback = useCallback((enclosure) => {
    updateEnclosure(enclosure);
    setExistsChanges(true);
  }, []);

  const deleteEnclosureCallback = useCallback((enclosureId) => {
    deleteEnclosureForItem(enclosureId);
    setExistsChanges(true);
  }, []);

  const addEnclosureToFavorite = useCallback((enclosureId) => {
    onAddEnclosureToFavorite(enclosureId);
  }, []);

  return (
    <EnclosuresDialog
      open={props.open}
      enclosures={enclosures}
      onClose={() => props.onClose(existsChanges)}
      onSubmit={(enclosures, commonData) =>
        addNewEnclosureFromUser(enclosures, commonData)
      }
      onDownload={(enclosureId, enclosureName) =>
        downloadEnclosure(enclosureId, enclosureName)
      }
      onPreview={previewEnclosure}
      onAddEnclosureToFavorite={addEnclosureToFavorite}
      refetchEnclosures={refetchEnclosures}
      showFilters={showFilters}
      onChooseFilterGroup={chooseEnclosuresFilterGroup}
      chosenFilterGroup={chosenEnclosuresFilterGroup}
      showLoader={isEnclosuresLoading}
      attachmentWindowHeight={props.attachmentWindowHeight}
      filterTypes={availableEnclosuresFilters}
      showExtendedAddEnclosureDialog={showExtendedAddEnclosureDialog}
      defaultEnclosureType={defaultEnclosureType}
      onUpdateEnclosure={(enclosure) => updateEnclosureCallback(enclosure)}
      onDeleteEnclosure={(enclosureId) => deleteEnclosureCallback(enclosureId)}
      showEnclosureType={showEnclosureType}
      showSubcontractorSelect={showSubcontractorSelect}
      canHideEnclosures={canHideEnclosures}
      readOnly={props.readOnly}
      showPublishButton={showPublishButton}
    />
  );
}

EnclosuresByItemDialog.propTypes = {
  open: PropTypes.bool,
  itemId: PropTypes.string,
  onClose: PropTypes.func,
  attachmentWindowHeight: PropTypes.object,
  itemType: PropTypes.oneOf(["ticket", "order", "ticket_order"]),
  readOnly: PropTypes.bool,
};

EnclosuresByItemDialog.defaultProps = {
  open: false,
  attachmentWindowHeight: { height: 400 },
  readOnly: false,
};

export default EnclosuresByItemDialog;
