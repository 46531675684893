import { useState, useEffect, useRef } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync } from "../../../hooks/useAsync";

import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../hooks/useFilterSearchParams";

import useLocationService from "../../../services/locationService";

import ObjectCategoryTable from "../../table/ObjectCategoryTable";

import ObjectCategoryDialog from "../ObjectCategoryDialog/ObjectCategoryDialog";
import useDialog from "../../../hooks/useDialog";

import ObjectSubcategoryDialog from "../ObjectSubcategoryDialog";
import useDialogWithId from "../../../hooks/useDialogWithId";

import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

import { isSearchParamsObjectEmpty } from "../../../helpers/methods";

import {
  overflowButtonStyle,
  centerVericalAlignIconStyle,
} from "../../../helpers/styles";

import {
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
} from "../../../helpers/constants";

const ObjectSubcategorySubTable = (props) => {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(props.tableFilterPrefix);

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefix,
  } = useFilterSearchParams(searchParams);

  const [objectSubcategorySearchParams, setObjectSubcategorySearchParams] =
    useState(getSearchParamsByFilterPrefix(props.tableFilterPrefix));

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      props.tableFilterPrefix,
      objectSubcategorySearchParams,
      setObjectSubcategorySearchParams
    );
  }, [searchParams, props.tableFilterPrefix]);

  const { getObjectSubcategoryData, getObjectSubcategoryFilteringData } =
    useLocationService();

  const objectSubcategoryData = useAsync(() => {
    return getObjectSubcategoryData(objectSubcategorySearchParams);
  }, [objectSubcategorySearchParams]);

  const objectSubcategoryFilteringData = useAsync(
    getObjectSubcategoryFilteringData
  );

  const [objectSubcategoryDataLocaly, setObjectSubcategoryDataLocaly] =
    useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (objectSubcategoryData.loading) {
      return;
    }
    setObjectSubcategoryDataLocaly(objectSubcategoryData.value.results);
    setCountRecords(objectSubcategoryData.value.count);
  }, [objectSubcategoryData.loading]);

  const [
    openObjectCategoryDialog,
    onOpenObjectCategoryDialog,
    onCloseObjectCategoryDialog,
  ] = useDialog();

  const [
    openObjectSubcategoryDialog,
    objectSubcategoryId,
    onOpenObjectSubcategoryDialog,
    onCloseObjectSubcategoryDialog,
  ] = useDialogWithId();

  const objectSubcategoryDialogModeRef = useRef();
  const handleOpenObjectSubcategoryDialogInCreateMode = () => {
    objectSubcategoryDialogModeRef.current = DIALOG_CREATE_MODE;
    onOpenObjectSubcategoryDialog(undefined);
  };

  const handleOpenObjectSubcategoryDialogInEditMode = (objectSubcategoryId) => {
    objectSubcategoryDialogModeRef.current = DIALOG_EDIT_MODE;
    onOpenObjectSubcategoryDialog(objectSubcategoryId);
  };

  const getObjectSubcategoryDialogCallback = () => {
    setObjectSubcategoryDataLocaly(undefined);
    if (
      objectSubcategoryDialogModeRef.current === DIALOG_CREATE_MODE &&
      !isSearchParamsObjectEmpty(objectSubcategorySearchParams)
    ) {
      setSearchParams(clearSearchParamsByFilterPrefix(props.tableFilterPrefix));
    } else {
      objectSubcategoryData.refetch();
    }

    if (props.setExistsChanges) {
      props.setExistsChanges(true);
    }
  };

  const getObjectCategoryDialogCallback = () => {
    if (props.setExistsChanges) {
      props.setExistsChanges(true);
    }
  };

  const isLoading =
    objectSubcategoryDataLocaly === undefined ||
    objectSubcategoryFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12}>
        <ObjectCategoryTable
          showCleanFilterIcon={true}
          style={{ maxHeight: "60vh" }}
          showFilters={true}
          data={objectSubcategoryDataLocaly}
          filteringData={objectSubcategoryFilteringData}
          filterPrefix={props.tableFilterPrefix}
          countRecords={countRecords}
          page={page}
          onPageChange={handleChangePageWithSearchParams}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onClickEdit={handleOpenObjectSubcategoryDialogInEditMode}
          initialExpandedFilterHeader={
            !isSearchParamsObjectEmpty(objectSubcategorySearchParams)
          }
          resetPageNumber={resetPageNumber}
          tableConfig={props.tableConfig}
          hiddenColumns={[]}
          columnsOrders={undefined}
          showExportToFileButton={false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          fullWidth
          variant="contained"
          startIcon={
            <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
          }
          onClick={onOpenObjectCategoryDialog}
          sx={overflowButtonStyle}
          color={"primary"}
        >
          {t("dialog.object_category.add_category")}
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          fullWidth
          variant="contained"
          startIcon={
            <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
          }
          onClick={handleOpenObjectSubcategoryDialogInCreateMode}
          sx={overflowButtonStyle}
          color={"primary"}
        >
          {t("dialog.object_category.add_subcategory")}
        </Button>
      </Grid>
      {openObjectSubcategoryDialog && (
        <ObjectSubcategoryDialog
          open={openObjectSubcategoryDialog}
          onClose={onCloseObjectSubcategoryDialog}
          dialogMode={objectSubcategoryDialogModeRef.current}
          onSubmitCallback={getObjectSubcategoryDialogCallback}
          objectCategoryId={objectSubcategoryId}
        />
      )}
      {openObjectCategoryDialog && (
        <ObjectCategoryDialog
          open={openObjectCategoryDialog}
          onClose={onCloseObjectCategoryDialog}
          dialogMode={DIALOG_CREATE_MODE}
          onSubmitCallback={getObjectCategoryDialogCallback}
        />
      )}
    </Grid>
  );
};

ObjectSubcategorySubTable.propTypes = {
  tableName: PropTypes.string,
  tableConfig: PropTypes.object,
  tableFilterPrefix: PropTypes.string,
  setExistsChanges: PropTypes.func,
};

ObjectSubcategorySubTable.defaultProps = {};

export default ObjectSubcategorySubTable;
