import BasicDialog from "../../base/BasicDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import TopCommunicationDiaryBox from "../../box/TopCommunicationDiaryBox/TopCommunicationDiaryBox";
import { INTERNAL_TYPE } from "../../../helpers/constants";
import AddEnclosureDialog from "../AddEnclosureDialog";
import useDialogWithFormValue from "../../../hooks/useDialogWithFormValue";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import EnclosuresWindow from "../../window/EnclosuresWindow";
import AddEnclosureButton from "../../button/AddEnclosureButton";

function EnclosuresDialog(props) {
  const { t } = useTranslation();

  const [
    openAddNewEnclosureDialog,
    newEnclosureFormValue,
    handleOpenAddNewEnclosureDialog,
    handleCloseAddNewEnclosureDialog,
  ] = useDialogWithFormValue();

  const getTopCommuncationDiaryBox = () => {
    return (
      <TopCommunicationDiaryBox
        filterTypes={props.filterTypes}
        chosenFilterGroup={props.chosenFilterGroup}
        onChooseFilterGroup={props.onChooseFilterGroup}
        buttonsGridSm={2}
      />
    );
  };

  const getBottomattachmentsBox = () => {
    return (
      <Grid
        container
        spacing={1}
        alignContent="center"
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <AddEnclosureButton
          variant="contained"
          color="primary"
          onClick={handleOpenAddNewEnclosureDialog}
          sx={{ padding: "7px", margin: "5px" }}
        />
      </Grid>
    );
  };

  const onSaveEnclosureFile = (enclosures, commonData) => {
    handleCloseAddNewEnclosureDialog();
    props.onSubmit(enclosures, commonData);
  };

  return (
    <>
      <BasicDialog
        open={props.open}
        onClose={props.onClose}
        titleAlign="center"
        title={t("dialog.enclosures_dialog.enclosures")}
        maxWidth="lg"
        showTopActionButton={props.showFilters}
        topActionButton={getTopCommuncationDiaryBox()}
        showBottomActionButton={true}
        bottomActionStyle={{ paddingTop: "10px", paddingLeft: "30px" }}
        bottomActionButton={
          !props.readOnly ? getBottomattachmentsBox() : undefined
        }
        titleSx={{ padding: 0, marign: 0 }}
        contentAlign="right"
        contentSx={{
          margin: 0,
          padding: "5px",
          overflow: "hidden",
          height: "400px",
        }}
      >
        <LoaderWrapper showLoader={props.showLoader}>
          <EnclosuresWindow
            enclosures={props.enclosures}
            showEnclosureType={props.showEnclosureType}
            showSubcontractorSelect={props.showSubcontractorSelect}
            showPublishButton={props.showPublishButton}
            onUpdateEnclosure={props.onUpdateEnclosure}
            onDeleteEnclosure={props.onDeleteEnclosure}
            onAddEnclosureToFavorite={props.onAddEnclosureToFavorite}
            refetchEnclosures={props.refetchEnclosures}
            onDownload={props.onDownload}
            onPreview={props.onPreview}
            isLoading={props.showLoader}
            canHideEnclosures={props.canHideEnclosures}
          />
        </LoaderWrapper>
      </BasicDialog>

      <AddEnclosureDialog
        open={openAddNewEnclosureDialog}
        onClose={handleCloseAddNewEnclosureDialog}
        onSubmit={onSaveEnclosureFile}
        defaultEnclosureType={props.defaultEnclosureType}
        showExtendedAddEnclosureDialog={props.showExtendedAddEnclosureDialog}
        onDownload={props.onDownload}
        onPreview={props.onPreview}
      />
    </>
  );
}

EnclosuresDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  messages: PropTypes.array,
  onSubmit: PropTypes.func,
  showFilters: PropTypes.bool,
  showLoader: PropTypes.bool,
  messageWindowHeight: PropTypes.object,
  filterTypes: PropTypes.array,
  onOpenAddNewEnclosureDialog: PropTypes.func,
  openAddNewEnlosureDialog: PropTypes.bool,
  onCloseNewEnlosureDialog: PropTypes.func,
  newEnclosureFormValue: PropTypes.object,
  onSaveEnclosure: PropTypes.func,
  defaultEnclosureType: PropTypes.string,
  onDownload: PropTypes.func,
  onPreview: PropTypes.func,
  onUpdateEnclosure: PropTypes.func,
  onDeleteEnclosure: PropTypes.func,
  onAddEnclosureToFavorite: PropTypes.func,
  showExtendedAddEnclosureDialog: PropTypes.bool,
  canHideEnclosures: PropTypes.bool,
  readOnly: PropTypes.bool,
};

EnclosuresDialog.defaultProps = {
  open: false,
  messages: [],
  showFilters: false,
  showLoader: true,
  messageWindowHeight: { height: 400 },
  filterTypes: [],
  openAddNewEnlosureDialog: false,
  newEnclosureFormValue: {},
  defaultEnclosureType: INTERNAL_TYPE,
  showExtendedAddEnclosureDialog: false,
  canHideEnclosures: false,
  readOnly: false,
};

export default EnclosuresDialog;
