import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { useSearchParams } from "react-router-dom";

import i18n from "../../../i18n";

import SplitButton from "../SplitButton";
import { getFieldValueFromSearchParams } from "../../../helpers/methods";

import useFillterButton from "../../../hooks/useFilterButton";
import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";

function SelectObjectLevelButton(props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { handleClickFilterButton } = useFillterButton(props.filterPrefix);
  const { getFilterFieldKey } = usePaginationWithSearchParams(
    props.filterPrefix
  );

  const level1FilterKey = useMemo(
    () => getFilterFieldKey("1"),
    [getFilterFieldKey]
  );
  const level2FilterKey = useMemo(
    () => getFilterFieldKey("2"),
    [getFilterFieldKey]
  );
  const level3FilterKey = useMemo(
    () => getFilterFieldKey("3"),
    [getFilterFieldKey]
  );
  const level4FilterKey = useMemo(
    () => getFilterFieldKey("4"),
    [getFilterFieldKey]
  );
  const level5FilterKey = useMemo(
    () => getFilterFieldKey("5"),
    [getFilterFieldKey]
  );

  const filterOptions = [
    {
      label: i18n.t("button.select_object_level.level_1"),
      key: level1FilterKey,
      callback: () => handleClickFilter("1"),
    },
    {
      label: i18n.t("button.select_object_level.level_2"),
      key: level2FilterKey,
      callback: () => handleClickFilter("2"),
    },

    {
      label: i18n.t("button.select_object_level.level_3"),
      key: level3FilterKey,
      callback: () => handleClickFilter("3"),
    },

    {
      label: i18n.t("button.select_object_level.level_4"),
      key: level4FilterKey,
      callback: () => handleClickFilter("4"),
    },

    {
      label: i18n.t("button.select_object_level.level_5"),
      key: level5FilterKey,
      callback: () => handleClickFilter("5"),
    },
  ];

  const selectedFilterKey = useMemo(() => {
    const filterValue = getFieldValueFromSearchParams(
      searchParams,
      getFilterFieldKey(props.filterKey)
    );

    if (filterValue === "1") return level1FilterKey;
    if (filterValue === "2") return level2FilterKey;
    if (filterValue === "3") return level3FilterKey;
    if (filterValue === "4") return level4FilterKey;
    if (filterValue === "5") return level5FilterKey;

    return props.defaultLevelFilterKey;
  }, [searchParams]);

  const handleClickFilter = (filterValue) => {
    if (props.onClickFilter) {
      props.onClickFilter(filterValue);
    } else {
      handleClickFilterButton(filterValue, props.filterKey);
    }
  };

  return (
    <SplitButton
      selectedItemKey={selectedFilterKey}
      withChangeSelectedItem={true}
      color={props.color}
      options={filterOptions}
    />
  );
}

SelectObjectLevelButton.propTypes = {
  filterKey: PropTypes.string,
  filterPrefix: PropTypes.string,
  defaultLevelFilterKey: PropTypes.string,
  onClickFilter: PropTypes.func,
  color: PropTypes.string,
};

SelectObjectLevelButton.defaultProps = {
  filterKey: "object_level",
  defaultLevelFilterKey: "5",
  color: "primary",
};

export default SelectObjectLevelButton;
