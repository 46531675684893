import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import { useAsync } from "../../../../hooks/useAsync";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import useWarehouseService from "../../../../services/warehouseService";
import useDialog from "../../../../hooks/useDialog";
import { WAREHOUSE_TABLE_CONFIG } from "./TableConfig";
import TableService from "../../../../services/tableService";
import FilterTable from "../../../table/FilterTable";
import { useEmptyArrayMemo } from "../../../../hooks/useEmptyArrayMemo";
import { useSearchParams } from "react-router-dom";
import useFilterSearchParams from "../../../../hooks/useFilterSearchParams";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import SearchButton from "../../../button/SearchButton/SearchButton";
import FilterDialog from "../../../base/FilterDialog";
import UniversalFilterForm from "../../../form/UniversalFilterForm";
import useDialogWithId from "../../../../hooks/useDialogWithId";
import WarehouseDetailsDialog from "../../../dialog/WarehouseDetailsDialog";
import WarehouseLocationDialog from "../../../dialog/WarehouseLocationDialog/WarehouseLocationDialog";

const WAREHOUSE_FILTER_PREFIX = "warehouse";

const WarehouseAdminWarehousesTab = (props) => {
  const { t } = useTranslation();
  const [warehousesLocal, setWarehousesLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [warehouseTableSearchParams, setWarehouseTableSearchParams] = useState({});

  const { setNewItemSearchParamsIfAreChanged,
    clearSearchParamsByFilterPrefix
  } =
    useFilterSearchParams(searchParams);

  const { emptyArrayMemo } = useEmptyArrayMemo()
  const {
    getWarehouseList
  } = useWarehouseService()

  const onCleanFlitersInFilterDialog = () => {
    setSearchParams(clearSearchParamsByFilterPrefix(WAREHOUSE_FILTER_PREFIX));
  };

  const [
    openWarehouseDialog,
    warehouseChangeId,
    onOpenWarehouseDialog,
    onCloseWarehouseDialog,
    exportChangeStatusStatus
  ] = useDialogWithId();

  

  const [
    openWarehouseLocationDialog,
    warehouseLocationChangeId,
    onOpenWarehouseLocationDialog,
    onCloseWarehouseLocationDialog,
    exportChangeLocationStatus
  ] = useDialogWithId();


  const [openFilterDialog,
    onOpenFilterDialog,
    onCloseFilterDialog] =
    useDialog();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
  } = usePaginationWithSearchParams(WAREHOUSE_FILTER_PREFIX);

  const warehouseData = useAsync(
    () => getWarehouseList(warehouseTableSearchParams),
    [warehouseTableSearchParams]
  );


  useEffect(() => {
    if (warehouseData.loading) {
      return;
    }
    setWarehousesLocal(warehouseData.value.results);
    setCountRecords(warehouseData.value.count);
  }, [warehouseData.loading]);

  const isLoading = warehousesLocal === undefined || props.filteringData.loading


  const handleOpenWarehouseDialog = useCallback(
    (e, warehouseId) => {
      e.stopPropagation();
      onOpenWarehouseDialog(warehouseId);
    },
    []
  );

  const onClickMultipleLocationAlert = useCallback(
    (e, warehouseId) => {
      e.stopPropagation();
      onOpenWarehouseLocationDialog(warehouseId);
    },
    []
  );


  const data = React.useMemo(
    () =>

      TableService.getPreparedDataForCollapsibleTable(
        WAREHOUSE_TABLE_CONFIG,
        warehousesLocal ? warehousesLocal : emptyArrayMemo,
        emptyArrayMemo,
        emptyArrayMemo,
        onClickMultipleLocationAlert,
        handleOpenWarehouseDialog
      ),

    [warehousesLocal, WAREHOUSE_TABLE_CONFIG]
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      WAREHOUSE_FILTER_PREFIX,
      warehouseTableSearchParams,
      setWarehouseTableSearchParams
    );
  }, [searchParams]);


  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={1}
    >
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
          onClick={(e) =>onOpenWarehouseDialog(undefined)}
        >
          {t("tabs.warehouse_admin.add_warehouse")}
        </Button>
      </Grid>
      <Grid item sm={9} />
      <Grid item xs={12} sm={1}>
        <SearchButton onClickSearch={onOpenFilterDialog} />
      </Grid>
      <Grid item xs={12}>
        <FilterTable
          data={data}
          showCheckbox={false}
          showCleanFilterIcon={true}
          countRecords={countRecords}
          page={page}
          onPageChange={handleChangePageWithSearchParams}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          filteringData={props.filteringData}
          style={{ maxHeight: "vh" }}
          tableConfig={WAREHOUSE_TABLE_CONFIG}
          showContextMenu={false}
          filterPrefix={WAREHOUSE_FILTER_PREFIX}
          headersConfig={TableService.getHeadersConfigForCollapsibleTable(
            WAREHOUSE_TABLE_CONFIG,
            props.filteringData || emptyArrayMemo,
            emptyArrayMemo,
            emptyArrayMemo,
          )}
        />
      </Grid>
      {openFilterDialog &&
        <FilterDialog
          open={openFilterDialog}
          onCleanFilters={onCleanFlitersInFilterDialog}
          onClose={onCloseFilterDialog}
          resetPageNumber={resetPageNumber}
          filterPrefix={WAREHOUSE_FILTER_PREFIX}
          filterForm={
            <UniversalFilterForm
              filteringData={props.filteringData || emptyArrayMemo}
              filtersConfig={WAREHOUSE_TABLE_CONFIG}
              includeOpenCloseFilter={false}
            />
          }
        />
      }
      {openWarehouseDialog &&
        <WarehouseDetailsDialog
          warehouseId={warehouseChangeId}
          open={openWarehouseDialog}
          onClose={onCloseWarehouseDialog}
          onRefetchData={warehouseData.refetch}
          filteringData={props.filteringData}
        />

      }
      {openWarehouseLocationDialog &&
      <WarehouseLocationDialog 
      warehouseId={warehouseLocationChangeId}
      open={openWarehouseLocationDialog}
      onClose={onCloseWarehouseLocationDialog}
      />

      }
    </Grid>
  );
};

WarehouseAdminWarehousesTab.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

WarehouseAdminWarehousesTab.defaultProps = {};

export default WarehouseAdminWarehousesTab;
