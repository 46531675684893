import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React from 'react';

const readOnlyStyle = (isReadOnly) => {

    if (isReadOnly) {
        return {
            backgroundColor: "#ededed",
            paddingBottom: "0 !important",
            paddingTop: "0 !important"
        }
    }
    return {}
}

const StyledCellEditableTableBody = React.memo(styled(TableCell)((props) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: "bold",
        fontSize: "0.92rem",
        lineHeight: 1,
    },
    border: "1px solid rgba(224, 224, 224, 1)",
    whiteSpace: "nowrap",
    textAlign: "center",
    padding: 0,
    ...readOnlyStyle(props.readOnly),
    ...props.style
})));

export default StyledCellEditableTableBody;