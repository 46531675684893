import i18n from "../../../i18n";
import DateService from "../../../services/dateService";
import OwnerLogsService from "../../../services/ownerLogsService";

export const OWNER_LOGS_TABLE_FIELDS_CONFIG = [
  {
    name: "created",
    getValueCallback: (rowData) => DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.owner_logs_table.changes_date"),
  },
  {
    name: "data_type",
    getValueCallback: (rowData) => OwnerLogsService.convertDataTypeToValue(rowData.data_type),
    label: i18n.t("table.owner_logs_table.data_type"),
  },
  {
    name: "ownlog_field",
    getValueCallback: (rowData) =>  i18n.t(`table.owner_logs_table.${rowData.ownlog_field}`),
    label: i18n.t("table.owner_logs_table.ownlog_field"),
  },
  {
    name: "ownlog_old_value",
    getValueCallback: (rowData) => rowData.ownlog_old_value,
    label: i18n.t("table.owner_logs_table.ownlog_old_value"),
  },
  {
    name: "ownlog_new_value",
    getValueCallback: (rowData) => rowData.ownlog_new_value,
    label: i18n.t("table.owner_logs_table.ownlog_new_value"),
  },
  {
    name: "user",
    getValueCallback: (rowData) => rowData.user,
    label: i18n.t("table.owner_logs_table.user"),
  },
];
