import { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";

import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";

import useUserService from "../../../services/userService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";

import LoaderWrapper from "../../../components/wrapper/LoaderWrapper";
import AutocompleteField from "../../../components/field/AutocompleteField";

import {
  getFieldValueFromSearchParams,
  getFilterFieldKeyByNameAndPrefix,
  getObjectFromSearchParams,
} from "../../../helpers/methods";

import DateService from "../../../services/dateService";
import DateField from "../../../components/field/DateField/DateField";

import RRTenantForm from "./RRTenantForm";

import useLocalStorage from "../../../hooks/useLocalStorage";

export default function RRPanelToolBar(props) {
  const { t } = useTranslation();

  const [localStorageSearchParams, setLocalStorageSearchParams] =
    useLocalStorage("rr-panel-tool-bar");

  const { getTenantList, getTenantRRDetails } = useUserService();

  const tenantList = useAsync(() => {
    let tempSearchParams = { tenant_active: true };
    if (props.onlyTenantRRVisibility) {
      tempSearchParams = { tempSearchParams, tenant_rr_visibility: true };
    }
    return getTenantList(tempSearchParams);
  }, []);

  const getTenantDetailsFn = useAsyncFn(getTenantRRDetails);

  const [searchParams, setSearchParams] = useSearchParams();

  const tenantValue = useMemo(() => {
    if (!props.filterPrefixes) {
      return getFieldValueFromSearchParams(
        searchParams,
        getFilterFieldKeyByNameAndPrefix("tenant", undefined)
      );
    }

    let tenant = null;
    for (const filterPrefix of props.filterPrefixes) {
      tenant =
        tenant ||
        getFieldValueFromSearchParams(
          searchParams,
          getFilterFieldKeyByNameAndPrefix("tenant", filterPrefix)
        );
    }
    return tenant;
  }, [searchParams, props.filterPrefixes]);

  const [selectedTenantData, setSelectedTenantData] = useState(null);
  useEffect(() => {
    if (!tenantValue || Array.isArray(tenantValue)) setSelectedTenantData(null);
    else {
      getTenantDetailsFn
        .execute(tenantValue)
        .then((res) => setSelectedTenantData(res));
    }

    setLocalStorageSearchParams((prevLocalStorageParams) => ({
      ...prevLocalStorageParams,
      tenant: tenantValue,
    }));
  }, [tenantValue]);

  const startSettlementRangeValue = useMemo(() => {
    if (!props.filterPrefixes) {
      return getFieldValueFromSearchParams(
        searchParams,
        getFilterFieldKeyByNameAndPrefix("start_settlement_range", undefined)
      );
    }

    let startSettlementRange = null;
    for (const filterPrefix of props.filterPrefixes) {
      startSettlementRange =
        startSettlementRange ||
        getFieldValueFromSearchParams(
          searchParams,
          getFilterFieldKeyByNameAndPrefix(
            "start_settlement_range",
            filterPrefix
          )
        );
    }
    return startSettlementRange;
  }, [searchParams, props.filterPrefixes]);

  useEffect(() => {
    setLocalStorageSearchParams((prevLocalStorageParams) => ({
      ...prevLocalStorageParams,
      start_settlement_range: startSettlementRangeValue,
    }));
  }, [startSettlementRangeValue]);

  const endSettlementRangeValue = useMemo(() => {
    if (!props.filterPrefixes) {
      return getFieldValueFromSearchParams(
        searchParams,
        getFilterFieldKeyByNameAndPrefix("end_settlement_range", undefined)
      );
    }

    let endSettlementRange = null;
    for (const filterPrefix of props.filterPrefixes) {
      endSettlementRange =
        endSettlementRange ||
        getFieldValueFromSearchParams(
          searchParams,
          getFilterFieldKeyByNameAndPrefix("end_settlement_range", filterPrefix)
        );
    }
    return endSettlementRange;
  }, [searchParams, props.filterPrefixes]);

  useEffect(() => {
    setLocalStorageSearchParams((prevLocalStorageParams) => ({
      ...prevLocalStorageParams,
      end_settlement_range: endSettlementRangeValue,
    }));
  }, [endSettlementRangeValue]);

  const handleChangeTenant = (e, value_object, value_key, state_value_name) => {
    let newValue = Array.isArray(value_object)
      ? value_object.map((option) =>
          typeof option === "object" ? option[value_key] : option
        )
      : value_object[value_key];
    if (newValue === undefined) {
      newValue = null;
    }

    setSearchParams((prevSearchParams) => {
      let tempSearchParams = { ...getObjectFromSearchParams(prevSearchParams) };
      if (!props.filterPrefixes) {
        tempSearchParams["tenant"] = newValue;
      } else {
        for (const filterPrefix of props.filterPrefixes) {
          tempSearchParams[
            getFilterFieldKeyByNameAndPrefix("tenant", filterPrefix)
          ] = newValue;
        }
      }
      return tempSearchParams;
    });
  };

  const handleChangeStartSettlementRange = (e) => {
    const newValue = DateService.getISOStringZeroHourOfDate(e.target.value);
    setSearchParams((prevSearchParams) => {
      let tempSearchParams = { ...getObjectFromSearchParams(prevSearchParams) };
      if (!props.filterPrefixes) {
        tempSearchParams["start_settlement_range"] = newValue;
      } else {
        for (const filterPrefix of props.filterPrefixes) {
          tempSearchParams[
            getFilterFieldKeyByNameAndPrefix(
              "start_settlement_range",
              filterPrefix
            )
          ] = newValue;
        }
      }

      return tempSearchParams;
    });
  };

  const handleChangeEndSettlementRange = (e) => {
    const newValue = DateService.getISOStringleEndHourOfDate(e.target.value);
    setSearchParams((prevSearchParams) => {
      let tempSearchParams = { ...getObjectFromSearchParams(prevSearchParams) };
      if (!props.filterPrefixes) {
        tempSearchParams["end_settlement_range"] = newValue;
      } else {
        for (const filterPrefix of props.filterPrefixes) {
          tempSearchParams[
            getFilterFieldKeyByNameAndPrefix(
              "end_settlement_range",
              filterPrefix
            )
          ] = newValue;
        }
      }
      return tempSearchParams;
    });
  };

  return (
    <LoaderWrapper showLoader={tenantList.loading}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        columnSpacing={3}
        rowSpacing={2}
        marginY={1}
      >
        <Grid item xs={12} md={2.5}>
          <AutocompleteField
            multiple={true}
            optionValueKey={"id"}
            optionLabelKey={"tenant_short_name"}
            name="tenant"
            value={tenantValue}
            options={tenantList.value}
            label={t("bar.rr_panel_tool_bar.tenants")}
            onChange={handleChangeTenant}
            required
          />
        </Grid>
        {selectedTenantData && (
          <Grid item xs={12} md={6.5}>
            <RRTenantForm tenantData={selectedTenantData} />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={3}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <DateField
            label={t("bar.rr_panel_tool_bar.start_settlement_date")}
            value={startSettlementRangeValue}
            onChange={handleChangeStartSettlementRange}
          />
          <div style={{ marginInline: "5px" }}>{"-"}</div>
          <DateField
            label={t("bar.rr_panel_tool_bar.end_settlement_date")}
            value={endSettlementRangeValue}
            onChange={handleChangeEndSettlementRange}
          />
        </Grid>
      </Grid>
    </LoaderWrapper>
  );
}

RRPanelToolBar.propTypes = {
  style: PropTypes.object,
  filterPrefixes: PropTypes.array,
};

RRPanelToolBar.defaultProps = {
  onlyTenantRRVisibility: false,
};
