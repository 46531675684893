import { useMemo, useState, useEffect } from "react";

import PropTypes from "prop-types";

import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { useAsync } from "../../hooks/useAsync";

import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";

import useFilterSearchParams from "../../hooks/useFilterSearchParams";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import useDialogWithId from "../../hooks/useDialogWithId";
import StdOrderAutomatDialog from "../../components/dialog/StdOrderAutomatDialog";
import StdTicketAutomatDialog from "../../components/dialog/StdTicketAutomatDialog";

import TableService from "../../services/tableService";
import useTicketService from "../../services/ticketService";
import useOrderService from "../../services/orderService";

import StdTicketOrderAutomatTable from "../../components/table/StdTicketOrderAutomatTable/";

import SelectStdTicketStdOrderTableButton from "../../components/button/SelectStdTicketStdOrderTableButton";

import {
  STD_AUTOMAT_TICKET_TABLE_NAME,
  STD_AUTOMAT_ORDER_TABLE_KT_NAME,
  STD_AUTOMAT_ORDER_TABLE_T_NAME,
  STD_AUTOMAT_TICKET_TABLE_PREFIX,
  STD_AUTOMAT_ORDER_TABLE_KT_PREFIX,
  STD_AUTOMAT_ORDER_TABLE_T_PREFIX,
} from "./TableConfig";

import {
  exportStdTicketAutomatAdminDataToFileUrl,
  exportStdOrderAutomatAdminDataToFileUrl,
} from "../../helpers/apiUrls";

const StdTicketOrderAutomatTableWithToolBar = (props) => {
  const { t } = useTranslation();

  const isSelectedTicketTable =
    props.tableName === STD_AUTOMAT_TICKET_TABLE_NAME;

  const {
    page,
    pageSize,
    setPageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(props.tableFilterPrefix);

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefixes,
  } = useFilterSearchParams(searchParams);

  const [adminSearchParams, setAdminSearchParams] = useState(
    getSearchParamsByFilterPrefix(props.tableFilterPrefix)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      props.tableFilterPrefix,
      adminSearchParams,
      setAdminSearchParams
    );
  }, [searchParams]);

  const {
    getStdTicketAutomatAdminData,
    getStdTicketAutomatAdminFilteringData,
  } = useTicketService();

  const { getStdOrderAutomatAdminData, getStdOrderAutomatAdminFilteringData } =
    useOrderService();

  const getAdminDataFnByTableName = (adminSearchParams) => {
    if (isSelectedTicketTable)
      return getStdTicketAutomatAdminData(adminSearchParams);

    return getStdOrderAutomatAdminData(adminSearchParams);
  };

  const getAdminFilteringDataFnByTableName = () => {
    if (isSelectedTicketTable) return getStdTicketAutomatAdminFilteringData();

    return getStdOrderAutomatAdminFilteringData({
      attach_skill_to_users: true,
    });
  };

  const adminData = useAsync(() => {
    return getAdminDataFnByTableName(adminSearchParams);
  }, [adminSearchParams, props.tableName]);

  const adminFilteringData = useAsync(
    () => getAdminFilteringDataFnByTableName(),
    [props.tableName]
  );

  const [adminDataLocaly, setAdminDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (adminData.loading) {
      return;
    }
    setAdminDataLocaly(adminData.value.results);
    setCountRecords(adminData.value.count);
  }, [adminData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setAdminDataLocaly(undefined);
    adminData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setAdminDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const exportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      props.tableConfig,
      hiddenColumnsForTables?.[props.tableName],
      columnsOrdersForTables?.[props.tableName]
    );
  }, [
    props.tableConfig,
    hiddenColumnsForTables?.[props.tableName],
    columnsOrdersForTables?.[props.tableName],
  ]);

  const [
    openStdTicketAutomatDialog,
    ticketCategoryId,
    onOpenStdTicketAutomatDialog,
    onCloseStdTicketAutomatDialog,
  ] = useDialogWithId();

  const [
    openStdOrderAutomatDialog,
    stdOrderId,
    onOpenStdOrderAutomatDialog,
    onCloseStdOrderAutomatDialog,
  ] = useDialogWithId();

  const handleUpdateAdminData = () => {
    setAdminDataLocaly(undefined);
    adminData.refetch();
  };

  const handleChangeSelectedTable = (filterValue) => {
    setAdminDataLocaly(undefined);
    setAdminSearchParams({});
    setHiddenColumnsForTables(undefined);
    setColumnsOrdersForTables(undefined);
    props.setSelectedTable(filterValue);
    setPageSize(10);
    setSearchParams({
      ...clearSearchParamsByFilterPrefixes([
        STD_AUTOMAT_TICKET_TABLE_PREFIX,
        STD_AUTOMAT_ORDER_TABLE_KT_PREFIX,
        STD_AUTOMAT_ORDER_TABLE_T_PREFIX,
      ]),
    });
  };

  const handleChangeStdOrderTable = (e, idx) => {
    const selectedSubTable =
      idx === 0
        ? STD_AUTOMAT_ORDER_TABLE_KT_NAME
        : STD_AUTOMAT_ORDER_TABLE_T_NAME;
    handleChangeSelectedTable(selectedSubTable);
  };

  const getExtraButtonListForToolBar = () => {
    return [
      <SelectStdTicketStdOrderTableButton
        onChangeFilterValue={handleChangeSelectedTable}
        stdStdTicketKey={STD_AUTOMAT_TICKET_TABLE_NAME}
        stdStdTicketLabel={t(
          "page.admin_std_tickets_orders_automat.std_ticket_automat"
        )}
        stdOrderKey={
          isSelectedTicketTable
            ? STD_AUTOMAT_ORDER_TABLE_KT_NAME
            : props.tableName
        }
        stdStdOrderLabel={t(
          "page.admin_std_tickets_orders_automat.std_order_automat"
        )}
        selectedFilterKey={props.tableName}
      />,
    ];
  };

  const isLoading = adminDataLocaly === undefined || adminFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={1}
      marginTop={1}
    >
      <Grid item xs={12}>
        <UniversalToolBarWithDialogs
          pageName={props.pageName}
          showMyFiltersButton={false}
          showOpenCloseFilterButton={false}
          tableConfig={props.tableConfigForUserPreferencesDialog}
          filteringData={adminFilteringData}
          refreshTable={refreshTable}
          onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
          resetPageNumber={resetPageNumber}
          hiddenColumnsForTables={hiddenColumnsForTables}
          setHiddenColumnsForTables={setHiddenColumnsForTables}
          columnsOrdersForTables={columnsOrdersForTables}
          setColumnsOrdersForTables={setColumnsOrdersForTables}
          filterPrefix={props.tableFilterPrefix}
          extraButtonList={getExtraButtonListForToolBar()}
          mdGridExtraButton={2.25}
        />
      </Grid>

      {!isSelectedTicketTable && (
        <Tabs
          value={props.tableName === STD_AUTOMAT_ORDER_TABLE_KT_NAME ? 0 : 1}
          onChange={handleChangeStdOrderTable}
        >
          <Tab
            label={t("page.admin_std_tickets_orders_automat.kt")}
            id={`id-${0}`}
          />
          <Tab
            label={t("page.admin_std_tickets_orders_automat.t")}
            id={`id-${1}`}
          />
        </Tabs>
      )}
      {hiddenColumnsForTables && (
        <Grid item xs={12}>
          <StdTicketOrderAutomatTable
            data={adminDataLocaly}
            countRecords={countRecords}
            page={page}
            onPageChange={handleChangePageWithSearchParams}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            resetPageNumber={resetPageNumber}
            tableConfig={props.tableConfig}
            filteringData={adminFilteringData}
            hiddenColumns={hiddenColumnsForTables?.[props.tableName]}
            columnsOrders={columnsOrdersForTables?.[props.tableName]}
            onClickEdit={
              isSelectedTicketTable
                ? onOpenStdTicketAutomatDialog
                : onOpenStdOrderAutomatDialog
            }
            style={{ maxHeight: isSelectedTicketTable ? "80vh" : "65vh" }}
            showExportToFileButton={true}
            exportToFileUrl={
              isSelectedTicketTable
                ? exportStdTicketAutomatAdminDataToFileUrl
                : exportStdOrderAutomatAdminDataToFileUrl
            }
            exportToFileSearchParams={adminSearchParams}
            exportToFileHeaders={exportHeaders}
            exportToFileFileName={
              isSelectedTicketTable
                ? "std_tickets_automat.xlsx"
                : "std_orders_automat.xlsx"
            }
            filterPrefix={props.tableFilterPrefix}
          />
        </Grid>
      )}
      {openStdTicketAutomatDialog && (
        <StdTicketAutomatDialog
          open={openStdTicketAutomatDialog}
          onClose={onCloseStdTicketAutomatDialog}
          onSubmitCallback={handleUpdateAdminData}
          ticketCategoryId={ticketCategoryId}
        />
      )}
      {openStdOrderAutomatDialog && (
        <StdOrderAutomatDialog
          open={openStdOrderAutomatDialog}
          onClose={onCloseStdOrderAutomatDialog}
          onSubmitCallback={handleUpdateAdminData}
          stdOrderId={stdOrderId}
        />
      )}
    </Grid>
  );
};

StdTicketOrderAutomatTableWithToolBar.propTypes = {
  pageName: PropTypes.string,
  tableName: PropTypes.string,
  tableConfig: PropTypes.object,
  tableFilterPrefix: PropTypes.string,
  tableConfigForUserPreferencesDialog: PropTypes.array,
  setSelectedTable: PropTypes.func,
};

StdTicketOrderAutomatTableWithToolBar.defaultProps = {};

export default StdTicketOrderAutomatTableWithToolBar;
