import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useTranslation } from "react-i18next";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";

const MailForm = ({
  mail,
  index,
  onEditMail,
  onRemoveMail,
  readOnly,
  subcontractorId }
) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={readOnly ? 12 : 10.8} onClick={() => onEditMail(mail?.mail_id)}>
        <TextFieldFormControl
          value={mail?.mail_alias}
          label={t("other.mail_form_box.mail_alias")}
          readOnly = {readOnly}
        />
      </Grid>
      {!readOnly &&
        <Grid item xs={1}>
          <IconButtonWithTooltip
            onClick={(e) => {
              e.stopPropagation();
              onRemoveMail(subcontractorId ? mail.subcontractormail_id: mail.ownermail_id, index)
            }}
            title={t("other.mail_form_box.remove_mail")}
          >
            <RemoveCircleIcon color="secondary" />
          </IconButtonWithTooltip>
        </Grid>
      }
    </>
  );
};

MailForm.propTypes = {
  mail: PropTypes.object,
  onMailChange: PropTypes.func,
  onMailRemove: PropTypes.func,
  readOnly: PropTypes.bool
};

MailForm.defaultProps = {
  readOnly : false
};

export default MailForm;
