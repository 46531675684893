import { useTranslation } from "react-i18next";

import TabsNavigationBar from "../../TabsNavigationBar";

function DunReportsNavigationBuidlingSubBar(props) {
  const { t } = useTranslation();
  return (
    <TabsNavigationBar
      pages={[
        {
          label: t("headers.feedback_notes"),
          link: "/tickets_per_feedback_report/",
        },
        {
          label: t("headers.nps_per_building"),
          link: "/tickets_per_nps_report/",
        },
      ]}
    />
  );
}

DunReportsNavigationBuidlingSubBar.propTypes = {};

export default DunReportsNavigationBuidlingSubBar;
