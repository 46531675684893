import { useState, useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { Grid, Button, Typography } from "@mui/material";

import {
  ENCLOUSURE_TABLE_NAME,
  ENCLOUSURE_TABLE_FILTER_PREFIX,
  ENCLOUSURE_TABLE_FIELDS_CONFIG,
} from "./PageTablesConfig";

import EnclosureTable from "../../components/table/EnclosureTable";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../hooks/useFilterSearchParams";

import useFileService from "../../services/fileService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";

import { useSnackbarAlert } from "../../context/snackbarAlert";

import DeleteIcon from "@mui/icons-material/Delete";

import { overflowButtonStyle } from "../../helpers/styles";

export default function EnclosureTableWithToolBar(props) {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [enclousureDataLocaly, setEnclousureDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();

  const {
    page: enclousureDataPage,
    pageSize: enclousureDataPageSize,
    handleChangePageWithSearchParams: handleChangeEnclousureDataPage,
    handleChangePageSizeWithSearchParams: handleChangeEnclousureDataPageSize,
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams(ENCLOUSURE_TABLE_FILTER_PREFIX);

  const { setNewItemSearchParamsIfAreChanged, getSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);
  const [enclosureSearchParams, setEnclosureSearchParams] = useState(
    getSearchParamsByFilterPrefix(ENCLOUSURE_TABLE_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      ENCLOUSURE_TABLE_FILTER_PREFIX,
      enclosureSearchParams,
      setEnclosureSearchParams
    );
  }, [searchParams]);

  const {
    getEnclosureFavoriteForUser,
    deleteEnclosureFromFavorite,
    getEnclousureFilteringData,
    handleDownloadEnclosure,
    handlePreviewEnclosure,
  } = useFileService();

  const enclousureData = useAsync(
    () => getEnclosureFavoriteForUser(enclosureSearchParams),
    [enclosureSearchParams]
  );

  const enclousureFilteringData = useAsync(getEnclousureFilteringData);

  useEffect(() => {
    if (enclousureData.loading) {
      return;
    }
    setEnclousureDataLocaly(enclousureData.value);
    setCountRecords(enclousureData.value.length);
  }, [enclousureData.loading]);

  const handleClickCheck = useCallback((id) => {
    setEnclousureDataLocaly((prevData) => {
      let selectedRow = { ...prevData.find((rowData) => rowData.id === id) };
      selectedRow.selected = !selectedRow.selected;
      return prevData.map((rowData) => {
        if (rowData.id === id) return selectedRow;
        return rowData;
      });
    });
  }, []);

  const [checkedAll, setCheckedAll] = useState(false);
  const handleClickCheckAll = useCallback(
    (value) => {
      let currValue = !checkedAll;
      setCheckedAll(currValue);

      setEnclousureDataLocaly((prev) => {
        return prev.map((rowData) => ({
          ...rowData,
          selected: currValue,
        }));
      });
    },
    [checkedAll]
  );

  const getSeletedEnclousureIds = () =>
    enclousureDataLocaly
      .filter((ticket) => ticket.selected)
      .map((ticket) => ticket.id);

  const deleteEnclosureFromFavoriteFn = useAsyncFn(deleteEnclosureFromFavorite);
  const handleDeleteEnclosureFromFavority = () => {
    let enclosureIds = getSeletedEnclousureIds();
    if (enclosureIds.length === 0) return;
    deleteEnclosureFromFavoriteFn
      .execute(enclosureIds)
      .then((res) => {
        setEnclousureDataLocaly(undefined);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.enclosure_from_favority_deleted")
        );
        enclousureData.refetch();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_deleting_enclosure_from_favority"
          )
        );
      });
  };

  const isLoading =
    enclousureFilteringData.loading ||
    enclousureDataLocaly === undefined ||
    countRecords === undefined;

  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12} md={3} lg={2} marginBottom={1}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          onClick={handleDeleteEnclosureFromFavority}
          startIcon={<DeleteIcon />}
          sx={overflowButtonStyle}
        >
          {t("page.my_documents.delete_from_favority")}
        </Button>
      </Grid>

      <Grid item xs={12} md={3} lg={2} marginBottom={1}>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          style={{ margin: 0, color: "var(--primary)" }}
        >
          {t("table.enclosure.table_name")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} lg={2} />
      <Grid item xs={12}>
        <LoaderWrapper showLoader={isLoading}>
          <EnclosureTable
            data={enclousureDataLocaly}
            showCheckbox={true}
            checkedAll={checkedAll}
            showCleanFilterIcon={false}
            onClickCheck={handleClickCheck}
            onClickCheckAll={handleClickCheckAll}
            countRecords={countRecords}
            page={enclousureDataPage}
            onPageChange={handleChangeEnclousureDataPage}
            rowsPerPage={enclousureDataPageSize}
            onRowsPerPageChange={handleChangeEnclousureDataPageSize}
            resetPageNumber={resetPageNumber}
            filteringData={enclousureFilteringData}
            style={{ maxHeight: "75vh" }}
            tableConfig={ENCLOUSURE_TABLE_FIELDS_CONFIG}
            onDownload={handleDownloadEnclosure}
            onPreview={handlePreviewEnclosure}
            filterPrefix={ENCLOUSURE_TABLE_FILTER_PREFIX}
          />
        </LoaderWrapper>
      </Grid>
    </Grid>
  );
}
