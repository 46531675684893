import React from "react";
import PropTypes from "prop-types";
import TableService from "../../../services/tableService";
import FilterTable from "../FilterTable";

export default function GuaranteeTable(props) {
  const data = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        props.tableConfig,
        props.data,
        props.hiddenColumns,
        props.columnsOrders,
        props.onClickMultipleLocationAlert,
        props.onClickEdit
      ),
    [props.data, props.hiddenColumns, props.columnsOrders]
  );

  return (
    <FilterTable
      checkedAll={props.checkedAll}
      onClickRow={props.onClickRow}
      onClickCheck={props.onClickCheck}
      onClickCheckAll={props.onClickCheckAll}
      data={data}
      filteringData={props.filteringData}
      countRecords={props.countRecords}
      page={props.page}
      onPageChange={props.onPageChange}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      rowsPerPageOptions={props.rowsPerPageOptions}
      resetPageNumber={props.resetPageNumber}
      headersConfig={TableService.getHeadersConfigForCollapsibleTable(
        props.tableConfig,
        props.filteringData,
        props.hiddenColumns,
        props.columnsOrders
      )}
      selectedItemId={props.selectedGuaranteeId}
      style={props.style}
      showCheckbox={props.showCheckbox}
      showTitle={props.showTitle}
      title={props.title}
      filterPrefix={props.filterPrefix}
      showFilters={props.showFilters}
      withPagination={props.withPagination}
      showCleanFilterIcon={props.showCleanFilterIcon}
      showContextMenu={props.showContextMenu}
      contextMenuActions={props.contextMenuActions}
    />
  );
}

GuaranteeTable.propTypes = {
  data: PropTypes.object,
  style: PropTypes.object,
  hiddenColumns: PropTypes.array,
  columnsOrders: PropTypes.array,
  showTitle: PropTypes.bool,
  title: PropTypes.string,
  filterPrefix: PropTypes.string,
  showCheckbox: PropTypes.bool,
  showFilters: PropTypes.bool,
  showCleanFilterIcon: PropTypes.bool,
  withPagination: PropTypes.bool,
  onClickMultipleLocationAlert: PropTypes.func,
  onClickEdit: PropTypes.func,
};

GuaranteeTable.defaultProps = {
  hiddenColumns: [],
  ticketData: [],
  showTitle: false,
  showCheckbox: false,
  showFilters: true,
  showCleanFilterIcon: true,
  withPagination: true,
};
