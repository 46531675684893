import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useTranslation } from "react-i18next";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";

const PhoneForm = ({
  index,
  phone,
  onEditPhone,
  onRemovePhone,
  readOnly,
  subcontractorId  }
) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={readOnly ? 12 : 10.8} onClick={() => onEditPhone(phone?.phone_id)}>
        <TextFieldFormControl
          value={phone?.phone_nr}
          label={phone?.phone_who}
          readOnly = {readOnly}
        />
      </Grid>
      {!readOnly &&
        <Grid item xs={1}>
          <IconButtonWithTooltip
            onClick={(e) => {
              e.stopPropagation();
              onRemovePhone(subcontractorId? phone.subcontractorphone_id: phone.ownerphone_id, index)
            }}
            title={t("other.phone_form_box.remove_phone")}
          >
            <RemoveCircleIcon color="secondary" />
          </IconButtonWithTooltip>
        </Grid>
      }
    </>
  );
};

PhoneForm.propTypes = {
  phone: PropTypes.object,
  onPhoneChange: PropTypes.func,
  onPhoneRemove: PropTypes.func,
  readOnly: PropTypes.bool
};

PhoneForm.defaultProps = {
  readOnly : false
};

export default PhoneForm;
