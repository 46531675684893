import React from "react";

import PropTypes from "prop-types";

import { Grid, Typography } from "@mui/material";

const FieldWithLabel = (props) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={0}
    >
      <Grid item xs={12} sm={props.labelSm}>
        <Typography
          variant="subtitle2"
          className="capitalize-first-letter"
          sx={{ textAlign: "left", marginLeft: "10px" }}
        >
          {props.required ? `${props.label}*` : props.label}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={props.fieldSm}>
        {props.field}
      </Grid>
    </Grid>
  );
};

FieldWithLabel.propTypes = {
  label: PropTypes.string,
  field: PropTypes.element,
  labelSm: PropTypes.number,
  fieldSm: PropTypes.number,
  required: PropTypes.bool,
};

FieldWithLabel.defaultProps = {
  labelSm: 4.5,
  fieldSm: 7.5,
  required: false,
};

export default FieldWithLabel;
