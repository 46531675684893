import { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import {
  SUBCONTRACTOR_TABLE_FIELDS_CONFIG,
  SUBCONTRACTOR_TABLE_NAME,
} from "./PageTablesConfig";
import OwnerTable from "../../components/table/OwnerTable";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import useUserService from "../../services/userService";
import { useEmptyArrayMemo } from "../../hooks/useEmptyArrayMemo";
import useDialog from "../../hooks/useDialog";
import SubcontractorDetailsDialog from "../../components/dialog/SubcontractorDetailsDialog";
import TableService from "../../services/tableService";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";
import UniversalFilterForm from "../../components/form/UniversalFilterForm";
import FilterDialog from "../../components/base/FilterDialog";
import DetailsDrawer from "../../components/drawer/UniversalDetailsDrawer/UniversalDetailsDrawer";
import useBasicDrawer from "../../hooks/useBasicDrawer";
import UserFilterDialog from "../../components/dialog/UserFilterDialog";
import { NAVIGATION_DRAWER_TYPE_SUBCONTRACTOR } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import { EDIT_SUBCONTRACTORS_PERMISSION } from "../../helpers/constants";
import useCheckPermission from "../../hooks/usePermission";
import SubcontractorManagmentToolBar from "../../components/bar/SubcontractorManagmentToolBar";

export default function SubcontractorManagementPage(props) { 
  const { pageName } = props;

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [subcontractorsLocal, setSubcontractorsLocal] = useState();
  const [subcontractorDataItem, setSubcontractorDataItem] = useState();
  const [countRecords, setCountRecords] = useState();
  const selectedSubcontractorIdRef = useRef();
  const [hasEditPermission] = useCheckPermission(EDIT_SUBCONTRACTORS_PERMISSION)

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();
  const [openDetailsDrawer, setOpenDetailsDrawer] = useBasicDrawer(false);

  const [
    openSubcontractorDetailsDialog,
    onOpenSubcontractorDetailsDialog,
    onCloseSubcontractorDetailsDialog,
  ] = useDialog();

  const [
    openUserFiltersDialog,
    onOpenUserFiltersDialog,
    onCloseUserFiltersDialog,
  ] = useDialog();

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const {
    page: subcontractorDataPage,
    pageSize: subcontractorDataPageSize,
    handleChangePageWithSearchParams: handleChangeSubcontractorsDataPage,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();



  const {
    getSubconstractorsList,
    getSubcontractorDetails,
    updateSubcontractorDetails
  } = useUserService();

  const subcontractorDetailsFn = useAsyncFn(getSubcontractorDetails);
  const updateSubcontractorDetailsFn = useAsyncFn(updateSubcontractorDetails);

  const subcontractorsData = useAsync(() => getSubconstractorsList(searchParams), [searchParams]);

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const onCleanFlitersInFilterDialog = () => {
    setSubcontractorsLocal(undefined);
    setCountRecords(undefined);
  };

  useEffect(() => {
    if (userPreferencesForPage.loading) {
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [SUBCONTRACTOR_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [SUBCONTRACTOR_TABLE_NAME],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);

  const { emptyArrayMemo } = useEmptyArrayMemo();

  useEffect(() => {
    if (subcontractorsData.loading) {
      return;
    }
    setSubcontractorsLocal(subcontractorsData.value.results);
    setCountRecords(subcontractorsData.value.count);
  }, [subcontractorsData.loading]);


  const handleOpenSubcontractorDetails = useCallback(
    (e, subcontractorId) => {
      e.stopPropagation();
      setOpenDetailsDrawer(false);
      setSubcontractorDataItem(undefined);
      selectedSubcontractorIdRef.current = subcontractorId;
      onOpenSubcontractorDetailsDialog();
    },
    [selectedSubcontractorIdRef.current]
  );

  const onCloseSubcontractorDialog = useCallback(() => {
    selectedSubcontractorIdRef.current = undefined;
    subcontractorsData.refetch();
    onCloseSubcontractorDetailsDialog();
    setOpenDetailsDrawer(false)
  }, [selectedSubcontractorIdRef.current]);

  const fullRefreshTable = () => {
    setSubcontractorsLocal(undefined);
    subcontractorsData.refetch();
  };

  const onRefetchDrawerData = () => {
    subcontractorDetailsFn.execute(selectedSubcontractorIdRef.current).then((data) => {
      setSubcontractorDataItem(data);
    });
  };

  const handleClickRow = (subcontractorId) => {
    if (!subcontractorId || subcontractorId === selectedSubcontractorIdRef.current) {
      selectedSubcontractorIdRef.current = null;
      setOpenDetailsDrawer(false);
      setSubcontractorDataItem(undefined);
    } else {
      setOpenDetailsDrawer(true);
      selectedSubcontractorIdRef.current = subcontractorId;
      onRefetchDrawerData();
    }
  };

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        fullRefreshTable();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const onUpdateSubcontractorDataPromise = useCallback(
    (data) => {
      return updateSubcontractorDetailsFn
        .execute(selectedSubcontractorIdRef.current, data)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.subcontractor_updated")
          );
          onRefetchDrawerData();
          fullRefreshTable();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_subcontractor_updating")
          );
          return Promise.reject(error);
        });
    },
    [selectedSubcontractorIdRef.current]
  );

  const isLoading = subcontractorsLocal === undefined || countRecords === undefined;

  return (
    <DetailsDrawer
      openDrawer={openDetailsDrawer}
      setOpenDrawer={setOpenDetailsDrawer}
      itemData={subcontractorDataItem}
      filteringData={emptyArrayMemo}
      itemType={"subcontractor"}
      isLoading={subcontractorDetailsFn.loading}
      onUpdateDataPromise={onUpdateSubcontractorDataPromise}
      readOnly={!hasEditPermission}
      onRefetchData={onRefetchDrawerData}
    >
      <NavigationDrawer
        drawerType={NAVIGATION_DRAWER_TYPE_SUBCONTRACTOR}
        pageName={pageName}
      >
        <DefaultPageWrapper titleKey={"subcontractors"}>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <SubcontractorManagmentToolBar
                style={{ marginBlock: "5px" }}
                onClickAdjustTable={onOpenTableColumnVisibilityDialog}
                onClickSearch={onOpenFilterDialog}
                onClickMyFilters={onOpenUserFiltersDialog}
                onClickCreateSubcontractor={onOpenSubcontractorDetailsDialog}
                openFilterKey={"subcontractor_active"}
                params={searchParams}
                hasPermission={hasEditPermission}
                subcontractorId={selectedSubcontractorIdRef.current}
              />
            </Grid>
            <Grid item xs={12}>
              <LoaderWrapper showLoader={isLoading}>
                <OwnerTable
                  data={subcontractorsLocal}
                  showCheckbox={false}
                  showCleanFilterIcon={true}
                  countRecords={countRecords}
                  page={subcontractorDataPage}
                  onClickRow={handleClickRow}
                  onPageChange={handleChangeSubcontractorsDataPage}
                  rowsPerPage={subcontractorDataPageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  selectedOwnerId={selectedSubcontractorIdRef.current}
                  filteringData={emptyArrayMemo}
                  hiddenColumns={
                    hiddenColumnsForTables
                      ? hiddenColumnsForTables[SUBCONTRACTOR_TABLE_NAME]
                      : emptyArrayMemo
                  }
                  columnsOrders={
                    columnsOrdersForTables
                      ? columnsOrdersForTables[SUBCONTRACTOR_TABLE_NAME]
                      : undefined
                  }
                  style={{ maxHeight: "vh" }}
                  tableConfig={SUBCONTRACTOR_TABLE_FIELDS_CONFIG}
                  showContextMenu={false}
                  contextMenuActions={emptyArrayMemo}
                  showDetailsIcon={false}
                  onClickEdit={handleOpenSubcontractorDetails}
                  hasPermission={hasEditPermission}
                />
              </LoaderWrapper>
            </Grid>
            {openTableColumnVisibilityDialog && (
              <TableColumnVisibilityDialog
                open={openTableColumnVisibilityDialog}
                onClose={onCloseTableColumnVisibilityDialog}
                onSubmit={handleUpdateUserPreferences}
                tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
                  [
                    {
                      name: SUBCONTRACTOR_TABLE_NAME,
                      config: SUBCONTRACTOR_TABLE_FIELDS_CONFIG,
                    },
                  ],
                  columnsOrdersForTables
                )}
                tablesHiddenColumns={hiddenColumnsForTables}
                isLoading={userPreferencesForPage.loading}
              />
            )}
            {openFilterDialog && (
              <FilterDialog
                open={openFilterDialog}
                onCleanFilters={onCleanFlitersInFilterDialog}
                onClose={onCloseFilterDialog}
                resetPageNumber={resetPageNumber}
                filterForm={
                  <UniversalFilterForm
                    filteringData={emptyArrayMemo}
                    filtersConfig={SUBCONTRACTOR_TABLE_FIELDS_CONFIG}
                    includeOpenCloseFilter={false}
                  />
                }
              />
            )}

            {openSubcontractorDetailsDialog && (
              <SubcontractorDetailsDialog
                open={openSubcontractorDetailsDialog}
                onClose={onCloseSubcontractorDialog}
                subcontractorId={selectedSubcontractorIdRef.current}
                readOnly={!hasEditPermission}
              />
            )}

            {openUserFiltersDialog && (
              <UserFilterDialog
                open={openUserFiltersDialog}
                onClose={onCloseUserFiltersDialog}
                pageName={pageName}
                filterForm={
                  <UniversalFilterForm
                    filteringData={emptyArrayMemo}
                    filtersConfig={SUBCONTRACTOR_TABLE_FIELDS_CONFIG}
                    includeOpenCloseFilter={false}
                  />
                }
              />
            )}
          </Grid>
        </DefaultPageWrapper>
      </NavigationDrawer>
    </DetailsDrawer>
  );
}
