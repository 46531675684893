import { Button, Grid } from "@mui/material"
import TextFieldFormControl from "../../field/TextFieldFormControl";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";


const ChangeTaskNameForm = ({buttonLabel, name, onChange, onSubmit}) => {
    const {t} = useTranslation();
    return(
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextFieldFormControl
                    value={name}
                    label={t('form.change_task_name.task_name')}
                    onChange={onChange}
                    name="task_name"
                />
            </Grid> 
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    size="small"
                    fullWidth
                    onClick={onSubmit}
                >
                    {buttonLabel ? buttonLabel : t('save')}

                </Button>
            </Grid>
        </Grid>
    )

}

ChangeTaskNameForm.propTypes = {
    buttonLabel: PropTypes.object,
    name: PropTypes.string,
    onChange: PropTypes.func
};

ChangeTaskNameForm.defaultProps = {
};


export default ChangeTaskNameForm