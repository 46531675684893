import PropTypes from "prop-types";

import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Badge } from "@mui/material";

function SearchButton(props) {
  return (
    <Badge
      badgeContent={props.searchBadgeContent}
      color="secondary"
      sx={{
        "& .MuiBadge-badge": {
          marginRight: "5px",
          marginTop: "5px",
        },
      }}
    >
      <IconButton
        style={{
          border: "3px solid var(--primary)",
          marginLeft: "auto",
        }}
        onClick={() => props.onClickSearch()}
      >
        <SearchIcon fontSize="small" sx={{ color: "var(--primary)" }} />
      </IconButton>
    </Badge>
  );
}

SearchButton.propTypes = {
  onClickSearch: PropTypes.func,
  searchBadgeContent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SearchButton.defaultProps = {
  onClickSearch: () => {},
};

export default SearchButton;
