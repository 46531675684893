import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useCallback, useState, useMemo } from "react";
import {  MAX_ALLOWED_ENCLOSURE_SIZE_IN_BYTES, MAX_FILENAME_LENGTH_CHARS } from "../../../helpers/constants";
import useFileService from "../../../services/fileService";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useForm } from "../../../hooks/useForm";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LoadingButton from "@mui/lab/LoadingButton";
import AddFileForm from "../../form/AddFileForm/AddFileForm";
import CertDataForm from "../../form/CertDataForm";
import useFieldValidation from "../../../hooks/useFieldValidation";
import useDialog from "../../../hooks/useDialog";
import useDocsService from "../../../services/docsService";
import { useAsync } from "../../../hooks/useAsync";
import CreateCertificateForm from "../../form/CreateCertificateForm/CreateCertificateForm";
import CertificateDetailsDialog from "../CertificateDetailsDialog";

function AddPartCertificationDialog(props) {

    const { t } = useTranslation();

    const snackbarAlert = useSnackbarAlert();

    const { getUnfillRequiredFields } = useFieldValidation();

    const { getCertificateSelectList } = useDocsService();
    const certificateSelectList = useAsync(getCertificateSelectList)

    const requiredFields  = useMemo(() => {
        return [
        "certificate",
        "certification_date",
        "certification_validation",
    ];
}, []);

    const {
        formValue,
        setFormValue,
        onChangeDate,
        onChangeAutocompleteFieldWithObjectOptions,
    } = useForm(props.certificate ? props.certificate : {});

    const [
        openCreateCertificateDialog,
        onOpenCreateCertificateDialog,
        onCloseCreateCertificateDialog,
      ] = useDialog();
    

    const [selectedFiles, setSelectedFiles] = useState([])

    const { showFileByBlobUrl } = useFileService();


    const setNotAddedNames = (namesArr) => {
        let formattedNames = ''
        for (let name of namesArr) {
            formattedNames += '\n' + name
        }
        return t("snackbar_alert.max_allowed_file_size_or_name_is_exceeded_files_are_not_added") + formattedNames
    }

    const onChooseFile = (e) => {
        let selectedFiles = e.target.files;
        let enclosures = []
        let notAddedEnclosuresNames = []
        if (selectedFiles) {
            for (let file of selectedFiles) {
                let file_data = { file: file, cert_name: file.name, content_type : file.type }

                if (file.size < MAX_ALLOWED_ENCLOSURE_SIZE_IN_BYTES && file.name.length < MAX_FILENAME_LENGTH_CHARS) {
                    file_data.blob_url = window.URL.createObjectURL(new Blob([file_data.file], { type: file_data.file.type }));
                    enclosures.push(file_data)
                } else {
                    notAddedEnclosuresNames.push(file.name)
                }
            }
            setSelectedFiles((enclosuresTemp) => {
                enclosuresTemp = [...enclosuresTemp, ...enclosures]
                return enclosuresTemp
            })
            if (notAddedEnclosuresNames.length > 0) {
                snackbarAlert.openWarningSnackbarAlert(
                    setNotAddedNames(notAddedEnclosuresNames)
                );
            }
        }
    }
    const onPreviewNewEnclosure = useCallback((enclosuerId, index) => {
        if (enclosuerId) {
            props.onPreview(enclosuerId)
        } else {
            let file_data = selectedFiles[index]
            showFileByBlobUrl(file_data.blob_url)
        }
    }, [selectedFiles])


    const onDeleteSelectedEnclosure = (index) => {
        setSelectedFiles((enclosuresTemp) => {
            enclosuresTemp = [...enclosuresTemp]
            enclosuresTemp.splice(index, 1)
            return enclosuresTemp
        })
    }

    const onSubmitButtonAction = useCallback(() => {
        if (props.certificate?.id){
            props.onSubmit(formValue)
        }else{
            props.onSubmit(selectedFiles, formValue)
        }
        setFormValue({})
        setSelectedFiles([])
    }, [selectedFiles, formValue])

    const isLoadingButtonDisabled= useMemo(() => {
        if (props.mode === "create"){
        return selectedFiles.length === 0 ||  getUnfillRequiredFields(requiredFields, formValue).length > 0;}
        return getUnfillRequiredFields(requiredFields, formValue).length > 0
      }, [selectedFiles, requiredFields, formValue, getUnfillRequiredFields]);
    
    

    return (
        <>
            <BasicDialog
                open={props.open}
                onClose={() => {
                    props.onClose()
                    setFormValue({})
                }}
                titleAlign="center"
                contentAlign="center"
                title={props.certificate ? t("dialog.add_new_part_cert_dialog.cert_details") : t("dialog.add_new_part_cert_dialog.add_cert") }
                maxWidth="sm"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={1}
                >
                    {props.mode === "create" &&
                    <Grid item xs={12}>
                        <AddFileForm
                            docs={selectedFiles}
                            onChooseFile={onChooseFile}
                            onDeleteEnclosure={onDeleteSelectedEnclosure}
                            onPreview={onPreviewNewEnclosure}
                            fileType={props.fileType}
                        />
                    </Grid>}

                    <Grid item xs={12}>
                        <CertDataForm
                            formValue={formValue}
                            onChangeDate={onChangeDate}
                            onChangeAutocompleteFieldWithObjectOptions={onChangeAutocompleteFieldWithObjectOptions}
                            certificateSelectList= {certificateSelectList}
                            onOpenCreateCertificate = {onOpenCreateCertificateDialog}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            disabled={isLoadingButtonDisabled}
                            variant="contained"
                            onClick={onSubmitButtonAction}
                            component="label"
                            loading = {props.docsLoading}
                            fullWidth
                            startIcon={<FileUploadIcon />}
                        >
                            {props.mode === "create" ? t("dialog.add_new_part_cert_dialog.save_file"): t("dialog.add_new_part_cert_dialog.save_changes")}
                        </LoadingButton>
                    </Grid>
                </Grid>
                
        {openCreateCertificateDialog &&
          <CertificateDetailsDialog
            open={openCreateCertificateDialog }
            onClose={onCloseCreateCertificateDialog}
            readOnly={false}
            onRefetchData={certificateSelectList.refetch}
          />
        }
            </BasicDialog>
        </>
    )

}

AddPartCertificationDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDownload: PropTypes.func,
    onPreview: PropTypes.func,
    mode : PropTypes.string
};
AddPartCertificationDialog.defaultProps = {
    open: false,
    mode: "create"
};

export default AddPartCertificationDialog