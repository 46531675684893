import { useTranslation } from "react-i18next";
import BasicDialog from "../../base/BasicDialog";
import ChangeTaskNameForm from "../../form/ChangeTaskNameForm";
import { useForm } from "../../../hooks/useForm";
import { useCallback } from "react";

const AllocateTaskDialog = ({
  open,
  onClose,
  order,
  onSubmit: onSubmitProps,
}) => {
  const { t } = useTranslation();

  const { formValue, onChange } = useForm({ task_name: order.order_name });

  const onSubmit = useCallback(() => {
    onSubmitProps(order, formValue);
  }, [onSubmitProps, order, formValue]);

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      titleAlign="center"
      contentAlign="center"
      title={t("dialog.allocate_task_dialog.allocate_task")}
      maxWidth="sm"
    >
      <ChangeTaskNameForm
        onChange={onChange}
        onSubmit={onSubmit}
        name={formValue.task_name}
        buttonLabel={t("dialog.allocate_task_dialog.allocate")}
      />
    </BasicDialog>
  );
};

export default AllocateTaskDialog;
