import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import AutocompleteField from "../../field/AutocompleteField";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";

import {
  MY_OLIVIA_LOGIN_SYSTEM,
  AAD_LOGIN_SYSTEM,
} from "../../../helpers/constants";

const UserTenantDetailsForm = (props) => {
  const { t } = useTranslation();

  const handleChangeUserExternalSystem = (e) => {
    props.onChange(e);

    props.onChange({
      target: {
        name: "user__user_external_identificator",
        value:
          e.target.value === AAD_LOGIN_SYSTEM
            ? props.formValue.user__mail
            : null,
      },
    });
  };

  const handleChangeUserMail = (e) => {
    props.onChange(e);
    if (props.formValue.user__user_external_system === AAD_LOGIN_SYSTEM) {
      props.onChange({
        target: {
          name: "user__user_external_identificator",
          value: e.target.value,
        },
      });
    }
  };

  const formContent = (
    <>
      {!props.hiddenFields.includes("user__user_external_system") && (
        <Grid item xs={12} md={6}>
          <AutocompleteField
            isObjectOption={false}
            multiple={false}
            name="user__user_external_system"
            value={props.formValue.user__user_external_system}
            options={props.filteringData.user__user_external_system}
            label={t("form.user_tenant.user__user_external_system")}
            onChange={handleChangeUserExternalSystem}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("user__user_external_system")
            }
            required={props.requiredFields.includes(
              "user__user_external_system"
            )}
            error={props.unfillRequiredFields.includes(
              "user__user_external_system"
            )}
            helperText={
              props.unfillRequiredFields.includes(
                "user__user_external_system"
              ) && t("field_required")
            }
          />
        </Grid>
      )}
      {!props.hiddenFields.includes("user__user_external_identificator") && (
        <Grid item xs={12} md={6}>
          <TextFieldFormControl
            name={"user__user_external_identificator"}
            onChange={props.onChange}
            value={props.formValue.user__user_external_identificator}
            label={t("form.user_tenant.user__user_external_identificator")}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes(
                "user__user_external_identificator"
              ) ||
              props.formValue.user__user_external_system === AAD_LOGIN_SYSTEM
            }
            required={props.requiredFields.includes(
              "user__user_external_identificator"
            )}
            isInvalid={props.unfillRequiredFields.includes(
              "user__user_external_identificator"
            )}
            helperText={
              props.unfillRequiredFields.includes(
                "user__user_external_identificator"
              ) && t("field_required")
            }
          />
        </Grid>
      )}
      {!props.hiddenFields.includes("user__first_name") && (
        <Grid item xs={12} md={6}>
          <TextFieldFormControl
            name={"user__first_name"}
            onChange={props.onChange}
            value={props.formValue.user__first_name}
            label={t("form.user_tenant.user__first_name")}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("user__first_name")
            }
            required={props.requiredFields.includes("user__first_name")}
            isInvalid={props.unfillRequiredFields.includes("user__first_name")}
            helperText={
              props.unfillRequiredFields.includes("user__first_name") &&
              t("field_required")
            }
          />
        </Grid>
      )}
      {!props.hiddenFields.includes("user__last_name") && (
        <Grid item xs={12} md={6}>
          <TextFieldFormControl
            name={"user__last_name"}
            onChange={props.onChange}
            value={props.formValue.user__last_name}
            label={t("form.user_tenant.user__last_name")}
            readOnly={
              props.readOnly || props.readOnlyFields.includes("user__last_name")
            }
            required={props.requiredFields.includes("user__last_name")}
            isInvalid={props.unfillRequiredFields.includes("user__last_name")}
            helperText={
              props.unfillRequiredFields.includes("user__last_name") &&
              t("field_required")
            }
          />
        </Grid>
      )}
      {!props.hiddenFields.includes("user__mail") && (
        <Grid item xs={12} md={6}>
          <TextFieldFormControl
            name={"user__mail"}
            onChange={handleChangeUserMail}
            value={props.formValue.user__mail}
            label={t("form.user_tenant.user__mail")}
            readOnly={
              props.readOnly || props.readOnlyFields.includes("user__mail")
            }
            required={props.requiredFields.includes("user__mail")}
            isInvalid={props.unfillRequiredFields.includes("user__mail")}
            helperText={
              props.unfillRequiredFields.includes("user__mail") &&
              t("field_required")
            }
          />
        </Grid>
      )}
      {!props.hiddenFields.includes("user__phone") && (
        <Grid item xs={12} md={6}>
          <TextFieldFormControl
            name={"user__phone"}
            onChange={props.onChange}
            value={props.formValue.user__phone}
            label={t("form.user_tenant.user__phone")}
            readOnly={
              props.readOnly || props.readOnlyFields.includes("user__phone")
            }
            required={props.requiredFields.includes("user__phone")}
            isInvalid={props.unfillRequiredFields.includes("user__phone")}
            helperText={
              props.unfillRequiredFields.includes("user__phone") &&
              t("field_required")
            }
          />
        </Grid>
      )}
      {!props.hiddenFields.includes("user__roles") && (
        <Grid item xs={12}>
          <AutocompleteField
            multiple={true}
            optionValueKey={"id"}
            optionLabelKey={"role_name"}
            name="user__roles"
            value={props.formValue.user__roles}
            options={props.filteringData.user__roles}
            label={t("form.user_tenant.user__roles")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            readOnly={
              props.readOnly || props.readOnlyFields.includes("user__roles")
            }
            required={props.requiredFields.includes("user__roles")}
            error={props.unfillRequiredFields.includes("user__roles")}
            helperText={
              props.unfillRequiredFields.includes("user__roles") &&
              t("field_required")
            }
          />
        </Grid>
      )}

      {!props.hiddenFields.includes("tenant") && (
        <Grid item xs={12} md={6}>
          <AutocompleteField
            multiple={false}
            optionValueKey={"id"}
            optionLabelKey={"tenant_short_name"}
            name="tenant"
            value={props.formValue.tenant}
            options={props.filteringData.tenant}
            label={t("form.user_tenant.tenant")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            readOnly={props.readOnly || props.readOnlyFields.includes("tenant")}
            required={props.requiredFields.includes("tenant")}
            error={props.unfillRequiredFields.includes("tenant")}
            helperText={
              props.unfillRequiredFields.includes("tenant") &&
              t("field_required")
            }
          />
        </Grid>
      )}
      {!props.hiddenFields.includes("user__object_id") && (
        <Grid item xs={12} md={6}>
          <AutocompleteField
            multiple={false}
            optionValueKey={"id"}
            optionLabelKey={"object_name_with_code"}
            name="user__object_id"
            value={props.formValue.user__object_id}
            label={t("form.user_tenant.user__object")}
            options={props.filteringData.objects_level_4}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            readOnly={
              props.readOnly || props.readOnlyFields.includes("user__object_id")
            }
            required={props.requiredFields.includes("user__object_id")}
            error={props.unfillRequiredFields.includes("user__object_id")}
            helperText={
              props.unfillRequiredFields.includes("user__object_id") &&
              t("field_required")
            }
          />
        </Grid>
      )}
      {!props.hiddenFields.includes("band") && (
        <Grid item xs={12} md={6}>
          <AutocompleteField
            multiple={false}
            optionValueKey={"id"}
            optionLabelKey={"band_name"}
            name="band"
            value={props.formValue.band}
            options={props.filteringData.band}
            label={t("form.user_tenant.band")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            readOnly={props.readOnly || props.readOnlyFields.includes("band")}
            required={props.requiredFields.includes("band")}
            error={props.unfillRequiredFields.includes("band")}
            helperText={
              props.unfillRequiredFields.includes("band") && t("field_required")
            }
          />
        </Grid>
      )}
      {!props.hiddenFields.includes("uste_band_tenant") && (
        <Grid item xs={12} md={6}>
          <TextFieldFormControl
            name={"uste_band_tenant"}
            onChange={props.onChange}
            value={props.formValue.uste_band_tenant}
            label={t("form.user_tenant.uste_band_tenant")}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("uste_band_tenant")
            }
            required={props.requiredFields.includes("uste_band_tenant")}
            isInvalid={props.unfillRequiredFields.includes("uste_band_tenant")}
            helperText={
              props.unfillRequiredFields.includes("uste_band_tenant") &&
              t("field_required")
            }
          />
        </Grid>
      )}
      {!props.hiddenFields.includes("uste_rr_visibility") && (
        <Grid item xs={6}>
          <TrueFalseSelectField
            name={"uste_rr_visibility"}
            label={t("form.user_tenant.uste_rr_visibility")}
            valuesAsBool
            value={props.formValue.uste_rr_visibility}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("uste_rr_visibility")
            }
            onChange={props.onChange}
            addEmptyOptions={false}
            required={props.requiredFields.includes("uste_rr_visibility")}
          />
        </Grid>
      )}
      {!props.hiddenFields.includes("responsibilities") && (
        <Grid item xs={12}>
          <AutocompleteField
            multiple={true}
            optionValueKey={"id"}
            optionLabelKey={"responsibility_name"}
            name="responsibilities"
            value={props.formValue.responsibilities}
            options={props.filteringData.responsibilities}
            label={t("form.user_tenant.responsibilities")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("responsibilities")
            }
            required={props.requiredFields.includes("responsibilities")}
            error={props.unfillRequiredFields.includes("responsibilities")}
            helperText={
              props.unfillRequiredFields.includes("responsibilities") &&
              t("field_required")
            }
          />
        </Grid>
      )}

      {!props.hiddenFields.includes("uste_note") && (
        <Grid item xs={12}>
          <TextFieldFormControl
            name={"uste_note"}
            onChange={props.onChange}
            value={props.formValue.uste_note}
            label={t("form.user_tenant.uste_note")}
            readOnly={
              props.readOnly || props.readOnlyFields.includes("uste_note")
            }
            resize={"vertical"}
            multiline
            rows={3}
            isInvalid={props.unfillRequiredFields.includes("uste_note")}
            helperText={
              props.unfillRequiredFields.includes("uste_note") &&
              t("field_required")
            }
          />
        </Grid>
      )}
    </>
  );

  if (props.onlyFormContent) return formContent;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={2}
      columnSpacing={1.5}
    >
      {formContent}
    </Grid>
  );
};

UserTenantDetailsForm.propTypes = {
  formValue: PropTypes.object,
  filteringData: PropTypes.object,
  readOnly: PropTypes.bool,
  readOnlyFieldss: PropTypes.array,
  hiddenFields: PropTypes.array,
  onChange: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  onlyFormContent: PropTypes.bool,
  unfillRequiredFields: PropTypes.array,
};

UserTenantDetailsForm.defaultProps = {
  readOnly: false,
  onlyFormContent: false,
  readOnlyFieldss: [],
  hiddenFields: [],
  unfillRequiredFields: PropTypes.array,
};

export default UserTenantDetailsForm;
