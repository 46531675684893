import SaftyReportsNavigationBar from "../../components/bar/SaftyReportsNavigationBar";

import PropTypes from "prop-types";

import TicketsReportBasePage from "../TicketsReportBasePage";

export default function TicketsSaftyReportsPage(props) {
  return (
    <TicketsReportBasePage
      pageName={props.pageName}
      pageTitleKey={props.pageTitleKey}
      groupBy={props.groupBy}
      labelBy={props.labelBy}
      showSelectTimeRange={props.showSelectTimeRange}
      showTicketOwner={props.showTicketOwner}
      showSelectObjectLevel2={props.showSelectObjectLevel2}
      NavigationBar={<SaftyReportsNavigationBar />}
      showNavigationBar={true}
      showChartInformationDialog={props.showChartInformationDialog}
      chartDescrtiption={props.chartDescrtiption}
    />
  );
}

TicketsSaftyReportsPage.propTypes = {
  labelBy: PropTypes.string,
  groupBy: PropTypes.string,
  showSelectTimeRange: PropTypes.bool,
  showTicketOwner: PropTypes.bool,
  showSelectObjectLevel2: PropTypes.bool,
  pageName: PropTypes.string,
  pageTitleKey: PropTypes.string,
  showChartInformationDialog: PropTypes.bool,
  chartDescrtiption: PropTypes.element,
};
