import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync } from "../../../hooks/useAsync";
import useWarehouseService from "../../../services/warehouseService";
import RackShellBox from "../../box/RackShellBox";


const WarehouseLocationDialog = (props) => {
  const { t } = useTranslation();

  const {
    getWarehouseLocationLists ,
  } = useWarehouseService();


  const warehouseLocationsList = useAsync(() => {
     if (props.warehouseId){
      return getWarehouseLocationLists ({'warehouse' : props.warehouseId});
    }
    return Promise.resolve([]);
  }, [props.warehouseId]);



  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={t(`dialog.warehouse_location_details_dialog.structure_details`) }
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        {warehouseLocationsList.loading  ? (
          <LoaderWrapper isLoading={true} />
        ) : (
          <>
            <Grid item xs={12}>
            < RackShellBox
              readOnly={true}
              warehouseLocations = {warehouseLocationsList.value || []}
              onAddWarLocation = {()=>undefined}
              onEditWarLocation = {()=>undefined}
              onRemoveWarLocation = {()=>undefined}
            />
            </Grid>

          </>
        )}
      </Grid>
    </BasicDialog>
  );
};

WarehouseLocationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  warehouseId : PropTypes.string,
};

WarehouseLocationDialog.defaultProps = {
  open: false,
};

export default WarehouseLocationDialog;
