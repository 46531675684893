import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import BasicDialog from "../../base/BasicDialog";
import CheckboxField from "../../field/CheckboxField";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import { useForm } from "../../../hooks/useForm";
import { isEmptyValue } from "../../../helpers/methods";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useCorrespondenceAdminService from "../../../services/correspondenceAdminService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import AutocompleteField from "../../field/AutocompleteField";

const CreateDepartmentDialog = (props) => {
  const { t } = useTranslation();
  const { formValue, setFormValue, onChange, onChangeAutocompleteFieldWithObjectOptions } = useForm({department_active:true});

  const { createDepartment, updateDepartmentData, getDepartmentDetails, getUserDepartmentSelectList} = useCorrespondenceAdminService();
  const createDepartmentFn = useAsyncFn(createDepartment);
  const updateDepartmentFn = useAsyncFn(updateDepartmentData);
  const snackbarAlert = useSnackbarAlert();
  const [emailError, setEmailError] = useState(false);
  
  const departmentDetailsData = useAsync(() => {
    if (props.departmentId) {
      return getDepartmentDetails(props.departmentId);
    }
    return Promise.resolve({});
  }, [props.departmentId]);

  const userDepartmentDetailsData = useAsync(() => {
    if (props.departmentId) {
      return getUserDepartmentSelectList({department_id:props.departmentId});
    }
    return Promise.resolve({});
  }, [props.departmentId]);

  useEffect(() => {
    if (!departmentDetailsData.loading && departmentDetailsData.value && props.departmentId) {
      setFormValue(departmentDetailsData.value);
    }
    if (props.departmentId && userDepartmentDetailsData.value) {
      const userIds = userDepartmentDetailsData.value.map(user => user.id);
      setFormValue(prevFormValue => ({
        ...prevFormValue,
        user_department: userIds
      }));
    }
  }, [props.departmentId, userDepartmentDetailsData.value, departmentDetailsData.loading]);


  const onSubmit = (departmentData) => {
    if (departmentData.id) {
      updateDepartmentFn.execute(departmentData.id, departmentData)
        .then(() => {
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.department_updated"));
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch(() => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.occurred_error_during_updating_department"));
        });
    } else {
      createDepartmentFn.execute(departmentData)
        .then(() => {
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.department_created"));
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch(() => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.occurred_error_during_creating_new_department"));
        });
    }
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      onSubmit(formValue);
    }
  };
  const onChangeMail = (e) => {
    const { name, value } = e.target;
    setFormValue((prev) => ({ ...prev, [name]: value }));

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(value) || value === "") {
      setEmailError(false); 
    } else {
      setEmailError(true); 
    }
  };
  const onChangeCheckbox = useCallback((e) => {
    const { name, checked } = e.target;
    setFormValue((prev) => ({ ...prev, [name]: checked }));
  }, []);

  const isFormValid = () => {
    return !isEmptyValue(formValue.department_name) && !emailError;
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      maxWidth="md"
      title={props.departmentId ? t("dialog.correspondence_admin_panel_dialogs.edit_department") : t("dialog.correspondence_admin_panel_dialogs.add_department")}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextFieldFormControl
            name="department_name"
            label={t("dialog.correspondence_admin_panel_dialogs.department_name")}
            value={formValue["department_name"]}
            onChange={onChange}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFormControl
            name="department_mail"
            label={t("dialog.correspondence_admin_panel_dialogs.department_mail")}
            value={formValue["department_mail"]}
            onChange={onChangeMail}
            fullWidth
            error={emailError}
            helperText={emailError ? t("form.mail_input_form.uncorrect_mail") : ""}
          />
        </Grid>
        <Grid item xs={3}>
          <CheckboxField
            name="department_active"
            label={t("dialog.correspondence_admin_panel_dialogs.active")}
            checked={formValue["department_active"]}
            onChange={onChangeCheckbox}
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteField
            multiple={true}
            optionValueKey={"id"}
            optionLabelKey={"full_name"}
            name="user_department"
            value={formValue["user_department"]}
            options={props.filteringData.value.users_list.filter(user => user.is_active)}
            label={t("tabs.correspondence_admin.choice_users")}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            readOnly={props.readOnly }
            required={props.requiredFields}
          />
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={!isFormValid()}
          >
            {props.departmentId ? t("save") : t("add")}
          </LoadingButton>
        </Grid>
        <Grid item xs={6}>
          <LoadingButton variant="outlined" color="secondary" fullWidth onClick={props.onClose}>
            {t("cancel")}
          </LoadingButton>
        </Grid>
      </Grid>
    </BasicDialog>
  );
};

CreateDepartmentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRefetchData: PropTypes.func,
  departmentId: PropTypes.string,
};

CreateDepartmentDialog.defaultProps = {
  departmentId: null,
  onRefetchData: null,
};

export default CreateDepartmentDialog;
