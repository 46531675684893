import {
  feedbackUrl,
  feedbackFilteringDataUrl,
  feedbackDetailsDataUrl,
  feedbackSlaDataUrl,
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { makeRequestWithLanguage } from "../helpers/makeRequestWithLanguage";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { prepareUrlWithQueryParams } from "../helpers/methods";

export default function useFeedbackService() {
  const axiosPrivate = useAxiosPrivate();

  const sendFeedback = (dataToSend) => {
    return makeRequest(axiosPrivate, feedbackUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const sendFeedbackNote = (feedbackId, dataToSend) => {
    return makeRequest(axiosPrivate, feedbackDetailsDataUrl(feedbackId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getFeedbackData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(feedbackUrl, searchParams)
    );
  };

  const getFeedbackDetailsData = (feedbackId) => {
    return makeRequest(axiosPrivate, feedbackDetailsDataUrl(feedbackId));
  };

  const getFeedbackFilteringData = () => {
    return makeRequestWithLanguage(axiosPrivate, feedbackFilteringDataUrl);
  };

  const getFeedbackSlaData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(feedbackSlaDataUrl, searchParams)
    );
  };

  return {
    sendFeedback,
    sendFeedbackNote,
    getFeedbackData,
    getFeedbackDetailsData,
    getFeedbackFilteringData,
    getFeedbackSlaData,
  };
}
