import { useState } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import BasicDialog from "../../base/BasicDialog";

import UserEmailNotificationsService from "../../../services/userEmailNotificationsService";

import useUserService from "../../../services/userService";
import { useAsyncFn } from "../../../hooks/useAsync";

import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { getErrorMsg } from "../../../helpers/methods";

const UserMailNotificationsDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [formValue, setFormValue] = useState(
    () => props.excludedEmailNotifications
  );

  const handleChangeExludedNotifications = (notificationType) => {
    setFormValue((prev) => {
      let excludedNotifications = [...prev];
      if (excludedNotifications.includes(notificationType)) {
        excludedNotifications = excludedNotifications.filter(
          (nt) => nt !== notificationType
        );
      } else {
        excludedNotifications.push(notificationType);
      }

      return excludedNotifications;
    });
  };

  const { updateUserEmailNotifications } = useUserService();
  const updateUserEmailNotificationsFn = useAsyncFn(
    updateUserEmailNotifications
  );

  const prepareDataToSend = () => ({
    user_ids: props.userIds,
    excluded_email_notifications: formValue,
  });

  const handleSubmit = () => {
    updateUserEmailNotificationsFn
      .execute(prepareDataToSend())
      .then((data) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.email_notifications_updated")
        );
        if (props.onSubmit) {
          props.onSubmit();
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error,
            t(
              "snackbar_alert.occurred_error_during_email_notifications_updating"
            )
          )
        );
      });
  };

  const isLoading = updateUserEmailNotificationsFn.loading;

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="left"
      title={t("dialog.user_email_notifications.edit_notifications")}
      maxWidth={"sm"}
      margin={0}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
        columnSpacing={0}
        margin={0}
        columns={12}
      >
        {UserEmailNotificationsService.getEmailNotificationsNameLabelPairsForOMs().map(
          (notification) => (
            <Grid item xs={12} key={notification.name}>
              <FormControlLabel
                sx={{ fontFamily: "Roboto", textTransform: "capitalize" }}
                control={
                  <Switch
                    checked={!formValue.includes(notification.name)}
                    onChange={() =>
                      handleChangeExludedNotifications(notification.name)
                    }
                  />
                }
                label={notification.label}
              />
            </Grid>
          )
        )}
        <Grid
          item
          container
          xs={12}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          columnSpacing={1}
          margin={0}
          columns={12}
        >
          <Grid item xs={12} sm={12 / 2}>
            <LoadingButton
              variant="contained"
              size="small"
              fullWidth
              onClick={handleSubmit}
              loading={isLoading}
            >
              {t("confirm")}
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sm={12 / 2}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              onClick={props.onClose}
              disabled={isLoading}
            >
              {t("cancel")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </BasicDialog>
  );
};

UserMailNotificationsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  emailNofications: PropTypes.array,
  excludedEmailNotifications: PropTypes.array,
  userIds: PropTypes.array,
};

UserMailNotificationsDialog.defaultProps = {
  open: false,
  excludedEmailNotifications: [],
  userIds: [],
};

export default UserMailNotificationsDialog;
