import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import BasicDialog from "../../base/BasicDialog";
import useUserService from "../../../services/userService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import SubcontractorForm from "../../form/SubcontractorForm";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { Grid } from "@mui/material";
import { getErrorMsg, isEmptyObject, isEmptyValue } from "../../../helpers/methods";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect } from "react";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import useOwnerFormsService from "../../../hooks/useOwnerFormsService";
import AddressFormBox from "../../box/AddressFormBox";
import AddressDialog from "../AddressDialog/AddressDialog";
import PhoneDialog from "../PhoneDialog/PhoneDialog";
import PhoneFormBox from "../../box/PhoneFormBox/PhoneFormBox";
import MailDialog from "../MailDialog";
import MailFormBox from "../../box/MailFormBox/MailFormBox";
import UserFormBox from "../../box/UserFormBox";
import SelectUserDialog from "../SelectUserDialog/SelectUserDialog";

const SubcontractorDetailsDialog = (props) => {
  const { t } = useTranslation();

  const { createNewSubcontractor,
    getSubcontractorDetails,
    updateSubcontractorDetails,
    createUserSubcontractor
  } = useUserService();

  const createNewSubcontractorFn = useAsyncFn(createNewSubcontractor);
  const updateSubcontractorFn = useAsyncFn(updateSubcontractorDetails);
  const createUserSubcontractorFn = useAsyncFn(createUserSubcontractor);

  const snackbarAlert = useSnackbarAlert();

  const { formValue, setFormValue, onChange } = useForm({ subcontractor_active: true, subcontractor_is_contractor: true });


  const subcontractorDetailsData = useAsync(
    () => getSubcontractorDetails(props.subcontractorId),
    [props.subcontractorId]
  );

  const {
    openAddressDialog,
    onOpenAddressDialog,
    onAddAddresForNewOwner,
    onEditAddress,
    onRemoveAddress,
    onCloseAddressDialog,

    openMailDialog,
    onOpenMailDialog,
    onAddMailForNewOwner,
    onEditMail,
    onRemoveMail,
    onCloseMailDialog,

    openPhoneDialog,
    onOpenPhoneDialog,
    onAddPhoneForNewOwner,
    onEditPhone,
    onRemovePhone,
    onClosePhoneDialog,

    openUserDialog,
    onOpenUserDialog,
    onAddUserForNewInstance,
    onRemoveUser,
    onCloseUserDialog,

    selectedAddressId,
    selectedMailId,
    selectedPhoneId } =
    useOwnerFormsService(subcontractorDetailsData.refetch,
      formValue,
      setFormValue,
      undefined,
      props.subcontractorId)



  useEffect(() => {
    if (subcontractorDetailsData.loading) {
      return;
    }
    if (!isEmptyObject(subcontractorDetailsData.value)) {
      setFormValue(subcontractorDetailsData.value);
    }

  }, [subcontractorDetailsData.loading]);


  const isReadOnly = props.readOnly || formValue?.subcontractor_active === false

  const requiredFields = [
    "subcontractor_full_name",
    "subcontractor_short_name",
    "subcontractor_nip",
  ];

  const isFormValid = () => {
    return requiredFields.every(
      (fieldName) => !isEmptyValue(formValue[fieldName])
    );
  };

  const onSubmit = (subcontractorData) => { 
    if (props.subcontractorId) {
      updateSubcontractorFn
        .execute(props.subcontractorId, subcontractorData)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.subcontractor_updated")
          );
          props.onClose();
        })
        .catch((err) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(err.data),
            t("snackbar_alert.occurred_error_during_subcontractor_updating")
          );
        });
    } else {
      createNewSubcontractorFn
        .execute(subcontractorData)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.subcontractor_created")
          );
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch((err) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(err.data),
            t("snackbar_alert.occurred_error_during_creating_new_subcontractor")
          );
        });
    }
  };

  const onAddNewSubcontractorUser = useCallback(((user) => {
    if (props.subcontractorId){
      createUserSubcontractorFn
        .execute({'user' : user.id, 'subcontractor' : props.subcontractorId})
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.subcontractor_updated")
          );
          subcontractorDetailsData.refetch()
        })
        .catch((err) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(err.data),
            t("snackbar_alert.occurred_error_during_subcontractor_updating")
          );
        });
    }else{
      onAddUserForNewInstance(user)
    }
    onCloseUserDialog()
  }), [props.subcontractorId, formValue])



  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={
        props.subcontractorId
          ? t(`dialog.subcontractor_dialog.subcontractor_details`)
          : t(`dialog.subcontractor_dialog.add_subcontractor`)
      }
      maxWidth="md"
      showDialogActions
    >
      <LoaderWrapper showLoader={subcontractorDetailsData.loading} >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12}>
            <SubcontractorForm
              readOnly={isReadOnly}
              onChange={onChange}
              formValue={formValue}
              subcontractorId={props.subcontractorId}
            />
          </Grid>
          <Grid item xs={6}>
            <AddressFormBox
              addresses={formValue?.addresses}
              onEditAddress={onEditAddress}
              onAddAddress={onOpenAddressDialog}
              onRemoveAddress={onRemoveAddress}
              readOnly={isReadOnly}
              subcontractorId={props.subcontractorId}
            />
          </Grid>
          <Grid item xs={6}>
            <PhoneFormBox
              phones={formValue?.phones}
              onEditPhone={onEditPhone}
              onAddPhone={onOpenPhoneDialog}
              onRemovePhone={onRemovePhone}
              readOnly={isReadOnly}
              subcontractorId={props.subcontractorId}
            />
          </Grid>
          <Grid item xs={6}>
            <MailFormBox
              mails={formValue?.mails}
              onEditMail={onEditMail}
              onAddMail={onOpenMailDialog}
              onRemoveMail={onRemoveMail}
              readOnly={isReadOnly}
              subcontractorId={props.subcontractorId}
            />
          </Grid>
          <Grid item xs={6}>
            <UserFormBox
              users={formValue?.users}
              onAddUser={onOpenUserDialog}
              onRemoveUser={onRemoveUser}
              readOnly={isReadOnly}
              subcontractorId={props.subcontractorId}
            />
          </Grid>
          {!isReadOnly && (
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={createNewSubcontractorFn.loading || updateSubcontractorFn.loading}
                disabled={!isFormValid()}
                onClick={() => onSubmit(formValue)}
              >
                {props.subcontractorId ? t("save") : t("create")}
              </LoadingButton>
            </Grid>
          )}
          {!isReadOnly && openAddressDialog &&
            <AddressDialog
              addressId={selectedAddressId.current}
              open={openAddressDialog}
              subcontractorId={props.subcontractorId}
              onRefetch={subcontractorDetailsData.refetch}
              onClose={onCloseAddressDialog}
              onAddAddresForNewOwner={onAddAddresForNewOwner}
            />
          }
          {!isReadOnly && openPhoneDialog &&
            <PhoneDialog
              phoneId={selectedPhoneId.current}
              open={openPhoneDialog}
              subcontractorId={props.subcontractorId}
              onRefetch={subcontractorDetailsData.refetch}
              onClose={onClosePhoneDialog}
              onAddPhoneForNewOwner={onAddPhoneForNewOwner}
            />
          }
          {!isReadOnly && openMailDialog &&
            <MailDialog
              mailId={selectedMailId.current}
              open={openMailDialog}
              subcontractorId={props.subcontractorId}
              onRefetch={subcontractorDetailsData.refetch}
              onClose={onCloseMailDialog}
              onAddMailForNewOwner={onAddMailForNewOwner}
            />
          }
          {openUserDialog &&
            <SelectUserDialog
              open={openUserDialog}
              onClose={onCloseUserDialog}
              searchParams={{'is_active' : true}}
              returnObject = {true}
              onSubmit={onAddNewSubcontractorUser}
            />
          }
        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
};

SubcontractorDetailsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  subcontractorId: PropTypes.func
};

SubcontractorDetailsDialog.defaultProps = {
  open: false,
};

export default SubcontractorDetailsDialog;
