import { useEffect, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import LoadingButton from "@mui/lab/LoadingButton";

import { useForm } from "../../../hooks/useForm";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";

import useTicketService from "../../../services/ticketService";
import BasicDialog from "../../base/BasicDialog";
import RelatedTicketRecord from "../../other/RelatedTicketRecord";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { isEmptyObject } from "../../../helpers/methods";

const emptyObjectsList = [{}];

const RelatedTicketsDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [existsChanges, setExistsChanges] = useState(false);

  const {
    getRelatedTicketForTicket,
    getTicketSelectListForWithoutSelectedTicket,
    getTicketTicketKindSelectList,
    updateRelatedTicketForTicket,
  } = useTicketService();

  const relatedTicketForTicket = useAsync(
    () => getRelatedTicketForTicket(props.ticketId),
    [props.ticketId]
  );

  const ticketSelectList = useAsync(
    () => getTicketSelectListForWithoutSelectedTicket(props.ticketId),
    [props.ticketId]
  );

  const ticketTicketKindSelectList = useAsync(getTicketTicketKindSelectList);
  const updateRelatedTicketForTicketFn = useAsyncFn(
    updateRelatedTicketForTicket
  );

  const prepareDataFromBackendToFormValue = (data) => {
    const resData = [];
    for (const rowData of data) {
      const {
        id,
        first_ticket,
        second_ticket,
        tictic_kind,
        tictic_note,
        is_allowed_to_manual_edit,
      } = rowData;
      const ticket =
        props.ticketId !== first_ticket ? first_ticket : second_ticket;
      resData.push({
        id,
        ticket,
        tictic_kind,
        tictic_note,
        is_allowed_to_manual_edit,
      });
    }
    return resData;
  };

  const prepareDataToSend = () => {
    return formValue.filter(
      (relation) => relation.ticket && relation.tictic_kind
    );
  };

  const {
    formValue,
    setFormValue,
    onChangeInArrayForm,
    onChangeAutocompleteFieldWithObjectOptionsInArrayForm,
  } = useForm();

  useEffect(() => {
    if (relatedTicketForTicket.loading) return;
    setFormValue(
      prepareDataFromBackendToFormValue(
        relatedTicketForTicket.value.length !== 0
          ? relatedTicketForTicket.value
          : emptyObjectsList
      )
    );
  }, [relatedTicketForTicket.loading]);

  const onSubmit = () => {
    updateRelatedTicketForTicketFn
      .execute(props.ticketId, prepareDataToSend())
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.ticket_relations_update")
        );
        relatedTicketForTicket.refetch();
        setExistsChanges(true);
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_updating_ticket_relations")
        );
      });
  };

  const handleDeleteRelation = useCallback((index) => {
    setFormValue((prev) => {
      let newFormValue = [...prev];
      if (newFormValue.length !== 1) {
        newFormValue.splice(index, 1);
      } else {
        newFormValue.splice(index, 1, {});
      }

      return newFormValue;
    });
  }, []);

  const handleAddRelation = useCallback(() => {
    setFormValue((prev) => [...prev, {}]);
  }, []);

  const handleChangeInArrayForm = useCallback((...inputProps) => {
    onChangeInArrayForm(...inputProps);
  }, []);

  const handleChangeAutocompleteFieldWithObjectOptionsInArrayForm = useCallback(
    (...inputProps) => {
      onChangeAutocompleteFieldWithObjectOptionsInArrayForm(...inputProps);
    },
    []
  );

  const isFormValid = () => {
    const ticketIds = formValue
      .map((relation) => relation.ticket)
      .filter((ticket) => ticket);

    return (
      formValue.every(
        (relation) =>
          isEmptyObject(relation) || (relation.ticket && relation.tictic_kind)
      ) && ticketIds.length === new Set(ticketIds).size
    );
  };

  const isLoading =
    relatedTicketForTicket.loading ||
    ticketSelectList.loading ||
    ticketTicketKindSelectList.loading ||
    formValue === undefined;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <>
        {formValue.map((ticketData, index) => (
          <RelatedTicketRecord
            key={ticketData.id}
            ticket={ticketData.ticket}
            tictic_kind={ticketData.tictic_kind}
            tictic_note={ticketData.tictic_note}
            readOnly={
              props.readOnly || ticketData.is_allowed_to_manual_edit === false
            }
            index={index}
            ticketTicketKindSelectList={ticketTicketKindSelectList.value}
            ticketSelectList={ticketSelectList.value}
            showButtonAddRelation={!props.readOnly && index === 0}
            onAddRelation={handleAddRelation}
            onDeleteRelation={handleDeleteRelation}
            onChange={handleChangeInArrayForm}
            onChangeAutocompleteFieldWithObjectOptions={
              handleChangeAutocompleteFieldWithObjectOptionsInArrayForm
            }
          />
        ))}
        {!props.readOnly && (
          <LoadingButton
            sx={{ marginTop: "20px" }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={onSubmit}
            loading={updateRelatedTicketForTicketFn.loading}
            disabled={!isFormValid()}
          >
            {t("save")}
          </LoadingButton>
        )}
      </>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose(existsChanges);
      }}
      titleAlign="center"
      title={t("dialog.related_tickets_dialog.related_tickets")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

RelatedTicketsDialog.propTypes = {
  open: PropTypes.bool,
  readOnly: PropTypes.bool,
  onClose: PropTypes.func,
  ticketId: PropTypes.string,
};

RelatedTicketsDialog.defaultProps = {};

export default RelatedTicketsDialog;
