import { useMemo, useState } from "react";

import { Grid } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import { NAVIGATION_DRAWER_TYPE_ADMIN } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import StdTicketStdOrderAutomatTableWithToolBar from "./StdTicketStdOrderAutomatTableWithToolBar";

import {
  STD_AUTOMAT_TICKET_TABLE_NAME,
  useStdTicketOrderAutomatOrder,
} from "./TableConfig";

export default function AdminStdTicketStdOrderAutomatPage(props) {
  const { pageName } = props;

  const { getTablePropsByTableSubTableNames, fetchingFromBackend } =
    useStdTicketOrderAutomatOrder();

  const [selectedTable, setSelectedTable] = useState(
    STD_AUTOMAT_TICKET_TABLE_NAME
  );

  const tableProps = useMemo(() => {
    if (fetchingFromBackend) {
      return;
    }
    return getTablePropsByTableSubTableNames(selectedTable);
  }, [selectedTable, fetchingFromBackend]);

  const isLoading = tableProps === undefined || fetchingFromBackend;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_ADMIN}
    >
      <DefaultPageWrapper titleKey={"admin_std_tickets_orders_automat"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
          marginTop={1}
        >
          <Grid item xs={12}>
            <StdTicketStdOrderAutomatTableWithToolBar
              pageName={props.pageName}
              setSelectedTable={setSelectedTable}
              tableName={tableProps.tableName}
              tableConfig={tableProps.tableConfig}
              tableFilterPrefix={tableProps.tableFilterPrefix}
              tableConfigForUserPreferencesDialog={
                tableProps.configForUserPreferenceDialog
              }
            />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
