import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import BaseBox from "../../base/BaseBox/baseBox";

export default function OrderShortDetailsForm(props) {
  const { t } = useTranslation();

  const content = (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12} sm={2}>
        <TextFieldFormControl
          label={t("form.order_short_details_form.order_nr")}
          value={props.formValue.order_nr}
          readOnly={true}
        />
      </Grid>
      <Grid item xs={12} sm={2.5}>
        <TextFieldFormControl
          label={t("form.order_short_details_form.ticket_category")}
          value={props.formValue.ticket_category}
          readOnly={true}
        />
      </Grid>
      <Grid item xs={12} sm={2.5}>
        <TextFieldFormControl
          label={t("form.order_short_details_form.order_name")}
          value={props.formValue.order_name}
          readOnly={true}
        />
      </Grid>
      <Grid item xs={12} sm={2.5}>
        <TextFieldFormControl
          label={t("form.order_short_details_form.order_priority")}
          value={props.formValue.order_priority}
          readOnly={true}
        />
      </Grid>
      <Grid item xs={12} sm={2.5}>
        <TextFieldFormControl
          label={t("form.order_short_details_form.status")}
          value={props.formValue.status}
          readOnly={true}
        />
      </Grid>
    </Grid>
  );

  return props.inBox ? <BaseBox>{content}</BaseBox> : content;
}

OrderShortDetailsForm.propTypes = {
  formValue: PropTypes.object,
  inBox: PropTypes.bool,
};

OrderShortDetailsForm.defaultProps = {
  inBox: false,
};
