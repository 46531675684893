import CollapseButton from "../CollapseButton";
import ExpandButton from "../ExpandButton";

const CollapseOrExpandButton = ({ isExpanded, onChangeIsExpanded, sx }) => {
  if (isExpanded) {
    return <CollapseButton onClick={() => onChangeIsExpanded(false)} sx={sx} />;
  } else {
    return <ExpandButton onClick={() => onChangeIsExpanded(true)} sx={sx} />;
  }
};

export default CollapseOrExpandButton;
