import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { useSearchParams } from "react-router-dom";

import i18n from "../../../i18n";

import SplitButton from "../SplitButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

import { getFieldValueFromSearchParams } from "../../../helpers/methods";

import { centerVericalAlignIconStyle } from "../../../helpers/styles";
import useFillterButton from "../../../hooks/useFilterButton";
import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";

function OpenCloseFilterButton(props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { handleClickFilterButton } = useFillterButton(props.filterPrefix);
  const { getFilterFieldKey } = usePaginationWithSearchParams(
    props.filterPrefix
  );

  const openFilterKey = useMemo(
    () => getFilterFieldKey("open"),
    [getFilterFieldKey]
  );

  const closeFilterKey = useMemo(
    () => getFilterFieldKey("close"),
    [getFilterFieldKey]
  );

  const openCloseFilterKey = useMemo(
    () => getFilterFieldKey("open_close"),
    [getFilterFieldKey]
  );

  const filterOptions = [
    {
      label: props.openCloseFilterLabel,
      key: openCloseFilterKey,
      callback: () => handleClickFilter(undefined),
    },
    {
      label: props.closeFilterLabel,
      key: closeFilterKey,
      callback: () => handleClickFilter(false),
      startIcon: <FilterAltOffIcon sx={centerVericalAlignIconStyle} />,
    },
    {
      label: props.openFilterLabel,
      key: openFilterKey,
      callback: () => handleClickFilter(true),
      startIcon: <FilterAltIcon sx={centerVericalAlignIconStyle} />,
    },
  ];

  const selectedFilterKey = useMemo(() => {
    const filterValue = getFieldValueFromSearchParams(
      searchParams,
      getFilterFieldKey(props.openFilterKey)
    );
    if (filterValue === "true") return openFilterKey;
    if (filterValue === "false") return closeFilterKey;
    return openCloseFilterKey;
  }, [searchParams]);

  const handleClickFilter = (filterValue) => {
    handleClickFilterButton(filterValue, props.openFilterKey);
  };

  return (
    <SplitButton
      selectedItemKey={selectedFilterKey}
      withChangeSelectedItem={true}
      options={filterOptions}
    />
  );
}

OpenCloseFilterButton.propTypes = {
  openFilterKey: PropTypes.string,
  openFilterLabel: PropTypes.string,
  closeFilterLabel: PropTypes.string,
  openCloseFilterLabel: PropTypes.string,
  filterPrefix: PropTypes.string,
};

OpenCloseFilterButton.defaultProps = {
  openFilterKey: "is_open",
  openCloseFilterLabel: i18n.t("button.open_close_filter_button.open/close"),
  closeFilterLabel: i18n.t("button.open_close_filter_button.close"),
  openFilterLabel: i18n.t("button.open_close_filter_button.open"),
};

export default OpenCloseFilterButton;
