import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import { useTranslation } from "react-i18next";
import { formatRackShellData } from "../../../page/StockWarehousePage/PageTablesConfig";

const RackSellLocationForm = ({ index, location, onEditWarLocation, onRemoveWarLocation, readOnly }) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={readOnly ? 12 : 5.4} onClick={() => onEditWarLocation(location.id, index)}>
        <TextFieldFormControl
          readOnly={readOnly}
          value={formatRackShellData(location)}
        />
      </Grid>
        {!readOnly &&
      <Grid item xs={0.5}>
          <IconButtonWithTooltip
            onClick={(e) => {
              e.stopPropagation();
              onRemoveWarLocation(location.id, index)
            }}
            title={t("form.rack_shell_location_form.remove_structure")}
          >
            <RemoveCircleIcon color="secondary" />
          </IconButtonWithTooltip>
          </Grid>
        }
    </>
  );
};

RackSellLocationForm.propTypes = {
  index : PropTypes.number,
  location: PropTypes.object,
  readOnly: PropTypes.bool,
  onEditWarLocation : PropTypes.func,
  onRemoveWarLocation : PropTypes.func
};

RackSellLocationForm.defaultProps = {
  readOnly: true
};

export default RackSellLocationForm;
