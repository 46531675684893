
import PropTypes from "prop-types";
import React  from "react";
import FloatField from "../FloatField/FloatField";

function IntegerField(props) {
  
  return (
    <FloatField
      {...props}
      decimalPlaces = {0}
    />
  );
}

IntegerField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  decimalPlaces: PropTypes.number,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  fullWidth: PropTypes.bool,
  style: PropTypes.object,
  helperText: PropTypes.string,
  helperTextStyle: PropTypes.object,
  suffix: PropTypes.string,
  onBlur: PropTypes.func,
  fitWidthToContent: PropTypes.bool,
  inputProps: PropTypes.object,
  allowNegative: PropTypes.bool,
  variant: PropTypes.string,
  required: PropTypes.bool,
};

IntegerField.defaultProps = {

};

export default IntegerField;
