import {
  questionFormUrl,
  questionAdminUrl,
  questionAdminFilteringDataUrl,
  questionAdminDetailsUrl,
  questionAdminListForFirstQuestionUrl,
  questionAnswerAdminUrl,
  questionAnswerAdminDetailsUrl,
  questionAnswerAdminFilteringDataUrl,
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { makeRequestWithLanguage } from "../helpers/makeRequestWithLanguage";
import {
  isEmptyValue,
  prepareUrlWithQueryParams,
  generateRandomString,
} from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function useFormService() {
  const axiosPrivate = useAxiosPrivate();

  const getFormQuestion = (questionId) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(`${questionFormUrl}${questionId}/`)
    );
  };

  const getEmptyQuestionAnswerObject = (questionId) => {
    return {
      question: questionId,
      answer: undefined,
    };
  };

  const getEmptyAnswerObject = () => ({
    quan_content: null,
    quan_content_eng: null,
    quan_nextQ: null,
    quan_active: true,
    std_orders: [],
    id: generateRandomString(20),
  });

  const getAnswersForQuestion = (questionAnswers) => {
    var answerForQuestion = [];
    for (const qa of questionAnswers.filter((x) => !isEmptyValue(x.answer))) {
      answerForQuestion.push({ question: qa.question, answer: qa.answer });
    }
    return answerForQuestion;
  };

  const getQuestionAdminData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(questionAdminUrl, searchParams)
    );
  };

  const getQuestionAdminListForFirstQuestion = (questionId) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        questionAdminListForFirstQuestionUrl(questionId)
      )
    );
  };

  const getQuestionAdminDetailsData = (questionId) => {
    return makeRequest(axiosPrivate, questionAdminDetailsUrl(questionId));
  };

  const createQuestion = (dataToSend) => {
    return makeRequest(axiosPrivate, questionAdminUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateQuestion = (dataToSend, questionId) => {
    return makeRequest(axiosPrivate, questionAdminDetailsUrl(questionId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getQuestionAdminFilteringData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(questionAdminFilteringDataUrl, searchParams)
    );
  };

  const getQuestionAnswerAdminData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(questionAnswerAdminUrl, searchParams)
    );
  };

  const getQuestionAnswerAdminDetailsData = (questionAnswerId) => {
    return makeRequest(
      axiosPrivate,
      questionAnswerAdminDetailsUrl(questionAnswerId)
    );
  };

  const createQuestionAnswer = (dataToSend) => {
    return makeRequest(axiosPrivate, questionAnswerAdminUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateQuestionAnswer = (dataToSend, questionAnswerId) => {
    return makeRequest(
      axiosPrivate,
      questionAnswerAdminDetailsUrl(questionAnswerId),
      {
        method: "PATCH",
        data: dataToSend,
      }
    );
  };

  const getQuestionAnswerAdminFilteringData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        questionAnswerAdminFilteringDataUrl,
        searchParams
      )
    );
  };

  return {
    getFormQuestion,
    getEmptyQuestionAnswerObject,
    getEmptyAnswerObject,
    getAnswersForQuestion,
    getQuestionAdminData,
    getQuestionAdminDetailsData,
    getQuestionAdminFilteringData,
    getQuestionAdminListForFirstQuestion,
    createQuestion,
    updateQuestion,
    getQuestionAnswerAdminData,
    getQuestionAnswerAdminDetailsData,
    getQuestionAnswerAdminFilteringData,
    createQuestionAnswer,
    updateQuestionAnswer,
  };
}
