import SelectField from "../../field/SelectField";
import i18n from "../../../i18n";
import { convertStringBoolValueToBool } from "../../../helpers/methods";
import PropTypes from "prop-types";

function TrueFalseSelectField({
  name,
  value,
  trueOptionLabel,
  falseOptionLabel,
  valuesAsBool = false,
  emptyOptionsLabel,
  variant = "outlined",
  addEmptyOptions = true,
  ...rest
}) {
  const formattedValue = valuesAsBool
    ? convertStringBoolValueToBool(value)
    : value;
  const statusSelectList = [
    { id: valuesAsBool ? true : "true", name: trueOptionLabel },
    { id: valuesAsBool ? false : "false", name: falseOptionLabel },
  ];
  return (
    <SelectField
      name={name}
      value={formattedValue}
      options={statusSelectList}
      variant={variant}
      emptyOptionsLabel={emptyOptionsLabel}
      addEmptyOptions={addEmptyOptions}
      {...rest}
    />
  );
}

TrueFalseSelectField.propTypes = {
  trueOptionLabel: PropTypes.string,
  falseOptionLabel: PropTypes.string,
  emptyOptionsLabel: PropTypes.string,
};

TrueFalseSelectField.defaultProps = {
  trueOptionLabel: i18n.t("true"),
  falseOptionLabel: i18n.t("false"),
  emptyOptionsLabel: "-------",
};

export default TrueFalseSelectField;

export function reverseLookupForTrueFalseSelectField(key) {
  const reverseLookup = { true: i18n.t("true"), false: i18n.t("false") };
  return reverseLookup[key];
}
