import PropTypes from "prop-types";

import i18n from "../../../i18n";

import SplitButton from "../SplitButton";

function SelectStdTicketStdOrderTableButton(props) {
  const filterOptions = [
    {
      label:
        props.stdStdTicketLabel ||
        i18n.t("button.select_std_ticket_std_order.std_ticket"),
      key: props.stdStdTicketKey,
      callback: () => props.onChangeFilterValue(props.stdStdTicketKey),
    },
    {
      label:
        props.stdStdOrderLabel ||
        i18n.t("button.select_std_ticket_std_order.std_order"),
      key: props.stdOrderKey,
      callback: () => props.onChangeFilterValue(props.stdOrderKey),
    },
  ];

  return (
    <SplitButton
      selectedItemKey={props.selectedFilterKey}
      withChangeSelectedItem={true}
      color={props.color}
      options={filterOptions}
    />
  );
}

SelectStdTicketStdOrderTableButton.propTypes = {
  stdStdTicketKey: PropTypes.string,
  stdOrderKey: PropTypes.string,
  selectedFilterKey: PropTypes.string,
  onChangeFilterValue: PropTypes.func,
  color: PropTypes.string,
};

SelectStdTicketStdOrderTableButton.defaultProps = {
  stdStdTicketKey: "std_ticket",
  stdOrderKey: "std_order",
  color: "primary",
};

export default SelectStdTicketStdOrderTableButton;
