import { useTranslation } from "react-i18next";
import OfferReportBasePage, { CHART_TYPE_PIE } from "../OffersReportBasePage/";

export default function OffersPerReportTypeReportPage(props) {
  const { t } = useTranslation();

  const chartDataLabelsFormater = (value, ctx) => {
    if (value === 0) return "";

    let dataLabelFirstRow = `${ctx.chart.data.labels[ctx.dataIndex]}`;
    let dataLabelSecondRow = `${value}`;

    let dataLabel = `${dataLabelFirstRow}:${dataLabelSecondRow}`;

    return dataLabel;
  };

  return (
    <OfferReportBasePage
      chartType={CHART_TYPE_PIE}
      pageName={props.pageName}
      pageTitleKey={"offers_per_report_type_report"}
      groupBy={"none"}
      // labelBy={"costs"}
      showSelectObjectLevel2={false}
      showSelectTenant={true}
      totalIndicatorLabel={""}
      showSelectReportType={true}
      showSelectOfferStatus={true}
      showTotalIndicator={true}
      chartDataLabelsUnit={t("headers.offers").toLocaleLowerCase()}
      showChartLegend={false}
      dataLabelsFormater={chartDataLabelsFormater}
    />
  );
}

OffersPerReportTypeReportPage.propTypes = {};
