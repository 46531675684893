import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DivField from "../DivField/DivField";

const DivSelectField = React.memo((props) => {
  const [displayValue, setDisplayValue] = useState("");

  useEffect(() => {
    var option;
    var displayValue = "";
    if (!props.value) {
      setDisplayValue(displayValue);
      return;
    }

    if (props.multiple) {
      if (props.isObjectOption) {
        displayValue = props.value
          .map(
            (val) =>
              props.options.find((x) => x[props.optionKey] === val)[
                props.optionLabel
              ]
          )
          .join(",");
      } else {
        displayValue = props.value
          .map((val) => props.options.find((x) => x === val))
          .join(",");
      }
    } else {
      if (props.isObjectOption) {
        option = props.options.find((x) => x[props.optionKey] === props.value);
      } else {
        option = props.options.find((x) => x === props.value);
      }
      if (option) {
        if (props.isObjectOption) {
          displayValue = option[props.optionLabel];
        } else {
          displayValue = option;
        }
      }
    }

    setDisplayValue(displayValue);
  }, [
    props.value,
    props.options,
    props.optionKey,
    props.optionLabel,
    props.multiple,
  ]);
  return (
    <DivField
      label={props.label}
      required={props.required}
      type={props.type}
      value={displayValue}
      helperText={props.helperText}
      isInvalid={props.isInvalid}
      onSelectField={props.onSelectField}
      showBorder={props.showBorder}
      readOnly={props.readOnly}
      onFocus={props.onFocus}
    />
    //   <div style={containerStyle}>
    //   <div class="outlined-textfield" onClick={(event) => props.onSelectField(event)}>
    //     {(props.label || props.required) && <label class="outlined-label" style={{ color: color }}>{getLabel()}</label>}
    //     <input readOnly type={props.type} textAlign="center" class="outlined-input" value={displayValue} style={props.isInvalid ? { border: `1px solid ${color}` }: {border: "none"}} />
    //   </div>
    //   <div style={{ ...helperTextStyle, ERROR_TEXT_STYLE }}>{props.helperText}</div>

    // </div>
  );
});

DivSelectField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  optionLabel: PropTypes.string,
  optionKey: PropTypes.string,
  showBorder: PropTypes.bool,
  readOnly: PropTypes.bool,
  isObjectOption: PropTypes.bool,
  onFocus: PropTypes.func,
};

DivSelectField.defaultProps = {
  options: [],
  optionLabel: "name",
  optionKey: "id",
  isObjectOption: true,
};

export default DivSelectField;
