import { Grid, IconButton, Typography } from "@mui/material";
import React from 'react';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import BaseBox from "../../base/BaseBox/baseBox";
import TextFieldFormControl from "../../field/TextFieldFormControl";

export default function SettlementFormBox({ settlements, onDecrementPayers, readOnly }) {
    const { t } = useTranslation();

    return (
        <BaseBox>
            <Grid container spacing={1}>
                <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
                    <Typography
                        variant="subtitle2"
                        className="capitalize-first-letter"
                    >
                        {t(`other.settlement_form_box.settlements`)}
                    </Typography>
                </Grid>
                <Grid item sm={4} xs={4} />

                <Grid item sm={1} xs={1}>
                    <IconButton onClick={onDecrementPayers}>
                        <InfoOutlinedIcon
                            fontSize="medium"
                            sx={{
                                color: "var(--secondary)",
                            }}
                        />
                    </IconButton>

                </Grid>
                {settlements?.map((settlement) => {
                    if (settlement.isDeleted !== true) {
                        return (
                            <Grid item xs={12}>
                                <TextFieldFormControl
                                    value={settlement.tenant_short_name}
                                    label={t("other.settlement_form_box.settlement_name")}
                                    readOnly={readOnly}
                                />
                            </Grid>
                        )
                    }
                })
                }
            </Grid>
        </BaseBox>
    );
}

SettlementFormBox.propTypes = {
    settlements: PropTypes.array,

}


SettlementFormBox.defaultProps = {
    settlements: [],
    readOnly: true
}
