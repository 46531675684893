import PropTypes from "prop-types";

import i18n from "../../../i18n";

import SplitButton from "../SplitButton";

function SelectRcpPlanRcpDoneButton(props) {
  const filterOptions = [
    {
      startIcon: props.startIcon,
      label: `${i18n.t("add").toUpperCase()} ${i18n
        .t("tabs.management_teams.schedule_plan")
        .toUpperCase()}`,
      key: props.rcpPlanKey,
      callback: () => props.onChangeKeyValue(props.rcpPlanKey),
    },
    {
      startIcon: props.startIcon,
      label: `${i18n.t("add").toUpperCase()} ${i18n
        .t("tabs.management_teams.schedule_done")
        .toUpperCase()}`,
      key: props.rcpDoneKey,
      callback: () => props.onChangeKeyValue(props.rcpDoneKey),
    },
  ];

  return (
    <SplitButton
      selectedItemKey={props.selectedKey}
      withChangeSelectedItem={true}
      color={props.color}
      options={filterOptions}
      onClick={props.onClick}
    />
  );
}

SelectRcpPlanRcpDoneButton.propTypes = {
  rcpPlanKey: PropTypes.string,
  rcpDoneKey: PropTypes.string,
  rcpPlanLabel: PropTypes.string,
  rcpDoneLabel: PropTypes.string,
  selectedKey: PropTypes.string,
  onChangeKeyValue: PropTypes.func,
  startIcon: PropTypes.element,
  onClick: PropTypes.func,
  color: PropTypes.string,
};

SelectRcpPlanRcpDoneButton.defaultProps = {
  rcpPlanKey: "plan",
  rcpDoneKey: "done",
  color: "primary",
};

export default SelectRcpPlanRcpDoneButton;
