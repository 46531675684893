import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
} from "../../helpers/constants";
import DateService from "../../services/dateService";

export const TASK_TABLE_NAME = "task";

export const TASK_TABLE_FIELDS_CONFIG = [
  {
    name: "task_nr",
    getValueCallback: (rowData) => rowData.task_nr,
    label: i18n.t("table.task.task_nr"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "task_name",
    getValueCallback: (rowData) => rowData.task_name,
    label: i18n.t("table.task.task_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "task_desc_creator",
    getValueCallback: (rowData) => rowData.task_desc_creator,
    label: i18n.t("table.task.task_desc_creator"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },

  {
    name: "task_date_startT_plan",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss(
        rowData.task_date_startT_plan
      ),
    label: i18n.t("table.task.task_date_startT_plan"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "task_date_stopT_plan",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss(
        rowData.task_date_stopT_plan
      ),
    label: i18n.t("table.task.task_date_stopT_plan"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "task_date_startT_real",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss(
        rowData.task_date_startT_real
      ),
    label: i18n.t("table.task.task_date_startT_real"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "task_date_stopT_real",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss(
        rowData.task_date_stopT_real
      ),
    label: i18n.t("table.task.task_date_stopT_real"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "order_priority",
    getValueCallback: (rowData) => rowData.order_priority,
    label: i18n.t("table.task.order_priority"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.order_priority,
      isObjectOption: true,
      optionLabelKey: "ordpri_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "order_desc_creator",
    getValueCallback: (rowData) => rowData.order_desc_creator,
    label: i18n.t("table.order.order_desc_creator"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "ticket_category",
    getValueCallback: (rowData) => rowData.ticket_category,
    label: i18n.t("table.task.ticket_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_category,
      isObjectOption: true,
      optionLabelKey: "tc_name",
      addNewValue: false,
      multiple: true,
    }),
  },

  {
    name: "status",
    getValueCallback: (rowData) => rowData.status,
    label: i18n.t("table.task.status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.status,
      isObjectOption: true,
      optionLabelKey: "status_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "substatus",
    getValueCallback: (rowData) => rowData.substatus,
    label: i18n.t("table.task.reason"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.substatus,
      isObjectOption: true,
      optionLabelKey: "substatus_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "task_t",
    getValueCallback: (rowData) => rowData.task_t,
    label: i18n.t("table.task.task_t"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.task_t,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "task_kt",
    getValueCallback: (rowData) => rowData.task_kt,
    label: i18n.t("table.task.task_kt"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.task_kt,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "skill",
    getValueCallback: (rowData) => rowData?.skill,
    label: i18n.t("table.task.skill"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.skill,
      isObjectOption: true,
      optionLabelKey: "skill_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_1",
    getValueCallback: (rowData) => rowData.location.level_1,
    label: i18n.t("table.task.object_level_1"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_2",
    getValueCallback: (rowData) => rowData.location.level_2,
    label: i18n.t("table.task.object_level_2"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_3",
    getValueCallback: (rowData) => rowData.location.level_3,
    label: i18n.t("table.task.object_level_3"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_4",
    getValueCallback: (rowData) => rowData.location.level_4,
    label: i18n.t("table.task.object_level_4"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_5",
    getValueCallback: (rowData) => rowData.location.level_5,
    label: i18n.t("table.task.object_level_5"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_owner",
    getValueCallback: (rowData) => rowData.ticket_owner,
    label: i18n.t("table.ticket.ticket_owner"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_owner,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_om",
    getValueCallback: (rowData) => rowData.ticket_om,
    label: i18n.t("table.ticket.ticket_om"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_om,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_desc_creator",
    getValueCallback: (rowData) => rowData.ticket_desc_creator,
    label: i18n.t("table.task.ticket_desc_creator"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.task.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
];
