import PropTypes from "prop-types";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import FloatField from "../../base/FloatField/FloatField";
import SelectField from "../SelectField";
import AutocompleteField from "../../field/AutocompleteField";
import DateField from "../../field/DateField/DateField";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";

import {
  TEXT_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
  SELECT_FIELD_TYPE,
  DATE_FIELD_TYPE,
  DATE_RANGE_FIELD_TYPE,
  DATE_TO_DATETIME_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  DATETIME_TO_DATE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
} from "../../../helpers/constants";

const FilterFieldForm = (props) => {
  let FilterField = null;
  let onFilterChange = props.onChange;
  switch (props.type) {
    case NUMERIC_FIELD_TYPE:
      FilterField = FloatField;
      break;
    case TEXT_FIELD_TYPE:
      FilterField = TextFieldFormControl;
      break;
    case AUTOCOMPLETE_FIELD_TYPE:
      FilterField = AutocompleteField;
      if (props.fieldConfig?.isObjectOption) {
        onFilterChange = props.onChangeAutocompleteFieldWithObjectOptions;
      }
      break;
    case SELECT_FIELD_TYPE:
      FilterField = SelectField;
      break;
    case DATE_FIELD_TYPE:
    case DATE_RANGE_FIELD_TYPE:
      FilterField = DateField;
      onFilterChange = props.onChangeDate;
      break;
    case DATE_TO_DATETIME_FIELD_TYPE:
      FilterField = DateField;
      onFilterChange = props.onChangeDateConvertedToDateTimeWithZeroHourOfDate;
      break;
    case DATETIME_TO_DATE_FIELD_TYPE:
    case DATETIME_TO_DATE_RANGE_FIELD_TYPE:
      FilterField = DateField;
      onFilterChange = props.onChangeDatetimeToDate;
      break;
    case BOOLEAN_FIELD_TYPE:
      FilterField = TrueFalseSelectField;
      break;
    default:
      return null;
  }
  return (
    <FilterField
      name={props.name}
      readOnly={props.readOnly}
      label={props.label}
      value={props.formValue[props.name]}
      onChange={onFilterChange}
      variant={"outlined"}
      {...props.fieldConfig}
    />
  );
};

FilterFieldForm.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  formValue: PropTypes.any,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  fieldConfig: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeDateConvertedToDateTimeWithZeroHourOfDate: PropTypes.func,
  onChangeDatetimeToDate: PropTypes.func,
};

export default FilterFieldForm;
