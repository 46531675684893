import { useState } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../../context/auth";
import useEffectOnce from "../../../hooks/useEffectOnce";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { validToken } from "../../../helpers/methods";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { accessToken, refreshAccess } = useAuth();
  useEffectOnce(() => {
    const verifyRefreshToken = async () => {
      try {
        await refreshAccess();
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    !validToken(accessToken) ? verifyRefreshToken() : setIsLoading(false);
  }, []);

  return (
    <LoaderWrapper showLoader={isLoading}>
      <Outlet />
    </LoaderWrapper>
  );
};

export default PersistLogin;
