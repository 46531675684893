import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import useOfferTableService from "../services/offerTableService";
import { getFieldsFromObject } from "../helpers/methods";
import useOfferService from "../services/offerService";
import { useAsync } from "./useAsync";
import { useAuth } from "../context/auth";
import { useSnackbarAlert } from "../context/snackbarAlert";
import { OFFER_PARTS_TABLE_FIELDS_CONFIG } from "../components/dialog/OfferConfiguratorDialog/DialogTablesConfig";


const useOfferPartData = (
  offerId, 
  setHasChangesOnPage,
  isReadOnly
) => {
  const { t } = useTranslation();
 
  const [partDataLocal, setPartDataLocal] = useState(undefined)

  const [requiredPartOfferFields, setRequieredPartOfferFields] = useState([])

  const [partSummaryRow, setPartSummaryRow] = useState()

  const [isOfferPartTableValid, setIsOfferPartTableValid] = useState(true)

  const {user} = useAuth()

  const snackbarAlert = useSnackbarAlert();

  const {
    getOfferPartsData
  } = useOfferService ()

  const {
    summaryElementsFields,
    setInititalPartData,
    getFieldsNameToSave,
    calculateElement,
    calculateMarginProcOnMarginPlnInput,
    calculateDiscountProcOnDiscountPlnInput,
    isTableValid,
    setPartOfferDataAfterChange
} = useOfferTableService();

const offerPartsList = useAsync(
    () =>  getOfferPartsData(offerId),
    [offerId]
  );

  useEffect(() => {
    if (partDataLocal && !isReadOnly) {
        setIsOfferPartTableValid(isTableValid(partDataLocal))
    }
}, [partDataLocal, isReadOnly]);


useEffect(() => {
    if (offerPartsList.loading) {
      return;
    }
  if (offerPartsList.value ) {
      setPartDataLocal(offerPartsList.value)
      setPartSummaryRow(summaryElementsFields(offerPartsList.value, 'paof'))
  }
}, [offerPartsList.loading]);




useEffect(() => {
    setRequieredPartOfferFields(OFFER_PARTS_TABLE_FIELDS_CONFIG.filter((config) => config.required === true).map((c) => c.name))
}, [OFFER_PARTS_TABLE_FIELDS_CONFIG]);

const onChangePartAutocompleteField = useCallback((
    e,
    value_object,
    value_key,
    state_value_name,
    index) => {
    let tempDataLocal = partDataLocal
    let element = tempDataLocal[index]
    element.editDate = Date.now()

    element.isEdited = true
    if (state_value_name === 'part') {
        element = setPartOfferDataAfterChange(element, value_object)
    } else {
        element[state_value_name] = value_object[value_key]
    }
    onCalculateElementLocal(element, index, tempDataLocal)
}, [partDataLocal, requiredPartOfferFields])

const onChangePartBySelectDialog = useCallback((index, part) =>{
    let tempDataLocal = partDataLocal
    let element = tempDataLocal[index]
    element.isEdited = true
    element = setPartOfferDataAfterChange(element, part)
    onCalculateElementLocal(element, index, tempDataLocal)
}, [partDataLocal, requiredPartOfferFields])

const onCalculateElementLocal = (element, index, tempDataLocal) =>{
    element = calculateElement(element, 'paof', requiredPartOfferFields)
    tempDataLocal[index] = { ...element }
    setPartSummaryRow(summaryElementsFields(tempDataLocal, 'paof'))
    setPartDataLocal([...tempDataLocal])
    setHasChangesOnPage(true)
}


const onChangePartTableData = useCallback((name, value, idx) => {
    let tempDataLocal = partDataLocal
    let element = tempDataLocal[idx]
    element[name] = value
    element.isEdited = true
    element.editDate = Date.now()

    let calulate_margin_pln = true
    let calulate_discount_pln = true

    if (name === 'paof_margin_pln') {
        element.paof_margin_proc = calculateMarginProcOnMarginPlnInput(element.paof_price, element.paof_margin_pln)
        calulate_margin_pln = false
    } else if (name === 'paof_discount_pln'){
        element.paof_discount_proc = calculateDiscountProcOnDiscountPlnInput(element.paof_price_with_margin, element.paof_discount_pln)
        calulate_discount_pln = false
    }
    element = calculateElement(element, 'paof', requiredPartOfferFields, calulate_margin_pln, calulate_discount_pln)
    tempDataLocal[idx] = { ...element }

    setPartSummaryRow(summaryElementsFields(tempDataLocal, 'paof'))
    setPartDataLocal([...tempDataLocal])
    setHasChangesOnPage(true)
}, [partDataLocal, requiredPartOfferFields])

const onHandlePartAddEmptyRow = useCallback(() => {
    let tempDataLocal = [...partDataLocal]
    tempDataLocal.push({ isEdited: true, editable: true,  isValid: false })
    setPartDataLocal([...tempDataLocal])
    setPartSummaryRow(summaryElementsFields(tempDataLocal, 'paof'))
    setHasChangesOnPage(true)
}, [partDataLocal])

const getPartOffersToSend = () => {
    let partOfferDataToSend = []
    if (partDataLocal) {
        for (let offerPartItem of partDataLocal) {
            if (offerPartItem.isEdited || offerPartItem.isDeleted) {
                partOfferDataToSend.push(prepareElementDataToSave(offerPartItem));
            }
        }
    }
    return partOfferDataToSend
}

const prepareElementDataToSave = (element) => {
    return getFieldsFromObject(element, getFieldsNameToSave('paof'));
};


const handleRemoveOfferFromPart = useCallback((rowIndex) => {
    let tempDataLocal = partDataLocal
    let element = tempDataLocal[rowIndex]
    if (element.id === undefined) {
        tempDataLocal.splice(rowIndex, 1)
    } else if  (user.is_k || user.user_id === element.paof_user){
        element.isDeleted = true
        tempDataLocal[rowIndex] = { ...element }
} else{
    snackbarAlert.openErrorSnackbarAlert(
        t("snackbar_alert.you_are_not_allowed_to_delete_row")
      );
}
    setPartSummaryRow(summaryElementsFields(tempDataLocal, 'paof'))
    setPartDataLocal([...tempDataLocal])
    setHasChangesOnPage(true)
}, [partDataLocal])

  return {
    offerPartData : partDataLocal,
    setOfferPartData : setPartDataLocal,
    offerPartSummaryRow : partSummaryRow,
    onChangePartAutocompleteField,
    onChangePartBySelectDialog,
    onChangePartTableData,
    onHandlePartAddEmptyRow,
    getPartOffersToSend,
    handleRemoveOfferFromPart,
    isOfferPartTableValid,
    isOfferPartLoading : offerPartsList.loading,
    refetchOfferPartData : offerPartsList.refetch
  };
};

export default useOfferPartData;
