import TicketsReportBasePage from "../TicketsReportBasePage";

import DunReportsNavigationBar from "../../components/bar/DunReportsNavigationBar";
import DunReportsNavigationPeopleSubBar from "../../components/bar/DunReportsNavigationBar/DunReportsNavigationPeopleSubBar";

export default function TicketPerResponseTimeReportPage(props) {
  return (
    <TicketsReportBasePage
      pageName={props.pageName}
      pageTitleKey={"tickets_per_response_time"}
      groupBy={"response_time"}
      labelBy={"ticket_k"}
      showSelectTimeRange={false}
      showOnlySelectDay={false}
      showTicketOwner={false}
      showSelectObjectLevel2={false}
      showSelectTicketK={true}
      showSelectCategoryButton={false}
      NavigationBar={
        <>
          <DunReportsNavigationBar
            multipleLocationKey={"/tickets_by_ticket_k_report/"}
          />
          <DunReportsNavigationPeopleSubBar />
        </>
      }
      showNavigationBar={true}
      showOpenCloseFilterButton={true}
      showTenantVisibilityButton={true}
      chartHeight={"40vh"}
      chartHeightInFullScreen={"67vh"}
    />
  );
}

TicketPerResponseTimeReportPage.propTypes = {};
