import { AUTOCOMPLETE_FIELD_TYPE, BOOLEAN_FIELD_TYPE, RESERVATION_KIND, TRANSACTION_KIND_DELETE, TRANSACTION_KIND_IN, TRANSACTION_KIND_MOVE, TRANSACTION_KIND_OUT } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import permissionTableService from "../../../../services/permissionTableService";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export const USER_WAREHOUSE_PERMISSION_TABLE_CONFIG = [
  {
    name: "user",
    getValueCallback: (rowData) => rowData.user_fullname,
    label: i18n.t("table.user_warehouse_perm_table.user_with_warehouse_role"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.users,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "warehouse",
    getValueCallback: (rowData) => rowData.warehouse_name,
    label: i18n.t("table.user_warehouse_perm_table.warehouse"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.warehouses,
      isObjectOption: true,
      optionLabelKey: "warehouse_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "trans_in",
    getValueCallback: (rowData) => permissionTableService.checkPermission(rowData.permissions, TRANSACTION_KIND_IN),
    valueAsCheckbox : true,
    label: i18n.t("table.user_warehouse_perm_table.transaction_in"),
    filterType: undefined
  },
  {
    name: "trans_out",
    getValueCallback: (rowData)=> permissionTableService.checkPermission(rowData.permissions, TRANSACTION_KIND_OUT),
    valueAsCheckbox : true,
    label: i18n.t("table.user_warehouse_perm_table.transaction_out"),
    filterType: undefined
  },
  {
    name: "trans_move",
    getValueCallback: (rowData)=> permissionTableService.checkPermission(rowData.permissions, TRANSACTION_KIND_MOVE),
    valueAsCheckbox : true,
    label: i18n.t("table.user_warehouse_perm_table.transaction_move"),
    filterType: undefined
  },
  {
    name: "trans_delete",
    getValueCallback: (rowData)=> permissionTableService.checkPermission(rowData.permissions, TRANSACTION_KIND_DELETE),
    valueAsCheckbox : true,
    label: i18n.t("table.user_warehouse_perm_table.transaction_delete"),
    filterType: undefined
  },
  {
    name: "trans_reservation",
    getValueCallback:  (rowData)=> permissionTableService.checkPermission(rowData.permissions, RESERVATION_KIND),
    valueAsCheckbox : true,
    label: i18n.t("table.user_warehouse_perm_table.trans_reservation"),
    filterType: undefined
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickDialog ) => {
      return (
        <ModeEditOutlineOutlined
          onClick={(e) => onClickDialog (e, rowData.id, "edit")}
        />
      );
    },
    label: i18n.t("table.user_warehouse_perm_table.edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "copy",
    getValueCallback: (rowData, onClickDialog ) => {
      return (
        <ContentCopyIcon
          onClick={(e) => onClickDialog(e, rowData.id, "copy")}
        />
      );
    },
    label: i18n.t("table.user_warehouse_perm_table.copy_permissions"),
    filterType: null
  }
];
