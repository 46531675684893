import { Grid } from "@mui/material";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import useCorrespondenceService from "../../services/correspondenceService";
import { useAsync } from "../../hooks/useAsync";
import { NAVIGATION_DRAWER_TYPE_CORRESPONDENCE } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import CorrespondenceRelatedTablesTabs from "../../components/tabs/CorrespondenceRelatedTablesTabs/CorrespondenceRelatedTablesTabs";
import useCheckPermission from "../../hooks/usePermission";
import { EDIT_CORRESPONDENCE_PERMISSION } from "../../helpers/constants";

export default function CorrespondencePage(props) {
  const { pageName } = props;
  const { getPostFilteringData } = useCorrespondenceService();
  const postFilteringData = useAsync(getPostFilteringData);
  const [hasEditPermission] = useCheckPermission(EDIT_CORRESPONDENCE_PERMISSION)

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_CORRESPONDENCE}
    >
      <DefaultPageWrapper titleKey={"correspondence"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <CorrespondenceRelatedTablesTabs
              pageName={pageName}
              filteringData={postFilteringData}
              hasEditPermission={hasEditPermission}
            />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}

