import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import { useTranslation } from "react-i18next";

const FullAddressForm = ({index, address, onEditAddress, onRemoveAddress, readOnly, subcontractorId }) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={readOnly ? 12 : 10.8}  onClick={() => onEditAddress(address.address_id)}>
        <TextFieldFormControl
          readOnly = {readOnly}
          value={address.full_address}
          label={address.address_kind}
        />
      </Grid>
      <Grid item xs={1}>
        {!readOnly &&
          <IconButtonWithTooltip
            onClick={(e) => {
              e.stopPropagation();
              onRemoveAddress(subcontractorId ? address.subcontractoraddress_id: address.owneraddress_id, index)
            }}
            title={t("form.full_address_form.remove_address")}
          >
            <RemoveCircleIcon color="secondary" />
          </IconButtonWithTooltip>
        }
      </Grid>
    </>
  );
};

FullAddressForm.propTypes = {
  address: PropTypes.object,
  readOnly: PropTypes.bool
};

FullAddressForm.defaultProps = {
  readOnly : true
};

export default FullAddressForm;
