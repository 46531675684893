import React from "react";

import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import FullWidthTabs from "../FullWidthTabs/FullWidthTabs";
import CorrespondencePostInTab from "./CorrespondencePostInTab/CorrespondencePostInTab";
import CorrespondencePostOutTab from "./CorrespondencePostOutTab";



const CorrespondenceRelatedTablesTabs = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <FullWidthTabs
      items={[
        {
          label: t("page.correspondence_page.post_in"),
          content: (
            <CorrespondencePostInTab
              readOnly={props.readOnly}
              pageName={props.pageName}
              filteringData={props.filteringData}
              hasEditPermission={props.hasEditPermission}
              
            />
          ),
        },
        {
          label: t("page.correspondence_page.post_out"),
          content: (
            <CorrespondencePostOutTab
              readOnly={props.readOnly}
              pageName={props.pageName}
              filteringData={props.filteringData}
              hasEditPermission={props.hasEditPermission}
            />
          ),
        },
      ]}
    />
  );
});

CorrespondenceRelatedTablesTabs.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

CorrespondenceRelatedTablesTabs.defaultProps = {
  readOnly: false,
};

export default CorrespondenceRelatedTablesTabs;
