import { useEffect, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";

import { useForm } from "../../../hooks/useForm";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";

import useTaskService from "../../../services/taskService";
import useOrderService from "../../../services/orderService";
import useLocationService from "../../../services/locationService";

import BasicDialog from "../../base/BasicDialog";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { getRandomString, isEmptyValue } from "../../../helpers/methods";
import BaseBox from "../../base/BaseBox/baseBox";

import OrderShortDetailsForm from "../../form/OrderShortDetailsForm";
import TaskCreateForm from "../../form/TaskCreateForm";

import useFileService from "../../../services/fileService";
import { EXTERNAL_TYPE } from "../../../helpers/constants";

const CreateTaskDialog = (props) => {
  const { t } = useTranslation();

  const snackbarAlert = useSnackbarAlert();

  const {
    getChosenHighestLevelObject,
    prepareLocationDataFromBackendForLocationRecordsComponent,
    getEmptyLocationElement,
  } = useLocationService();

  const { getInitialOrderDataForCreateTask } = useOrderService();
  const { getTaskFilteringDataForCreate, createTask } = useTaskService();

  const initialTaskData = useAsync(() =>
    getInitialOrderDataForCreateTask(props.orderId)
  );
  const filteringData = useAsync(getTaskFilteringDataForCreate);
  const createTaskFn = useAsyncFn(createTask);

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm(undefined);

  const [orderData, setOrderData] = useState(undefined);
  const [locations, setLocations] = useState(undefined);

  useEffect(() => {
    if (initialTaskData.loading) return;

    const { order, location, ...formValue } = initialTaskData.value;

    setLocations(
      prepareLocationDataFromBackendForLocationRecordsComponent(location)
    );
    setOrderData(order);
    setFormValue(formValue);
  }, [initialTaskData.loading]);

  const isFormValid = () => {
    for (const location of locations) {
      if (isEmptyValue(location[0]?.id)) {
        return false;
      }
    }

    const requiredFormField = [
      "task_name",
      "task_t",
      "status",
      "task_date_startT_real",
      "task_date_stopT_real",
    ];

    for (const fieldName of requiredFormField) {
      if (isEmptyValue(formValue[fieldName])) {
        return false;
      }
    }

    if (formValue.task_date_stopT_real >= new Date()) return false;

    if (formValue.task_date_startT_real >= formValue.task_date_stopT_real)
      return false;

    return true;
  };

  const getDialogTitle = () => {
    return t("dialog.create_task_dialog.add_visit");
  };

  const [enclosures, setEnclosures] = useState([]);
  const onAddEnclosure = (enclosures) => {
    setEnclosures((enclosuresTemp) => [...enclosuresTemp, ...enclosures]);
  };

  const onDeleteEnclosure = (index) => {
    setEnclosures((enclosuresTemp) => {
      enclosuresTemp = [...enclosuresTemp];
      enclosuresTemp.splice(index, 1);
      return enclosuresTemp;
    });
  };

  const { downloadFileByBlobUrl } = useFileService();
  const onDownloadEnclosure = (enclosureId, enclosureName, index) => {
    const enclosure = enclosures[index];
    downloadFileByBlobUrl(enclosure.blob_url, enclosureName);
  };

  const isLoading =
    initialTaskData.loading ||
    filteringData.loading ||
    formValue === undefined ||
    orderData === undefined ||
    locations === undefined;

  const prepareDataToSend = () => {
    return {
      ...formValue,
      order: formValue.order_id,
      task_date_startT_plan: formValue.task_date_startT_real,
      task_date_stopT_plan: formValue.task_date_startT_real,
      task_object: getChosenHighestLevelObject(locations[0]),
      enclosures: prepareEnclosuresToSend (enclosures)
    };
  };

  const prepareEnclosuresToSend = (enclosures) =>{
    for (let enclosure of enclosures){
      enclosure['enclosure_external'] = enclosure.enclosure_type === EXTERNAL_TYPE
      enclosure['token_data'] = {
        'file_ori_name' : enclosure['enclosure_name'],
        'unique_index' : getRandomString(),
        'content_type' : enclosure['file'].type
      }
    }
    return enclosures
  }

  const onSubmit = () => {
    createTaskFn
      .execute(prepareDataToSend())
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.task_created")
        );
        if (props.onSubmit) props.onSubmit();

        props.onClose();
      })
      .catch((error) => {
        if (error.status === 400) {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_creating_task")
          );
        } else {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.server_error")
          );
        }
      });
  };

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12}>
          <OrderShortDetailsForm formValue={orderData} inBox={true} />
        </Grid>
        <Grid item xs={12}>
          <BaseBox>
            <TaskCreateForm
              locations={locations}
              onChangeLocations={setLocations}
              formValue={formValue}
              onChange={onChange}
              onChangeAutocompleteFieldWithObjectOptions={
                onChangeAutocompleteFieldWithObjectOptions
              }
              filteringData={filteringData.value}
              enclosures={enclosures}
              onAddEnclosure={onAddEnclosure}
              onDeleteEnclosure={onDeleteEnclosure}
              onDownloadEnclosure={onDownloadEnclosure}
            />
          </BaseBox>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            sx={{ marginTop: "20px" }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={onSubmit}
            loading={createTaskFn.loading}
            disabled={!isFormValid()}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      titleAlign="center"
      title={getDialogTitle()}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

CreateTaskDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  orderId: PropTypes.string,
};

CreateTaskDialog.defaultProps = {};

export default CreateTaskDialog;
