import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import BasicDialog from "../../base/BasicDialog";
import { useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useDocsService from "../../../services/docsService";
import CreateCertificateForm from "../../form/CreateCertificateForm";

const CertificateDetailsDialog = (props) => {

  const { t } = useTranslation();
  const { createCertificate } = useDocsService();
  const createCertificateFn = useAsyncFn(createCertificate);
  const snackbarAlert = useSnackbarAlert();

  const onSubmit = (certData) => {
    createCertificateFn
      .execute(certData)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.certificate_created")
        );
        if (props.onRefetchData) {
          props.onRefetchData();
        }
        props.onClose(true);
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_creating_certificate")
        );
      });
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={
        props.readOnly
          ? t(`dialog.certificate_dialog.certificate`)
          : t(`dialog.certificate_dialog.create_certificate`)
      }
      maxWidth="xs"
    >
      <CreateCertificateForm
        onSubmit={onSubmit}
        loadingSubmitButton={createCertificateFn.loading}
        readOnly={props.readOnly}
      />
    </BasicDialog>
  );
};

CertificateDetailsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

CertificateDetailsDialog.defaultProps = {
  open: false,
};

export default CertificateDetailsDialog;
