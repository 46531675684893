import { useState, useEffect, useCallback, useMemo } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import { STOCK_BUFFER_TABLE_FIELDS_CONFIG } from "./PageTablesConfig";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { useEmptyArrayMemo } from "../../hooks/useEmptyArrayMemo";
import useWarehouseService from "../../services/warehouseService";
import FilterTable from "../../components/table/FilterTable";
import TableService from "../../services/tableService";
import { NAVIGATION_DRAWER_TYPE_WAREHOUSES } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import { centerVericalAlignIconStyle } from "../../helpers/styles";
import BufferDialog from "../../components/dialog/BufferDialog";
import useDialog from "../../hooks/useDialog";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSnackbarAlert } from "../../context/snackbarAlert";
import usePurchaseService from "../../services/purchaseService";
import useDialogWithId from "../../hooks/useDialogWithId";
import PurchaseDialog from "../../components/dialog/PurchaseDialog";
import { BUFFER_EDIT_PERMISSION, CREATE_PURCHASE_PERMISSION } from "../../helpers/constants";
import useCheckPermission from "../../hooks/usePermission";
import { buffersExportDataUrl } from "../../helpers/apiUrls";
import { APP_STAGE } from "../../helpers/constants";

export default function StockBufferPage(props) {
  const { pageName } = props;
  const snackbarAlert = useSnackbarAlert();
  const { t } = useTranslation();
  const [stocksLocal, setStocksLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();
  const [selectedBufferId, setSelectedBuffertId] = useState();

  const [hasEditBufferPermission] = useCheckPermission(BUFFER_EDIT_PERMISSION )
  const [hasCreatePurchasePermission] = useCheckPermission(CREATE_PURCHASE_PERMISSION )
  const localTable = [APP_STAGE.STAGE, APP_STAGE.LOCAL].includes(window._env_.APP_STAGE.toString().toLowerCase()) ? STOCK_BUFFER_TABLE_FIELDS_CONFIG : STOCK_BUFFER_TABLE_FIELDS_CONFIG.slice(1)

  const [
    openBufferDialog,
    onOpenBufferDialog,
    onCloseBufferDialog,
  ] = useDialog();

  const [
    openPoDialog,
    poEditId,
    handleOpenPoDialog,
    handleClosePoDialog,
    exportPoStatus
  ] = useDialogWithId();

  const {
    page: stockDataPage,
    pageSize: stockDataPageSize,
    handleChangePageWithSearchParams: handleChangeStockDataPage,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();


  const {
    getBuffers,
    getBufferFilteringData,
    removeBuffer
  } = useWarehouseService()

  const {
    createPo
  } = usePurchaseService()
  

  const removeBufferFn = useAsyncFn(removeBuffer)
  const createPoFn = useAsyncFn(createPo)

  const bufferData = useAsync(
    () => getBuffers(searchParams),
    [searchParams]
  );


  const closeBufferDialog = useCallback(() => {
    setSelectedBuffertId(undefined);
    onCloseBufferDialog();
  }, [selectedBufferId]);


  const { emptyArrayMemo } = useEmptyArrayMemo()

  const buffersFilteringData = useAsync(getBufferFilteringData)

  useEffect(() => {
    if (bufferData.loading) {
      return;
    }
    setStocksLocal(bufferData.value.results);
    setCountRecords(bufferData.value.count);
  }, [bufferData.loading]);

  const onEditBuffer = useCallback(
    (e, bufferId) => {
      e.stopPropagation();
      setSelectedBuffertId(bufferId);
      onOpenBufferDialog();
    },
    [selectedBufferId]
  );

  const onClickPurchase = useCallback(
    (e, partId) => {
      e.stopPropagation();
      createPoFn
      .execute({'part' : partId})
      .then((res) => {
        let poId = res.id
        handleOpenPoDialog(poId)
      })
    },
    []
  );



  const handleRemoveBuffer = (bufferId) => {
    removeBufferFn 
    .execute(bufferId)
    .then((res) => {
      bufferData.refetch()
      snackbarAlert.openSuccessSnackbarAlert(
        t("snackbar_alert.buffer_removed")
      );
    })
    .catch((error) => {
      snackbarAlert.openErrorSnackbarAlert(
        t("snackbar_alert.occurred_error_during_buffer_removing")
      );
    });
  };

  const isLoading =
    stocksLocal === undefined ||
    countRecords === undefined;

  const data = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        localTable ,
        stocksLocal,
        emptyArrayMemo,
        emptyArrayMemo,
        onClickPurchase,
        onEditBuffer,
      ),
    [stocksLocal, localTable ]
  );

  const stockExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      STOCK_BUFFER_TABLE_FIELDS_CONFIG,
      emptyArrayMemo,
      emptyArrayMemo
    );
  }, [
    emptyArrayMemo,
    STOCK_BUFFER_TABLE_FIELDS_CONFIG,
  ]);

  const bufferTableContextMenuActions = [
    {
      label: t("page.stock_buffer_page.remove_buffer"),
      callback: (bufferId) => handleRemoveBuffer(bufferId),
      icon: <DeleteOutlineIcon fontSize="medium" />,
    },
  ];

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_WAREHOUSES}
      >
      <DefaultPageWrapper titleKey={"buffers"}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
      {hasEditBufferPermission &&
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
            onClick={onOpenBufferDialog}
          >
            {t("page.stock_buffer_page.new_buffer")}
          </Button>
        </Grid>}
          <Grid item xs={12}>
            {
              isLoading ?
                <LoaderWrapper showLoader={true} />
                :
                <FilterTable
                  data={data}
                  showCheckbox={false}
                  showCleanFilterIcon={true}
                  countRecords={countRecords}
                  page={stockDataPage}
                  onPageChange={handleChangeStockDataPage}
                  rowsPerPage={stockDataPageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  filteringData={buffersFilteringData}
                  hiddenColumns={emptyArrayMemo}
                  style={{ maxHeight: "vh" }}
                  tableConfig={localTable }
                  headersConfig={TableService.getHeadersConfigForCollapsibleTable(
                    localTable,
                    buffersFilteringData || emptyArrayMemo,
                     emptyArrayMemo
                  )}
                  showContextMenu={true}
                  contextMenuActions={bufferTableContextMenuActions}
                  showExportToFileButton={true}
                  exportToFileUrl={buffersExportDataUrl}
                  exportToFileSearchParams={searchParams}
                  exportToFileHeaders={stockExportHeaders}
                  exportToFileFileName={`${t(
                    "page.stock_buffer_page.buffer_stock"
                  ).replace(" ", "_")}.xlsx`}
                />
            }
          </Grid>
        </Grid>
        {openBufferDialog && (
          <BufferDialog
            open={openBufferDialog}
            onClose={closeBufferDialog}
            filteringData={buffersFilteringData}
            onRefetchData={bufferData.refetch}
            bufferId={selectedBufferId}
          />
        )}
        
        {openPoDialog &&
          <PurchaseDialog
            poId = {poEditId}
            open={openPoDialog}
            onClose={handleClosePoDialog}
            hasEditBufferPermission = {hasEditBufferPermission}
            onRefetchData = {()=>undefined}
            hasEditPermission = {hasCreatePurchasePermission}
          />
        }
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
