import { useState } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { useAsync } from "../../../hooks/useAsync";

import useTicketService from "../../../services/ticketService";
import BasicDialog from "../../base/BasicDialog";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import AutocompleteField from "../../field/AutocompleteField";
import { Button, Grid } from "@mui/material";

const SelectDeactivateReasonDialog = (props) => {
  const { t } = useTranslation();

  const [selectedReason, setSelectedReason] = useState();


  const onChangeAutocompleteField = (
    e,
  ) => {
    setSelectedReason(e.target.value)
  };
  
  const getDialogContent = () => {

    return (
      <>
        <Grid item xs={12}>
          <AutocompleteField
            name="reason"
            label={t("dialog.select_deactivate_reason.select_reason")}
            value={selectedReason}
            options={props.deactivateReasonsList}
            isObjectOption={false}
            addNewValue={false}
            onChange={onChangeAutocompleteField}
            required
          />
        </Grid>
        <Button
          sx={{ marginTop: "20px" }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => props.onSubmit(selectedReason)}
          disabled={!selectedReason}
        >
          {t("confirm")}
        </Button>
      </>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      titleAlign="center"
      maxWidth="sm"
      title={t("dialog.select_deactivate_reason.choose_select_reason")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

SelectDeactivateReasonDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  deactivateReasonsList : PropTypes.array
};

SelectDeactivateReasonDialog.defaultProps = {
  open : false,
  deactivateReasonsList : []
};

export default SelectDeactivateReasonDialog;
