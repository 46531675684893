import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import { UNIQUE_ERROR_TEXT } from "../../../helpers/constants";


const PhoneDetailsForm = ({
  phone,
  isNumberValid,
  onChange }
) => {

  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={1}
    >
      <Grid item xs={12}>
        <TextFieldFormControl
          name="phone_who"
          label={t("form.phone_details_form.phone_who")}
          value={phone?.phone_who}
          required
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="phone_nr"
          label={t("form.phone_details_form.phone_nr")}
          value={phone?.phone_nr}
          required
          onChange={onChange}
          helperText = {isNumberValid ? "" :t("form.phone_details_form.uncorrect_phone_number")}
          helperTextStyle = {UNIQUE_ERROR_TEXT.helperTextStyle}
        />
      </Grid>
    </Grid>
  );
};

PhoneDetailsForm.propTypes = {
  label: PropTypes.string,
  isNumberValid : PropTypes.bool,
  onChange: PropTypes.func,
};

PhoneDetailsForm.defaultProps = {
  isNumberValid : true
};

export default PhoneDetailsForm;
