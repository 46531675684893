import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import AutocompleteField from "../../field/AutocompleteField";
import FloatField from "../../base/FloatField/FloatField";
import TextFieldFormControl from "../../field/TextFieldFormControl";

const BufferDetailsForm = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
    <Grid item xs={3.5}>
      <AutocompleteField
        name={"part"}
        label={t("form.buffer_details_form.part")}
        value={props.bufferData?.part}
        options={props.filteringData?.value?.parts}
        isObjectOption={true}
        optionLabelKey={"part_name"}
        onChange={props.onChangeAutocompleteFieldWithObjectOptions}
        addNewValue={false}
        multiple={false}
        disabled={props.bufferId !== undefined}
        required
      />
    </Grid>
      <Grid item xs={3.5}>
        <AutocompleteField
          name={"warehouse"}
          label={t("form.buffer_details_form.warehouse")}
          value={props.bufferData?.warehouse}
          options={props.filteringData?.value?.warehouses}
          isObjectOption={true}
          optionLabelKey={"warehouse_name"}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          addNewValue={false}
          multiple={false}
          disabled={props.bufferId}
          required
        />
      </Grid>
      <Grid item xs={2}>
        <FloatField
          name="buffer_amount"
          readOnly={props.readOnly}
          value={props.bufferData?.buffer_amount}
          label={t("form.buffer_details_form.buffer_amount")}
          onChange={props.onChange}
          required
        />
      </Grid>
      <Grid item xs={2}>
        <FloatField
          name="stock_amount"
          disabled={true}
          value={props.bufferData?.stock_amount}
          label={t("form.buffer_details_form.stock_amount")}
        />
      </Grid>
      <Grid item xs={1}>
        <TextFieldFormControl
          disabled={true}
          value={props.bufferData?.part_unit}
          label={t("form.buffer_details_form.part_unit")}
        />
      </Grid>
    </Grid>
  );
};

BufferDetailsForm.propTypes = {
  bufferData: PropTypes.object,
  readOnly: PropTypes.bool,
  filteringData: PropTypes.object,
  warehousePartSelectList: PropTypes.array,
};

BufferDetailsForm.defaultProps = {
  readOnly: false,
  filteringData: { value: [] },
  warehousePartSelectList: [],
};

export default BufferDetailsForm;
