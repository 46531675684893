import { useEffect, useRef } from "react";

// Check if this work fine localy and on the server
// Otherwise remove strictMode and use normal useEffect
const useEffectOnce = (callback, deps = []) => {
  const effectRun = useRef(false);
  useEffect(() => {
    if (effectRun.current === true || process.env.NODE_ENV !== "development") {
      callback();
    }

    return () => (effectRun.current = true);
    // eslint-disable-next-line
  }, deps);
};

export default useEffectOnce;
