import { useTranslation } from "react-i18next";

import TabsNavigationBar from "../../TabsNavigationBar";

function DunReportsNavigationPeopleSubBar(props) {
  const { t } = useTranslation();
  return (
    <TabsNavigationBar
      pages={[
        {
          label: t("headers.tickets_per_k"),
          link: "/tickets_by_ticket_k_report/",
        },
        {
          label: t("headers.orders_per_kt"),
          link: "/orders_by_order_kt_report/",
        },
        {
          label: t("headers.orders_per_tb"),
          link: "/orders_by_order_t_report/",
        },
        {
          label: t("headers.orders_per_skill"),
          link: "/orders_by_skill_report/",
        },
        {
          label: t("headers.tickets_per_response_time"),
          link: "/tickets_by_response_time_report/",
        },
      ]}
    />
  );
}

DunReportsNavigationPeopleSubBar.propTypes = {};

export default DunReportsNavigationPeopleSubBar;
