import OrdersSaftyReportPage from "../OrdersSaftyReportPage";

const CHART_DESCRIPTION = (
  <div>
    Wykres pokazuje liczbę unikalnych zleceń z filtracją po osobach
    odpowiedzialnych oraz w podziale na 3 grupy: <br />
    <b>1. Zielone</b> – zlecenia zrealizowane w standardowym czasie
    <br />
    <b>2. Żółte</b>– zlecenia zrealizowane do 2 dni od standardowego czasu
    <br />
    <b>3. Czerwone</b> – zlecenia zrealizowane powyżej 2 dni po czasie
    standardowym dla danego zlecenia <br />
    <br />W tabeli pod wykresem znajdują się 3 dodatkowe kolumny:
    <br />
    <br />
    <b>1. SLA</b> – zakładany czas realizacji zgłoszenia (na podstawie
    standardowego czasu realizacji, zaokrąglonego do pełnego dnia).
    <br />
    <b>2. Czas realizacji</b> – faktyczny czas od założenia ticketu do
    zamknięcia, zaokrąglenie do pełnego dnia <br />
    <b>3. Przekroczenie SLA</b> – różnica między czasem od utworzenia do
    zamknięcia ticketu, a wskaźnikiem SLA, zaokrąglenie do pełnego dnia <br />
  </div>
);

export default function OrdersBySlaReportPage(props) {
  return (
    <OrdersSaftyReportPage
      pageName={props.pageName}
      pageTitleKey={"orders_by_sla_report"}
      groupBy={"sla"}
      labelBy={"order_kt"}
      showSelectOrderKt={true}
      showSelectTenant={false}
      showSelectTicketCategory={false}
      showChartInformationDialog={true}
      chartDescrtiption={CHART_DESCRIPTION}
    />
  );
}
