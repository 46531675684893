import jwtDecode from "jwt-decode";
import i18n from "../i18n";
import {
  FILTER_SEPARATOR,
  FORMAT_NUMBER_SEPARATOR,
  APP_STAGE,
} from "./constants";
import moment from "moment";

export function title(string) {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isJWT(token) {
  const parts = token.split(".");
  return parts.length === 3 && parts.every((part) => part.length > 0);
}

export const validToken = (token) => {
  return token && isJWT(token) && jwtDecode(token).exp > Date.now() / 1000;
};

export function setUrlParamsFromMultiChoiceFilter(paramName, choiceList) {
  if (choiceList.length == 0) return "";
  return `${paramName}__in=${choiceList.join(",")}`;
}

export function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function prepareUrlWithQueryParams(url, queryParams) {
  if (queryParams) {
    var queryString = "";
    if (
      queryParams instanceof URLSearchParams ||
      typeof queryParams === "string"
    ) {
      queryString = queryParams.toString();
    } else {
      queryString = Object.keys(queryParams)
        .map((key) => {
          let value = queryParams[key];
          if (Array.isArray(value)) {
            return value
              .map((arr_val) => {
                if (typeof arr_val === "string") {
                  // "&" is a special char in query_params
                  arr_val = arr_val.replace("&", "%26");
                }
                return `${key}=${arr_val}`;
              })
              .join("&");
          }
          if (typeof value === "string") {
            // "&" is a special char in query_params
            value = value.replace("&", "%26");
          }
          return key + "=" + value;
        })
        .join("&");
    }
    if (queryString) {
      url = `${url}?${queryString}`;
    }
  }
  return url;
}

export function prepareQueryParamsFromObject(paramsObject) {
  return Object.keys(paramsObject)
    .map((key) => key + "=" + paramsObject[key])
    .join("&");
}

export function getFieldsFromObject(obj, fieldsNamesArray) {
  const filteredObj = Object.entries(obj).reduce((acc, [key, value]) => {
    if (fieldsNamesArray.includes(key)) {
      acc[key] = !isEmptyValue(value) ? value : null;
    }
    return acc;
  }, {});

  return filteredObj;
}

export function getObjectWithoutKeys(obj, excludedFieldsNamesArray) {
  const filteredObj = Object.entries(obj).reduce((acc, [key, value]) => {
    if (!excludedFieldsNamesArray.includes(key)) {
      acc[key] = value ? value : null;
    }
    return acc;
  }, {});
  return filteredObj;
}

export function checkIfIsUndefinedOrNullOrEmpty(value) {
  return [undefined, null, ""].includes(value);
}

export function getFieldValueFromSearchParams(searchParams, key) {
  const value = searchParams.getAll(key);
  return value.length > 1 ? value : value[0];
}

export function getObjectFromSearchParams(searchParams, exculdedParams) {
  const searchParamsObject = {};
  for (let key of searchParams.keys()) {
    if (exculdedParams && exculdedParams.includes(key)) continue;
    searchParamsObject[key] = getFieldValueFromSearchParams(searchParams, key);
  }
  return searchParamsObject;
}

export function getQueryStringFromSearchParams(searchParams, exculdedParams) {
  let queryString = "";

  for (const [key, value] of Object.entries(
    getObjectFromSearchParams(searchParams)
  )) {
    if (exculdedParams && exculdedParams.includes(key)) continue;
    if (Array.isArray(value)) {
      for (let arr_val of value) {
        queryString += `&${key}=${arr_val}`;
      }
    } else queryString += `&${key}=${value}`;
  }

  return queryString.length > 0 ? queryString.slice(1) : "";
}

export function getUnitLabel(unitName, sup = undefined, withBracket = true) {
  if (sup !== undefined) {
    return (
      <>
        {withBracket ? "[" : null}
        {unitName}
        <sup>{sup}</sup>
        {withBracket ? "]" : null}
      </>
    );
  } else {
    return (
      <>
        {withBracket ? "[" : null}
        {unitName}
        {withBracket ? "]" : null}
      </>
    );
  }
}

export const convertFlatListFromBackendToTranslatedOptionObject = (
  valueList,
  optionKey = "id",
  optionLabelKey = "name"
) => {
  if (!valueList) return [];
  return valueList.map((value) => ({
    [optionKey]: value,
    [optionLabelKey]: i18n.t(`backend_choices_list.${value}`),
  }));
};

export const convertObjectListFromBackendToTranslatedOptionObject = (
  valueList,
  optionLabelKey = "name"
) => {
  if (!valueList) return [];
  return valueList.map((value) => ({
    ...value,
    [optionLabelKey]: i18n.t(`backend_choices_list.${value[optionLabelKey]}`),
  }));
};

export function convertBoolenValueToZero(value) {
  return value ? 1 : 0;
}

export function convertUndefinedValueToZero(value) {
  return !isEmptyValue(value) ? value : 0;
}

export function convertEmptyValueToZero(value) {
  return !isEmptyValue(value) ? value : 0;
}

export function convertEmptyValueOrMinusCharToZero(value) {
  return !isEmptyValue(value) && value !== "-" ? value : 0;
}

export function getCurrentDate() {
  return new Date().toISOString().slice(0, 10);
}

export function isEmptyValue(value) {
  return [null, undefined, ""].includes(value);
}

export function isEmptyArray(arr) {
  return arr.length === 0;
}

export function isEmptyObject(object) {
  return Object.keys(object).length === 0;
}

export function roundToTwoPlaces(value) {
  return Math.round(value * 100) / 100;
}

export function roundPrice(price) {
  return roundToTwoPlaces(price);
}

export function roundToInteger(value) {
  return Math.ceil(value);
}

export function convertPlnValueToOtherCurrency(plnValue, currencyValue) {
  return roundPrice(plnValue / currencyValue);
}

export function convertOtherCurrencyToPlnValue(
  otherCurrencyValue,
  currencyValue
) {
  return roundPrice(otherCurrencyValue * currencyValue);
}

export function getNumberOfActiveFilters(
  searchParams,
  isURLSearchParams = false
) {
  const searchParamsKeys = Object.keys(
    isURLSearchParams ? getObjectFromSearchParams(searchParams) : searchParams
  );
  let count = searchParamsKeys.length;
  if (searchParamsKeys.includes("page")) {
    count -= 1;
  }
  return Math.max(count, 0);
}

export function round(number, decimalPlaces) {
  if (!isEmptyValue(number) && !isNaN(number)) {
    return +(Math.round(number + `e+${decimalPlaces}`) + `e-${decimalPlaces}`);
  }
  return number;
}

export function formatNumber(number, separator = " ") {
  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  return parts.join(".");
}

export function unformatNumber(formattedNumber) {
  const unformattedString = formattedNumber.replace(/\s/g, "");
  return unformattedString;
}

export function roundAndFormatNumber(
  number,
  decimalPlaces = 2,
  separator = FORMAT_NUMBER_SEPARATOR
) {
  if (!isEmptyValue(number)) {
    number = formatNumber(round(number, decimalPlaces), separator);
  }
  return number;
}

export function countPercent(
  nominator,
  denominator,
  roundPercentToTwoPlaces = true,
  addPercentChar = false
) {
  if (denominator !== undefined) {
    var percent = (nominator / denominator) * 100;
    if (roundPercentToTwoPlaces) {
      percent = roundToTwoPlaces(percent);
    }
    if (addPercentChar) {
      percent = `${percent}%`;
    }
    return percent;
  }
  return "-";
}

export function checkIfValueIsObject(value) {
  return typeof value === "object" && value !== null;
}

export function convertEmptyValueToNull(value) {
  return !isEmptyValue(value) ? value : null;
}

export function returnEmptyStringWhenIsUndefined(value) {
  if ([undefined, null].includes(value)) {
    return "";
  }
  return value;
}
export function getMaxFlotWhenIsUndefined(value) {
  if (value) {
    return value;
  }
  return Number.MAX_VALUE;
}

export function getMinFlotWhenIsUndefined(value) {
  if (value) {
    return value;
  }
  return Number.MIN_VALUE;
}

export function convertStringBoolValueToBool(value) {
  if (!["true", "false"].includes(value)) return value;
  return value === "true" ? true : false;
}

export function isObjectEmpty(_object) {
  return Object.keys(_object).length === 0;
}

export function isSearchParamsEmpty(searchParams, pageSizeKey = "page_size") {
  return (
    searchParams.size === 0 ||
    ![...searchParams.entries()].some(([key, val]) => {
      return !key.includes(pageSizeKey) && !isEmptyValue(val);
    })
  );
}

export function isSearchParamsForFilteringEmpty(
  searchParams,
  pageKey = "page",
  pageSizeKey = "page_size",
  orderKey = "ordering"
) {
  return (
    searchParams.size === 0 ||
    ![...searchParams.entries()].some(([key, val]) => {
      return (
        ![pageKey, pageSizeKey, orderKey].includes(key) && !isEmptyValue(val)
      );
    })
  );
}

export function removeElementsInArrayByIndex(array, index) {
  if (index < 0 || index >= array.length) {
    console.error("Bad index");
    return;
  }
  array.splice(index + 1, array.length - index);
  return array;
}

export function getKeysFromFilterDict(array) {
  return array.map((el) => {
    return el.key;
  });
}

export function getInitSelectedKeyFromDict(array) {
  let keys = [];
  for (let dict of array) {
    if (dict.selectedDefault) {
      keys.push(dict.key);
    }
  }
  return keys;
}

export function getExposureTypesList() {
  return [
    { key: "internal", name: i18n.t("internal") },
    { key: "external", name: i18n.t("external") },
  ];
}

export function getVatRates() {
  return [5, 8, 23];
}

function isObject(val) {
  if (val === null) {
    return false;
  }
  return typeof val === "object";
}

export function isArraysEquals(arr1, arr2) {
  return arr1.length === arr2.length && arr1.every((v, i) => arr2[i] === v);
}

export function isDictsAreEqual(dict1, dict2, excludedKeys) {
  if (excludedKeys) {
    dict1 = getObjectWithoutKeys(dict1, excludedKeys);
    dict2 = getObjectWithoutKeys(dict2, excludedKeys);
  }

  if (Object.keys(dict1).length !== Object.keys(dict2).length) {
    return false;
  }

  for (const key in dict1) {
    if (typeof dict1[key] !== typeof dict2[key]) {
      return false;
    }

    if (Array.isArray(dict1[key]) && Array.isArray(dict1[key])) {
      if (!isArraysEquals(dict1[key], dict2[key])) {
        return false;
      } else {
        continue;
      }
    }

    if (isObject(dict1[key]) && isObject(dict2[key])) {
      if (!isDictsAreEqual(dict1[key], dict2[key])) {
        return false;
      } else {
        continue;
      }
    }

    if (dict1[key] !== dict2[key]) {
      return false;
    }
  }

  return true;
}

export function getFilterSearchParamsKey(filterPrefix) {
  if (!isEmptyValue(filterPrefix)) {
    return `${filterPrefix}${FILTER_SEPARATOR}`;
  }
  return "";
}

export function getFilterSearchParamsKeyForField(fieldKey, filterPrefix) {
  return `${getFilterSearchParamsKey(filterPrefix)}${fieldKey}`;
}

export function clearSearchParamsByFilterPrefixFn(
  searchParams,
  filterPrefix = "",
  excludedKeys = [],
  excludePageSize = true
) {
  let clearedParams = {};
  const filterKey = getFilterSearchParamsKey(filterPrefix);
  let excludedFilterKeys = [];
  if (excludePageSize) {
    excludedFilterKeys.push("page_size");
  }
  excludedFilterKeys = [excludedFilterKeys, ...excludedKeys].map((key) =>
    getFilterSearchParamsKeyForField(key, filterPrefix)
  );

  searchParams.forEach((value, key) => {
    if (
      (!isEmptyValue(filterPrefix) && !key.includes(filterKey)) ||
      excludedFilterKeys.includes(key)
    ) {
      clearedParams[key] = getFieldValueFromSearchParams(searchParams, key);
    }
  });
  return clearedParams;
}

export function clearSearchParamsByFilterPrefixesFn(
  searchParams,
  filterPrefixes = [],
  excludedKeys = []
) {
  let clearedParams = {};
  const filterKeys = filterPrefixes.map((prefix) =>
    getFilterSearchParamsKey(prefix)
  );

  const excludedFilterKeys = ["page_size", ...excludedKeys]
    .map((key) =>
      filterPrefixes.map((filterKey) =>
        getFilterSearchParamsKeyForField(key, filterKey)
      )
    )
    .flat();

  searchParams.forEach((value, key) => {
    if (
      filterKeys.every((filterKey) => !key.includes(filterKey)) ||
      excludedFilterKeys.includes(key)
    ) {
      clearedParams[key] = getFieldValueFromSearchParams(searchParams, key);
    }
  });
  return clearedParams;
}

export function convertMomentInstanceToStringDatetimeInUtc(momentInstance) {
  momentInstance = moment(momentInstance);
  momentInstance.utc();
  return momentInstance.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
}

export function getTimeInHHMMFormatFromMinutes(mins) {
  if (mins < 0) {
    throw new RangeError("Valid input should be greater than or equal to 0");
  }
  let h = (mins / 60) | 0;
  let m = mins % 60 | 0;
  return `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}`;
}

export function findAndDeleteObjectFromArray(
  array,
  valueToFind,
  fieldKey = "id"
) {
  const index = array.findIndex(function (x) {
    return x[fieldKey] === valueToFind;
  });
  if (index !== -1) {
    array.splice(index, 1);
  }
  return {
    array: array,
    deleted: index !== -1,
  };
}

export function getFilterFieldKeyByNameAndPrefix(fieldName, prefix) {
  if (prefix) {
    return `${getFilterSearchParamsKey(prefix)}${fieldName}`;
  }
  return fieldName;
}

export function getDefaultFilterValues(defaultFilter) {
  let filterValuesTemp = {};
  for (const filter of defaultFilter) {
    for (const [key, value] of Object.entries(filter.filter_value)) {
      filterValuesTemp[
        getFilterFieldKeyByNameAndPrefix(key, filter.filter_prefix)
      ] = value;
    }
  }
  return filterValuesTemp;
}

export function getDefaultFilterValuesFromDefaultAndUserFilters(
  defaultFilters,
  userFilters
) {
  let filterValuesTemp = getDefaultFilterValues(userFilters);

  const userFilterPrefixes = userFilters.map((f) => f.filter_prefix);
  for (const filter of defaultFilters) {
    if (
      userFilterPrefixes.includes(filter.filter_prefix) ||
      !filter.filter_prefix
    ) {
      continue;
    }

    for (const [key, value] of Object.entries(filter.filter_value)) {
      filterValuesTemp[
        getFilterFieldKeyByNameAndPrefix(key, filter.filter_prefix)
      ] = value;
    }
  }
  return filterValuesTemp;
}

export function blendHexWithOpacity(hexColor, opacity) {
  // check correct opacity
  if (opacity < 0 || opacity > 1) {
    console.error("Bad value of opacity. Value must be between 0 a 1.");
    return null;
  }
  // Check correct format of hexColor
  const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
  if (!hexRegex.test(hexColor)) {
    console.error("Bad format of color hex.");
    return null;
  }

  hexColor = hexColor.length === 4 ? expandHex(hexColor) : hexColor;

  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  const newR = Math.round(r + (255 - r) * (1 - opacity));
  const newG = Math.round(g + (255 - g) * (1 - opacity));
  const newB = Math.round(b + (255 - b) * (1 - opacity));

  const newHexColor = `#${componentToHex(newR)}${componentToHex(
    newG
  )}${componentToHex(newB)}`;

  return newHexColor;
}

function expandHex(hex) {
  return `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
}

function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

export function convertRGBToRGBWithOpacity(rgb, opacity) {
  if (opacity < 0 || opacity > 1) {
    console.error("Bad value of opacity. Value must be between 0 a 1.");
    return null;
  }
  rgb = rgb.replace("rgb(", "").replace(")", "");
  const rgbColors = rgb.split(", ");
  const r = rgbColors[0];
  const g = rgbColors[1];
  const b = rgbColors[2];
  const newR = Math.round(r * (1 - opacity) + 255 * opacity);
  const newG = Math.round(g * (1 - opacity) + 255 * opacity);
  const newB = Math.round(b * (1 - opacity) + 255 * opacity);
  return `rgb(${newR}, ${newG}, ${newB})`;
}

export function getObjectKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const getErrorMsg = (
  errorData,
  defaultErrorMsg = i18n.t(`snackbar_alert.occurred_unknown_error`)
) => {
  let errorCode = errorData.error_code;
  if (!errorCode) {
    return defaultErrorMsg;
  }

  const tags = [...errorCode.matchAll("\\[.*?\\]")];
  // replace tags with indexed placeholders
  if (tags) {
    for (const [index, tag] of tags.entries()) {
      errorCode = errorCode.replace(tag, `\$${index}`);
    }
  }

  let errorMsg = i18n.t(`snackbar_alert.${errorCode}`);
  // error_code not found in dict
  if (errorMsg.includes("snackbar_alert")) {
    return defaultErrorMsg;
  }

  // reverse replace indexed placeholders with tags
  if (tags) {
    for (const [index, tag] of tags.entries()) {
      errorMsg = errorMsg.replace(
        `\$${index}`,
        tag[0].substring(1, tag[0].length - 1)
      );
    }
  }

  return errorMsg;
};

export const isSearchParamsObjectEmpty = (
  object,
  {
    pagekey = "page",
    pageSizeKey = "page_size",
    orderKey = "ordering",
    excludedKeys = [],
  } = {}
) => {
  return isObjectEmpty(
    Object.entries(object).filter(
      ([key, val]) =>
        ![
          `${pagekey}`,
          `${pageSizeKey}`,
          `${orderKey}`,
          ...excludedKeys,
        ].includes(key)
    )
  );
};

export const getRandomString = () => {
  return (Math.random() + 1).toString(36).substring(2);
};

export const isAppTesting = () => {
  return ![APP_STAGE.PROD].includes(
    window._env_.APP_STAGE.toString().toLowerCase()
  );
};

export const getSortedObjectKeysValuesByKey = (object, sortedFn) => {
  let keys = Object.keys(object).sort(sortedFn);
  let values = [];

  for (const key of keys) {
    values.push(object[key]);
  }

  return { keys, values };
};

export const getSearchParamsObjectForFilterPrefix = (
  _object,
  filterPrefix = ""
) =>
  Object.fromEntries(
    Object.entries(_object).map(([k, v]) => [
      getFilterFieldKeyByNameAndPrefix(k, filterPrefix),
      v,
    ])
  );
export const convertOrderArrayToOrderObject = (orderArray) => {
  let orderObject = {};
  for (let key of orderArray) {
    orderObject[key] = orderArray.indexOf(key);
  }
  return orderObject;
};

export const sortArrayOfObjectsByOrderArray = (
  arrayOfObjects,
  orderArray,
  key = "name"
) => {
  if (!orderArray) return arrayOfObjects;

  const orderObject = convertOrderArrayToOrderObject(orderArray);

  let resArrayOfObjects = [...arrayOfObjects];

  resArrayOfObjects.sort((a, b) => {
    let aName = a[key];
    let bName = b[key];

    if (orderObject[aName] === undefined && orderObject[bName] === undefined)
      return 0;

    if (orderObject[bName] === undefined) return -1;
    if (orderObject[aName] === undefined) return 1;

    if (orderObject[aName] <= orderObject[bName]) return -1;

    return 1;
  });

  return resArrayOfObjects;
};

export function generateRandomString(len) {
  let s = "";
  while (s.length < len)
    s += Math.random()
      .toString(36)
      .substr(2, len - s.length);
  return s;
}

export function getTranslatedList(list, key) {
  let translatedList = [];
  if (list) {
    for (let transaction of list) {
      translatedList.push({
        id: transaction,
        name: i18n.t(`${key}${transaction}`),
      });
    }
  }
  return translatedList;
}


export function sortedIemByNumers(itemList) {
  return itemList.sort((a, b) => {
    const aParts = a.item_number.split(".").map(Number);
    const bParts = b.item_number.split(".").map(Number);

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aValue = aParts[i] || 0;
      const bValue = bParts[i] || 0;
      if (aValue !== bValue) {
        return aValue - bValue;
      }
    }
    return 0;
  });
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isRangesOverlapping(x1, x2, y1, y2) {
  return (
    (x1 === y1 && x2 === y2) ||
    (x1 > y1 && x1 < y2) ||
    (x2 > y1 && x2 < y2) ||
    (y1 > x1 && y1 < x2) ||
    (y2 > x1 && y2 < x2)
  );
}

export function removeKeysFromObject(obj, keysToRemove) {
  keysToRemove.forEach(key => {
      if (key in obj) {
          delete obj[key];
      }
  });
  return obj;
}