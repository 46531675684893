import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { isEmptyValue } from "../../../helpers/methods";
import useMailService from "../../../services/mailService";
import MailInputForm from "../../form/MailInputForm";


const MailDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [isMailFormatValid, setIsMailFormatValid] = useState(true)

  const requiredFields = [
    "mail_alias",
  ];

  const mailFormatRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;


  const {
    formValue,
    setFormValue,
    onChange,
  } = useForm({});

  const {
    getMailDetails,
    createMail,
    updateMail } = useMailService();

  const updateMailFn = useAsyncFn(updateMail)
  const createMailFn = useAsyncFn(createMail)

  const mailDetailsData = useAsync(
    () => getMailDetails(props.mailId),
    [props.mailId]
  );


  useEffect(() => {
    if (mailDetailsData.loading) {
      return;
    }
    setFormValue(mailDetailsData.value);
  }, [mailDetailsData.loading]);


  useEffect(() => {
    if (formValue.mail_alias){
      setIsMailFormatValid(validateMailFormat(formValue.mail_alias))
    }else{
      setIsMailFormatValid(true)
    }
    
  }, [formValue]);

  const prepareNewMailData = () => {
    let tempMail = formValue
    tempMail['owner'] = props.ownerId
    tempMail['subcontractor'] = props.subcontractorId
    return tempMail
  }


  const onSubmit = useCallback(() => {
    if (props.mailId) {
      updateMailFn
        .execute(props.mailId, formValue)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.mail_updated")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_mail_updating")
          );
        });
    } else {
      if(props.ownerId || props.subcontractorId){
      createMailFn
        .execute(prepareNewMailData())
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.mail_added")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_mail_adding")
          );
        });} else{
          props.onAddMailForNewOwner(formValue)
          props.onClose();
        }
    }

  }, [formValue]);

  const isValid = formValue ? requiredFields.every(
    (fieldName) => !isEmptyValue(formValue[fieldName])
  ) && isMailFormatValid: false

  const validateMailFormat = (mailAlias) =>{
    return mailFormatRegex.test(mailAlias);
  }

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.mailId ? t(`dialog.mail_details_dialog.edit_mail`) : t(`dialog.mail_details_dialog.create_mail`)}
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        {mailDetailsData.loading  ?
          <LoaderWrapper isLoading={true} />
          :
          <>
            <Grid item xs={12}>
              <MailInputForm
                mail={formValue}
                isMailValid= {isMailFormatValid}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={updateMailFn.loading}
                disabled={!isValid}
                onClick={onSubmit}
              >
                {props.mailId ? t("save") : t("create")}
              </LoadingButton>
            </Grid>
          </>
        }
      </Grid>
    </BasicDialog>
  );
};

MailDialog.propTypes = {
  ownerId: PropTypes.string,
  mailId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

MailDialog.defaultProps = {
  open: false,
};

export default MailDialog;
