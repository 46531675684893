import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import SelectField from "../../field/SelectField";
import DateField from "../../field/DateField/DateField";
import AutocompleteField from "../../field/AutocompleteField";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";
import MaskedTextFieldForm from "../../base/MaskedTextFieldForm";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

const AgreementDetailsForm = (props) => {
  const { t } = useTranslation();


  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      {props.agreementId &&
        <Grid item xs={6}>
          <TextFieldFormControl
            name="agreement_nr"
            readOnly={true}
            value={props.agreementData?.agreement_nr}
            label={t("form.agreements_details_form.agreement_nr")}
            onChange={props.onChange}
          />
        </Grid>}
      <Grid item xs={6}>
        <TextFieldFormControl
          name="agreement_title"
          readOnly={props.readOnly}
          required
          value={props.agreementData?.agreement_title}
          label={t("form.agreements_details_form.agreement_title")}
          onChange={props.onChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormControl
          name="agreement_bluedew_nr"
          readOnly={props.readOnly}
          value={props.agreementData?.agreement_bluedew_nr}
          label={t("form.agreements_details_form.agreement_bluedew_nr")}
          onChange={props.onChange}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteField
          name={"owner"}
          label={t("form.agreements_details_form.agreement_owner")}
          value={props.agreementData?.owner}
          options={props.filteringData?.value?.owner}
          isObjectOption={true}
          optionLabelKey={"owner_short_name"}
          onChange={props.onChangeOwner}
          addNewValue={false}
          multiple={true}
          required
        />
      </Grid>
      <Grid item xs={1.5}>
          <TrueFalseSelectField
            name="agreement_is_tenant"
            readOnly={props.readOnly}
            valuesAsBool
            value={props.agreementData?.agreement_is_tenant}
            label={t("form.agreements_details_form.is_tenant")}
            onChange={props.onChange}
            addEmptyOptions={false}
          />
        </Grid>
      <Grid item xs={4.5}>
        { props.agreementData?.agreement_is_tenant ?
        <AutocompleteField
          name={"tenant"}
          label={t("form.agreements_details_form.agreement_tenant")}
          value={props.agreementData?.tenant}
          options={props.filteringData?.value?.tenant}
          isObjectOption={true}
          optionLabelKey={"tenant_short_name"}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          addNewValue={false}
          multiple={true}
          required
        />:
        <TextFieldFormControl
        name="agreement_individual_person"
        readOnly={props.readOnly}
        value={props.agreementData?.agreement_individual_person}
        label={t("form.agreements_details_form.agreement_individual_person")}
        onChange={props.onChange}
        required
      />
        }
      </Grid>

      <Grid item xs={6}>
        <AutocompleteField
          name={"agreement_kind"}
          label={t("form.agreements_details_form.agreement_kind")}
          value={props.agreementData?.agreement_kind}
          options={props.filteringData?.value?.agreement_kind}
          isObjectOption={true}
          optionLabelKey={"agki_category"}
          onChange={props.onChangeAgreementKind}
          addNewValue={false}
          multiple={false}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteField
          name={"agreement_subkind"}
          label={t("form.agreements_details_form.agreement_subkind")}
          value={props.agreementData?.agreement_subkind}
          options={props.agreementSubkindSelectList}
          isObjectOption={true}
          optionLabelKey={"agsu_subcategory"}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          addNewValue={false}
          multiple={false}
          required
        />
      </Grid>

      <Grid item xs={6}>
        <SelectField
          label={t("form.agreements_details_form.agreement_sign_form")}
          name="agreement_sign_form"
          value={props.agreementData?.agreement_sign_form}
          options={props.filteringData?.value?.agreement_sign_form}
          isObjectOption={false}
          onChange={props.onChange}
          readOnly={props.readOnly}
        />
      </Grid>
      <Grid item xs={2}>
        <SelectField
          label={t("form.agreements_details_form.prefix")}
          name="agreement_location_prefix"
          value={props.agreementData?.agreement_location_prefix}
          options={props.filteringData?.value?.location_prefixes}
          isObjectOption={false}
          onChange={props.onChange}
          readOnly={props.readOnly}
          required
        />
      </Grid>
      <Grid item xs={2}>
        {props.isExistLocationForm ?

          <SelectField
            label={t("form.agreements_details_form.agreement_location_binder")}
            name="agreement_location_binder"
            value={props.agreementData?.agreement_location_binder}
            options={props.locationBindersByOwner}
            isObjectOption={false}
            onChange={props.onChange}
            readOnly={props.readOnly}
            required
          />
          :
          <MaskedTextFieldForm
            mask={"****"}
            onChange={props.onChange}
            disabled={props.readOnly}
            value={props.agreementData?.agreement_location_binder}
            maskChar={"_"}
            name="agreement_location_binder"
            label={t("form.agreements_details_form.agreement_location_binder")}
            required
          />
        }
      </Grid>
      <Grid item xs={1.5}>
        <MaskedTextFieldForm
          mask={"999a"}
          onChange={props.onChange}
          disabled={props.readOnly}
          value={props.agreementData?.agreement_location_number}
          maskChar={"_"}
          name="agreement_location_number"
          label={t("form.agreements_details_form.agreement_location_number")}
          showHelperText={!props.isLocationFormatValid}
          FormHelperTextProps={{ sx: { color: 'red' } }}
          helperText={t("form.agreements_details_form.uncorrect_location_format")}
          required
        />
      </Grid>
      <Grid item xs={0.5}>
        {props.isExistLocationForm ?
        <IconButtonWithTooltip
          title={t("form.agreements_details_form.add_binder")}
          onClick={props.onAddNewLocation}
        >
          <AddCircleOutlineOutlined color="primary" />
        </IconButtonWithTooltip>
        :
        <IconButtonWithTooltip
          title={t("form.agreements_details_form.cancel")}
          onClick={props.onCancelAddNewLocation}
        >
          <DoDisturbIcon color="primary" />
        </IconButtonWithTooltip>
        }
      </Grid>

      <Grid item xs={6}>
        <AutocompleteField
          label={t("form.agreements_details_form.agreement_parent")}
          name="parent"
          value={props.agreementData?.parent}
          options={props.agreementParentsList}
          isObjectOption={true}
          optionLabelKey={"agreement_descriptive_name"}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          readOnly={props.readOnly}
          addNewValue={false}
        />
      </Grid>
      {props.agreementId &&
        <Grid item xs={6}>
          <TrueFalseSelectField
            name="agreement_active"
            readOnly={props.readOnly}
            valuesAsBool
            value={props.agreementData?.agreement_active}
            label={t("form.agreements_details_form.agreement_active")}
            onChange={props.onChange}
            addEmptyOptions={false}
          />
        </Grid>}
      <Grid item xs={6}>
        <DateField
          name="agreement_date_sign"
          label={t("form.agreements_details_form.agreement_date_sign")}
          value={props.agreementData?.agreement_date_sign}
          readOnly={props.readOnly}
          onChange={props.onChangeDate}
          required
        />
      </Grid>
    </Grid>
  );
};

AgreementDetailsForm.propTypes = {
  agreementData: PropTypes.object,
  readOnly: PropTypes.bool,
  filteringData: PropTypes.object,
  agreementSubkindSelectList: PropTypes.array,
  agreementParentsList: PropTypes.array,
  onChangeOwner: PropTypes.func,
  onAddNewLocation: PropTypes.func,
  isExistLocationForm: PropTypes.bool,
  locationBindersByOwner: PropTypes.bool,
  onCancelAddNewLocation : PropTypes.func
};

AgreementDetailsForm.defaultProps = {
  readOnly: false,
  filteringData: { value: [] },
  valueagreementSubkindSelectList: [],
  agreementParentsList: [],
  isExistLocationForm: true,
  locationBindersByOwner: []
};

export default AgreementDetailsForm;
