import { useState, useContext, createContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import axios from "../api/axios";
import useLocalStorage from "../hooks/useLocalStorage";
import { makeRequest } from "../helpers/makeRequest";
import {
  loginUrl,
  logoutUrl,
  refreshTokenUrl,
  aadLoginUrl,
  myOliviaLoginUrl,
} from "../helpers/apiUrls";
import { prepareUrlWithQueryParams } from "../helpers/methods";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken, removeAccessToken] =
    useLocalStorage("accessToken");

  const [user, setUser] = useState(() => {
    if (accessToken) return jwtDecode(accessToken);
  });

  const navigate = useNavigate();
  const location = useLocation();

  const redirectToMyOliviaLoginPage = (email) => {
    const myOliviaLoginPageUrl = window._env_.MY_OLIVIA_LOGIN_PAGE_URL;
    const redirectUrl = `${window.origin}${
      location.state?.from?.pathname || "/"
    }`;
    window.location.replace(
      prepareUrlWithQueryParams(myOliviaLoginPageUrl, {
        email: email,
        redirect: redirectUrl,
      })
    );
  };

  const login = (username, password) => {
    return makeRequest(axios, loginUrl, {
      method: "POST",
      data: { username, password },
    }).then((data) => {
      setAccessToken(data?.access);
      setUser(jwtDecode(data?.access));

      const from = "/";
      navigate(from, { replace: true });
      //instead navige to hide password
      // window.location.replace(from);
    });
  };

  const aadLogin = (accessToken, redirectUrl) => {
    return makeRequest(axios, aadLoginUrl, {
      method: "POST",
      data: { access_token: accessToken },
    }).then((data) => {
      setAccessToken(data?.access);
      setUser(jwtDecode(data?.access));

      const from = redirectUrl || location.state?.from || "/";
      navigate(from, { replace: true });
      //instead navige to hide password
      // window.location.replace(from);
    });
  };

  const myOliviaLogin = (accessToken) => {
    return makeRequest(axios, myOliviaLoginUrl, {
      method: "POST",
      data: { access_token: accessToken },
    })
      .then((data) => {
        setAccessToken(data?.access);
        setUser(jwtDecode(data?.access));
      })
      .catch((err) => {
        removeAccessToken();
        setUser(null);
        return Promise.reject(err);
      });
  };

  const refreshAccess = async () => {
    try {
      const data = await makeRequest(axios, refreshTokenUrl, {
        method: "POST",
        data: {},
      });
      const access = await data.access;
      setAccessToken(access);
      setUser(jwtDecode(access));

      return access;
    } catch {
      removeAccessToken();
      setUser(null);
    }
  };

  const logout = () => {
    return makeRequest(axios, logoutUrl, {
      method: "POST",
      data: {},
    }).then(() => {
      removeAccessToken(null);
      setUser(null);
      // navigate("/login", { replace: true, state: { from: location } });
      navigate("/login");
    });
  };

  const contextData = {
    user,
    accessToken,
    refreshAccess,
    login,
    redirectToMyOliviaLoginPage,
    logout,
    aadLogin,
    myOliviaLogin,
  };
  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
