import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { useAsync } from "../../../hooks/useAsync";

import useTicketService from "../../../services/ticketService";

import LoaderWrapper from "../../wrapper/LoaderWrapper";
import BasicDialog from "../../base/BasicDialog";
import PdfWindow from "../../window/PdfWindow/PdfWindow";

function TicketProtocolDialog(props) {
  const { t } = useTranslation();

  const { getTicketProtocol } = useTicketService();
  const ticketProtocol = useAsync(() => getTicketProtocol(props.ticketId));

  const isLoading = ticketProtocol.loading;

  return (
    <>
      <BasicDialog
        open={props.open}
        onClose={props.onClose}
        titleAlign="center"
        title={t("dialog.ticket_protocol.ticket_protocol")}
        maxWidth="lg"
        showTopActionButton={false}
        showBottomActionButton={true}
        bottomActionStyle={{ padding: "10px" }}
        showCustomFooter={false}
        showDialogActions={false}
      >
        <LoaderWrapper showLoader={isLoading}>
          <PdfWindow url={ticketProtocol.value} />
        </LoaderWrapper>
      </BasicDialog>
    </>
  );
}

TicketProtocolDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ticketId: PropTypes.string,
};

TicketProtocolDialog.defaultProps = {
  open: false,
};

export default TicketProtocolDialog;
