
export default function useThumbnailService() {
const convertToThumbnail = async (inputFile, maxHeight, maxWidth, callback) => {
    const reader = new FileReader();
    
    reader.onload = function(event) {
        const img = new Image();
        img.onload = function() {
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;
            
            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }
            
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            
            canvas.toBlob(function(blob) {
                callback(blob);
            }, 'image/jpeg', 0.9);
        }
        img.src = event.target.result;
    }
    
    reader.readAsDataURL(inputFile);
}
return {
    convertToThumbnail
  };
}
