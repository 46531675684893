import TicketsSaftyReportsPage from "../TicketsSaftyReportsPage";

const CHART_DESCRIPTION = (
  <div>
    Wykres pokazuje liczbę unikalnych zgłoszeń z możliwością filtracji po
    kategorii zgłoszenia, zakresu czasowego oraz wybranych budynków.
    <br />
    <br />W tabeli pod wykresem znajdują się 3 dodatkowe kolumny:
    <br />
    <br />
    <b>1. SLA</b> – zakładany czas realizacji zgłoszenia (na podstawie
    standardowego czasu realizacji, zaokrąglonego do pełnego dnia).
    <br />
    <b>2. Czas realizacji</b> – faktyczny czas od założenia ticketu do
    zamknięcia, zaokrąglenie do pełnego dnia <br />
    <b>3. Przekroczenie SLA</b> – różnica między czasem od utworzenia do
    zamknięcia ticketu, a wskaźnikiem SLA, zaokrąglenie do pełnego dnia <br />
  </div>
);

export default function TicketsPerTimeReportPage(props) {
  return (
    <TicketsSaftyReportsPage
      pageName={props.pageName}
      pageTitleKey={"tickets_per_time_report"}
      groupBy={"ticket_category"}
      labelBy={"time_range"}
      showSelectTimeRange={true}
      showTicketOwner={false}
      showSelectObjectLevel2={false}
      showChartInformationDialog={true}
      chartDescrtiption={CHART_DESCRIPTION}
    />
  );
}
