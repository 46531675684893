import OrdersSaftyReportPage from "../OrdersSaftyReportPage";

const CHART_DESCRIPTION = (
  <div>
    Wykres pokazuje liczbę unikalnych zleceń z możliwością filtracji po
    kategorii zgłoszenia, zakresu czasowego oraz wybranych użytkowników,
    określonych jako odpowiedzialnych za wykonanie zlecenia.
    <br />
    <br />W tabeli pod wykresem znajdują się 3 dodatkowe kolumny:
    <br />
    <br />
    <b>1. SLA</b> – zakładany czas realizacji zgłoszenia (na podstawie
    standardowego czasu realizacji, zaokrąglonego do pełnego dnia).
    <br />
    <b>2. Czas realizacji</b> – faktyczny czas od założenia ticketu do
    zamknięcia, zaokrąglenie do pełnego dnia <br />
    <b>3. Przekroczenie SLA</b> – różnica między czasem od utworzenia do
    zamknięcia ticketu, a wskaźnikiem SLA, zaokrąglenie do pełnego dnia <br />
  </div>
);

export default function OrdersByTicketCategoryReportPage(props) {
  return (
    <OrdersSaftyReportPage
      pageName={props.pageName}
      pageTitleKey={"orders_by_ticket_category_report"}
      groupBy={"ticket_category"}
      labelBy={"order_kt"}
      showSelectOrderKt={true}
      showSelectTenant={true}
      showSelectTicketCategory={true}
      showChartInformationDialog={true}
      chartDescrtiption={CHART_DESCRIPTION}
    />
  );
}
