import { CommunicationFiltersTypes, EXTERNAL_TYPE } from "../helpers/constants";
import useCommunicationService from "../services/communicationService";
import { useAsync, useAsyncFn } from "./useAsync";
import { useState, useEffect, useRef } from "react";
import { useSnackbarAlert } from "../context/snackbarAlert";
import { useTranslation } from "react-i18next";
import { useAuth } from "../context/auth";
import usePermissionService from "../services/permissionService";

const useMessagesData = (itemId, defaultFilters = [], itemType = "ticket") => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const snackbarAlert = useSnackbarAlert();

  const { defaultMessageType, showMessageType, canHideMessages } =
    usePermissionService();

  const defaultMessageForm = {
    message_content: "",
    message_type: defaultMessageType,
  };
  const [localMessages, setLocalMessages] = useState([]);
  const [localMessageForm, setLocalMessageForm] = useState({
    ...defaultMessageForm,
  });

  const [chosenFilterGroup, setFilterChosenGroup] = useState([
    "external",
    "internal",
  ]);

  const [isCorrectedSend, setIsCorrectedSend] = useState(false);
  const [searchParams, setSearchParams] = useState({ exclude_logs: true });

  const { getMessagesForItemData, sendNewMessageInItem, udpateMessageData } =
    useCommunicationService();

  const messagesData = useAsync(
    () => getMessagesForItemData(itemId, searchParams, itemType),
    [searchParams]
  );

  const saveMessageFn = useAsyncFn(sendNewMessageInItem);

  const updateMessageFn = useAsyncFn(udpateMessageData);

  useEffect(() => {
    if (!messagesData.loading) {
      setLocalMessages(messagesData.value);
    }
  }, [messagesData.loading]);

  const prepareMessageToSend = (message) => {
    message["message_sender"] = user.user_id;
    if (itemType === "ticket" || itemType === "ticket_order") {
      message["ticket"] = itemId;
    } else {
      message["order"] = itemId;
    }
    message["message_external"] =
      message.message_type === EXTERNAL_TYPE ? true : false;
    return message;
  };

  const prepareMessageToHide = (messageId) => {
    return {
      id: messageId,
      message_active: false,
    };
  };

  const chooseFilterGroup = (groupName) => {
    setFilterChosenGroup((oldState) => {
      var newState = [...oldState];
      if (newState.includes(groupName)) {
        newState.splice(newState.indexOf(groupName), 1);
      } else {
        newState.push(groupName);
      }
      return newState;
    });
  };

  useEffect(() => {
    let filterArray = defaultFilters.filter(
      (el) => !chosenFilterGroup.includes(el)
    );
    let filters = {};
    for (let [key, value] of Object.entries(CommunicationFiltersTypes)) {
      if (filterArray.includes(value.key)) {
        let object = {};
        let key = value.filterParam;
        object[key] = true;
        filters = Object.assign({}, filters, object);
      }
    }
    setSearchParams({ ...filters });
  }, [chosenFilterGroup]);

  const sendNewMessage = (newMessage) => {
    setIsCorrectedSend(false);
    saveMessageFn
      .execute(itemId, prepareMessageToSend(newMessage), itemType)
      .then((res) => {
        // don't reset filters after submit
        // setFilterChosenGroup([...defaultFilters]);
        setLocalMessages((prev) => [res, ...prev]);
        setIsCorrectedSend(true);
        setLocalMessageForm({ ...defaultMessageForm });
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.message_sent")
        );
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_message_sending")
        );
      });
  };

  const hideMessage = (messageId) => {
    setIsCorrectedSend(false);
    updateMessageFn
      .execute(messageId, prepareMessageToHide(messageId))
      .then((res) => {
        // don't reset filters after submit
        // setFilterChosenGroup([...defaultFilters]);
        // setLocalMessages((prev) => [res, ...prev]);
        setIsCorrectedSend(true);
        setLocalMessageForm({ ...defaultMessageForm });
        messagesData.refetch()
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.message_hidden")
        );
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_message_hiding")
        );
      });
  };

  return {
    messages: localMessages,
    localMessageForm,
    sendNewMessage,
    hideMessage,
    chooseFilterGroup,
    chosenFilterGroup,
    isLoading: messagesData.loading,
    isCorrectedSend,
    showMessageType,
    canHideMessages,
  };
};

export default useMessagesData;
