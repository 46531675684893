import { roleDescriptionDataUrl } from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function useRoleService() {
  const axiosPrivate = useAxiosPrivate();

  const getRoleDescriptionData = () => {
    return makeRequest(axiosPrivate, roleDescriptionDataUrl);
  };

  return { getRoleDescriptionData };
}
