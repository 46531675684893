import { useState, useMemo } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import BasicDialog from "../../base/BasicDialog";

import ObjectCategorySubTable from "./ObjectCategorySubTable";
import ObjectSubcategorySubTable from "./ObjectSubcategorySubTable";

import SelectObjectCategorySubcategoryButton from "../../button/SelectObjectCategorySubcategoryButton";

import {
  getTablePropsByName,
  OBJECT_CATEGORY_TABLE_NAME,
  OBJECT_SUBCATEGORY_TABLE_NAME,
} from "./TableConfig";

const ObjectCategoryTableDialog = (props) => {
  const { t } = useTranslation();

  const [existsChanges, setExistsChanges] = useState(false);

  const [selectedTable, setSelectedTable] = useState(
    OBJECT_CATEGORY_TABLE_NAME
  );

  const tableProps = useMemo(
    () => getTablePropsByName(selectedTable),
    [selectedTable]
  );

  const dialogTitle = useMemo(
    () =>
      selectedTable === OBJECT_CATEGORY_TABLE_NAME
        ? t("dialog.object_category.categories")
        : t("dialog.object_category.subcategories"),
    [selectedTable]
  );

  const getSelectedTable = () => {
    const SelectedSubTable =
      selectedTable === OBJECT_CATEGORY_TABLE_NAME
        ? ObjectCategorySubTable
        : ObjectSubcategorySubTable;

    return (
      <SelectedSubTable
        tableName={tableProps.tableName}
        tableConfig={tableProps.tableConfig}
        tableFilterPrefix={tableProps.tableFilterPrefix}
        setExistsChanges={setExistsChanges}
      />
    );
  };

  const getDialogContent = () => (
    <Grid container spacing={1} justifyContent={"flex-end"}>
      <Grid item xs={4}>
        <SelectObjectCategorySubcategoryButton
          categoryFilterKey={OBJECT_CATEGORY_TABLE_NAME}
          subcategoryFilterKey={OBJECT_SUBCATEGORY_TABLE_NAME}
          selectedFilterKey={selectedTable}
          onChangeFilterValue={setSelectedTable}
        />
      </Grid>
      <Grid item xs={12}>
        {getSelectedTable()}
      </Grid>
    </Grid>
  );

  return (
    <BasicDialog
      open={props.open}
      onClose={() => props.onClose(existsChanges)}
      titleAlign="center"
      maxWidth={"md"}
      title={dialogTitle}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

ObjectCategoryTableDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

ObjectCategoryTableDialog.defaultProps = {};

export default ObjectCategoryTableDialog;
