import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

export default function InformationDialog(props) {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth open={props.open} maxWidth={props.maxWidth}>
      <DialogTitle sx={props.titleSx} align={props.titleAlign}>
        {props.title}
      </DialogTitle>
      {props.showTopCloseButton && (
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {props.content !== undefined && (
        <DialogContent
          dividers={props.showDialogContentDividers}
          align={props.contentTextAlign}
          sx={props.contentSx}
        >
          {props.content}
        </DialogContent>
      )}
      <DialogActions sx={{ justifyContent: "center" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          {props.showDialogActions && (
            <Grid item xs={12} textAlign={"center"} justifyContent={"center"}>
              <Button
                color={props.btnColor}
                style={{ minWidth: "300px" }}
                fullWidth={props.btnFullWidth}
                variant="contained"
                size="small"
                onClick={props.onClose}
                sx={props.btnSx}
              >
                {props.btnLabel || t("close")}
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

InformationDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.any,
  titleAlign: PropTypes.oneOf(["right", "left", "center"]),
  onClose: PropTypes.func,
  showDialogContentDividers: PropTypes.bool,
  contentTextAlign: PropTypes.oneOf(["right", "left", "center"]),
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  titleSx: PropTypes.object,
  contentSx: PropTypes.object,
  btnColor: PropTypes.string,
  btnLabel: PropTypes.string,
  btnFullWidth: PropTypes.bool,
  showTopCloseButton: PropTypes.bool,
  showDialogActions: PropTypes.bool,
};

InformationDialog.defaultProps = {
  open: false,
  titleAlign: "center",
  showDialogContentDividers: true,
  contentTextAlign: "center",
  btnYesColor: "primary",
  btnFullWidth: false,
  showTopCloseButton: false,
  showDialogActions: true,
};
