import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useForm } from "../../../hooks/useForm";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";

import useOrderService from "../../../services/orderService";
import useLocationService from "../../../services/locationService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import { getErrorMsg } from "../../../helpers/methods";

const KT_PREFIX = "KT__";
const T_PREFIX = "T__";
const SKILL_PREFIX = "SKILL__";

const StdOrderAutomatDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const {
    formValue,
    setFormValue,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm();

  const {
    getStdOrderAutomatAdminDetailsData,
    getStdOrderAutomatAdminFilteringData,
    updateStdOrderAutomat,
  } = useOrderService();

  const { getObjectSelectList } = useLocationService();
  const objectsLevel2 = useAsync(() =>
    getObjectSelectList({ object_level: 2 })
  );

  const stdOrderAutomatInitialData = useAsync(() => {
    return getStdOrderAutomatAdminDetailsData(props.stdOrderId);
  }, [props.stdOrderId]);

  const stdOrderAutomatDataToCopyFn = useAsyncFn(() => {
    if (!formValue || !formValue?.std_order_to_copy)
      return Promise.resolve(null);
    return getStdOrderAutomatAdminDetailsData(formValue?.std_order_to_copy);
  }, [formValue?.std_order_to_copy]);

  useEffect(() => {
    if (stdOrderAutomatInitialData.loading) return;

    let tempFormValue = {};
    for (const [key, value] of Object.entries(
      stdOrderAutomatInitialData.value.std_order_automats
    )) {
      tempFormValue[`${KT_PREFIX}${key}`] = value.ordautomat_kt?.id;
      tempFormValue[`${T_PREFIX}${key}`] = value.ordautomat_t?.id;
      tempFormValue[`${SKILL_PREFIX}${key}`] = value.skill?.id;
    }

    setFormValue(tempFormValue);
  }, [stdOrderAutomatInitialData.loading]);

  useEffect(() => {
    if (
      stdOrderAutomatDataToCopyFn.loading ||
      !stdOrderAutomatDataToCopyFn.value
    )
      return;

    let tempFormValue = {
      std_order_to_copy: formValue.std_order_to_copy,
      kt_to_copy: formValue.kt_to_copy,
      t_to_copy: formValue.t_to_copy,
      skill_to_copy: formValue.skill_to_copy,
    };
    for (const [key, value] of Object.entries(
      stdOrderAutomatDataToCopyFn.value.std_order_automats
    )) {
      tempFormValue[`${KT_PREFIX}${key}`] = value.ordautomat_kt?.id;
      tempFormValue[`${T_PREFIX}${key}`] = value.ordautomat_t?.id;
      tempFormValue[`${SKILL_PREFIX}${key}`] = value.skill?.id;
    }

    setFormValue(tempFormValue);
  }, [stdOrderAutomatDataToCopyFn.loading]);

  const prepareDataToSend = () => {
    let dataToSend = {};
    for (const object of objectsLevel2.value) {
      const key = object.id;
      if (
        formValue[`${KT_PREFIX}${key}`] ===
          stdOrderAutomatInitialData.value?.std_order_automats[key]
            ?.ordautomat_kt?.id &&
        formValue[`${T_PREFIX}${key}`] ===
          stdOrderAutomatInitialData.value?.std_order_automats[key]
            ?.ordautomat_t?.id &&
        formValue[`${SKILL_PREFIX}${key}`] ===
          stdOrderAutomatInitialData.value?.std_order_automats[key]?.skill?.id
      ) {
        continue;
      }

      dataToSend[key] = {
        ordautomat_kt: formValue[`${KT_PREFIX}${key}`],
        ordautomat_t: formValue[`${T_PREFIX}${key}`],
        skill: formValue[`${SKILL_PREFIX}${key}`],
      };
    }

    return dataToSend;
  };

  const updateStdOrderAutomatFn = useAsyncFn(updateStdOrderAutomat);
  const stdTicketFilteringData = useAsync(getStdOrderAutomatAdminFilteringData);

  const handleCopyUsersSkillToAllObject = () => {
    let tempFormValue = {
      std_order_to_copy: formValue.std_order_to_copy,
      kt_to_copy: formValue.kt_to_copy,
      t_to_copy: formValue.t_to_copy,
      skill_to_copy: formValue.skill_to_copy,
    };
    for (const key of Object.keys(
      stdOrderAutomatInitialData.value.std_order_automats
    )) {
      tempFormValue[`${KT_PREFIX}${key}`] = tempFormValue.kt_to_copy;
      tempFormValue[`${T_PREFIX}${key}`] = tempFormValue.t_to_copy;
      tempFormValue[`${SKILL_PREFIX}${key}`] = tempFormValue.skill_to_copy;
    }

    setFormValue(tempFormValue);
  };

  const handleSubmit = (dataToSend) => {
    updateStdOrderAutomatFn
      .execute(dataToSend, props.stdOrderId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.std_order_automats_updated")
        );
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isFormValid = formValue !== undefined;

  const generateStdOrderAutomatFields = () => {
    return objectsLevel2.value.map((object) => (
      <Grid
        item
        xs={12}
        key={object.id}
        container
        spacing={2}
        justifyContent={"center"}
      >
        <Grid item xs={3}>
          <TextFieldFormControl
            value={object.object_name}
            readOnly
            inputStyle={{
              "-webkit-text-fill-color": "black",
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <AutocompleteField
            multiple={false}
            optionValueKey={"id"}
            optionLabelKey={"full_name"}
            name={`${KT_PREFIX}${object.id}`}
            label={t("dialog.std_order_automat.kt")}
            value={formValue[`${KT_PREFIX}${object.id}`]}
            options={stdTicketFilteringData.value.ordautomat_kt}
            onChange={handleChangeOrderKt}
          />
        </Grid>
        <Grid item xs={3}>
          <AutocompleteField
            multiple={false}
            optionValueKey={"id"}
            optionLabelKey={"full_name"}
            name={`${SKILL_PREFIX}${object.id}`}
            label={t("dialog.std_order_automat.skill")}
            value={formValue[`${SKILL_PREFIX}${object.id}`]}
            options={stdTicketFilteringData.value.skill}
            onChange={handleChangeSkill}
          />
        </Grid>
        <Grid item xs={3}>
          <AutocompleteField
            multiple={false}
            optionValueKey={"id"}
            optionLabelKey={"full_name"}
            name={`${T_PREFIX}${object.id}`}
            label={t("dialog.std_order_automat.t")}
            value={formValue[`${T_PREFIX}${object.id}`]}
            options={stdTicketFilteringData.value.ordautomat_t}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
          />
        </Grid>
      </Grid>
    ));
  };

  const isDiabledCopyUsersSkillButtom = () => {
    if (formValue === undefined) return true;
    if (
      !formValue.kt_to_copy &&
      !formValue.t_to_copy &&
      !formValue.skill_to_copy
    )
      return true;

    if (formValue.kt_to_copy && formValue.skill_to_copy) return true;

    return false;
  };

  const handleChangeSkillToCopy = (...inputPros) => {
    onChangeAutocompleteFieldWithObjectOptions(...inputPros);
    setFormValue((prev) => ({
      ...prev,
      kt_to_copy: undefined,
    }));
  };

  const handleChangeUserToCopy = (...inputPros) => {
    onChangeAutocompleteFieldWithObjectOptions(...inputPros);
    setFormValue((prev) => ({
      ...prev,
      skill_to_copy: undefined,
    }));
  };

  const handleChangeSkill = (...inputPros) => {
    const { name } = onChangeAutocompleteFieldWithObjectOptions(...inputPros);
    const objectId = name.replace(SKILL_PREFIX, "");
    setFormValue((prev) => ({
      ...prev,
      [`${KT_PREFIX}${objectId}`]: undefined,
    }));
  };

  const handleChangeOrderKt = (...inputPros) => {
    const { name } = onChangeAutocompleteFieldWithObjectOptions(...inputPros);
    const objectId = name.replace(KT_PREFIX, "");
    setFormValue((prev) => ({
      ...prev,
      [`${SKILL_PREFIX}${objectId}`]: undefined,
    }));
  };

  const isLoading =
    formValue === undefined ||
    stdTicketFilteringData.loading ||
    stdOrderAutomatDataToCopyFn.loading ||
    objectsLevel2.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        rowGap={1}
        spacing={2}
      >
        <Grid item xs={12}>
          <TextFieldFormControl
            value={stdOrderAutomatInitialData.value.std_order_name}
            inputStyle={{
              "-webkit-text-fill-color": "black",
              "text-align": "center",
            }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={9}>
          <AutocompleteField
            multiple={false}
            optionValueKey={"id"}
            optionLabelKey={"std_order_name"}
            label={t(
              "dialog.std_order_automat.copy_responsible_from_std_order"
            )}
            name={"std_order_to_copy"}
            value={formValue.std_order_to_copy}
            options={stdTicketFilteringData.value.std_order}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
          />
        </Grid>
        <Grid item xs={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={stdOrderAutomatDataToCopyFn.loading}
            disabled={!formValue.std_order_to_copy}
            onClick={() =>
              stdOrderAutomatDataToCopyFn.execute(formValue.std_order_to_copy)
            }
          >
            {t("confirm")}
          </LoadingButton>
        </Grid>
        <Grid item xs={3}>
          <AutocompleteField
            multiple={false}
            optionValueKey={"id"}
            label={t("dialog.std_order_automat.assign_one_kt")}
            optionLabelKey={"full_name"}
            name={"kt_to_copy"}
            value={formValue.kt_to_copy}
            options={stdTicketFilteringData.value.ordautomat_kt}
            onChange={handleChangeUserToCopy}
          />
        </Grid>
        <Grid item xs={3}>
          <AutocompleteField
            multiple={false}
            optionValueKey={"id"}
            label={t("dialog.std_order_automat.assign_one_skill")}
            optionLabelKey={"full_name"}
            name={"skill_to_copy"}
            value={formValue.skill_to_copy}
            options={stdTicketFilteringData.value.skill}
            onChange={handleChangeSkillToCopy}
          />
        </Grid>
        <Grid item xs={3}>
          <AutocompleteField
            multiple={false}
            optionValueKey={"id"}
            label={t("dialog.std_order_automat.assign_one_t")}
            optionLabelKey={"full_name"}
            name={"t_to_copy"}
            value={formValue.t_to_copy}
            options={stdTicketFilteringData.value.ordautomat_t}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
          />
        </Grid>
        <Grid item xs={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={stdOrderAutomatDataToCopyFn.loading}
            disabled={isDiabledCopyUsersSkillButtom()}
            onClick={handleCopyUsersSkillToAllObject}
          >
            {t("confirm")}
          </LoadingButton>
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={1}
          marginTop={1}
          sx={{ maxHeight: "50vh", overflowY: "auto" }}
        >
          {generateStdOrderAutomatFields()}
        </Grid>
        <Grid item xs={12} marginTop={2}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={updateStdOrderAutomatFn.loading}
            disabled={!isFormValid}
            onClick={() => handleSubmit(prepareDataToSend())}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      showTopFullScreenButton={false}
      title={t("dialog.std_order_automat.assign_responsible_to_std_order")}
      maxWidth="lg"
      showDialogActions
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

StdOrderAutomatDialog.propTypes = {
  stdOrderId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onSubmitCallback: PropTypes.func,
};

StdOrderAutomatDialog.defaultProps = {
  open: false,
};

export default StdOrderAutomatDialog;
