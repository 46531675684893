import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import useOfferService from "../services/offerService";
import { useAsync } from "./useAsync";
import { getFieldsFromObject } from "../helpers/methods";



const useClausesData = (
    offerId,
) => {

    const [offerClausesLocal, setOfferClausesLocal] = useState(undefined)

    const {
        getOfferClausesList,
        getCalusesList
    } = useOfferService()

    const getFieldsNameToSave = () => {
        return [
          "id",
          "clause_id",
          "isDeleted"
        ];
      };
    

    const offerClausesList = useAsync(
        () => getClausesListByOfferId(),
        [offerId]
    );

    const getClausesListByOfferId = () =>{
        if (offerId){
            return getOfferClausesList(offerId)
        }
        return getCalusesList({'clause_automated' : true})
    }



    useEffect(() => {
        if (offerClausesList.loading) {
            return;
        }
        setOfferClausesLocal(offerClausesList.value)
    }, [offerClausesList.loading]);


  

    const onAddClausesTableData = useCallback((newClauses) => {
        let tempDataLocal = offerClausesLocal
        let formattedClauses = []
        for (let c of newClauses){
            c['clause_id'] = c.id
            delete c.id
            formattedClauses.push(c)
        }
        setOfferClausesLocal([...tempDataLocal, ...formattedClauses])
    }, [offerClausesLocal])



    const getClausesToSend = () => {
      return  offerClausesLocal.map((c) => prepareSettlementDataToSave(c))
    }

    const prepareSettlementDataToSave = (element) => {
        return getFieldsFromObject(element, getFieldsNameToSave());
    };


    const handleRemoveClausesFromList = useCallback((rowIndex) => {
        let tempDataLocal = offerClausesLocal
        let element = tempDataLocal[rowIndex]
        if (element.id === undefined) {
            tempDataLocal.splice(rowIndex, 1)
        } else {
            element.isDeleted = true
            tempDataLocal[rowIndex] = { ...element }
        }
        setOfferClausesLocal([...tempDataLocal])
    },  [offerClausesLocal])

    return {
        clausesData:  offerClausesLocal,
        handleRemoveClausesFromList,
        onAddClausesTableData,
        getClausesToSend,
        refetchClauseData : offerClausesList.refetch,
        clauseLoading : offerClausesList.loading
    };
};

export default useClausesData;
