import i18n from "../../i18n";

import { AUTOCOMPLETE_FIELD_TYPE } from "../../helpers/constants";

import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

import useLocationService from "../../services/locationService";
import { useAsync } from "../../hooks/useAsync";

export const STD_AUTOMAT_TICKET_TABLE_NAME = "ticket";
export const STD_AUTOMAT_ORDER_TABLE_NAME = "order";
export const STD_AUTOMAT_ORDER_TABLE_KT_NAME = "order_kt";
export const STD_AUTOMAT_ORDER_TABLE_T_NAME = "order_T";

export const STD_AUTOMAT_TICKET_TABLE_PREFIX = "ticket";
export const STD_AUTOMAT_ORDER_TABLE_KT_PREFIX = "order_kt";
export const STD_AUTOMAT_ORDER_TABLE_T_PREFIX = "order_t";

export const STD_AUTOMAT_TICKET_ARRAY_NAME = "std_ticket_automats";
export const STD_AUTOMAT_ORDER_ARRAY_NAME = "std_order_automats";

const STD_TIKET_AUTOMAT_K_PREFIX = "ticautomat_k__";
const STD_ORDER_AUTOMAT_KT_PREFIX = "ordautomat_kt__";
const STD_ORDER_AUTOMAT_T_PREFIX = "ordautomat_t__";

const STD_TIKET_AUTOMAT_K_FIELD_NAME = "ticautomat_k";
const STD_ORDER_AUTOMAT_KT_FIELD_NAME = "ordautomat_kt";
const STD_ORDER_AUTOMAT_T_FIELD_NAME = "ordautomat_t";

export const STD_AUTOMAT_TICKET_BASE_TABLE_CONFIG = [
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
    ),
    label: i18n.t("edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
  {
    name: "ticket_category",
    other_export_field_name: "tc_name",
    getValueCallback: (rowData) => rowData.tc_name,
    label: i18n.t("table.ticket.ticket_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_category,
      isObjectOption: true,
      optionLabelKey: "tc_name",
      addNewValue: false,
      multiple: true,
    }),
  },
];

export const STD_AUTOMAT_ORDER_BASE_TABLE_CONFIG = [
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
    ),
    label: i18n.t("edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
  {
    name: "std_order",
    other_export_field_name: "std_order_name",
    getValueCallback: (rowData) => rowData.std_order_name,
    label: i18n.t("table.std_order.std_order"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.std_order,
      isObjectOption: true,
      optionLabelKey: "std_order_name",
      addNewValue: false,
      multiple: true,
    }),
  },
];

export const useStdTicketOrderAutomatOrder = () => {
  const { getObjectSelectList } = useLocationService();
  const objectsLevel2 = useAsync(() =>
    getObjectSelectList({ object_level: 2 })
  );

  const getResponsibleUsersForObjectsTableConfig = (
    rolePrefix,
    objectArrayName,
    fieldName
  ) => {
    return objectsLevel2.value.map((object) => ({
      name: `${rolePrefix}${object.id}`,
      getValueCallback: (rowData) => {
        return rowData[objectArrayName][object.id][fieldName]?.full_name;
      },
      label: object.object_name,
      filterType: AUTOCOMPLETE_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => ({
        options: filteringData.value[fieldName],
        isObjectOption: true,
        optionLabelKey: "full_name",
        addNewValue: false,
        multiple: true,
      }),
    }));
  };

  const getTablePropsByTableSubTableNames = (selectedTable) => {
    let tableConfig, tableName, tablePrefix;

    if (selectedTable === STD_AUTOMAT_TICKET_TABLE_NAME) {
      tableName = STD_AUTOMAT_TICKET_TABLE_NAME;
      tablePrefix = STD_AUTOMAT_TICKET_TABLE_PREFIX;
      tableConfig = [
        ...STD_AUTOMAT_TICKET_BASE_TABLE_CONFIG,
        ...getResponsibleUsersForObjectsTableConfig(
          STD_TIKET_AUTOMAT_K_PREFIX,
          STD_AUTOMAT_TICKET_ARRAY_NAME,
          STD_TIKET_AUTOMAT_K_FIELD_NAME
        ),
      ];
    } else if (selectedTable === STD_AUTOMAT_ORDER_TABLE_KT_NAME) {
      tableName = STD_AUTOMAT_ORDER_TABLE_KT_NAME;
      tablePrefix = STD_AUTOMAT_ORDER_TABLE_KT_PREFIX;
      tableConfig = [
        ...STD_AUTOMAT_ORDER_BASE_TABLE_CONFIG,
        ...getResponsibleUsersForObjectsTableConfig(
          STD_ORDER_AUTOMAT_KT_PREFIX,
          STD_AUTOMAT_ORDER_ARRAY_NAME,
          STD_ORDER_AUTOMAT_KT_FIELD_NAME
        ),
      ];
    } else if (selectedTable === STD_AUTOMAT_ORDER_TABLE_T_NAME) {
      tableName = STD_AUTOMAT_ORDER_TABLE_T_NAME;
      tablePrefix = STD_AUTOMAT_ORDER_TABLE_T_PREFIX;
      tableConfig = [
        ...STD_AUTOMAT_ORDER_BASE_TABLE_CONFIG,
        ...getResponsibleUsersForObjectsTableConfig(
          STD_ORDER_AUTOMAT_T_PREFIX,
          STD_AUTOMAT_ORDER_ARRAY_NAME,
          STD_ORDER_AUTOMAT_T_FIELD_NAME
        ),
      ];
    }

    return {
      tableName: tableName,
      tableConfig: tableConfig,
      tableFilterPrefix: tablePrefix,
      configForUserPreferenceDialog: [
        {
          name: tableName,
          config: tableConfig,
        },
      ],
    };
  };

  return {
    fetchingFromBackend:
      objectsLevel2.loading || objectsLevel2.value === undefined,
    getTablePropsByTableSubTableNames,
  };
};
