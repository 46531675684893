import BasicDialog from "../../base/BasicDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Stack } from "@mui/material";
import React, { useCallback, useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MessagesWindow from "../../window/MessagesWindow";
import MessageInputForm from "../../form/MessageInputForm/MessageInputForm";
import TopCommunicationDiaryBox from "../../box/TopCommunicationDiaryBox/TopCommunicationDiaryBox";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import BaseBox from "../../base/BaseBox/baseBox";

function MessagesDialog(props) {
  const { t } = useTranslation();

  const [isFullScreen, setIsFullScreen] = useState(props.allwaysFullScreen);

  const onChangeFullScreen = useCallback(
    (fullScreen) => {
      if (props.onToggleFullScreen) {
        props.onToggleFullScreen(fullScreen);
      }

      setIsFullScreen(!fullScreen);
    },
    [isFullScreen]
  );

  const getTopCommuncationDiaryBox = (buttonsGridSm = 1) => {
    return (
      <TopCommunicationDiaryBox
        filterTypes={props.filterTypes}
        chosenFilterGroup={props.chosenFilterGroup}
        onChooseFilterGroup={props.onChooseFilterGroup}
        buttonsGridSm={buttonsGridSm}
      />
    );
  };

  const getMessageInputBox = () => {
    return (
      <MessageInputForm
        showLoader={props.showLoader}
        showMessageType={props.showMessageType}
        onSubmit={props.onSubmit}
        inputMessage={props.inputMessage}
        isCorrectedSend={props.isCorrectedSend}
        onOpenEnclosuresDialog={props.onOpenEnclosuresDialog}
        showOnlyAttachmentsIcon={props.inputMessageFormShowOnlyAttachmentsIcon}
        gridJustifyContent={props.inputMessageFormGridJustifyContent}
        enterButtonGridSmGrid={props.inputMessageFormEnterButtonGridSmGrid}
        errorText={props.inputMessageErrorText}
      />
    );
  };

  const getContent = () => (
    <LoaderWrapper showLoader={false}>
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="center"
        overflow="auto"
      >
        <Grid item xs={12}>
          <MessagesWindow
            messages={props.messages}
            messageWindowHeight={props.messageWindowHeight}
            canHideMessages={props.canHideMessages}
            isFullScreen={isFullScreen}
            contextMenuActions={[
              {
                label: t("dialog.message_dialog.hide"),
                callback: (messageId) => {
                  props.onHideMessage(messageId);
                },
                icon: <VisibilityOffIcon fontSize="small" />,
              },
            ]}
          />
        </Grid>
      </Grid>
    </LoaderWrapper>
  );

  const getDialogAsForm = () => (
    <BaseBox>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} alignItems="flex-start">
          <Typography
            variant="h6"
            className="capitalize-first-letter"
            color={"primary"}
            marginBottom={-2}
          >
            {t("dialog.message_dialog.communication_diary")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {getTopCommuncationDiaryBox(3.5)}
        </Grid>
        <Grid item xs={12}>
          {getContent()}
        </Grid>
        <Grid item xs={12}>
          {getMessageInputBox()}
        </Grid>
      </Grid>
    </BaseBox>
  );

  const getDialog = () => (
    <BasicDialog
      open={props.open}
      onClose={(e) => props.onClose()}
      titleAlign="center"
      title={t("dialog.message_dialog.communication_diary")}
      maxWidth="md"
      showTopActionButton={props.showFilters}
      topActionButton={getTopCommuncationDiaryBox()}
      showBottomActionButton={true}
      bottomActionStyle={{ paddingTop: "10px", paddingLeft: "30px" }}
      bottomActionButton={getMessageInputBox()}
      titleSx={{ padding: 0, marign: 0 }}
      contentAlign="right"
      contentSx={{ margin: 0, padding: 0, overflow: "auto" }}
      onToggleFullScreen={onChangeFullScreen}
    >
      {getContent()}
    </BasicDialog>
  );

  return props.showDialogAsForm ? getDialogAsForm() : getDialog();
}

MessagesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  messages: PropTypes.array,
  showMessageType: PropTypes.bool,
  onSubmit: PropTypes.func,
  showFilters: PropTypes.bool,
  showLoader: PropTypes.bool,
  messageWindowHeight: PropTypes.object,
  onOpenEnclosuresDialog: PropTypes.func,
  filterTypes: PropTypes.array,
  onHideMessage: PropTypes.func,
  canHideMessages: PropTypes.bool,
  showDialogAsForm: PropTypes.bool,
  inputMessageFormShowOnlyAttachmentsIcon: PropTypes.bool,
  inputMessageFormSGridJustifyContent: PropTypes.string,
  inputMessageFormSEnterButtonGridSmGrid: PropTypes.number,
  inputMessageErrorText: PropTypes.string,
};

MessagesDialog.defaultProps = {
  open: false,
  messages: [],
  showMessageType: false,
  showFilters: false,
  showLoader: true,
  messageWindowHeight: { height: 400 },
  filterTypes: [],
  canHideMessages: false,
  showDialogAsForm: false,
};

export default MessagesDialog;
