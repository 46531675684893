import { useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../context/auth";
import useEffectOnce from "../../../hooks/useEffectOnce";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useTranslation } from "react-i18next";
import { validToken } from "../../../helpers/methods";
import { getErrorMsg } from "../../../helpers/methods";

const MyOliviaAuth = () => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [isLoading, setIsLoading] = useState(true);

  const { myOliviaLogin } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const userToken = searchParams.get("user");

  useEffectOnce(() => {
    const verifyMyOliviaToken = async () => {
      try {
        setSearchParams({});
        await myOliviaLogin(userToken);
        snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.logged_in"));
      } catch (err) {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(err.data),
          t("snackbar_alert.occurred_error_during_logging_in")
        );
      } finally {
        setIsLoading(false);
      }
    };

    validToken(userToken) ? verifyMyOliviaToken() : setIsLoading(false);
  }, []);

  return (
    <LoaderWrapper showLoader={isLoading}>
      <Outlet />
    </LoaderWrapper>
  );
};

export default MyOliviaAuth;
