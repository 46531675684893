import { useCallback, useMemo } from "react";
import hasUserPermission, {
  IS_K,
  IS_KT,
  IS_TB,
  IS_T,
  IS_ADMIN,
  IS_RR,
} from "../helpers/userPermissions";
import { useAuth } from "../context/auth";
import {
  CommunicationFiltersTypes,
  EDIT_PERMISSION,
  EXTERNAL_TYPE,
  INTERNAL_TYPE,
  TRANSACTION_KIND_IN,
} from "../helpers/constants";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { otherPermissionsSelectList } from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";

export default function usePermissionService() {
  const { user } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const availableEnclosuresFilters = useMemo(() => {
    if (user.is_aad_user) {
      return [
        CommunicationFiltersTypes.external,
        CommunicationFiltersTypes.internal,
      ];
    }
    return [];
  }, [user]);

  const availableMessagesFilters = useMemo(() => {
    if (hasUserPermission([IS_ADMIN, IS_K, IS_KT, IS_TB], user)) {
      return [
        CommunicationFiltersTypes.logs,
        CommunicationFiltersTypes.external,
        CommunicationFiltersTypes.internal,
      ];
    }
    return [CommunicationFiltersTypes.logs, CommunicationFiltersTypes.external];
  }, [user]);

  const defaultEnclosureType = useMemo(() => {
    if (!user.is_aad_user && !hasUserPermission([IS_T], user)) {
      return EXTERNAL_TYPE;
    }
    return INTERNAL_TYPE;
  }, [user]);

  const defaultMessageType = useMemo(() => {
    if (!user.is_aad_user) {
      return EXTERNAL_TYPE;
    }
    return INTERNAL_TYPE;
  }, [user]);

  const showExtendedAddEnclosureDialog = useMemo(() => {
    return hasUserPermission([IS_ADMIN, IS_K, IS_KT, IS_TB], user);
  }, [user]);

  const showFilters = useMemo(() => {
    return true;
    // return hasUserPermission([IS_ADMIN, IS_K, IS_KT], user);
  }, [user]);

  const showEnclosureType = useMemo(() => {
    return hasUserPermission([IS_ADMIN, IS_K, IS_KT, IS_TB], user);
  }, [user]);

  const showMessageType = useMemo(() => {
    return hasUserPermission([IS_ADMIN, IS_K, IS_KT, IS_TB], user);
  }, [user]);

  const showSubcontractorSelect = useMemo(() => {
    return hasUserPermission([IS_ADMIN, IS_K, IS_KT, IS_TB], user);
  }, [user]);

  const canHideEnclosures = useMemo(() => {
    return hasUserPermission([IS_ADMIN, IS_K, IS_KT, IS_TB], user);
  }, [user]);

  const canHideMessages = useMemo(() => {
    return hasUserPermission([IS_ADMIN, IS_K, IS_KT, IS_TB], user);
  }, [user]);

  const showReportOnBehalfButton = useMemo(() => {
    return hasUserPermission([IS_ADMIN, IS_K, IS_KT, IS_TB], user);
  }, [user]);

  const showChangesCircle = useMemo(() => {
    return true;
    // return hasUserPermission([IS_ADMIN, IS_K, IS_KT, IS_OM], user);
  }, [user]);

  const showCreteOfferButtonInOffersForTicketDialog = useMemo(() => {
    return hasUserPermission([IS_ADMIN, IS_K, IS_RR], user);
  }, [user]);

  const showAllObjectsAndLevels = useMemo(() => {
    return user.is_aad_user;
  }, [user]);

  const showPublishButton = useMemo(() => {
    return user.is_aad_user;
  }, [user]);


  const checkPermissionToEdit = useCallback((permissionList) => {
    if (permissionList){
      return  permissionList.includes(EDIT_PERMISSION);
    }
    return false;
  }, [EDIT_PERMISSION]);

  const getOtherPermissionsSelectList = () => {
    return makeRequest(axiosPrivate, otherPermissionsSelectList);
  };

  return {
    availableEnclosuresFilters,
    defaultEnclosureType,
    showExtendedAddEnclosureDialog,
    showFilters,
    showEnclosureType,
    showSubcontractorSelect,
    canHideEnclosures,
    availableMessagesFilters,
    defaultMessageType,
    showMessageType,
    canHideMessages,
    showReportOnBehalfButton,
    showChangesCircle,
    showCreteOfferButtonInOffersForTicketDialog,
    showAllObjectsAndLevels,
    showPublishButton,
    checkPermissionToEdit,
    getOtherPermissionsSelectList
  };
}
