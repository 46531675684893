import { useState, useEffect, useMemo, useRef } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import LoaderWrapper from "../../../../wrapper/LoaderWrapper";

import { useAsync } from "../../../../../hooks/useAsync";

import usePaginationWithSearchParams from "../../../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../../../hooks/useFilterSearchParams";

import useLocationService from "../../../../../services/locationService";

import TableService from "../../../../../services/tableService";
import ObjectTenantTable from "../../../../table/ObjectTenantTable";
import { exportObjectTenantExtendedLocationToFilteUrl } from "../../../../../helpers/apiUrls";
import {
  getFilterFieldKeyByNameAndPrefix,
  getFilterSearchParamsKeyForField,
} from "../../../../../helpers/methods";

import {
  OBJECT_TENANT_TABLE_FILTER_PREFIX,
  OBJECT_TENANT_TABLE_NAME,
  OBJECT_TENANT_TABLE_CONFIG,
} from "./TableConfig";

import { isSearchParamsObjectEmpty } from "../../../../../helpers/methods";

const RRAdminObjectTenantTable = (props) => {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(
    OBJECT_TENANT_TABLE_FILTER_PREFIX,
    undefined,
    100
  );

  const { setNewItemSearchParamsIfAreChanged, getSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);
  const [objectTenantSearchParams, setObjectTenantSearchParams] = useState(
    getSearchParamsByFilterPrefix(OBJECT_TENANT_TABLE_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      OBJECT_TENANT_TABLE_FILTER_PREFIX,
      objectTenantSearchParams,
      setObjectTenantSearchParams
    );
  }, [searchParams]);

  const { getObjectTenantExtendedLocationList, getObjectTenantFilteringData } =
    useLocationService();

  const objectTenantData = useAsync(
    () =>
      getObjectTenantExtendedLocationList(
        {
          ...objectTenantSearchParams,
          page_size: objectTenantSearchParams.page_size || 100,
          is_tenant_rr_visibility: true,
        },
        props.locationLastLevel
      ),
    [objectTenantSearchParams]
  );

  const objectTenantFilteringData = useAsync(() =>
    getObjectTenantFilteringData({ tenant_rr_visibility: true })
  );

  const [objectTenantDataLocaly, setObjectTenantDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (objectTenantData.loading) {
      return;
    }
    setObjectTenantDataLocaly(objectTenantData.value.results);
    setCountRecords(objectTenantData.value.count);
  }, [objectTenantData.loading]);

  const objectTenantExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(OBJECT_TENANT_TABLE_CONFIG, []);
  }, [OBJECT_TENANT_TABLE_CONFIG]);

  const isLoading =
    objectTenantDataLocaly === undefined || objectTenantFilteringData.loading;

  const handleClickTableRow = (rowId) => {
    const rowData = objectTenantDataLocaly.find((row) => row.id === rowId);

    const tenantId = objectTenantFilteringData.value.tenant.find(
      (tenant) => tenant.tenant_short_name === rowData.tenant
    )?.id;
    const level1 = rowData.level_1;
    const level2 = rowData.level_2;
    const level3 = rowData.level_3;

    setSearchParams((prev) => {
      let tempSearchParams = { ...prev };
      for (let searchPrefix of props.searchPrefixes) {
        tempSearchParams = {
          ...tempSearchParams,
          [getFilterFieldKeyByNameAndPrefix("objects_level_1", searchPrefix)]:
            level1,
          [getFilterFieldKeyByNameAndPrefix("objects_level_2", searchPrefix)]:
            level2,
          [getFilterFieldKeyByNameAndPrefix("objects_level_3", searchPrefix)]:
            level3,
          [getFilterFieldKeyByNameAndPrefix("tenant", searchPrefix)]: tenantId,
        };
      }
      return tempSearchParams;
    });
  };

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ObjectTenantTable
          showCleanFilterIcon={true}
          style={{ maxHeight: "60vh" }}
          showFilters={true}
          data={objectTenantDataLocaly}
          filterPrefix={OBJECT_TENANT_TABLE_FILTER_PREFIX}
          filteringData={objectTenantFilteringData}
          countRecords={countRecords}
          page={page}
          onPageChange={handleChangePageWithSearchParams}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          tableConfig={OBJECT_TENANT_TABLE_CONFIG}
          hiddenColumns={[]}
          showExportToFileButton={true}
          exportToFileUrl={exportObjectTenantExtendedLocationToFilteUrl(
            props.locationLastLevel
          )}
          exportToFileSearchParams={{
            ...objectTenantSearchParams,
            is_tenant_rr_visibility: true,
          }}
          exportToFileHeaders={objectTenantExportHeaders}
          exportToFileFileName={`${t("tabs.rr_admin.locations")}.xlsx`}
          initialExpandedFilterHeader={true}
          onClickRow={handleClickTableRow}
        />
      </Grid>
    </Grid>
  );
};

RRAdminObjectTenantTable.propTypes = {
  pageName: PropTypes.string,
  locationLastLevel: PropTypes.number,
  readOnly: PropTypes.bool,
  searchPrefixes: PropTypes.array,
};

RRAdminObjectTenantTable.defaultProps = {
  locationLastLevel: 3,
  searchPrefixes: [],
};

export default RRAdminObjectTenantTable;
