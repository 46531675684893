import { useState } from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { OliviaLogo } from "../../../media/logo";

export default function ErrorForm(props) {
  const { t } = useTranslation();

  return (
    <Paper elevation={3} style={props.style}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={1}
      >
        <Grid item xs={12} marginTop={2}>
          <img className="" src={OliviaLogo} alt="" height={80} />
        </Grid>

        <Grid item xs={12} marginTop={3}>
          <Typography
            style={{ color: "var(--alert)" }}
            className="capitalize-first-letter"
            variant="h6"
            display="block"
            gutterBottom
          >
            {props.errorMessage || t("form.error.an_unknown_error_occurred")}
          </Typography>
        </Grid>
        <Grid item xs={12} marginTop={3} marginBottom={2}>
          {props.showRefreshBtn && (
            <Button
              fullWidth
              type="submit"
              onClick={props.onClickBtn}
              variant="contained"
              color="error"
            >
              {props.btnLabel || t("form.error.try_again")}
            </Button>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

ErrorForm.propTypes = {
  style: PropTypes.object,
  errorMessage: PropTypes.string,
  btnLabel: PropTypes.string,
  onClickBtn: PropTypes.func,
  showRefreshBtn: PropTypes.bool,
};

ErrorForm.defaultProps = {
  onClickBtn: () => window.location.reload(),
  showRefreshBtn: true,
};
