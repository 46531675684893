import { useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";

import { useForm } from "../../../hooks/useForm";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";

import useGuaranteeService from "../../../services/guaranteeService";
import GuaranteeScopeForm from "../../form/GuaranteeScopeForm";

import BasicDialog from "../../base/BasicDialog";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { isEmptyValue } from "../../../helpers/methods";

const GuaranteeScopeDialog = (props) => {
  const { t } = useTranslation();

  const snackbarAlert = useSnackbarAlert();

  const {
    getGuaranteeScopeDetailsData,
    getGuaranteeScopeFilteringData,
    createOrUpdateGuaranteeScope,
  } = useGuaranteeService();

  const guaranteeScopeDetailsData = useAsync(() => {
    if (!props.guaranteeScopeId)
      return Promise.resolve({ loading: false, value: {} });
    return getGuaranteeScopeDetailsData(props.guaranteeScopeId);
  }, [props.guaranteeScopeId]);

  const guaranteeScopeFilteringData = useAsync(getGuaranteeScopeFilteringData);

  const createOrUpdateGuaranteeScopeFn = useAsyncFn(
    createOrUpdateGuaranteeScope
  );

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm(undefined);

  useEffect(() => {
    if (guaranteeScopeDetailsData.loading) return;
    setFormValue(guaranteeScopeDetailsData.value);
  }, [guaranteeScopeDetailsData.loading]);

  const onSubmit = () => {
    createOrUpdateGuaranteeScopeFn
      .execute(props.guaranteeScopeId, formValue)
      .then((res) => {
        if (res.status === 201)
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.guarantee_scope_added")
          );
        else
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.guarantee_scope_updated")
          );
        props.onSubmit();
        props.onClose();
      })
      .catch((error) => {
        if (error.status === 400) {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_saving_changes")
          );
        } else {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.server_error")
          );
        }
      });
  };

  const isFormValid = () => {
    const requiredFields = [
      "guascope_date_close",
      "guascope_kind",
      "guascope_responsible",
    ];

    return requiredFields.every((field) => !isEmptyValue(formValue[field]));
  };

  const getDialogTitle = () => {
    if (props.guaranteeScopeId)
      return t("dialog.guarantee_scope_dialog.update_guarantee_scope");
    return t("dialog.guarantee_scope_dialog.create_guarantee_scope");
  };

  const isLoading =
    guaranteeScopeDetailsData.loading ||
    guaranteeScopeFilteringData.loading ||
    formValue === undefined;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <GuaranteeScopeForm
            formValue={formValue}
            filteringData={guaranteeScopeFilteringData.value}
            onChange={onChange}
            onChangeDate={onChangeDate}
            onChangeAutocompleteFieldWithObjectOptions={
              onChangeAutocompleteFieldWithObjectOptions
            }
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            sx={{ marginTop: "20px" }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={onSubmit}
            loading={createOrUpdateGuaranteeScopeFn.loading}
            disabled={!isFormValid()}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      titleAlign="center"
      title={getDialogTitle()}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

GuaranteeScopeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  guaranteeScopeId: PropTypes.string,
};

GuaranteeScopeDialog.defaultProps = {};

export default GuaranteeScopeDialog;
