import React from "react";

import PropTypes from "prop-types";

import Switch from "@mui/material/Switch";
import { Typography, Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const SortableSwitch = ({
  label,
  checked,
  onChangeColumnVisibility,
  allowChangeVisibility,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <DragIndicatorIcon style={{ cursor: "grab", color: "var(--primary)" }} />
      <FormControlLabel
        sx={{
          fontFamily: "Roboto",
          textTransform: "capitalize",
        }}
        labelPlacement="start"
        control={
          <Switch
            checked={checked}
            onChange={onChangeColumnVisibility}
            disabled={allowChangeVisibility}
          />
        }
        label={label}
      />
    </div>
  );
};

function TableColumnVisibilityForm(props) {
  const handleOnDragEnd = ({ source, destination }) => {
    if (!destination) return;

    props.onChangeColumnOrders(
      props.tableName,
      source.index,
      destination.index
    );
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      margin={0}
    >
      <Grid item xs={12}>
        <Typography variant="h6">{props.tableLabel}</Typography>
      </Grid>
      <Grid item xs={12}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId={props.tableLabel}>
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {props.tableConfig.map((field, idx) => (
                  <Draggable
                    key={field.name}
                    draggableId={field.name}
                    index={idx}
                  >
                    {(draggableProvided, draggableSnapshot) => {
                      return (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={{
                            ...draggableProvided.draggableProps.style,
                            left: "auto !important",
                            top:
                              props.dialogContentScrollPosition > 0
                                ? draggableProvided.draggableProps.style.top -
                                  25
                                : "auto !important",
                          }}
                        >
                          <SortableSwitch
                            label={field.label}
                            checked={!props.hiddenColumns.includes(field.name)}
                            onChangeColumnVisibility={() =>
                              props.onChangeColumnVisibility(
                                props.tableName,
                                field.name,
                                props.tableConfig
                              )
                            }
                            allowChangeVisibility={!field.allowChangeVisibility}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </Grid>
  );
}

TableColumnVisibilityForm.propTypes = {
  tableName: PropTypes.string,
  tableLabel: PropTypes.string,
  tableConfig: PropTypes.array,
  hiddenColumns: PropTypes.array,
  columnsOrders: PropTypes.array,
  onChangeColumnVisibility: PropTypes.func,
  onChangeColumnOrders: PropTypes.func,
  dialogContentScrollPosition: PropTypes.number,
};

export default TableColumnVisibilityForm;
