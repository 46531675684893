import React, { useCallback } from "react";

import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import SelectField from "../../field/SelectField";
import TrueFalseSelectField from "../../field/TrueFalseSelectField/trueFalseSelectField";

import { useTranslation } from "react-i18next";

export const ANSWER_BORDER_COLOR = "#c0c0c1";
export const INACTIVE_ANSWER_COLOR = "#FFCCCC";

const QuestionAnswerAdminForm = React.memo((props) => {
  const { t } = useTranslation();

  const onChangeCb = useCallback(
    (e) => props.onChange(props.idx, e),
    [props.idx, props.onChange]
  );

  const onChangeAutocompleteFieldWithObjectOptionsCb = useCallback(
    (...inputPros) =>
      props.onChangeAutocompleteFieldWithObjectOptions(props.idx, ...inputPros),
    [props.idx, props.onChangeAutocompleteFieldWithObjectOptions]
  );

  const getFormStyle = () => ({
    border: `1px solid ${
      props.readOnly && !props.formValue.quan_active
        ? INACTIVE_ANSWER_COLOR
        : ANSWER_BORDER_COLOR
    }`,
    borderRadius: "20px",
    padding: "10px",
    backgroundColor:
      props.readOnly && !props.formValue.quan_active
        ? INACTIVE_ANSWER_COLOR
        : null,
  });

  return (
    <Grid container spacing={1} alignItems={"center"} style={getFormStyle()}>
      <Grid item xs={12} sm={4} container spacing={1}>
        <Grid item xs={12}>
          <TextFieldFormControl
            name={"quan_content"}
            readOnly={props.readOnly}
            label={t("form.question_answer_form.quan_content")}
            value={props.formValue.quan_content}
            onChange={onChangeCb}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormControl
            name={"quan_content_eng"}
            readOnly={props.readOnly}
            label={t("form.question_answer_form.quan_content_eng")}
            value={props.formValue.quan_content_eng}
            onChange={onChangeCb}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <AutocompleteField
          name="std_orders"
          label={t("form.question_answer_form.std_orders")}
          value={props.formValue.std_orders}
          options={props.filteringData.std_order}
          readOnly={props.readOnly}
          isObjectOption={true}
          optionLabelKey={"std_order_name"}
          addNewValue={false}
          multiple={true}
          onChange={onChangeAutocompleteFieldWithObjectOptionsCb}
        />
      </Grid>
      <Grid item xs={12} sm={4} container spacing={1}>
        <Grid item xs={12}>
          <AutocompleteField
            name="quan_nextQ"
            label={
              props.formValue.quan_nextQ
                ? t("form.question_answer_form.quan_nextQ")
                : t("form.question_answer_form.form_end")
            }
            value={props.formValue.quan_nextQ}
            options={props.filteringData.question}
            readOnly={props.readOnly}
            isObjectOption={true}
            optionLabelKey={"question_text"}
            addNewValue={false}
            onChange={onChangeAutocompleteFieldWithObjectOptionsCb}
          />
          {/* <SelectField
            name="quan_nextQ"
            label={t("form.question_answer_form.quan_nextQ")}
            value={props.formValue.quan_nextQ}
            options={props.filteringData.question}
            readOnly={props.readOnly}
            isObjectOption={true}
            emptyOptionsLabel={t("form.question_answer_form.form_end")}
            addEmptyOptions={true}
            optionLabel={"question_text"}
            onChange={props.onChange}
          /> */}
        </Grid>
        <Grid item xs={12}>
          <TrueFalseSelectField
            value={props.formValue.quan_active}
            valuesAsBool
            onChange={onChangeCb}
            label={t("form.question_answer_form.quan_active")}
            name={"quan_active"}
            addEmptyOptions={false}
            readOnly={props.readOnly}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

QuestionAnswerAdminForm.propTypes = {
  formValue: PropTypes.object,
  filteringData: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
};

QuestionAnswerAdminForm.defaultProps = {
  readOnly: false,
};

export default QuestionAnswerAdminForm;
