import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

import TuneIcon from "@mui/icons-material/Tune";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import TicketOfferButton from "../../button/TicketOfferButton";
import { centerVericalAlignIconStyle } from "../../../helpers/styles";
import SplitButton from "../../button/SplitButton";
import { overflowButtonStyle } from "../../../helpers/styles";

export default function OffersManagmentToolBar(props) {
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      columnSpacing={1}
      rowSpacing={2}
      style={props.style}
    >
      <Grid item xs={12} md={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={
            <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
          }
          onClick={props.onClickCreateOfferTicket}
          sx={overflowButtonStyle}
        >
          {t("bar.offer_tool_bar.create_offer")}
        </Button>
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<TuneIcon sx={centerVericalAlignIconStyle} />}
          onClick={props.onClickAdjustTable}
          sx={overflowButtonStyle}
        >
          {t("bar.offer_tool_bar.adjust_table")}
        </Button>
      </Grid>
      <Grid item xs={12} md={2}>
        <SplitButton
          startIcon={<AccountTreeIcon sx={centerVericalAlignIconStyle} />}
          label={t("bar.tool_bar.mass_actions")}
          options={props.massActionButtonOptions}
        />
      </Grid>

      <Grid item xs={4} sx={{ display: { xs: "none", sm: "flex" } }} />
      <Grid item xs={12} md={2}>
        <TicketOfferButton
          options={props.filterOptions}
          isTicketTableView={props.isTicketTableView}
        />
      </Grid>
    </Grid>
  );
}

OffersManagmentToolBar.propTypes = {
  style: PropTypes.object,
  onClickAdjustTable: PropTypes.func,
  onClickCreateOfferWithoutTicket: PropTypes.func,
  setVisibilityTicketTableView: PropTypes.func,
  isTicketTableView: PropTypes.bool,
  massActionButtonOptions: PropTypes.array,
};

OffersManagmentToolBar.defaultProps = {
  isTicketTableView: true,
  massActionButtonOptions: [],
};
