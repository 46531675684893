import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useSkillService from "../../../services/skillService";
import { useAsync } from "../../../hooks/useAsync";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import SkillHasUserTable from "../../table/SkillHasUserTable";
import { Button, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { centerVericalAlignIconStyle } from "../../../helpers/styles";
import CreateSkillDialog from "../../dialog/CreateSkillDialog/CreateSkillDialog";
import useDialogWithFormValue from "../../../hooks/useDialogWithFormValue";
import { useTranslation } from "react-i18next";
import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../hooks/useFilterSearchParams";
import { isSearchParamsObjectEmpty } from "../../../helpers/methods";

const SKILL_USER_TABLE_FILTER_PREFIX = "skill_user_table";

const SkillUser = (props) => {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams(SKILL_USER_TABLE_FILTER_PREFIX);

  const { setNewItemSearchParamsIfAreChanged, getSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);
  const [skillUserSearchParams, setSkillUserSearchParams] = useState(
    getSearchParamsByFilterPrefix(SKILL_USER_TABLE_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      SKILL_USER_TABLE_FILTER_PREFIX,
      skillUserSearchParams,
      setSkillUserSearchParams
    );
  }, [searchParams]);

  const { getSkillHasUser, getSkillUserFilteringData } = useSkillService();

  const skillHasUser = useAsync(
    () => getSkillHasUser(skillUserSearchParams),
    [skillUserSearchParams]
  );
  const skillUserFilteringData = useAsync(getSkillUserFilteringData);

  const formValueConverter = (formValue) => {
    return {
      ...formValue,
      leaders: formValue.leaders.map((obj) => obj.id),
      employees: formValue.employees.map((obj) => obj.id),
    };
  };

  const [
    openCreateSkillDialog,
    createSkillDialogFormValue,
    handleOpenCreateSkillDialog,
    handleCloseCreateSkillDialog,
  ] = useDialogWithFormValue({ formValueConverter });

  const onCloseCreateSkillDialog = (saved = false) => {
    if (saved) {
      setSkillHasUserLocaly(undefined);
      props.onChanges();
      skillHasUser.refetch();
    }
    handleCloseCreateSkillDialog();
  };

  const [skillHasUserLocaly, setSkillHasUserLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (skillHasUser.loading) {
      return;
    }
    setSkillHasUserLocaly(skillHasUser.value.results);
    setCountRecords(skillHasUser.value.count);
  }, [skillHasUser.loading]);

  return (
    <LoaderWrapper
      showLoader={
        skillHasUserLocaly === undefined || skillUserFilteringData.loading
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12} textAlign={"left"}>
          <Button
            variant="contained"
            size="small"
            startIcon={
              <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
            }
            onClick={() => handleOpenCreateSkillDialog()}
          >
            {t("other.skill_user.add_team")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <SkillHasUserTable
            skillHasUser={skillHasUserLocaly}
            filteringData={skillUserFilteringData.value}
            onClickEdit={handleOpenCreateSkillDialog}
            filterPrefix={SKILL_USER_TABLE_FILTER_PREFIX}
            countRecords={countRecords}
            page={page}
            onPageChange={handleChangePageWithSearchParams}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            initialExpandedFilterHeader={
              !isSearchParamsObjectEmpty(skillUserSearchParams)
            }
            resetPageNumber={resetPageNumber}
          />
        </Grid>
      </Grid>
      {openCreateSkillDialog && (
        <CreateSkillDialog
          onClose={onCloseCreateSkillDialog}
          open={openCreateSkillDialog}
          initFormValue={createSkillDialogFormValue}
        />
      )}
    </LoaderWrapper>
  );
};

SkillUser.propTypes = {
  onChanges: PropTypes.func,
};

export default SkillUser;
