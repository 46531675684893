import React, { useEffect, useMemo, useRef, useState } from "react";
import TextFieldFormControl from "../TextFieldFormControl";
import PropTypes from "prop-types";
import useFieldTable from "../../../hooks/useFieldTable";
import DivField from "../DivField/DivField";
import useField from "../../../hooks/useField";

export const sxTextFieldTable = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 0,
      borderColor: "transparent",
    },
    fontSize: "0.875rem",
    "& .MuiInputBase-input": {
      textAlign: "center",
      minWidth: "fit-content",
    },
  },
};

const TextFieldTable = React.memo((props) => {
  const { fieldTableRef, isSelected, onSelectField, onBlur } = useFieldTable();

  const { getInputWidth, inputStyleTable } = useField();

  const inputStyleField = useMemo(() => {
    return { minWidth: getInputWidth(props.value), ...inputStyleTable };
  }, [props.value, inputStyleTable]);

  const inputPropsField = useMemo(() => {
    return { style: inputStyleField };
  }, [inputStyleField]);

  const onBlurLocal = (event) => {
    if (props.onBlur) {
      props.onBlur();
    }
    onBlur();
  };

  return (
    <>
      {
        isSelected ? (
          <TextFieldFormControl
            {...props}
            id="outlined-required"
            label=""
            fullWidth={props.fullWidth !== undefined ? props.fullWidth : true}
            value={props.value}
            disabled={props.disabled}
            size="small"
            type={props.type}
            name={props.name}
            onChange={props.onChange}
            readOnly={props.readOnly}
            min={props.min}
            max={props.max}
            sx={sxTextFieldTable}
            style={props.style}
            required={props.required}
            multiline={props.multiline}
            rows={props.rows}
            onBlur={onBlurLocal}
            inputRef={fieldTableRef}
            isInvalid={props.isInvalid}
            inputProps={inputPropsField}
            suffix = {props.suffix}
          />
        ) : (
          <DivField

            {...props}
            onSelectField={onSelectField}
            onFocus={onSelectField}
          /> 
        )
      }
    </>
  );
});

TextFieldTable.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  style: PropTypes.object,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  isInvalid: PropTypes.bool,
};

TextFieldTable.defaultProps = {
  disabled: false,
  required: false,
  multiline: false,
  rows: 1,
};

export default TextFieldTable;
