import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import AutocompleteField from "../../field/AutocompleteField";
import { isEmptyValue } from "../../../helpers/methods";

const CreateSkillForm = ({
  onChange,
  onChangeAutocompleteFieldWithObjectOptions,
  skillName,
  leaders,
  employees,
  description,
  employeesSelectList,
  leaderSelectList,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextFieldFormControl
          onChange={onChange}
          name={"skill_name"}
          value={skillName}
          label={t("form.create_skill.skill_name")}
          required={true}
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteField
          multiple
          valueObject={true}
          optionLabelKey={"full_name"}
          name="leaders"
          value={leaders}
          options={leaderSelectList}
          label={t("form.create_skill.leaders")}
          onChange={onChangeAutocompleteFieldWithObjectOptions}
          required={true}
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteField
          multiple
          valueObject={true}
          optionLabelKey={"full_name"}
          name="employees"
          value={employees}
          options={employeesSelectList}
          label={t("form.create_skill.employees")}
          onChange={onChangeAutocompleteFieldWithObjectOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          addEmptyOptions={false}
          name="skill_desc"
          label={t("form.create_skill.description")}
          value={description}
          onChange={onChange}
          multiline
          rows={2}
        />
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          fullWidth
          variant="contained"
          size="small"
          onClick={onSubmit}
          disabled={isEmptyValue(skillName) || isEmptyValue(leaders)}
        >
          {t("save")}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default CreateSkillForm;
