import DivSelectField from "../DivSelectField/DivSelectField";
import SelectField from "../SelectField/SelectField";
import PropTypes from "prop-types";
import useFieldTable from "../../../hooks/useFieldTable";
import useField from "../../../hooks/useField";

function SelectFieldTable(props) {
  const { fieldTableRef, isSelected, onSelectField, onBlur } = useFieldTable();

  const { inputStyleTable } = useField();

  const onChangeLocal = (event) => {
    props.onChange(event);
    onBlur(event);
  };

  const onBlurLocal = (event) => {
    setTimeout(() => {
      onBlur(event);
    }, 200);
  };

  return (
    <>
      {isSelected ? (
        <SelectField
          style={{
            fontSize: "0.875rem",
            borderRadius: 0,
            textAlign: "center",
            ...inputStyleTable,
          }}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: props.showBorder ? undefined : "transparent",
            },
            ".MuiOutlinedInput-input": {
              padding: 0,
            },
          }}
          name={props.name}
          value={props.value}
          options={props.options}
          onChange={onChangeLocal}
          isObjectOption={props.isObjectOption}
          optionLabel={props.optionLabel}
          optionKey={props.optionKey}
          readOnly={props.readOnly}
          ref={fieldTableRef}
          onBlur={onBlurLocal}
          open={isSelected}
          multiple={props.multiple}
        />
      ) : (
        <DivSelectField
          {...props}
          onSelectField={onSelectField}
          onFocus={onSelectField}
          multiple={props.multiple}
        />
      )}
    </>
  );
}

SelectFieldTable.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  isObjectOption: PropTypes.bool,
  optionLabel: PropTypes.string,
  optionKey: PropTypes.string,
  readOnly: PropTypes.bool,
  showBorder: PropTypes.bool,
};

SelectFieldTable.defaultProps = {
  readOnly: false,
  options: [],
  optionLabel: "name",
  optionKey: "id",
};

export default SelectFieldTable;
