import PropTypes from "prop-types";

import i18n from "../../../i18n";

import SplitButton from "../SplitButton";

function SelectBarPieChartButton(props) {
  const filterOptions = [
    {
      label:
        props.barChartLabel || i18n.t("button.select_chart_button.bar_chart"),
      key: props.barChartKey,
      callback: () => props.onChangeFilterValue(props.barChartKey),
    },
    {
      label:
        props.pieChartLabel || i18n.t("button.select_chart_button.pie_chart"),
      key: props.pieChartKey,
      callback: () => props.onChangeFilterValue(props.pieChartKey),
    },
  ];

  return (
    <SplitButton
      selectedItemKey={props.selectedFilterKey}
      withChangeSelectedItem={true}
      color={props.color}
      options={filterOptions}
    />
  );
}

SelectBarPieChartButton.propTypes = {
  barChartKey: PropTypes.string,
  pieChartKey: PropTypes.string,
  selectedFilterKey: PropTypes.string,
  onChangeFilterValue: PropTypes.func,
  color: PropTypes.string,
};

SelectBarPieChartButton.defaultProps = {
  barChartKey: "bar_chart",
  pieChartKey: "pie_chart",
  color: "primary",
};

export default SelectBarPieChartButton;
