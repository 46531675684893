import i18n from "../../../i18n";

import { TEXT_FIELD_TYPE } from "../../../helpers/constants";

export const ROLE_DIALOG_TABLE_FIELDS_CONFIG = [
  {
    name: "role_name",
    getValueCallback: (rowData) => rowData.role_name,
    label: i18n.t("table.role.role_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "role_desc",
    getValueCallback: (rowData) => rowData.role_desc,
    label: i18n.t("table.role.role_desc"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    maxTextLength: 1000,
  },
];
