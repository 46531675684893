import { Button, Grid } from "@mui/material";
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LoadingButton from "@mui/lab/LoadingButton";

export default function BottomOfferGenerateButtonsBox(props) {
    const { t } = useTranslation();
    
    return (
        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          style={{ marginBottom: "10px" }}>
          <Grid item xs={3}>
            {!props.readOnly &&
              <LoadingButton
                fullWidth
                variant="contained"
                color="primary"
                loading={props.loading}
                disabled={!props.validatedOffer.offer_accept_rr}
                onClick={() => props.onSendOfferToRR(props.validatedOffer.id)}>
                {t("dialog.generate_offer_dialog.send_for_verify_to_rr")}
              </LoadingButton>
            }
          </Grid>
          <Grid item xs={3}>
            {!props.readOnly &&
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={props.onSendOfferToOM}
                disabled={!props.validatedOffer.is_allowed_to_send_om} >
                {props.validatedOffer.offer_internal?
                t("dialog.generate_offer_dialog.accept_internal_offer")
                :
                t("dialog.generate_offer_dialog.confirm_and_send_to_om")}
              </Button>
            }
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={props.fileIsEmpty}
              onClick={() => props.downloadFileByBlobUrl(props.validatedOffer.id, `${props.validatedOffer.offer_nr}.pdf`)}
            >
              {t("dialog.generate_offer_dialog.download_offer_document")}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={props.onClose}>
              {t("close")}
            </Button>
          </Grid>
        </Grid>
      );
}

BottomOfferGenerateButtonsBox.propTypes = {
    onClose: PropTypes.func,
    readOnly: PropTypes.bool,
    validatedOffer: PropTypes.object,
    onSendOfferToRR: PropTypes.func,
    onSendOfferToOM: PropTypes.func,
    downloadFileByBlobUrl : PropTypes.func,
    fileIsEmpty : PropTypes.bool,
    pdfUrl : PropTypes.string,
    loading : PropTypes.bool
}


BottomOfferGenerateButtonsBox.defaultProps = {
    readOnly: true,
    validatedOffer: {},
    fileIsEmpty : true,
    loading : false

}
