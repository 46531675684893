import { Grid } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";

import EnclosureTableWithToolBar from "./EnclosureTableWithToolBar";
import DocsRestrictedTableWithToolBar from "./DocsRestrictedTableWithToolBar";
import MyDocumentsExternalSearchBar from "./MyDocumentsExternalSearchBar";

import {
  ENCLOUSURE_TABLE_FILTER_PREFIX,
  DOCS_RESTRICTED_TABLE_FILTER_PREFIX,
} from "./PageTablesConfig";

export default function MyDocumentsExternalPage(props) {
  const { pageName } = props;

  return (
    <NavigationDrawer pageName={pageName}>
      <DefaultPageWrapper titleKey={"my_documents"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={3}
          marginTop={1}
        >
          <Grid item xs={12}>
            <MyDocumentsExternalSearchBar
              tableFilterPrefixes={[
                ENCLOUSURE_TABLE_FILTER_PREFIX,
                DOCS_RESTRICTED_TABLE_FILTER_PREFIX,
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <DocsRestrictedTableWithToolBar />
          </Grid>
          <Grid item xs={12}>
            <EnclosureTableWithToolBar />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
