import React, { useRef } from "react";

import PropTypes from "prop-types";
import i18n from "../../../../i18n";

import useDialog from "../../../../hooks/useDialog";

import { Grid } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

import AutocompleteField from "../../../field/AutocompleteField";
import TextFieldFormControl from "../../../field/TextFieldFormControl";

import UserDetailsDialog from "../../../dialog/UserDetailsDialog";
import TenantDetailsDialog from "../../../dialog/TenantDetailsDialog";

import FieldWithLabel from "../../../field/FieldWithLabel";

const PersonsForm = ({
  taskTId,
  taskTIOptions,
  onChangeTaskT,
  isTaskTAllowedToChange,
  ownerName,
  ownerId,
  omName,
  omId,
  tenantName,
  tenantId,
  isResponsiblePersonAllowedToChange,
  itemType,
  reposoniblePersonId,
  reposoniblePersonOptions,
  onChangeResponsiblePerson,
  skillId,
  skillOptions,
  onChangeSkill,
  isSkillAllowedToChange,
  orderTId,
  onChangeOrderT,
  isOrderTAllowedToChange,
  orderTOptions,
}) => {
  const [
    openUserDetailsDialog,
    onOpenUserDetailsDialog,
    onCloseUserDetailsDialog,
  ] = useDialog();

  const [
    openTenantDetailsDialog,
    onOpenTenantDetailsDialog,
    onCloseTenantDetailsDialog,
  ] = useDialog();

  const selectedUserId = useRef();
  const userDetailsDialogTitle = useRef();
  const handleOpenUserDetailsDialog = (userId, title) => {
    selectedUserId.current = userId;
    userDetailsDialogTitle.current = title;

    onOpenUserDetailsDialog();
  };

  const getFieldNameForResponsiblePerson = () => {
    return itemType === "order"
      ? "order_kt"
      : itemType === "task"
      ? "task_kt"
      : "ticket_k";
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.ticket.ticket_owner")}
          field={
            <TextFieldFormControl
              value={ownerName}
              suffix={
                ownerId ? (
                  <PersonIcon
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleOpenUserDetailsDialog(ownerId, "applicant")
                    }
                  />
                ) : null
              }
              readOnly
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.ticket.ticket_om")}
          field={
            <TextFieldFormControl
              value={omName}
              suffix={
                omId ? (
                  <PersonIcon
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleOpenUserDetailsDialog(omId, "ticket_om")
                    }
                  />
                ) : null
              }
              readOnly
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.ticket.tenant")}
          field={
            <TextFieldFormControl
              value={tenantName}
              suffix={
                tenantId ? (
                  <PersonIcon
                    style={{ cursor: "pointer" }}
                    onClick={onOpenTenantDetailsDialog}
                  />
                ) : null
              }
              readOnly
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.ticket.responsilbe_person")}
          field={
            <AutocompleteField
              name={getFieldNameForResponsiblePerson()}
              value={reposoniblePersonId}
              options={reposoniblePersonOptions}
              isObjectOption={true}
              optionLabelKey={"full_name"}
              onChange={onChangeResponsiblePerson}
              disableClearable
              readOnly={!isResponsiblePersonAllowedToChange}
            />
          }
        />
      </Grid>
      {(itemType === "order" || itemType === "task") && (
        <Grid item xs={12}>
          <FieldWithLabel
            label={i18n.t("form.order.skill")}
            field={
              <AutocompleteField
                name={"skill"}
                value={skillId}
                options={skillOptions}
                isObjectOption={true}
                optionLabelKey={"skill_name"}
                onChange={onChangeSkill}
                disableClearable
                readOnly={!isSkillAllowedToChange}
              />
            }
          />
        </Grid>
      )}
      {itemType === "order" && (
        <Grid item xs={12}>
          <FieldWithLabel
            label={i18n.t("form.order.order_t")}
            field={
              <AutocompleteField
                name={"order_t"}
                value={orderTId}
                options={orderTOptions}
                isObjectOption={true}
                optionLabelKey={"full_name"}
                onChange={onChangeOrderT}
                readOnly={!isOrderTAllowedToChange}
              />
            }
          />
        </Grid>
      )}
      {itemType === "task" && (
        <Grid item xs={12}>
          <FieldWithLabel
            label={i18n.t("form.task.task_t")}
            field={
              <AutocompleteField
                name={"task_t"}
                value={taskTId}
                options={taskTIOptions}
                isObjectOption={true}
                optionLabelKey={"full_name"}
                onChange={onChangeTaskT}
                disableClearable
                readOnly={!isTaskTAllowedToChange}
              />
            }
          />
        </Grid>
      )}
      {openUserDetailsDialog && (
        <UserDetailsDialog
          open={openUserDetailsDialog}
          onClose={onCloseUserDetailsDialog}
          userId={selectedUserId.current}
          title={userDetailsDialogTitle.current}
        />
      )}
      {openTenantDetailsDialog && (
        <TenantDetailsDialog
          open={openTenantDetailsDialog}
          onClose={onCloseTenantDetailsDialog}
          tenantId={tenantId}
        />
      )}
    </Grid>
  );
};

PersonsForm.propTypes = {
  taskTId: PropTypes.string,
  taskTIOptions: PropTypes.array,
  onChangeTaskT: PropTypes.func,
  isTaskTAllowedToChange: PropTypes.bool,
  ownerId: PropTypes.string,
  tenantName: PropTypes.string,
  tenantId: PropTypes.string,
  reposoniblePersonId: PropTypes.string,
  onChangeResponsiblePerson: PropTypes.func,
  isResponsiblePersonAllowedToChange: PropTypes.bool,
  reposoniblePersonOptions: PropTypes.array,
  skillId: PropTypes.string,
  skillOptions: PropTypes.array,
  onChangeSkill: PropTypes.func,
  isSkillAllowedToChange: PropTypes.bool,
  itemType: PropTypes.oneOf(["ticket", "order", "task"]),
};

PersonsForm.defaultProps = {};

export default PersonsForm;
