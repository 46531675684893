import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import { useAsync } from "../../../../hooks/useAsync";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import useDialog from "../../../../hooks/useDialog";
import { SIDE_ADMINISTRATION_TABLE_CONFIG } from "./TableConfig";
import TableService from "../../../../services/tableService";
import FilterTable from "../../../table/FilterTable";
import { useEmptyArrayMemo } from "../../../../hooks/useEmptyArrayMemo";
import { useSearchParams } from "react-router-dom";
import useFilterSearchParams from "../../../../hooks/useFilterSearchParams";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import SearchButton from "../../../button/SearchButton/SearchButton";
import FilterDialog from "../../../base/FilterDialog";
import UniversalFilterForm from "../../../form/UniversalFilterForm";
import useDialogWithId from "../../../../hooks/useDialogWithId";
import useCorrespondenceAdminService from "../../../../services/correspondenceAdminService";
import CreateSideAdministrationDialog from "../../../dialog/CreateSideAdministrationDialog/CreateSideAdministrationDialog";


const SIDE_ADMINISTRATION_FILTER_PREFIX = "side_administration";

const CorrespondenceAdminSideAdministrationTab = (props) => {
  const { t } = useTranslation();
  const [sideAdministrationsLocal, setSideAdministrationsLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sideAdministrationTableSearchParams, setSideAdministrationTableSearchParams] = useState({});

  const {
    setNewItemSearchParamsIfAreChanged,
    clearSearchParamsByFilterPrefix
  } = useFilterSearchParams(searchParams);

  const { emptyArrayMemo } = useEmptyArrayMemo();
  const { getSideAdministrationList } = useCorrespondenceAdminService();

  const onCleanFlitersInFilterDialog = () => {
    setSearchParams(clearSearchParamsByFilterPrefix(SIDE_ADMINISTRATION_FILTER_PREFIX));
  };

  const [
    openSideAdministrationDialog,
    sideAdministrationChangeId,
    onOpenSideAdministrationDialog,
    onCloseSideAdministrationDialog,
  ] = useDialogWithId();

  const [
    openFilterDialog,
    onOpenFilterDialog,
    onCloseFilterDialog
  ] = useDialog();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
  } = usePaginationWithSearchParams(SIDE_ADMINISTRATION_FILTER_PREFIX);

  const sideAdministrationData = useAsync(
    () => getSideAdministrationList(sideAdministrationTableSearchParams),
    [sideAdministrationTableSearchParams]
  );

  useEffect(() => {
    if (sideAdministrationData.loading) {
      return;
    }
    setSideAdministrationsLocal(sideAdministrationData.value.results);
    setCountRecords(sideAdministrationData.value.count);
  }, [sideAdministrationData.loading]);

  const isLoading = sideAdministrationsLocal === undefined|| props.filteringData.loading;

  const handleOpenSideAdministrationDialog = useCallback(
    (e, sideAdministrationId) => {
      e.stopPropagation();
      onOpenSideAdministrationDialog(sideAdministrationId);
    },
    []
  );

  const refreshTable = () => {
    setSideAdministrationsLocal([]);
    sideAdministrationData.refetch();
  };

  const data = useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        SIDE_ADMINISTRATION_TABLE_CONFIG,
        sideAdministrationsLocal ? sideAdministrationsLocal : emptyArrayMemo,
        emptyArrayMemo,
        emptyArrayMemo,
        handleOpenSideAdministrationDialog,
      ),
    [sideAdministrationsLocal, SIDE_ADMINISTRATION_TABLE_CONFIG]
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      SIDE_ADMINISTRATION_FILTER_PREFIX,
      sideAdministrationTableSearchParams,
      setSideAdministrationTableSearchParams
    );
  }, [searchParams]);



  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={1}
    >
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
          onClick={(e) => onOpenSideAdministrationDialog(undefined)}
        >
          {t("tabs.correspondence_admin.add_side_administration")}
        </Button>
      </Grid>
      <Grid item sm={9} />
      <Grid item xs={12} sm={1}>
        <SearchButton onClickSearch={onOpenFilterDialog} />
      </Grid>
      <Grid item xs={12}>
        <FilterTable
          data={data}
          showCheckbox={false}
          showCleanFilterIcon={true}
          countRecords={countRecords}
          page={page}
          onPageChange={handleChangePageWithSearchParams}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          filteringData={props.filteringData}
          style={{ maxHeight: "vh" }}
          tableConfig={SIDE_ADMINISTRATION_TABLE_CONFIG}
          showContextMenu={false}
          filterPrefix={SIDE_ADMINISTRATION_FILTER_PREFIX}
          headersConfig={TableService.getHeadersConfigForCollapsibleTable(
            SIDE_ADMINISTRATION_TABLE_CONFIG,
            props.filteringData || emptyArrayMemo,
            emptyArrayMemo,
            emptyArrayMemo,
          )}
        />
      </Grid>
      {openFilterDialog &&
        <FilterDialog
          open={openFilterDialog}
          onCleanFilters={onCleanFlitersInFilterDialog}
          onClose={onCloseFilterDialog}
          resetPageNumber={resetPageNumber}
          filterPrefix={SIDE_ADMINISTRATION_FILTER_PREFIX}
          filterForm={
            <UniversalFilterForm
              filteringData={props.filteringData || emptyArrayMemo}
              filtersConfig={SIDE_ADMINISTRATION_TABLE_CONFIG}
              includeOpenCloseFilter={false}
            />
          }
        />
      }
      {openSideAdministrationDialog &&
        <CreateSideAdministrationDialog
          sideAdministrationId={sideAdministrationChangeId}
          open={openSideAdministrationDialog}
          onClose={onCloseSideAdministrationDialog}
          onRefetchData={refreshTable}
          filteringData={props.filteringData}
        />
      }
    </Grid>
  );
};

CorrespondenceAdminSideAdministrationTab.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

CorrespondenceAdminSideAdministrationTab.defaultProps = {};

export default CorrespondenceAdminSideAdministrationTab;
