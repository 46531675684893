import React, { useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import BasicDrawer from "./BasicDrawer";

// import { useState } from "react";
// import NavigationDrawer from "./NavigationDrawer";
// import {
//   openMiniDrawerWidth,
//   closeMiniDrawerWidth,
// } from "./MiniDrawer/MiniDrawer";

export default function GridDrawer(props) {
  const theme = useTheme();
  const topComponent = useRef(null);

  useEffect(() => {
    scrollToTop();
  }, [props.itemData]);

  const scrollToTop = () => {
    topComponent.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest', 
    });
  };

  // const leftDrawerWidth = openLeftDrawer
  //   ? `${openMiniDrawerWidth}px`
  //   : `${closeMiniDrawerWidth}px`;
  // const rightDrawerWidth = props.openDrawer ? `${props.drawerWidth}px` : "0px";
  // const width = `calc(100% - ${rightDrawerWidth} - ${leftDrawerWidth} +  ${theme.spacing(
  //   props.spacing
  // )})`;

  return (
    <div ref={topComponent} >
    <Grid
      container
      spacing={props.spacing}
      style={
        props.openDrawer
          ? {
              width: props.openDrawer && props.isPageDrawer
                ? `calc(100% - ${props.drawerWidth}px + ${theme.spacing(
                    props.spacing
                  )})`
                : undefined,
              transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }
          : {
              transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            }
      }
    >
      <BasicDrawer
        open={props.openDrawer}
        drawerWidth={props.drawerWidth}
        onClose={() => {
          props.setOpenDrawer(false);
        }}
        
        onOpen={() => {
          props.setOpenDrawer(true);
        }}
        title={props.drawerTitle}
        isLoading={props.isLoading}
        arrowIcon={props.arrowIcon}
        showMiniDrawer = {props.showMiniDrawer}
      >
        {props.drawerContentElement}
      </BasicDrawer>
      {/* <NavigationDrawer
        open={openLeftDrawer}
        setOpen={setOpenLeftDrawer}
        pageName={props.pageName}
      />
      <div style={{ width: width }}>{props.children}</div> */}
      {props.children}
    </Grid>
    </div>
  );
}

GridDrawer.propTypes = {
  openDrawer: PropTypes.bool,
  drawerWidth: PropTypes.number,
  setOpenDrawer: PropTypes.func,
  drawerTitle: PropTypes.string,
  arrowIcon: PropTypes.bool,
  drawerContentElement: PropTypes.element,
  isLoading: PropTypes.bool,
  pageName: PropTypes.string,
  showMiniDrawer : PropTypes.bool,
  isPageDrawer : PropTypes.bool
};

GridDrawer.defaultProps = {
  openDrawer: false,
  drawerWidth: 350,
  arrowIcon: false,
  isLoading: false,
  showMiniDrawer : false,
  isPageDrawer : true
};
