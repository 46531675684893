import i18n from "../../../../../i18n";

import {
  TEXT_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
} from "../../../../../helpers/constants";

export const USER_TENANT_TABLE_NAME = "user_tenant_table";
export const USER_TENANT_TABLE_FILTER_PREFIX = "locations__user_tenant";
export const USER_TENANT_TABLE_CONFIG = [
  {
    name: "user__first_name",
    getValueCallback: (rowData) => rowData.user__first_name,
    label: i18n.t("table.user_tenant_table.user__first_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "user__last_name",
    getValueCallback: (rowData) => rowData.user__last_name,
    label: i18n.t("table.user_tenant_table.user__last_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.user_tenant_table.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "user__mail",
    getValueCallback: (rowData) => rowData.user__mail,
    label: i18n.t("table.user_tenant_table.user__mail"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "user__phone",
    getValueCallback: (rowData) => rowData.user__phone,
    label: i18n.t("table.user_tenant_table.user__phone"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },

  {
    name: "band",
    getValueCallback: (rowData) => rowData.band,
    label: i18n.t("table.user_tenant_table.band"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.band,
      isObjectOption: true,
      optionLabelKey: "band_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "uste_band_tenant",
    getValueCallback: (rowData) => rowData.uste_band_tenant,
    label: i18n.t("table.user_tenant_table.uste_band_tenant"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "uste_note",
    getValueCallback: (rowData) => rowData.uste_note,
    label: i18n.t("table.user_tenant_table.uste_note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },

  {
    name: "responsibilities",
    getValueCallback: (rowData) => rowData.responsibilities,
    label: i18n.t("table.user_tenant_table.responsibilities"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.responsibilities,
      isObjectOption: true,
      optionLabelKey: "responsibility_name",
      addNewValue: false,
      multiple: true,
    }),
  },
];
