import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useForm } from "../../../hooks/useForm";

import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import QuestionAdminForm from "../../form/QuestionAdminForm";
import useFormService from "../../../services/formService";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import MiniButton from "../../base/MiniButton/MiniButton";

import { getErrorMsg, isEmptyValue } from "../../../helpers/methods";

import {
  DIALOG_PREVIEW_MODE,
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
} from "../../../helpers/constants";

const REQUIRED_FIELDS = [
  "question_text",
  "question_text_eng",
  "question_type",
  "answers",
  "question_active",
];

const ANSWERS_REQUIRED_FIELDS = [
  "id",
  "quan_content",
  "quan_content_eng",
  "quan_active",
  // "quan_order",
  // "std_orders",
];

const FIELDS_TO_SAVE = [...REQUIRED_FIELDS, "question_active"];

const QuestionAdminDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const dialogTitle = useMemo(() => {
    if (props.title) return props.title;

    return props.dialogMode === DIALOG_CREATE_MODE
      ? t("dialog.question.add_new_question")
      : props.dialogMode === DIALOG_EDIT_MODE
      ? t("dialog.question.edit_question")
      : t("dialog.question.question_details");
  }, [props.dialogMode, props.title]);

  const isReadOnly = useMemo(
    () => props.readOnly || props.dialogMode === DIALOG_PREVIEW_MODE,
    [props.readOnly, props.dialogMode]
  );

  const fieldsToSave = useMemo(
    () => props.fieldsToSave || FIELDS_TO_SAVE,
    [props.fieldsToSave]
  );

  const {
    getQuestionAdminDetailsData,
    getQuestionAdminFilteringData,
    getQuestionAnswerAdminFilteringData,
    createQuestion,
    updateQuestion,
    getEmptyAnswerObject,
  } = useFormService();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm();

  const {
    formValue: answersFormValue,
    setFormValue: setAnswersFormValue,
    onChangeInArrayForm: onChangeAnswersInArrayForm,
    onChangeAutocompleteFieldWithObjectOptionsInArrayForm:
      onChangeAnswersAutocompleteFieldWithObjectOptionsInArrayForm,
  } = useForm();

  const handleAddAnwser = () => {
    setAnswersFormValue((prev) => {
      return [...prev, getEmptyAnswerObject()];
    });
  };

  const handleChangeAnswerOrder = (sourceIdx, destinationIdx) => {
    setAnswersFormValue((prev) => {
      let answers = [...prev];

      const [reorderedItem] = answers.splice(sourceIdx, 1);
      answers.splice(destinationIdx, 0, reorderedItem);

      return answers;
    });
  };

  const questionInitialData = useAsync(() => {
    if (props.dialogMode === DIALOG_CREATE_MODE) {
      return Promise.resolve({
        question_type: "select",
        question_active: true,
        answers: [getEmptyAnswerObject(), getEmptyAnswerObject()],
      });
    }

    return getQuestionAdminDetailsData(props.questionId);
  }, [props.questionId, props.dialogMode]);

  useEffect(() => {
    setFormValue(questionInitialData.value);
    setAnswersFormValue(questionInitialData.value?.answers);
  }, [questionInitialData.loading]);

  const prepareDataToSend = () => {
    const dataToSend = {};
    for (const [key, value] of Object.entries(formValue)) {
      if (fieldsToSave.includes(key)) {
        dataToSend[key] = value;
      }
    }

    dataToSend["answers"] = answersFormValue.map((answer, idx) => ({
      ...answer,
      quan_nextQ: answer.quan_nextQ || null,
      quan_order: idx + 1,
    }));

    return dataToSend;
  };

  const questionFilteringData = useAsync(getQuestionAdminFilteringData);
  const questionAnswerFilteringData = useAsync(
    getQuestionAnswerAdminFilteringData
  );

  const updateQuestionFn = useAsyncFn(updateQuestion);
  const addQuestionFn = useAsyncFn(createQuestion);
  const submitFn = useMemo(
    () =>
      props.dialogMode === DIALOG_CREATE_MODE
        ? addQuestionFn
        : updateQuestionFn,
    [props.dialogMode]
  );

  const handleSubmit = (dataToSend) => {
    submitFn
      .execute(dataToSend, props.questionId)
      .then((res) => {
        const successMsg =
          props.dialogMode === DIALOG_CREATE_MODE
            ? t("snackbar_alert.question_added")
            : t("snackbar_alert.question_updated");
        snackbarAlert.openSuccessSnackbarAlert(successMsg);
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const [dialogContentScrollPosition, setDialogContentScrollPosition] =
    useState(false);
  const handleScrollDialogContent = (e) => {
    setDialogContentScrollPosition(e.target.scrollTop);
  };

  const isFormValid =
    formValue &&
    REQUIRED_FIELDS.every((field) => !isEmptyValue(formValue[field])) &&
    answersFormValue.length >= 2 &&
    answersFormValue.every((answer) =>
      ANSWERS_REQUIRED_FIELDS.every((field) => !isEmptyValue(answer[field]))
    );

  const isLoading =
    formValue === undefined ||
    answersFormValue === undefined ||
    questionFilteringData.loading ||
    questionAnswerFilteringData.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
        rowGap={1}
        columnSpacing={2}
      >
        <Grid item xs={12} sm={4} textAlign={"end"}>
          <MiniButton
            variant="contained"
            size="small"
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={handleAddAnwser}
          >
            {t("dialog.question.add_new_question_answer")}
          </MiniButton>
        </Grid>
        <Grid item xs={12}>
          <QuestionAdminForm
            formValue={formValue}
            awswersFormValue={answersFormValue}
            onChange={onChange}
            onChangeAutocompleteFieldWithObjectOptions={
              onChangeAutocompleteFieldWithObjectOptions
            }
            questionFilteringData={questionFilteringData.value}
            questionAnswerFilteringData={questionAnswerFilteringData.value}
            onChangeAnswersInArrayForm={onChangeAnswersInArrayForm}
            onChangeAnswersAutocompleteFieldWithObjectOptionsInArrayForm={
              onChangeAnswersAutocompleteFieldWithObjectOptionsInArrayForm
            }
            readOnly={false}
            onChangeAnswerOrder={handleChangeAnswerOrder}
            dialogContentScrollPosition={dialogContentScrollPosition}
          />
        </Grid>

        {!isReadOnly && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={addQuestionFn.loading || updateQuestionFn.loading}
              disabled={!isFormValid}
              onClick={() => handleSubmit(prepareDataToSend())}
            >
              {t("save")}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      showTopFullScreenButton={true}
      title={dialogTitle}
      maxWidth="lg"
      showDialogActions
      onScrollDialogContent={handleScrollDialogContent}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

QuestionAdminDialog.propTypes = {
  dialogMode: PropTypes.oneOf([
    DIALOG_CREATE_MODE,
    DIALOG_EDIT_MODE,
    DIALOG_PREVIEW_MODE,
  ]),
  readOnly: PropTypes.bool,
  questionId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onSubmitCallback: PropTypes.func,
  fieldsToSave: PropTypes.array,
};

QuestionAdminDialog.defaultProps = {
  open: false,
};

export default QuestionAdminDialog;
