import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import BasicDialog from "../../base/BasicDialog";
import FullWidthTabs from "../../tabs/FullWidthTabs/FullWidthTabs";
import SkillUser from "../../other/SkillUser";
import UserSkill from "../../other/UserSkill";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import PropTypes from "prop-types";
import SchedulePlan from "../../other/SchedulePlan";
import ScheduleDone from "../../other/ScheduleDone";

const ManagementTeamsDialog = ({
  open,
  onClose,
  userIsLeaderForSkillsData,
}) => {
  const { t } = useTranslation();
  const [existsChanges, setExistChanges] = useState(false);
  const handleSetExistsChanges = useCallback(() => setExistChanges(true), []);

  return (
    <BasicDialog
      open={open}
      onClose={() => onClose(existsChanges)}
      maxWidth={"lg"}
      titleAlign="center"
      title={t("dialog.management_teams_dialog.management_teams")}
      contentSx={{ paddingTop: 0 }}
      diableDraggable
    >
      <LoaderWrapper>
        <FullWidthTabs
          items={[
            {
              label: t("tabs.management_teams.schedule_plan"),
              content: <SchedulePlan onChanges={handleSetExistsChanges} />,
            },
            {
              label: t("tabs.management_teams.schedule_done"),
              content: <ScheduleDone onChanges={handleSetExistsChanges} />,
            },
            {
              label: t("tabs.management_teams.teams"),
              content: <SkillUser onChanges={handleSetExistsChanges} />,
            },
            {
              label: t("tabs.management_teams.employees"),
              content: <UserSkill onChanges={handleSetExistsChanges} />,
            },
          ]}
        />
      </LoaderWrapper>
    </BasicDialog>
  );
};

ManagementTeamsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

ManagementTeamsDialog.defaultProps = {};

export default ManagementTeamsDialog;
