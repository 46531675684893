import React, { useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import LocationRecord from "../LocationRecord/LocationRecord";
import useLocationService from "../../../services/locationService";
import { isEmptyValue } from "../../../helpers/methods";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

const LocationRecords = React.memo(
  React.forwardRef((props, ref) => {
    const { getEmptyLocationElement, getLevelFieldId } = useLocationService();

    const [unfillRequiredFields, setUnfillRequiredFields] = useState([]);

    useImperativeHandle(ref, () => ({
      checkIfRequiredFieldsAreFill, // Wartość, którą chcemy udostępnić
    }));

    const onChangeLocation = (location, index) => {
      var locations = [...props.locations];
      locations[index] = location;
      props.onChangeLocations(locations);
    };

    const onDeleteLocation = (index) => {
      var locations = [...props.locations];
      locations.splice(index, 1);
      props.onChangeLocations(locations);
    };

    const onAddLocation = () => {
      var locations = [...props.locations];
      locations.push(getEmptyLocationElement());
      props.onChangeLocations(locations);
    };

    const checkIfRequiredFieldsAreFill = () => {
      if (props.readOnly) return [];
      let unfillFieldsTemp = [];
      let index = 0;
      for (const location of props.locations) {
        for (const location_level of location) {
          if (props.showAllObjectsAndLevels && isEmptyValue(location[0].id)) {
            unfillFieldsTemp.push(getLevelFieldId(index, 0));
          } else if (
            props.showAllObjectsAndLevels &&
            isEmptyValue(location[1].id)
          ) {
            unfillFieldsTemp.push(getLevelFieldId(index, 1));
          } else if (
            !props.showAllObjectsAndLevels &&
            isEmptyValue(location_level.id)
          ) {
            unfillFieldsTemp.push(
              getLevelFieldId(index, location_level.level - 1)
            );
          }
        }
        index += 1;
      }
      setUnfillRequiredFields(unfillFieldsTemp);
      return unfillFieldsTemp;
    };

    return (
      <LoaderWrapper showLoader={props.locationLoading}>
        <Grid container spacing={0.5}>
          {props.locations.map((locationLevels, index) => (
            <Grid item xs={12}>
              <LocationRecord
                readOnly={props.readOnly}
                index={index}
                locationLevels={locationLevels}
                onChangeLocation={onChangeLocation}
                showLocationNumber={props.locations.length > 1}
                showButtonDeleteLocation={props.locations.length > 1}
                onDeleteLocation={onDeleteLocation}
                showButtonAddLocation={
                  props.showAddLocationButton && index === 0
                }
                onAddLocation={onAddLocation}
                unfillRequiredFields={unfillRequiredFields}
                maxAllowedLevelNumber={props.maxAllowedLevelNumber}
                showAllObjectsAndLevels={props.showAllObjectsAndLevels}
                createTicketLocation={props.createTicketLocation}
                ticketOm={props.ticketOm}
                showTitile={props.showLocationRecordTitle}
                displayInRows={props.displayInRows}
                showEmptyLevel={props.showEmptyLevel}
                showSelectObjectLevelButton={props.showSelectObjectLevelButton}
                objectLevelForSearchDialog={props.objectLevelForSearchDialog}
              />
            </Grid>
          ))}
        </Grid>
      </LoaderWrapper>
    );
  })
);
LocationRecords.propTypes = {
  locations: PropTypes.array,
  onChangeLocations: PropTypes.func,
  showAddLocationButton: PropTypes.bool,
  readOnly: PropTypes.bool,
  locationLoading: PropTypes.bool,
  maxAllowedLevelNumber: PropTypes.number,
  showLocationRecordTitle: PropTypes.bool,
  showEmptyLevel: PropTypes.bool,
  showSelectObjectLevelButton: PropTypes.bool,
  objectLevelForSearchDialog: PropTypes.number,
};

LocationRecords.defaultProps = {
  locations: [],
  showAddLocationButton: true,
  readOnly: false,
  createTicketLocation: false,
  maxAllowedLevelNumber: 5,
  showLocationRecordTitle: true,
  displayInRows: false,
  showEmptyLevel: false,
  showSelectObjectLevelButton: false,
  objectLevelForSearchDialog: 4,
};

export default LocationRecords;
