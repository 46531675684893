import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";
import FullWidthTabs from "../FullWidthTabs/FullWidthTabs";
import { useTranslation } from "react-i18next";
import useOrderService from "../../../services/orderService";
import OrderFilterTable from "../../table/OrderFilterTable";
import { ORDER_TABLE_FIELDS_CONFIG } from "../../table/TicketCollapsibleTable/TableConfigs";

import CreateOrderDialog from "../../dialog/CreateOrderDialog";
import MultipleLocalizationDialog from "../../dialog/MutlipleLocalizationDialog";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import useDialog from "../../../hooks/useDialog";
import { Button } from "@mui/material";

import { useAsync } from "../../../hooks/useAsync";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import OfferTable from "../../table/OfferTable/OfferTable";
import useOfferService from "../../../services/offerService";
import { OFFER_TABLE_FIELDS_CONFIG } from "../../../page/OffersManagmentPage/PageTablesConfig";

import {
  overflowButtonStyle,
  centerVericalAlignIconStyle,
} from "../../../helpers/styles";

const OrderTableTab = (props) => {
  const { t } = useTranslation();

  const { getOrderWithoutPaginationData } = useOrderService();
  const orderData = useAsync(
    () => getOrderWithoutPaginationData(`ticket=${props.ticketId}`),
    [props.ticketId]
  );

  const [
    openCreateOrderDialog,
    onOpenCreateOrderDialog,
    onCloseCreateOrderDialog,
  ] = useDialog();

  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();

  const clickedOrderMultipleLocalization = useRef();
  const handleOpenMultipleLocalizationDialog = useCallback(
    (e, orderId) => {
      e.stopPropagation();
      clickedOrderMultipleLocalization.current = orderData.value.find(
        (order) => order.id === orderId
      ).locations;
      onOpenMultipleLocalizationDialog();
    },
    [onOpenMultipleLocalizationDialog, orderData.value]
  );

  const handleCloseMultipleLocalizationDialog = () => {
    clickedOrderMultipleLocalization.current = null;
    onCloseMultipleLocalizationDialog();
  };

  const handleCloseCreateOrderDialog = (refresh) => {
    if (refresh) orderData.refetch();
    onCloseCreateOrderDialog();
  };

  if (orderData.loading) return <LoaderWrapper showLoader={true} />;

  return (
    <div style={{ textAlign: "left" }}>
      {!props.readOnly && (
        <Button
          variant="contained"
          size="small"
          startIcon={
            <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
          }
          onClick={onOpenCreateOrderDialog}
          sx={{ ...overflowButtonStyle, marginBottom: "10px" }}
        >
          {t("tabs.ticket_related_tables.add_order")}
        </Button>
      )}
      <OrderFilterTable
        showChangesCircle={false}
        showDetailsIcon={true}
        data={orderData.value}
        showCheckbox={false}
        showCleanFilterIcon={false}
        hiddenColumns={[]}
        tableConfig={ORDER_TABLE_FIELDS_CONFIG}
        onClickMultipleLocationAlert={handleOpenMultipleLocalizationDialog}
        showFilters={false}
        withPagination={false}
      />
      {openCreateOrderDialog && (
        <CreateOrderDialog
          open={openCreateOrderDialog}
          onClose={handleCloseCreateOrderDialog}
          ticketId={props.ticketId}
        />
      )}
      {openMultipleLocalizationDialog &&
        clickedOrderMultipleLocalization.current && (
          <MultipleLocalizationDialog
            open={openMultipleLocalizationDialog}
            onClose={handleCloseMultipleLocalizationDialog}
            localizationData={clickedOrderMultipleLocalization.current}
            itemType={"order"}
          />
        )}
    </div>
  );
};

const OfferTableTab = (props) => {
  const { getOfferListByTicket } = useOfferService();
  const offerData = useAsync(
    () => getOfferListByTicket(props.ticketId, `page_size=100`),
    [props.ticketId]
  );

  if (offerData.loading) return <LoaderWrapper showLoader={true} />;

  return (
    <OfferTable
      showFilters={false}
      showCleanFilterIcon={false}
      data={offerData.value.results}
      showCheckbox={false}
      withPagination={false}
      hiddenColumns={[]}
      showTitle={false}
      tableConfig={OFFER_TABLE_FIELDS_CONFIG}
      showContextMenu={false}
    />
  );
};

const TicketRelatedTablesTabs = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <FullWidthTabs
      items={[
        {
          label: t("tabs.ticket_related_tables.orders"),
          content: (
            <OrderTableTab
              ticketId={props.ticketId}
              readOnly={props.readOnly}
            />
          ),
        },
        {
          label: t("tabs.ticket_related_tables.offers"),
          content: (
            <OfferTableTab
              ticketId={props.ticketId}
              readOnly={props.readOnly}
            />
          ),
        },
        { label: t("tabs.ticket_related_tables.settlements"), content: null },
      ]}
    />
  );
});

TicketRelatedTablesTabs.propTypes = {
  ticketId: PropTypes.string,
  readOnly: PropTypes.bool,
};

TicketRelatedTablesTabs.defaultProps = {
  readOnly: false,
};

export default TicketRelatedTablesTabs;
