import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { NAVIGATION_DRAWER_TYPE_PAYMENT } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import DateField from "../../components/field/DateField/DateField";
import DateService from "../../services/dateService";

import useOfferService from "../../services/offerService";

import { useForm } from "../../hooks/useForm";

import { overflowButtonStyle } from "../../helpers/styles";

export default function GrtReportPage(props) {
  const { t } = useTranslation();

  const {
    formValue,
    onChangeDateConvertedToDateTimeWithZeroHourOfDate: onChangeStartDate,
    onChangeDateConvertedToDateTimeWithEndHourOfDate: onChangeEndDate,
  } = useForm({ hours_timezone_offset: DateService.getHoursTimezoneOffset() });

  const { getGrtReport } = useOfferService();

  return (
    <NavigationDrawer
      pageName={"grt_report"}
      drawerType={NAVIGATION_DRAWER_TYPE_PAYMENT}
    >
      <DefaultPageWrapper titleKey={"grt_report"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="center"
          columnSpacing={3}
          marginTop={2}
        >
          <Grid item xs={12} md={3} marginBottom={1}>
            <DateField
              name={"grt_start_date_range"}
              label={t("page.grt_report.grt_start_date_range")}
              value={formValue.grt_start_date_range}
              onChange={onChangeStartDate}
              showClearIcon={true}
            />
          </Grid>
          <Grid item xs={12} md={3} marginBottom={1}>
            <DateField
              name={"grt_end_date_range"}
              label={t("page.grt_report.grt_end_date_range")}
              value={formValue.grt_end_date_range}
              onChange={onChangeEndDate}
              showClearIcon={true}
            />
          </Grid>

          <Grid item xs={12} md={2} marginBottom={1}>
            <Button
              fullWidth
              variant="contained"
              size="small"
              sx={overflowButtonStyle}
              onClick={() => getGrtReport(formValue)}
            >
              {t("page.grt_report.download_report")}
            </Button>
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
