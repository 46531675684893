import { Navigate } from "react-router-dom";
import { useAuth } from "../../../context/auth";
import hasUserPermission, {
  IS_ADMIN,
  IS_KT,
  IS_REPORT_SAFETY,
  IS_REPORT_FM,
} from "../../../helpers/userPermissions";

const ReportsRedirectRoute = () => {
  const { user } = useAuth();
  let redirectLink = "/login/";

  if (hasUserPermission([IS_KT], user)) redirectLink = "/rcp_time_tracking/";
  else if (hasUserPermission([IS_REPORT_SAFETY], user))
    redirectLink = "/tickets_per_time_report/";
  else if (hasUserPermission([IS_REPORT_FM], user))
    redirectLink = "/tickets_per_location_day_report/";
  else if (hasUserPermission([IS_ADMIN], user))
    redirectLink = "/rcp_time_tracking/";

  return <Navigate to={redirectLink} />;
};

export default ReportsRedirectRoute;
