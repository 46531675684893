import React, { useState } from "react";

import PropTypes from "prop-types";

import Divider from "@mui/material/Divider";
import TicketDrawerContent from "../TicketDrawerContent";
import FeedbackForm from "../../UniversalDetailsDrawerForms/FeedbackForm";

const FeedbackDrawerContent = (props) => {
  return (
    <TicketDrawerContent
      itemData={props.itemData.ticket}
      itemType={"ticket"}
      filteringData={props.filteringData}
      readOnly={true}
      availableActionButtons={props.availableActionButtons}
      onOpenDialogParamsAction={() => {}}
      onRefetchData={props.onRefetchData}
      extraContent={[
        <Divider />,
        <FeedbackForm
          rateName={props.itemData.rate_name}
          feedbackNote={props.itemData.feedback_note}
        />,
      ]}
    />
  );
};

FeedbackDrawerContent.propTypes = {
  itemData: PropTypes.object,
  availableActionButtons: PropTypes.array,
  onOpenDialogParamsAction: PropTypes.func,
  onRefetchData: PropTypes.func,
};

FeedbackDrawerContent.defaultProps = {
  availableActionButtons: ["enclosures"],
};

export default FeedbackDrawerContent;
