import { useState, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useForm } from "../../../hooks/useForm";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import TrueFalseSelectField from "../../field/TrueFalseSelectField/trueFalseSelectField";
import SelectField from "../../field/SelectField";

import useTicketService from "../../../services/ticketService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import { TICKET_CATEGORY_KINDS } from "../../../helpers/constants";
import { getErrorMsg, isEmptyValue } from "../../../helpers/methods";

import NewTicketCategoryInformationDialog from "../NewTicketCategoryInformationDialog";
import useDialog from "../../../hooks/useDialog";

import {
  DIALOG_PREVIEW_MODE,
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
} from "../../../helpers/constants";

const REQUIRED_FIELDS = ["tc_name", "tc_name_eng", "tc_kind", "tc_active"];

const TicketCategoryDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const dialogTitle = useMemo(() => {
    return props.dialogMode === DIALOG_CREATE_MODE
      ? t("dialog.ticket_category.add_new_category")
      : props.dialogMode === DIALOG_EDIT_MODE
      ? t("dialog.ticket_category.edit_category")
      : t("dialog.ticket_category.category_details");
  }, [props.dialogMode]);

  const isReadOnly = useMemo(
    () => props.readOnly || props.dialogMode === DIALOG_PREVIEW_MODE,
    [props.readOnly, props.dialogMode]
  );

  const fieldsToSave = useMemo(
    () => props.fieldsToSave || REQUIRED_FIELDS,
    [props.fieldsToSave]
  );

  const ticketCategoryKindOptions = useMemo(() => {
    let optionsTemp;
    optionsTemp = [];
    for (const choice of Object.values(TICKET_CATEGORY_KINDS)) {
      optionsTemp.push({
        id: choice,
        name: t(`backend_choices_list.${choice}`),
      });
    }
    return optionsTemp;
  }, [t]);

  const { formValue, setFormValue, onChange } = useForm();

  const {
    getTicketCategoryDetailsData,
    addTicketCategory,
    updateTicketCategory,
  } = useTicketService();

  const objectCategoryInitialData = useAsync(() => {
    if (props.dialogMode === DIALOG_CREATE_MODE) {
      return Promise.resolve({ tc_active: true });
    }

    return getTicketCategoryDetailsData(props.ticketCategoryId);
  }, [props.getTicketCategoryDetailsData, props.dialogMode]);

  useEffect(() => {
    setFormValue(objectCategoryInitialData.value);
  }, [objectCategoryInitialData.loading]);

  const prepareDataToSend = () => {
    const dataToSend = {};
    for (const [key, value] of Object.entries(formValue)) {
      if (fieldsToSave.includes(key)) {
        dataToSend[key] = value;
      }
    }
    return dataToSend;
  };

  const updateTicketCategoryFn = useAsyncFn(updateTicketCategory);
  const addObjectCategoryFn = useAsyncFn(addTicketCategory);
  const submitFn = useMemo(
    () =>
      props.dialogMode === DIALOG_CREATE_MODE
        ? addObjectCategoryFn
        : updateTicketCategoryFn,
    [props.dialogMode]
  );

  const [
    openNewTicketCategoryInformationDialog,
    onOpenNewTicketCategoryInformationDialog,
    onCloseNewTicketCategoryInformationDialog,
  ] = useDialog();

  const handleCloseNewTicketCategoryInformationDialog = () => {
    onCloseNewTicketCategoryInformationDialog();
    if (props.onSubmitCallback) {
      props.onSubmitCallback();
    }
    props.onClose();
  };

  const [isDialogHidden, setIsDialogHidden] = useState(false);
  const handleSubmit = (dataToSend) => {
    submitFn
      .execute(dataToSend, props.ticketCategoryId)
      .then((res) => {
        if (props.dialogMode === DIALOG_CREATE_MODE) {
          setIsDialogHidden(true);
          onOpenNewTicketCategoryInformationDialog();
        } else {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.ticket_category_updated")
          );
          if (props.onSubmitCallback) {
            props.onSubmitCallback();
          }
          props.onClose();
        }
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isFormValid =
    formValue &&
    REQUIRED_FIELDS.every((field) => !isEmptyValue(formValue[field]));

  const isLoading = formValue === undefined;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={2}
        columnSpacing={2}
      >
        <Grid item xs={12} s>
          <TextFieldFormControl
            name="tc_name"
            required
            value={formValue.tc_name}
            label={t("dialog.ticket_category.tc_name")}
            readOnly={isReadOnly}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} s>
          <TextFieldFormControl
            name="tc_name_eng"
            required
            value={formValue.tc_name_eng}
            label={t("dialog.ticket_category.tc_name_eng")}
            readOnly={isReadOnly}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            options={ticketCategoryKindOptions}
            name={"tc_kind"}
            value={formValue.tc_kind}
            onChange={onChange}
            label={t("dialog.ticket_category.tc_kind")}
            required
          />
        </Grid>
        {props.dialogMode !== DIALOG_CREATE_MODE && (
          <Grid item xs={12}>
            <TrueFalseSelectField
              name={"tc_active"}
              label={t("dialog.ticket_category.tc_active")}
              value={formValue.tc_active}
              onChange={onChange}
              addEmptyOptions={false}
              valuesAsBool
              required
            />
          </Grid>
        )}
        {!isReadOnly && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={
                addObjectCategoryFn.loading || updateTicketCategoryFn.loading
              }
              disabled={!isFormValid}
              onClick={() => handleSubmit(prepareDataToSend())}
            >
              {t("save")}
            </LoadingButton>
          </Grid>
        )}
        {openNewTicketCategoryInformationDialog && (
          <NewTicketCategoryInformationDialog
            open={openNewTicketCategoryInformationDialog}
            onClose={handleCloseNewTicketCategoryInformationDialog}
          />
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      showTopFullScreenButton={false}
      title={dialogTitle}
      maxWidth="xs"
      showDialogActions
      hide={isDialogHidden}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

TicketCategoryDialog.propTypes = {
  dialogMode: PropTypes.oneOf([
    DIALOG_CREATE_MODE,
    DIALOG_EDIT_MODE,
    DIALOG_PREVIEW_MODE,
  ]),
  readOnly: PropTypes.bool,
  getTicketCategoryDetailsData: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  fieldsToSave: PropTypes.array,
};

TicketCategoryDialog.defaultProps = {
  open: false,
};

export default TicketCategoryDialog;
