import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import FieldWithLabel from "../../../field/FieldWithLabel";
import TextFieldFormControl from "../../../field/TextFieldFormControl";
import TrueFalseSelectField from "../../../field/TrueFalseSelectField";
import SelectField from "../../../field/SelectField";
import AutocompleteField from "../../../field/AutocompleteField";


const PartForm = (props) => {
  const { t } = useTranslation();



  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={2}
    >
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.part_details.part_name")}
          field={
            <TextFieldFormControl
              value={props.partData.part_name}
              readOnly={props.readOnly}
            />
          }
        />
      </Grid>

      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.part_details.part_producer")}
          field={
            <AutocompleteField
            value={props.partData.part_suplier}
            options={props.partChoiceSelectData?.value.part_supliers}
            isObjectOption={true}
            optionLabelKey={"subcontractor_short_name"}
            addNewValue={false}
            readOnly={props.readOnly}
          />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.part_details.part_kind")}
          field={
            <AutocompleteField
            value={props.partData.part_kind}
            options={props.partChoiceSelectData?.value.part_kinds}
            isObjectOption={true}
            optionLabelKey={"partkind_name"}
            addNewValue={false}
            readOnly={props.readOnly}
          />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.part_details.account")}
          field={
            <AutocompleteField
            value={props.partData.account}
            options={props.partChoiceSelectData?.value.accounts}
            isObjectOption={true}
            optionLabelKey={"account_name"}
            addNewValue={false}
            readOnly={props.readOnly}
          />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.part_details.gtu")}
          field={
            <AutocompleteField
            value={props.partData.gtu}
            options={props.partChoiceSelectData?.value.gtus}
            isObjectOption={true}
            optionLabelKey={"gtu_name"}
            addNewValue={false}
            readOnly={props.readOnly}
          />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.part_details.part_number")}
          field={
            <TextFieldFormControl
            value={props.partData.part_number}
            readOnly={props.readOnly}
          />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.part_details.part_type")}
          field={
            <TextFieldFormControl
            value={props.partData.part_type}
            readOnly={props.readOnly}
          />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.part_details.part_package")}
          field={
            <TextFieldFormControl
            value={props.partData.part_package}
            readOnly={props.readOnly}
          />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.part_details.part_carbon_footprint")}
          field={
            <TextFieldFormControl
            value={props.partData.part_carbon_footprint}
            readOnly={props.readOnly}
          />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.part_details.part_lead_time")}
          field={
            <TextFieldFormControl
            value={props.partData.part_lead_time}
            readOnly={props.readOnly}
          />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.part_details.part_unit")}
          field={
            <TextFieldFormControl
            value={props.partData.part_unit}
            readOnly={props.readOnly}
          />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.part_details.part_price")}
          field={
            <TextFieldFormControl
            value={props.partData.part_price}
            readOnly={props.readOnly}
            suffix={"zł"}
          />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.part_details.part_margin_min_pln")}
          field={
            <TextFieldFormControl
            value={props.partData.part_margin_min_pln}
            readOnly={props.readOnly}
            suffix={"zł"}
          />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.part_details.part_margin_min_proc")}
          field={
            <TextFieldFormControl
            value={props.partData.part_margin_min_proc}
            readOnly={props.readOnly}
            suffix={"%"}
          />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.part_details.part_vat_proc")}
          field={
            <TextFieldFormControl
            value={props.partData.part_vat_proc}
            readOnly={props.readOnly}
            suffix={"%"}
          />
          }
        />
      </Grid>
    
    </Grid>
  );
};

PartForm.propTypes = {
  partData: PropTypes.object,
  readOnly: PropTypes.bool
};

PartForm.defaultProps = {
  readOnly: true,
};

export default PartForm;
