import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SplitButton from "../SplitButton";

function JoinOfferButton(props) {

  const selectedFilterKey = useMemo(() => {
    if (props.isJoinOffersTableView) {
      return 'my-offers'
    }
    return "all-offers"
  }, [props.isJoinOffersTableView]);

  return (
    <SplitButton
      selectedItemKey={selectedFilterKey}
      withChangeSelectedItem={true}
      options={props.options}
    />
  );
}

JoinOfferButton.propTypes = {
  isJoinOffersTableView: PropTypes.bool,
  options: PropTypes.array
};

JoinOfferButton.defaultProps = {
  isJoinOffersTableView: true,
  options: []
};

export default JoinOfferButton;
