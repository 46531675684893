import { Grid } from "@mui/material";
import SelectField from "../../field/SelectField";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmptyValue } from "../../../helpers/methods";
import AutocompleteField from "../../field/AutocompleteField";
import { useMemo } from "react";
import LoaderWrapper from "../../wrapper/LoaderWrapper";


const CreateUserSkillForm = ({ onChange, onChangeAutocompleteFieldWithObjectOptions, onSubmit, user, skills, userSelectList, skillsSelectList, skillIdsAllowToEdit = [], allowChangeUser = true }) => {
    const { t } = useTranslation();
    const fixedChosenOptions = useMemo(() => {
        let fixedChosenOptionsTemp = []
        if (skillsSelectList !== undefined && skillIdsAllowToEdit !== undefined) {
            for (const skill of skillsSelectList) {
                if (!skillIdsAllowToEdit.includes(skill.id) && skills.findIndex(s => s === skill.id) !== -1) {
                    fixedChosenOptionsTemp.push(skill.id)
                }
            }
        }
        return fixedChosenOptionsTemp
    }, [skillsSelectList, skillIdsAllowToEdit, skills])

    const skillOptions = useMemo(() => {

        if (skillsSelectList !== undefined && skillIdsAllowToEdit !== undefined) {
            return skillsSelectList.filter(x => skillIdsAllowToEdit.includes(x.id) || fixedChosenOptions.includes(x.id))
        }
        return undefined
    }, [skillsSelectList, skillIdsAllowToEdit, fixedChosenOptions])
   
    return (
        <LoaderWrapper showLoader={skillOptions === undefined}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <SelectField
                        options={userSelectList}
                        onChange={onChange}
                        name="user"
                        value={user}
                        optionLabel={"full_name"}
                        readOnly={!allowChangeUser}
                        label={t("form.create_user_skill.employee")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AutocompleteField
                        multiple
                        valueObject={true}
                        optionLabelKey={"skill_name"}
                        name="skills"
                        value={skills}
                        options={skillOptions}
                        label={t("form.create_user_skill.skill")}
                        onChange={onChangeAutocompleteFieldWithObjectOptions}
                        getOptionDisabled={(option) => !skillIdsAllowToEdit.includes(option.id)}
                        fixedChosenOptions={fixedChosenOptions}
                        filterSelectedOptions
                    />
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        size="small"
                        onClick={onSubmit}
                        disabled={isEmptyValue(user)}
                    >
                        {t("save")}
                    </LoadingButton>
                </Grid>
            </Grid>
        </LoaderWrapper>
    )
}

export default CreateUserSkillForm;