import React, {useCallback} from "react";
import { useTranslation } from "react-i18next";
import EditableTable from "../EditableTable/EditableTable";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import AddButton from "../../button/AddButton";
import PropTypes from "prop-types";
import TableService from "../../../services/tableService";
import ValidationForm from "../../form/ValidationForm"

const OfferItemTable = React.memo((props) => {

  const data = React.useMemo(
    () =>
      TableService.getFieldsConfigForEditableTable(
        props.tableConfig,
        props.data,
        props.filteringData,
        ...props.onAddtitionalMethod
      ),
    [props.data, props.tableConfig, props.filteringData, props.onAddtitionalMethod]
  );

  const summaryData = React.useMemo(
    () =>
      TableService.getFieldsConfigForEditableTable( 
        props.tableConfig,
        [props.summaryDataRow],
      ),
    [props.data, props.tableConfig, props.filteringData, props.onAddtitionalMethod]
  );

  const getAddRowButton = useCallback(() => {
    if (!props.readOnly || props.dialogMode === 'joinOffer') {
      return (<AddButton onClick={props.onHandleAddEmptyRow} />)
    }
  }, [props.readOnly, props.onHandleAddEmptyRow, props.dialogMode])

  return (
    <LoaderWrapper showLoader={props.data === undefined}>
      <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
        {getAddRowButton()}
        <EditableTable
          showCheckbox={false}
          showTitle={false}
          showPagination={false}
          tableConfig={props.tableConfig}
          data={data}
          summaryDataRow={summaryData}
          onDataChange={props.onDataChange}
          onChangeAutocomplete={props.onChangeAutocomplete}
          readOnly={props.dialogMode === 'joinOffer' ? props.isLoading || props.isLoading : props.readOnly}
          showContextMenu={props.showContextMenu}
          contextMenuActions={props.contextMenuActions}
          onClickAddItemToAutocompletedField={props.onClickAddItemToAutocompletedField}
          countDeletedRows={true}
          style={props.style}
          onHasCommentToService={props.onHasCommentToService}
        />
      </div>
      <ValidationForm
        isRequiredeFieldsValid={props.isValid}
      />
    </LoaderWrapper>
  );
})


OfferItemTable.propTypes = {
  data: PropTypes.array,
  readOnly: PropTypes.bool,
  onHandleAddEmptyRow: PropTypes.func,
  summaryDataRow: PropTypes.object,
  onDataChange: PropTypes.func,
  onChangeAutocomplete: PropTypes.func,
  fieldConfig: PropTypes.object,
  showContextMenu: PropTypes.bool,
  contextMenuActions: PropTypes.array,
  onClickAddItemToAutocompletedField: PropTypes.func,
  isValid: PropTypes.bool,
  style: PropTypes.object,
  dialogMode: PropTypes.oneOf(["newOffer", "existOffer", "joinOffer"]),
  onAddtitionalMethods: PropTypes.object,
  isLoading: PropTypes.bool
};

OfferItemTable.defaultProps = {
  readOnly: true,
  showContextMenu: false,
  contextMenuActions: [],
  isValid: true,
  dialogMode: "newOffer",
  onAddtitionalMethods: {},
  isLoading: true
};

export default OfferItemTable