import React, { useState , forwardRef, useImperativeHandle, useMemo } from 'react';
import PropTypes from "prop-types";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import useWarehouseService from "../../../../services/warehouseService";
import { useAsync, useAsyncFn } from "../../../../hooks/useAsync";
import { TRANSACTION_IN_TABLE_CONFIG } from "./TableConfig";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import TransactionTable from "../../../table/TransactionTable";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import TransactionDialog from "../../../dialog/TransactionDialog";
import TransactionInForm from "../../../form/TransactionInForm";
import { TRANSACTION_KIND_IN } from "../../../../helpers/constants";
import { useEmptyArrayMemo } from '../../../../hooks/useEmptyArrayMemo';
import TableService from '../../../../services/tableService';
import { transactionInExportDataUrl } from '../../../../helpers/apiUrls';

const WarehouseTransactionInTab = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [hasPermissionToEdit, setHasPermissionToEdit] = useState(true);

  const {
    page: transactionInPage,
    pageSize: transactionInPageSize,
    handleChangePageWithSearchParams: handleChangeTransactionInDataPage,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const { getTransactionInData } = useWarehouseService()

  const { emptyArrayMemo } = useEmptyArrayMemo()

  const transactionInList = useAsync(
    () => {
      return getTransactionInData(searchParams)
    },
    [searchParams]
  )

  useImperativeHandle(ref, () => ({
    onRefetchData() {
      transactionInList.refetch()
    },
  }));
 



  return (
    <Grid container spacing={1}>
      {hasPermissionToEdit &&
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
            onClick={props.onOpenTransactionDialog}
          >
            {t("tabs.warehouse_transaction_tabs.new_transaction_in")}
          </Button>
        </Grid>}
      <Grid item xs={12}>
        <TransactionTable
          transactionFilteringData={props.transactionFilteringData}
          tableConfig={TRANSACTION_IN_TABLE_CONFIG}
          transactionData={transactionInList}
          page={transactionInPage}
          pageSize={transactionInPageSize}
          handleChangePageWithSearchParams={handleChangeTransactionInDataPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          exportToFileUrl={transactionInExportDataUrl}
          exportToFileSearchParams={searchParams}
  
        />
      </Grid>
      {
        props.openTransactionDialog &&
        <TransactionDialog
        open={props.openTransactionDialog}
        title={t("tabs.warehouse_transaction_tabs.new_transaction_in")}
        onClose={props.onCloseTransactionDialog}
        transactionKind= {TRANSACTION_KIND_IN}
        extraRequiredFields = {["transaction_price"]}
        onSubmit = {props.onSubmit}
        isDataSaving= {props.isSaving}
          transactionForm = {
            <TransactionInForm/>
          }
        />
      }
    </Grid>
  );
});

WarehouseTransactionInTab.propTypes = {
  readOnly: PropTypes.bool,
};

WarehouseTransactionInTab.defaultProps = {};

export default WarehouseTransactionInTab;
