import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import BaseBox from "../../base/BaseBox/baseBox";
import BaseTable from "../../base/BaseTable/BaseTable";
import TableService from "../../../services/tableService";
import { GUARANTEE_AGREEMENT_TABLE_FIELDS_CONFIG } from "./BoxTablesConfig";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

export default function AgreementsGuaranteeBox({
  guaranteeAgreements,
  hasPermission,
  onClickEdit,
}) {
  const { t } = useTranslation();
  const [
    agreementsGuaranteeTableConfigLocal,
    setAgreementsGuaranteeTableConfigLocal,
  ] = useState(GUARANTEE_AGREEMENT_TABLE_FIELDS_CONFIG);

  useEffect(() => {
    if (
      hasPermission &&
      agreementsGuaranteeTableConfigLocal.find(
        (config) => config.name === "edit"
      ) === undefined
    ) {
      const tempConfig = [...GUARANTEE_AGREEMENT_TABLE_FIELDS_CONFIG];
      tempConfig.push({
        name: "edit",
        getValueCallback: (rowData, onClickEdit) => {
          return (
            <ModeEditOutlineOutlined
              onClick={(e) => onClickEdit(e, rowData.id)}
            />
          );
        },
        label: t("table.agreements_table.edit"),
        filterType: null,
        getFilterFieldConfigCallback: (filteringData) => undefined,
      });
      setAgreementsGuaranteeTableConfigLocal(tempConfig);
    }
  }, [hasPermission]);

  return (
    <BaseBox>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
          <Typography variant="subtitle2" className="capitalize-first-letter">
            {t(`other.agreements_guarantee_box.guarantees`)}
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6} />

        <Grid item xs={12}>
          <BaseTable
            headers={TableService.getHeaderLables(
              agreementsGuaranteeTableConfigLocal
            )}
            rows={TableService.getPreparedDataForBaseTable(
              agreementsGuaranteeTableConfigLocal,
              guaranteeAgreements,
              [],
              undefined,
              onClickEdit
            )}
            showNoRecordsPaper={true}
          />
        </Grid>
      </Grid>
    </BaseBox>
  );
}

AgreementsGuaranteeBox.propTypes = {
  guaranteeAgreements: PropTypes.array,
};

AgreementsGuaranteeBox.defaultProps = {
  guaranteeAgreements: [],
};
