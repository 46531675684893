import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useForm } from "../../../../../hooks/useForm";
import useDialog from "../../../../../hooks/useDialog";
import LoaderWrapper from "../../../../wrapper/LoaderWrapper";
import SelectDeactivateReasonDialog from "../../../../dialog/SelectDeactivateReasonDialog/SelectDeactivateReasonDialog";
import AddressDialog from "../../../../dialog/AddressDialog/AddressDialog";
import PhoneDialog from "../../../../dialog/PhoneDialog/PhoneDialog";
import MailDialog from "../../../../dialog/MailDialog";
import useOwnerFormsService from "../../../../../hooks/useOwnerFormsService";
import AddressFormBox from "../../../../box/AddressFormBox";
import PhoneFormBox from "../../../../box/PhoneFormBox/PhoneFormBox";
import MailFormBox from "../../../../box/MailFormBox/MailFormBox";
import SubcontractorForm from "../../UniversalDetailsDrawerForms/SubcontractorForm";
import ConfirmationDialog from "../../../../dialog/ConfirmationDialog/confirmationDialog";
import { useTranslation } from "react-i18next";
import UserFormBox from "../../../../box/UserFormBox";
import SelectUserDialog from "../../../../dialog/SelectUserDialog/SelectUserDialog";
import { getErrorMsg } from "../../../../../helpers/methods";
import { useSnackbarAlert } from "../../../../../context/snackbarAlert";
import useUserService from "../../../../../services/userService";
import { useAsyncFn } from "../../../../../hooks/useAsync";

const SubcontractorDrawerContent = (props) => {

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const {
    formValue,
    setFormValue,
    onChangeWithCallback,
  } = useForm(props.subcontractorData);

  const {
    createUserSubcontractor
  } = useUserService();

  const createUserSubcontractorFn = useAsyncFn(createUserSubcontractor);

  const [isLoading, setIsLoading] = useState(false);

  const [
    openConfirmationDialog,
    onOpenConfirmationDialog,
    onCloseConfirmationDialog,
  ] = useDialog();

  const {
    openAddressDialog,
    onOpenAddressDialog,
    onEditAddress,
    onRemoveAddress,
    onCloseAddressDialog,


    openMailDialog,
    onOpenMailDialog,
    onEditMail,
    onRemoveMail,
    onCloseMailDialog,

    openPhoneDialog,
    onOpenPhoneDialog,
    onEditPhone,
    onRemovePhone,
    onClosePhoneDialog,

    openUserDialog,
    onOpenUserDialog,
    onRemoveUser,
    onCloseUserDialog,

    selectedAddressId,
    selectedMailId,
    selectedPhoneId } = useOwnerFormsService(props.onRefetchData,
      formValue, 
      setFormValue,
      undefined,
      props.subcontractorId)



  const handleChangeFieldValue = (name, value) => {
    setIsLoading(true);
    const oldValue = formValue[name];
    props
      .onUpdateDataPromise({ [name]: value })
      .then(() => {
      })
      .catch((error) => {
        setFormValue((prev) => ({ ...prev, [name]: oldValue }));
      })
      .finally(() => {
        setIsLoading(false);
        onCloseConfirmationDialog()
      });
  };

  const onChangeSubcontractorActivate = (name, value) => {
    if (value) {
      handleChangeFieldValue(name, value)
    } else {
      onOpenConfirmationDialog()
    }
  }

  const onSubmitDeactivate = useCallback(() => {
    props
      .onUpdateDataPromise({
        "subcontractor_active": false
      })
  }, [])

  const onCancelDeactivate = useCallback(() =>{
    setFormValue((prev) => ({ ...prev, "subcontractor_active": true}));
    onCloseConfirmationDialog()
  },[formValue])


  const onAddNewSubcontractorUser = useCallback(((user) => {
      createUserSubcontractorFn
        .execute({'user' : user.id, 'subcontractor' : props.subcontractorId})
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.subcontractor_updated")
          );
          props.onRefetchData()
        })
        .catch((err) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(err.data),
            t("snackbar_alert.occurred_error_during_subcontractor_updating")
          );
        });
    onCloseUserDialog()
  }), [props.subcontractorId, formValue])


  if (isLoading) {
    return <LoaderWrapper isLoading={isLoading} />
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      paddingX={1}
      rowGap={1}
      marginTop={1}
      marginBottom={3}
    >
      <Grid item xs={12}>
        <SubcontractorForm
          subcontractorData={formValue}
          onChangeTextField={onChangeWithCallback(handleChangeFieldValue)}
          onChangeSubcontractorActivate={onChangeWithCallback(onChangeSubcontractorActivate)}
          onChangeSelectField={onChangeWithCallback(handleChangeFieldValue)}
          readOnly={!formValue?.subcontractor_active || props.readOnly}
        />
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <AddressFormBox
          addresses={formValue?.addresses}
          onEditAddress={onEditAddress}
          onAddAddress={onOpenAddressDialog}
          onRemoveAddress={onRemoveAddress}
          readOnly={!formValue?.subcontractor_active || props.readOnly}
          subcontractorId={props.subcontractorId}
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneFormBox
          phones={formValue?.phones}
          onEditPhone={onEditPhone}
          onAddPhone={onOpenPhoneDialog}
          onRemovePhone={onRemovePhone}
          readOnly={!formValue?.subcontractor_active || props.readOnly}
          subcontractorId={props.subcontractorId}
        />
      </Grid>
      <Grid item xs={12}>
        <MailFormBox
          mails={formValue?.mails}
          onEditMail={onEditMail}
          onAddMail={onOpenMailDialog}
          onRemoveMail={onRemoveMail}
          readOnly={!formValue?.subcontractor_active || props.readOnly}
          subcontractorId={props.subcontractorId}
        />
      </Grid>
      
      <Grid item xs={12}>
            <UserFormBox
              users={formValue?.users}
              onAddUser={onOpenUserDialog}
              onRemoveUser={onRemoveUser}
              readOnly={!formValue?.subcontractor_active || props.readOnly}
              subcontractorId={props.subcontractorId}
            />
          </Grid>
      {openConfirmationDialog && (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onNo={onCancelDeactivate }
          onYes={() => {
            onSubmitDeactivate()
            onCloseConfirmationDialog()
          }}
          title={t("dialog.subcontractor_confirmation_dialog.alert")}
          content={t("dialog.subcontractor_confirmation_dialog.are_you_sure_to_deactivate_subcontractor")}
        />
      )}
      {openAddressDialog &&
        <AddressDialog
          addressId={selectedAddressId.current}
          open={openAddressDialog}
          onRefetch={props.onRefetchData}
          onClose={onCloseAddressDialog}
          subcontractorId={props.subcontractorId}
        />
      }
      {openPhoneDialog &&
        <PhoneDialog
          phoneId={selectedPhoneId.current}
          open={openPhoneDialog}
          onRefetch={props.onRefetchData}
          onClose={onClosePhoneDialog}
          subcontractorId={props.subcontractorId}
        />
      }
      {openMailDialog &&
        <MailDialog
          mailId={selectedMailId.current}
          open={openMailDialog}
          subcontractorId={props.subcontractorId}
          onRefetch={props.onRefetchData}
          onClose={onCloseMailDialog}
        />
      }
      {openUserDialog &&
        <SelectUserDialog
          open={openUserDialog}
          onClose={onCloseUserDialog}
          searchParams={{'is_active' : true}}
          returnObject = {true}
          onSubmit={onAddNewSubcontractorUser}
        />
      }
    </Grid>
  );
};

SubcontractorDrawerContent.propTypes = {
  ownerData: PropTypes.object,
  onRefetchData: PropTypes.func,
  readOnly: PropTypes.bool,
  onRefetchTableData: PropTypes.func,
};

SubcontractorDrawerContent.defaultProps = {
  isLoading: false,
  readOnly: true,
};

export default SubcontractorDrawerContent;
