import TicketsFeedbackReportBasePage, {
  CHART_TYPE_FEEDBACK_DISTRIBUTION,
} from "../TicketsFeedbackReportBasePage";

export default function TicketFeedbackDistributionReportPage(props) {
  return (
    <TicketsFeedbackReportBasePage
      chartType={CHART_TYPE_FEEDBACK_DISTRIBUTION}
      pageName={props.pageName}
      pageTitleKey={"feedback_notes"}
      showNps={true}
      showSelectObjectLevel2={false}
      showSelectTenant={true}
    />
  );
}

TicketFeedbackDistributionReportPage.propTypes = {};
