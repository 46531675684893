import InformationDialog from "../InformationDialog/informationDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useTimeout from "../../../hooks/useTimeout";

export default function NewTicketCategoryInformationDialog(props) {
  const { t } = useTranslation();

  const { clear } = useTimeout(props.onClose, 10000);

  const handleClose = () => {
    clear();
    props.onClose();
  };

  return (
    <InformationDialog
      open={props.open}
      titleSx={{
        color: "var(--brand-green)",
        fontSize: "24px",
        paddingBlock: "18px",
      }}
      btnColor={"success"}
      onClose={handleClose}
      maxWidth={"xs"}
      title={t(
        "dialog.new_ticket_category_information_dialog.new_ticket_category_added"
      )}
      content={t(
        "dialog.new_ticket_category_information_dialog.write_to_my_solvy_admins_about_changing_default_icon"
      )}
      contentSx={{
        fontSize: "20px",
        fontWeight: "400",
        paddingBlock: "26px",
      }}
      btnSx={{
        paddingBlock: "6px",
        marginBlock: "16px",
        width: "400px",
        borderRadius: "6px",
      }}
    />
  );
}

NewTicketCategoryInformationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

NewTicketCategoryInformationDialog.defaultProps = {
  open: false,
};
