import {
  stockWarehouseDataUrl,
  stockWarehouseFilteringDataUrl,
  poLinesFilteringDetailsDataUrl,
  poFilteringDataUrl,
  poFilteringDetailsData,
  reservationByPartDataUrl,
  transactionByPartDataUrl,
  reservationDataUrl,
  transactionInDataUrl,
  transactionFilteringDataUrl,
  transactionOutDataUrl,
  transactionRemoveDataUrl,
  transactionMoveDataUrl,
  buffersDataUrl,
  bufferFilteringDataUrl,
  bufferDetailsUrl,
  stockAmountUrl,
  transactionSelectingDataUrl,
  transactionDataUrl,
  stockAvailabilityUrl,
  warehousesDataUrl,
  warehousesFilteringDataUrl,
  warehouseDetailsUrl,
  warehouseLocationDetailsUrl,
  warehouseLocationUrl,
  reservationUrl,
  transactionWzDataUrl
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { prepareUrlWithQueryParams } from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function useWarehouseService() {
  const axiosPrivate = useAxiosPrivate();


  const getStockWarehouseList = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(stockWarehouseDataUrl, searchParams));
  };

  const getStockWarehouseFilteringData = () => {
    return makeRequest(axiosPrivate, stockWarehouseFilteringDataUrl);
  };

  const getStockAmount = (stockData) => {
    return makeRequest(axiosPrivate, stockAmountUrl, {
      method: "POST",
      data: stockData,
    });
  }

  const getStockAvailability = (stockData) => {
    return makeRequest(axiosPrivate, stockAvailabilityUrl, {
      method: "POST",
      data: stockData,
    });
  }

  const createTransaction = (transactionData) => {
    return makeRequest(axiosPrivate, transactionDataUrl, {
      method: "POST",
      data: transactionData,
    });
  }

  const createReservation = (reservationData) => {
    return makeRequest(axiosPrivate, reservationDataUrl, {
      method: "POST",
      data: reservationData,
    });
  }

  const getTransactionInData = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(transactionInDataUrl, searchParams));
  };

  const getTransactionOutData = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(transactionOutDataUrl, searchParams));
  };

  const getTransactionMoveData = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(transactionMoveDataUrl, searchParams));
  };


  const getTransactionRemoveData = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(transactionRemoveDataUrl, searchParams));
  };

  const getTransactionByPartData = (partId, searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(transactionByPartDataUrl(partId), searchParams));
  };

  const getTransactionFilteringData = () => {
    return makeRequest(axiosPrivate, transactionFilteringDataUrl);
  };

  const getTransactionSelectingData = () => {
    return makeRequest(axiosPrivate, transactionSelectingDataUrl);
  };


  const getReservationData = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(reservationDataUrl, searchParams));
  };

  const cancelReservation = (reservationId) => {
    return makeRequest(axiosPrivate, reservationUrl(reservationId), {
      method: "DELETE",
    });
  };

  const getReservationByPartData = (partId, searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(reservationByPartDataUrl(partId), searchParams));
  };

  const getBuffers = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(buffersDataUrl, searchParams));
  };

  const getBuffersDetails = (bufferId) => {
    if (bufferId) {
      return makeRequest(axiosPrivate, bufferDetailsUrl(bufferId));
    }
    return Promise.resolve({});
  };

  const createBuffer = (bufferData) => {
    return makeRequest(axiosPrivate, buffersDataUrl, {
      method: "POST",
      data: bufferData,
    });
  }

  const updateBufferDetails = (bufferId, bufferData) => {
    return makeRequest(axiosPrivate, bufferDetailsUrl(bufferId), {
      method: "PATCH",
      data: bufferData,
    });
  };

  const getBufferFilteringData = () => {
    return makeRequest(axiosPrivate, bufferFilteringDataUrl);
  };

  const removeBuffer = (bufferId) => {
    return makeRequest(axiosPrivate, bufferDetailsUrl(bufferId), {
      method: "DELETE",
    });
  }

  const getWarehouseList = (searchParams={}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(warehousesDataUrl, searchParams));
  };

  const getWarehouseFilteringData = () => {
    return makeRequest(axiosPrivate, warehousesFilteringDataUrl);
  };
  

  const getWarehouseDetails = (warehouseId) => {
    if (warehouseId) {
      return makeRequest(axiosPrivate, warehouseDetailsUrl(warehouseId));
    }
    return Promise.resolve({});
  };

  const createWarehouse = (warehouseData) => {
    return makeRequest(axiosPrivate, warehousesDataUrl, {
      method: "POST",
      data: warehouseData,
    });
  }

  const updateWarehouse = (warehouseId, warehouseData) => {
    return makeRequest(axiosPrivate, warehouseDetailsUrl(warehouseId), {
      method: "PATCH",
      data: warehouseData,
    });
  };
  
  const getWarehouseLocationLists = (searchParams={}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(warehouseLocationUrl, searchParams));
};

  const getWarehouseLocationDetails = (warLocId) => {
      return makeRequest(axiosPrivate, warehouseLocationDetailsUrl(warLocId));
  };

  const createWarehouseLocation = (warehouseLocationData) => {
    return makeRequest(axiosPrivate, warehouseLocationUrl, {
      method: "POST",
      data: warehouseLocationData,
    });
  }

  const updateWarehouseLocation = (warLocId, warehouseLocationData) => {
    return makeRequest(axiosPrivate, warehouseLocationDetailsUrl(warLocId), {
      method: "PATCH",
      data: warehouseLocationData,
    });
  };

  const getTransactionWzData = (searchParams = {}) => {
    return makeRequest(axiosPrivate, prepareUrlWithQueryParams(transactionWzDataUrl, searchParams));
  };
  

  return {
    createBuffer,
    getBuffers,
    getBuffersDetails,
    updateBufferDetails,
    removeBuffer,
    getStockAmount,
    getStockAvailability,
    getStockWarehouseList,
    getStockWarehouseFilteringData,
    createTransaction,
    createReservation,
    getTransactionSelectingData,
    getTransactionInData,
    getTransactionOutData,
    getTransactionMoveData,
    getTransactionRemoveData,
    getReservationData,
    getTransactionByPartData,
    getReservationByPartData,
    getTransactionFilteringData,
    getBufferFilteringData,
    getWarehouseList,
    getWarehouseFilteringData,
    getWarehouseDetails,
    updateWarehouse,
    createWarehouse,
    getWarehouseLocationLists,
    getWarehouseLocationDetails,
    createWarehouseLocation,
    updateWarehouseLocation,
    cancelReservation,
    getTransactionWzData
  }
}

