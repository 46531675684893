import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseBox from "../../base/BaseBox/baseBox";
import EditableTable from "../../table/EditableTable/EditableTable";
import TableService from "../../../services/tableService";


const OfferClauseForm = (props) => {

  const { t } = useTranslation();

  const data = React.useMemo(
    () =>
      TableService.getFieldsConfigForEditableTable(
        props.tableConfig,
        props.data,
      ),
    [props.data, props.tableConfig]
  );


  return (
    <Grid container spacing={1}>
      <Grid item xs = {12}>
      <EditableTable
        showCheckbox={props.showCheckbox}
        checkedAll={props.checkedAll}
        onClickCheckAll={props.onClickCheckAll}
        onClickCheck={props.onClickCheck}
        showTitle={false}
        showPagination={false}
        tableConfig={props.tableConfig}
        readOnly={props.readOnly}
        showContextMenu={props.showContextMenu}
        contextMenuActions={props.contextMenuActions}
        countDataRows={false}
        data={data}
        allowClickCheckbox={props.allowClickCheckbox}
      />
</Grid>
      {props.showSubmitButton &&
        <Grid item xs = {12}>
        <Button
          variant="contained"
          fullWidth
          onClick={props.onSubmitButton}
        >
          {t("form.clauses_form.add_clausules")} </Button>
        </Grid>
      }
   </Grid>
  );
};

OfferClauseForm.propTypes = {
  onSubmit: PropTypes.func,
  ticketId: PropTypes.string,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  onOpenAddNewSupplier: PropTypes.func,
  subcontractorSelectList: PropTypes.array,
  showCheckbox: PropTypes.bool,
  checkedAll: PropTypes.bool,
  onClickCheckAll: PropTypes.func,
  showContextMenu: PropTypes.bool,
  showAddButon: PropTypes.bool,
  showSubmitButton: PropTypes.bool,
  allowClickCheckbox: PropTypes.bool,
};

OfferClauseForm.defaultProps = {
  readOnly: false,
  subcontractorSelectList: [],
  showCheckbox: false,
  showContextMenu: false,
  showAddButon: true,
  showSubmitButton: false,
  allowClickCheckbox: false
};

export default OfferClauseForm;
