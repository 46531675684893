import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect, useMemo  } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useFieldValidation from "../../../hooks/useFieldValidation";
import useWarehouseService from "../../../services/warehouseService";
import BufferDetailsForm from "../../form/BufferDetailsForm";


const BufferDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const { getUnfillRequiredFields } = useFieldValidation();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions
  } = useForm({});

  const {
    getBuffersDetails,
    updateBufferDetails,
    createBuffer,
    getStockAmount
  } = useWarehouseService();

  const updateBufferDetailsFn = useAsyncFn(updateBufferDetails)
  const stockAmountFn = useAsyncFn(getStockAmount)
  const createBufferFn = useAsyncFn(createBuffer)

  const requiredFields = useMemo(() => {
    return [
      "warehouse",
      "part",
      "buffer_amount",
    ];
  }, []);

  const bufferDetailsData = useAsync(
    () => getBuffersDetails(props.bufferId),
    [props.bufferId]
  );

  useEffect(() => {
    if (bufferDetailsData.loading) {
      return;
    }
    if (bufferDetailsData.value) {
      setFormValue(bufferDetailsData.value);

    }
  }, [bufferDetailsData.loading]);

  const isLoadingButtonDisabled = useMemo(() => {
    return getUnfillRequiredFields(requiredFields, formValue).length > 0;
  }, [requiredFields, formValue, getUnfillRequiredFields]);

  const prepreDataToGetAmount = (data) =>{
    let retData = {}
    retData.warehouse = data.warehouse
    retData.part = data.part
    return retData
  }

  const onSetLocalAmountAfterAutocompleteChange = useCallback((e, value_object, value_key, state_value_name) => {
    let newValues = onChangeAutocompleteFieldWithObjectOptions(e, value_object, value_key, state_value_name)
    let tempForm = {...formValue}
    tempForm[newValues.name] = newValues.value
    if (state_value_name === "part"){
      tempForm = {...tempForm, 'part_unit' : value_object.part_unit}
    }
    if (tempForm.warehouse  && tempForm.part) {
      stockAmountFn
        .execute(prepreDataToGetAmount(formValue))
        .then((res) => {
          setFormValue({
            ...tempForm,
            ...res
          });
        })
    }else{
      setFormValue({
        ...tempForm,
      });
    }
  },[formValue])

  const onSubmit = useCallback(() => {
    if (props.bufferId) {
      updateBufferDetailsFn
        .execute(props.bufferId, formValue)
        .then((res) => {
          props.onRefetchData()
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.buffer_updated")
          );
          props.onClose();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_buffer_updating")
          );
        });
    } else {
      createBufferFn
        .execute(formValue)
        .then((res) => {
          props.onRefetchData()
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.buffer_created")
          );
          props.onClose();
        })
        .catch((error) => {
          if (error.status === 400 && error.data.error === 'exist'){
            snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.buffer_exist")
          );
          
          }
          else if (error.status === 400 && error.data.error === 'you_have_not_permission_for_this_action'){
            snackbarAlert.openErrorSnackbarAlert(
              t("snackbar_alert.you_have_not_permission_for_this_action"))
          }
          else{
            snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_buffer_creating")
          );
          }
        });
    }
  }, [formValue]);



  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.bufferId ? t(`dialog.buffer_details_dialog.edit_buffer`) : t(`dialog.buffer_details_dialog.create_buffer`)}
      maxWidth="md"
      showDialogActions
    >

      <LoaderWrapper showLoader={bufferDetailsData.loading} >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12}>
            <BufferDetailsForm
              readOnly={props.readOnly}
              bufferData={formValue}
              onChange={onChange}
              onChangeAutocompleteFieldWithObjectOptions={onSetLocalAmountAfterAutocompleteChange}
              filteringData={props.filteringData}
              bufferId = {props.bufferId}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={updateBufferDetailsFn.loading || createBufferFn.loading}
              disabled={isLoadingButtonDisabled}
              onClick={onSubmit}
            >
              {props.bufferId ? t("save") : t("create")}
            </LoadingButton>
          </Grid>

        </Grid>

      </LoaderWrapper>

    </BasicDialog>
  );
};

BufferDialog.propTypes = {
  agreementId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool
};

BufferDialog.defaultProps = {
  open: false,
};

export default BufferDialog;
