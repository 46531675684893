import React from "react";
import PropTypes from "prop-types";
import TableService from "../../../services/tableService";
import FilterTable from "../FilterTable";

export default function DocsRestrictedTable(props) {
  const data = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        props.tableConfig,
        props.data,
        props.hiddenColumns,
        props.columnsOrders,
        props.onClickMultipleLocationAlert,
        props.onDownload,
        props.onPreview,
        props.onClickEdit,
        props.onClickEditUserNote,
        props.onClickInfo
      ),
    [props.data]
  );
  return (
    <FilterTable
      checkedAll={props.checkedAll}
      onClickRow={props.onClickRow}
      onClickCheck={props.onClickCheck}
      onClickCheckAll={props.onClickCheckAll}
      data={data}
      filteringData={props.filteringData}
      countRecords={props.countRecords}
      page={props.page}
      onPageChange={props.onPageChange}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      rowsPerPageOptions={props.rowsPerPageOptions}
      resetPageNumber={props.resetPageNumber}
      headersConfig={TableService.getHeadersConfigForCollapsibleTable(
        props.tableConfig,
        props.filteringData,
        props.hiddenColumns,
        props.columnsOrders
      )}
      selectedItemId={props.selectedItemId}
      style={props.style}
      showCheckbox={false}
      showTitle={props.showTitle}
      title={props.title}
      filterPrefix={props.filterPrefix}
      showCleanFilterIcon={true}
    />
  );
}

DocsRestrictedTable.propTypes = {
  data: PropTypes.object,
  style: PropTypes.object,
  hiddenColumns: PropTypes.array,
  columnsOrders: PropTypes.array,
  showTitle: PropTypes.bool,
  title: PropTypes.string,
  filterPrefix: PropTypes.string,
  onClickMultipleLocationAlert: PropTypes.func,
  onDownload: PropTypes.func,
  onPreview: PropTypes.func,
  onClickonClickEditUserNote: PropTypes.func,
  onClickInfo: PropTypes.func,
};

DocsRestrictedTable.defaultProps = {
  hiddenColumns: [],
  data: [],
  showTitle: false,
};
