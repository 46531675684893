import React from "react";
import PropTypes from "prop-types";
import SelectField from "../../field/SelectField/SelectField";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import { SELECT_FIELD_TYPE } from "../../../helpers/constants";
import useFormService from "../../../services/formService";
import { useAsync } from "../../../hooks/useAsync";

const Question = React.memo((props) => {
  const { getFormQuestion } = useFormService();

  const question = useAsync(
    () => getFormQuestion(props.questionId),
    [props.questionId]
  );

  const onChange = (e) => {
    const answer = question.value.answers.find((x) => x.id === e.target.value);
    props.onChange(e, { id: question.value.id }, answer);
  };

  const getQuestionByTypeAndMode = () => {
    if (props.isQuestionnaire)
      return (
        <TextFieldFormControl
          label={question.value?.question_text}
          value={props.answer}
          readOnly={true}
        />
      );

    switch (question.value?.question_type) {
      case SELECT_FIELD_TYPE:
        return (
          <SelectField
            label={question.value.question_text}
            value={props.answer}
            options={question.value.answers.filter(
              (answer) => answer.quan_active
            )}
            optionLabel={"quan_content"}
            variant={"outlined"}
            onChange={onChange}
            required={props.required}
            id={`question_${props.questionId}`}
            error={props.error}
            helperText={props.helperText}
            readOnly={props.readOnly}
          />
        );
      default:
        return null;
    }
  };

  return <>{getQuestionByTypeAndMode()}</>;
});

Question.propTypes = {
  questionId: PropTypes.string,
  answer: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  readOnly: PropTypes.bool,
  isQuestionnaire: PropTypes.bool,
};

Question.defaultProps = {
  isQuestionnaire: false,
};

export default Question;
