import {
  convertEmptyValueOrMinusCharToZero,
  convertUndefinedValueToZero,
  isEmptyValue,
  roundPrice,
} from "../helpers/methods";

export default function useSettlementTableService() {


  const calulateSingleSettlementByRate = (s, offerSummaryData, requiredPartOfferFields) => {
    const paymentRate = roundPrice(convertUndefinedValueToZero(s.settlement_payment_rate))
    
    s.settlement_netto = paymentRate * convertUndefinedValueToZero(offerSummaryData?.items_quotation_net)
    s.settlement_vat = paymentRate * convertUndefinedValueToZero(offerSummaryData?.items_quotation_vat)
    s.settlement_brutto = paymentRate * convertUndefinedValueToZero(offerSummaryData?.items_quotation_gross)

    isElementValid(s, requiredPartOfferFields)
    return s
  }

  const calulateSingleSettlementByNettCost = (s, offerSummaryData, requiredPartOfferFields) => {
    s.settlement_payment_rate = roundPrice(convertUndefinedValueToZero( s.settlement_netto / offerSummaryData?.items_quotation_net))

    s.settlement_vat = s.settlement_payment_rate * convertUndefinedValueToZero(offerSummaryData?.items_quotation_vat)
    s.settlement_brutto = s.settlement_payment_rate * convertUndefinedValueToZero(offerSummaryData?.items_quotation_gross)

    isElementValid(s, requiredPartOfferFields)
    return s
  }




  const setSettlementDataAfterChange = (element, payer) => {
    element.payer_id = payer.id
    element.address = payer.address
    element.tenant_nip = payer.tenant_nip
    element.tenant_short_name = payer.tenant_short_name
    return element
  }

  const setSettlementCalculatedData = (settlements, totalOfferCost, requiredPartOfferFields) => {
    for (let s of settlements) {
      calulateSingleSettlementByRate (s, totalOfferCost, requiredPartOfferFields)
    }
    return settlements
  }

  const isElementValid = (element, requiredFields) => {
    element.isValid = requiredFields.every(
      (fieldName) => !isEmptyValue(element[fieldName])
    )
  };

  const isTableValid = (elements) => {
    return elements
      .filter(
        (element) => element.isDeleted !== true
      )
      .every(
        (element) => element.isValid !== false
      )
  }

  const checkIsRateSumIsValid = (settlements) => {
    let sum = 0
    for (let s of settlements) {
      if (s.isDeleted !== true) {
        sum += parseFloat(convertEmptyValueOrMinusCharToZero(s.settlement_payment_rate))
      }
    }
    return sum === 1.0
  }


  const getFieldsNameToSave = () => {
    return [
      "id",
      "payer_id",
      "settlement_po",
      "settlement_payment_rate",
      "isDeleted"
    ];
  };


  return {
    calulateSingleSettlementByRate,
    calulateSingleSettlementByNettCost,
    setSettlementDataAfterChange,
    setSettlementCalculatedData,
    isElementValid,
    isTableValid,
    checkIsRateSumIsValid,
    getFieldsNameToSave
  };
}
