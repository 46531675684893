import { Box } from "@mui/material";
import PropTypes from "prop-types";

export default function BoxWithLabel(props) {
  return (
    <Box
      fullWidth
      component={props.component}
      color={props.color}
      border={props.border}
      borderRadius={props.borderRadius}
      sx={{ zIndex: 100 }}
      style={props.boxStyle}
    >
      {props.component === "fieldset" && <legend>{props.label}</legend>}
      {props.component !== "fieldset" && (
        <div
          style={
            props.labelStyle
              ? props.labelStyle
              : {
                  background: "var(--closed)",
                  overflow: "hidden",
                  borderRadius: `${props.borderRadius * 4}px ${
                    props.borderRadius * 4
                  }px 0px 0px`,
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontFamily: "roboto",
                }
          }
        >
          {props.label}
        </div>
      )}

      <div style={props.style}>{props.children}</div>
    </Box>
  );
}

BoxWithLabel.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  color: PropTypes.string,
  border: PropTypes.string,
  borderRadius: PropTypes.number,
  component: PropTypes.string,
  boxStyle: PropTypes.object,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
};

BoxWithLabel.defaultProps = {
  color: "var(--primary)",
  border: "1px solid var(--primary)",
  borderRadius: 2,
  component: "fieldset",
};
