import { Button, Grid, Typography } from "@mui/material";
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseBox from "../../base/BaseBox/baseBox";
import BoardForm from "../../form/BoardForm";

export default function BoardFormBox({ boards, onEditBoard, onAddBoard, onRemoveBoard, readOnly }) {
    const { t } = useTranslation();

    return (
        <BaseBox>
            <Grid container spacing={1}>
                <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
                    <Typography
                        variant="subtitle2"
                        className="capitalize-first-letter"
                    >
                        {t(`other.board_form_box.owner_boards`)}
                    </Typography>
                </Grid>
                <Grid item sm={6} xs={6} />
                {boards?.map((board, index) => {
                    return (
                        <BoardForm
                            board={board}
                            index={index}
                            onEditBoard={onEditBoard}
                            onRemoveBoard={onRemoveBoard}
                            readOnly ={readOnly}
                        />
                    )
                })
                }
                <Grid item xs={12}>
                    {!readOnly &&
                    <Button
                        fullWidth
                        variant="text"
                        size="small"
                        onClick={onAddBoard}
                    >
                        {t("other.board_form_box.add_board")}
                    </Button>
                    }
                </Grid>
            </Grid>
        </BaseBox>
    );
}

BoardFormBox.propTypes = {
    boards : PropTypes.array,
    onEditBoard : PropTypes.func,
    onAddBoard : PropTypes.func,
    onRemoveBoard : PropTypes.func,
    readOnly : PropTypes.bool
}


BoardFormBox.defaultProps = {
    boards : [],
    readOnly : true
}
