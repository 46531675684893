import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseBox from "../../base/BaseBox/baseBox";
import OfferSummaryDetailsForm from "../OfferSummaryDetailsForm/OfferSummaryDetailsForm";
import OfferCommentsForm from "../../drawer/UniversalDetailsDrawer/UniversalDetailsDrawerForms/OfferCommentsForm";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useForm } from "../../../hooks/useForm";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import useOfferService from "../../../services/offerService";
import OfferSettlementsForm from "../OfferSettlementsForm";
import { SETTLEMENT_READONLY_TABLE_FIELDS_CONFIG } from "../OfferSettlementsForm/FormTablesConfig";
import useSettlementData from "../../../hooks/useSettlementData";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { OFFER_ACCEPED_BY_RR } from "../../../helpers/constants";


const OfferDecrementForm = (props) => {

  const { t } = useTranslation();

  const snackbarAlert = useSnackbarAlert();

  const {
    formValue,
    setFormValue,
  } = useForm({'id' : props.offerId});

  const {
    updateOfferData,
    getOfferSummaryCostsData,
  } = useOfferService();

  const {
    offerSettlementData,
    settlementLoading,
  } = useSettlementData(
    props.offerId,
    SETTLEMENT_READONLY_TABLE_FIELDS_CONFIG,
    undefined,
    true
  )

  const onPrepareDataToSave = (data, action = undefined) => {
    let dataToSend = {}
  
    dataToSend.offer_details = data
    dataToSend.action = action
    return dataToSend
}

  const updateOfferDataFn = useAsyncFn(updateOfferData);

    const onOfferUpdate = (offerId, data, refetchData = false) => {
        updateOfferDataFn
            .execute(offerId, data)
            .then((res) => {
                snackbarAlert.openSuccessSnackbarAlert(
                    t("snackbar_alert.offer_data_updated")
                );
                if (refetchData){
                  offerDetails.refetch()
                }else{
                  props.onAcceptOffer()
                }
            })
            .catch((error) => {
                snackbarAlert.openErrorSnackbarAlert(
                    t("snackbar_alert.occurred_error_during_updating_offer")
                );
            });
    } 



  const offerDetails = useAsync(
    () => getOfferSummaryCostsData(props.offerId),
    [props.offerId, props.formMode]
  );

  useEffect(() => {
    if (offerDetails.loading) {
      return;
    }
    setFormValue(offerDetails.value)
  }, [offerDetails.loading]);

  const onChangeOfferComment = useCallback((e) => {
    let tempForm = formValue
    tempForm[e.target.name] = e.target.value
    setFormValue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    onOfferUpdate(props.offerId, onPrepareDataToSave(tempForm), true )
  }, [props.offerId, formValue]);

  const onSendOfferToRR = useCallback(() => {
    onOfferUpdate(props.offerId, onPrepareDataToSave(formValue, OFFER_ACCEPED_BY_RR), false)
  }, [props.offerId, formValue]);


const isLoading = offerDetails.loading || settlementLoading

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <BaseBox style={{ marginTop: "20px" }}>
        <Grid
          container spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Grid item xs={12}>
            <Typography
              textAlign="center"
              variant="overline"
              display="block"
              gutterBottom
              style={{ margin: 0, fontSize: "20px", color: "var(--primary)" }}
            >
              {`${t("form.offer_decrement_form.offer_nr")} ${formValue?.offer_nr}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <OfferSummaryDetailsForm
              data={formValue} />
          </Grid>
          <Grid item xs={4}>
            <OfferCommentsForm
              offer_k_note={formValue?.offer_k_note}
              offer_rr_note={formValue?.offer_rr_note}
              offer_om_note={formValue?.offer_om_note}
              readOnly={false}
              dialogMode={props.formMode}
              onChange={onChangeOfferComment} />
          </Grid>
          <Grid item xs={2}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            size="small"
            loading={updateOfferDataFn.loading}
            onClick={onSendOfferToRR}
          >
            
            {t("form.offer_decrement_form.accept_and_send_to_k")}
          </LoadingButton>
            </Grid>
          <Grid item xs={12}>
            <OfferSettlementsForm
              data={offerSettlementData ? offerSettlementData : []}
              tableConfig={SETTLEMENT_READONLY_TABLE_FIELDS_CONFIG}
              readOnly={true}
            />
          </Grid>
        </Grid>
    </BaseBox>
  );
};

OfferDecrementForm.propTypes = {
  data: PropTypes.object,
  dialogMode: PropTypes.oneOf(["acceptRR", "settlements", "preview"]),
  onAcceptOffer : PropTypes.func
};

OfferDecrementForm.defaultProps = {
  data: {}
};

export default OfferDecrementForm;
