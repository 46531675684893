import { useTranslation } from "react-i18next";
import BasicDialog from "../../base/BasicDialog";
import CreateUserSkillForm from "../../form/CreateUserSkillForm";
import { useForm } from "../../../hooks/useForm";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useItemService from "../../../services/itemService";
import { useAsyncFn } from "../../../hooks/useAsync";
import CreateParameterForm from "../../form/CreateParameterForm/CreateParameterForm";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { getErrorMsg, isEmptyValue } from "../../../helpers/methods";

const CreateParameterDialog = ({ open, onClose, onRefetchParamatersList }) => {

    const { t } = useTranslation();

    const requiredFields = [
        "param_name",
        "param_unit"
      ];

    const {
        formValue,
        onChange
    } = useForm({});

    const snackbarAlert = useSnackbarAlert();

    const { createParameter } = useItemService()
    const createParameterFn = useAsyncFn(createParameter)

    const onSubmit = () => {
        createParameterFn
            .execute(formValue)
            .then((result) => {
                snackbarAlert.openSuccessSnackbarAlert(
                    t("snackbar_alert.parameter_created")
                );
                onRefetchParamatersList()
                onClose();
            })
            .catch((error) => {
                snackbarAlert.openErrorSnackbarAlert(
                  getErrorMsg(error.data),
                  t("snackbar_alert.occurred_error_during_parameter_creating")
                );
              })
    }

    const isFormValid = () => {
        return requiredFields.every(
          (fieldName) => !isEmptyValue(formValue[fieldName])
        );
      };

    return (
        <BasicDialog
            open={open}
            onClose={onClose}
            maxWidth={"xs"}
            titleAlign="center"
            title={t("dialog.create_parameter_dialog.create_parameter")}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CreateParameterForm
                        onChange={onChange}
                        formValue={formValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        loading={createParameterFn.loading}
                        disabled={!isFormValid()}
                        onClick={onSubmit}
                    >
                        {t("create")}
                    </LoadingButton>
                </Grid>
            </Grid>
        </BasicDialog>
    )
}

export default CreateParameterDialog