import React, { useCallback } from "react";
import { Grid } from "@mui/material";
import SelectField from "../../../field/SelectField";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { TIME_OPTIONS_HOURS } from "../../../../helpers/constants";

const SidebarHeaderContent = React.memo((props) => {
  const { t } = useTranslation();

  const selectFontSize = "14px";

  const onClearSkill = useCallback(() => {
    props.onChangeSkill({ target: { value: undefined, name: "skill" } });
  }, [props.onChangeSkill]);

  return (
    <Grid
      container
      {...props.getRootProps()}
      style={{
        ...props.getRootProps().style,
        background: "white",
        padding: 0.5,
      }}
    >
      <Grid item xs={12}>
        <SelectField
          size="small"
          variant="outlined"
          options={props.skillSelectList}
          style={{ height: "30px", fontSize: selectFontSize }}
          optionLabel={"skill_name"}
          onChange={props.onChangeSkill}
          value={props.skill}
          name="skill"
          label={t("other.planner_timeline.group")}
          onClear={onClearSkill}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0.5}>
          <Grid item xs={12} sm={6}>
            <SelectField
              options={TIME_OPTIONS_HOURS}
              value={props.startHour}
              isObjectOption={false}
              style={{ height: "30px", fontSize: selectFontSize }}
              onChange={props.onChangeStartHour}
              onClear={props.onClearStartHour}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              options={TIME_OPTIONS_HOURS}
              value={props.endHour}
              isObjectOption={false}
              style={{ height: "30px", fontSize: selectFontSize }}
              onChange={props.onChangeEndHour}
              onClear={props.onClearEndHour}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6} style={{ textTransform: "uppercase" }}>
            {t("other.planner_timeline.team")}
          </Grid>
          <Grid item xs={6} style={{ textTransform: "uppercase" }}>
            {t("other.planner_timeline.employee")}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

SidebarHeaderContent.propTypes = {
  startHour: PropTypes.string,
  endHour: PropTypes.string,
  skillSelectList: PropTypes.array,
  onChangeStartHour: PropTypes.func,
  onChangeEndHour: PropTypes.func,
  onClearStartHour: PropTypes.func,
  onClearEndHour: PropTypes.func,
  onChangeSkill: PropTypes.func,
  skill: PropTypes.string,
};

SidebarHeaderContent.defaultProps = {};

export default SidebarHeaderContent;
