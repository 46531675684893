import i18n from "../../../i18n";
import FileTableCell from "../../other/FileTableCell/FileTableCell";


export const PO_INVOICE_TABLE_FIELDS_CONFIG = [
  {
    name: "fv_nr",
    getValueCallback: (rowData) => rowData.fv_nr,
    label: i18n.t("table.po_invoices_table.fv_number"),
  },
  {
    name: "fv_date",
    getValueCallback: (rowData) => rowData.fv_date,
    label: i18n.t("table.po_invoices_table.fv_date"),
  },
  {
    name: "fv_date_sales",
    getValueCallback: (rowData) => rowData.fv_date_sales,
    label: i18n.t("table.po_invoices_table.fv_date_sales"),
  },
  {
    name: "fv_issuer",
    getValueCallback: (rowData) => rowData.fv_issuer,
    label: i18n.t("table.po_invoices_table.fv_issuer"),
  },
  {
    name: "fv_payer",
    getValueCallback: (rowData) => rowData.fv_payer,
    label: i18n.t("table.po_invoices_table.fv_payer"),
  },
  {
    name: "fv_net",
    getValueCallback: (rowData) => rowData.fv_net,
    label: i18n.t("table.po_invoices_table.fv_net"),
  },
  {
    name: "fv_vat",
    getValueCallback: (rowData) => rowData.fv_vat,
    label: i18n.t("table.po_invoices_table.fv_vat"),
  },
  {
    name: "fv_gross",
    getValueCallback: (rowData) => rowData.fv_gross,
    label: i18n.t("table.po_invoices_table.fv_gross"),
  },
  {
    name: "fv_desc",
    getValueCallback: (rowData) => rowData.fv_desc,
    label: i18n.t("table.po_invoices_table.fv_desc"),
  },
  {
    name: "fv_blueDew",
    getValueCallback: (rowData) => rowData.fv_blueDew,
    label: i18n.t("table.po_invoices_table.fv_blueDew"),
  },
  {
    name: "fv_date_payment",
    getValueCallback: (rowData) => rowData.fv_date_payment,
    label: i18n.t("table.po_invoices_table.fv_date_payment"),

  },
  {
    name: "fv_doc",
    getValueCallback: (rowData, onPreviewDoc, onDownloadDoc) => {
      const content = (
        <FileTableCell
        fileType={'docs'} 
        file={rowData.fv_doc} 
        onPreview={onPreviewDoc}
        onDownload = {onDownloadDoc}
        readOnly={true}/>
      );
      return rowData.fv_doc
        ? content
        : "";
    },
    label: i18n.t("table.po_invoices_table.fv_doc"),
  },
];
