import * as React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  StyledTableCell,
  StyledTableRow,
} from "../../base/BaseTable/BaseTable";
import { Tooltip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CheckboxField from "../../base/CheckboxField/checkboxField";
import ContextMenu from "../../wrapper/ContextMenu";

import IconButton from "@mui/material/IconButton";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";

import TableService from "../../../services/tableService";

function areRowDataPropsEqual(oldProps, newProps) {
  return (
    oldProps.id === newProps.id &&
    oldProps.selected === newProps.selected &&
    oldProps.backgroundColor === newProps.backgroundColor &&
    oldProps.data.length === newProps.data.length &&
    oldProps.updated === newProps.updated &&
    oldProps.showContextMenu === newProps.showContextMenu
  );
}

const FilterTableDataRow = React.memo((props) => {
  const { t } = useTranslation();
  const handleClickDetailsIcon = (e) => {
    e.stopPropagation();
    props.onClickDetailsIcon();
  };

  return (
    <React.Fragment>
      <ContextMenu
        key={props.id}
        showMenu={props.showContextMenu}
        actions={props.contextMenuActions.map((action) => ({
          label: action.label,
          icon: action.icon,
          callback: () => action.callback(props.id),
        }))}
        Component={
          <StyledTableRow
            sx={{
              "& > *": {
                borderBottom: "unset",
                cursor: "pointer",
                background: props.backgroundColor,
              },
            }}
            onClick={() => {
              props.onClickRow(props.id);
            }}
            draggable={props.draggableRow}
            onDragStart={
              props.onDragStart
                ? (e) => {
                    props.onDragStart(e, props.data);
                  }
                : undefined
            }
            onDragEnd={
              props.onDragEnd
                ? (e) => {
                    props.onDragEnd(e, props.id);
                  }
                : undefined
            }
          >
            {props.showChangesCircle && (
              <StyledTableCell>
                {props.hasChanges ? (
                  <CircleIcon
                    color="success"
                    fontSize="small"
                    sx={{ marginLeft: "8px" }}
                  />
                ) : (
                  <CircleIcon
                    color="disabled"
                    fontSize="small"
                    sx={{ marginLeft: "8px" }}
                  />
                )}
              </StyledTableCell>
            )}
            {props.showCheckbox && (
              <StyledTableCell>
                <CheckboxField
                  size="small"
                  labelPlacement={"bottom"}
                  checked={props.selected}
                  onChange={() => props.onClickCheck(props.id)}
                />
              </StyledTableCell>
            )}
            {props.showDetailsIcon && (
              <StyledTableCell>
                <Tooltip title={t("open_in_new_explorer_window")}>
                  <IconButton size="small" onClick={handleClickDetailsIcon}>
                    <OpenInBrowserIcon />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            )}
            {props.showFilters && <StyledTableCell />}
            {props.data
              .filter((rowData) => !rowData.onlyData)
              .map((rowData, index) => {
                return (
                  <StyledTableCell
                    key={index}
                    component="th"
                    align="center"
                    style={{ ...rowData.cellStyle }}
                  >
                    {rowData.valueAsCheckbox ? (
                      <CheckboxField checked={rowData.value} disabled={true} />
                    ) : (
                      TableService.getCellContent(
                        rowData.value,
                        rowData.maxTextLength
                      )
                    )}
                  </StyledTableCell>
                );
              })}
          </StyledTableRow>
        }
      />
      <StyledTableRow>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={props.data.length + 1}
          align="center"
        ></StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}, areRowDataPropsEqual);

export default FilterTableDataRow;

FilterTableDataRow.propTypes = {
  id: PropTypes.string,
  updated: PropTypes.string,
  backgroundColor: PropTypes.string,
  data: PropTypes.array,
  selected: PropTypes.bool,
  onClickCheck: PropTypes.func,
  showDetailsIcon: PropTypes.bool,
  onClickDetailsIcon: PropTypes.func,
  onClickRow: PropTypes.func,
  CollapsibleElement: PropTypes.element,
  selectedItemId: PropTypes.string,
  style: PropTypes.object,
  showCheckbox: PropTypes.bool,
  showContextMenu: PropTypes.bool,
  contextMenuActions: PropTypes.arrayOf(PropTypes.object),
  showFilters: PropTypes.bool,
  draggableRow: PropTypes.bool,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  hasChanges: PropTypes.func,
  showChangesCircle: PropTypes.func,
};

FilterTableDataRow.defaultProps = {
  showCheckbox: true,
  showDetailsIcon: false,
  showContextMenu: false,
  contextMenuActions: [],
  showFilters: true,
  draggableRow: false,
  hasChanges: true,
  showChangesCircle: false,
};
