import { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import {
  DOCS_RESTRICTED_TABLE_NAME,
  DOCS_RESTRICTED_TABLE_FILTER_PREFIX,
  DOCS_RESTRICTED_TABLE_FIELDS_CONFIG,
} from "./PageTablesConfig";

import DocsRestrictedTable from "../../components/table/DocsRestrictedTable";

import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import useFilterSearchParams from "../../hooks/useFilterSearchParams";

import useDocsService from "../../services/docsService";
import { useAsync } from "../../hooks/useAsync";

import useDialog from "../../hooks/useDialog";
import DocsUserNoteDialog from "../../components/dialog/DocsUserNoteDialog/DocsUserNoteDialog";

import { useAuth } from "../../context/auth";

export default function DocsRestrictedTableWithToolBar(props) {
  const { t } = useTranslation();

  const { user } = useAuth();

  const [
    openDocsUserNoteDialog,
    onOpenDocsUserNoteDialog,
    onCloseDocsUserNoteDialog,
  ] = useDialog();
  const docsIdRef = useRef();
  const docsUserNoteId = useRef();

  const handleClickDocsUserNote = (docsId, doscUserNoteId) => {
    docsIdRef.current = docsId;
    docsUserNoteId.current = doscUserNoteId;

    onOpenDocsUserNoteDialog();
  };

  const [dataLocaly, setDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams(DOCS_RESTRICTED_TABLE_FILTER_PREFIX);

  const { setNewItemSearchParamsIfAreChanged, getSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);
  const [docsSearchParams, setDocsSearchParams] = useState(
    getSearchParamsByFilterPrefix(DOCS_RESTRICTED_TABLE_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      DOCS_RESTRICTED_TABLE_FILTER_PREFIX,
      docsSearchParams,
      setDocsSearchParams
    );
  });

  const {
    getDocsRestrictedData,
    getDocsRestrictedFilteringData,
    handleDownloadDoc,
    handlePreviewDoc,
  } = useDocsService();

  const docsRestrictedData = useAsync(
    () => getDocsRestrictedData(docsSearchParams),
    [docsSearchParams]
  );

  const docsRestrictedFilteringData = useAsync(getDocsRestrictedFilteringData);

  useEffect(() => {
    if (docsRestrictedData.loading) {
      return;
    }
    setDataLocaly(docsRestrictedData.value.results);
    setCountRecords(docsRestrictedData.value.count);
  }, [docsRestrictedData.loading]);

  const handleEditDocsUserNote = () => {
    setDataLocaly(undefined);
    docsRestrictedData.refetch();
  };

  const isLoading =
    docsRestrictedFilteringData.loading ||
    dataLocaly === undefined ||
    countRecords === undefined;

  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={0}
    >
      <Grid item xs={12}>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          style={{ margin: 0, color: "var(--primary)" }}
        >
          {t("table.docs_restricted.table_name")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <LoaderWrapper showLoader={isLoading}>
          <DocsRestrictedTable
            data={dataLocaly}
            showCheckbox={false}
            showCleanFilterIcon={false}
            countRecords={countRecords}
            page={page}
            onPageChange={handleChangePageWithSearchParams}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            resetPageNumber={resetPageNumber}
            filteringData={docsRestrictedFilteringData}
            style={{ maxHeight: "75vh" }}
            tableConfig={DOCS_RESTRICTED_TABLE_FIELDS_CONFIG}
            onDownload={handleDownloadDoc}
            onPreview={handlePreviewDoc}
            filterPrefix={DOCS_RESTRICTED_TABLE_FILTER_PREFIX}
            onClickEditUserNote={handleClickDocsUserNote}
          />
        </LoaderWrapper>
      </Grid>
      {openDocsUserNoteDialog && (
        <DocsUserNoteDialog
          open={openDocsUserNoteDialog}
          onClose={onCloseDocsUserNoteDialog}
          onSubmit={handleEditDocsUserNote}
          docsId={docsIdRef.current}
          userId={user.user_id}
          docsUserNoteId={docsUserNoteId.current}
        />
      )}
    </Grid>
  );
}
