import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import FloatField from "../../base/FloatField/FloatField";
import { useTranslation } from "react-i18next";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";

const SubcontractorForm = (props) => {

  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextFieldFormControl
          name={"subcontractor_full_name"}
          label={t(`form.subcontractor_form.subcontractor_full_name`)}
          value={props.formValue.subcontractor_full_name}
          onChange={props.onChange}
          required
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormControl
          name={"subcontractor_short_name"}
          label={t(`form.subcontractor_form.subcontractor_short_name`)}
          value={props.formValue.subcontractor_short_name}
          onChange={props.onChange}
          required
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormControl
          name={"subcontractor_legal"}
          label={t(`form.subcontractor_form.subcontractor_legal`)}
          value={props.formValue.subcontractor_legal}
          onChange={props.onChange}
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <FloatField
          name={"subcontractor_nip"}
          label={t(`form.subcontractor_form.nip`)}
          value={props.formValue.subcontractor_nip}
          onChange={props.onChange}
          disabled={props.readOnly}
          required
          decimalPlaces={0}
        />
      </Grid>
      <Grid item xs={6}>
        <FloatField
          name={"subcontractor_krs"}
          label={t(`form.subcontractor_form.krs`)}
          value={props.formValue.subcontractor_krs}
          onChange={props.onChange}
          disabled={props.readOnly}
          decimalPlaces={0}
        />
      </Grid>
      <Grid item xs={6}>
        <FloatField
          name={"subcontractor_regon"}
          label={t(`form.subcontractor_form.regon`)}
          value={props.formValue.subcontractor_regon}
          onChange={props.onChange}
          disabled={props.readOnly}
          decimalPlaces={0}
        />
      </Grid>
      <Grid item xs={6}>
        <TrueFalseSelectField
          name="subcontractor_is_contractor"
          readOnly={props.readOnly}
          valuesAsBool
          value={props.formValue.subcontractor_is_contractor}
          label={t("form.subcontractor_form.contractor")}
          onChange={props.onChange}
          addEmptyOptions={false}
        />
      </Grid>
      <Grid item xs={6}>
        <TrueFalseSelectField
          name="subcontractor_is_producer"
          readOnly={props.readOnly}
          valuesAsBool
          value={props.formValue.subcontractor_is_producer}
          label={t("form.subcontractor_form.producer")}
          onChange={props.onChange}
          addEmptyOptions={false}
        />
      </Grid>
      <Grid item xs={6}>
        <TrueFalseSelectField
          name="subcontractor_is_supplier"
          readOnly={props.readOnly}
          valuesAsBool
          value={props.formValue.subcontractor_is_supplier}
          label={t("form.subcontractor_form.supplier")}
          onChange={props.onChange}
          addEmptyOptions={false}
        />
      </Grid>
      {props.subcontractorId &&
      <Grid item xs={6}>
        <TrueFalseSelectField
          name="subcontractor_active"
          readOnly={props.readOnly}
          valuesAsBool
          value={props.formValue.subcontractor_active}
          label={t("form.subcontractor_form.subcontractor_active")}
          onChange={props.onChange}
          addEmptyOptions={false}
        />
      </Grid>}
   
    </Grid>
  );
};

SubcontractorForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
};

SubcontractorForm.defaultProps = {
  readOnly: false,
};

export default SubcontractorForm;
