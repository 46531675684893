import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SplitButton from "../SplitButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import {  getFieldValueFromSearchParams} from "../../../helpers/methods";
import { centerVericalAlignIconStyle } from "../../../helpers/styles";
import useFillterButton from "../../../hooks/useFilterButton";

function ActiveDesactiveButton(props) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const {handleClickFilterButton} = useFillterButton();

  const filterOptions = [
    {
      label: t("button.active_disactive_filter_button.all"),
      key: "active_desactive",
      callback: () => handleClickFilter(undefined),
    },
    {
      label: t("button.active_disactive_filter_button.desactive"),
      key: "desactive",
      callback: () => handleClickFilter(false),
      startIcon: <FilterAltOffIcon sx={centerVericalAlignIconStyle} />,
    },
    {
      label: t("button.active_disactive_filter_button.active"),
      key: "active",
      callback: () => handleClickFilter(true),
      startIcon: <FilterAltIcon sx={centerVericalAlignIconStyle} />,
    },
  ];

  const selectedFilterKey = useMemo(() => {
    const filterValue = getFieldValueFromSearchParams(
      searchParams,
      props.openFilterKey
    );

    if (filterValue === "true") return "active";
    if (filterValue === "false") return "desactive";
    return "active_desactive";
  }, [searchParams]);

  const handleClickFilter = (filterValue) => {
    handleClickFilterButton(filterValue, props.openFilterKey)
  };



  return (
    <SplitButton
      selectedItemKey={selectedFilterKey}
      withChangeSelectedItem={true}
      options={filterOptions}
    />
  );
}

ActiveDesactiveButton.propTypes = {
  openFilterKey: PropTypes.string,
};

ActiveDesactiveButton.defaultProps = {
  openFilterKey: "owner_active",
};

export default ActiveDesactiveButton;
