import { useTranslation } from "react-i18next";
import BasicDialog from "../../base/BasicDialog";
import { useForm } from "../../../hooks/useForm";
import CreateSkillForm from "../../form/CreateSkillForm";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import useUserService from "../../../services/userService";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useSkillService from "../../../services/skillService";
import { getErrorMsg } from "../../../helpers/methods";

const CreateSkillDialog = ({ open, onClose, initFormValue = {} }) => {
  const { t } = useTranslation();

  const { formValue, onChange, onChangeAutocompleteFieldWithObjectOptions } =
    useForm(initFormValue);

  const { getEmployeesSelectList, getUserSelectList } = useUserService();

  const { createSkill, updateSkill } = useSkillService();

  const snackbarAlert = useSnackbarAlert();

  const createSkillFn = useAsyncFn(createSkill);
  const updateSkillFn = useAsyncFn(updateSkill);

  const employeesSelectList = useAsync(getEmployeesSelectList, []);
  const leaderSelectList = useAsync(() =>
    getUserSelectList("roles=role_is_kt&roles=role_is_tb")
  );

  const onSubmit = () => {
    if (formValue.id) {
      const skillId = formValue.id;
      delete formValue.id;
      updateSkillFn
        .execute(skillId, formValue)
        .then((result) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.team_updated")
          );
          onClose(true);
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(
              error.data,
              t("snackbar_alert.occurred_error_during_team_updated")
            )
          );
        });
    } else {
      createSkillFn
        .execute(formValue)
        .then((result) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.team_created")
          );
          onClose(true);
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(
              error.data,
              t("snackbar_alert.occurred_error_during_team_created")
            )
          );
        });
    }
  };

  return (
    <BasicDialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth={"sm"}
      titleAlign="center"
      title={t("dialog.management_teams_dialog.management_teams")}
    >
      <LoaderWrapper
        showLoader={employeesSelectList.loading || leaderSelectList.loading}
      >
        <CreateSkillForm
          onChange={onChange}
          onChangeAutocompleteFieldWithObjectOptions={
            onChangeAutocompleteFieldWithObjectOptions
          }
          skillName={formValue.skill_name}
          leaders={formValue.leaders}
          employees={formValue.employees}
          description={formValue.skill_desc}
          employeesSelectList={employeesSelectList.value}
          leaderSelectList={leaderSelectList.value}
          onSubmit={onSubmit}
        />
      </LoaderWrapper>
    </BasicDialog>
  );
};

export default CreateSkillDialog;
