
import { useState } from "react";


const useBasicDrawer = (defaultOpen = false, defaultDrawerWidth=350, defaultDrawerTitle=undefined) => {
    const [open, setOpen] = useState(defaultOpen);
    const [drawerWidth, setDrawerWidth] = useState(defaultDrawerWidth);
    const [drawerTitle, setDrawerTitle] = useState(defaultDrawerTitle);

    return [open, setOpen, drawerWidth, setDrawerWidth, drawerTitle, setDrawerTitle];
  };
  
  export default useBasicDrawer;