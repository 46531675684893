import React from "react";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import { TableCell, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export default function NoRecordsTableFooter({colspan, style}) {

    const { t } = useTranslation();
    return (
        <TableFooter>
            <TableRow >
                <TableCell colspan = {colspan}>
                <Typography
                    textAlign ="center"
                    variant="overline"
                    display="block"
                    gutterBottom
                    style={{margin: 0, fontSize: "20px", color: "var(--primary)", ...style }}
                >
                    {t("no_records")}
                </Typography>
                </TableCell>
            </TableRow>
        </TableFooter>
    );
}

NoRecordsTableFooter.propTypes = {
    colspan : PropTypes.number 
  }; 
  NoRecordsTableFooter.defaultProps = {
    colspan : 0
  };
