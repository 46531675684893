import {
  skillSelectListUrl,
  skillUrl,
  skillUserUrl,
  skillUserFilteringDataUrl,
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { prepareUrlWithQueryParams } from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function useSkillService() {
  const axiosPrivate = useAxiosPrivate();

  const getSkillSelectList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(skillSelectListUrl, searchParams)
    );
  };

  const getSkillUserFilteringData = () => {
    return makeRequest(axiosPrivate, skillUserFilteringDataUrl);
  };

  const getSkillHasUser = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(skillUserUrl, searchParams)
    );
  };

  const createSkill = (dataToSend) => {
    return makeRequest(axiosPrivate, skillUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateSkill = (skillId, dataToSend) => {
    return makeRequest(axiosPrivate, `${skillUrl}${skillId}/`, {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getSkillsUserIsLeaderSelectList = (user = undefined) => {
    let params = {};
    if (user) {
      params = { leader: user.user_id };
    }
    return getSkillSelectList(params);
  };
  return {
    getSkillHasUser,
    createSkill,
    updateSkill,
    getSkillSelectList,
    getSkillsUserIsLeaderSelectList,
    getSkillUserFilteringData,
  };
}
