import { useState, useEffect, useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useForm } from "../../../hooks/useForm";

import useTicketService from "../../../services/ticketService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";

import OverridingForm from "../../form/OverridingForm";
import OverridingStdTicketForm from "../../form/OverridingStdTicketsForm";
import useLocationService from "../../../services/locationService";

import CreateTicketDialog from "../CreateTicketDialog";
import useDialog from "../../../hooks/useDialog";

import {
  getErrorMsg,
  isEmptyValue,
  title,
  isArraysEquals,
} from "../../../helpers/methods";

import {
  DIALOG_PREVIEW_MODE,
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
} from "../../../helpers/constants";

const REQUIRED_FIELDS = [
  "overriding_date_plan",
  "tenant",
  "overriding_subkind",
  "location",
];

const CONCTACT_DETAILS_FIELDS = [
  "overriding_contact_full_name",
  "overriding_contact_email",
  "overriding_contact_phone",
];

const FIELDS_TO_SAVE_IN_CREATE_MODE = [
  ...REQUIRED_FIELDS,
  ...CONCTACT_DETAILS_FIELDS,
  "overriding_note",
];

const FIELDS_TO_SAVE_IN_EDIT_MODE = ["overriding_date_plan"];

const OverridingDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const isCreateMode = props.dialogMode === DIALOG_CREATE_MODE;
  const isEditMode = props.dialogMode === DIALOG_EDIT_MODE;

  const dialogTitle = useMemo(() => {
    if (props.title) return props.title;

    return isCreateMode
      ? t("dialog.overriding.add_new_overriding")
      : isEditMode
      ? t("dialog.overriding.edit_overriding")
      : t("dialog.overriding.overriding_details");
  }, [props.dialogMode, props.title]);

  const submitButtonLabel = useMemo(() => {
    if (props.submitButtonLabel) return props.submitButtonLabel;

    return isCreateMode ? t("create") : isEditMode ? t("save") : null;
  }, [props.dialogMode, props.submitButtonLabel]);

  const isReadOnly = useMemo(
    () => props.readOnly || props.dialogMode === DIALOG_PREVIEW_MODE,
    [props.readOnly, props.dialogMode]
  );

  const fieldsToSave = useMemo(
    () =>
      props.fieldsToSave
        ? props.fieldsToSave
        : isCreateMode
        ? FIELDS_TO_SAVE_IN_CREATE_MODE
        : isEditMode
        ? FIELDS_TO_SAVE_IN_EDIT_MODE
        : [],
    [(props.fieldsToSave, props.dialogMode)]
  );

  const readOnlyFields = useMemo(
    () =>
      [
        ...REQUIRED_FIELDS,
        ...CONCTACT_DETAILS_FIELDS,
        "overriding_note",
      ].filter((field) => !fieldsToSave.includes(field)),
    [fieldsToSave]
  );

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm();

  const handleChangeLocation = (locationData) => {
    setFormValue((prev) => ({
      ...prev,
      location: locationData,
    }));
  };

  const {
    prepareLocationDataToSend,
    prepareLocationDataFromBackendForLocationRecordsComponent,
  } = useLocationService();

  const {
    getStdTicketsConfigForHandOver,
    getOverridingDetailsData,
    getOverridingFilteringData,
    createOverriding,
    updateOverriding,
  } = useTicketService();

  const stdTicketsConfigForHandOver = useAsync(getStdTicketsConfigForHandOver);

  const overridingInitialData = useAsync(() => {
    if (isCreateMode) {
      return Promise.resolve({
        configured_std_tickets: [],
        overriding_kind: "hand_over",
      });
    }

    return getOverridingDetailsData(props.overridingId, {
      include_configured_std_tickets: true,
    });
  }, [props.overridingId, props.dialogMode]);

  useEffect(() => {
    if (overridingInitialData.loading) return;

    let { overriding_contact_details, ...tempOverridingInitialData } = {
      ...overridingInitialData.value,
    };
    tempOverridingInitialData.location =
      prepareLocationDataFromBackendForLocationRecordsComponent(
        overridingInitialData.value?.location
      );
    tempOverridingInitialData.overriding_contact_full_name =
      overriding_contact_details?.full_name;
    tempOverridingInitialData.overriding_contact_email =
      overriding_contact_details?.email;
    tempOverridingInitialData.overriding_contact_phone =
      overriding_contact_details?.phone;

    setFormValue(tempOverridingInitialData);
  }, [overridingInitialData.loading]);

  const prepareDataToSend = () => {
    const dataToSend = {};
    for (const [key, value] of Object.entries(formValue)) {
      if (CONCTACT_DETAILS_FIELDS.includes(key)) {
        continue;
      }

      if (fieldsToSave.includes(key)) {
        if (key === "location") {
          dataToSend["object"] = prepareLocationDataToSend(value)[0];
          continue;
        }
        dataToSend[key] = value;
      }
    }
    dataToSend.tickets = ticketsConfiguredLocaly;
    dataToSend.automatic_message_content = getDefaultTicketDescCreator();

    if (isCreateMode) {
      dataToSend.overriding_contact_details = {
        full_name: formValue.overriding_contact_full_name,
        email: formValue.overriding_contact_email,
        phone: formValue.overriding_contact_phone,
      };
    }

    return dataToSend;
  };

  const overridingFilteringData = useAsync(getOverridingFilteringData);

  const updateOverridingFn = useAsyncFn(updateOverriding);
  const addOverridingFn = useAsyncFn(createOverriding);
  const submitFn = useMemo(
    () => (isCreateMode ? addOverridingFn : updateOverridingFn),
    [props.dialogMode]
  );

  const handleSubmit = (dataToSend) => {
    submitFn
      .execute(dataToSend, props.overridingId)
      .then((res) => {
        const successMsg = isCreateMode
          ? t("snackbar_alert.overriding_added")
          : t("snackbar_alert.overriding_updated");
        snackbarAlert.openSuccessSnackbarAlert(successMsg);
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isFormValid =
    formValue &&
    REQUIRED_FIELDS.every((field) => {
      if (!fieldsToSave.includes(field)) return true;

      if (field === "location") {
        return (
          formValue["location"][0].filter((object) => object.id).length === 4
        );
      }

      return !isEmptyValue(formValue[field]);
    });

  const [
    openCreateTicketDialog,
    onOpenCreateTicketDialog,
    onCloseCreateTicketDialog,
  ] = useDialog();

  const ticketInitialDataRef = useRef();

  const getDefaultTicketDescCreator = () => {
    const tenantName = overridingFilteringData.value.tenant.find(
      (tenant) => tenant.id === formValue.tenant
    ).tenant_short_name;

    const locationLevel1 = formValue.location[0][0].object_name;
    const locationLevel2 = formValue.location[0][1].object_name;
    const locationLevel3 = formValue.location[0][2].object_name;
    const locationLevel4 = formValue.location[0][3].object_name;

    const overridingSubkind = t(
      `backend_choices_list.${formValue.overriding_subkind}`
    );

    const overridingDatePlan = formValue.overriding_date_plan;

    const getOverridingConcatDetails = () => {
      const contactDetailsArray = CONCTACT_DETAILS_FIELDS.map(
        (field) => formValue[field]
      ).filter((field) => !isEmptyValue(field));

      return `\n${t(
        "dialog.overriding.overriding_contact"
      )}: ${contactDetailsArray.join(", ")}.`;
    };

    let tempDefaultTicketDescCreator = `${t(
      "dialog.overriding.new_overriding"
    )}. ${t("dialog.overriding.tenant")}: ${tenantName}; ${t(
      "dialog.overriding.overriding_date_plan"
    )}: ${overridingDatePlan}; ${t(
      "dialog.overriding.location"
    )}: ${locationLevel1}, ${locationLevel2}, ${title(
      locationLevel3
    )}, ${locationLevel4}; ${t(
      "dialog.overriding.overriding_subkind"
    )}: ${overridingSubkind}.`;

    if (
      CONCTACT_DETAILS_FIELDS.some((field) => !isEmptyValue(formValue[field]))
    ) {
      tempDefaultTicketDescCreator += getOverridingConcatDetails();
    }

    return tempDefaultTicketDescCreator;
  };

  const getDataFromConfiguredTicketLocaly = (configuredTicketLocaly) => ({
    ticket_name: configuredTicketLocaly.ticket_name,
    ticket_desc_creator: configuredTicketLocaly.ticket_desc_creator,
    question_answers: configuredTicketLocaly.answers_for_question,
    enclosures: configuredTicketLocaly.enclosures,
    tikcet_contact: configuredTicketLocaly.tikcet_contact,
    ticket_om: configuredTicketLocaly.ticket_om
      ? overridingFilteringData.value.user.find(
          (user) => user.id === configuredTicketLocaly.ticket_om
        )
      : undefined,
  });

  const handleOpenCreateTicketDialog = (stdTicketConfig) => {
    ticketInitialDataRef.current = {
      std_ticket: stdTicketConfig.std_ticket_id,
      ticket_name: stdTicketConfig.std_ticket_name,
      ticket_category: stdTicketConfig.ticket_category_id,
      locations: formValue.location,
    };

    const configuredTicketLocaly = ticketsConfiguredLocaly.find(
      (configuredTicket) =>
        configuredTicket.std_ticket === stdTicketConfig.std_ticket_id
    );

    if (configuredTicketLocaly) {
      ticketInitialDataRef.current = {
        ...ticketInitialDataRef.current,
        ...getDataFromConfiguredTicketLocaly(configuredTicketLocaly),
      };
    }

    onOpenCreateTicketDialog();
  };

  const handleCloseCreateTicketDialog = (isTicketCanceled) => {
    if (isTicketCanceled === true) {
      setTicketsConfiguredLocaly((prev) =>
        prev.filter(
          (ticket) =>
            ticket.std_ticket !== ticketInitialDataRef.current?.std_ticket
        )
      );
    }

    ticketInitialDataRef.current = null;
    onCloseCreateTicketDialog();
  };

  const [ticketsConfiguredLocaly, setTicketsConfiguredLocaly] = useState([]);
  const handleCreateTicket = (ticketData) => {
    const configuredTicketLocaly = ticketsConfiguredLocaly.find(
      (configuredTicket) =>
        configuredTicket.std_ticket === ticketData.std_ticket
    );
    if (configuredTicketLocaly) {
      setTicketsConfiguredLocaly((prev) =>
        prev.map((configuredTicket) =>
          configuredTicket.std_ticket === configuredTicketLocaly.std_ticket
            ? ticketData
            : configuredTicket
        )
      );
    } else {
      setTicketsConfiguredLocaly((prev) => [...prev, ticketData]);
    }
  };

  const isLoading =
    formValue === undefined ||
    overridingFilteringData.loading ||
    stdTicketsConfigForHandOver.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
        columnSpacing={2}
      >
        <Grid item xs={12}>
          <OverridingForm
            formValue={formValue}
            filteringData={overridingFilteringData}
            onChange={onChange}
            onChangeDate={onChangeDate}
            onChangeAutocompleteFieldWithObjectOptions={
              onChangeAutocompleteFieldWithObjectOptions
            }
            onChangeLocation={handleChangeLocation}
            readOnly={isReadOnly}
            readOnlyFields={readOnlyFields}
          />
        </Grid>
        {isFormValid && (
          <Grid item xs={12} marginY={2}>
            <OverridingStdTicketForm
              readOnly={isReadOnly}
              stdTicketConfig={stdTicketsConfigForHandOver.value}
              stdTicketConfigured={
                overridingInitialData.value?.configured_std_tickets
              }
              stdTicketConfiguredLocal={ticketsConfiguredLocaly.map(
                (stdTicket) => stdTicket.std_ticket
              )}
              onClick={handleOpenCreateTicketDialog}
            />
          </Grid>
        )}
        {!isReadOnly && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              fullWidth
              loading={addOverridingFn.loading || updateOverridingFn.loading}
              disabled={!isFormValid}
              onClick={() => handleSubmit(prepareDataToSend())}
            >
              {submitButtonLabel}
            </LoadingButton>
          </Grid>
        )}
        {openCreateTicketDialog && (
          <CreateTicketDialog
            open={openCreateTicketDialog}
            onClose={handleCloseCreateTicketDialog}
            returnTrueIfTicketCanceled={true}
            initialData={ticketInitialDataRef.current}
            onSubmitReplacement={handleCreateTicket}
            readOnlyFields={["std_ticket", "ticket_category", "locations"]}
            maxAllowedLevelNumber={3}
            allowOnlySingleLocation={true}
          />
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      showTopFullScreenButton={true}
      title={dialogTitle}
      maxWidth="md"
      showDialogActions
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

OverridingDialog.propTypes = {
  dialogMode: PropTypes.oneOf([
    DIALOG_CREATE_MODE,
    DIALOG_EDIT_MODE,
    DIALOG_PREVIEW_MODE,
  ]),
  readOnly: PropTypes.bool,
  overridingId: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onSubmitCallback: PropTypes.func,
  fieldsToSave: PropTypes.array,
};

OverridingDialog.defaultProps = {
  open: false,
};

export default OverridingDialog;
