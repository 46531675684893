import { Grid } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";

import { NAVIGATION_DRAWER_TYPE_REPORTS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import RcpTimeTrackingSearchBar from "../../components/bar/RcpTimeTrackingSearchBar";
import { RCP_TIME_TRACKING_PREFIX } from "./RcpTimeTrackingTables/TablesConfigs";
import RcpTimeTrackingTables from "./RcpTimeTrackingTables";

import { SEARCH_PARAMS_SUMMARY_PAGE_SIZE_KEY } from "./constants";

export default function RcpTimeTrackingPage(props) {
  const { pageName } = props;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_REPORTS}
    >
      <DefaultPageWrapper titleKey={"rcp_time_tracking"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <RcpTimeTrackingSearchBar
              tableFilterPrefixes={[RCP_TIME_TRACKING_PREFIX]}
              excludedKeysForClearFilters={[
                SEARCH_PARAMS_SUMMARY_PAGE_SIZE_KEY,
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <RcpTimeTrackingTables pageName={pageName} />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
