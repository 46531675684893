import { useState, useEffect, useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import useDialog from "../../hooks/useDialog";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";
import TableService from "../../services/tableService";
import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import FilterDialog from "../../components/base/FilterDialog";
import UserFilterDialog from "../../components/dialog/UserFilterDialog";
import {
  ICO_REGISTRY_TABLE_FIELDS_CONFIG,
  ICO_REGISTRY_TABLE_NAME,
} from "./PageTablesConfig";
import UniversalFilterForm from "../../components/form/UniversalFilterForm";
import AgreementTable from "../../components/table/AgreementTable";
import { exportAgreementsToFileUrl } from "../../helpers/apiUrls";
import RelatedAgreementsBox from "../../components/box/RelatedAgreementsBox/RelatedAgreementsBox";
import FileUploadList from "../../components/other/FileUploadList";
import BaseBox from "../../components/base/BaseBox/baseBox";
import { NAVIGATION_DRAWER_TYPE_ICO} from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import useDocsData from "../../hooks/useDocsData";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import UniversalToolBar from "../../components/bar/UniversalToolBar";
import useIcoService from "../../services/icoService";
import IcoConfiguratorDialog from "../../components/dialog/IcoConfiguratorDialog";
import { DOCS_RESTRICTED_OWNER, DOCS_RESTRICTED_USER } from "../../helpers/constants";

const TABLE_CONFIGS = [
  { name: ICO_REGISTRY_TABLE_NAME, config: ICO_REGISTRY_TABLE_FIELDS_CONFIG },
];

export default function IcoRegistryPage(props) {
  const { pageName } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const avaibleRestrictedAreas =  [DOCS_RESTRICTED_OWNER, DOCS_RESTRICTED_USER]

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  // const [hasEditPermission] = useCheckPermission(EDIT_AGREEMENTS_PERMISSION)
  const [hasEditPermission] = [true]
  const [icoRegistryTableConfigLocal, setIcoRegistryTableConfigLocal] = useState(
    ICO_REGISTRY_TABLE_FIELDS_CONFIG
  );
  const [icosDataLocal, setIcosDataLocal] = useState([]);
  const [countIcoRecord, setCountIcoRecord] = useState();
  const [selectedIcoId, setSelectedIcoId] = useState();
  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState([]);
  const [unhiddenColumnsForTables, setUnhiddenColumnsForTables] = useState([]);
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();
  const [relatedIcosLocal, setRelatedIcosLocal] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);


  const onCleanFlitersInFilterDialog = () => {
    setIcosDataLocal(undefined);
    setCountIcoRecord(undefined);
  };

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const [
    openUserFiltersDialog,
    onOpenUserFiltersDialog,
    onCloseUserFiltersDialog,
  ] = useDialog();

  const [openAgreementDialog, onOpenIcoDialog, onCloseIcoDialog] =
    useDialog();


  const {
    page: icosDataPage,
    pageSize: icosDataPageSize,
    handleChangePageWithSearchParams: handleChangeIcoDataPage,
    handleChangePageSizeWithSearchParams: handleChangeIcoDataPageSize,
    resetPageNumber,
  } = usePaginationWithSearchParams();

  const {
    docs,
    isDocsLoading,
    updateDocs,
    hideDocs,
    onDownloadDoc,
    onPreviewDoc,
    refetchDocs,
  } = useDocsData(selectedIcoId, "ico");

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getUnhiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

    const {getIcoRegistryList, getIcoFilteringData, getRelatedIcos} = useIcoService();

  const icosData = useAsync(
    () => getIcoRegistryList(searchParams),
    [searchParams]
  );

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const icoFilteringData = useAsync(getIcoFilteringData);
  const getRelatedIcosFn = useAsyncFn(getRelatedIcos);

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const onRelatedIcos = (icoId) => {
    getRelatedIcosFn.execute(icoId).then((data) => {
      setRelatedIcosLocal(data);
    });
  };


  useEffect(() => {
    if (icosData.loading) {
      return;
    }
    setIcosDataLocal(icosData.value.results);
    setCountIcoRecord(icosData.value.count);
  }, [icosData.loading]);


  useEffect(() => {
    if (userPreferencesForPage.loading) {
      setHiddenColumnsForTables(undefined);
      setColumnsOrdersForTables(undefined);
      setUnhiddenColumnsForTables(undefined)
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [ICO_REGISTRY_TABLE_NAME],
        userPreferencesForPage.value,
        {[ICO_REGISTRY_TABLE_NAME]: ICO_REGISTRY_TABLE_FIELDS_CONFIG}
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [ICO_REGISTRY_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    
    setUnhiddenColumnsForTables(() =>
      getUnhiddenColumnFormValueFromBackend(
        [ICO_REGISTRY_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

  }, [userPreferencesForPage.loading]);

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders,
    uhiddenDefaultColumns
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders,
          uhiddenDefaultColumns
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const handleClickIcoRow = (icoId) => {
    if (!icoId || icoId === selectedIcoId) {
      setSelectedIcoId(undefined);
      setRelatedIcosLocal([]);
    } else {
      setSelectedIcoId(icoId);
      onRelatedIcos(icoId);
    }
  };

  const handleOpenIcoDetails = useCallback(
    (e, icoId) => {
      e.stopPropagation();
      setSelectedIcoId(icoId);
      onOpenIcoDialog();
    },
    [selectedIcoId]
  );

  const massActionButtonOptions = []


  const handleOpenNewIco = useCallback(() => {
    onOpenIcoDialog();
  }, [selectedIcoId]);




  const handleClickCheckAll = useCallback(
    (value) => {
      let currValue = !checkedAll;
      setCheckedAll(currValue);

      setIcosDataLocal((prev) => {
        return prev.map((rowData) => ({
          ...rowData,
          selected: currValue,
        }));
      });
    },
    [checkedAll]
  );

  
  const handleClickCheck = useCallback((id) => {
    setIcosDataLocal((prevData) => {
      let selectedRow = { ...prevData.find((rowData) => rowData.id === id) };
      selectedRow.selected = !selectedRow.selected;
      return prevData.map((rowData) => {
        if (rowData.id === id) return selectedRow;
        return rowData;
      });
    });
  }, []);


  const onRefetchDetails = () => {
    if (selectedIcoId) {
      onRelatedIcos(selectedIcoId);
      refetchDocs();
    }
  };

  const closeIcoDialog = useCallback(() => {
    setSelectedIcoId(undefined);
    onCloseIcoDialog();
    icosData.refetch()
  }, [selectedIcoId]);


  const isAgreementTableLoading =
    userPreferencesForPage.loading ||
    hiddenColumnsForTables === undefined ||
    columnsOrdersForTables === undefined ||
    icoFilteringData.loading ||
    icosDataLocal === undefined 


  const isDetailsLoading = getRelatedIcosFn.loading;

  const agreementExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      ICO_REGISTRY_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[ICO_REGISTRY_TABLE_NAME],
      columnsOrdersForTables?.[ICO_REGISTRY_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[ICO_REGISTRY_TABLE_NAME],
    columnsOrdersForTables?.[ICO_REGISTRY_TABLE_NAME],
    ICO_REGISTRY_TABLE_FIELDS_CONFIG,
  ]);


  const getSelectedAgreementDetails = () => {
    return (
      <>      </>
    // <LoaderWrapper showLoader={isDetailsLoading}>
    //     <Grid container spacing={1}>
    //       <Grid item xs={8}>
    //         <RelatedAgreementsBox 
    //         relatedAgreements={relatedIcosLocal}
    //         agreementType = {"ico"}
    //         />
    //       </Grid>

    //       <Grid item xs={4}>
    //         <BaseBox>
    //           <FileUploadList
    //             addEnclosureButtonProps={{ size: "mini" }}
    //             enclosures={docs}
    //             fileType={"docs"}
    //             onPreviewEnclosure={onPreviewDoc}
    //             onDownloadEnclosure={onDownloadDoc}
    //             onDeleteEnclosure={hideDocs}
    //             canRemoveEnclosures={true}
    //             readOnly={isDocsLoading || !hasEditPermission}
    //             onUpdateEnclosure={updateDocs}
    //             ico = {selectedIcoId}
    //             refetchDocs = {refetchDocs}
    //             availableDocsRestrictedTypes= {avaibleRestrictedAreas}
    //             multiple = {true}
    //           />
    //         </BaseBox>
    //       </Grid>
    //     </Grid>
    //   </LoaderWrapper> 

    );
  };
  return (
    <NavigationDrawer
      drawerType={NAVIGATION_DRAWER_TYPE_ICO}
      pageName={pageName}
    >
      <DefaultPageWrapper titleKey={"icos"}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
              <UniversalToolBar
                style={{ marginBlock: "5px" }}
                massActionButtonOptions={massActionButtonOptions}
                onClickSearch={onOpenFilterDialog}
                onClickMyFilters={onOpenUserFiltersDialog}
                onClickAdjustTable={onOpenTableColumnVisibilityDialog}
                onClickCreateItem={handleOpenNewIco}
                showOpenCloseFilterButton={false}
                itemType ={"ico"}
                selectedIcoId = {selectedIcoId}
                showCreateButton={hasEditPermission}
              />
            </Grid>
          <Grid item xs={12}>
            <LoaderWrapper showLoader={isAgreementTableLoading}>
              <AgreementTable
                data={icosDataLocal}
                onClickRow={handleClickIcoRow}
                countRecords={countIcoRecord}
                page={icosDataPage}
                onPageChange={handleChangeIcoDataPage}
                rowsPerPage={icosDataPageSize}
                onRowsPerPageChange={handleChangeIcoDataPageSize}
                resetPageNumber={resetPageNumber}
                filteringData={
                  icoFilteringData ? icoFilteringData : []
                }
                hiddenColumns={
                  hiddenColumnsForTables
                    ? hiddenColumnsForTables[ICO_REGISTRY_TABLE_NAME]
                    : []
                }
                columnsOrders={
                  columnsOrdersForTables
                    ? columnsOrdersForTables[ICO_REGISTRY_TABLE_NAME]
                    : undefined
                }
                selectedAgreementId={selectedIcoId}
                style={{ maxHeight: "40vh" }}
                tableConfig={icoRegistryTableConfigLocal}
                showCleanFilterIcon={true}
                showContextMenu={false}
                showExportToFileButton={true}
                exportToFileUrl={exportAgreementsToFileUrl}
                exportToFileSearchParams={searchParams}
                exportToFileHeaders={agreementExportHeaders}
                exportToFileFileName={`${t(
                  "page.ico_regitry_page.loans_and_bonds"
                ).replace(" ", "_")}.xlsx`}
                showDetailsIcon={false}
                onClickEdit={handleOpenIcoDetails}
                hasPermission={hasEditPermission}
                showCheckbox={true}
                checkedAll={checkedAll}
                onClickCheckAll={handleClickCheckAll}
                onClickCheck={handleClickCheck}

              />
            </LoaderWrapper>
          </Grid>
          <Grid item xs={12}>
            {selectedIcoId ? (
              getSelectedAgreementDetails()
            ) : (
              <Typography
                textAlign="center"
                variant="overline"
                display="block"
                gutterBottom
                style={{ margin: 0, fontSize: "20px", color: "var(--primary)" }}
              >
                {/* {t("page.ico_regitry_page.choose_agreement_to_show_details")} */}
              </Typography>
            )}
          </Grid>
        </Grid>

        {openTableColumnVisibilityDialog && (
          <TableColumnVisibilityDialog
            open={openTableColumnVisibilityDialog}
            onClose={onCloseTableColumnVisibilityDialog}
            onSubmit={handleUpdateUserPreferences}
            tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
              TABLE_CONFIGS,
              columnsOrdersForTables
            )}
            tablesHiddenColumns={hiddenColumnsForTables}
            tablesUnhiddenDefaultColumns = {unhiddenColumnsForTables}
            isLoading={userPreferencesForPage.loading}
          />
        )}
        {openFilterDialog && (
          <FilterDialog
            open={openFilterDialog}
            onCleanFilters={onCleanFlitersInFilterDialog}
            onClose={onCloseFilterDialog}
            resetPageNumber={resetPageNumber}
            filterForm={
              <UniversalFilterForm
                filteringData={icoFilteringData}
                filtersConfig={icoRegistryTableConfigLocal}
                includeOpenCloseFilter={false}
              />
            }
          />
        )}
        {openUserFiltersDialog && (
          <UserFilterDialog
            open={openUserFiltersDialog}
            onClose={onCloseUserFiltersDialog}
            pageName={pageName}
            filterForm={
              <UniversalFilterForm
                filteringData={icoFilteringData}
                filtersConfig={icoRegistryTableConfigLocal}
                includeOpenCloseFilter={false}
              />
            }
          />
        )}
      {openAgreementDialog && 
          <IcoConfiguratorDialog
            open={openAgreementDialog}
            onClose={closeIcoDialog}
            filteringData={icoFilteringData}
            onRefetchData={icosData.refetch}
            onRefetchDetails={onRefetchDetails}
            icoId={selectedIcoId}
            readOnly={false}
          />
      }
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
