import React from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import OfferForm from "../../UniversalDetailsDrawerForms/OfferForm";
import OfferStatusForm from "../../UniversalDetailsDrawerForms/OfferStatusForm";
import OfferCommentsForm from "../../UniversalDetailsDrawerForms/OfferCommentsForm";
import useDialog from "../../../../../hooks/useDialog";
import OfferHistoryLogDialog from "../../../../dialog/OfferHistoryLogDialog/OfferHistoryLogDialog";
import { useTranslation } from "react-i18next";

const OfferDrawerContent = (props) => {

  const onClear = (e, name) => {
    e = { ...e, target: { ...e.target, value: null, name: name } }
    props.onChangeOfferDetails(e)
  }
  const { t } = useTranslation();

  const [
    openLogDialog,
    onOpenLogDialog,
    handleCloseLogDialog,
  ] = useDialog();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      paddingX={1}
      rowGap={1}
      marginTop={1}
      marginBottom={3}
    >
      <Grid item xs={12}>
        <OfferForm
          data={props.itemData}
          filteringData={props.filteringData}
          onChange={props.onChangeOfferDetails}
          onChangeDate={props.onChangeDate}
          onChangeAutocompletedOfferDetails={props.onChangeAutocompletedOfferDetails}
          onClear={(e, name) => onClear(e, name)}
          readOnly={props.readOnly}
          dialogMode={props.dialogMode}
          unfillRequiredFields={props.unfillRequiredFields}
          onDecrementPayers={props.onDecrementPayers}
          settlements={props.settlements}
          showSettlements={props.showSettlements}
        />
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <OfferCommentsForm
          offer_k_note={props.itemData?.offer_k_note}
          offer_rr_note={props.itemData?.offer_rr_note}
          offer_om_note={props.itemData?.offer_om_note}
          readOnly={props.itemData?.is_read_only}
          dialogMode={props.dialogMode}
          onChange={props.onChangeOfferDetails}
          allowEditKNote={props.allowEditKNote} />
      </Grid>
      {props.dialogMode !== "newOffer" &&
        <>
          <Grid item xs={12}>
            <Divider />
            <OfferStatusForm
              currentStatus={props.itemData?.current_status} />
          </Grid>
          {props.showLogsButton &&
          <Grid item xs={12}>
            <Divider />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={onOpenLogDialog}
            >
              {t(`dialog.offer_history_log_dialog.show_changes_request_history_table`)}
            </Button>
          </Grid>}
        </>}
      {openLogDialog &&
        <OfferHistoryLogDialog
          offerId={props.itemData.id}
          open={openLogDialog}
          onClose={handleCloseLogDialog}
        />
      }
    </Grid>
  );
};

OfferDrawerContent.propTypes = {
  itemData: PropTypes.object,
  readOnly: PropTypes.bool,
  onClear: PropTypes.func,
  onChangeOfferDetailst: PropTypes.func,
  filteringData: PropTypes.object,
  onChangeAutocompletedOfferDetails: PropTypes.func,
  dialogMode: PropTypes.oneOf(["newOffer", "existOffer", "joinOffer", "preview"]),
  showLogsButton : PropTypes.bool
};

OfferDrawerContent.defaultProps = {
  readOnly: true,
  showLogsButton : false
};

export default OfferDrawerContent;
