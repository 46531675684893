import React, { useEffect } from "react";

import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import { useAuth } from "../../../context/auth";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import DateTimeField from "../../field/DateTimeField";

import { useTranslation } from "react-i18next";
import hasUserPermission, {
  IS_ADMIN,
  IS_KT,
} from "../../../helpers/userPermissions";
import { EXTERNAL_TYPE, INTERNAL_TYPE } from "../../../helpers/constants";
import FileUploadList from "../../other/FileUploadList";

import LocationRecords from "../../other/LocationRecords";
const CreateTaskForm = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const isStartDateInvalid =
    props.formValue.task_date_startT_real >= new Date();
  const startDateHelperText = isStartDateInvalid
    ? t("form.create_task_form.date_must_be_less_then_current_date")
    : "";

  const isStopDateGreaterThenCurrentDate =
    props.formValue.task_date_stopT_real >= new Date();
  const isStopDateLessThenStartDate =
    props.formValue.task_date_startT_real &&
    props.formValue.task_date_stopT_real &&
    props.formValue.task_date_startT_real >=
    props.formValue.task_date_stopT_real;
  const stopDateHelperText = isStopDateGreaterThenCurrentDate
    ? t("form.create_task_form.date_must_be_less_then_current_date")
    : isStopDateLessThenStartDate
      ? t("form.create_task_form.stop_date_must_be_greather_then_start_date")
      : "";
  const isStopDateInvalid =
    isStopDateGreaterThenCurrentDate || isStopDateLessThenStartDate;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <TextFieldFormControl
          readOnly={true}
          label={t("form.create_task_form.task_nr")}
          value={props.formValue.task_nr}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldFormControl
          name="task_name"
          label={t("form.create_task_form.task_name")}
          value={props.formValue.task_name}
          onChange={props.onChange}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutocompleteField
          name="task_t"
          label={t("form.create_task_form.task_t")}
          value={props.formValue.task_t}
          options={props.filteringData.task_t}
          readOnly={!hasUserPermission([IS_KT, IS_ADMIN], user)}
          isObjectOption={true}
          optionLabelKey={"full_name"}
          addNewValue={false}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutocompleteField
          name="status"
          label={t("form.create_task_form.status")}
          value={props.formValue.status}
          options={props.filteringData.status}
          isObjectOption={true}
          optionLabelKey={"status_name"}
          addNewValue={false}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <LocationRecords
          locations={props.locations}
          onChangeLocations={props.onChangeLocations}
          showAddLocationButton={false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateTimeField
          label={t("form.create_task_form.task_date_start")}
          onChange={props.onChange}
          name={"task_date_startT_real"}
          value={props.formValue.task_date_startT_real}
          isInvalid={isStartDateInvalid}
          helperText={startDateHelperText}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateTimeField
          label={t("form.create_task_form.task_date_stop")}
          onChange={props.onChange}
          name={"task_date_stopT_real"}
          value={props.formValue.task_date_stopT_real}
          isInvalid={isStopDateInvalid}
          helperText={stopDateHelperText}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="task_desc_creator"
          label={t("form.create_task_form.task_desc_creator")}
          value={props.formValue.task_desc_creator}
          onChange={props.onChange}
          multiline={3}
          rows={3}
        />
      </Grid>
      <Grid item xs={12}>
        <FileUploadList
          addEnclosureButtonProps={{ size: "mini" }}
          defaultEnclosureType={INTERNAL_TYPE}
          enclosures={props.enclosures}
          onDownloadEnclosure={props.onDownloadEnclosure}
          onAddEnclosure={props.onAddEnclosure}
          onDeleteEnclosure={props.onDeleteEnclosure}
        />
      </Grid>
    </Grid>
  );
};

CreateTaskForm.propTypes = {
  formValue: PropTypes.object,
  filteringData: PropTypes.object,
  locations: PropTypes.array,
  onChange: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  onChangeLocations: PropTypes.func,
  enclosures: PropTypes.array,
  onDownloadEnclosure: PropTypes.func,
  onAddEnclosure: PropTypes.func,
  onDeleteEnclosure: PropTypes.func,
};

CreateTaskForm.defaultProps = {};

export default CreateTaskForm;
