import PropTypes from "prop-types";

import OrdersReportBasePage from "../OrdersReportBasePage";

import SaftyReportsNavigationBar from "../../components/bar/SaftyReportsNavigationBar";

export default function OrdersSaftyReportPage(props) {
  return (
    <OrdersReportBasePage
      pageName={props.pageName}
      pageTitleKey={props.pageTitleKey}
      groupBy={props.groupBy}
      labelBy={props.labelBy}
      showSelectTicketCategory={props.showSelectTicketCategory}
      showSelectOrderKt={props.showSelectOrderKt}
      showSelectTenant={props.showSelectTenant}
      NavigationBar={<SaftyReportsNavigationBar />}
      showNavigationBar={true}
      showChartInformationDialog={props.showChartInformationDialog}
      chartDescrtiption={props.chartDescrtiption}
    />
  );
}

OrdersSaftyReportPage.propTypes = {
  labelBy: PropTypes.string,
  groupBy: PropTypes.string,
  showSelectTicketCategory: PropTypes.bool,
  showSelectOrderKt: PropTypes.bool,
  showSelectTenant: PropTypes.bool,
  pageName: PropTypes.string,
  pageTitleKey: PropTypes.string,
  NavigationBar: PropTypes.element,
  showNavigationBar: PropTypes.bool,
  chartHeight: PropTypes.string,
  chartHeightInFullScreen: PropTypes.string,
  showChartInformationDialog: PropTypes.bool,
  chartDescrtiption: PropTypes.element,
};

OrdersSaftyReportPage.defaultProps = {
  chartHeight: "45vh",
  chartHeightInFullScreen: "72vh",
};
