import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";

import LoadingButton from "@mui/lab/LoadingButton";

export default function FilterEditForm(props) {
  const { t } = useTranslation();
  const [formValue, setFormValue] = useState(props.formValue);
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormValue((formValue) => ({ ...formValue, [name]: value }));
  };

  const isFormValid =
    formValue.filter_name && formValue.filter_is_default != undefined;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
      margin={0}
    >
      <Grid item xs={12} sm={6}>
        <TextFieldFormControl
          name="filter_name"
          label={t("form.filter.filter_name")}
          value={formValue.filter_name}
          onChange={onChange}
          readOnly={props.readOnly}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TrueFalseSelectField
          name="filter_is_default"
          label={t("form.filter.filter_is_default")}
          value={formValue.filter_is_default}
          onChange={onChange}
          readOnly={props.readOnly}
          valuesAsBool={true}
          required
        />
      </Grid>
      {!props.readOnly && (
        <>
          <Grid item xs={12} sm={6}>
            <LoadingButton
              variant="contained"
              size="small"
              fullWidth
              onClick={() => props.onSubmit(formValue)}
              disabled={!isFormValid}
              loading={props.isDataSaving}
            >
              {t("confirm")}
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              onClick={props.onCancel}
              disabled={props.isDataSaving}
            >
              {t("cancel")}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}

FilterEditForm.propTypes = {
  formValue: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  readOnly: PropTypes.bool,
  isDataSaving: PropTypes.bool,
};

FilterEditForm.defaultProps = {
  formValue: {},
  isDataSaving: false,
};
