import PropTypes from "prop-types";
import BaseTable from "../../base/BaseTable/BaseTable";
import TableService from "../../../services/tableService";



const MultipleLocalizationTable = (props) => {

  return (
    <>
      <BaseTable
        headers={TableService.getHeaderLables(
          props.headersConfig
        )}
        style={props.style}
        rows={TableService.getPreparedDataForBaseTable(
          props.headersConfig,
          props.localizationData
        )}
      />
    </>
  );
};

MultipleLocalizationTable.propTypes = {
  localizationData: PropTypes.array,
  style: PropTypes.object,
  headersConfig: PropTypes.array
};

MultipleLocalizationTable.defaultProps = {
};

export default MultipleLocalizationTable;
