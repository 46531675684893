import React, { useMemo } from "react";
import Avatar from "@mui/material/Avatar";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import LocalParkingOutlinedIcon from "@mui/icons-material/LocalParkingOutlined";
import ElevatorOutlinedIcon from "@mui/icons-material/ElevatorOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import DoorFrontOutlinedIcon from "@mui/icons-material/DoorFrontOutlined";
import MonitorOutlinedIcon from "@mui/icons-material/MonitorOutlined";
import HeatPumpOutlinedIcon from "@mui/icons-material/HeatPumpOutlined";
import HouseSidingOutlinedIcon from "@mui/icons-material/HouseSidingOutlined";
import CleanHandsOutlinedIcon from "@mui/icons-material/CleanHandsOutlined";
import FireExtinguisherOutlinedIcon from "@mui/icons-material/FireExtinguisherOutlined";
import LocalLaundryServiceOutlinedIcon from "@mui/icons-material/LocalLaundryServiceOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FormatPaintOutlinedIcon from "@mui/icons-material/FormatPaintOutlined";
import LocalFloristOutlinedIcon from "@mui/icons-material/LocalFloristOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import WashOutlinedIcon from "@mui/icons-material/WashOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import TtyOutlinedIcon from "@mui/icons-material/TtyOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import DraftsIcon from "@mui/icons-material/Drafts";
import DescriptionIcon from "@mui/icons-material/Description";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import HeatPumpIcon from "@mui/icons-material/HeatPump";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EuroIcon from "@mui/icons-material/Euro";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BusinessIcon from "@mui/icons-material/Business";
import LogoutIcon from "@mui/icons-material/Logout";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import ConstructionIcon from "@mui/icons-material/Construction";
import FireTruckIcon from "@mui/icons-material/FireTruck";
import SchemaIcon from "@mui/icons-material/Schema";
import SecurityIcon from "@mui/icons-material/Security";
import PhonelinkIcon from "@mui/icons-material/Phonelink";
import TryIcon from "@mui/icons-material/Try";
import PersonIcon from "@mui/icons-material/Person";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import FireExtinguisherIcon from "@mui/icons-material/FireExtinguisher";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import CreditCardIcon from '@mui/icons-material/CreditCard';

import StairsOutlinedIcon from "@mui/icons-material/StairsOutlined";
import { useTheme } from "@emotion/react";

const CardIconWithText = React.memo((props) => {
  const theme = useTheme();

  const chooseIcon = () => {
    const fontSize = "large";
    switch (props.icon) {
      case "DescriptionOutlinedIcon":
        return <DescriptionOutlinedIcon fontSize={fontSize} />;
      case "BadgeOutlinedIcon":
        return <BadgeOutlinedIcon fontSize={fontSize} />;
      case "CleaningServicesOutlinedIcon":
        return <CleaningServicesOutlinedIcon fontSize={fontSize} />;
      case "LocalParkingOutlinedIcon":
        return <LocalParkingOutlinedIcon fontSize={fontSize} />;
      case "DirectionsBikeIcon":
        return <DirectionsBikeIcon fontSize={fontSize} />;
      case "ElevatorOutlinedIcon":
        return <ElevatorOutlinedIcon fontSize={fontSize} />;
      case "WarningAmberOutlinedIcon":
        return <WarningAmberOutlinedIcon fontSize={fontSize} />;
      case "DoorFrontOutlinedIcon":
        return <DoorFrontOutlinedIcon fontSize={fontSize} />;
      case "MonitorOutlinedIcon":
        return <MonitorOutlinedIcon fontSize={fontSize} />;
      case "HeatPumpOutlinedIcon":
        return <HeatPumpOutlinedIcon fontSize={fontSize} />;
      case "HouseSidingOutlinedIcon":
        return <HouseSidingOutlinedIcon fontSize={fontSize} />;
      case "CleanHandsOutlinedIcon":
        return <CleanHandsOutlinedIcon fontSize={fontSize} />;
      case "FireExtinguisherOutlinedIcon":
        return <FireExtinguisherOutlinedIcon fontSize={fontSize} />;
      case "LocalLaundryServiceOutlinedIcon":
        return <LocalLaundryServiceOutlinedIcon fontSize={fontSize} />;
      case "AddCircleOutlineOutlinedIcon":
        return <AddCircleOutlineOutlinedIcon fontSize={fontSize} />;
      case "FormatPaintOutlinedIcon":
        return <FormatPaintOutlinedIcon fontSize={fontSize} />;
      case "LocalFloristOutlinedIcon":
        return <LocalFloristOutlinedIcon fontSize={fontSize} />;
      case "ConstructionOutlinedIcon":
        return <ConstructionOutlinedIcon fontSize={fontSize} />;
      case "LocalShippingOutlinedIcon":
        return <LocalShippingOutlinedIcon fontSize={fontSize} />;
      case "WashOutlinedIcon":
        return <WashOutlinedIcon fontSize={fontSize} />;
      case "TtyOutlinedIcon":
        return <TtyOutlinedIcon fontSize={fontSize} />;
      case "ShoppingCartOutlinedIcon":
        return <ShoppingCartOutlinedIcon fontSize={fontSize} />;
      case "QuizOutlinedIcon":
        return <QuizOutlinedIcon fontSize={fontSize} />;
      case "GroupsIcon":
        return <GroupsIcon fontSize={fontSize} />;
      case "DraftsIcon":
        return <DraftsIcon fontSize={fontSize} />;
      case "DescriptionIcon":
        return <DescriptionIcon fontSize={fontSize} />;
      case "AccountBalanceIcon":
        return <AccountBalanceIcon fontSize={fontSize} />;
      case "WorkspacePremiumIcon":
        return <WorkspacePremiumIcon fontSize={fontSize} />;
      case "WarehouseIcon":
        return <WarehouseIcon fontSize={fontSize} />;
      case "DeviceThermostatIcon":
        return <DeviceThermostatIcon fontSize={fontSize} />;
      case "Diversity3Icon":
        return <Diversity3Icon fontSize={fontSize} />;
      case "RunningWithErrorsIcon":
        return <RunningWithErrorsIcon fontSize={fontSize} />;
      case "HeatPumpIcon":
        return <HeatPumpIcon fontSize={fontSize} />;
      case "ShoppingCartIcon":
        return <ShoppingCartIcon fontSize={fontSize} />;
      case "EngineeringIcon":
        return <EngineeringIcon fontSize={fontSize} />;
      case "ApartmentIcon":
        return <ApartmentIcon fontSize={fontSize} />;
      case "DashboardIcon":
        return <DashboardIcon fontSize={fontSize} />;
      case "AssessmentIcon":
        return <AssessmentIcon fontSize={fontSize} />;
      case "AdminPanelSettingsIcon":
        return <AdminPanelSettingsIcon fontSize={fontSize} />;
      case "EuroIcon":
        return <EuroIcon fontSize={fontSize} />;
      case "BusinessIcon":
        return <BusinessIcon fontSize={fontSize} />;
      case "LogoutIcon":
        return <LogoutIcon fontSize={fontSize} />;
      case "ElectricMeterIcon":
        return <ElectricMeterIcon fontSize={fontSize} />;
      case "NaturePeopleIcon":
        return <NaturePeopleIcon fontSize={fontSize} />;
      case "ConstructionIcon":
        return <ConstructionIcon fontSize={fontSize} />;
      case "FireTruckIcon":
        return <FireTruckIcon fontSize={fontSize} />;
      case "SchemaIcon":
        return <SchemaIcon fontSize={fontSize} />;
      case "SecurityIcon":
        return <SecurityIcon fontSize={fontSize} />;
      case "PhonelinkIcon":
        return <PhonelinkIcon fontSize={fontSize} />;
      case "TryIcon":
        return <TryIcon fontSize={fontSize} />;
      case "PersonIcon":
        return <PersonIcon fontSize={fontSize} />;
      case "ReportGmailerrorredIcon":
        return <ReportGmailerrorredIcon fontSize={fontSize} />;
      case "FireExtinguisherIcon":
        return <FireExtinguisherIcon fontSize={fontSize} />;
      case "RequestQuoteIcon":
        return <RequestQuoteIcon fontSize={fontSize} />;
      case "HandshakeIcon":
        return <HandshakeIcon fontSize={fontSize} />;
      case "AddHomeWorkIcon":
        return <AddHomeWorkIcon fontSize={fontSize} />;
      case "FiberNewIcon":
        return <FiberNewIcon fontSize={fontSize} />;
      case "AlternateEmailIcon":
        return <AlternateEmailIcon fontSize={fontSize} />;
      case "AddBusinessIcon":
        return <AddBusinessIcon fontSize={fontSize} />;
      case "CreditCardIcon":
        return <CreditCardIcon fontSize={fontSize} />;
      case "StairsOutlinedIcon":
        return <StairsOutlinedIcon fontSize={fontSize} />;
      default:
        return null;
    }
  };

  const getIcon = useMemo(() => {
    if (React.isValidElement(props.icon)) {
      return props.icon;
    }
    return chooseIcon();
  }, [props.icon]);

  return (
    <Card
      sx={{
        color: "#FFFFFF",
        background: props.isSelected ? theme.palette.primary.light : "grey",
      }}
    >
      <CardActionArea
        sx={{ padding: 1, ...props.cardAreaStyle }}
        onClick={props.onClick}
      >
        {getIcon}
        <CardContent sx={{ padding: "0 !important" }}>
          <Typography
            variant="body2"
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {props.label}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
});

CardIconWithText.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.oneOfType(PropTypes.string, PropTypes.element),
  isSelected: PropTypes.bool,
  cardAreaStyle: PropTypes.object,
};

CardIconWithText.defaultProps = {
  categories: [],
  isSelected: false,
};

export default CardIconWithText;
