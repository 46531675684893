import i18n from "../../../i18n";

import { convertFlatListFromBackendToTranslatedOptionObject } from "../../../helpers/methods";

import {
  NUMERIC_RANGE_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
  TIME_RANGE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../../helpers/constants";

import DateService from "../../../services/dateService";

export const RCP_TIME_TRACKING_TABLE = "rcp_table";
export const RCP_TIME_TRACKING_PREFIX = "rcp_time";
export const RCP_TIME_TRACKING_CONFIG = [
  {
    name: "employee",
    getValueCallback: (rowData) => rowData.employee,
    label: i18n.t("table.schedule.employee"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.employee,
      isObjectOption: true,
      optionLabelKey: "full_name",
      multiple: true,
    }),
  },
  {
    name: "day",
    getValueCallback: (rowData) => rowData.rcpdone_day,
    label: i18n.t("table.schedule.day"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    cellStyle: { whiteSpace: "nowrap" },
    other_export_field_name: "rcpdone_day",
  },

  {
    name: "rcpdone_activity",
    getValueCallback: (rowData) =>
      i18n.t(`table.schedule.${rowData[`rcpdone_activity`]}`),
    label: i18n.t("table.schedule.type"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: convertFlatListFromBackendToTranslatedOptionObject(
        filteringData.rcpplan_activity
      ),
      isObjectOption: true,
      multiple: true,
    }),
  },
  {
    name: "rcpdone_hours",
    getValueCallback: (rowData) => rowData.rcpdone_hours,
    label: i18n.t("table.schedule.amount_hours_per_day"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "ticket_nr",
    getValueCallback: (rowData) => rowData.ticket_nr,
    label: i18n.t("table.schedule.ticket_nr"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "ticket_name",
    getValueCallback: (rowData) => rowData.ticket_name,
    label: i18n.t("table.schedule.ticket_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "order_nr",
    getValueCallback: (rowData) => rowData.order_nr,
    label: i18n.t("table.schedule.order_nr"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "order_name",
    getValueCallback: (rowData) => rowData.order_name,
    label: i18n.t("table.schedule.order_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "task_nr",
    getValueCallback: (rowData) => rowData.task_nr,
    label: i18n.t("table.schedule.task_nr"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "task_name",
    getValueCallback: (rowData) => rowData.task_name,
    label: i18n.t("table.schedule.task_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "task_date_startT_real",
    getValueCallback: (rowData) =>
      DateService.getHoursAndMinutesStringFromStringData(
        rowData.task_date_startT_real
      ),
    label: i18n.t("table.schedule.time_start"),
    filterType: TIME_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "task_date_stopT_real",
    getValueCallback: (rowData) =>
      DateService.getHoursAndMinutesStringFromStringData(
        rowData.task_date_stopT_real
      ),
    label: i18n.t("table.schedule.time_end"),
    filterType: TIME_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },

  {
    name: "task_duration",
    getValueCallback: (rowData) => rowData.task_duration,
    label: i18n.t("table.schedule.duration"),
    filterType: TIME_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];

export const RCP_TIME_TRACKING_SUMMARY_CONFIG = [
  {
    name: "employee",
    getValueCallback: (rowData) => rowData.employee,
    label: i18n.t("table.schedule.employee"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.employee,
      isObjectOption: true,
      optionLabelKey: "full_name",
      multiple: true,
    }),
  },
  {
    name: "rcpdone_activity",
    getValueCallback: (rowData) =>
      i18n.t(`table.schedule.${rowData[`rcpdone_activity`]}`),
    label: i18n.t("table.schedule.type"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: convertFlatListFromBackendToTranslatedOptionObject(
        filteringData.rcpplan_activity
      ),
      isObjectOption: true,
      multiple: true,
    }),
  },
  {
    name: "rcpdone_assumption_hours",
    getValueCallback: (rowData) => rowData.rcpdone_assumption_hours,
    label: i18n.t("table.schedule.rcpdone"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "rcpdone_realization_hours",
    getValueCallback: (rowData) => rowData.rcpdone_realization_hours,
    label: i18n.t("table.schedule.realization_hours"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "rcpdone_realization_percent",
    getValueCallback: (rowData) => rowData.rcpdone_realization_percent,
    label: i18n.t("table.schedule.realization_percent"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];
