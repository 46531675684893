import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Grid} from "@mui/material";
import { useState} from "react";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import { isEmptyValue } from "../../../helpers/methods";
import LoadingButton from "@mui/lab/LoadingButton";


function OfferRejectDialog(props) {

    const { t } = useTranslation();


    const [formValue, setFormValue] = useState({});

    const onChange = (e) => {
        const { name, value } = e.target;

        setFormValue((formValue) => ({
            ...formValue,
            [name]: value,
        }));
    };

    return (
        <BasicDialog
            open={props.open}
            onClose={props.onClose}
            titleAlign="center"
            contentAlign="center"
            title={t("dialog.offer_reject_dialog.offer_reject")}
            maxWidth="sm"
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
            >
                <>
                    <Grid item xs={12}>
                        <TextFieldFormControl
                            name={"offer_om_note"}
                            label =  {props.isOfferCR ? t("dialog.offer_reject_dialog.add_reason_reject_cr"): t("dialog.offer_reject_dialog.add_reason_reject_offer")}
                            value={formValue.offer_om_note}
                            multiline={true}
                            rows={4}
                            disabled={props.readOnly}
                            onChange={(e) => onChange(e)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            variant="contained"
                            color="error"
                            fullWidth
                            loading ={props.loading}
                            onClick={() => props.onSubmit(formValue)}
                            disabled = {isEmptyValue(formValue.offer_om_note)}
                        >
                            {props.isOfferCR ?t("dialog.offer_reject_dialog.reject_cr") : t("dialog.offer_reject_dialog.reject_offer")}
                        </LoadingButton>
                    </Grid>
                </>

            </Grid>
        </BasicDialog>
    )

}

OfferRejectDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit : PropTypes.func,
    isOfferCR : PropTypes.bool

};
OfferRejectDialog.defaultProps = {
    open: false,
    isOfferCR:false
};

export default OfferRejectDialog