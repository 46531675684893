import React, { useRef, useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useAsync } from "../../../hooks/useAsync";
import useOrderService from "../../../services/orderService";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import CollapsibleTable from "../CollapsibleTable";
import TableService from "../../../services/tableService";
import StdOrderTable from "../StdOrderTable";

import useDialogWithId from "../../../hooks/useDialogWithId";
import StdOrderDialog from "../../dialog/StdOrderDialog";

import {
  STD_ORDER_TABLE_NAME,
  STD_TICKET_TABLE_NAME,
  STD_TICKET_TABLE_CONFIG,
  STD_OTDER_TABLE_CONFIG,
} from "../../../page/AdminStdTicketStdOrderPage/TableConfig";

import { DIALOG_EDIT_MODE } from "../../../helpers/constants";

const InnerStdOrderTable = React.memo(
  ({
    id,
    updatedInnerElementData,
    tableConfig,
    hiddenColumns,
    columnsOrders,
  }) => {
    const [stdOrderDataLocal, setstdOrderDataLocal] = useState(undefined);
    const { getStdOrderForStdTicketAdminData } = useOrderService();

    const [
      openStdOrderDialog,
      stdOrderId,
      onOpenStdOrderDialog,
      onCloseStdOrderDialog,
    ] = useDialogWithId();

    const stdOrderData = useAsync(
      () => getStdOrderForStdTicketAdminData(id),
      [updatedInnerElementData, id]
    );

    useEffect(() => {
      if (!stdOrderData.loading) {
        setstdOrderDataLocal(stdOrderData.value);
      }
    }, [stdOrderData.loading]);

    return (
      <LoaderWrapper showLoader={stdOrderData.loading}>
        <>
          {stdOrderDataLocal && (
            <StdOrderTable
              showChangesCircle={false}
              showChangesCircleFilter={false}
              data={stdOrderDataLocal}
              showCheckbox={false}
              showCleanFilterIcon={false}
              onClickEdit={onOpenStdOrderDialog}
              hiddenColumns={hiddenColumns}
              columnsOrders={columnsOrders}
              tableConfig={tableConfig}
              showFilters={false}
              withPagination={false}
              showCounterRecords={false}
            />
          )}
        </>
        {openStdOrderDialog && (
          <StdOrderDialog
            open={openStdOrderDialog}
            onClose={onCloseStdOrderDialog}
            dialogMode={DIALOG_EDIT_MODE}
            onSubmitCallback={stdOrderData.refetch}
            stdOrderId={stdOrderId}
          />
        )}
      </LoaderWrapper>
    );
  }
);

const StdTicketTable = React.memo((props) => {
  const data = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        STD_TICKET_TABLE_CONFIG,
        props.data,
        props.hiddenColumns[STD_TICKET_TABLE_NAME],
        props.columnsOrders[STD_TICKET_TABLE_NAME],
        props.onClickEdit
      ),
    [
      props.data,
      props.hiddenColumns[STD_TICKET_TABLE_NAME],
      props.columnsOrders[STD_TICKET_TABLE_NAME],
    ]
  );
  return (
    <CollapsibleTable
      showChangesCircle={props.showChangesCircle}
      checkedAll={props.checkedAll}
      showCheckbox={props.showCheckbox}
      showDetailsIcon={props.showDetailsIcon}
      showCleanFilterIcon={props.showCleanFilterIcon}
      onClickRow={props.onClickRow}
      onClickCheckAll={props.onClickCheckAll}
      data={data}
      filteringData={props.filteringData}
      countRecords={props.countRecords}
      page={props.page}
      onPageChange={props.onPageChange}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      rowsPerPageOptions={props.rowsPerPageOptions}
      resetPageNumber={props.resetPageNumber}
      headersConfig={TableService.getHeadersConfigForCollapsibleTable(
        STD_TICKET_TABLE_CONFIG,
        props.filteringData,
        props.hiddenColumns[STD_TICKET_TABLE_NAME],
        props.columnsOrders[STD_TICKET_TABLE_NAME]
      )}
      CollapsibleElement={
        <InnerStdOrderTable
          hiddenColumns={props.hiddenColumns[STD_ORDER_TABLE_NAME]}
          columnsOrders={props.columnsOrders[STD_ORDER_TABLE_NAME]}
          tableConfig={STD_OTDER_TABLE_CONFIG}
        />
      }
      style={props.style}
      filterPrefix={props.filterPrefix}
      showExportToFileButton={props.showExportToFileButton}
      exportToFileUrl={props.exportToFileUrl}
      exportToFileSearchParams={props.exportToFileSearchParams}
      exportToFileHeaders={props.exportToFileHeaders}
      exportToFileFileName={props.exportToFileFileName}
    />
  );
});

StdTicketTable.propTypes = {
  showDetailsIcon: PropTypes.bool,
  checkedAll: PropTypes.bool,
  onClickCheck: PropTypes.func,
  onClickCheckAll: PropTypes.func,
  onClickRow: PropTypes.func,
  showCleanFilterIcon: PropTypes.bool,
  data: PropTypes.array,
  filteringData: PropTypes.object,
  countRecords: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  resetPageNumber: PropTypes.func,
  hiddenColumns: PropTypes.object,
  columnsOrders: PropTypes.object,
  style: PropTypes.object,
  filterPrefix: PropTypes.string,
  onClickInnerTableRow: PropTypes.func,
  showChangesCircle: PropTypes.func,
  showExportToFileButton: PropTypes.bool,
  exportToFileUrl: PropTypes.string,
  exportToFileSearchParams: PropTypes.object,
  exportToFileHeaders: PropTypes.array,
  exportToFileFileName: PropTypes.string,
};

StdTicketTable.defaultProps = {
  hiddenColumns: {},
  columnsOrders: {},
  showDetailsIcon: false,
  showCleanFilterIcon: false,
  showChangesCircle: false,
  showCheckbox: false,
};

export default StdTicketTable;
