import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import BasicDialog from "../../base/BasicDialog";
import { Button, Grid } from "@mui/material";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { MATERIALS_SHOPPING_LINES_TABLE_FIELDS_CONFIG, MATERIALS_SHOPPING_TABLE_FIELDS_CONFIG } from "./DialogTablesConfig";
import EditableTable from "../../table/EditableTable/EditableTable";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import TableService from "../../../services/tableService";
import useDialog from "../../../hooks/useDialog";
import usePurchaseService from "../../../services/purchaseService";
import { useEmptyArrayMemo } from "../../../hooks/useEmptyArrayMemo";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { centerVericalAlignIconStyle } from "../../../helpers/styles";
import { openMiniDrawerWidth } from "../../drawer/MiniDrawer/MiniDrawer";
import IsTestingAppWrapper from "../../wrapper/IsTestingAppWrapper/IsTestingAppWrapper";
import FunctionsDrawer, { FUNCTION_DRAWER_TYPE_PURCHASES } from "../../drawer/FunctionsDrawer/FunctionsDrawer";
import useUserService from "../../../services/userService";
import useDocsData from "../../../hooks/useDocsData";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import PurchaseLineStatusDialog from "../PurchaseLineStatusDialog"
import DateService from "../../../services/dateService";
import PoLineDialog from "../PoLineDialog/PoLineDialog";
import useItemCalculationService from "../../../services/itemCalculationService";
import { getFieldsFromObject } from "../../../helpers/methods";
import { NUMERIC_FIELD_TYPE, PURCHASE_SEND_ACTION } from "../../../helpers/constants";
import BoxWithTypography from "../../box/BoxWithTypography/BoxWithTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import useDialogWithId from "../../../hooks/useDialogWithId";
import FvPreviewDialog from "../FvPreviewDialog";
import useFieldValidation from "../../../hooks/useFieldValidation";
import ConfirmationDialog from "../ConfirmationDialog/confirmationDialog";
import useOfferService from "../../../services/offerService";
import AddEditDocsRestrictedDialog from "../AddEditDocsRestrictedDialog";



const PurchaseDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const requiredSendFormFields = useMemo(() => {
    return [
      "po_payer",
      "po_issuer",
      "po_mail"
    ];
  }, []);

  const { getUnfillRequiredFields } = useFieldValidation();
  const [
    openAddNewPoLineDialog,
    handleOpenNewPoLineDialog,
    handleCloseNewPoLineDialog,
  ] = useDialog();

  const [
    openFvListByPoDialog,
    handleOpenFvListByPoDialog,
    onCloseInvoicePreViewDialog,
  ] = useDialog();

  const [
    openAddNewEnclosureDialog,
    handleOpenAddNewEnclosureDialog,
    handleCloseAddNewEnclosureDialog,
  ] = useDialog();

  const [
    openChangeStatusDialog,
    poLineChangeStatusId,
    handleOpenChangeStatusDialog,
    handleCloseChangeStatusDialog,
    exportChangeStatusStatus
  ] = useDialogWithId();

  const {
    saveDocs,
    updateDocs,
    onDownloadDoc,
    onPreviewDoc,
    purchaseUploadedFile
  } = useDocsData(props.poId, "purchase");

  const {
    getPartInfoSelectListData,
  } = useOfferService();

  const { getPoDetailsData,
    getPoFilteringData,
    getPoLinesByPoListData,
    updatePoData,
    removePoDocs,
    removePoLine } = usePurchaseService();

  const [
    openConfirmationDialog,
    onOpenConfirmationDialog,
    onCloseConfirmationDialog,
  ] = useDialog();


  const { getUsersBySubcontractor } = useUserService()

  const { calculatePoLineValue, getTotalPoValueByLines, getPoLinesFieldsNameToSave } = useItemCalculationService()

  const poFilterData = useAsync(getPoFilteringData);
  const poDetailsData = useAsync(() => getPoDetailsData(props.poId));
  const [poDetailsDataLocal, setPoDetailsDataLocal] = useState([]);
  const [poFilteringDataLocal, setPoFilteringDataLocal] = useState([]);
  const [allowEdit, setAllowEdit] = useState(false)
  const [allowCreatePart, setAllowCreatePart] = useState(false)

  const [poLinesConifgLocal, setPoLinesConifgLocal] = useState([...MATERIALS_SHOPPING_LINES_TABLE_FIELDS_CONFIG])

  const usersBySubcontractorFn = useAsyncFn(getUsersBySubcontractor)
  const updatePoDataFn = useAsyncFn(updatePoData)
  const removePoDocsFn = useAsyncFn(removePoDocs)
  const removePoLineFn = useAsyncFn(removePoLine)
  const [poNumberToInvoiceDialog, setPoNumberToInvoiceDialog] = useState()
  const [dataAreUpdating, setDataAreUpdating] = useState(false)

  useEffect(() => {
    if (poDetailsData.loading) {
      return;
    }
    setPoDetailsDataLocal([poDetailsData.value]);
    setPoNumberToInvoiceDialog(poDetailsData.value.po_nr)
    setAllowEdit(props.hasEditPermission && poDetailsData.value.editable)
    setAllowCreatePart(props.hasCreatePartPermission && poDetailsData.value.editable)
    if (poDetailsData.value.po_date_send !== null && props.hasEditPermission) {
      let tempPoLinesConfig = [...MATERIALS_SHOPPING_LINES_TABLE_FIELDS_CONFIG]
      tempPoLinesConfig.push(
        {
          getValueCallback: (rowData, index, onClickChangeStatus) => {
            const content = (
              <EditIcon onClick={(e) => onClickChangeStatus(e, rowData.id)} />
            );
            return rowData.allow_change_status
              ? content
              : "";
          },
          label: t("table.materials_shopping_table.change_status"),
          filterType: null,
          allowChangeVisibility: false,
          getFilterFieldConfigCallback: (filteringData) => undefined,
        },

      )
      var slacedConfig = [
        ...tempPoLinesConfig.slice(0, 5),
        {
          name: "poli_price_real",
          getValueCallback: (rowData) => rowData.poli_price_real,
          label: t("table.materials_shopping_table.poli_price_real"),
          editable: false,
          dataType: NUMERIC_FIELD_TYPE,
        },
        ...tempPoLinesConfig.slice(5),
      ]
      setPoLinesConifgLocal([...slacedConfig])
    }

    onUpdateIssuerPersonList(poDetailsData.value.user_subcontractor_sel_list)
  }, [poDetailsData.loading, poDetailsData.value]);


  useEffect(() => {
    if (poFilterData.loading) {
      return;
    }
    setPoFilteringDataLocal(poFilterData.value);
  }, [poFilterData.loading, poFilterData.value]);



  const poLinesDetailsData = useAsync(() => getPoLinesByPoListData(props.poId));

  const [poLinesDetailsDataLocal, setPoLinesDetailsDataLocal] = useState([]);


  const partsInfoSelectList = useAsync(getPartInfoSelectListData)
  useEffect(() => {
    if (poLinesDetailsData.loading) {
      return;
    }
    setPoLinesDetailsDataLocal(poLinesDetailsData.value);
  }, [poLinesDetailsData.loading]);


  const onUpdateIssuerPersonList = (issuerPersonList) => {
    let tempFilteringData = { ...poFilteringDataLocal }
    tempFilteringData['po_person'] = issuerPersonList
    setPoFilteringDataLocal({ ...tempFilteringData })
  }

  const prepareHandleAutocompleteFieldChange = (dataLocal, setDataLocal) => (event, valueObject, valueKey, name, index) => {
    let tempDataLocal = [...dataLocal];
    let element = { ...tempDataLocal[index] };
    let issuerId = valueObject[valueKey]

    element[name] = issuerId;
    element.editDate = Date.now()
    tempDataLocal[index] = element;
    setDataLocal(tempDataLocal);
    if (name === 'po_issuer') {
      if (issuerId) {
        usersBySubcontractorFn.execute(issuerId)
          .then((res) => {
            onUpdateIssuerPersonList(res)
          })
      } else {
        onUpdateIssuerPersonList(emptyArrayMemo)
      }
      element["po_mail"] = null
    } else if (name === 'po_person') {
      element["po_mail"] = valueObject.mail
    }
  };


  useEffect(() => {
    let tempDataLocal = [...poDetailsDataLocal];
    let element = { ...tempDataLocal[0] };
    element['po_doc'] = { ...purchaseUploadedFile }
    tempDataLocal[0] = element;
    setPoDetailsDataLocal([...tempDataLocal])
    handleCloseAddNewEnclosureDialog()
  }, [purchaseUploadedFile]);


  const onDeleteLocalDoc = useCallback((poId, index) => {
    removePoDocsFn.execute(poId)
      .then((res) => {
        let tempDataLocal = [...poDetailsDataLocal];
        let element = { ...tempDataLocal[index] };
        element['po_doc'] = undefined
        tempDataLocal[index] = { ...element };
        setPoDetailsDataLocal([...tempDataLocal]);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.docs_deleted")
        );
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_docs_deleting")
        );
      });
  }, [poDetailsDataLocal])

  const handleChangeAutocompleteFieldForDetails = prepareHandleAutocompleteFieldChange(poDetailsDataLocal, setPoDetailsDataLocal);
  const handleChangeAutocompleteFieldForLines = prepareHandleAutocompleteFieldChange(poLinesDetailsDataLocal, setPoLinesDetailsDataLocal);

  const onUpdateTotalValue = useCallback((totalVale, data, setDataLocal) => {
    let tempDataLocal = [...data];
    let element = { ...tempDataLocal[0] };
    element['po_value'] = totalVale
    tempDataLocal[0] = { ...element };
    setDataLocal([...tempDataLocal]);
  }, [poDetailsDataLocal])

  const createPreparedHandleFieldChange = (dataLocal, setDataLocal) => (name, value, index) => {
    let tempDataLocal = [...dataLocal];
    let element = { ...tempDataLocal[index] };
    element[name] = value;
    element.editDate = Date.now()
    element['isEdited'] = true
    if (name === "poli_amount") {
      element = calculatePoLineValue(element)
    }
    tempDataLocal[index] = element;
    setDataLocal([...tempDataLocal]);
    onUpdateTotalValue(getTotalPoValueByLines(tempDataLocal), tempDataLocal, setDataLocal)
  };


  const getPurchaseLinesToSend = () => {
    let purchaseLinesToSend = []
    if (poLinesDetailsDataLocal) {
      for (let poLine of poLinesDetailsDataLocal) {
        if (poLine.isEdited && !poLine.isDeleted) {
          purchaseLinesToSend.push(preparePurchaseLinesDataToSave(poLine));
        }
      }
    }
    return purchaseLinesToSend
  }

  const preparePurchaseLinesDataToSave = (element) => {
    return getFieldsFromObject(element, getPoLinesFieldsNameToSave());
  };

  const handleFieldChangeForDetails = createPreparedHandleFieldChange(poDetailsDataLocal, setPoDetailsDataLocal);
  const handleFieldChangeForLines = createPreparedHandleFieldChange(poLinesDetailsDataLocal, setPoLinesDetailsDataLocal);

  const { emptyArrayMemo } = useEmptyArrayMemo()

  const onPrepareDataToSave = (action = undefined) => {
    let dataToSend = {};
    let poData = poDetailsDataLocal[0]
    poData.po_date_forecast = DateService.convertDateToFormatYYYYMMDD(poData.po_date_forecast)
    delete poData.po_doc
    dataToSend.po_details = poData;
    dataToSend.po_lines = getPurchaseLinesToSend()
    dataToSend.action = action;
    return dataToSend;
  };

  const onUpdatePoLinesLocal = useCallback((newPoLine) => {
    setPoLinesDetailsDataLocal((prev) => ([...prev, newPoLine]))
  }, [poLinesDetailsDataLocal])

  const onSendPurchase = useCallback(() => {
    onCloseConfirmationDialog()
    setDataAreUpdating(true)
    updatePoDataFn.execute(props.poId, onPrepareDataToSave(PURCHASE_SEND_ACTION))
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.po_sended")
        );
        poLinesDetailsData.refetch()
        poDetailsData.refetch()
        setDataAreUpdating(false)
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_po_sending")
        );
        setDataAreUpdating(false)

      });
  }, [poDetailsDataLocal, poLinesDetailsDataLocal])

  const onUpdateData = useCallback(() => {
    setDataAreUpdating(true)
    updatePoDataFn.execute(props.poId, onPrepareDataToSave())
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.po_updated")
        );
        poDetailsData.refetch()
        poLinesDetailsData.refetch()
        setDataAreUpdating(false)
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_po_updating")
        );
        setDataAreUpdating(false)

      });
  }, [poDetailsDataLocal, poLinesDetailsDataLocal])

  const isLoading = poLinesDetailsData.loading || poDetailsData.loading || dataAreUpdating


  const handleRemoveLine = useCallback((rowIndex) => {
    let tempDataLocal = poLinesDetailsDataLocal
    let elementToRemove = tempDataLocal[rowIndex]
    removePoLineFn.execute(elementToRemove.id, onPrepareDataToSave())
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.po_line_removed")
        );
        elementToRemove.isDeleted = true
        tempDataLocal[rowIndex] = { ...elementToRemove }
        setPoLinesDetailsDataLocal([...tempDataLocal])
        setDataAreUpdating(false)
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_po_line_removing")
        );
        setDataAreUpdating(false)

      });
  }, [poLinesDetailsDataLocal])

  const poLinesTableContextMenuActions = useMemo(
    () => [

      {
        label: t("dialog.purchase_dialog.remove_po_line"),
        callback: handleRemoveLine,
        icon: <DeleteIcon fontSize="small" />,
        visible: true,
      },
    ],
    [handleRemoveLine]
  );

  const onClickChangeStatus = useCallback((e, poLineId) => {
    handleOpenChangeStatusDialog(poLineId)
  }, [])





  const preparedData = React.useMemo(
    () =>
      poDetailsDataLocal
        ? TableService.getFieldsConfigForEditableTable(
          MATERIALS_SHOPPING_TABLE_FIELDS_CONFIG,
          poDetailsDataLocal,
          poFilteringDataLocal,
          handleOpenAddNewEnclosureDialog,
          updateDocs,
          onPreviewDoc,
          onDownloadDoc,
          onDeleteLocalDoc,
          allowEdit,
          poDetailsData.refetch
        )
        : [],
    [poDetailsDataLocal, poFilteringDataLocal]
  );

  const preparedLinesData = React.useMemo(
    () =>
      poLinesDetailsDataLocal
        ? TableService.getFieldsConfigForEditableTable(
          poLinesConifgLocal,
          poLinesDetailsDataLocal,
          emptyArrayMemo,
          onClickChangeStatus
        )
        : [],
    [poLinesConifgLocal, poLinesDetailsDataLocal]
  )

  const getNotDeletedPoLines = (poLines) => {
    return poLines.filter(line => line.isDeleted !== true)
  }

  const isSendButtonDisabled = useMemo(() => {
    if (poDetailsDataLocal.length > 0) {
      return getUnfillRequiredFields(requiredSendFormFields, poDetailsDataLocal[0]).length > 0 || getNotDeletedPoLines(poLinesDetailsDataLocal).length === 0;
    }
    return false

  }, [requiredSendFormFields, poDetailsDataLocal, poLinesDetailsDataLocal, getUnfillRequiredFields]);

  const onCloseDialog = useCallback(() => {
    props.onClose()
    props.onRefetchData()
    setPoLinesConifgLocal([])
  }, [])

  const onFullRefetch = () => {
    poDetailsData.refetch()
    poLinesDetailsData.refetch()
  }

  const onCloseAddDocsDialog = useCallback(() => {
    poDetailsData.refetch()
    handleCloseAddNewEnclosureDialog()
  }, [])


  return (
    <BasicDialog
      open={props.open}

      titleAlign="center"
      contentAlign="center"
      title={props.poId ? `${t(`dialog.purchase_dialog.purchase_nr`)} ${poDetailsData?.value?.po_nr}` : t(`dialog.purchase_dialog.new_purchase_order`)}
      allwaysFullScreen={true}
      showCustomFooter={false}
      showDialogActions={false}
      titleSx={{ height: `${64}px`, marginLeft: `${openMiniDrawerWidth}px` }}
      showTopCloseButton={false}
    >
      <IsTestingAppWrapper />
      <FunctionsDrawer
        drawerType={FUNCTION_DRAWER_TYPE_PURCHASES}
        readOnly={!allowEdit}
        onClose={onCloseDialog}
        onSaveChanges={onUpdateData}
        onSendPurchase={onOpenConfirmationDialog}
        isSavingData={isLoading}
        showInvoives={handleOpenFvListByPoDialog}
        poId={props.poId}
        isSendButtonDisabled={isSendButtonDisabled}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          columnSpacing={2}
          rowSpacing={2}
          style={props.style}
        >
          {
            isLoading ?
              <LoaderWrapper showLoader={true} />
              :
              <>
                <Grid item xs={12}>

                  <EditableTable
                    data={preparedData}
                    readOnly={!allowEdit}
                    editable={allowEdit}
                    showCheckbox={false}
                    showCleanFilterIcon={true}
                    onChangeAutocomplete={handleChangeAutocompleteFieldForDetails}
                    onDataChange={handleFieldChangeForDetails}
                    filteringData={poFilteringDataLocal || emptyArrayMemo}
                    style={{ maxHeight: "vh" }}
                    tableConfig={MATERIALS_SHOPPING_TABLE_FIELDS_CONFIG}
                    showContextMenu={false}
                  />

                </Grid>
                {allowEdit &&
                  <Grid item xs={12} sm={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      size="small"
                      startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
                      onClick={handleOpenNewPoLineDialog}
                    >
                      {t("dialog.purchase_dialog.add_row")}
                    </Button>
                  </Grid>}


                <Grid item xs={12}>
                  <BoxWithTypography
                    style={{ padding: "10px" }}
                    label={t(`dialog.purchase_dialog.order_lines`)}
                    component={"div"}
                  >
                    <EditableTable
                      data={preparedLinesData}
                      readOnly={!allowEdit}
                      editable={allowEdit}
                      showCheckbox={false}
                      showCleanFilterIcon={true}
                      onChangeAutocomplete={handleChangeAutocompleteFieldForLines}
                      onDataChange={handleFieldChangeForLines}
                      filteringData={emptyArrayMemo}
                      style={{ maxHeight: "vh" }}
                      tableConfig={poLinesConifgLocal}
                      showContextMenu={allowEdit}
                      contextMenuActions={poLinesTableContextMenuActions}
                    />
                  </BoxWithTypography>
                </Grid>
              </>
          }
        </Grid>
        {openAddNewEnclosureDialog &&
          <AddEditDocsRestrictedDialog
            open={openAddNewEnclosureDialog}
            onClose={handleCloseAddNewEnclosureDialog}
            docsId={undefined}
            submitButtonLabel={t("dialog.enclosures_dialog.add_docs")}
            po={props.poId}
            onSubmit={onCloseAddDocsDialog}
            multiple={false}
            availableDocsRestrictedTypes={[]}
          />
        }

        {openAddNewPoLineDialog &&
          <PoLineDialog
            open={openAddNewPoLineDialog}
            onClose={handleCloseNewPoLineDialog}
            poId={props.poId}
            onUpdatePoLinesLocal={onUpdatePoLinesLocal}
          />}

        {openChangeStatusDialog &&
          <PurchaseLineStatusDialog
            open={openChangeStatusDialog}
            onClose={handleCloseChangeStatusDialog}
            onRefetch={onFullRefetch}
            poLineId={poLineChangeStatusId}
          />
        }
        {openFvListByPoDialog &&
          <FvPreviewDialog
            open={openFvListByPoDialog}
            onClose={onCloseInvoicePreViewDialog}
            poId={props.poId}
            poNumber={poNumberToInvoiceDialog}
          />
        }
        {openConfirmationDialog && (
          <ConfirmationDialog
            open={openConfirmationDialog}
            onNo={onCloseConfirmationDialog}
            onYes={onSendPurchase}
            title={t("dialog.purchase_confirmation_dialog.confirm_action")}
            content={t("dialog.purchase_confirmation_dialog.are_you_sure_to_send_purchase")}
          />
        )}

      </FunctionsDrawer>
    </BasicDialog>
  );
};

PurchaseDialog.propTypes = {
  poId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool
};

PurchaseDialog.defaultProps = {
  open: false,
};

export default PurchaseDialog;
