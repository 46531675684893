import axios from "axios";
import { TicketAppApiUrl } from "../helpers/apiUrls";

export default axios.create({
  withCredentials: true,
  baseURL: TicketAppApiUrl,
});

export const axiosPrivate = axios.create({
  withCredentials: true,
  baseURL: TicketAppApiUrl,
});
