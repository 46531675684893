import { useState, useEffect, useMemo, useRef } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import LoaderWrapper from "../../../../wrapper/LoaderWrapper";

import { useAsync } from "../../../../../hooks/useAsync";

import usePaginationWithSearchParams from "../../../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../../../hooks/useFilterSearchParams";

import useUserService from "../../../../../services/userService";

import TableService from "../../../../../services/tableService";
import TenantTable from "../../../../table/TenantTable/TenantTable";
import { exportTenantShortDetailsToFileUrl } from "../../../../../helpers/apiUrls";

import {
  TENANT_TABLE_FILTER_PREFIX,
  TENANT_TABLE_NAME,
  TENANT_TABLE_CONFIG,
} from "./TableConfig";

import {
  isSearchParamsObjectEmpty,
  getFilterFieldKeyByNameAndPrefix,
} from "../../../../../helpers/methods";

const RRAdminTenantTable = (props) => {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(TENANT_TABLE_FILTER_PREFIX, undefined, 100);

  const { setNewItemSearchParamsIfAreChanged, getSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);
  const [tenantsSearchParams, setTenantsSearchParams] = useState(
    getSearchParamsByFilterPrefix(TENANT_TABLE_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      TENANT_TABLE_FILTER_PREFIX,
      tenantsSearchParams,
      setTenantsSearchParams
    );
  }, [searchParams]);

  const { getTenantShortDetailsDataList, getTenantList } = useUserService();

  const tenantData = useAsync(
    () =>
      getTenantShortDetailsDataList({
        ...tenantsSearchParams,
        page_size: tenantsSearchParams.page_size || 100,
        tenant_rr_visibility: true,
      }),
    [tenantsSearchParams]
  );

  const tenantFilteringData = useAsync(() =>
    getTenantList({ tenant_rr_visibility: true })
  );

  const [tenantDataLocaly, setTenantDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (tenantData.loading) {
      return;
    }
    setTenantDataLocaly(tenantData.value.results);
    setCountRecords(tenantData.value.count);
  }, [tenantData.loading]);

  const tenantExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(TENANT_TABLE_CONFIG, []);
  }, [TENANT_TABLE_CONFIG]);

  const isLoading =
    tenantDataLocaly === undefined || tenantFilteringData.loading;

  const handleClickTableRow = (tenantId) => {
    setSearchParams((prev) => {
      let tempSearchParams = { ...prev };
      for (let searchPrefix of props.searchPrefixes) {
        tempSearchParams[
          getFilterFieldKeyByNameAndPrefix("tenant", searchPrefix)
        ] = tenantId;
      }
      return tempSearchParams;
    });
  };

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TenantTable
          showCleanFilterIcon={true}
          style={{ maxHeight: "60vh" }}
          showFilters={true}
          data={tenantDataLocaly}
          filterPrefix={TENANT_TABLE_FILTER_PREFIX}
          filteringData={tenantFilteringData}
          countRecords={countRecords}
          page={page}
          onPageChange={handleChangePageWithSearchParams}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          tableConfig={TENANT_TABLE_CONFIG}
          hiddenColumns={[]}
          showExportToFileButton={true}
          exportToFileUrl={exportTenantShortDetailsToFileUrl}
          exportToFileSearchParams={{
            ...tenantsSearchParams,
            tenant_rr_visibility: true,
          }}
          exportToFileHeaders={tenantExportHeaders}
          exportToFileFileName={`${t("tabs.rr_admin.tenants")}.xlsx`}
          initialExpandedFilterHeader={true}
          onClickRow={handleClickTableRow}
        />
      </Grid>
    </Grid>
  );
};

RRAdminTenantTable.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
  searchPrefixes: PropTypes.array,
};

RRAdminTenantTable.defaultProps = { searchPrefixes: [] };

export default RRAdminTenantTable;
