import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const OfferValidationForm = (props) => {

  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={1}>
        
      {(props.offerCost === undefined || props.offerCost <= 0) &&
        <Grid item xs={12}>
          <Typography
            variant="h6"
            className="capitalize-first-letter"
          >
            {t(`form.offer_validation_form.offer_value_is_zero`)}
          </Typography>
        </Grid>
      }
      {props.offerValidatedFields.length > 0 &&
        <>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              className="capitalize-first-letter"
              style={{width: "max-content"}}
            >
              {t(`form.offer_validation_form.empty_fields_needs_to_be_completed_before_offer_generate`)}
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
};

OfferValidationForm.propTypes = {
  offerCost: PropTypes.number,
  offerValidatedFields: PropTypes.array
};

OfferValidationForm.defaultProps = {
  offerValidatedFields: []
};

export default OfferValidationForm;
