import React, { useState } from "react";

import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import TextFieldFormControl from "../../../field/TextFieldFormControl";

import i18n from "../../../../i18n";

import FieldWithLabel from "../../../field/FieldWithLabel";

const FeedbackForm = (props) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.feedback.rate_name")}
          field={
            <TextFieldFormControl
              name={"rate_name"}
              value={props.rateName}
              disabled={true}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"feedback_note"}
          label={i18n.t("form.feedback.feedback_note")}
          value={props.feedbackNote}
          multiline
          rows={4}
          disabled={true}
        />
      </Grid>
    </Grid>
  );
};

FeedbackForm.propTypes = {
  rateName: PropTypes.string,
  feedbackNote: PropTypes.string,
};

FeedbackForm.defaultProps = {};

export default FeedbackForm;
