import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BaseTable from "../../base/BaseTable/BaseTable";
import AddButton from "../../button/AddButton";
import React, { useCallback } from "react";
import TableService from "../../../services/tableService";
import ValidationForm from "../ValidationForm";
import { TRANSFERS_TABLE_FIELDS_CONFIG } from "./TableConfig";


const IcoTransfersForm = React.memo(({ readOnly, transfers, onHandleAddEmptyRow, showAddButton, isTableValid, isTransferSumValid }) => {
  console.log('ico transfer form')
  const getAddRowButton = useCallback(() => {
    if (!readOnly && showAddButton) {
      return (<AddButton onClick={onHandleAddEmptyRow} />)
    }
  }, [readOnly, showAddButton])


  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12}>
        <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
          {getAddRowButton()}
          <BaseTable
            showContextMenu={false}
            showNoRecordsPaper={false}
            headers={TableService.getHeaderLables(
              TRANSFERS_TABLE_FIELDS_CONFIG
            )}
            rows={TableService.getPreparedDataForBaseTable(
              TRANSFERS_TABLE_FIELDS_CONFIG,
              transfers,
              [],
              []
            )}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <ValidationForm
          isRequiredeFieldsValid={isTableValid}
          totalTransferAreHigherThanConditionValue={!isTransferSumValid}
        />
      </Grid>
    </Grid>
  );
});

IcoTransfersForm.propTypes = {
  readOnly: PropTypes.bool,
  transfers: PropTypes.array,
  isTransferSumValid: PropTypes.bool,
  showAddButton: PropTypes.bool
};

IcoTransfersForm.defaultProps = {
  readOnly: false,
  transfers: [],
  isTransferSumValid: true,
  showAddButton: true
};

export default IcoTransfersForm;