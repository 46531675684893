import BasicDialog from "../../base/BasicDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React from "react";
import ValidationForm from "../../form/ValidationForm";

function OfferSaveErrorDialog(props) {
  const { t } = useTranslation();

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      title={t("dialog.offer_save_error_dialog.offer_containts_errors")}
      maxWidth="sm"
      contentSx = {{overflow : "hidden", padding : "5px"}}
    >
      <ValidationForm
        isRequiredeFieldsValid={props.isTableValid}
        sumSettlementRatesIsCorrectly={props.isSumValid}
      />
    </BasicDialog>
  );
}

OfferSaveErrorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isRequiredeFieldsValid: PropTypes.bool,
  sumSettlementRatesIsCorrectly: PropTypes.bool
};

OfferSaveErrorDialog.defaultProps = {
  open: false,
  isRequiredeFieldsValid: true,
  sumSettlementRatesIsCorrectly: true
};

export default OfferSaveErrorDialog;
