import i18n from "../../../i18n";


export const PO_TABLE_FIELDS_CONFIG = [
  {
    name: "po_nr",
    getValueCallback: (rowData) => rowData.po_nr,
    label: i18n.t("table.selected_po_table.po_nr"),
  },
  {
    name: "po_payer",
    getValueCallback: (rowData) => rowData.po_payer,
    label: i18n.t("table.selected_po_table.po_payer"),
  },
  {
    name: "po_creator",
    getValueCallback: (rowData) => rowData.po_creator,
    label: i18n.t("table.selected_po_table.po_creator"),
  },
  {
    name: "po_issuer",
    getValueCallback: (rowData) => rowData.po_issuer,
    label: i18n.t("table.selected_po_table.po_issuer"),
  },
  {
    name: "po_value",
    getValueCallback: (rowData) => rowData.po_value,
    label: i18n.t("table.selected_po_table.po_value"),
  },
];