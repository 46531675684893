import PropTypes from "prop-types";

import useDialog from "../../../hooks/useDialog";
import FilterDialog from "../../base/FilterDialog";
import UserFilterDialog from "../../dialog/UserFilterDialog";
import TableColumnVisibilityDialog from "../../dialog/TableColumnVisibilityDialog";

import useUserTablePreferences from "../../../hooks/useUserTablePreferences";
import UniversalFilterForm from "../../form/UniversalFilterForm";

import UniversalToolBar from "../UniversalToolBar/UniversalToolBar";

export default function UniversalToolBarWithDialogs(props) {
  const {
    pageName,
    tableConfig,
    filteringData,
    refreshTable,
    onCleanFlitersInFilterDialog,
    resetPageNumber,
    filterPrefix,
    hiddenColumnsForTables,
    setHiddenColumnsForTables,
    columnsOrdersForTables,
    setColumnsOrdersForTables,
    ...universalToolBarProps
  } = props;

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const [
    openUserFiltersDialog,
    onOpenUserFiltersDialog,
    onCloseUserFiltersDialog,
  ] = useDialog();

  const {
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
    handleUpdateUserPreferences,
    loadingUserPreferences,
    tablesConfigs,
  } = useUserTablePreferences({
    pageName,
    refreshTable,
    tablesConfigs: Array.isArray(tableConfig) ? tableConfig : [tableConfig],
    setHiddenColumnsForTables,
    columnsOrdersForTables,
    setColumnsOrdersForTables,
  });

  return (
    <>
      <UniversalToolBar
        {...universalToolBarProps}
        filterPrefix={filterPrefix}
        onClickAdjustTable={
          universalToolBarProps.onClickAdjustTable ||
          onOpenTableColumnVisibilityDialog
        }
        onClickMyFilters={
          universalToolBarProps.onClickMyFilters || onOpenUserFiltersDialog
        }
        onClickSearch={
          universalToolBarProps.onClickSearch || onOpenFilterDialog
        }
      />
      {openFilterDialog && (
        <FilterDialog
          open={openFilterDialog}
          onCleanFilters={onCleanFlitersInFilterDialog}
          onClose={onCloseFilterDialog}
          resetPageNumber={resetPageNumber}
          filterPrefix={filterPrefix}
          filterForm={
            <UniversalFilterForm
              filteringData={filteringData}
              filtersConfig={
                Array.isArray(tableConfig)
                  ? tableConfig[0].config
                  : tableConfig.config
              }
              includeOpenCloseFilter={props.showOpenCloseFilterButton}
              {...props.extraFilterOptions}
            />
          }
        />
      )}
      {openUserFiltersDialog && (
        <UserFilterDialog
          open={openUserFiltersDialog}
          onClose={onCloseUserFiltersDialog}
          pageName={props.pageName}
          filterPrefix={filterPrefix}
          filterForm={
            <UniversalFilterForm
              filteringData={filteringData}
              filtersConfig={
                Array.isArray(tableConfig)
                  ? tableConfig[0].config
                  : tableConfig.config
              }
              includeOpenCloseFilter={props.showOpenCloseFilterButton}
              {...props.extraFilterOptions}
            />
          }
        />
      )}
      {openTableColumnVisibilityDialog && (
        <TableColumnVisibilityDialog
          open={openTableColumnVisibilityDialog}
          onClose={onCloseTableColumnVisibilityDialog}
          onSubmit={handleUpdateUserPreferences}
          tablesConfigs={tablesConfigs}
          tablesHiddenColumns={hiddenColumnsForTables}
          columnsOrders={columnsOrdersForTables}
          isLoading={loadingUserPreferences}
        />
      )}
    </>
  );
}

UniversalToolBarWithDialogs.propTypes = {
  style: PropTypes.object,
  onClickAdjustTable: PropTypes.func,
  onClickMyFilters: PropTypes.func,
  onClickCreateItem: PropTypes.func,
  massActionButtonOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      callback: PropTypes.func,
    })
  ),
  onClickOpenCloseFilter: PropTypes.func,
  onClickSearch: PropTypes.func,
  itemType: PropTypes.oneOf(["ticket", "order", "task", undefined]),
  showMassActionButton: PropTypes.bool,
  showCreateButton: PropTypes.bool,
  showOpenCloseFilterButton: PropTypes.bool,
  showCleanfiltersButton: PropTypes.bool,
  showMyFiltersButton: PropTypes.bool,
  showSearchButton: PropTypes.bool,
  mdGridButton: PropTypes.number,
  mdGridExtraButton: PropTypes.number,
  extraButtonList: PropTypes.array,
  filterPrefix: PropTypes.string,
  isFilterSet: PropTypes.bool,
  pageName: PropTypes.string,
  tableConfig: PropTypes.object,
  refreshTable: PropTypes.func,
  onCleanFlitersInFilterDialog: PropTypes.func,
  filteringData: PropTypes.object,
  extraFilterOptions: PropTypes.object,
};

UniversalToolBarWithDialogs.defaultProps = {
  showMassActionButton: false,
  showCreateButton: false,
  showCleanfiltersButton: true,
  showOpenCloseFilterButton: true,
  mdGridButton: 1.75,
  extraButtonList: [],
};
