import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import EditIcon from '@mui/icons-material/Edit';
import DateService from "../../services/dateService";
import SelectFieldService from "../../services/selectFieldService";

export const STOCK_BUFFER_TABLE_FIELDS_CONFIG = [
  {
    name: "order",
    getValueCallback: (rowData, onClickPurchase, onEditBuffer) => {
      const content = (
        <AddShoppingCartIcon  onClick={(e) => onClickPurchase(e, rowData.part)} />
      );
      return rowData?.has_permission_to_create_purchase ? content : undefined
    },
    label: i18n.t("table.stock_buffer_table.order"),
    filterType: null,
    allowChangeVisibility: false,
    excludedFromExport : true,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickPurchase, onEditBuffer) => {
      const content =(
        <EditIcon onClick={(e) => onEditBuffer(e, rowData.id)} />
      );
      return rowData?.has_permission_to_edit_buffers? content : undefined
    },
    label: i18n.t("table.stock_buffer_table.edit"),
    filterType: null,
    allowChangeVisibility: false,
    excludedFromExport : true,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "part_name",
    getValueCallback: (rowData) => rowData.part_name,
    label: i18n.t("table.stock_buffer_table.part_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "warehouse",
    getValueCallback: (rowData) => rowData.warehouse,
    label: i18n.t("table.stock_buffer_table.warehouse_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.warehouses,
      isObjectOption: true,
      optionLabelKey: "warehouse_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "buffer_amount",
    getValueCallback: (rowData) => rowData.buffer_amount,
    label: i18n.t("table.stock_buffer_table.buffer_amount"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "stock_amount",
    getValueCallback: (rowData) => rowData.stock_amount,
    label: i18n.t("table.stock_buffer_table.stock_amount"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "stock_availability",
    getValueCallback: (rowData) => rowData.stock_availability,
    label: i18n.t("table.stock_buffer_table.stock_availability"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "part_unit",
    getValueCallback: (rowData) => i18n.t(`other.units.${rowData.part_unit}`),
    label: i18n.t("table.stock_buffer_table.part_unit"),
  },
  {
    name: "exceed",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.exceed),
    label: i18n.t("table.stock_buffer_table.exceed"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.stock_buffer_table.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "user",
    getValueCallback: (rowData) => rowData.user,
    label: i18n.t("table.stock_buffer_table.operator"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.responsiblers,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },

];
