import { AUTOCOMPLETE_FIELD_TYPE, NUMERIC_FIELD_TYPE, TEXT_FIELD_TYPE } from "../../../helpers/constants";
import i18n from "../../../i18n";



export const SETTLEMENT_TABLE_FIELDS_CONFIG = [
  {
    name: "payer_id",
    getValueCallback: (rowData) => rowData.payer_id,
    label: i18n.t("table.settlements_table.payer_full_name"),
    editable: true,
    required: true,
    dataType: AUTOCOMPLETE_FIELD_TYPE,
    style: { minWidth: "250px" },
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData,
      isObjectOption: true,
      optionLabelKey: "tenant_full_name",
      addNewValue: false,
      showExternalButton: false,
    }),
  },
  {
    name: "address",
    getValueCallback: (rowData) => rowData.address,
    label: i18n.t("table.settlements_table.payer_address"),
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "tenant_nip",
    getValueCallback: (rowData) => rowData.tenant_nip,
    label: i18n.t("table.settlements_table.nip"),
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "settlement_po",
    getValueCallback: (rowData) => rowData.settlement_po,
    label: i18n.t("table.settlements_table.po"),
    dataType: TEXT_FIELD_TYPE,
    editable: true,
  },
  {
    name: "settlement_payment_rate",
    getValueCallback: (rowData) => rowData.settlement_payment_rate,
    label: i18n.t("table.settlements_table.payment_rate"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true,
    required: true,
  },
  {
    name: "settlement_netto",
    getValueCallback: (rowData) => rowData.settlement_netto,
    label: i18n.t("table.settlements_table.quotation_net"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true,
    required: true,
  },
  {
    name: "settlement_vat",
    getValueCallback: (rowData) => rowData.settlement_vat,
    label: i18n.t("table.settlements_table.quotation_vat"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "settlement_brutto",
    getValueCallback: (rowData) => rowData.settlement_brutto,
    label: i18n.t("table.settlements_table.quotation_brutto"),
    dataType: NUMERIC_FIELD_TYPE,
  },
];


export const SETTLEMENT_READONLY_TABLE_FIELDS_CONFIG = [
  {
    name: "tenant_full_name",
    getValueCallback: (rowData) => rowData.tenant_full_name,
    label: i18n.t("table.settlements_table.payer_full_name"),
    dataType: TEXT_FIELD_TYPE,
    style: { minWidth: "250px" },

  },
  {
    name: "address",
    getValueCallback: (rowData) => rowData.address,
    label: i18n.t("table.settlements_table.payer_address"),
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "tenant_nip",
    getValueCallback: (rowData) => rowData.tenant_nip,
    label: i18n.t("table.settlements_table.nip"),
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "settlement_po",
    getValueCallback: (rowData) => rowData.settlement_po,
    label: i18n.t("table.settlements_table.po"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true,
  },
  {
    name: "settlement_payment_rate",
    getValueCallback: (rowData) => rowData.settlement_payment_rate,
    label: i18n.t("table.settlements_table.payment_rate"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "settlement_netto",
    getValueCallback: (rowData) => rowData.settlement_netto,
    label: i18n.t("table.settlements_table.quotation_net"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "settlement_vat",
    getValueCallback: (rowData) => rowData.settlement_vat,
    label: i18n.t("table.settlements_table.quotation_vat"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "settlement_brutto",
    getValueCallback: (rowData) => rowData.settlement_brutto,
    label: i18n.t("table.settlements_table.quotation_brutto"),
    dataType: NUMERIC_FIELD_TYPE,
  },
];
