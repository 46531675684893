import Grid from "@mui/material/Grid";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TitleTypography from "../../base/TitleTypography";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useAuth } from "../../../context/auth";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useTranslation } from "react-i18next";
import useLogout from "../../../hooks/useLogout";

export default function MenuBar({ titleKey = "menu" }) {
  const { user } = useAuth();
  const { handleLogout } = useLogout();
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid
        item
        md={3}
        xs={12}
        textAlign={{ xs: "center", md: "left" }}
        marginBottom={{ xs: 2, md: 0 }}
      >
        {/* TODO Place to put image */}
        {/* <img className="" src={pkbx_logo} alt="" height={43} /> */}
      </Grid>
      <Grid item md={6} xs={12} marginBottom={{ xs: 2, md: 0 }}>
        <TitleTypography titleKey={titleKey} color={"primary"} />
      </Grid>
      <Grid
        item
        md={3}
        xs={12}
        display="inline-flex"
        gap={"5px"}
        justifyContent={{ xs: "center", md: "end" }}
        alignItems={"center"}
      >
        <AccountCircleIcon style={{ color: "var(--secondary)" }} />
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          style={{ margin: 0, color: "var(--secondary)" }}
        >
          {user.full_name}
        </Typography>
        <Tooltip title={t("log_out")}>
          <IconButton
            color="var(--secondary)"
            onClick={() => handleLogout()}
            sx={{ padding: "5px" }}
          >
            <PowerSettingsNewIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
