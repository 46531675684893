import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../context/snackbarAlert";
import {
  taskUrl,
  taskMassCreateOrUpdateUrl,
  taskPlannerUrl,
  taskWaitingFilteringDataUrl,
  taskWaitingRoomUrl,
  taskFilteringDataForCreateUrl,
  myTaskDataUrl,
  taskFilteringDataUrl,
  taskDetailsDataUrl,
  taskListUrl,
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { makeRequestWithLanguage } from "../helpers/makeRequestWithLanguage";
import { prepareUrlWithQueryParams } from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useFileService from "./fileService";

export default function useTaskService() {
  const axiosPrivate = useAxiosPrivate();
  const { uploadAttachments } = useFileService();
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const getTasksForPlanner = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(taskPlannerUrl, searchParams)
    );
  };

  const massCreateOrUpdateTasks = (dataToSend) => {
    return makeRequest(axiosPrivate, taskMassCreateOrUpdateUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const getTaskWaitingRoom = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(taskWaitingRoomUrl, searchParams)
    );
  };

  const getTaskWaitingRoomFilteringData = () => {
    return makeRequest(axiosPrivate, taskWaitingFilteringDataUrl);
  };

  const getTaskFilteringDataForCreate = () => {
    return makeRequest(axiosPrivate, taskFilteringDataForCreateUrl);
  };

  const createTask = async (dataToSend) => {
    const enclosures = dataToSend.enclosures;
    delete dataToSend.enclosures;

    let results = await uploadAttachments(enclosures, {});
    let uploadedData = results["uploadedData"];
    let showWaring = results["showWaring"];

    dataToSend.enclosures = uploadedData;
    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, taskUrl, {
        method: "POST",
        data: dataToSend,
      })
        .then((result) => {
          resolve(result);
          if (showWaring) {
            snackbarAlert.openWarningSnackbarAlert(
              t("snackbar_alert.occurred_warning_during_upload_file")
            );
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getTaskFilteringData = () => {
    return makeRequestWithLanguage(axiosPrivate, taskFilteringDataUrl);
  };

  const getMyTasksData = (searchParams, isDialogWithParamsOpen = true) => {
    if (isDialogWithParamsOpen) {
      return makeRequestWithLanguage(
        axiosPrivate,
        prepareUrlWithQueryParams(myTaskDataUrl, searchParams)
      );
    }
    return Promise.resolve([]);
  };

  const getTaskData = (searchParams, isDialogWithParamsOpen = true) => {
    if (isDialogWithParamsOpen) {
      return makeRequestWithLanguage(
        axiosPrivate,
        prepareUrlWithQueryParams(taskListUrl, searchParams)
      );
    }

    return Promise.resolve([]);
  };

  const getTaskDataByPageName = (
    pageName,
    searchParams,
    isDialogWithParamsOpen = true
  ) => {
    if (pageName === "my_tasks")
      return getMyTasksData(searchParams, isDialogWithParamsOpen);
    return getTaskData(searchParams, isDialogWithParamsOpen);
  };

  const getTaskDetailsData = (taskId) => {
    return makeRequest(axiosPrivate, taskDetailsDataUrl(taskId));
  };

  const updateTaskData = (taskId, dataToSend) => {
    return makeRequest(axiosPrivate, taskDetailsDataUrl(taskId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  return {
    getTasksForPlanner,
    massCreateOrUpdateTasks,
    getTaskWaitingRoomFilteringData,
    getTaskWaitingRoom,
    getTaskFilteringDataForCreate,
    createTask,
    getTaskFilteringData,
    getMyTasksData,
    getTaskDataByPageName,
    getTaskDetailsData,
    getTaskData,
    updateTaskData,
  };
}
