import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import EditableTable from "../../table/EditableTable/EditableTable";
import TableService from "../../../services/tableService";
import AddButton from "../../button/AddButton";
import ValidationForm from "../ValidationForm";
import MessageInputForm from "../MessageInputForm"
import { Grid, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";


const OfferSettlementsForm = (props) => {

  const { t } = useTranslation();

  const data = React.useMemo(
    () =>
      TableService.getFieldsConfigForEditableTable(
        props.tableConfig,
        props.data ? props.data : [],
        props.filteringData? props.filteringData : [],
      ),
    [props.data, props.tableConfig, props.filteringData,props.readOnly]
  );

  const getAddRowButton = useCallback(() => {
    if (!props.readOnly && props.showAddButton) {
      return (<AddButton onClick={props.onHandleAddEmptyRow} />)
    }
  }, [props.readOnly, props.onHandleAddEmptyRow, props.dialogMode])

  return (
    <Grid
      container
      spacing={1}>
      <Grid item xs={12}>
        <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
          {getAddRowButton()}
          <EditableTable
            showCheckbox={false}
            showTitle={false}
            showPagination={false}
            data={data}
            readOnly={props.readOnly}
            tableConfig={props.tableConfig}
            onChangeAutocomplete={props.onChangeAutocomplete}
            showContextMenu={props.showContextMenu}
            contextMenuActions={props.contextMenuActions}
            onDataChange={props.onDataChange}
            countDeletedRows={true}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <ValidationForm
          isRequiredeFieldsValid={props.isTableValid}
          sumSettlementRatesIsCorrectly={props.isSumValid}
        />
      </Grid>


      {props.showConfirmationForm &&
        <>
          <Grid item xs={12}>
            <MessageInputForm
              showAttachmentsButton={false}
              label={t("form.offer_settlement_form.send_request_for_change_settlements")}
              onSubmit = {props.onSendMessage}
            />
          </Grid>
          <Grid item xs={12}>

            <LoadingButton
              fullWidth
              loading={props.loading}
              variant="contained"
              color="alternate"
              disabled = {!props.isPoNumberValid}
              onClick={props.onConfirmAcceptOfferByOm}>
  
              {props.isOfferCR ?  t("form.offer_settlement_form.accept_cr") :t("form.offer_settlement_form.accept_offer")}
            </LoadingButton>
          </Grid>
        </>
      }
    </Grid>

  );
};

OfferSettlementsForm.propTypes = {
  onSubmit: PropTypes.func,
  ticketId: PropTypes.string,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChangeAutocomplete: PropTypes.func,
  showContextMenu: PropTypes.bool,
  contextMenuActions: PropTypes.func,
  onDataChange: PropTypes.func,
  isRequiredeFieldsValid: PropTypes.bool,
  sumSettlementRatesIsCorrectly: PropTypes.bool,
  filteringData: PropTypes.array,
  showAddButton: PropTypes.bool,
  filteringData: PropTypes.array,
  onConfirmAcceptOfferByOm : PropTypes.func,
  onSendMessage : PropTypes.func,
  loading : PropTypes.bool,
  isOfferCR : PropTypes.bool,
  isPoNumberValid : PropTypes.bool
};

OfferSettlementsForm.defaultProps = {
  readOnly: false,
  subcontractorSelectList: [],
  showContextMenu: false,
  contextMenuActions: [],
  filteringData: [],
  isRequiredeFieldsValid: true,
  sumSettlementRatesIsCorrectly: true,
  showAddButton: true,
  filteringData: [],
  loading : false,
  isOfferCR : false,
  isPoNumberValid : true
};

export default OfferSettlementsForm;
