import React, { useEffect, useState } from "react";
import { ERROR_TEXT_STYLE } from "../../../helpers/constants";
import "./style.css";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";
import useField from "../../../hooks/useField";
import { isEmptyValue } from "../../../helpers/methods";

// const divStyle = {
//     position: 'relative',

//     display: 'inline-flex',
//     alignItems: 'center',
//     textAlign: 'center',
//     justifyContent: 'center',
//     height: '1.4375em',
//     // borderRadius: '4px',
//     padding: '8.5px 14px',
//     whiteSpace: "nowrap",
//   };

const helperTextStyle = {
  marginTop: "2px",
  color: "red",
  fontSize: "12px",
  fontWeight: "bold",
  textAlign: "left",
};

const containerStyle = {
  // display: 'grid',
  alignItems: "flex-start",
};

// const labelStyle = {
//   fontSize: '14px',
//   marginBottom: '4px',
// };

// const outlinedLabel = {
//   position: "absolute",
//   top: "-12px",
//   left: "8px",
//   backgroundColor: "white",
//   padding: "0 4px",
//   fontSize: "12px",
//   color: "gray"
// }

const DivField = React.memo((props) => {
  const defaultColor = "rgba(0, 0, 0, 0.23)";

  const theme = useTheme();
  const [color, setColor] = useState(
    props.showBorder ? defaultColor : "transparent"
  );

  const { getInputWidth, inputStyleTable } = useField();
  useEffect(() => {
    if (!props.showBorder) {
      setColor("transparent");
    } else if (props.disabled) {
      setColor(theme.palette.action.disabled);
    } else if (props.isInvalid) {
      setColor(theme.palette.error.main);
    } else {
      setColor(defaultColor);
    }
  }, [props.isInvalid, props.disabled, props.showBorder]);

  const getLabel = () => {
    var label = props.label ? props.label : "";
    if (props.required) {
      label += "*";
    }
    return label;
  };

  const getInputPropsStyle = () => {
    return props.inputProps.style ? props.inputProps.style : {};
  };


  return (
    <div
      style={{
        ...containerStyle,

        backgroundColor: props.readOnly ? "#ededed" : undefined,
      }}
    >
      <div
        class="outlined-textfield"
        style={props.style}
        onClick={
          !props.disabled && !props.readOnly
            ? (event) => props.onSelectField(event)
            : undefined
        }
      >
        {(props.label || props.required) && (
          <label class="outlined-label" style={{ color: color }}>
            {getLabel()}
          </label>
        )}
        <input
          onFocus={props.readOnly ? undefined : props.onFocus}
          disabled={props.disabled}
          readOnly
          type={props.type}
          textAlign="center"
          class="outlined-input"
          value={!isEmptyValue(props.value) ? props.value : ""}
          style={{
            ...inputStyleTable,
            border: `1px solid ${color}`,
            color: props.disabled ? color : undefined,
            minWidth: getInputWidth(props.value, true),
            ...getInputPropsStyle(),
          }}
        />
      </div>
      {props.helperText && (
        <div style={{ ...helperTextStyle, ERROR_TEXT_STYLE }}>
          {props.helperText}
        </div>
      )}
    </div>
  );
});

DivField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helperText: PropTypes.string,
  isInvalid: PropTypes.bool,
  onSelectField: PropTypes.func,
  showBorder: PropTypes.bool,
  disabled: PropTypes.disabled,
  style: PropTypes.object,
  readOnly: PropTypes.bool,
  inputProps: PropTypes.object,
  onFocus: PropTypes.func,
};

DivField.defaultProps = {
  options: [],
  optionLabel: "name",
  optionKey: "id",
  showBorder: false,
  disabled: false,
  style: {},
  readOnly: false,
  inputProps: {},
};

export default DivField;
