import { useState, useEffect, useMemo, useRef, useCallback } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { NAVIGATION_DRAWER_TYPE_REPORTS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import useTicketService from "../../services/ticketService";
import { useAsync } from "../../hooks/useAsync";

import TicketTable from "../../components/table/TicketTable";
import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";
import TicketFeedbackChartToolBar from "../../components/bar/TicketFeedbackChartToolBar";

import TableService from "../../services/tableService";

import ChartService from "../../services/chartService";
import DateService from "../../services/dateService";
import VerticalBarChart from "../../components/charts/VerticalBarChart/VerticalBarChart";

import { getNpsIndicatorColor } from "../../helpers/styles";

import OpenCloseFilterButton from "../../components/button/OpenCloseFilterButton";
import CollapseOrExpandButton from "../../components/other/CollapseOrExpandButton";

import MultipleLocalizationDialog from "../../components/dialog/MutlipleLocalizationDialog/MultipleLocalizationDialog";
import useDialog from "../../hooks/useDialog";

import { exportTicketsSlaToFileUrl } from "../../helpers/apiUrls";
import { getObjectFromSearchParams } from "../../helpers/methods";

import DunReportsNavigationBar from "../../components/bar/DunReportsNavigationBar";
import DunReportsNavigationFeedbackSubBar from "../../components/bar/DunReportsNavigationBar/DunReportsNavigationFeedbackSubBar";

import {
  TICKET_FEEDBACK_TABLE_FIELDS_CONFIG,
  TICKET_FEEDBACK_TABLE_NAME,
} from "./TableConfig";

const TABLE_CONFIGS = [
  {
    name: TICKET_FEEDBACK_TABLE_NAME,
    config: TICKET_FEEDBACK_TABLE_FIELDS_CONFIG,
  },
];

export const CHART_TYPE_FEEDBACK_DISTRIBUTION = "feedback_distribution";
export const CHART_TYPE_NPS_DISTRIBUTION = "nps_distribution";

export default function TicketsFeedbackReportBasePage(props) {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams();

  const { getTicketsFeedbackDistribution, getTicketSlaFilteringData } =
    useTicketService();

  const ticketData = useAsync(() => {
    let tempSearchParams = {
      ...getObjectFromSearchParams(searchParams),
    };
    tempSearchParams.chart_type = props.chartType;

    tempSearchParams.hours_timezone_offset =
      DateService.getHoursTimezoneOffset();
    return getTicketsFeedbackDistribution(tempSearchParams);
  }, [searchParams]);

  const ticketFilteringData = useAsync(() =>
    getTicketSlaFilteringData({ include_ticket_object: true })
  );

  const [ticketDataLocaly, setTicketDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  const [npsIndicator, setNpsIndicator] = useState();
  const [ticketsDistribution, setTicketDistribution] = useState();

  useEffect(() => {
    if (ticketData.loading) {
      setTicketDistribution(undefined);
      return;
    }
    setTicketDataLocaly(ticketData.value.results);
    setTicketDistribution(ticketData.value.tickets_distribution);
    setNpsIndicator(ticketData.value.nps);
    setCountRecords(ticketData.value.count);
  }, [ticketData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] =
    useState(undefined);
  const [columnsOrdersForTables, setColumnsOrdersForTables] =
    useState(undefined);

  const refreshTable = () => {
    setTicketDataLocaly(undefined);
    setTicketDistribution(undefined);
    ticketData.refetch();
  };

  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();
  const clickedTicketMultipleLocalization = useRef();

  const handleOpenMultipleLocalizationDialog = useCallback(
    (e, ticketId) => {
      e.stopPropagation();
      clickedTicketMultipleLocalization.current = ticketDataLocaly.find(
        (ticket) => ticket.id === ticketId
      ).locations;
      onOpenMultipleLocalizationDialog();
    },
    [onOpenMultipleLocalizationDialog, ticketDataLocaly]
  );

  const handleCloseMultipleLocalizationDialog = () => {
    clickedTicketMultipleLocalization.current = null;
    onCloseMultipleLocalizationDialog();
  };

  const ticketSlaExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      TICKET_FEEDBACK_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[TICKET_FEEDBACK_TABLE_NAME],
      columnsOrdersForTables?.[TICKET_FEEDBACK_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[TICKET_FEEDBACK_TABLE_NAME],
    columnsOrdersForTables?.[TICKET_FEEDBACK_TABLE_NAME],
    TICKET_FEEDBACK_TABLE_FIELDS_CONFIG,
  ]);

  const getExportSearchParams = () => {
    const tempSearchParams = { ...getObjectFromSearchParams(searchParams) };
    tempSearchParams.is_open = false;
    return tempSearchParams;
  };

  const getExtraButtonListMdGrid = () => {
    return [2.5, 0.5];
  };

  const [chartIsFullScreen, setChartFullScreen] = useState(false);

  const getUniveralToolBarExtraButtonList = () => {
    return [
      <OpenCloseFilterButton
        openCloseFilterLabel={t("page.feedback_management_page.tenant_rr_all")}
        closeFilterLabel={t(
          "page.feedback_management_page.tenat_rr_not_visibility"
        )}
        openFilterLabel={t("page.feedback_management_page.tenat_rr_visibility")}
        openFilterKey={"tenant_rr_visibility"}
      />,
      <CollapseOrExpandButton
        isExpanded={chartIsFullScreen}
        onChangeIsExpanded={setChartFullScreen}
      />,
    ];
  };

  const getChartPropsByChartType = () => {
    if (props.chartType === CHART_TYPE_NPS_DISTRIBUTION) {
      return {
        data: {
          labels: ticketsDistribution.labels,
          datasets: ChartService.prepareDataForSingleOrMultiGroupsChart(
            ticketsDistribution.data,
            true
          ),
        },
        datalabelsFormaterFn: (value, context) =>
          ChartService.getChartDataLabelsFormaterForTotalPercentRatioFn(
            context,
            countRecords
          ),
        tooltipFormaterFn: (value, context) =>
          ChartService.getChartTooltipFormaterForPercentRatioFn(value, context),
        legendDisplay: true,
        titleDisplay: false,
        dataLabelsDisplay: true,
      };
    }

    if (props.chartType === CHART_TYPE_FEEDBACK_DISTRIBUTION) {
      return {
        data: ChartService.prepareDataFromDict(
          ticketsDistribution,
          ChartService.prepareRedGreenBackgroundColorsGradientForData(
            ticketsDistribution
          )
        ),
        titleText: t(
          "page.feedback_management_page.evaluation_summary_of_closed_tickets"
        ),
        legendDisplay: false,
      };
    }

    return {};
  };

  const isLoading =
    ticketDataLocaly === undefined || ticketFilteringData.loading;

  return (
    <NavigationDrawer
      pageName={props.pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_REPORTS}
    >
      <DefaultPageWrapper titleKey={props.pageTitleKey}>
        <LoaderWrapper showLoader={isLoading}>
          <Grid
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={1}
          >
            <Grid item xs={12}>
              <DunReportsNavigationBar
                multipleLocationKey={"/tickets_per_feedback_report/"}
              />
            </Grid>
            <Grid item xs={12}>
              <DunReportsNavigationFeedbackSubBar />
            </Grid>
            <Grid item xs={12}>
              <TicketFeedbackChartToolBar
                pageName={props.pageName}
                showSelectObjectLevel2={props.showSelectObjectLevel2}
                showSelectTenant={props.showSelectTenant}
                showTenantVisibilityButton={true}
                tableFilteringData={ticketFilteringData}
                tableFieldConfigs={TICKET_FEEDBACK_TABLE_FIELDS_CONFIG}
              />
            </Grid>
            {ticketsDistribution && (
              <Grid
                item
                xs={12}
                style={{ height: chartIsFullScreen ? "67vh" : "40vh" }}
              >
                {props.showNps && (
                  <Typography
                    color={getNpsIndicatorColor(npsIndicator)}
                    style={{
                      position: "absolute",
                      marginTop: "10px",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {t("page.feedback_management_page.nps_indicator")}
                    {": "}
                    {npsIndicator}
                  </Typography>
                )}
                <VerticalBarChart {...getChartPropsByChartType()} />
              </Grid>
            )}
            <Grid item xs={12} marginY={1}>
              <UniversalToolBarWithDialogs
                pageName={props.pageName}
                tableConfig={TABLE_CONFIGS}
                filteringData={ticketFilteringData}
                refreshTable={refreshTable}
                resetPageNumber={resetPageNumber}
                hiddenColumnsForTables={hiddenColumnsForTables}
                setHiddenColumnsForTables={setHiddenColumnsForTables}
                columnsOrdersForTables={columnsOrdersForTables}
                setColumnsOrdersForTables={setColumnsOrdersForTables}
                showMassActionButton={false}
                showCreateButton={false}
                showOpenCloseFilterButton={false}
                showCleanfiltersButton={false}
                showMyFiltersButton={false}
                showSearchButton={false}
                extraButtonList={getUniveralToolBarExtraButtonList()}
                extraButtonListMdGrid={getExtraButtonListMdGrid()}
              />
            </Grid>
            <Grid item xs={12}>
              {hiddenColumnsForTables &&
                columnsOrdersForTables &&
                !chartIsFullScreen && (
                  <TicketTable
                    showCleanFilterIcon={false}
                    showFilters={true}
                    showDetailsIcon={true}
                    data={ticketDataLocaly}
                    filteringData={ticketFilteringData}
                    countRecords={countRecords}
                    page={page}
                    onPageChange={handleChangePageWithSearchParams}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    resetPageNumber={resetPageNumber}
                    tableConfig={TICKET_FEEDBACK_TABLE_FIELDS_CONFIG}
                    hiddenColumns={
                      hiddenColumnsForTables[TICKET_FEEDBACK_TABLE_NAME]
                    }
                    columnsOrders={
                      columnsOrdersForTables[TICKET_FEEDBACK_TABLE_NAME]
                    }
                    style={{ maxHeight: "65vh" }}
                    showExportToFileButton={true}
                    exportToFileUrl={exportTicketsSlaToFileUrl}
                    exportToFileSearchParams={getExportSearchParams()}
                    exportToFileHeaders={ticketSlaExportHeaders}
                    exportToFileFileName={`${t(
                      "dialog.ticket_sla_dialog.tickets"
                    ).replace(" ", "_")}.xlsx`}
                    itemDetailsLinkPrefix={"ticket_details"}
                    onClickMultipleLocationAlert={
                      handleOpenMultipleLocalizationDialog
                    }
                  />
                )}
            </Grid>
            {openMultipleLocalizationDialog &&
              clickedTicketMultipleLocalization.current && (
                <MultipleLocalizationDialog
                  open={openMultipleLocalizationDialog}
                  onClose={handleCloseMultipleLocalizationDialog}
                  localizationData={clickedTicketMultipleLocalization.current}
                  itemType={"ticket"}
                />
              )}
          </Grid>
        </LoaderWrapper>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}

TicketsFeedbackReportBasePage.propTypes = {
  chartType: PropTypes.string,
  pageName: PropTypes.string,
  pageTitleKey: PropTypes.string,
  showNps: PropTypes.bool,
  showSelectObjectLevel2: PropTypes.bool,
  showSelectTenant: PropTypes.bool,
};
