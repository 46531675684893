import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import useUserService from "../../../services/userService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import AutocompleteField from "../../field/AutocompleteField";
import { useCallback, useEffect } from "react";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { getTranslatedList } from "../../../helpers/methods";

const UserWarehousePermissionDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();


  const setInitForm = () =>{
    if (props.dialogType === "edit"){
      return { 'user_id': props.userId, 'warehouse_id': props.warehouseId }
    } else if (props.dialogType === "copy"){
      return {'warehouse_id': props.warehouseId , 'permissions' : props.permissions}
    }
    return {}
  }

  const {
    formValue,
    setFormValue,
    onChangeAutocompleteFieldWithObjectOptions
  } = useForm(setInitForm());

  const { getTransactionWarehousePermissionList,
    updateUserPermission
   } = useUserService();

  const updateUserWarehousePermissionFn = useAsyncFn(updateUserPermission)

  const usersWarehousePermissions = useAsync(
    () => {
      if (formValue?.user_id && formValue?.warehouse_id && props.dialogType !== "copy") {
        return getTransactionWarehousePermissionList({ 'user': formValue?.user_id, 'warehouse': formValue?.warehouse_id })
      }
      return Promise.resolve({})
    },
    [formValue?.user_id, formValue?.warehouse_id]
  );

  useEffect(() => {
    if (usersWarehousePermissions.loading) {
      return;
    }
    if (usersWarehousePermissions.value.length > 0) {
      setFormValue(usersWarehousePermissions.value[0]);

    } else  if (props.dialogType !== "copy"){
      setFormValue((prev) => ({ ...prev, 'permissions': [] }))
    }
  }, [usersWarehousePermissions.loading]);

  const onSubmit = useCallback(() => {
      updateUserWarehousePermissionFn
        .execute(formValue)
        .then((res) => {
          props.onRefetchData()
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.permissions_updated")
          );
          props.onClose();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_permissions_updating")
          );
        });
  }, [formValue]);

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={t("dialog.user_warehouse_permission_dialog.permission_management")}
      maxWidth="xs"
      rowGap={2}
      showDialogActions
    >
      <LoaderWrapper showLoader={usersWarehousePermissions.loading}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          rowGap={1}
        >
          <Grid item xs={12}>
            <AutocompleteField
              name={"user_id"}
              value={formValue?.user_id}
              label={t("dialog.user_warehouse_permission_dialog.user")}
              options={props.filteringData?.value?.users}
              isObjectOption={true}
              optionLabelKey={"full_name"}
              addNewValue={false}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              disabled={props.dialogType === "edit"}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteField
              name={"warehouse_id"}
              value={formValue?.warehouse_id}
              label={t("dialog.user_warehouse_permission_dialog.warehouse_name")}
              options={props.filteringData?.value?.warehouses}
              isObjectOption={true}
              optionLabelKey={"warehouse_name"}
              addNewValue={false}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              disabled={props.isEditDialog}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteField
              name={"permissions"}
              value={formValue?.permissions}
              label={t("dialog.user_warehouse_permission_dialog.transaction_permissions")}
              options={getTranslatedList(props.filteringData?.value?.transaction_permission_kinds, "transactions.")}
              isObjectOption={true}
              optionLabelKey = {"name"}
              multiple={true}
              addNewValue={false}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={updateUserWarehousePermissionFn.loading}
              disabled={!formValue?.user_id || !formValue?.warehouse_id }
              onClick={onSubmit}
            >
              { t("save")}
            </LoadingButton>
          </Grid>


        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
};

UserWarehousePermissionDialog.propTypes = {
  userId: PropTypes.string,
  warehouseId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dialogType : PropTypes.oneOf([undefined, "edit", "copy"])
};

UserWarehousePermissionDialog.defaultProps = {
  open: false,
};

export default UserWarehousePermissionDialog;
