import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import React, { useEffect, useState } from "react";
import usePurchaseService from "../../../services/purchaseService";
import { useAsync } from "../../../hooks/useAsync";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import TableService from "../../../services/tableService";
import useDocsData from "../../../hooks/useDocsData";
import BaseTable from "../../base/BaseTable/BaseTable";
import { PO_INVOICE_TABLE_FIELDS_CONFIG } from "./TableConfig";
import { useTranslation } from "react-i18next";


const FvPreviewDialog = (props) => {

  const [fvListLocal, setFvListLocal] = useState([])
  const { t } = useTranslation();

  const {
    getFVsFlatList
  } = usePurchaseService()

  const {
    onDownloadDoc,
    onPreviewDoc,
  } = useDocsData();


  const fvList = useAsync(() => {
    if (props.poId) {
      return getFVsFlatList({'po' : props.poId})
    }
    return Promise.resolve([])
  }, [props.poId])

  useEffect(() => {
    if (fvList.loading) {
      return;
    }
    if (fvList.value) {
      setFvListLocal(fvList.value)
    }
  }, [fvList.loading]);



  if (fvListLocal.loading) return <LoaderWrapper showLoader={true} />;
  return (

    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={`${t("dialog.fv_preview_dialog.invoices")}${props.poNumber}`}
      maxWidth="xl"
      showDialogActions={false}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >

        <Grid item xs={12}>
          <BaseTable
            showContextMenu={false}
            showNoRecordsPaper={false}
            headers={TableService.getHeaderLables(
              PO_INVOICE_TABLE_FIELDS_CONFIG 
            )}
            rows={TableService.getPreparedDataForBaseTable(
              PO_INVOICE_TABLE_FIELDS_CONFIG,
              fvListLocal,
              [],
              [],
              onPreviewDoc,
              onDownloadDoc
            )}
          />
        </Grid>
      </Grid>
    </BasicDialog>
  );
};

FvPreviewDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  poId : PropTypes.string,
  poNumber : PropTypes.string,
};

FvPreviewDialog.defaultProps = {
  open: false,
};

export default FvPreviewDialog ;
