import i18n from "../../../i18n";
import DateService from "../../../services/dateService";
import FileTableCell from "../../other/FileTableCell/FileTableCell";


export const PO_TABLE_FIELDS_CONFIG = [
  {
    name: "po_nr",
    getValueCallback: (rowData) => rowData.po_nr,
    label: i18n.t("table.materials_shopping_table.po_number"),
  },
  {
    name: "po_payer",
    getValueCallback: (rowData) => rowData.po_payer,
    label: i18n.t("table.materials_shopping_table.po_payer"),
  },
  {
    name: "po_creator",
    getValueCallback: (rowData) => rowData.po_creator,
    label: i18n.t("table.materials_shopping_table.po_creator"),
  },
  {
    name: "po_issuer",
    getValueCallback: (rowData) => rowData.po_issuer,
    label: i18n.t("table.materials_shopping_table.po_issuer"),
  },
  {
    name: "po_person",
    getValueCallback: (rowData) => rowData.po_person,
    label: i18n.t("table.materials_shopping_table.po_person"),
  },
  {
    name: "po_mail",
    getValueCallback: (rowData) => rowData.po_mail,
    label: i18n.t("table.materials_shopping_table.po_mail"),
  },
  {
    name: "po_value",
    getValueCallback: (rowData) => rowData.po_value,
    label: i18n.t("table.materials_shopping_table.value"),
  },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.created
      ),
    label: i18n.t("table.materials_shopping_table.created"),
  },
  {
    name: "po_date_send",
    getValueCallback: (rowData) => rowData.po_date_send,
    label: i18n.t("table.materials_shopping_table.date_send"),
  },
  {
    name: "po_date_forecast",
    getValueCallback: (rowData) => rowData.po_date_forecast,
    label: i18n.t("table.materials_shopping_table.date_forecast"),
  },
  {
    name: "po_doc",
    getValueCallback: (rowData,  onPreviewDoc, onDownloadDoc) => {
      const content = (
        <FileTableCell 
        fileType={'docs'} 
        file={rowData.po_doc} 
        onPreview={onPreviewDoc}
        onDownload = {onDownloadDoc}
        readOnly={true}/>
      );
      return rowData.po_doc
        ? content
        : "";
    },
    label: i18n.t("table.materials_shopping_table.url_origin"),
    filterType: null,
    allowChangeVisibility: false,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];