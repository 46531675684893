import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";


const ValidationForm = (props) => {

  const { t } = useTranslation();

  return (
    <>
      {!props.isRequiredeFieldsValid &&
        <Typography
          variant="subtitle1"
          color={"error"}
          style={{
            textTransform: "uppercase",
          }}>
          {t("form.validation_form.not_all_required_fields_are_completed")}
        </Typography>}
      {!props.sumSettlementRatesIsCorrectly &&
        <Typography
          variant="subtitle1"
          color={"error"}
          style={{
            textTransform: "uppercase",
          }}>
          {t("form.validation_form.sum_of_settlement_rate_is_not_equal_one")}
        </Typography>}
      {props.offerCostIsEqualZero &&
        <Typography
          variant="subtitle1"
          color={"error"}
          style={{
            textTransform: "uppercase",
          }}>
          {t("form.validation_form.sum_of_offer_must_be_more_than_zero")}
        </Typography>}
      {props.totalTransferAreHigherThanConditionValue &&
        <Typography
          variant="subtitle1"
          color={"error"}
          style={{
            textTransform: "uppercase",
          }}>
          {t("form.validation_form.sum_off_transer_is_higher_than_condition_value")}
        </Typography>}
      {props.icoScheduleShouldBeUpdated &&
        <Typography
          variant="subtitle1"
          color={"error"}
          style={{
            textTransform: "uppercase",
          }}>
          {t("form.validation_form.recalculate_schedule_again")}
        </Typography>}
      {!props.isSumEqualConditionValue &&
        <Typography
          variant="subtitle1"
          color={"error"}
          style={{
            textTransform: "uppercase",
          }}>
          {t("form.validation_form.schedule_sum_is_not_equal_condition_value")}
        </Typography>}
      {!props.areIcoScheduleDatesAsc &&
        <Typography
          variant="subtitle1"
          color={"error"}
          style={{
            textTransform: "uppercase",
          }}>
          {t("form.validation_form.dates_are_not_ascending")}
        </Typography>}
        {!props.isFirstInstallmentDateValid &&
        <Typography
          variant="subtitle1"
          color={"error"}
          style={{
            textTransform: "uppercase",
          }}>
          {t("form.validation_form.first_installment_date_is_after_first_interest_date")}
        </Typography>}
        {!props.isFirstDateAfterActivationDateValid &&
        <Typography
          variant="subtitle1"
          color={"error"}
          style={{
            textTransform: "uppercase",
          }}>
          {t("form.validation_form.first_due_date_is_before_activation_date")}
        </Typography>}
        {!props.isPartialInstallmentSumIsValid &&
        <Typography
          variant="subtitle1"
          color={"error"}
          style={{
            textTransform: "uppercase",
          }}>
          {t("form.validation_form.partial_installment_sum_is_not_valid")}
        </Typography>}

    </>
  );
};

ValidationForm.propTypes = {
  isRequiredeFieldsValid: PropTypes.bool,
  sumSettlementRatesIsCorrectly: PropTypes.bool,
  offerCostIsEqualZero : PropTypes.bool,
  totalTransferAreHigherThanConditionValue : PropTypes.bool,
  icoScheduleShouldBeUpdated :PropTypes.bool,
  isSumEqualConditionValue : PropTypes.bool,
  areIcoScheduleDatesAsc : PropTypes.bool,
  isFirstInstallmentDateValid : PropTypes.bool,
  isFirstDateAfterActivationDateValid : PropTypes.bool,
  isPartialInstallmentSumIsValid : PropTypes.bool
};

ValidationForm.defaultProps = {
  isRequiredeFieldsValid: true,
  sumSettlementRatesIsCorrectly: true,
  offerCostIsEqualZero : false,
  totalTransferAreHigherThanConditionValue : false,
  icoScheduleShouldBeUpdated : false,
  isSumEqualConditionValue : true,
  areIcoScheduleDatesAsc : true,
  isFirstInstallmentDateValid : true,
  isFirstDateAfterActivationDateValid : true,
  isPartialInstallmentSumIsValid : true
};

export default ValidationForm;
