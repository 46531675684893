import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import BasicDialog from "../../base/BasicDialog";
import { useAsync  } from "../../../hooks/useAsync";
import PartParameterForm from "../../form/PartParameterForm";
import { useCallback, useState } from "react";
import { Grid} from "@mui/material";
import { isEmptyValue } from "../../../helpers/methods";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "../../../hooks/useForm";
import useItemService from "../../../services/itemService";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import useDialog from "../../../hooks/useDialog";
import CreateParameterDialog from "../CreateParameterDialog/CreateParameterDialog";

const PartParameterDialog = (props) => {
  const { t } = useTranslation();

  const {getParametersList } = useItemService();
  const partParameterList = useAsync(getParametersList)

  const [selectedUnit, setSelectedUnit] = useState("-")

  const requiredFields = [
    "parameter",
    "parval_value"
  ];

  
  const [
    openCreateParameterDialog,
    onOpenCreateParameterDialog,
    onCloseCreateParameterDialog ] =
    useDialog();


  const { formValue, setFormValue, onChange, onChangeAutocompleteFieldWithObjectOptions } = useForm(props.parameterValueData? props.parameterValueData : {"part": props.partId});

  const onChangeParameter = useCallback((e, value_object, value_key, state_value_name) => {
    let tempFormValue = {...formValue}

    tempFormValue['param_name'] = value_object.param_name
    tempFormValue['param_unit'] = value_object.param_unit
    setFormValue({...tempFormValue})
    onChangeAutocompleteFieldWithObjectOptions(e, value_object, value_key, state_value_name)
    setSelectedUnit(value_object.param_unit)

  }, [formValue])

  const isFormValid = () => {
    return requiredFields.every(
      (fieldName) => !isEmptyValue(formValue[fieldName])
    );
  };



  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={
        props.parameterValueData
          ? t(`dialog.part_parameter_dialog.edit_part_parameter`)
          : t(`dialog.part_parameter_dialog.add_part_parameter`)
      }
      maxWidth="xs"
    >
      <LoaderWrapper showLoader={partParameterList.loading}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PartParameterForm
            parametersList={partParameterList}
            selectedUnit = {selectedUnit}
            formValue = {formValue}
            onChange = {onChange}
            onChangeParameter = {onChangeParameter}
            readOnly={props.readOnly}
            onOpenAddNewParameter = {onOpenCreateParameterDialog}
            readOnlyParameter = {props.parameterValueData !== undefined}
          />
        </Grid>

        {!props.readOnly && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={props.isLoading}
              disabled={!isFormValid()}
              onClick={() => props.onAddParameter(formValue)}
            >
              {props.parameterValueData? t("save_changes") : t("add")}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
      </LoaderWrapper>
      {openCreateParameterDialog &&
          <CreateParameterDialog
            open={openCreateParameterDialog}
            onClose={onCloseCreateParameterDialog}
            onRefetchParamatersList = {partParameterList.refetch}
          />}
    </BasicDialog>
  );
};

PartParameterDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

PartParameterDialog.defaultProps = {
  open: false,
};

export default PartParameterDialog;
