import {
  guaranteeDataUrl,
  guaranteeFilteringDataUrl,
  guaranteeScopeDataUrl,
  guaranteeScopeFilteringDataUrl,
  guaranteeDetailsDataUrl,
  guaranteeScopeDetailsDataUrl,
  guaranteeScopeManualReminderUrl,
  guaranteeAgreementDetailsDataUrl,
  guaranteeAgreementsDataUrl,
  guaranteeAgreementFilteringDataUrl,
  guaranteeAgreementsDataPaginationUrl,
  guaranteeNextNumberUrl,
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { prepareUrlWithQueryParams } from "../helpers/methods";

export default function useGuaranteeService() {
  const axiosPrivate = useAxiosPrivate();

  const getGuaranteeData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(guaranteeDataUrl, searchParams)
    );
  }; 
  
  
  const getGuaranteeDataByAgreement = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(guaranteeAgreementsDataUrl, searchParams)
    );
  };

  const getGuaranteeAgreementFilteringData = () => {
    return makeRequest(axiosPrivate, guaranteeAgreementFilteringDataUrl);
  };
  
  const getGuaranteeAgreementData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(guaranteeAgreementsDataPaginationUrl, searchParams)
    );
  };

  const getGuaranteeFilteringData = () => {
    return makeRequest(axiosPrivate, guaranteeFilteringDataUrl);
  };

  const getGuaranteeScopeData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(guaranteeScopeDataUrl, searchParams)
    );
  };

  const getGuaranteeScopeDataForGuarantee = (guaranteeId, searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(guaranteeScopeDataUrl, {
        ...searchParams,
        guarantee: guaranteeId,
      })
    );
  };

  const getGuaranteeScopeFilteringData = () => {
    return makeRequest(axiosPrivate, guaranteeScopeFilteringDataUrl);
  };

  const getGuaranteeDetailsData = (guaranteeId) => {
    return makeRequest(axiosPrivate, guaranteeDetailsDataUrl(guaranteeId));
  };

  const getGuaranteeAgreementDetailsData = (guaranteeId) => {
    return makeRequest(axiosPrivate, guaranteeAgreementDetailsDataUrl(guaranteeId));
  };

  const getGuaranteeScopeDetailsData = (guaranteeScopeId) => {
    return makeRequest(
      axiosPrivate,
      guaranteeScopeDetailsDataUrl(guaranteeScopeId)
    );
  };

  const createOrUpdateGuarantee = (guaranteeId, dataToSend) => {
    if (guaranteeId) return updateGuarantee(guaranteeId, dataToSend);
    return createGuarantee(dataToSend);
  };

  const createGuarantee = (dataToSend) => {
    return axiosPrivate(guaranteeDataUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateGuarantee = (guaranteeId, dataToSend) => {
    return axiosPrivate(guaranteeDetailsDataUrl(guaranteeId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const createOrUpdateGuaranteeScope = (guaranteeScopeId, dataToSend) => {
    if (guaranteeScopeId)
      return updateGuaranteeScope(guaranteeScopeId, dataToSend);
    return createGuaranteeScope(dataToSend);
  };

  const createGuaranteeScope = (dataToSend) => {
    return axiosPrivate(guaranteeScopeDataUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateGuaranteeScope = (guaranteeScopeId, dataToSend) => {
    return axiosPrivate(guaranteeScopeDetailsDataUrl(guaranteeScopeId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getEmptyGuaranteeScope = () => {
    return {};
  };

  const sendGuaranteeScopeManualReminder = (guaranteeScopeId) => {
    return makeRequest(
      axiosPrivate,
      guaranteeScopeManualReminderUrl(guaranteeScopeId)
    );
  };

  const getNextGuaranteeNumber = (data) => {
    return makeRequest(axiosPrivate, guaranteeNextNumberUrl, {
      method: "POST",
      data: data,
    });
  };

  return {
    getGuaranteeData,
    getGuaranteeFilteringData,
    getGuaranteeScopeData,
    getGuaranteeDetailsData,
    getGuaranteeScopeDetailsData,
    getGuaranteeScopeDataForGuarantee,
    getGuaranteeScopeFilteringData,
    createOrUpdateGuarantee,
    createGuarantee,
    updateGuarantee,
    createOrUpdateGuaranteeScope,
    createGuaranteeScope,
    updateGuaranteeScope,
    getEmptyGuaranteeScope,
    sendGuaranteeScopeManualReminder,
    getGuaranteeAgreementDetailsData,
    getGuaranteeDataByAgreement,
    getGuaranteeAgreementFilteringData,
    getGuaranteeAgreementData,
    getNextGuaranteeNumber
  };
}
