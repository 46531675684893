import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import { useForm } from "../../../hooks/useForm";
import React, { useCallback, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { convertUndefinedValueToZero, isEmptyArray, isEmptyValue, roundPrice } from "../../../helpers/methods";
import useItemService from "../../../services/itemService";
import useWarehouseService from "../../../services/warehouseService";
import usePurchaseService from "../../../services/purchaseService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import useOfferService from "../../../services/offerService";
import { RESERVATION_KIND, TRANSACTION_KIND_IN, TRANSACTION_KIND_MOVE, TRANSACTION_KIND_OUT } from "../../../helpers/constants";
import DateService from "../../../services/dateService";
import SelectPartDialog from "../SelectPartDialog";
import useDialog from "../../../hooks/useDialog";
import useUserService from "../../../services/userService";


const DEFAULT_TRANSACTION_REQUIRED_FIELDS = ["warehouse", "part", "transaction_amount"]
const DEFAULT_MATERIALS_FIELDS = ["part", "transaction_amount"]
const RESERVATION_REQUIRED_FIELDS = ["warehouse", "part", "reservation_amount", "reservation_closed_date"]

const TransactionDialog = (props) => {
  const {
    getEmptyItemElement,
    prepareItemDataFromBackendForItemRecordComponent,
  } = useItemService()

  const snackbarAlert = useSnackbarAlert();
  const {
    getTransactionSelectingData,
    getStockAvailability,
    getWarehouseLocationLists,
    getTransactionWzData
  } = useWarehouseService();



  const { getPartsSelectListData,
    getPartItemRecordById
  } = useOfferService();

  const {
    getAllowedWarehouseByTransactionKind
  } = useUserService();

  const getPartItemRecordByIdFn = useAsyncFn(getPartItemRecordById)

  const {
    formValue,
    setFormValue,
    onChangeAutocompleteFieldWithObjectOptions,
    onChange,
    onChangeDate
  } = useForm(props.transactionKind === RESERVATION_KIND ?
    {
      "transaction_kind": props.transactionKind,
      "reservation_closed_date": props.reservationClosedDate
    } :
    {
      "transaction_kind": props.transactionKind
    }
  );

  const { t } = useTranslation();

  const fetchTransactionWzData = useAsync(() => {
    if (props.transactionKind === TRANSACTION_KIND_OUT) {
      return getTransactionWzData();
    }
    return Promise.resolve({});
  }, [props.transactionKind]);

  const allowedWarehouses = useAsync(() => {
      return getAllowedWarehouseByTransactionKind({ "permission_kind":props.transactionKind });
  }, [props.transactionKind]);

  useEffect(() => {
    if (fetchTransactionWzData.loading || props.transactionKind !== TRANSACTION_KIND_OUT) {
      return;
    }
    if (fetchTransactionWzData?.value) {
      setFormValue((tempForm) => ({
        ...tempForm,
        "transaction_wz": fetchTransactionWzData?.value.transaction_wz,
      }));
    }
  }, [fetchTransactionWzData.loading, props.transactionKind]);


  const [requiredFields, setRequiredFields] = useState(props.transactionKind === RESERVATION_KIND ? RESERVATION_REQUIRED_FIELDS : props.transactionKind === TRANSACTION_KIND_IN ? ["warehouse"]
    : [...DEFAULT_TRANSACTION_REQUIRED_FIELDS, ...props.extraRequiredFields])
  const [items, setItems] = useState(getEmptyItemElement());

  const transactionSelectDataList = useAsync(getTransactionSelectingData)

  const warehouseLocationLists = useAsync(() => {
    if (formValue.warehouse && props.transactionKind === TRANSACTION_KIND_IN) {
      return getWarehouseLocationLists({ 'warehouse': formValue.warehouse })
    }
    else if (formValue.warehouse_receiving && props.transactionKind === TRANSACTION_KIND_MOVE) {
      return getWarehouseLocationLists({ 'warehouse': formValue.warehouse_receiving })
    }
    return Promise.resolve([])
  }, [formValue?.warehouse, formValue?.warehouse_receiving])

  const {
    createPo
  } = usePurchaseService()

  const createPoFn = useAsyncFn(createPo)

  const onCreateNewPo = useCallback(() => {
    createPoFn
      .execute({ 'po_date_send': DateService.convertDateToFormatYYYYMMDD(new Date()) })
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.po_created")
        );
        transactionSelectDataList.refetch()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_po_creating")
        );
      });
  },
    []
  );


  const onChangeLevelItem = (item, index = 0) => {
    var items_temp = [...items];
    items_temp[index] = item;
    setItems(item);
    onCleanPart()
  };





  const onChangeAutocompleteLocal = useCallback((...inputProps) => {
    onChangeAutocompleteFieldWithObjectOptions(...inputProps)
    const [event, valueObject, valueKey, name, index] = inputProps
    if (name === 'transaction_po') {
      setItems(getEmptyItemElement())
      onCleanPart()
    } else if (name === 'part') {
      onSetPartData(valueObject)
    } else if (name === 'warehouse' && props.transactionKind === TRANSACTION_KIND_IN) {
      setFormValue((tempForm) => ({
        ...tempForm,
        "warehouse_location": undefined,
      }))
     
    }  else if (name === "warehouse" && props.transactionKind !== TRANSACTION_KIND_IN){
      onCleanPart()
      setItems(getEmptyItemElement())
      setFormValue((tempForm) => ({
       ...tempForm,
       "warehouse_location": undefined,
     }))
     }else if (name === 'warehouse_receiving' && props.transactionKind === TRANSACTION_KIND_MOVE) {
      setFormValue((tempForm) => ({
        ...tempForm,
        "warehouse_location": undefined,
      }))
    }

  }, [formValue])

  const onSetPartData = (valueObject) => {
    setFormValue((tempForm) => ({
      ...tempForm,
      "part_unit": valueObject["part_unit"],
      "transaction_price": props.transactionKind === TRANSACTION_KIND_IN ? valueObject["part_price"] : undefined,
      "transaction_amount": undefined,
      "transaction_value": undefined,
    }))
  }
  const partByPoList = useAsync(() => {
    if (formValue.transaction_po) {
      return getPartsSelectListData({ 'po': formValue.transaction_po })
    }
    return Promise.resolve([])
  }, [formValue.transaction_po])

  const [
    openSelectPartDialog,
    onOpenSelectPartDialog,
    onCloseSelectPartDialog,
  ] = useDialog();

  const prepreDataToGetAmount = (data) => {
    let retData = {}
    retData.warehouse = data.warehouse
    retData.part = data.part
    return retData
  }

  const getStockAmountAndPrice = useAsync(() => {
    if (formValue.warehouse && formValue.part && props.transactionKind !== TRANSACTION_KIND_IN) {
      return getStockAvailability(prepreDataToGetAmount(formValue))
    }
    return Promise.resolve([])
  }, [formValue.warehouse, formValue.part])

  useEffect(() => {
    if (getStockAmountAndPrice.loading) {
      return;
    }
    if (getStockAmountAndPrice.value) {
      setFormValue((tempForm) => ({ ...tempForm, "stock_availability": getStockAmountAndPrice.value.stock_availability, "stock_amount": getStockAmountAndPrice.value.stock_amount, "transaction_price": getStockAmountAndPrice.value.stock_price }))

    }
  }, [getStockAmountAndPrice.loading]);


  const onChangeLocal = useCallback((...inputProps) => {
    const { name, value } = onChange(...inputProps)
    if (name === "transaction_amount" ||name === "transaction_price") {
      setFormValue((tempForm) => ({ ...tempForm, "transaction_value": roundPrice(convertUndefinedValueToZero(tempForm.transaction_amount) * convertUndefinedValueToZero(tempForm.transaction_price)) }))
    }
  }, [formValue, items])

  const onCleanPart = useCallback(() => {
    setFormValue((tempForm) => ({
      ...tempForm,
      "part": undefined,
      "poli_unit": undefined,
      "transaction_price": undefined,
      "transaction_amount": undefined,
      "transaction_value": undefined,
    }))
  }, [formValue])

  const checkAmount = () => {
    if (props.transactionKind === TRANSACTION_KIND_IN) {
      return true
    } else if (props.transactionKind === RESERVATION_KIND) {
      return formValue.reservation_amount <= formValue.stock_availability
    } else if (props.transactionKind === TRANSACTION_KIND_OUT) {
      return formValue.transaction_amount <= formValue.stock_amount
    }
    return formValue.transaction_amount <= formValue.stock_availability
  }

  const checkMaterials = () => {
    if (props.transactionKind !== TRANSACTION_KIND_IN) {
      return true
    } 
    if (isEmptyArray(formValue?.materials ? formValue.materials : [])){
      return false
    }
    for (let material of formValue.materials){
      if(material.transaction_price === 0 || material.transaction_amount ==0 || !DEFAULT_MATERIALS_FIELDS.every((fieldName) => !isEmptyValue(material[fieldName]))){
        return false
      }
    }

    return true
  }


  const isValid = formValue ? requiredFields.every(
    (fieldName) => !isEmptyValue(formValue[fieldName])
  ) && checkAmount() && checkMaterials() : false

  const submitData = useCallback(() => {
    props.onSubmit(formValue)
  }, [formValue, requiredFields])

  const setItemBranchWithMaterial = useCallback((selectedPartId) => {
    getPartItemRecordByIdFn.execute(selectedPartId)
      .then((result) => {
        setItems(prepareItemDataFromBackendForItemRecordComponent(result.item))
        setFormValue((prev) => ({ ...prev, 'part': result.id }))
        onSetPartData(result)
        onCloseSelectPartDialog()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_get_item_data")
        );
      })

  }, [items, formValue])



  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.title}
      maxWidth="lg"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
   

        <Grid item xs={12}>
          {React.cloneElement(props.transactionForm, {
            formValue: formValue,
            loading: props.loading,
            onChange: onChangeLocal,
            setFormValue: setFormValue,
            onChangeAutocomplete: onChangeAutocompleteLocal,
            items: items,
            onChangeLevelItem: onChangeLevelItem,
            onCleanPart: onCleanPart,
            onChangeDate: onChangeDate,
            onCreateNewPo: onCreateNewPo,
            transactionSelectData: transactionSelectDataList ? transactionSelectDataList : [],
            poPartList: partByPoList,
            onClickSearchItem: onOpenSelectPartDialog,
            warehouseLocations: warehouseLocationLists ? warehouseLocationLists : [],
            allowedWarehouses : allowedWarehouses? allowedWarehouses : []
          })}
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={props.isDataSaving}
            disabled={!isValid}
            onClick={submitData}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
        {openSelectPartDialog &&
          <SelectPartDialog
            open={openSelectPartDialog}
            onClose={onCloseSelectPartDialog}
            onSubmit={setItemBranchWithMaterial}
            searchParams = {props.transactionKind !== TRANSACTION_KIND_IN && formValue?.warehouse ? { 'item_isnull': false, 'warehouse_stock' :  formValue.warehouse } :{ 'item_isnull': false }}
          />
        }

      </Grid>
    </BasicDialog>
  );
};

TransactionDialog.propTypes = {
  poLineId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  extraRequiredFields: PropTypes.array,
  transactionKind: PropTypes.string
};

TransactionDialog.defaultProps = {
  open: false,
  extraRequiredFields: [],
  transactionKind: TRANSACTION_KIND_IN
};

export default TransactionDialog;
