import { useState, useEffect, useMemo, useRef, useCallback } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import {
  NAVIGATION_DRAWER_TYPE_OFFERS,
  NAVIGATION_DRAWER_TYPE_RR,
} from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import RRPanelToolBar from "./RRPanelToolBar";

import { useAsync } from "../../hooks/useAsync";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import useTicketService from "../../services/ticketService";
import TicketTable from "../../components/table/TicketTable";

import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";

import VerticalBarChart from "../../components/charts/VerticalBarChart/VerticalBarChart";
import PieChart from "../../components/charts/PieChart/PieChart";
import ChartService from "../../services/chartService";

import TableService from "../../services/tableService";
import { exportTicketsSlaToFileUrl } from "../../helpers/apiUrls";

import {
  getFilterFieldKeyByNameAndPrefix,
  getObjectFromSearchParams,
} from "../../helpers/methods";
import { BOOLEAN_FIELD_TYPE } from "../../helpers/constants";

import MultipleLocalizationDialog from "../../components/dialog/MutlipleLocalizationDialog/MultipleLocalizationDialog";
import useDialog from "../../hooks/useDialog";

import OpenCloseFilterButton from "../../components/button/OpenCloseFilterButton";
import SelectBarPieChartButton from "../../components/button/SelectChartButton/SelectBarPieChartButton";

import {
  TICKET_SLA_TABLE_NAME,
  TICKET_SLA_TABLE_FIELDS_CONFIG,
} from "./TableConfig";

const tableConfig = {
  name: TICKET_SLA_TABLE_NAME,
  config: TICKET_SLA_TABLE_FIELDS_CONFIG,
};

export default function RRPanelPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    setSearchParams,
    searchParams,
  } = usePaginationWithSearchParams(undefined, undefined, 100);

  const { getTicketsWithSlaDelayTimeDistribution, getTicketSlaFilteringData } =
    useTicketService();

  const ticketSlaData = useAsync(() => {
    let tempSearchParams = {
      ...getObjectFromSearchParams(searchParams),
    };
    tempSearchParams.page_size = tempSearchParams.page_size || 100;
    return getTicketsWithSlaDelayTimeDistribution(tempSearchParams);
  }, [searchParams]);

  const ticketSlaFilteringData = useAsync(getTicketSlaFilteringData);

  const [ticketSlaDataLocaly, setTicketSlaDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  const [slaDelayTimeByCount, setSlaDelayByCount] = useState(undefined);
  const [slaDelayTimeRanges, setSlaDelayTimeRanges] = useState(undefined);
  const [selectedChart, setSelectedChart] = useState("bar_chart");

  useEffect(() => {
    if (ticketSlaData.loading) {
      return;
    }
    setTicketSlaDataLocaly(ticketSlaData.value.results);
    setCountRecords(ticketSlaData.value.count);
    setSlaDelayByCount(ticketSlaData.value.sla_delay_time_by_count);
    setSlaDelayTimeRanges(ticketSlaData.value.sla_delay_time_ranges);
  }, [ticketSlaData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setTicketSlaDataLocaly(undefined);
    setSlaDelayByCount(undefined);
    ticketSlaData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setTicketSlaDataLocaly(undefined);
    setSlaDelayByCount(undefined);
    setCountRecords(undefined);
  };

  const ticketSlaExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      TICKET_SLA_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[TICKET_SLA_TABLE_NAME],
      columnsOrdersForTables?.[TICKET_SLA_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[TICKET_SLA_TABLE_NAME],
    columnsOrdersForTables?.[TICKET_SLA_TABLE_NAME],
    TICKET_SLA_TABLE_FIELDS_CONFIG,
  ]);

  const handleClickBarChart = ({ label: slaDelayTime }) => {
    if (slaDelayTime)
      setSearchParams((prev) => ({
        ...prev,
        [getFilterFieldKeyByNameAndPrefix("sla_delay_time", undefined)]:
          slaDelayTime,
      }));
  };

  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();
  const clickedTicketMultipleLocalization = useRef();
  const handleOpenMultipleLocalizationDialog = useCallback(
    (e, ticketId) => {
      e.stopPropagation();
      clickedTicketMultipleLocalization.current = ticketSlaDataLocaly.find(
        (ticket) => ticket.id === ticketId
      ).locations;
      onOpenMultipleLocalizationDialog();
    },
    [onOpenMultipleLocalizationDialog, ticketSlaDataLocaly]
  );

  const handleCloseMultipleLocalizationDialog = () => {
    clickedTicketMultipleLocalization.current = null;
    onCloseMultipleLocalizationDialog();
  };

  const isLoading =
    ticketSlaDataLocaly === undefined ||
    slaDelayTimeByCount === undefined ||
    slaDelayTimeRanges === undefined ||
    ticketSlaFilteringData.loading;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_RR}
    >
      <DefaultPageWrapper titleKey={"rr_panel"}>
        <LoaderWrapper showLoader={isLoading}>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={1}
          >
            <Grid item xs={12}>
              <RRPanelToolBar />
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={1}
              justifyContent={"flex-end"}
            >
              <Grid item xs={2}>
                <SelectBarPieChartButton
                  selectedFilterKey={selectedChart}
                  onChangeFilterValue={setSelectedChart}
                />
              </Grid>
              {selectedChart === "bar_chart" && slaDelayTimeByCount && (
                <Grid item xs={12} style={{ height: "50vh" }}>
                  <VerticalBarChart
                    data={ChartService.prepareSortedDataFromDict({
                      data: slaDelayTimeByCount,
                      sortFn: (a, b) => parseInt(a) - parseInt(b),
                      backgroundColorFn:
                        ChartService.prepareBackgroundColorsForSlaData,
                    })}
                    legendDisplay={false}
                    titleDisplay={false}
                    onClickBar={handleClickBarChart}
                  />
                </Grid>
              )}
              {selectedChart === "pie_chart" && slaDelayTimeRanges && (
                <Grid item xs={12} style={{ height: "50vh" }}>
                  <PieChart
                    data={{
                      datasets: [
                        {
                          data: Object.values(slaDelayTimeRanges).map(
                            (slaRange) => slaRange.count
                          ),
                          backgroundColor: Object.values(
                            slaDelayTimeRanges
                          ).map((slaRange) => slaRange.color),
                        },
                      ],
                      labels: Object.keys(slaDelayTimeRanges).map(
                        (slaDelayKey) => t(`page.rr_panel_page.${slaDelayKey}`)
                      ),
                    }}
                    legendDisplay={false}
                    dataLabelsUnit={t("page.rr_panel_page.ticket_count")}
                    titleDisplay={false}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <UniversalToolBarWithDialogs
                  pageName={props.pageName}
                  tableConfig={tableConfig}
                  filteringData={ticketSlaFilteringData}
                  refreshTable={refreshTable}
                  onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
                  resetPageNumber={resetPageNumber}
                  hiddenColumnsForTables={hiddenColumnsForTables}
                  setHiddenColumnsForTables={setHiddenColumnsForTables}
                  columnsOrdersForTables={columnsOrdersForTables}
                  setColumnsOrdersForTables={setColumnsOrdersForTables}
                  mdGridExtraButton={2.5}
                  extraButtonList={[
                    <OpenCloseFilterButton
                      openCloseFilterLabel={t(
                        "page.feedback_management_page.tenant_rr_all"
                      )}
                      closeFilterLabel={t(
                        "page.feedback_management_page.tenat_rr_not_visibility"
                      )}
                      openFilterLabel={t(
                        "page.feedback_management_page.tenat_rr_visibility"
                      )}
                      openFilterKey={"tenant_rr_visibility"}
                    />,
                  ]}
                  extraFilterOptions={{
                    includeExtraFilter: true,
                    extraFilterKey: "tenant_rr_visibility",
                    extraFilterName: t(
                      "page.feedback_management_page.tenat_rr_visibility"
                    ),
                    extraFilterType: BOOLEAN_FIELD_TYPE,
                    extraFilterFieldConfig: {
                      valuesAsBool: true,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {hiddenColumnsForTables && columnsOrdersForTables && (
                  <TicketTable
                    showCleanFilterIcon={true}
                    showFilters={true}
                    showDetailsIcon={true}
                    data={ticketSlaDataLocaly}
                    filteringData={ticketSlaFilteringData}
                    countRecords={countRecords}
                    page={page}
                    onPageChange={handleChangePageWithSearchParams}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    resetPageNumber={resetPageNumber}
                    tableConfig={TICKET_SLA_TABLE_FIELDS_CONFIG}
                    hiddenColumns={
                      hiddenColumnsForTables[TICKET_SLA_TABLE_NAME]
                    }
                    columnsOrders={
                      columnsOrdersForTables[TICKET_SLA_TABLE_NAME]
                    }
                    style={{ maxHeight: "75vh" }}
                    showExportToFileButton={true}
                    exportToFileUrl={exportTicketsSlaToFileUrl}
                    exportToFileHeaders={ticketSlaExportHeaders}
                    exportToFileSearchParams={searchParams}
                    exportToFileFileName={`${t(
                      "dialog.ticket_sla_dialog.tickets"
                    ).replace(" ", "_")}.xlsx`}
                    initialExpandedFilterHeader={true}
                    itemDetailsLinkPrefix={"ticket_details"}
                    onClickMultipleLocationAlert={
                      handleOpenMultipleLocalizationDialog
                    }
                    mdGridExtraButton={2.5}
                    extraButtonList={[
                      <OpenCloseFilterButton
                        openCloseFilterLabel={t(
                          "page.feedback_management_page.tenant_rr_all"
                        )}
                        closeFilterLabel={t(
                          "page.feedback_management_page.tenat_rr_not_visibility"
                        )}
                        openFilterLabel={t(
                          "page.feedback_management_page.tenat_rr_visibility"
                        )}
                        openFilterKey={"tenant_rr_visibility"}
                      />,
                    ]}
                  />
                )}
              </Grid>
              {openMultipleLocalizationDialog &&
                clickedTicketMultipleLocalization.current && (
                  <MultipleLocalizationDialog
                    open={openMultipleLocalizationDialog}
                    onClose={handleCloseMultipleLocalizationDialog}
                    localizationData={clickedTicketMultipleLocalization.current}
                    itemType={"ticket"}
                  />
                )}
            </Grid>
          </Grid>
        </LoaderWrapper>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
