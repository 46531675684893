import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper"
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { NAVIGATION_DRAWER_TYPE_ADMIN } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import PermissionDialog from "../../components/dialog/PermissionDialog";
import FilterTable from "../../components/table/FilterTable";
import { centerVericalAlignIconStyle } from "../../helpers/styles";
import { USER_PERMISSION_TABLE_CONFIG } from "./TableConfig";
import { useSearchParams } from "react-router-dom";
import useDialog from "../../hooks/useDialog";
import { useEmptyArrayMemo } from "../../hooks/useEmptyArrayMemo";
import React, {useEffect, useCallback, useState } from "react";
import useUserService from "../../services/userService";
import { useAsync } from "../../hooks/useAsync";
import TableService from "../../services/tableService";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import usePermissionService from "../../services/permissionService";

export default function AdminPermissionsPage(props) {
  const { pageName } = props;
  const { t } = useTranslation();
  const [permissionsLocal, setPermissionsLocal] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRowData, setSelectedRowData] = useState();
  const [dialogType, setDialogType] = useState();

  const {
    getUserSelectList
  } =useUserService()

  const {
    getNonWarehousePermissionList
  } = useUserService();

  const {
    getOtherPermissionsSelectList
  } = usePermissionService()


  const permissionList = useAsync(() =>getNonWarehousePermissionList(searchParams),
[searchParams])  

const userList = useAsync(() =>getUserSelectList({'is_external' : false}),
[searchParams])

const permissionsList = useAsync(getOtherPermissionsSelectList)

  useEffect(() => {
    if (permissionList.loading) {
      return;
    }
    setPermissionsLocal(permissionList.value);

  }, [permissionList.loading]);


  const [
    openUserWarehousePermDialog,
    onOpenUserWarehousePermDialog,
    onCloseUserWarehousePermDialog
  ] = useDialog();


  const { emptyArrayMemo } = useEmptyArrayMemo()

  const handleOpenPermissionDialog = useCallback(
    (e, rowId, dialogType) => {
      e.stopPropagation();
      setSelectedRowData(permissionsLocal.find(perm => perm.id === rowId))
      onOpenUserWarehousePermDialog();
      setDialogType(dialogType)
    },
    [selectedRowData, permissionsLocal, dialogType, openUserWarehousePermDialog]
  );

  const handleClosePermissionDialog  = useCallback(
    () => {
      setSelectedRowData(undefined)
      onCloseUserWarehousePermDialog();
      setDialogType(undefined)
    },
    [selectedRowData, dialogType, openUserWarehousePermDialog]
  );

  const isLoading = false

  const data = React.useMemo(
    () =>

      TableService.getPreparedDataForCollapsibleTable(
        USER_PERMISSION_TABLE_CONFIG,
        permissionsLocal ? permissionsLocal : emptyArrayMemo,
        emptyArrayMemo,
        emptyArrayMemo,
        handleOpenPermissionDialog
      ),

    [permissionsLocal, USER_PERMISSION_TABLE_CONFIG]
  );

 

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_ADMIN}
    >
      <DefaultPageWrapper titleKey={"admin_permissions"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
          marginTop={1}
        >
          <Grid item xs={12} sm={2}>
            <Button
              fullWidth
              variant="contained"
              size="small"
              startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
              onClick={onOpenUserWarehousePermDialog}
            >
              {t("page.admin_permissions_page.add_permission")}
            </Button>
          </Grid>
          <Grid item sm={9} />
          <Grid item xs={12}>
            <FilterTable
              data={data}
              showCheckbox={false}
              showCleanFilterIcon={true}
              filteringData={props.filteringData}
              style={{ maxHeight: "vh" }}
              tableConfig={USER_PERMISSION_TABLE_CONFIG}
              showContextMenu={false}
              withPagination={false}
              headersConfig={TableService.getHeadersConfigForCollapsibleTable(
                USER_PERMISSION_TABLE_CONFIG,
                userList?.value || emptyArrayMemo,
                emptyArrayMemo,
                emptyArrayMemo,
              )}
            />
          </Grid>
          {
            openUserWarehousePermDialog &&
            <PermissionDialog
              open={openUserWarehousePermDialog}
              onClose={handleClosePermissionDialog}
              onRefetchData={permissionList.refetch}
              userId={selectedRowData?.user_id}
              permissions={selectedRowData?.permissions}
              dialogType= {dialogType}
              permissionType={"other"}
              usersList={userList?.value || emptyArrayMemo}
              permissionKinds={permissionsList?.value || emptyArrayMemo}
            />
          }
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
