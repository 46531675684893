import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

function AddButton(props) {
  return (
    <IconButton style={props.iconButtoStyle} onClick={props.onClick}>
      <ControlPointOutlinedIcon
        fontSize="medium"
        // fontSize={props.iconFontSize}
        sx={{ color: props.iconColor }}
      />
    </IconButton>
  );
}
AddButton.propTypes = {
  onClick: PropTypes.func,
  iconButtoStyle: PropTypes.object,
  iconFontSize: PropTypes.oneOf(["small", "medium", "large"]),
  iconColor: PropTypes.string,
};
AddButton.defaultProps = {
  onClick: () => {},
  iconFontSize: "large",
  iconColor: "var(--primary)",
};
export default AddButton;
