import { useState, useEffect, useCallback } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import useDialog from "../../hooks/useDialog";
import { NAVIGATION_DRAWER_TYPE_OFFERS, NAVIGATION_DRAWER_TYPE_WAREHOUSES } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import CreateItemDialog from "../../components/dialog/CreateItemDialog";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { centerVericalAlignIconStyle } from "../../helpers/styles";
import BaseBox from "../../components/base/BaseBox/baseBox";
import ItemExtendForm from "../../components/form/ItemExtendForm/ItemExtendForm";
import PropTypes from "prop-types";
import { useForm } from "../../hooks/useForm";
import AutocompleteField from "../../components/field/AutocompleteField";
import useOfferService from "../../services/offerService";
import StockTransactionsRelatedTablesTabs from "../../components/tabs/StockTransactionsRelatedTablesTabs";
import MaterialParamRelatedTablesTabs from "../../components/tabs/MaterialParamRelatedTablesTabs";
import useCertsData from "../../hooks/useCertsData";
import useEnclosuresData from "../../hooks/useEnclosuresData";
import PartParameterDialog from "../../components/dialog/PartParameterDialog";
import useItemService from "../../services/itemService";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import { getErrorMsg } from "../../helpers/methods";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import BoxWithTypography from "../../components/box/BoxWithTypography/BoxWithTypography";
import ItemRecord from "../../components/other/ItemRecord/ItemRecord";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import SubcontractorDetailsDialog from "../../components/dialog/SubcontractorDetailsDialog/SubcontractorDetailsDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import useItemFormValidation from "../../hooks/useItemFormValidation";
import useLocationService from "../../services/locationService";
import SelectItemDialog from "../../components/dialog/SelectItemDialog";
import { MATERIAL_MAIN_BRANCH_NAME, MANAGE_PARTS_PERMISSION} from "../../helpers/constants";
import useCheckPermission from "../../hooks/usePermission";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog/confirmationDialog";
import DeleteIcon from '@mui/icons-material/Delete';

export default function MaterialPassportPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm({});
  const {
    unfillRequiredFields,
    locationRecordsRef,
    checkIfRequiredFieldsAreFill
  } = useItemFormValidation("part", formValue);

  const snackbarAlert = useSnackbarAlert();

  const { getChosenHighestLevelObject } = useLocationService()
  const [partParamterLocal, setPartParamterLocal] = useState([])
  const [selectedPartId, setSelectedPartId] = useState(undefined);
  const [selectedParameterValue, setSelectedParameterValue] = useState(undefined);
  const [editMode, setEditMode] = useState(false)


  const { getPartDetailsData,
    getPartInfoSelectListData,
    getPartFilteringData,
    editPartData,
    removePart } = useOfferService();

  const { getPartParametersList,
    createParameterValue,
    updateParameterValue,
    removeParameterValue,
    prepareItemDataFromBackendForItemRecordComponent,
    getEmptyItemElement,
    getItemRecordById } = useItemService()

  const [hasPermissionToManage] = useCheckPermission(MANAGE_PARTS_PERMISSION )
  


  const createParameterValueFn = useAsyncFn(createParameterValue)
  const updateParameterValueFn = useAsyncFn(updateParameterValue)
  const removeParameterValueFn = useAsyncFn(removeParameterValue)
  const removePartFn = useAsyncFn(removePart)
  const updatePartDataFn = useAsyncFn(editPartData)
  const getItemRecordByIdFn = useAsyncFn(getItemRecordById)
  const [items, setItems] = useState(getEmptyItemElement());


  const {
    certs,
    updateCerts,
    saveCerts,
    hideCerts,
    onDownloadCert,
    onPreviewCert
  } = useCertsData(selectedPartId)


  const {
    enclosures,
    saveEnclosures,
    onPreviewEnclosure,
    onDownloadEnclosure,
    deleteEnclosureForPart,
    updatePartEnclosure,
  } = useEnclosuresData(selectedPartId, undefined, "part");

  const partDetailsData = useAsync(() => {
    if (selectedPartId) {
      return getPartDetailsData(selectedPartId);
    }
    return Promise.resolve({});
  },
    [selectedPartId]);


  const partParametersData = useAsync(() => {
    if (selectedPartId) {
      return getPartParametersList(selectedPartId);
    }
    return Promise.resolve([]);
  },
    [selectedPartId]);


  useEffect(() => {
    if (partDetailsData.loading) {
      return;
    }
    if (partDetailsData.value) {
      setFormValue(partDetailsData.value);
      setItems(prepareItemDataFromBackendForItemRecordComponent(partDetailsData.value.item))
    }
  }, [partDetailsData.loading]);




  useEffect(() => {
    if (partParametersData.loading) {
      return;
    }
    if (partParametersData.value) {
      setPartParamterLocal(partParametersData.value);
    }
  }, [partParametersData.loading]);

  const onAddParameter = (paramValuesData) => {
    if (paramValuesData.id) {
      updateParameterValueFn
        .execute(paramValuesData.id, paramValuesData)
        .then((result) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.parameter_value_updated")
          );
          onCloseAddPartParameterDialog()
          partParametersData.refetch()
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(error.data),
            t("snackbar_alert.occurred_error_during_parameter_value_updating")
          );
        })
    } else {
      createParameterValueFn
        .execute(paramValuesData)
        .then((result) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.parameter_value_added")
          );
          onCloseAddPartParameterDialog()
          partParametersData.refetch()
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(error.data),
            t("snackbar_alert.occurred_error_during_parameter_value_adding")
          );
        })
    }
  }

  const onHidePramaterValue = (parameterValueId) => {
    let parameter = getParamterValueById(parameterValueId)
    removeParameterValueFn
      .execute(parameter.id, prepapreParameterToHide(parameter))
      .then((result) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.parameter_value_removed")
        );
        onCloseAddPartParameterDialog()
        partParametersData.refetch()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(error.data),
          t("snackbar_alert.occurred_error_during_parameter_value_removing")
        );
      })
  };


  const [
    openCreateItemDialog,
    onOpenCreateItemDialog,
    onCloseCreateItemDialog,
  ] = useDialog();

  const [
    openAddPartParameterDialog,
    onOpenAddPartParameterDialog,
    onCloseAddPartParameterDialog,
  ] = useDialog();

  const [
    openConfirmationDialog,
    onOpenConfirmationDialog,
    onCloseConfirmationDialog,
  ] = useDialog();

  const [
    openSelectItemDialog,
    onOpenSelectItemDialog,
    onCloseSelectItemDialog,
  ] = useDialog();

  const onChangePartFromList = useCallback((e, value_object, value_key, state_value_name) => {
    setSelectedPartId(value_object[value_key])
    onChangeAutocompleteFieldWithObjectOptions(e, value_object, value_key, state_value_name)
    setEditMode(false)
  }, [selectedPartId])


  const getParamterValueById = (paramValueId) => {
    return partParamterLocal.find(param => param.id === paramValueId)
  }

  const getItemRecord = useCallback((selectedItemId) => {
    getItemRecordByIdFn.execute(selectedItemId)
      .then((result) => {
        setItems(prepareItemDataFromBackendForItemRecordComponent(result.item))
        onCloseSelectItemDialog()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_get_item_data")
        );
      })

  }, [items])

  const onUpdatePramaterValue = (parameterValueId) => {
    setSelectedParameterValue(getParamterValueById(parameterValueId))
    onOpenAddPartParameterDialog()
  };

  const prepapreParameterToHide = useCallback((parameterValue) => {
    return {
      id: parameterValue.id,
      parval_active: false,
    }

  }, [])

  const closePramaeterValuesDialog = useCallback(() => {
    setSelectedParameterValue(undefined)
    onCloseAddPartParameterDialog()
  }, [selectedParameterValue])

  const onClickOpenParametersPartDialog = useCallback(() => {
    setSelectedParameterValue(undefined)
    onOpenAddPartParameterDialog()
  }, [selectedParameterValue])

  const partsInfoSelectList = useAsync(getPartInfoSelectListData)
  const partFilteringData = useAsync(getPartFilteringData)

  const isLoading = partDetailsData.loading || partFilteringData.loading

  const partParamsContextMenuActions = [
    {
      label: t("page.material_passport_page.edit_parameter"),
      callback: (parameterValueId) => onUpdatePramaterValue(parameterValueId),
      icon: <EditIcon fontSize="small" />,
    },
    {
      label: t("page.material_passport_page.remove_parameter"),
      callback: (parameterValueId) => onHidePramaterValue(parameterValueId),
      icon: <DeleteOutlineIcon fontSize="small" />,
    },
  ];

  const onChangeLevelItem = (item, index = 0) => {
    var items_temp = [...items];
    items_temp[index] = item;
    setItems(item);
  };

  const onUpdateData = () => {
    if (checkIfRequiredFieldsAreFill()) {
      formValue['item'] = getChosenHighestLevelObject(items)
      updatePartDataFn
        .execute(formValue.id, formValue)
        .then((result) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.part_data_updated")
          );
          setEditMode(false)
          partDetailsData.refetch()
          partsInfoSelectList.refetch()
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(error.data),
            t("snackbar_alert.occurred_error_during_part_data_updating")
          );
        })
    }
  }

  const removeData = () => {
      removePartFn
        .execute(formValue.id)
        .then((result) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.part_removed")
          );
          setSelectedPartId(undefined)
          setFormValue({})
          partDetailsData.refetch()
          partsInfoSelectList.refetch()
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_part_removing")
          );
        })
  }

  const handleCloseConfirmationDialog = (confirmRemovePart) => {
    onCloseConfirmationDialog();
    if (confirmRemovePart)
      removeData();
  };

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_WAREHOUSES}
      >
      
      <DefaultPageWrapper titleKey={"material_passport"}>
      <LoaderWrapper showLoader={partsInfoSelectList.loading} >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          columnSpacing={2}
          rowSpacing={2}
          style={{ marginBottom: "10px" }}

        >
          <Grid item xs={12} sm={2} >
            <AutocompleteField
              name={`id`}
              label={t(`form.item_form.choice_material`)}
              value={formValue[`id`]}
              options={partsInfoSelectList.value}
              isObjectOption={true}
              optionLabelKey={"part_info"}
              addNewValue={false}
              onChange={onChangePartFromList}
              disabled={props.readOnly}
            />
          </Grid>
          <Grid item xs={12} sm={4} />
          <Grid item xs={12} sm={2} textAlign={{ xs: "center", sm: "right" }}>
            {selectedPartId && !editMode && hasPermissionToManage &&
              <Button
                fullWidth
                variant="contained"
                size="small"
                color="error"
                startIcon={<DeleteIcon sx={centerVericalAlignIconStyle} />}
                onClick={onOpenConfirmationDialog}
              >
                {t("bar.warehouse_tool_bar.remove_material")}
              </Button>
              }
          </Grid>
          <Grid item xs={12} sm={2} textAlign={{ xs: "center", sm: "right" }}>
            {selectedPartId && !editMode && hasPermissionToManage &&
              <Button
                fullWidth
                variant="contained"
                size="small"
                startIcon={<ModeEditOutlineIcon sx={centerVericalAlignIconStyle} />}
                onClick={() => setEditMode(true)}
              >
                {t("bar.warehouse_tool_bar.edit_material")}
              </Button>
              }
          </Grid>
          {hasPermissionToManage &&
            <Grid item xs={12} sm={2} textAlign={{ xs: "center", sm: "right" }}>
              <Button
                fullWidth
                variant="contained"
                size="small"
                startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
                onClick={onOpenCreateItemDialog}
              >
                {t("bar.warehouse_tool_bar.add_material")}
              </Button>
            </Grid>}
        </Grid>
        </LoaderWrapper>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          columnSpacing={2}
          rowSpacing={2}
          style={props.style}

        >
          <LoaderWrapper showLoader={isLoading} >
            {selectedPartId ? (
              <>
                <Grid item xs={12} >
                  <ItemExtendForm
                    itemType={'part'}
                    formValue={formValue}
                    itemId={selectedPartId}
                    readOnly={!editMode}
                    setFormValue={setFormValue}
                    onChange={onChange}
                    onChangeAutocompleteFieldWithObjectOptions={onChangeAutocompleteFieldWithObjectOptions}
                    partChoiceSelectData={partFilteringData}
                    unfillRequiredFields={unfillRequiredFields}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BoxWithTypography
                    style={{ padding: "10px" }}
                    label={t(`form.item_form.purchase_tree`)}
                    component={"div"}
                  >
                    <ItemRecord
                      ref={locationRecordsRef}
                      itemLevels={items}
                      onChangeItem={onChangeLevelItem}
                      readOnly={!editMode}
                      onClickSearchItem={onOpenSelectItemDialog}
                      defaultFirstLevelItemName={MATERIAL_MAIN_BRANCH_NAME}
                    />
                  </BoxWithTypography>
                </Grid>
                <Grid item xs={12} sm={4} />
                {editMode &&
                  <Grid item xs={12} sm={4}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      fullWidth
                      loading={props.isLoading}
                      onClick={onUpdateData}
                    >
                      {t("save_changes")}
                    </LoadingButton>
                  </Grid>}

                <Grid item xs={12} sm={4} />
                <Grid item xs={12} sm={8}>
                  < StockTransactionsRelatedTablesTabs
                    partId={selectedPartId}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  < MaterialParamRelatedTablesTabs
                    readOnly={false}
                    onParamtersRefetchData={partParametersData.refetch}
                    onHandleAddNewParameter={onClickOpenParametersPartDialog}
                    partsParamsData={partParamterLocal}
                    showParametersContextMenu={true}
                    parametersContextMenuActions={partParamsContextMenuActions}
                    certificates={certs}
                    previewCertificate={onPreviewCert}
                    downloadCertificate={onDownloadCert}
                    updateCertificate={updateCerts}
                    deleteCertificate={hideCerts}
                    addCertificate={saveCerts}
                    partId={selectedPartId}
                    enclosures={enclosures}
                    previewEnclosure={onPreviewEnclosure}
                    downloadEnclosure={onDownloadEnclosure}
                    updateEnclosure={updatePartEnclosure}
                    deleteEnclosure={deleteEnclosureForPart}
                    addEnclosure={saveEnclosures}
                  />
                </Grid>

              </>
            ) : (
              <Grid item xs={12} >
                <BaseBox>
                  <Typography
                    textAlign="center"
                    variant="overline"
                    display="block"
                    gutterBottom
                    style={{ margin: 0, fontSize: "20px", color: "var(--primary)" }}
                  >
                    {t("form.item_form.select_material_first")}
                  </Typography>
                </BaseBox>
              </Grid>
            )}
          </LoaderWrapper>
        </Grid>

        {openCreateItemDialog && (
          <CreateItemDialog
            open={openCreateItemDialog}
            onClose={onCloseCreateItemDialog}
            onRefetchData={partsInfoSelectList.refetch}
            itemType={'part'}
          />
        )}

        {openAddPartParameterDialog && (
          <PartParameterDialog
            open={openAddPartParameterDialog}
            onClose={closePramaeterValuesDialog}
            onRefetchData={partsInfoSelectList.refetch}
            partId={selectedPartId}
            onAddParameter={onAddParameter}
            isLoading={createParameterValueFn.loading}
            parameterValueData={selectedParameterValue}
          />
        )}
      
        {openSelectItemDialog &&
          <SelectItemDialog
            open={openSelectItemDialog}
            onClose={onCloseSelectItemDialog}
            onSubmit={getItemRecord}
          />
        }

        {openConfirmationDialog && (
          <ConfirmationDialog
            open={openConfirmationDialog}
            onNo={() => handleCloseConfirmationDialog(false)}
            onYes={() => handleCloseConfirmationDialog(true)}
            title={t("page.material_passport_page.alert")}
            content={t("page.material_passport_page.are_you_sure_to_remove_part")}
          />
        )}
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
MaterialPassportPage.propTypes = {
  style: PropTypes.object,
};

MaterialPassportPage.defaultProps = {

};


