import { getSortedObjectKeysValuesByKey } from "../helpers/methods";
import { getStyleForSLACell } from "../helpers/styles";

const ChartService = {
  prepareDataFromDict: function (data, backgroundColor) {
    return {
      datasets: [
        {
          data: Object.values(data),
          backgroundColor: backgroundColor,
        },
      ],
      labels: Object.keys(data),
    };
  },

  prepareSortedDataFromDict: function ({
    data,
    sortFn,
    backgroundColor,
    backgroundColorFn,
  } = {}) {
    const { keys, values } = getSortedObjectKeysValuesByKey(data, sortFn);
    const backgroundColorValue = backgroundColorFn
      ? backgroundColorFn(keys, values)
      : backgroundColor;
    return {
      datasets: [
        {
          data: values,
          backgroundColor: backgroundColorValue,
        },
      ],
      labels: keys,
    };
  },

  prepareRedGreenBackgroundColorsGradientForData: function (data) {
    const dataLength = Object.keys(data).length;
    const colorStep = Math.round(255 / (dataLength - 1));

    let backgroundColors = [];
    for (let i = 0; i <= dataLength; i += 1) {
      backgroundColors.push(
        `rgba(${255 - i * colorStep}, ${i * colorStep}, 0, 0.5)`
      );
    }
    return backgroundColors;
  },

  prepareBackgroundColorsForSlaData: function (keys, values) {
    return keys.map((key) => getStyleForSLACell(key, true));
  },

  prepareDataForSingleOrMultiGroupsChart: function (
    chartData,
    isMultipleGroupsChart
  ) {
    if (isMultipleGroupsChart) {
      return Object.values(chartData).map((groupData) => {
        let groupParams = {
          label: groupData.group_name,
          data: Object.values(groupData.data).map(
            (labelData) => labelData.count
          ),
        };
        if (groupData.color) groupParams.backgroundColor = groupData.color;

        return groupParams;
      });
    } else {
      return [
        {
          data: Object.values(chartData).map((labelData) => labelData.count),
        },
      ];
    }
  },

  getPercentRatioLabel: function (numerator, denominator) {
    if (numerator === 0 || denominator === 0) {
      return "";
    }

    // return `${numerator}/${denominator} (${(
    //   (numerator / denominator) *
    //   100
    // ).toFixed(2)}%)`;

    let dataLabelFirstRow = `${numerator}/${denominator}`;
    let dataLabelSecondRow = `(${((numerator / denominator) * 100).toFixed(
      2
    )}%)`;

    let dataLabelSecondRowPadStart = Math.ceil(
      dataLabelFirstRow.length - dataLabelSecondRow.length
    );
    if (dataLabelSecondRowPadStart > 0) {
      dataLabelSecondRow = dataLabelSecondRow.padStart(
        dataLabelSecondRow.length + dataLabelSecondRowPadStart,
        " "
      );
    }

    return `${dataLabelFirstRow}\n${dataLabelSecondRow}`;
  },

  getDataSetArrayFromContext: function (context) {
    const datasetArray = [];
    context.chart.data.datasets.forEach((dataset) => {
      if (dataset.data[context.dataIndex] !== undefined) {
        datasetArray.push(dataset.data[context.dataIndex]);
      }
    });
    return datasetArray;
  },

  getChartDataLabelsFormaterForTotalPercentRatioFn: function (
    context,
    totalCount
  ) {
    const datasetArray = this.getDataSetArrayFromContext(context);

    return context.datasetIndex === datasetArray.length - 1
      ? this.getPercentRatioLabel(
          datasetArray.reduce((sum, curr) => sum + curr, 0),
          totalCount
        )
      : "";
  },

  getChartTooltipFormaterForPercentRatioFn: function (value, context) {
    const datasetArray = this.getDataSetArrayFromContext(context);

    return this.getPercentRatioLabel(
      value.y,
      datasetArray.reduce((sum, curr) => sum + curr, 0)
    );
  },
};

export default ChartService;
