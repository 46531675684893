import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FilterTable from "../../table/FilterTable";
import React, { useEffect, useImperativeHandle, useState } from "react";
import TableService from "../../../services/tableService";
import PropTypes from "prop-types";
import { UNFINISHED_TASK_TABLE_FIELDS_CONFIG } from "../../../page/PlannerPage/PageTablesConfig";
import useTaskService from "../../../services/taskService";
import { useAsync } from "../../../hooks/useAsync";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useMemo } from "react";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import OpenCloseFilterButton from "../../button/OpenCloseFilterButton";
// import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../hooks/useFilterSearchParams";

const UNFINISHED_TASKS_TABLE_NAME = "unfinished_tasks";

const PlannerUnfinishedTasks = React.forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    refetchTaskWaitingRoom: taskWaitingRoom.refetch,
  }));

  const [searchParams] = useSearchParams();
  const [unfinishedTasksSearchParams, setUnfinishedTasksSearchParams] =
    useState({});
  const { setNewItemSearchParamsIfAreChanged } =
    useFilterSearchParams(searchParams);

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      UNFINISHED_TASKS_TABLE_NAME,
      unfinishedTasksSearchParams,
      setUnfinishedTasksSearchParams
    );
  }, [searchParams]);

  const [tasks, setTasks] = useState();

  const { getTaskWaitingRoomFilteringData, getTaskWaitingRoom } =
    useTaskService();

  const waitingRoomFilteringData = useAsync(getTaskWaitingRoomFilteringData);
  const taskWaitingRoom = useAsync(() => {
    return getTaskWaitingRoom(unfinishedTasksSearchParams);
  }, [unfinishedTasksSearchParams]);

  const filteringData = useMemo(
    () =>
      waitingRoomFilteringData.value ? waitingRoomFilteringData : undefined,
    [waitingRoomFilteringData]
  );

  const getTaskListWithAllocated = useCallback(() => {
    var tasksList = props.newAllocatedTasks;
    if (taskWaitingRoom.value) {
      tasksList = [...tasksList, ...taskWaitingRoom.value];
    }
    return tasksList.filter((x) => !props.plannedTaskIds.includes(x.id));
  }, [taskWaitingRoom.value, props.newAllocatedTasks, props.plannedTaskIds]);

  useEffect(() => {
    let tasksTemp;
    if (!taskWaitingRoom.loading) {
      tasksTemp = TableService.getPreparedDataForCollapsibleTable(
        props.tableConfig,
        getTaskListWithAllocated(),
        props.hiddenColumns,
        props.columnsOrders
      );
    }
    if (tasksTemp) setTasks(tasksTemp);
  }, [
    props.hiddenColumns,
    props.tableConfig,
    getTaskListWithAllocated,
    taskWaitingRoom.loading,
  ]);

  const onTaskDrop = (e, _id) => {
    props.onTaskDrop(
      e,
      getTaskListWithAllocated().find((x) => x.id === _id)
    );
  };

  return (
    <LoaderWrapper
      showLoader={waitingRoomFilteringData.loading || tasks === undefined}
    >
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <TopToolBar />
        </Grid>
        <Grid item xs={12}>
          <FilterTable
            data={tasks || []}
            headersConfig={TableService.getHeadersConfigForCollapsibleTable(
              props.tableConfig,
              filteringData,
              props.hiddenColumns,
              props.columnsOrders
            )}
            draggableRows={props.draggableRows}
            onDragEndRow={onTaskDrop}
            onDragStartRow={props.onDragStartRow}
            withPagination={false}
            showCheckbox={false}
            style={{ maxHeight: "300px" }}
            filterPrefix={UNFINISHED_TASKS_TABLE_NAME}
            onClickRow={props.onClickTableRow}
            filteringData={filteringData}
            contextMenuActions={props.contextMenuTableActions}
            showCleanFilterIcon={true}
            selectedItemId={props.selectedTaskId}
          />
        </Grid>
      </Grid>
    </LoaderWrapper>
  );
});

const TopToolBar = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      columnSpacing={1}
      rowSpacing={2}
      style={{ marginBlock: "5px", minHeight: "56.8px" }}
    >
      <Grid item xs={12} md={6}>
        <Typography textAlign={"left"} variant="h6" color={"primary"}>
          {t("other.planner_unfinished_tasks.waiting_room")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <OpenCloseFilterButton
          filterPrefix={UNFINISHED_TASKS_TABLE_NAME}
          openFilterKey={"is_allocated"}
          openCloseFilterLabel={t(
            "other.planner_unfinished_tasks.allocated_unfinished"
          )}
          openFilterLabel={t("other.planner_unfinished_tasks.allocated")}
          closeFilterLabel={t("other.planner_unfinished_tasks.unfinished")}
        />
      </Grid>
    </Grid>
  );
};

PlannerUnfinishedTasks.propTypes = {
  tableConfig: PropTypes.object,
  onOrderDrop: PropTypes.func,
  onDragStartRow: PropTypes.func,
  pageName: PropTypes.string,
  style: PropTypes.object,
  filterPrefix: PropTypes.string,
  onClickTableRow: PropTypes.func,
  hiddenColumns: PropTypes.array,
  columnsOrders: PropTypes.array,
  contextMenuTableActions: PropTypes.array,
  newAllocatedTasks: PropTypes.array,
  onTaskDrop: PropTypes.func,
  plannedTaskIdsFromWaitingRoom: PropTypes.array,
};

PlannerUnfinishedTasks.defaultProps = {
  tableConfig: UNFINISHED_TASK_TABLE_FIELDS_CONFIG,
  pageName: "planner_page",
  hiddenColumns: [],
  newAllocatedTasks: [],
  plannedTaskIdsFromWaitingRoom: [],
};

export default PlannerUnfinishedTasks;
