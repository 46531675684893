import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import SelectField from "../../field/SelectField/SelectField";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import TrueFalseSelectField from "../../field/TrueFalseSelectField"
import { getExposureTypesList } from "../../../helpers/methods";

export default function EnclosureDetailsForm(props) {
  const { t } = useTranslation();

  const getTranslatedEnclosurePartList = (list) => {
    let translatedList = []
    if (list) {
      for (let kind of list) {
        translatedList.push({ 'key': kind, 'name': t(`form.add_enclosure_form.${kind}`) })
      }
    }
    return translatedList
  }


  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12} sm={12}>
        <TextFieldFormControl
          disabled
          value={props.formValue.enclosure_name}
          label={t("form.enclosure_details_form.enclosure_name")}
          style={{ textAlign: "center" }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="enclosure_note"
          label={t("form.enclosure_details_form.note")}
          value={props.formValue.enclosure_note}
          multiline={true}
          rows={4}
          onChange={props.onChange}
          disabled={!props.editable}
        />
      </Grid>
      {props.showSubcontractorSelect &&
        <Grid item xs={12} sm={4}>
          <TrueFalseSelectField
            name="enclosure_subcontractor"
            label={t("form.enclosure_details_form.visible_for_subcontractor")}
            value={props.formValue.enclosure_subcontractor}
            onChange={props.onChange}
            valuesAsBool={true}
            disabled={!props.editable}
            addEmptyOptions={false}
          />
        </Grid>
      }
      {props.showEnclosureType &&
        <Grid item xs={12} sm={4}>
          <SelectField
            name="enclosure_type"
            label={t("form.enclosure_details_form.enclosure_type")}
            value={props.formValue.enclosure_type}
            options={getExposureTypesList()}
            optionLabel={"name"}
            optionKey={'key'}
            addNewValue={false}
            onChange={props.onChange}
            disabled={!props.editable}
            variant="outlined"
          />
        </Grid>
      }
      {props.showEnclosurePartKind &&
        <Grid item xs={12} sm={6}>
          <SelectField
            name="enpa_kind_part"
            label={t("form.add_enclosure_form.enclosure_part_kind")}
            value={props.formValue.enpa_kind_part}
            options={getTranslatedEnclosurePartList(props.enclosurePartKindList)}
            optionLabel={"name"}
            optionKey={'key'}
            addNewValue={false}
            onChange={props.onChange}
          />
        </Grid>}
      {props.editable &&
        <Grid item xs={12}>
          <Button

            variant="contained"
            onClick={props.onSubmit}
            component="label"
            fullWidth
          >
            {t("form.enclosure_details_form.save_changes")}
          </Button>
        </Grid>
      }
    </Grid>
  );
}

EnclosureDetailsForm.propTypes = {
  onChange: PropTypes.func,
  formValue: PropTypes.object,
  showEnclosureType: PropTypes.bool,
  editable: PropTypes.bool,
  showSubcontractorSelect: PropTypes.bool,
  showEnclosurePartKind: PropTypes.bool
};

EnclosureDetailsForm.defaultProps = {
  formValue: {},
  showEnclosureType: false,
  editable: false,
  showSubcontractorSelect: false,
  showEnclosurePartKind: false
};
