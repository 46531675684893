import Typography from "@mui/material/Typography";
import titleDict from "./titleDict";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function TitleTypography(props) {
  const navigate = useNavigate();

  const [titleElement] = useState(() => {
    return titleDict[props.titleKey];
  });

  const onClick = () => {
    navigate(titleElement.navigateTo);
  };

  const isNavigateTo = () => {
    return titleElement && titleElement.navigateTo;
  };

  return (
    <Typography
      variant="h1"
      className="typography-title"
      color={props.color}
      style={{ cursor: isNavigateTo() ? "pointer" : undefined }}
      onClick={isNavigateTo() ? onClick : undefined}
    >
      {titleElement ? titleElement.label : ""}
    </Typography>
  );
}

TitleTypography.propTypes = {
  titleKey: PropTypes.string,
  color: PropTypes.string,
};

TitleTypography.defaultProps = {
  titleKey: "ticket_app",
  color: "primary",
};
