import "./contextMenu.css";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

const ContextMenu = React.memo((props) => {

  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {

    if (!props.showMenu) return;

    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const disableSecondRightClick = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (contextMenu)
      document.addEventListener("contextmenu", disableSecondRightClick);

    return () =>
      document.removeEventListener("contextmenu", disableSecondRightClick);
  }, [contextMenu]);

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleClickMenuItem = (callback) => {
    callback();
    handleClose();
  };

  if (props.Component === undefined){
    return null
  }
  return (
    <>
      {React.cloneElement(props.Component, {
        onContextMenu: handleContextMenu,
      })}
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        sx={{margin: 0, padding: 0}}
      >
        <MenuList style={{margin: 0, padding: 0}}>
          {props.actions &&
            props.actions.map((action) => {
              if(action.visible !== false){
                return(
              <MenuItem
                className="menu-item"
                key={action.label}
                onClick={() => handleClickMenuItem(action.callback)}
                style={{
                  cursor: "contextMenu",
                  textAlign: "right",
                  marginTop : 0,
                  marginBottom : 0,
                  paddingRight : "10px",
                  paddingLeft: "10px"
                }}
              >
                {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
                <ListItemText>{action.label}</ListItemText>
              </MenuItem>)}
})}
        </MenuList>
      </Menu>
    </>
  );
});

ContextMenu.propTypes = {
  Component: PropTypes.element.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      callback: PropTypes.func,
      icon: PropTypes.element,
      visible: PropTypes.bool,
    })
  ),
  showMenu: PropTypes.bool,
};

ContextMenu.defaultProps = {
  actions: [],
  showMenu: true,
};

export default ContextMenu;
