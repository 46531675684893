import { Grid, Typography } from "@mui/material";
import React from "react";
import AddEnclosureButton from "../../button/AddEnclosureButton";
import EnclosuresWindow from "../../window/EnclosuresWindow";
import PropTypes from "prop-types";
import useDialog from "../../../hooks/useDialog";
import AddEnclosureDialog from "../../dialog/AddEnclosureDialog";
import { useTranslation } from "react-i18next";
import useFileService from "../../../services/fileService";
import AddPartCertificationDialog from "../../dialog/AddPartCertificationDialog";
import AddEditDocsRestrictedDialog from "../../dialog/AddEditDocsRestrictedDialog";
import { DOCS_RESTRICTED_OWNER, DOCS_RESTRICTED_USER } from "../../../helpers/constants";


const
    FileUploadList = React.memo((props) => {
        const { t } = useTranslation();

        const [
            openAddNewEnclosureDialog,
            handleOpenAddNewEnclosureDialog,
            handleCloseAddNewEnclosureDialog,
        ] = useDialog();

        const { showFileByBlobUrl } = useFileService();

        const onSubmitUploadFile = (temporaryFiles, commonData) => {
            let enclosures = []
            for (let enc of temporaryFiles) {
                let enclosure = { ...enc, ...commonData }
                enclosures.push(enclosure)
            }
            handleCloseAddNewEnclosureDialog()
            props.onAddEnclosure(enclosures, commonData)
        }


        const onPreview = (enclosureId, index) => {
            if (enclosureId === undefined) {
                const enclosure = props.enclosures[index]
                showFileByBlobUrl(enclosure.blob_url)
            } else {
                props.onPreviewEnclosure(enclosureId)
            }
        }

        const onCloseAddDocumentDialog = () => {
            handleCloseAddNewEnclosureDialog()
            if (props.refetchDocs) {
                props.refetchDocs()
            }
        }

        const getFileUploadListTitle = () => {
            switch (props.fileType) {
                case "docs":
                    return t("other.file_upload_list.docs")
                case "certs":
                    return t("other.file_upload_list.certificates")
                default:
                    return t("other.file_upload_list.enclosures")
            }
        }

        const AddFileDialogProps = {
            open: openAddNewEnclosureDialog,
            onClose: handleCloseAddNewEnclosureDialog,
            submitButtonLabel: t("add"),
            onSubmit: onSubmitUploadFile,
            fileType: props.fileType,
            multiple: props.multiple
        }

        return (
            <Grid container spacing={1}>
                <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
                    <Typography
                        variant="subtitle2"
                        className="capitalize-first-letter"
                    >
                        {getFileUploadListTitle()}
                    </Typography>
                </Grid>
                <Grid item sm={6} xs={6} textAlign={"end"}>
                    {(!props.readOnly && !props.hideAddButton) &&
                        <AddEnclosureButton
                            variant="contained"
                            color="primary"
                            size={props.sizeAddEnclosureButton}
                            onClick={handleOpenAddNewEnclosureDialog}
                            {...props.addEnclosureButtonProps}
                            fileType={props.fileType}
                        />}
                </Grid>
                <Grid item xs={12}>
                    <EnclosuresWindow
                        enclosureWindowStyle={props.enclosureWindowStyle}
                        enclosures={props.enclosures}
                        showCreator={false}
                        onDownload={props.onDownloadEnclosure}
                        onPreview={onPreview}
                        onDeleteEnclosure={props.onDeleteEnclosure}
                        onUpdateEnclosure={props.onUpdateEnclosure}
                        showAddToFavorite={false}
                        showPublishButton={false}
                        canRemoveEnclosures={props.canRemoveEnclosures}
                        readOnly={props.readOnly}
                        fileType={props.fileType}
                        showDetailsButton={props.showDetailsButton}
                        showSubcontractorSelect={props.showSubcontractorSelect}
                        showEnclosureType={props.showEnclosureType}
                        showEnclosurePartKind={props.showEnclosurePartKind}
                        refetchDocs={props.refetchDocs}
                        availableDocsRestrictedTypes= {props.availableDocsRestrictedTypes}
                    />
                </Grid>
                {openAddNewEnclosureDialog && props.fileType === "enclosure" &&
                    <AddEnclosureDialog
                        showExtendedAddEnclosureDialog={props.showExtendedAddEnclosureDialog}
                        showSubcontractorSelect={props.showSubcontractorSelect}
                        showEnclosureType={props.showEnclosureType}
                        showEnclosurePartKind={props.showEnclosurePartKind}
                        defaultEnclosureType={props.defaultEnclosureType}
                        allowCopyEnclosure={props.allowCopyEnclosure}
                        {...AddFileDialogProps}
                    />}
                {openAddNewEnclosureDialog && props.fileType === "docs" &&
                    <AddEditDocsRestrictedDialog
                        open={openAddNewEnclosureDialog}
                        onClose={handleCloseAddNewEnclosureDialog}
                        docsId={undefined}
                        submitButtonLabel={t("dialog.enclosures_dialog.add_docs")}
                        agreement={props.agreement}
                        post={props.post}
                        ico={props.ico}
                        onSubmit={onCloseAddDocumentDialog}
                        multiple={props.multiple}
                        availableDocsRestrictedTypes={props.availableDocsRestrictedTypes}
                        filesOnMemory={props.filesOnMemory}
                        onSaveOnMemoryFile={props.onAddFile}
                        readOnly={props.readOnly}
                    />
                }
                {openAddNewEnclosureDialog && props.fileType === "certs" &&
                    <AddPartCertificationDialog
                        {...AddFileDialogProps}
                    />}
            </Grid>
        )
    })

FileUploadList.propTypes = {
    enclosures: PropTypes.array,
    addEnclosureButtonProps: PropTypes.object,
    enclosureWindowStyle: PropTypes.object,
    defaultEnclosureType: PropTypes.string,
    onAddEnclosure: PropTypes.func,
    onDeleteEnclosure: PropTypes.func,
    showExtendedAddEnclosureDialog: PropTypes.bool,
    canRemoveEnclosures: PropTypes.bool,
    onUpdateEnclosure: PropTypes.func,
    readOnly: PropTypes.bool,
    fileType: PropTypes.oneOf["enclosure", "docs", "certs"],
    hideAddButton: PropTypes.bool
};

FileUploadList.defaultProps = {
    enclosures: [],
    addEnclosureButtonProps: {},
    enclosureWindowStyle: {},
    showEnclosureType: false,
    canRemoveEnclosures: false,
    readOnly: false,
    fileType: "enclosure",
    hideAddButton: false
};
export default FileUploadList
