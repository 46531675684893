import { useAsync } from "../../../hooks/useAsync";
import useOrderService from "../../../services/orderService";
import BasicDialog from "../../base/BasicDialog";
import AutocompleteField from "../../field/AutocompleteField";
import PropTypes from "prop-types";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useForm } from "../../../hooks/useForm";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const SelectOrderToAddTaskDialog = (props) => {
  const { t } = useTranslation();

  const { formValue, onChangeAutocompleteFieldWithObjectOptions } = useForm({
    order: undefined,
  });

  const { getOrderSelectList } = useOrderService();
  const orderList = useAsync(getOrderSelectList);

  return (
    <BasicDialog
      title={t("dialog.select_order_dialog.select_order")}
      open={props.open}
      onClose={props.onClose}
      maxWidth={"sm"}
      titleAlign="center"
    >
      <LoaderWrapper showLoader={orderList.loading}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AutocompleteField
              label={t("dialog.select_order_dialog.select_order")}
              options={orderList?.value || []}
              value={formValue.order}
              name="order"
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              optionLabelKey={"order_descriptive_name"}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              disabled={!formValue.order}
              onClick={() => {
                props.onSubmit(formValue.order);
              }}
            >
              {t("confirm")}
            </Button>
          </Grid>
        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
};

SelectOrderToAddTaskDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

SelectOrderToAddTaskDialog.defaultProps = {};

export default SelectOrderToAddTaskDialog;
