import { Outlet } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { APP_STAGE_BAR_HEIGHT } from "../../../helpers/constants";
import { isAppTesting, capitalizeFirstLetter } from "../../../helpers/methods";

import { useTranslation } from "react-i18next";

function IsTestingAppWrapper() {
  const { t } = useTranslation();

  return (
    <>
      {isAppTesting() && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "var(--alert)",
            color: "var(--white)",
            fontFamily: "Roboto",
            textAlign: "center",
            fontSize: "15px",
            fontWeight: "bold",
            width: "100%",
            height: APP_STAGE_BAR_HEIGHT,
            top: 0,
            left: 0,
            zIndex: 999,
          }}
        >
          {`${t("test_environment")} ${capitalizeFirstLetter(
            window._env_.APP_STAGE.toString()
          )}`}
        </div>
      )}
      <Outlet />
    </>
  );
}

export default IsTestingAppWrapper;
