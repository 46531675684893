import { useState, useEffect } from "react";

export const usePreventBrowserUnload = () => {
  const [isDataSaved, setIsDataSaved] = useState(true);

  useEffect(() => {
    const handleOnBeforeUnLoad = (e) => {
      if (isDataSaved) return;

      e.preventDefault();
      return (e.returnValue = "");
    };

    window.addEventListener("beforeunload", handleOnBeforeUnLoad);
    return () =>
      window.removeEventListener("beforeunload", handleOnBeforeUnLoad);
  }, [isDataSaved]);

  return {
    preventBrowserUnload: () => setIsDataSaved(false),
    allowBrowserUnload: () => setIsDataSaved(true),
  };
};
