import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useState } from "react";
import ValidateDataService from "../../../services/validatateDataService";
import AddEnclosureForm from "../../form/AddEnclosureForm";
import { INTERNAL_TYPE } from "../../../helpers/constants";
import EnclosureDetailsForm from "../../form/EnclosureDetailsForm";
import useFileService from "../../../services/fileService";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync } from "../../../hooks/useAsync";

function EnclosureDetailsDialog(props) {

    const { t } = useTranslation();

    const [formValue, setFormValue] = useState({...props.enclosure});

    const {  getEnclosuresKindPartData } = useFileService()
    const enclosuresKindPartData = useAsync(
      () => {
          if (props.showEnclosurePartKind){
              return getEnclosuresKindPartData()
          }
          return Promise.resolve([])
      }, [props.showEnclosurePartKind]
      )

    const onChange = (e) => {
        const { name, value } = e.target;
        let changeLogDict = {}

        if (name === 'enclosure_subcontractor'){
            changeLogDict.is_subcontractor_visibility_updated = true
        } else if (name === 'enclosure_type'){
            changeLogDict.is_enclosure_type_updated =true
        }

        setFormValue((formValue) => ({
            ...formValue,
            ...changeLogDict,
            [name]: ValidateDataService.isEmptyValue(value) ? "" : value,
        }));
    };

    return (
        <BasicDialog
            open={props.open}
            onClose={() => {
                props.onClose()
                setFormValue({})
            }}
            titleAlign="center"
            contentAlign="center"
            title={t("dialog.encolsure_details_dialog.enclosure_details")}
            maxWidth="md"
        >
            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <LoaderWrapper showLoader={enclosuresKindPartData.loading}>
                    <EnclosureDetailsForm
                        editable={props.editable}
                        showEnclosureType = {props.showEnclosureType}
                        showSubcontractorSelect = {props.showSubcontractorSelect}
                        showEnclosurePartKind= {props.showEnclosurePartKind}
                        enclosurePartKindList= {enclosuresKindPartData?.value?.kinds}
                        formValue={formValue}
                        onChange={onChange}
                        onSubmit={() => {
                            props.onSubmit(formValue)
                            setFormValue({})
                        }
                        }
                    />
                    </LoaderWrapper>
                </Grid>
            </Grid>
        </BasicDialog>
    )

}

EnclosureDetailsDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    showEnclosureType : PropTypes.bool,
    defaultEnclosureType : PropTypes.string,
    showSubcontractorSelect : PropTypes.bool,
    editable : PropTypes.bool
};
EnclosureDetailsDialog.defaultProps = {
    open: false,
    defaultEnclosureType : INTERNAL_TYPE,
    showEnclosureType : false,
    showSubcontractorSelect : false,
    editable : false

};

export default EnclosureDetailsDialog