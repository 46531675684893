import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@mui/material';
import LoaderWrapper from '../../../wrapper/LoaderWrapper';
import FilterTable from '../../../table/FilterTable';
import { useAsync } from '../../../../hooks/useAsync';
import useDialogWithId from '../../../../hooks/useDialogWithId';
import usePaginationWithSearchParams from '../../../../hooks/usePaginationWithSearchParams';
import useCorrespondenceAdminService from '../../../../services/correspondenceAdminService';
import CreatePostDeliveryDialog from '../../../dialog/CreatePostDeliveryDialog/CreatePostDeliveryDialog';
import { useEmptyArrayMemo } from '../../../../hooks/useEmptyArrayMemo';
import TableService from '../../../../services/tableService';
import { POST_DELIVERY_TABLE_CONFIG } from './TableConfig';
import SearchButton from '../../../button/SearchButton/SearchButton';  
import FilterDialog from '../../../base/FilterDialog';  
import UniversalFilterForm from '../../../form/UniversalFilterForm';  
import useDialog from '../../../../hooks/useDialog';  
import useFilterSearchParams from '../../../../hooks/useFilterSearchParams';  
import { useSearchParams } from 'react-router-dom';  
const POST_DELIVERY_FILTER_PREFIX = 'post_delivery';  

const CorrespondenceAdminPostDeliveryTab = (props) => {
  const { t } = useTranslation();
  const [postDeliveries, setPostDeliveries] = useState([]);
  const [countRecords, setCountRecords] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();  
  const [postDeliveryTableSearchParams, setPostDeliveryTableSearchParams] = useState({});  
  const { setNewItemSearchParamsIfAreChanged, clearSearchParamsByFilterPrefix } = useFilterSearchParams(searchParams);  

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] = useDialog();  
  const [openPostDeliveryDialog, postDeliveryId, onOpenPostDeliveryDialog, onClosePostDeliveryDialog] = useDialogWithId();
  const { getPostDeliveryList } = useCorrespondenceAdminService();
  const { emptyArrayMemo } = useEmptyArrayMemo();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
  } = usePaginationWithSearchParams(POST_DELIVERY_FILTER_PREFIX);  

  const postDeliveryData = useAsync(
    () => getPostDeliveryList(postDeliveryTableSearchParams),  
    [postDeliveryTableSearchParams]  
  );

  useEffect(() => {
    if (!postDeliveryData.loading) {
      setPostDeliveries(postDeliveryData.value.results);
      setCountRecords(postDeliveryData.value.count);
    }
  }, [postDeliveryData.loading]);

  const handleOpenPostDeliveryDialog = useCallback(
    (e, postDeliveryId) => {
      e.stopPropagation();
      onOpenPostDeliveryDialog(postDeliveryId);
    },
    [onOpenPostDeliveryDialog]
  );

  const refreshTable = () => {
    setPostDeliveries([]);
    postDeliveryData.refetch();
  };

  const data = useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        POST_DELIVERY_TABLE_CONFIG,
        postDeliveries ? postDeliveries : emptyArrayMemo,
        emptyArrayMemo,
        emptyArrayMemo,
        handleOpenPostDeliveryDialog,
      ),
    [postDeliveries, POST_DELIVERY_TABLE_CONFIG]
  );

  const onCleanFiltersInFilterDialog = () => {  
    setSearchParams(clearSearchParamsByFilterPrefix(POST_DELIVERY_FILTER_PREFIX));
  };

  useEffect(() => {  
    setNewItemSearchParamsIfAreChanged(
      POST_DELIVERY_FILTER_PREFIX,
      postDeliveryTableSearchParams,
      setPostDeliveryTableSearchParams
    );
  }, [searchParams]);

  if (data===undefined) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          onClick={(e) => onOpenPostDeliveryDialog()}
        >
          {t('tabs.correspondence_admin.add_post_delivery')}
        </Button>
      </Grid>
      <Grid item sm={9} />  
      <Grid item xs={12} sm={1}>  
        <SearchButton onClickSearch={onOpenFilterDialog} /> 
      </Grid>
      <Grid item xs={12}>
      <FilterTable
          data={data}
          showCheckbox={false}
          showCleanFilterIcon={true}
          countRecords={countRecords}
          page={page}
          onPageChange={handleChangePageWithSearchParams}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          filteringData={props.filteringData}
          style={{ maxHeight: "vh" }}
          tableConfig={POST_DELIVERY_TABLE_CONFIG}
          showContextMenu={false}
          filterPrefix={POST_DELIVERY_FILTER_PREFIX}
          headersConfig={TableService.getHeadersConfigForCollapsibleTable(
            POST_DELIVERY_TABLE_CONFIG,
            props.filteringData || emptyArrayMemo,
            emptyArrayMemo,
            emptyArrayMemo,
          )}
        />
      </Grid>
      {openFilterDialog && (  
        <FilterDialog
          open={openFilterDialog}
          onCleanFilters={onCleanFiltersInFilterDialog}
          onClose={onCloseFilterDialog}
          resetPageNumber={resetPageNumber}
          filterPrefix={POST_DELIVERY_FILTER_PREFIX}
          filterForm={
            <UniversalFilterForm
              filteringData={props.filteringData || emptyArrayMemo}
              filtersConfig={POST_DELIVERY_TABLE_CONFIG}
              includeOpenCloseFilter={false}
            />
          }
        />
      )}
      {openPostDeliveryDialog && (
        <CreatePostDeliveryDialog
          open={openPostDeliveryDialog}
          onClose={onClosePostDeliveryDialog}
          deliveryId={postDeliveryId}
          onRefetchData={refreshTable}
        />
      )}
    </Grid>
  );
};

CorrespondenceAdminPostDeliveryTab.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

CorrespondenceAdminPostDeliveryTab.defaultProps = {};

export default CorrespondenceAdminPostDeliveryTab;
