import BasicDialog from "../../base/BasicDialog";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import PersonsForm from "../../drawer/UniversalDetailsDrawer/UniversalDetailsDrawerForms/PersonsForm";
import { useForm } from "../../../hooks/useForm";
import ItemDetailsForm from "../../drawer/UniversalDetailsDrawer/UniversalDetailsDrawerForms/ItemDetailsForm";
import { Divider, Grid } from "@mui/material";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import useTicketService from "../../../services/ticketService";
import { useAsync } from "../../../hooks/useAsync";
import { useEffect } from "react";
import LocalizationForm from "../../drawer/UniversalDetailsDrawer/UniversalDetailsDrawerForms/LocalizationForm";

const TicketDetailsDialog = (props) => {
  const { t } = useTranslation();

  const { formValue, setFormValue } = useForm();

  const { getTicketDetailsData, getTicketFilteringData } = useTicketService();

  const ticketDetails = useAsync(
    () => getTicketDetailsData(props.ticketId),
    [props.ticketId]
  );

  const ticketFilteringData = useAsync(getTicketFilteringData);

  useEffect(() => {
    if (ticketDetails.loading) {
      return;
    }
    setFormValue(ticketDetails.value);
  }, [ticketDetails.loading]);

  const isLoading =
    ticketFilteringData.loading ||
    ticketDetails.loading ||
    formValue === undefined;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PersonsForm
            ownerName={formValue.ticket_owner?.full_name}
            ownerId={formValue.ticket_owner?.id}
            tenantName={formValue.tenant?.tenant_short_name}
            tenantId={formValue.tenant?.id}
            reposoniblePersonId={formValue.ticket_k}
            isResponsiblePersonAllowedToChange={!props.readOnly}
            reposoniblePersonOptions={ticketFilteringData.value.ticket_k}
            itemType={props.itemType}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ItemDetailsForm
            isPaid={formValue.ticket_paid}
            isAllowedToChangeIsPaid={!props.readOnly}
            statusName={formValue.ticket_status}
            substatusName={formValue.ticket_substatus}
            categoryId={formValue.ticket_category}
            isCategoryAllowedToChange={!props.readOnly}
            categoryOptions={ticketFilteringData.value.ticket_category}
            priorityId={formValue.ticket_priority}
            priorityOptions={ticketFilteringData.value.ticket_priority}
            isPriorityAllowedToChange={!props.readOnly}
            ticketDescCreator={formValue.ticket_desc_creator}
            itemName={formValue.ticket_name}
            isAllowedToChangeItemName={!props.readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <LocalizationForm
            itemForChangeLocalizationId={formValue.id}
            isAllowedToChangeLocalization={!props.readOnly}
            localizationData={formValue.locations}
            isMultipleLocalization={formValue.is_multiple_locations}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      maxWidth="xs"
      title={t("dialog.ticket_details_dialog.ticket_details")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

TicketDetailsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ticketId: PropTypes.string,
  readOnly: PropTypes.bool,
  itemType: PropTypes.string,
};

TicketDetailsDialog.defaultProps = {
  itemType: "ticket",
  readOnly: true,
};

export default TicketDetailsDialog;
