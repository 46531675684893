import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import FullWidthTabs from "../FullWidthTabs/FullWidthTabs";
import WarehouseAdminWarehousesTab from "./WarehouseAdminWarehousesTab";
import WarehouseAdminPermissionsTab from "./WarehouseAdminPermissionsTab/WarehouseAdminWarehousesTab";
import WarehouseAdminOtherPermissionsTab from "./WarehouseAdminOtherPermissionsTab/WarehouseAdminOtherPermissionsTab";
import useWarehouseService from "../../../services/warehouseService";
import { useAsync } from "../../../hooks/useAsync";



const WarehouseAdminTabs = React.memo((props) => {
  const { t } = useTranslation();

  const {
    getWarehouseFilteringData
  } = useWarehouseService();

  const userWarehouseFilteringData  = useAsync(getWarehouseFilteringData);

  return (
    <FullWidthTabs
    cleanFilters={true}
      items={[
        {
          label: t("tabs.warehouse_admin.warehouses"),
          content: (
            <WarehouseAdminWarehousesTab
              readOnly={props.readOnly}
              pageName={props.pageName}
              filteringData = {userWarehouseFilteringData}
            />
          ),
        },
        {
          label: t("tabs.warehouse_admin.transaction_permissions"),
          content: (
            <WarehouseAdminPermissionsTab
              readOnly={props.readOnly}
              pageName={props.pageName}
              filteringData = {userWarehouseFilteringData}
            />
          ),
        },
        // {
        //   label: t("tabs.warehouse_admin.other_permissions"),
        //   content: (
        //     <WarehouseAdminOtherPermissionsTab
        //       readOnly={props.readOnly}
        //       pageName={props.pageName}
        //       filteringData = {userWarehouseFilteringData}
        //     />
        //   ),
        // },
      ]}
    />
  );
});

WarehouseAdminTabs.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

WarehouseAdminTabs.defaultProps = {
  readOnly: false,
};

export default WarehouseAdminTabs;
