import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { userPreferecesUrl } from "../helpers/apiUrls";

export default function useUserPreferenceService() {
  const axiosPrivate = useAxiosPrivate();

  const getUserPreferencesForPage = (pageName) => {
    return makeRequest(axiosPrivate, userPreferecesUrl(pageName));
  };

  const updateUserPreferencesForPage = (pageName, dataToSend) => {
    return makeRequest(axiosPrivate, userPreferecesUrl(pageName), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getHiddenColumnFormValueFromBackend = (
    tableNames,
    userPreferencesForPageValue,
    tableConfig = undefined, 
  ) => {
    let resData = {};
    let unhiddenData = {}; 
    tableNames.forEach((tableName) => {
      resData[tableName] = userPreferencesForPageValue[tableName]?.hidden_columns || [];
      unhiddenData[tableName] = userPreferencesForPageValue[tableName]?.unhidden_default_columns || [];
    });
    
    tableNames.forEach((tableName) =>{resData[tableName] = getDefaultHiddenColumnsToHiddenList(resData[tableName], unhiddenData[tableName],tableConfig? tableConfig[tableName]: [])})
    return resData;
  };

  const getUnhiddenColumnFormValueFromBackend = (
    tableNames,
    userPreferencesForPageValue,
  ) => {
    let unhiddenData = {}; 
    tableNames.forEach((tableName) => {
      unhiddenData[tableName] = userPreferencesForPageValue[tableName]?.unhidden_default_columns || [];
    });
    
    return unhiddenData;
  };

  const convertUserPreferencesFromFrontendToBackend = (
    hiddenColumnsForTables,
    columnsOrdersForTables,
    unhiddenDefaultsColumns,
  ) => {
  
    return Object.entries(hiddenColumnsForTables).map(
      ([tableName, hiddenColumnsForTable]) => ({
        key: tableName,
        value: {
          hidden_columns: hiddenColumnsForTable,
          columns_order: columnsOrdersForTables[tableName],
          unhidden_default_columns: unhiddenDefaultsColumns?  unhiddenDefaultsColumns[tableName] : []
        },
      })
    );
  };

  const getColumnOrdersFormValueFromBackend = (
    tableNames,
    userPreferencesForPageValue
  ) => {
    let resData = {};

    tableNames.forEach((tableName) => {
      resData[tableName] =
        userPreferencesForPageValue[tableName]?.columns_order;
    });

    return resData;
  };

  const getDefaultHiddenColumnsToHiddenList = (hiddenColumns, unhiddenDefaults, config) =>{
    let defaultHiddenColumns = []
    if (config){
      defaultHiddenColumns = config.filter(column => column.defaultHidden === true && !unhiddenDefaults.includes(column.name)).map(column => column.name)
    }
    return [...hiddenColumns, ...defaultHiddenColumns]
  }

  return {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getUnhiddenColumnFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
    getColumnOrdersFormValueFromBackend,
    getDefaultHiddenColumnsToHiddenList
  };
}
