import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import Router from "./components/routing/Router";

import { AuthProvider } from "./context/auth";
import { SnackbarAlertProvider } from "./context/snackbarAlert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { DataSavedProvider } from "./context/dataSaved";

const theme = createTheme({
  palette: {
    primary: {
      light: "#1d5472",
      main: "#133649",
      dark: "#081820",
      contrastText: "#fff",
    },
    alternate: {
      light: "#68ac6b",
      main: "#509153",
      dark: "#3e7040",
      contrastText: "#fff",
    },
    success: {
      light: "#68ac6b",
      main: "#509153",
      dark: "#3e7040",
      contrastText: "#fff",
    },
    secondary: {
      light: "#555",
      main: "#444",
      dark: "#333",
      contrastText: "#fff",
    },

    warning: {
      main: "#EDBD01",
      contrastText: "#fff",
    },

    error: {
      main: "#d40808",
    },
  },
});

function App({ msalInstance }) {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <SnackbarAlertProvider>
            <AuthProvider>
              {/* <DataSavedProvider> */}
              <Router />
              {/* </DataSavedProvider> */}
            </AuthProvider>
          </SnackbarAlertProvider>
        </MsalProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
