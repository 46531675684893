import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import DateService from "../../services/dateService";

import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import PrefixSuffixFieldTableWrapper from "../../components/wrapper/PrefixSuffixFieldTableWrapper";

import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";

export const ENCLOUSURE_TABLE_NAME = "enclosure";
export const ENCLOUSURE_TABLE_FILTER_PREFIX = "enclosure";

export const ENCLOUSURE_TABLE_FIELDS_CONFIG = [
  {
    name: "docs_name",
    getValueCallback: (rowData) => rowData.enclosure_name,
    label: i18n.t("table.enclosure.enclosure_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "docsnote",
    getValueCallback: (rowData) => rowData.enclosure_note,
    label: i18n.t("table.enclosure.enclosure_note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "ticket_nr",
    getValueCallback: (rowData) => rowData.ticket_nr,
    label: i18n.t("table.enclosure.ticket_nr"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.enclosure.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  {
    name: "user",
    getValueCallback: (rowData) => rowData.user,
    label: i18n.t("table.enclosure.author"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.user,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "download",
    getValueCallback: (rowData, onDownload, onPreview) => (
      <IconButton
        color="primary"
        onClick={() => {
          onDownload(rowData.id, rowData.enclosure_name);
        }}
        sx={{ padding: "5px" }}
      >
        <DownloadIcon fontSize="medium" />
      </IconButton>
    ),
    label: i18n.t("table.enclosure.download"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },

  {
    name: "preview",
    getValueCallback: (rowData, onDownload, onPreview) =>
      rowData.content_type === "application/pdf" ||
      (rowData.content_type && rowData.content_type.includes("image")) ? (
        <IconButton
          color="primary"
          onClick={() => {
            onPreview(rowData.id);
          }}
          sx={{ padding: "5px" }}
        >
          <PreviewOutlinedIcon fontSize="medium" />
        </IconButton>
      ) : null,
    label: i18n.t("table.enclosure.preview"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { maxWidth: "100px" },
    }),
  },
];

export const DOCS_RESTRICTED_TABLE_NAME = "docs_restricted";
export const DOCS_RESTRICTED_TABLE_FILTER_PREFIX = "docs_restricted";

export const DOCS_RESTRICTED_TABLE_FIELDS_CONFIG = [
  {
    name: "docs_name",
    getValueCallback: (rowData) => rowData.docs_name,
    label: i18n.t("table.docs_restricted.docs_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "docs_note",
    getValueCallback: (rowData) => rowData.docs_note,
    label: i18n.t("table.docs_restricted.docs_note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "user_note",
    label: i18n.t("table.docs_restricted.user_note"),
    filterType: TEXT_FIELD_TYPE,
    getValueCallback: (
      rowData,
      onClickMultipleLocationAlert,
      onDownload,
      onPreview,
      onClickEdit,
      onClickEditUserNote
    ) => {
      // const Icon = rowData.user_note
      //   ? ModeEditOutlineOutlined
      //   : ControlPointOutlinedIcon;

      return !rowData.user_note ? (
        <PrefixSuffixFieldTableWrapper
          suffix={
            <IconButton
              size="small"
              color="primary"
              onClick={(e) =>
                onClickEditUserNote(rowData.id, rowData.user_note?.id)
              }
              sx={{ padding: "5px" }}
            >
              <ControlPointOutlinedIcon style={{ cursor: "pointer" }} />
            </IconButton>
          }
        >
          {rowData.user_note?.docs_user_note}
        </PrefixSuffixFieldTableWrapper>
      ) : (
        <div
          onClick={(e) =>
            onClickEditUserNote(rowData.id, rowData.user_note?.id)
          }
        >
          {rowData.user_note?.docs_user_note}
        </div>
      );
    },
  },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.docs_restricted.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  {
    name: "user",
    getValueCallback: (rowData) => rowData.docs_user,
    label: i18n.t("table.docs_restricted.docs_user"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.docs_user,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "download",
    getValueCallback: (
      rowData,
      onClickMultipleLocationAlert,
      onDownload,
      onPreview,
      onClickEdit,
      onClickEditUserNote
    ) => (
      <IconButton
        color="primary"
        onClick={() => {
          onDownload(rowData.id, rowData.docs_name);
        }}
        sx={{ padding: "5px" }}
      >
        <DownloadIcon fontSize="medium" />
      </IconButton>
    ),
    label: i18n.t("table.docs_restricted.download"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },

  {
    name: "preview",
    getValueCallback: (
      rowData,
      onClickMultipleLocationAlert,
      onDownload,
      onPreview
    ) =>
      rowData.content_type === "application/pdf" ||
      (rowData.content_type && rowData.content_type.includes("image")) ? (
        <IconButton
          color="primary"
          onClick={() => {
            onPreview(rowData.id);
          }}
          sx={{ padding: "5px" }}
        >
          <PreviewOutlinedIcon fontSize="medium" />
        </IconButton>
      ) : null,
    label: i18n.t("table.docs_restricted.preview"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { maxWidth: "100px" },
    }),
  },
];
