import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SplitButton from "../SplitButton";
import { useSearchParams } from "react-router-dom";

function GuaranteeGuaranteeScopeButton(props) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedFilterKey = useMemo(() => {
    return props.isGuaranteeTableView ? "guarantee" : "guarantee_scope";
  }, [props.isGuaranteeTableView, searchParams]);

  return (
    <SplitButton
      selectedItemKey={selectedFilterKey}
      withChangeSelectedItem={true}
      options={props.options}
    />
  );
}

GuaranteeGuaranteeScopeButton.propTypes = {
  isGuaranteeTableView: PropTypes.bool,
  options: PropTypes.array,
};

GuaranteeGuaranteeScopeButton.defaultProps = {
  isGuaranteeTableView: true,
  options: [],
};

export default GuaranteeGuaranteeScopeButton;
