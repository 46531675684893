import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../../field/TextFieldFormControl";
import i18n from "../../../../i18n";
import { useAuth } from "../../../../context/auth";
import NotesDialog from "../../../dialog/NotesDialog/NotesDialog";
import useDialog from "../../../../hooks/useDialog";

const OfferCommentsForm = ({
  offer_k_note,
  offer_rr_note,
  offer_om_note,
  allowEditKNote,
  onChange,
  dialogMode
}) => {

  const { user } = useAuth()

  const [
    openKNoteDialog,
    onOpenKNoteDialog,
    handleCloseKNoteDialog,
  ] = useDialog();

  const [
    openRRNoteDialog,
    onOpenRRNoteDialog,
    handleCloseRRNoteDialog,
  ] = useDialog();

  const [
    openOMNoteDialog,
    onOpenOMNoteDialog,
    handleCloseOMNoteDialog,
  ] = useDialog();


  const onChangeKNote = useCallback((formValue) => {
    let k_note = formValue['note']
    let e = {}
    e.target = {}
    e.target.name = 'offer_k_note'
    e.target.value = k_note
    onChange(e)
    handleCloseKNoteDialog()
  }, [onChange])

  const onChangeRRNote = useCallback((formValue) => {
    let k_note = formValue['note']
    let e = {}
    e.target = {}
    e.target.name = 'offer_rr_note'
    e.target.value = k_note
    onChange(e)
    handleCloseRRNoteDialog()
  }, [onChange])

  return (

    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
        <Grid item xs={12}>
      <div onClick={onOpenKNoteDialog}>
          <TextFieldFormControl
            label={i18n.t("form.offer_comments_form.offer_k_note")}
            value={offer_k_note}
            multiline
            rows={4}
          />
          </div>
        </Grid>
        <Grid item xs={12}>
      <div onClick={onOpenRRNoteDialog}>
          <TextFieldFormControl
            label={i18n.t("form.offer_comments_form.offer_rr_note")}
            value={offer_rr_note}
            multiline
            rows={4}
          />
      </div>
        </Grid>
        <Grid item xs={12}>
      {/* <div onClick={onOpenOMNoteDialog}>
          <TextFieldFormControl
            label={i18n.t("form.offer_comments_form.offer_om_note")}
            value={offer_om_note}
            multiline
            rows={4}
          />
      </div> */}
        </Grid>
      {
        openKNoteDialog && (
          <NotesDialog
            open={openKNoteDialog}
            onClose={handleCloseKNoteDialog}
            showFilename={false}
            note={offer_k_note}
            readOnly={!allowEditKNote || !user.is_k}
            onSubmit={(formV) => onChangeKNote(formV)}
            title={i18n.t("form.offer_comments_form.offer_k_note")}
          />
        )
      }
      {
        openRRNoteDialog && (
          <NotesDialog
            open={openRRNoteDialog}
            onClose={handleCloseRRNoteDialog}
            showFilename={false}
            note={offer_rr_note}
            readOnly={dialogMode !== "acceptRR"}
            onSubmit={(formV) => onChangeRRNote(formV)}
            title={i18n.t("form.offer_comments_form.offer_rr_note")}
          />
        )
      }
      {
        openOMNoteDialog && (
          <NotesDialog
            open={openOMNoteDialog}
            onClose={handleCloseOMNoteDialog}
            showFilename={false}
            note={offer_om_note}
            readOnly={true}
            title={i18n.t("form.offer_comments_form.offer_om_note")}
          />
        )
      }

    </Grid>
  );
};

OfferCommentsForm.propTypes = {
  offer_k_note: PropTypes.string,
  offer_rr_note: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  allowEditKNote : PropTypes.bool
};

OfferCommentsForm.defaultProps = {
  readOnly: true,
  allowEditKNote : false
};

export default OfferCommentsForm;
