import { useTranslation } from "react-i18next"
import BasicDialog from "../../base/BasicDialog"
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import useUserService from "../../../services/userService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import CreateUserSkillForm from "../../form/CreateUserSkillForm";
import { useForm } from "../../../hooks/useForm";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useSkillService from "../../../services/skillService";

const CreateUserSkillDialog = ({open, onClose, initForm, skillIdsUserIsLeader}) => {

    //userskill - [{'id': '', 'user': '', skill: ''}]
    const {t} = useTranslation();
    const {
        formValue,
        onChange,
        onChangeAutocompleteFieldWithObjectOptions
    } = useForm(initForm);

    const snackbarAlert = useSnackbarAlert();

    const {getSkillSelectList} = useSkillService();
    const { getEmployeesSelectList, assignSkillsToUser } = useUserService();
    const skillSelectList = useAsync(getSkillSelectList);
    const employeesSelectList = useAsync(getEmployeesSelectList);

    const assignSkillsToUserFn = useAsyncFn(assignSkillsToUser);



    const onSubmit = () => {
        assignSkillsToUserFn
        .execute(formValue)
        .then((result) => {
            snackbarAlert.openSuccessSnackbarAlert(
                t("snackbar_alert.team_updated")
            );
            onClose(true);
        })
        .catch((error) => {
            snackbarAlert.openErrorSnackbarAlert(
                t("snackbar_alert.occurred_error_during_team_updated")
            );
        })
    }

    return (
        <BasicDialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth={"sm"}
            titleAlign="center"
            title={t("dialog.create_user_skill.assign_employee_to_skill")}
        >
            <LoaderWrapper showLoader={skillSelectList.loading || employeesSelectList.loading}>
                <CreateUserSkillForm
                    onChange={onChange}
                    onChangeAutocompleteFieldWithObjectOptions={onChangeAutocompleteFieldWithObjectOptions}
                    userSelectList={employeesSelectList.value}
                    skillsSelectList={skillSelectList.value}
                    onSubmit={onSubmit}
                    allowChangeUser={false}
                    user={formValue.user}
                    skills={formValue.skills}
                    skillIdsAllowToEdit={skillIdsUserIsLeader}
                />
            </LoaderWrapper>
        </BasicDialog>
    )
}

export default CreateUserSkillDialog