import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
  TEXT_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
} from "../../helpers/constants";
import FlagMarker from "../../components/other/FlagMarker";
import SelectFieldService from "../../services/selectFieldService";
import WarningAlertIcon from "../../components/other/WarningAlertIcon";
import PrefixSuffixFieldTableWrapper from "../../components/wrapper/PrefixSuffixFieldTableWrapper";
import DateService from "../../services/dateService";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Tooltip } from "@mui/material";

export const ORDER_TABLE_NAME = "order";

export const ORDER_TABLE_FIELDS_CONFIG = [
  {
    name: "flags",
    getValueCallback: (rowData) =>
      rowData.order_flag.map((tf) => (
        <FlagMarker flagName={tf.flag_name} flagColor={tf.flag_color} />
      )),
    label: i18n.t("table.order.order_flag"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.flags,
      isObjectOption: true,
      optionLabelKey: "flag_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "100px" },
    }),
    other_export_field_name: "order_flag",
  },
  {
    name: "ticket_nr",
    getValueCallback: (rowData) => rowData.ticket_nr,
    label: i18n.t("table.order.ticket_nr"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "order_nr",
    getValueCallback: (rowData) => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ zIndex: 0, marginLeft: "20px" }}>
            {rowData.order_nr}
          </div>
          {rowData.order_has_enclosures && (
            <div style={{ zIndex: 0, marginRight: "20px" }}>
              <Tooltip
                title={i18n.t("table.order.order_has_enclosures")}
                placement={"right"}
              >
                <AttachFileIcon />
              </Tooltip>
            </div>
          )}
        </div>
      );
    },
    label: i18n.t("table.order.order_nr"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "order_name",
    getValueCallback: (rowData) => rowData.order_name,
    label: i18n.t("table.order.order_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "order_desc_creator",
    getValueCallback: (rowData) => rowData.order_desc_creator,
    label: i18n.t("table.order.order_desc_creator"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "status",
    getValueCallback: (rowData) => rowData.status,
    label: i18n.t("table.order.status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.status,
      isObjectOption: true,
      optionLabelKey: "status_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "order_priority",
    getValueCallback: (rowData) => rowData.order_priority,
    label: i18n.t("table.order.order_priority"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.order_priority,
      isObjectOption: true,
      optionLabelKey: "ordpri_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "order_type",
    getValueCallback: (rowData) => rowData.order_type,
    label: i18n.t("table.order.order_type"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.order_type,
      isObjectOption: true,
      optionLabelKey: "ordtyp_type",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.order.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "order_date_closeSTD_real",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.order_date_closeSTD_real
      ),
    label: i18n.t("table.order.order_date_closeSTD_real"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "order_paid",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.order_paid),
    label: i18n.t("form.order.order_paid"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "objects_level_1",
    getValueCallback: (rowData, onClickMultipleLocationAlert) => {
      return rowData.is_multiple_locations ? (
        <PrefixSuffixFieldTableWrapper
          prefix={
            <WarningAlertIcon
              style={{ cursor: "pointer" }}
              onClick={(e) => onClickMultipleLocationAlert(e, rowData.id)}
            />
          }
        >
          {rowData.locations[0].level_1}
        </PrefixSuffixFieldTableWrapper>
      ) : (
        rowData.locations[0].level_1
      );
    },
    label: i18n.t("table.order.object_level_1"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_2",
    getValueCallback: (rowData) => rowData.locations[0].level_2,
    label: i18n.t("table.order.object_level_2"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_3",
    getValueCallback: (rowData) => rowData.locations[0].level_3,
    label: i18n.t("table.order.object_level_3"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_4",
    getValueCallback: (rowData) => rowData.locations[0].level_4,
    label: i18n.t("table.order.object_level_4"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_5",
    getValueCallback: (rowData) => rowData.locations[0].level_5,
    label: i18n.t("table.order.object_level_5"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "order_kt",
    getValueCallback: (rowData) => rowData?.order_kt,
    label: i18n.t("table.order.order_kt"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.order_kt,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "order_t",
    getValueCallback: (rowData) => rowData?.order_t,
    label: i18n.t("table.order.order_t"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.order_t,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "skill",
    getValueCallback: (rowData) => rowData?.skill,
    label: i18n.t("table.order.skill"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.skill,
      isObjectOption: true,
      optionLabelKey: "skill_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "order_kt_need",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.order_kt_need),
    label: i18n.t("table.order.order_kt_need"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.ticket.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_owner",
    getValueCallback: (rowData) => rowData.ticket_owner,
    label: i18n.t("table.ticket.ticket_owner"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_owner,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_category",
    getValueCallback: (rowData) => rowData.ticket_category,
    label: i18n.t("table.ticket.ticket_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_category,
      isObjectOption: true,
      optionLabelKey: "tc_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_om",
    getValueCallback: (rowData) => rowData.ticket_om,
    label: i18n.t("table.ticket.ticket_om"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_om,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_desc_creator",
    getValueCallback: (rowData) => rowData.ticket_desc_creator,
    label: i18n.t("table.order.ticket_desc_creator"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];
