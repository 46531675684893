import PropTypes from "prop-types";

import { CircularProgress } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";

const LoadingDialog = (props) => {
  return (
    <BasicDialog
      open={props.open}
      titleAlign="center"
      contentAlign="center"
      title={props.dialogTitle}
      titleFontSize={props.titleFontSize}
      maxWidth={props.dialogMaxWidth}
      showDialogActions={false}
      showTopCloseButton={false}
      showTopFullScreenButton={false}
      dividers={false}
    >
      <div style={{ marginBlock: props.loaderMargin }}>
        <CircularProgress size={props.loaderSize} />
      </div>
    </BasicDialog>
  );
};

LoadingDialog.propTypes = {
  open: PropTypes.bool,
  dialogTitle: PropTypes.string,
  dialogMaxWidth: PropTypes.string,
  loaderSize: PropTypes.objectOf([PropTypes.string, PropTypes.number]),
  loaderMargin: PropTypes.string,
  titleFontSize: PropTypes.string,
};

LoadingDialog.defaultProps = {
  open: false,
  dialogMaxWidth: "sm",
  loaderSize: "15vh",
  loaderMargin: "10vh",
  titleFontSize: "27px",
};

export default LoadingDialog;
