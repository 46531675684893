import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { isEmptyValue } from "../../../helpers/methods";
import usePhoneService from "../../../services/phoneService";
import PhoneDetailsForm from "../../form/PhoneDetailsForm/PhoneDetailsForm";


const PhoneDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [isNumberFormatValid, setIsNumberFormatValid] = useState(true)
  const requiredFields = [
    "phone_nr",
    "phone_who"
  ];

  const phoneFormatRegex = /^\+?[0-9 ]+$/;

  const {
    formValue,
    setFormValue,
    onChange,
  } = useForm({});

  const {
    getPhoneDetails,
    createPhone,
    updatePhone } = usePhoneService();

  const updatePhoneFn = useAsyncFn(updatePhone)
  const createPhoneFn = useAsyncFn(createPhone)

  const phoneDetailsData = useAsync(
    () => getPhoneDetails(props.phoneId),
    [props.phoneId]
  );


  useEffect(() => {
    if (phoneDetailsData.loading) {
      return;
    }
    setFormValue(phoneDetailsData.value);
  }, [phoneDetailsData.loading]);


  const prepareNewPhoneData = () => {
    let tempPhone = formValue
    tempPhone['owner'] = props.ownerId
    tempPhone['subcontractor'] = props.subcontractorId
    return tempPhone
  }

  useEffect(() => {
    if (formValue.phone_nr){
      setIsNumberFormatValid(validatePhoneFormat(formValue.phone_nr))
    }else{
      setIsNumberFormatValid(true)
    }
  }, [formValue]);

  const onSubmit = useCallback(() => {
    if (props.phoneId) {
      updatePhoneFn
        .execute(props.phoneId , formValue)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.phone_updated")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_phone_updating")
          );
        });
    } else {
      if(props.ownerId || props.subcontractorId){
      createPhoneFn
        .execute(prepareNewPhoneData())
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.phone_added")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_phone_adding")
          );
        });
      }else{
        props.onAddPhoneForNewOwner(formValue);
        props.onClose();
      }
        
    }

  }, [formValue]);

  const isValid = formValue ? requiredFields.every(
    (fieldName) => !isEmptyValue(formValue[fieldName])
  ) && isNumberFormatValid : false

  const validatePhoneFormat = (phoneNumber) =>{
    return phoneFormatRegex.test(phoneNumber);
  }

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.phoneId ? t(`dialog.phone_details_dialog.edit_phone`) : t(`dialog.phone_details_dialog.create_phone`)}
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        {phoneDetailsData.loading  ?
          <LoaderWrapper isLoading={true} />
          :
          <>
            <Grid item xs={12}>
              <PhoneDetailsForm
                phone={formValue}
                isNumberValid = {isNumberFormatValid}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={updatePhoneFn.loading}
                disabled={!isValid}
                onClick={onSubmit}
              >
                {props.phoneId ? t("save") : t("create")}
              </LoadingButton>
            </Grid>
          </>
        }
      </Grid>
    </BasicDialog>
  );
};

PhoneDialog.propTypes = {
  ownerId: PropTypes.string,
  phoneId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

PhoneDialog.defaultProps = {
  open: false,
};

export default PhoneDialog;
