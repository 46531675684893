import React from "react";
import PropTypes from "prop-types";

import { Box } from "@mui/system";
import { Checkbox } from "@mui/material";

const CheckboxFieldTable = React.memo((props) => {
  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Checkbox
        name={props.name}
        size="small"
        checked={props.value !== undefined ? props.value : false}
        onChange={props.onChange}
        disabled={!props.editable}
        style={{ padding: 0 }}
      />
    </Box>
  );
});

CheckboxFieldTable.propTypes = {
  value: PropTypes.bool,
  name: PropTypes.string,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
};

CheckboxFieldTable.defaultProps = {
  editable: true,
  onChange: () => {},
};

export default CheckboxFieldTable;
