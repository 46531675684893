import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import ValidationForm from "../ValidationForm";
import EditableTable from "../../table/EditableTable/EditableTable";
import { useEmptyArrayMemo } from "../../../hooks/useEmptyArrayMemo";
import BoxWithTypography from "../../box/BoxWithTypography/BoxWithTypography";
import TableService from "../../../services/tableService";
import { DATE_FIELD_TYPE, NUMERIC_FIELD_TYPE, TEXT_FIELD_TYPE } from "../../../helpers/constants";
import _default from "@emotion/styled";



const EditableBoxForm = React.memo((props) => {
  console.log('EditableBoxForm', props.kind)


  const { t } = useTranslation();

  const SCHEDULE_TABLE_FIELDS_CONFIG = React.useMemo(() => {
    return [
      {
        name: "icoschedule_amount_to_pay",
        getValueCallback: (rowData) => rowData?.icoschedule_amount_to_pay,
        label: t("form.schedule_form.amount"),
        dataType: NUMERIC_FIELD_TYPE,
        editable: true
      },
      {
        name: "icoschedule_due_date",
        getValueCallback: (rowData) => rowData?.icoschedule_due_date,
        label: t("form.schedule_form.ico_due_date"),
        dataType: DATE_FIELD_TYPE,
        editable: true
      }]
  }, [])

  const INTEREST_TABLE_FIELDS_CONFIG = React.useMemo(() => {
    return [
      {
        name: "icoschedule_amount_to_pay",
        getValueCallback: (rowData) => rowData?.icoschedule_amount_to_pay,
        label: t("form.schedule_form.amount"),
        dataType: NUMERIC_FIELD_TYPE,
        editable: false
      },
      {
        name: "icoschedule_due_date",
        getValueCallback: (rowData) => rowData?.icoschedule_due_date,
        label: t("form.schedule_form.ico_due_date"),
        dataType: DATE_FIELD_TYPE,
        editable: true
      }
    ]
  }, [])


  const { emptyArrayMemo } = useEmptyArrayMemo()

  const style = useMemo(() => {
    return { maxHeight: "vh" }
  }, [])


  const tableData = React.useMemo(() => {
    return TableService.getFieldsConfigForEditableTable(
      props.kind === "interest" ? INTEREST_TABLE_FIELDS_CONFIG : SCHEDULE_TABLE_FIELDS_CONFIG,
      props.data,
      emptyArrayMemo)
  }, [props.data, INTEREST_TABLE_FIELDS_CONFIG, SCHEDULE_TABLE_FIELDS_CONFIG, props.kind])

  const onEditRowByKind = useCallback((name, value, index) => {
    props.onEditRow(name, value, index, props.kind)
    switch (props.kind) {
      case "tranche":
        props.setTranchesChangedByUser(true)
        props.setScheduleUpdated(false)
        break;
      case "installment":
        props.setInstallmentsChangedByUser(true)
        props.setScheduleUpdated(false)
        break;
      case "interest":
        props.setInterestsChangedByUser(true)
        props.setScheduleUpdated(false)
        break;
    }

  }, [props.kind])

  return (
    <BoxWithTypography
      warning={props.showWarning}
      error={!props.areDateValid || !props.isSumValid  ||!props.isFirstDateAfterActivationDateValid || !props.isPartialInstallmentSumIsValid}
      style={{ padding: "10px" }}
      label={t(`form.schedule_form.${props.kind}`)}
      component={"div"}
    >
      <EditableTable
        data={tableData}
        readOnly={props.readOnly}
        editable={true}
        showCheckbox={false}
        kind={props.kind}
        onDataChange={onEditRowByKind}
        filteringData={emptyArrayMemo}
        style={style}
        tableConfig={props.kind === "interest" ? INTEREST_TABLE_FIELDS_CONFIG : SCHEDULE_TABLE_FIELDS_CONFIG}
        showContextMenu={false}
      />
           <ValidationForm
          isSumEqualConditionValue ={props.isSumValid}
          areIcoScheduleDatesAsc = {props.areDateValid}
          isFirstDateAfterActivationDateValid={props.isFirstDateAfterActivationDateValid}
          isPartialInstallmentSumIsValid={props.isPartialInstallmentSumIsValid}
        />
    </BoxWithTypography>
  )

});

const IcoScheduleForm = React.memo((props) => {
  console.log('ico schedule form')
  const { t } = useTranslation();

  const scheduleKinds = useMemo(() => {
    return ["tranche", "installment", "interest"]
  }, [])


  const getShowWaring = useCallback((kind) => {
    switch (kind) {
      case "tranche":
        return props.areTranchesChangedByUser
      case "installment":
        return props.areInstallmentsChangedByUser
      case "interest":
        return props.areInterestsChangedByUser
      default:
        return false
    }
  }, [props.areTranchesChangedByUser, props.areInstallmentsChangedByUser,props.areInterestsChangedByUser, props.kind])

  const getDatesValidation = useCallback((kind) => {
    switch (kind) {
      case "tranche":
        return props.isTanchesDatesTableValid
      case "installment":
        return props.isInstallmentDatesTableValid
      case "interest":
        return props.isInterestDatesTableValid
      default:
        return false
    }
  }, [props.isTanchesDatesTableValid, props.isInstallmentDatesTableValid, props.isInterestDatesTableValid, props.kind])

  const getSumValidation = useCallback((kind) => {
    switch (kind) {
      case "tranche":
        return props.isTanchesSumTableValid
      case "installment":
        return props.isInstallmentSumTableValid
      default:
        return true
    }
  }, [props.isTanchesSumTableValid, props.isInstallmentSumTableValid, props.kind])


  const getFirstDateAfterActivationDateValidation = useCallback((kind) => {
    switch (kind) {
      case "installment":
        return props.isFirstInstalmmentDateAfterActivationDateValid
      case "interest":
        return props.isFirstInterestDateAfterActivationDateValid
      default:
        return true
    }
  }, [props.isFirstInstalmmentDateAfterActivationDateValid, props.isFirstInterestDateAfterActivationDateValid, props.kind])

  const getPartialInstallmentSumIsValid = useCallback((kind) => {
    switch (kind) {
      case "installment":
        return props.checkPartialInstallmentSumIsValid
      default:
        return true
    }
  }, [props.checkPartialInstallmentSumIsValid,  props.kind])

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={1}
    >
      {!props.isScheduleUpdated &&
        <Grid item xs={1.5}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={false}
            disabled={!props.isScheduleFormValid}
            onClick={props.onRecalculateSchedule}
          >
            {t(`form.schedule_form.recalculate_schedule`)}
          </LoadingButton>
        </Grid>}

      {scheduleKinds?.map((kind) => {
        return (
          <Grid item xs={props.isScheduleUpdated ? 4 : 3.5}>
            <EditableBoxForm
              kind={kind}
              readOnly={props.readOnly}
              data={props.icoSchedules[kind]}
              onEditRow={props.onDataChange}
              setTranchesChangedByUser={props.setTranchesChangedByUser}
              setInstallmentsChangedByUser={props.setInstallmentsChangedByUser}
              setInterestsChangedByUser={props.setInterestsChangedByUser}
              setScheduleUpdated={props.setScheduleUpdated}
              showWarning={getShowWaring(kind)}
              areDateValid={getDatesValidation(kind)}
              isSumValid={getSumValidation(kind)}
              isFirstDateAfterActivationDateValid = {getFirstDateAfterActivationDateValidation(kind)}
              isPartialInstallmentSumIsValid = {getPartialInstallmentSumIsValid(kind)}
            />
          </Grid>

        )
      })
      }
      <Grid item xs={12}>
        <ValidationForm
          icoScheduleShouldBeUpdated={!props.isScheduleUpdated}
        />
      </Grid>
    </Grid>
  );
});

IcoScheduleForm.propTypes = {
  readOnly: PropTypes.bool,
  icoId: PropTypes.string,
  onChangeSchedule: PropTypes.func,
  icoSchedules: PropTypes.array,
  isScheduleUpdated: PropTypes.bool,
  isScheduleFormValid: PropTypes.bool
};

IcoScheduleForm.defaultProps = {
  readOnly: false,
  icoSchedules: [],
  isScheduleUpdated: true,
  isScheduleFormValid: false
};

export default IcoScheduleForm;