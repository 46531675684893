import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import React, { useEffect, useState } from "react";
import usePurchaseService from "../../../services/purchaseService";
import { useAsync } from "../../../hooks/useAsync";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import TableService from "../../../services/tableService";
import useDocsData from "../../../hooks/useDocsData";
import BaseTable from "../../base/BaseTable/BaseTable";
import { PO_TABLE_FIELDS_CONFIG } from "./TableConfig";
import { useTranslation } from "react-i18next";


const PurchasePreviewDialog = (props) => {

  const [poListLocal, setPoListLocal] = useState([])
  const { t } = useTranslation();

  const {
    getPoFlatListData
  } = usePurchaseService()

  const {
    onDownloadDoc,
    onPreviewDoc,
  } = useDocsData();


  const poList = useAsync(() => {
    if (props.fvId) {
      return getPoFlatListData({'fv' : props.fvId})
    }
    return Promise.resolve([])
  }, [props.poId])

  useEffect(() => {
    if (poList.loading) {
      return;
    }
    if (poList.value) {
      setPoListLocal(poList.value)
    }
  }, [poList.loading]);



  if (poListLocal.loading) return <LoaderWrapper showLoader={true} />;
  return (

    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={`${t("dialog.po_preview_dialog.purchases")}${props.fvNumber}`}
      maxWidth="xl"
      showDialogActions={false}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >

        <Grid item xs={12}>
          <BaseTable
            showContextMenu={false}
            showNoRecordsPaper={false}
            headers={TableService.getHeaderLables(
              PO_TABLE_FIELDS_CONFIG  
            )}
            rows={TableService.getPreparedDataForBaseTable(
              PO_TABLE_FIELDS_CONFIG ,
              poListLocal,
              [],
              [],
              onPreviewDoc,
              onDownloadDoc
            )}
          />
        </Grid>
      </Grid>
    </BasicDialog>
  );
};

PurchasePreviewDialog .propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  fvId : PropTypes.string,
  fvNumber : PropTypes.string
};

PurchasePreviewDialog .defaultProps = {
  open: false,
};

export default PurchasePreviewDialog ;
