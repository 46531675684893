import i18n from "../../../i18n";


export const TRANSFERS_TABLE_FIELDS_CONFIG = [
  {
    name: "transfer_date",
    getValueCallback: (rowData) => rowData.transfer_date,
    label: i18n.t("table.transfer_table.transfer_date"),
  },  
  {
    name: "transfer_payer",
    getValueCallback: (rowData) => rowData.transfer_payer_name,
    label: i18n.t("table.transfer_table.transfer_payer"),
  },
  {
    name: "transfer_receiver",
    getValueCallback: (rowData) => rowData.transfer_receiver_name,
    label: i18n.t("table.transfer_table.transfer_receiver"),
  },
  {
    name: "transfer_payer_iban",
    getValueCallback: (rowData) => rowData.transfer_payer_iban,
    label: i18n.t("table.transfer_table.transfer_payer_iban"),
  },
  {
    name: "transfer_receiver_iban",
    getValueCallback: (rowData) => rowData.transfer_receiver_iban,
    label: i18n.t("table.transfer_table.transfer_receiver_iban"),
  },
  {
    name: "currency",
    getValueCallback: (rowData) => rowData.currency,
    label: i18n.t("table.transfer_table.currency"),
  },
  {
    name: "transfer_amount",
    getValueCallback: (rowData) => rowData.transfer_amount,
    label: i18n.t("table.transfer_table.transfer_amount"),
  },
  {
    name: "transfer_title",
    getValueCallback: (rowData) => rowData.transfer_title,
    label: i18n.t("table.transfer_table.transfer_title")
  },
];
