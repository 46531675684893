import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, IconButton, Typography } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import InfoIcon from "@mui/icons-material/Info";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";

import { convertObjectListFromBackendToTranslatedOptionObject } from "../../../helpers/methods";

import MiniButton from "../../base/MiniButton/MiniButton";
import WindowService from "../../../services/windowService";

const RelatedTicketRecord = React.memo((props) => {
  const { t } = useTranslation();;

  const onChange = (e) => {
    props.onChange(props.index, e);
  };

  const onChangeAutocompleteFieldWithObjectOptions = (...inputProps) =>
    props.onChangeAutocompleteFieldWithObjectOptions(
      props.index,
      ...inputProps
    );

  const getTicTicKindOptions = () => {
    return convertObjectListFromBackendToTranslatedOptionObject(
      props.ticketTicketKindSelectList.filter(
        (ttk) => ttk.is_allowed_to_manual_edit === !props.readOnly
      ),
      "tictic_kind"
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
            <Typography variant="subtitle2" className="capitalize-first-letter">
              {t("other.related_ticket_record.relation")}
              {props.showRelationNumber ? ` ${props.index + 1}` : ""}
            </Typography>
            {props.ticket && (
              <IconButton
                aria-label="info"
                size="small"
                sx={{ padding: 0, paddingLeft: 0.5, color: "var(--primary)" }}
                onClick={() => {
                  WindowService.openInNewTab(`/ticket_details/${props.ticket}`);
                }}
              >
                <InfoIcon fontSize="inherit" />
              </IconButton>
            )}
            {props.showButtonDeleteRelation && !props.readOnly && (
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ padding: 0, paddingLeft: 0.5, color: "var(--alert)" }}
                onClick={() => {
                  props.onDeleteRelation(props.index);
                }}
              >
                <RemoveCircleIcon fontSize="inherit" />
              </IconButton>
            )}
          </Grid>
          <Grid item sm={6} xs={6} textAlign={"end"}>
            {props.showButtonAddRelation && (
              <MiniButton
                variant="contained"
                size="small"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={props.onAddRelation}
              >
                {t("other.related_ticket_record.add_relation")}
              </MiniButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <AutocompleteField
              name="ticket"
              label={t("other.related_ticket_record.ticket")}
              value={props.ticket}
              options={props.ticketSelectList}
              isObjectOption={true}
              optionLabelKey={"ticket_descriptive_name"}
              addNewValue={false}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              readOnly={props.readOnly}
              required
            />
          </Grid>
          <Grid item xs={2}>
            <AutocompleteField
              name="tictic_kind"
              label={t("other.related_ticket_record.tictic_kind")}
              value={props.tictic_kind}
              options={getTicTicKindOptions()}
              isObjectOption={true}
              optionLabelKey={"tictic_kind"}
              addNewValue={false}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              required
              readOnly={props.readOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldFormControl
              name="tictic_note"
              label={t("other.related_ticket_record.tictic_note")}
              value={props.tictic_note}
              onChange={onChange}
              multiline
              rows={2}
              disabled={props.readOnly}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

RelatedTicketRecord.propTypes = {
  index: PropTypes.number,
  ticket: PropTypes.string,
  tictic_kind: PropTypes.string,
  tictic_note: PropTypes.string,
  onChange: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  showRelationNumber: PropTypes.bool,
  showButtonDeleteRelation: PropTypes.bool,
  onDeleteRelation: PropTypes.func,
  showButtonAddRelation: PropTypes.bool,
  onAddRelation: PropTypes.func,
  ticketTicketKindSelectList: PropTypes.array,
  ticketSelectList: PropTypes.array,
  readOnly: PropTypes.bool,
};

RelatedTicketRecord.defaultProps = {
  showRelationNumber: true,
  showButtonDeleteRelation: true,
  showButtonAddRelation: true,
  readOnly: false,
};

export default RelatedTicketRecord;
