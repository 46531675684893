import PropTypes from "prop-types";
import i18n from "../../../i18n";
import BaseTable from "../../base/BaseTable/BaseTable";
import TableService from "../../../services/tableService";
import DateService from "../../../services/dateService";
import SelectFieldService from "../../../services/selectFieldService";
import { AUTOCOMPLETE_FIELD_TYPE, BOOLEAN_FIELD_TYPE, DATETIME_TO_DATE_RANGE_FIELD_TYPE, NUMERIC_RANGE_FIELD_TYPE } from "../../../helpers/constants";


export const MATERIAL_RESERVATIONS_TABLE_NAME = "Material";
export const MATERIAL_RESERVATIONS_TABLE_FIELDS_CONFIG = [
  {
    name: "warehouse",
    getValueCallback: (rowData) => rowData.warehouse,
    label: i18n.t("table.stock_warehouse_table.warehouse_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.warehouses,
      isObjectOption: true,
      optionLabelKey: "warehouse_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "reservation_amount",
    getValueCallback: (rowData) => rowData.reservation_amount,
    label: i18n.t("tabs.stock_transaction_related_tables.amount"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "created",
    getValueCallback: (rowData) => DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("tabs.stock_transaction_related_tables.reservation_date"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: () => undefined,
  },
  {
    name: "user",
    getValueCallback: (rowData) => rowData.user,
    label: i18n.t("tabs.stock_transaction_related_tables.reservation_user"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.users,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "reservation_date_closed",
    getValueCallback: (rowData) => rowData.reservation_closed_date,
    label: i18n.t("tabs.stock_transaction_related_tables.reservation_date_closed"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: () => undefined,
  },
  {
    name: "reservation_closed",
    getValueCallback: (rowData) => SelectFieldService.getLabelOptionsTrueOrFalse(rowData.reservation_closed),
    label: i18n.t("tabs.stock_transaction_related_tables.reservation_closed"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];

const MaterialReservationTable = (props) => {
  return (
    <>
      <BaseTable
        headers={TableService.getHeaderLables(
          MATERIAL_RESERVATIONS_TABLE_FIELDS_CONFIG,
          props.hiddenColumns
        )}
        showNoRecordsPaper={props.showNoRecordsPaper}
        style={props.style}
        rows={TableService.getPreparedDataForBaseTable(
          MATERIAL_RESERVATIONS_TABLE_FIELDS_CONFIG,
          props.hiddenColumns,

        )}
      />
    </>
  );
};

MaterialReservationTable.propTypes = {
  style: PropTypes.object,
  hiddenColumns: PropTypes.array,
  showNoRecordsPaper: PropTypes.bool,
};

MaterialReservationTable.defaultProps = {
  hiddenColumns: [],
  showNoRecordsPaper: true,
};

export default MaterialReservationTable;
