import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useTranslation } from "react-i18next";
import useDialogWithFormValue from "../../../hooks/useDialogWithFormValue";
import UserHasSkillTable from "../../table/UserHasSkillTable";
import { useAsync } from "../../../hooks/useAsync";
import useUserService from "../../../services/userService";
import CreateUserSkillDialog from "../../dialog/CreateUserSkillDialog";
import useSkillService from "../../../services/skillService";
import { useAuth } from "../../../context/auth";
import { useMemo, useState, useEffect } from "react";
import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../hooks/useFilterSearchParams";
import { isSearchParamsObjectEmpty } from "../../../helpers/methods";

const USER_SKILL_TABLE_FILTER_PREFIX = "user_skill_table";

const UserSkill = (props) => {
  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams(USER_SKILL_TABLE_FILTER_PREFIX);

  const { setNewItemSearchParamsIfAreChanged, getSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);

  const [userSkillSearchParams, setUserSkillSearchParams] = useState(() =>
    getSearchParamsByFilterPrefix(USER_SKILL_TABLE_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      USER_SKILL_TABLE_FILTER_PREFIX,
      userSkillSearchParams,
      setUserSkillSearchParams
    );
  }, [searchParams]);

  const { getEmployeeSkill } = useUserService();

  const employeeSkill = useAsync(
    () => getEmployeeSkill(userSkillSearchParams),
    [userSkillSearchParams]
  );

  const [canEditEmployeers, setCanEditEmployeers] = useState(false);

  const { user } = useAuth();

  const { getSkillsUserIsLeaderSelectList } = useSkillService();

  const [
    openCreateUserSkillDialog,
    createUserSkillDialogFormValue,
    handleOpenCreateUserSkillDialog,
    handleCloseCreateUserSkillDialog,
  ] = useDialogWithFormValue();

  const onClickEdit = (userData) => {
    handleOpenCreateUserSkillDialog({
      user: userData.id,
      skills: userData.userskill.map((x) => x.skill.id),
    });
  };

  const onCloseCreateSkillDialog = (saved = false) => {
    if (saved) {
      setSkillEmployeeSkillLocaly(undefined);
      employeeSkill.refetch();
      props.onChanges();
    }
    handleCloseCreateUserSkillDialog();
  };

  const skillsUserIsLeaderSelectList = useAsync(
    () => getSkillsUserIsLeaderSelectList(),
    []
  );

  const skillIdsUserIsLeader = useMemo(() => {
    if (skillsUserIsLeaderSelectList.value) {
      let list = skillsUserIsLeaderSelectList.value.map((x) => x.id);
      if (list.length > 0 || user.is_admin) {
        setCanEditEmployeers(true);
      }
      return list;
    }
    return [];
  }, [skillsUserIsLeaderSelectList.value]);

  const [employeeSkillLocaly, setSkillEmployeeSkillLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (employeeSkill.loading) {
      return;
    }
    setSkillEmployeeSkillLocaly(employeeSkill.value.results);
    setCountRecords(employeeSkill.value.count);
  }, [employeeSkill.loading]);

  return (
    <LoaderWrapper
      showLoader={
        employeeSkillLocaly === undefined ||
        skillsUserIsLeaderSelectList.loading
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <UserHasSkillTable
            usersHasSkills={employeeSkillLocaly}
            filteringData={skillsUserIsLeaderSelectList.value}
            filterPrefix={USER_SKILL_TABLE_FILTER_PREFIX}
            canEdit={canEditEmployeers}
            onClickEdit={onClickEdit}
            countRecords={countRecords}
            page={page}
            onPageChange={handleChangePageWithSearchParams}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            initialExpandedFilterHeader={
              !isSearchParamsObjectEmpty(userSkillSearchParams)
            }
            resetPageNumber={resetPageNumber}
          />
        </Grid>
      </Grid>
      {openCreateUserSkillDialog && (
        <CreateUserSkillDialog
          open={openCreateUserSkillDialog}
          onClose={onCloseCreateSkillDialog}
          initForm={createUserSkillDialogFormValue}
          skillIdsUserIsLeader={skillIdsUserIsLeader}
        />
      )}
    </LoaderWrapper>
  );
};

UserSkill.propTypes = {
  onChanges: PropTypes.func,
};

export default UserSkill;
