import { Grid } from "@mui/material";
import ExportToFileButton from "../../button/ExportToFileButton";
import BasicPaginationWithRowsPerPage from "../../base/BasicPaginationWithRowsPerPage";
import PropTypes from "prop-types";
import Badge from "@mui/material/Badge";
import CounterRecords from "../CounterRecords";

const BottomTable = (props) => {
  return (
    <>
      {(props.withPagination ||
        props.showExportToFileButton ||
        props.showCounterRecords) && (
        <Grid container>
          {(props.showExportToFileButton || props.showCounterRecords) && (
            <Grid
              item
              xs={12}
              sm={props.withPagination ? 3 : 12}
              paddingTop={"14px"}
              textAlign={{ xs: "center", sm: "left" }}
            >
              {props.showExportToFileButton && (
                <Badge
                  color="error"
                  badgeContent={props.countRecords}
                  max={999}
                  size="small"
                >
                  <ExportToFileButton
                    exportFileUrl={props.exportToFileUrl}
                    searchParams={props.exportToFileSearchParams}
                    headers={props.exportToFileHeaders}
                    fileName={props.exportToFileFileName}
                    exportToFileRequestContent={
                      props.exportToFileRequestContent
                    }
                  />
                </Badge>
              )}
              {props.showCounterRecords && !props.showExportToFileButton && (
                <CounterRecords value={props.countRecords} />
              )}
            </Grid>
          )}
          {props.withPagination && (
            <Grid
              item
              xs={12}
              sm={
                props.showExportToFileButton || props.showCounterRecords
                  ? 9
                  : 12
              }
              textAlign={{ xs: "center", sm: "right" }}
            >
              <BasicPaginationWithRowsPerPage
                page={props.page}
                countRecords={props.countRecords}
                onPageChange={props.onPageChange}
                rowsPerPage={props.rowsPerPage}
                onRowsPerPageChange={props.onRowsPerPageChange}
                rowsPerPageOptions={props.rowsPerPageOptions}
                filterPrefix={props.filterPrefix}
                pageKey={props.pageKey}
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};
BottomTable.propTypes = {
  withPagination: PropTypes.bool,
  showExportToFileButton: PropTypes.bool,
  exportToFileUrl: PropTypes.string,
  exportToFileSearchParams: PropTypes.object,
  exportToFileHeaders: PropTypes.array,
  exportToFileFileName: PropTypes.string,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageKey: PropTypes.string,
  countRecords: PropTypes.number,
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  showCounterRecords: PropTypes.bool,
  exportToFileRequestContent: PropTypes.object,
  filterPrefix: PropTypes.string,
};

BottomTable.defaultProps = {
  withPagination: true,
  showExportToFileButton: false,
  showCounterRecords: true,
};

export default BottomTable;
