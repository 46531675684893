import { Button, Grid, Typography } from "@mui/material";
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseBox from "../../base/BaseBox/baseBox";
import MailForm from "../../form/MailForm";

export default function MailFormBox({ mails, onEditMail, onAddMail, onRemoveMail, readOnly, subcontractorId }) {
    const { t } = useTranslation();

    return (
        <BaseBox>
            <Grid container spacing={1}>
                <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
                    <Typography
                        variant="subtitle2"
                        className="capitalize-first-letter"
                    >
                        {subcontractorId ? t(`other.mail_form_box.subcontractor_mails`) : t(`other.mail_form_box.owner_mails`)}
                    </Typography>
                </Grid>
                <Grid item sm={6} xs={6} />
                {mails?.map((mail, index) => {
                    return (
                        <MailForm
                            mail={mail}
                            index = {index}
                            onEditMail={onEditMail}
                            onRemoveMail={onRemoveMail}
                            readOnly={readOnly}
                            subcontractorId={subcontractorId}
                        />
                    )
                })
                }
                <Grid item xs={12}>
                    
                {!readOnly &&
                    <Button
                        fullWidth
                        variant="text"
                        size="small"
                        onClick={onAddMail}
                    >
                        {t("other.mail_form_box.add_mail")}
                    </Button>}
                </Grid>
            </Grid>
        </BaseBox>
    );
}

MailFormBox.propTypes = {
    mails : PropTypes.array,
    onEditMail : PropTypes.func,
    onAddMail : PropTypes.func,
    onRemoveMail : PropTypes.func,
    readOnly: PropTypes.bool
}


MailFormBox.defaultProps = {
    mails : [],
    readOnly: true
}
