import { Button, Grid, Typography } from "@mui/material";
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseBox from "../../base/BaseBox/baseBox";
import FullAddressForm from "../../form/FullAddressForm";

export default function AddressFormBox({ addresses, onEditAddress, onAddAddress, onRemoveAddress, readOnly, subcontractorId }) {
    const { t } = useTranslation();

    return (
        <BaseBox>
            <Grid container spacing={1}>
                <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
                    <Typography
                        variant="subtitle2"
                        className="capitalize-first-letter"
                    >
                        {subcontractorId ?t(`other.address_form_box.subcontractor_addresses`) :t(`other.address_form_box.owner_addresses`)}
                    </Typography>
                </Grid>
                <Grid item sm={6} xs={6} />
                {addresses?.map((address, index) => {
                    return (
                        <FullAddressForm
                            index = {index}
                            address={address}
                            onEditAddress={onEditAddress}
                            onRemoveAddress = {onRemoveAddress}
                            readOnly = {readOnly}
                            subcontractorId={subcontractorId}
                        />
                    )
                })
                }
                <Grid item xs={12}>
                    
                {!readOnly &&
                    <Button
                        fullWidth
                        variant="text"
                        size="small"
                        onClick={onAddAddress}
                    >
                        {t("other.address_form_box.add_address")}
                    </Button>
}
                </Grid>
            </Grid>
        </BaseBox>
    );
}

AddressFormBox.propTypes = {
    onClose: PropTypes.func,
    readOnly: PropTypes.bool,
    validatedOffer: PropTypes.object,
    onSendOfferToRR: PropTypes.func,
    onSendOfferToOM: PropTypes.func,
    downloadFileByBlobUrl: PropTypes.func,
    fileIsEmpty: PropTypes.bool,
    pdfUrl: PropTypes.string
}


AddressFormBox.defaultProps = {
    readOnly: true,
    validatedOffer: {},
    fileIsEmpty: true

}
