import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ErrorForm from "../../components/form/ErrorForm";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import FeedbackForm from "../../components/form/FeedbackForm/FeedbackForm";
import PageFooter from "../../components/pageFooter/PageFooter";
import useFeedbackService from "../../services/feedbackService";
import { useAsync } from "../../hooks/useAsync";
import { getErrorMsg } from "../../helpers/methods";

function FeedbackPage() {
  const { t } = useTranslation();
  const { ticketId, rateId } = useParams();

  const prepareDataToSend = () => ({
    ticket: ticketId,
    rate: rateId,
  });

  const { sendFeedback } = useFeedbackService();

  const sendFeedbackAsync = useAsync(
    () => sendFeedback(prepareDataToSend()),
    [ticketId, rateId]
  );

  return (
    <LoaderWrapper showLoader={sendFeedbackAsync.loading}>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            style={{ minHeight: "75vh" }}
          >
            {sendFeedbackAsync.error ? (
              <ErrorForm
                style={{ maxWidth: "600px", padding: "10px" }}
                errorMessage={getErrorMsg(sendFeedbackAsync.error.data)}
                showRefreshBtn={false}
              />
            ) : (
              <FeedbackForm
                feedbackId={
                  sendFeedbackAsync.value && sendFeedbackAsync.value.id
                }
                ticketId={ticketId}
                style={{ maxWidth: "600px", padding: "10px" }}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} marginTop={3}>
          <PageFooter />
        </Grid>
      </Grid>
    </LoaderWrapper>
  );
}

export default FeedbackPage;
