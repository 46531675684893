import { useEffect, useMemo } from "react";

import PropTypes from "prop-types";

import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import { useAsync } from "../../../hooks/useAsync";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useForm } from "../../../hooks/useForm";

import useFilterSearchParams from "../../../hooks/useFilterSearchParams";
import useTicketService from "../../../services/ticketService";

import TicketFeedbackChartToolBarForm from "./TicketFeedbackChartToolBarForm";
import UniversalFilterForm from "../../form/UniversalFilterForm";

import useDialog from "../../../hooks/useDialog";
import UserFilterDialog from "../../dialog/UserFilterDialog";

import {
  getSearchParamsObjectForFilterPrefix,
  getFilterSearchParamsKeyForField,
  isEmptyArray,
  isEmptyValue,
} from "../../../helpers/methods";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

import {
  overflowButtonStyle,
  centerVericalAlignIconStyle,
} from "../../../helpers/styles";

const UserFilteringDialogForm = (props) => {
  return (
    <>
      <TicketFeedbackChartToolBarForm
        {...props}
        onlyFormContent={false}
        isVerticalForm={true}
        filteringData={props.filteringData}
        showSelectTenant={props.showSelectTenant}
        showSelectObjectLevel2={props.showSelectObjectLevel2}
        showOpenCloseFilterButton={props.showOpenCloseFilterButton}
        showTenantVisibilityButton={props.showTenantVisibilityButton}
      />
      <UniversalFilterForm
        {...props}
        filteringData={props.tableFilteringData}
        filtersConfig={props.tableFieldConfigs}
        style={{ marginTop: "4px" }}
      />
    </>
  );
};

export default function TicketFeedbackChartToolBar(props) {
  const { t } = useTranslation();

  const [
    openUserFiltersDialog,
    onOpenUserFiltersDialog,
    onCloseUserFiltersDialog,
  ] = useDialog();

  const { getTicketChartFilteringData } = useTicketService();

  const filteringData = useAsync(() =>
    getTicketChartFilteringData({ include_tenant: true })
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const { getSearchParamsByFilterPrefix, clearSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);

  const {
    formValue,
    setFormValue,
    onChangeDateConvertedToDateTimeWithEndHourOfDate,
    onChangeDateConvertedToDateTimeWithZeroHourOfDate,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm(getSearchParamsByFilterPrefix(props.filterPrefix));

  useEffect(() => {
    setFormValue(getSearchParamsByFilterPrefix(props.filterPrefix));
  }, [searchParams]);

  const handleSearch = () => {
    setSearchParams(() => ({
      ...clearSearchParamsByFilterPrefix(props.filterPrefix),
      ...getSearchParamsObjectForFilterPrefix(formValue, props.filterPrefix),
      [getFilterSearchParamsKeyForField("page", props.filterPrefix)]: 1,
    }));
  };
  const handleCleanFilters = () => {
    setFormValue(() => ({}));
    setSearchParams(() => clearSearchParamsByFilterPrefix(props.filterPrefix));
  };

  const isLoading = formValue === undefined || !filteringData.value;

  const isFormValid = () => {
    let requiredFields = [];

    return requiredFields.every((field) =>
      Array.isArray(formValue[field])
        ? !isEmptyArray(formValue[field])
        : !isEmptyValue(formValue[field])
    );
  };

  const excludedFieldsForUserFilterDialog = useMemo(() => {
    let excludedFields = ["ticket_date_closeALL_real"];

    if (!props.showSelectTenant) {
      excludedFields.push("tenant");
    }

    if (!props.showSelectObjectLevel2) {
      excludedFields.push("objects_level_2_id");
    }

    return excludedFields;
  }, [props.showSelectTenant, props.showSelectObjectLevel2]);

  const tableFiledsConfigForUserFilteringDialog = useMemo(
    () =>
      props.tableFieldConfigs.filter(
        (config) => !excludedFieldsForUserFilterDialog.includes(config.name)
      ),

    [props.tableFieldConfigs, excludedFieldsForUserFilterDialog]
  );

  return (
    <LoaderWrapper showLoader={isLoading}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        columnSpacing={3}
        rowSpacing={2}
        marginY={1}
      >
        {props.showMyFiltersButton && (
          <Grid item xs={12} md={1.5}>
            <Button
              fullWidth
              variant="contained"
              size="small"
              startIcon={<FilterAltIcon sx={centerVericalAlignIconStyle} />}
              onClick={onOpenUserFiltersDialog}
              color={"primary"}
              sx={overflowButtonStyle}
            >
              {t("bar.tool_bar.my_filters")}
            </Button>
          </Grid>
        )}
        <TicketFeedbackChartToolBarForm
          onlyFormContent={true}
          formValue={formValue}
          setFormValue={setFormValue}
          onChangeDateConvertedToDateTimeWithEndHourOfDate={
            onChangeDateConvertedToDateTimeWithEndHourOfDate
          }
          onChangeDateConvertedToDateTimeWithZeroHourOfDate={
            onChangeDateConvertedToDateTimeWithZeroHourOfDate
          }
          onChangeDate={onChangeDate}
          onChangeAutocompleteFieldWithObjectOptions={
            onChangeAutocompleteFieldWithObjectOptions
          }
          isVerticalForm={false}
          filteringData={filteringData}
          showSelectTenant={props.showSelectTenant}
          showSelectObjectLevel2={props.showSelectObjectLevel2}
        />

        <Grid
          container
          columnSpacing={2}
          item
          xs={12}
          md={props.showClearFiltersIcon ? 2.5 : 1.5}
          justifyContent={"flex-end"}
        >
          <Grid item xs={12} md={props.showClearFiltersIcon ? 5 : 12}>
            <Button
              variant="contained"
              color="success"
              size="small"
              fullWidth
              disabled={!isFormValid()}
              onClick={handleSearch}
            >
              {t("search")}
            </Button>
          </Grid>
          {props.showClearFiltersIcon && (
            <Grid item xs={12} md={7}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                onClick={handleCleanFilters}
              >
                {t("clean_filters")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      {openUserFiltersDialog && (
        <UserFilterDialog
          open={openUserFiltersDialog}
          onClose={onCloseUserFiltersDialog}
          pageName={props.pageName}
          filterPrefix={props.filterPrefix}
          filterForm={
            <UserFilteringDialogForm
              {...props}
              filteringData={filteringData}
              tableFieldConfigs={tableFiledsConfigForUserFilteringDialog}
            />
          }
        />
      )}
    </LoaderWrapper>
  );
}

TicketFeedbackChartToolBar.propTypes = {
  pageName: PropTypes.string,
  filterPrefix: PropTypes.string,
  showMyFiltersButton: PropTypes.bool,
  showSelectTenant: PropTypes.bool,
  showSelectObjectLevel2: PropTypes.bool,
  showClearFiltersIcon: PropTypes.bool,
};

TicketFeedbackChartToolBar.defaultProps = {
  showMyFiltersButton: true,
  showSelectTenant: false,
  showSelectObjectLevel2: false,
  showClearFiltersIcon: true,
};
