import { useState, useEffect, useCallback, useRef, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import {
  ORDER_TABLE_NAME,
  ORDER_TABLE_FIELDS_CONFIG,
} from "../OrderManagementPage/PageTablesConfig";

import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import UniversalToolBar from "../../components/bar/UniversalToolBar";

import useOrderService from "../../services/orderService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";

import useDialog from "../../hooks/useDialog";
import FilterDialog from "../../components/base/FilterDialog";
import UserFilterDialog from "../../components/dialog/UserFilterDialog";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";
import MultipleLocalizationDialog from "../../components/dialog/MutlipleLocalizationDialog";
import ChangeStatusDialog from "../../components/dialog/ChangeStatusDialog";
import AddDeleteFlagDialog from "../../components/dialog/AddDeleteFlagDialog";

import UniversalFilterForm from "../../components/form/UniversalFilterForm";

import TableService from "../../services/tableService";

import DetailsDrawer from "../../components/drawer/UniversalDetailsDrawer";

import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";

import useBasicDrawer from "../../hooks/useBasicDrawer";
// import CreateOrderDialog from "../../components/dialog/CreateOrderDialog";
// import { isSearchParamsEmpty } from "../../helpers/methods";
import SelectTicketToAddOrderDialog from "../../components/dialog/SelectTicketToAddOrderDialog";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import OrderFilterTable from "../../components/table/OrderFilterTable";
import { exportMyOrdersToFileUrl } from "../../helpers/apiUrls";
import usePermissionService from "../../services/permissionService";

const TABLE_CONFIGS = [
  { name: ORDER_TABLE_NAME, config: ORDER_TABLE_FIELDS_CONFIG },
];

export default function MyOrdersPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [orderDataLocaly, setOrderDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();

  const [isDialogWithParamsClosed, setIsDialogWithParamsClosed] =
    useState(true);

  const { showChangesCircle } = usePermissionService();

  const {
    page: orderDataPage,
    pageSize: orderDataPageSize,
    handleChangePageWithSearchParams: handleChangeOrderDataPage,
    handleChangePageSizeWithSearchParams: handleChangeOrderDataPageSize,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const handleChangeRowsPerPage = (e) => {
    handleChangeOrderDataPageSize(e, parseInt(e.target.value, 10));
  };

  const {
    getMyOrdersData,
    updateOrderData,
    getOrderDetailsData,
    getOrderFilteringData,
    addOrderToFavority,
    deleteOrderFromFavority,
    deleteFlagsfromOrders,
    updateMassOrderData,
  } = useOrderService();

  const orderData = useAsync(
    () => {
      if (isDialogWithParamsClosed) {
        return getMyOrdersData(searchParams)
      } else {
        return Promise.resolve([])
      }
    },
    [searchParams, isDialogWithParamsClosed]
  );

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const fullRefreshTable = () => {
    setOrderDataLocaly(undefined);
    orderData.refetch();
  };

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();
  useEffect(() => {
    if (userPreferencesForPage.loading) {
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [ORDER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [ORDER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        fullRefreshTable();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const orderFilteringData = useAsync(getOrderFilteringData);

  useEffect(() => {
    if (orderData.loading) {
      return;
    }
    setOrderDataLocaly(orderData.value.results);
    setCountRecords(orderData.value.count);
  }, [orderData.loading]);

  const handleClickCheck = useCallback((id) => {
    setOrderDataLocaly((prevData) => {
      let selectedRow = { ...prevData.find((rowData) => rowData.id === id) };
      selectedRow.selected = !selectedRow.selected;
      return prevData.map((rowData) => {
        if (rowData.id === id) return selectedRow;
        return rowData;
      });
    });
  }, []);

  const [checkedAll, setCheckedAll] = useState(false);
  const handleClickCheckAll = useCallback(
    (value) => {
      let currValue = !checkedAll;
      setCheckedAll(currValue);

      setOrderDataLocaly((prev) => {
        return prev.map((rowData) => ({
          ...rowData,
          selected: currValue,
        }));
      });
    },
    [checkedAll]
  );

  const getSeletedOrderIds = () =>
    orderDataLocaly.filter((order) => order.selected).map((order) => order.id);

  const addOrdersToFavorityFn = useAsyncFn(addOrderToFavority);

  const handleAddOrderForUserFavority = () => {
    let orderIds = getSeletedOrderIds();
    if (orderIds.length === 0) return;

    addOrdersToFavorityFn
      .execute(orderIds)
      .then((res) => {
        setOrderDataLocaly(undefined);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.order_to_favority_added")
        );
        orderData.refetch();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_adding_order_to_favority")
        );
      });
  };

  const deleteOrdersFromFavorityFn = useAsyncFn(deleteOrderFromFavority);
  const handleDeleteOrdersFromFavority = () => {
    let orderIds = getSeletedOrderIds();
    if (orderIds.length === 0) return;
    deleteOrdersFromFavorityFn
      .execute(orderIds)
      .then((res) => {
        setOrderDataLocaly(undefined);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.order_from_favority_deleted")
        );
        orderData.refetch();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_deleting_order_from_favority")
        );
      });
  };

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const [
    openUserFiltersDialog,
    onOpenUserFiltersDialog,
    onCloseUserFiltersDialog,
  ] = useDialog();

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();

  //   const [
  //     openSelectTicketToAddOrderDialog,
  //     onOpenSelectTicketToAddOrderDialog,
  //     onCloseSelectTicketToAddOrderDialog,
  //   ] = useDialog();

  //   const [
  //     openCreateOrderDialog,
  //     onOpenCreateOrderDialog,
  //     onCloseCreateOrderDialog,
  //   ] = useDialog();

  const [
    openAddDeleteFlagsToOrderDialog,
    onOpenAddDeleteFlagsToOrderDialog,
    onCloseAddDeleteFlagsToOrderDialog,
  ] = useDialog();

  const [
    openChangeOrderStatusDialog,
    onOpenChangeOrderStatusDialog,
    onCloseChangeOrderStatusDialog,
  ] = useDialog();

  //   const selectedTicketIdToAddOrder = useRef();
  //   const handleSelectTicketToAddOrderDialog = (ticketId) => {
  //     selectedTicketIdToAddOrder.current = ticketId;
  //     onCloseSelectTicketToAddOrderDialog();
  //     onOpenCreateOrderDialog();
  //   };

  const clickedOrderMultipleLocalization = useRef();
  const handleOpenMultipleLocalizationDialog = useCallback(
    (e, orderId) => {
      e.stopPropagation();
      clickedOrderMultipleLocalization.current = orderDataLocaly.find(
        (order) => order.id === orderId
      ).locations;
      onOpenMultipleLocalizationDialog();
    },
    [onOpenMultipleLocalizationDialog, orderDataLocaly]
  );

  const handleCloseMultipleLocalizationDialog = () => {
    clickedOrderMultipleLocalization.current = null;
    onCloseMultipleLocalizationDialog();
  };

  const onCleanFlitersInFilterDialog = () => {
    setOrderDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const isLoading =
    userPreferencesForPage.loading ||
    hiddenColumnsForTables === undefined ||
    columnsOrdersForTables === undefined ||
    orderFilteringData.loading ||
    orderDataLocaly === undefined ||
    countRecords === undefined;

  const [openDetailsDrawer, setOpenDetailsDrawer] = useBasicDrawer(false);
  const openedOrderIdRef = useRef();
  const opendedOrderDetailsDataRef = useRef();
  const getOrderDetailsDataFn = useAsyncFn(getOrderDetailsData);
  const handleOpenDetailsDrawer = useCallback(
    (orderId) => {
      if (
        openDetailsDrawer &&
        (!orderId || orderId === opendedOrderDetailsDataRef.current?.id)
      ) {
        setOpenDetailsDrawer(false);
        openedOrderIdRef.current = null;
        opendedOrderDetailsDataRef.current = null;
      } else {
        setOpenDetailsDrawer(true);
        openedOrderIdRef.current = orderId;
        executeGetOrderDetailsDataFn(orderId);
      }

      setOrderDataLocaly((prevData) => {
        return prevData.map((rowData) => {
          if (rowData.id === orderId)
            return {
              ...rowData,
              has_changes: false,
            };
          return rowData;
        });
      });
    },
    [openDetailsDrawer, openedOrderIdRef.current]
  );

  const executeGetOrderDetailsDataFn = (orderId) => {
    getOrderDetailsDataFn
      .execute(orderId)
      .then((data) => (opendedOrderDetailsDataRef.current = data));
  };

  const updateOrderDataFn = useAsyncFn(updateOrderData);

  const onUpdateOrderDataPromise = (orderId, dataToSend) => {
    return updateOrderDataFn
      .execute(orderId, dataToSend)
      .then((data) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.order_data_updated")
        );
        orderData.refetch();
        return Promise.resolve(data);
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_updating_order_data")
        );
        return Promise.reject(error);
      });
  };
  //   const onCloseCreateOrderDialogLocal = (refreshList = false) => {
  //     if (refreshList) {
  //       if (isSearchParamsEmpty(searchParams)) {
  //         orderData.refetch();
  //       } else {
  //         setSearchParams({});
  //       }
  //     }
  //     onCloseCreateOrderDialog();
  //   };

  const flagDialogModeRef = useRef();
  const handleOpenAddDeleteFlagsToOrderDialog = (dialogMode) => {
    if (getSeletedOrderIds().length === 0) return;
    flagDialogModeRef.current = dialogMode;
    onOpenAddDeleteFlagsToOrderDialog();
  };

  const handleAddDeleteFlagsToOrders = () => {
    orderData.refetch();
    onCloseAddDeleteFlagsToOrderDialog();
  };

  const handleOpenChangeOrderStatusDialog = () => {
    if (getSeletedOrderIds().length === 0) return;
    onOpenChangeOrderStatusDialog();
  };

  const updateMassOrderDataFn = useAsyncFn(updateMassOrderData);

  const prepareOrderDataForMassUpdate = (dataToSend) => {
    return getSeletedOrderIds().map((id) => ({
      id,
      ...dataToSend,
    }));
  };

  const handleChangeMassOrderStatus = (statusData) => {
    updateMassOrderDataFn
      .execute(prepareOrderDataForMassUpdate({ status_data: statusData }))
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.mass_status_changed")
        );
        onCloseChangeOrderStatusDialog();
        orderData.refetch();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_order_mass_status_change")
        );
      });
  };

  const massActionButtonOptions = [
    {
      label: t("page.order_management_page.mass_status_change"),
      callback: handleOpenChangeOrderStatusDialog,
    },
    {
      label: t("page.order_management_page.add_order_to_favority"),
      callback: handleAddOrderForUserFavority,
    },
    {
      label: t("page.order_management_page.delete_order_from_favority"),
      callback: handleDeleteOrdersFromFavority,
    },
    {
      label: t("page.order_management_page.add_flags_to_orders"),
      callback: () => handleOpenAddDeleteFlagsToOrderDialog("add"),
    },
    {
      label: t("page.order_management_page.delete_flags_from_orders"),
      callback: () => handleOpenAddDeleteFlagsToOrderDialog("delete"),
    },
  ];

  const orderExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      ORDER_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[ORDER_TABLE_NAME],
      columnsOrdersForTables?.[ORDER_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[ORDER_TABLE_NAME],
    columnsOrdersForTables?.[ORDER_TABLE_NAME],
    ORDER_TABLE_FIELDS_CONFIG,
  ]);

  return (
    <DetailsDrawer
      openDrawer={openDetailsDrawer}
      setOpenDrawer={handleOpenDetailsDrawer}
      itemData={opendedOrderDetailsDataRef.current}
      filteringData={orderFilteringData?.value}
      itemType={"order"}
      isLoading={
        getOrderDetailsDataFn.loading || !opendedOrderDetailsDataRef.current
      }
      onUpdateDataPromise={onUpdateOrderDataPromise}
      onOpenDialogParamsAction={setIsDialogWithParamsClosed}
      onRefetchData={() =>
        executeGetOrderDetailsDataFn(opendedOrderDetailsDataRef.current?.id)
      }
      onRefetchTableData={fullRefreshTable}
      hasPermissionToManageOrders={true}
      availableActionButtons={["enclosures", "change_status", "create_task"]}
    >
      <NavigationDrawer pageName={pageName}>
        <DefaultPageWrapper titleKey={"my_orders"}>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <UniversalToolBar
                style={{ marginBlock: "5px" }}
                massActionButtonOptions={massActionButtonOptions}
                onClickSearch={onOpenFilterDialog}
                onClickMyFilters={onOpenUserFiltersDialog}
                onClickAdjustTable={onOpenTableColumnVisibilityDialog}
                // onClickCreateItem={onOpenSelectTicketToAddOrderDialog}
                showCreateButton={false}
                itemType={"order"}
              />
            </Grid>
            <Grid item xs={12}>
              <LoaderWrapper showLoader={isLoading}>
                <OrderFilterTable
                  showDetailsIcon={true}
                  showChangesCircle={showChangesCircle}
                  data={orderDataLocaly}
                  showCheckbox={true}
                  checkedAll={checkedAll}
                  showCleanFilterIcon={false}
                  onClickRow={handleOpenDetailsDrawer}
                  onClickCheck={handleClickCheck}
                  onClickCheckAll={handleClickCheckAll}
                  countRecords={countRecords}
                  page={orderDataPage}
                  onPageChange={handleChangeOrderDataPage}
                  rowsPerPage={orderDataPageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  filteringData={orderFilteringData}
                  hiddenColumns={
                    hiddenColumnsForTables
                      ? hiddenColumnsForTables[ORDER_TABLE_NAME]
                      : []
                  }
                  columnsOrders={
                    columnsOrdersForTables
                      ? columnsOrdersForTables[ORDER_TABLE_NAME]
                      : undefined
                  }
                  selectedOrderId={openedOrderIdRef.current}
                  style={{ maxHeight: "75vh" }}
                  tableConfig={ORDER_TABLE_FIELDS_CONFIG}
                  onClickMultipleLocationAlert={
                    handleOpenMultipleLocalizationDialog
                  }
                  showExportToFileButton={true}
                  exportToFileUrl={exportMyOrdersToFileUrl}
                  exportToFileSearchParams={searchParams}
                  exportToFileHeaders={orderExportHeaders}
                  exportToFileFileName={`${t(
                    "page.order_management_page.orders"
                  ).replace(" ", "_")}.xlsx`}
                />
              </LoaderWrapper>
            </Grid>
          </Grid>
          <FilterDialog
            open={openFilterDialog}
            onCleanFilters={onCleanFlitersInFilterDialog}
            onClose={onCloseFilterDialog}
            resetPageNumber={resetPageNumber}
            filterForm={
              <UniversalFilterForm
                filteringData={orderFilteringData}
                filtersConfig={ORDER_TABLE_FIELDS_CONFIG}
                includeOpenCloseFilter={true}
                includeHasChangesDotFilter={true}
              />
            }
          />
          {openUserFiltersDialog && (
            <UserFilterDialog
              open={openUserFiltersDialog}
              onClose={onCloseUserFiltersDialog}
              pageName={pageName}
              filterForm={
                <UniversalFilterForm
                  filteringData={orderFilteringData}
                  filtersConfig={ORDER_TABLE_FIELDS_CONFIG}
                  includeOpenCloseFilter={true}
                  includeHasChangesDotFilter={true}
                />
              }
            />
          )}
          {openTableColumnVisibilityDialog && (
            <TableColumnVisibilityDialog
              open={openTableColumnVisibilityDialog}
              onClose={onCloseTableColumnVisibilityDialog}
              onSubmit={handleUpdateUserPreferences}
              tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
                TABLE_CONFIGS,
                columnsOrdersForTables
              )}
              tablesHiddenColumns={hiddenColumnsForTables}
              isLoading={userPreferencesForPage.loading}
            />
          )}
          {openMultipleLocalizationDialog &&
            clickedOrderMultipleLocalization.current && (
              <MultipleLocalizationDialog
                open={openMultipleLocalizationDialog}
                onClose={handleCloseMultipleLocalizationDialog}
                localizationData={clickedOrderMultipleLocalization.current}
                itemType={"order"}
              />
            )}
          {/* {openSelectTicketToAddOrderDialog && (
            <SelectTicketToAddOrderDialog
              open={openSelectTicketToAddOrderDialog}
              onClose={onCloseSelectTicketToAddOrderDialog}
              onSubmit={handleSelectTicketToAddOrderDialog}
            />
          )} */}
          {/* {openCreateOrderDialog && (
            <CreateOrderDialog
              open={openCreateOrderDialog}
              onClose={onCloseCreateOrderDialogLocal}
              ticketId={selectedTicketIdToAddOrder.current}
            />
          )} */}

          {openAddDeleteFlagsToOrderDialog && (
            <AddDeleteFlagDialog
              open={openAddDeleteFlagsToOrderDialog}
              onClose={onCloseAddDeleteFlagsToOrderDialog}
              itemType={"order"}
              itemIds={getSeletedOrderIds()}
              onSubmit={handleAddDeleteFlagsToOrders}
              dialogMode={flagDialogModeRef.current}
            />
          )}

          {openChangeOrderStatusDialog && (
            <ChangeStatusDialog
              open={openChangeOrderStatusDialog}
              onClose={onCloseChangeOrderStatusDialog}
              itemType={"order"}
              onSubmit={handleChangeMassOrderStatus}
              isLoading={updateMassOrderDataFn.loading}
              isMassAction={true}
            />
          )}
        </DefaultPageWrapper>
      </NavigationDrawer>
    </DetailsDrawer>
  );
}
