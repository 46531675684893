import { Grid, IconButton, Typography } from "@mui/material";
import React from 'react';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import BaseBox from "../../base/BaseBox/baseBox";
import TextFieldFormControl from "../../field/TextFieldFormControl";

export default function BoxWithTypography(props) {
    const { t } = useTranslation();

    return (
        <BaseBox
        error={props.error}
        warning={props.warning}
        >
            <Grid container spacing={1}>
                <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
                    <Typography
                        variant="subtitle2"
                        className="capitalize-first-letter"
                    >
                        {props.label}
                    </Typography>
                </Grid>
                <Grid item sm={5} xs={5} />

            </Grid>
            <div style={props.style}>{props.children}</div>
        </BaseBox>
    );
}

BoxWithTypography.propTypes = {
    style: PropTypes.object,
    children: PropTypes.object,
    error : PropTypes.bool
}


BoxWithTypography.defaultProps = {
    error :false
}
