import { AUTOCOMPLETE_FIELD_TYPE,  BUFFER_EDIT_PERMISSION, CREATE_INVOCIE_PERMISSION, CREATE_PURCHASE_PERMISSION, MANAGE_PARTS_PERMISSION } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import permissionTableService from "../../../../services/permissionTableService";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export const USER_WAREHOUSE_PERMISSION_TABLE_CONFIG = [
  {
    name: "user",
    getValueCallback: (rowData) => rowData.user_fullname,
    label: i18n.t("table.other_warehouse_perm_table.user_with_warehouse_role"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.users,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },

  {
    name: "part_manage",
    getValueCallback: (rowData) => permissionTableService.checkPermission(rowData.permissions, MANAGE_PARTS_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.other_warehouse_perm_table.part_manage"),
    filterType: undefined
  },
  {
    name: "create_purchase",
    getValueCallback: (rowData)=> permissionTableService.checkPermission(rowData.permissions, CREATE_PURCHASE_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.other_warehouse_perm_table.create_purchase"),
    filterType: undefined
  },
  {
    name: "create_invoice",
    getValueCallback: (rowData)=> permissionTableService.checkPermission(rowData.permissions, CREATE_INVOCIE_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.other_warehouse_perm_table.create_invoice"),
    filterType: undefined
  },
  {
    name: "buffer_edit",
    getValueCallback: (rowData)=> permissionTableService.checkPermission(rowData.permissions, BUFFER_EDIT_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.other_warehouse_perm_table.buffer_edit"),
    filterType: undefined
  },

  {
    name: "edit",
    getValueCallback: (rowData, onClickDialog) => {
      return (
        <ModeEditOutlineOutlined
          onClick={(e) => onClickDialog(e, rowData.id, "edit")}
        />
      );
    },
    label: i18n.t("table.other_warehouse_perm_table.edit"),
    filterType: null
  },
  {
    name: "copy",
    getValueCallback: (rowData, onClickDialog, ) => {
      return (
        <ContentCopyIcon
          onClick={(e) => onClickDialog(e, rowData.id, "copy")}
        />
      );
    },
    label: i18n.t("table.other_warehouse_perm_table.copy_permissions"),
    filterType: null
  }
];
