import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import useFilterService from "../../../services/filterService";
import { useAsync } from "../../../hooks/useAsync";

import { useAuth } from "../../../context/auth";
import {
  isEmptyArray,
  getDefaultFilterValues,
  getDefaultFilterValuesFromDefaultAndUserFilters,
  isEmptyObject,
} from "../../../helpers/methods";

import useLocalStorage from "../../../hooks/useLocalStorage";

const FilterRoute = ({
  pageName,
  defaultFilters,
  localStorageKey,
  children,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  const [localStorageSearchParams] = useLocalStorage(localStorageKey);

  const { getUserFilters } = useFilterService();
  const defaultUserFilter = useAsync(
    () =>
      getUserFilters({
        filter_page: pageName,
        user: user.user_id,
        filter_is_default: true,
      }),
    []
  );

  useEffect(() => {
    if (defaultUserFilter.loading) return;

    // set default filter if user don't refresh page
    if (!searchParams.size) {
      let filterValues = null;

      if (
        !isEmptyArray(defaultUserFilter.value) &&
        !isEmptyArray(defaultFilters)
      ) {
        filterValues = getDefaultFilterValuesFromDefaultAndUserFilters(
          defaultFilters,
          defaultUserFilter.value
        );
      } else if (!isEmptyArray(defaultUserFilter.value)) {
        filterValues = getDefaultFilterValues(defaultUserFilter.value);
      } else if (!isEmptyArray(defaultFilters)) {
        filterValues = getDefaultFilterValues(defaultFilters);
      }

      if (
        localStorageKey &&
        localStorageSearchParams &&
        !isEmptyObject(localStorageSearchParams)
      ) {
        if (filterValues) {
          setSearchParams({ ...filterValues, ...localStorageSearchParams });
        } else {
          setSearchParams(localStorageSearchParams);
        }
      } else if (filterValues) {
        setSearchParams(filterValues);
      }
    }
    setIsLoading(false);
  }, [defaultUserFilter.loading]);

  if (isLoading) return <LoaderWrapper showLoader={true} />;
  return React.cloneElement(children, { pageName: pageName });
};

FilterRoute.propTypes = {
  pageName: PropTypes.string,
  localStorageKey: PropTypes.string,
  defaultFilters: PropTypes.arrayOf(
    PropTypes.shape({
      filter_prefix: PropTypes.string,
      filter_value: PropTypes.object,
    })
  ),
};

FilterRoute.defaultProps = {
  defaultFilters: [],
};

export default FilterRoute;
