import { Grid } from "@mui/material";
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MiniButton from "../../base/MiniButton";

export default function TopCommunicationDiaryBox(props) {
    const { t } = useTranslation();

    return (
        <Grid container spacing={1}
            alignContent="center"
            direction="row"
            alignItems="center"
            justifyContent="flex-start">
            {props.filterTypes.length > 0 &&
                <>
                    <FilterAltIcon style={{ marginTop: "7px", marginLeft: "5px" }} />
                    {props.filterTypes.map((filterType) => (
                        <Grid item xs={12} sm={props.buttonsGridSm} key={`button_group_type_${filterType.key}`}>
                            <MiniButton
                                sx={{
                                    width: "100%",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                }}
                                size={"small"}
                                fullWidth
                                onClick={() => { props.onChooseFilterGroup(filterType.key); }}
                                variant={props.chosenFilterGroup.includes(filterType.key) ? "contained" : "outlined"}
                                color={filterType.color}
                            >
                                {t(`dialog.message_dialog.${filterType.name}`)}
                            </MiniButton>
                        </Grid>
                    ))}
                </>
            }
        </Grid>
    )
}

TopCommunicationDiaryBox.propTypes = {
    filterTypes: PropTypes.array,
    chosenFilterGroup: PropTypes.array,
    onChooseFilterGroup: PropTypes.func,
    buttonsGridSm: PropTypes.number
}


TopCommunicationDiaryBox.defaultProps = {
    filterTypes: [],
    chosenFilterGroup: [],
    buttonsGridSm: 1
}
