import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TuneIcon from "@mui/icons-material/Tune";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import {
  centerVericalAlignIconStyle,
  overflowButtonStyle,
} from "../../../helpers/styles";
import SearchButton from "../../button/SearchButton/SearchButton";
import ActiveDesactiveButton from "../../button/ActiveDesactiveButton/ActiveDesactiveButton";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import { useSearchParams } from "react-router-dom";
import { isSearchParamsForFilteringEmpty } from "../../../helpers/methods";

export default function OwnerManagmentToolBar(props) {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const isFilterSet =
    props.isFilterSet !== undefined
      ? props.isFilterSet
      : !isSearchParamsForFilteringEmpty(searchParams);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      columnSpacing={1}
      rowSpacing={2}
      style={props.style}
    >        
    {props.hasPermission &&
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={
            props.ownerId  ?
            <ModeEditOutlineOutlined  sx={centerVericalAlignIconStyle} />
            :
            <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
          }
          onClick={props.onClickCreateOwner}
        >
          {props.ownerId ? t("bar.owner_tool_bar.edit_owner") :t("bar.owner_tool_bar.create_owner")}
        </Button>
      </Grid>}
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<TuneIcon sx={centerVericalAlignIconStyle} />}
          onClick={props.onClickAdjustTable}
        >
          {t("bar.owner_tool_bar.adjust_table")}
        </Button>
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<FilterAltIcon sx={centerVericalAlignIconStyle} />}
          onClick={props.onClickMyFilters}
          sx={overflowButtonStyle}
          color={isFilterSet ? "success" : "primary"}
        >
          {t("bar.tool_bar.my_filters")}
        </Button>
      </Grid>
      {!props.hasPermission &&  <Grid item xs={12} sm={2}/>}
      <Grid item md={3.7} sx={{ display: { xs: "none", sm: "flex" } }} />
      <Grid item xs={12} sm={2}>
        <ActiveDesactiveButton
          openFilterKey={props.openFilterKey}
          params={props.params}
        />
      </Grid>

      <Grid item xs={12} md={0.2} textAlign={{ xs: "center", md: "right" }}>
        <SearchButton onClickSearch={props.onClickSearch} />
      </Grid>
    </Grid>
  );
}

OwnerManagmentToolBar.propTypes = {
  style: PropTypes.object,
  onClickAdjustTable: PropTypes.func,
  onClickCreateOfferWithoutTicket: PropTypes.func,
  setVisibilityTicketTableView: PropTypes.func,
  isTicketTableView: PropTypes.bool,
  massActionButtonOptions: PropTypes.array,
};

OwnerManagmentToolBar.defaultProps = {
  isTicketTableView: true,
  massActionButtonOptions: [],
};
