import i18n from "../../i18n";

import { Typography } from "@mui/material";

import {
  TEXT_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
} from "../../helpers/constants";

import { getStyleForSLACell } from "../../helpers/styles";

import DateService from "../../services/dateService";
import WindowService from "../../services/windowService";

import WarningAlertIcon from "../../components/other/WarningAlertIcon/WarningAlertIcon";
import PrefixSuffixFieldTableWrapper from "../../components/wrapper/PrefixSuffixFieldTableWrapper";

export const TICKET_SLA_TABLE_NAME = "ticket";

export const TICKET_SLA_TABLE_FIELDS_CONFIG = [
  {
    name: "ticket_nr",
    getValueCallback: (rowData) => rowData.ticket_nr,
    label: i18n.t("table.ticket.ticket_nr"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "ticket_name",
    getValueCallback: (rowData) => rowData.ticket_name,
    label: i18n.t("table.ticket.ticket_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
  },

  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.ticket.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.ticket.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "100px" },
    }),
    includeExcludedFilter: true,
  },
  {
    name: "ticket_owner",
    getValueCallback: (rowData) => rowData.ticket_owner,
    label: i18n.t("table.ticket.ticket_owner"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_owner,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_owner_email",
    getValueCallback: (rowData) => rowData.ticket_owner_email,
    label: i18n.t("table.ticket.ticket_owner_email"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "ticket_owner_phone",
    getValueCallback: (rowData) => rowData.ticket_owner_phone,
    label: i18n.t("table.ticket.ticket_owner_phone"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "ticket_desc_creator",
    getValueCallback: (rowData) => rowData.ticket_desc_creator,
    label: i18n.t("table.ticket.ticket_desc_creator"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "objects_level_1",
    getValueCallback: (rowData, onClickMultipleLocationAlert) => {
      const content = (
        <PrefixSuffixFieldTableWrapper
          prefix={
            <WarningAlertIcon
              onClick={(e) => onClickMultipleLocationAlert(e, rowData.id)}
            />
          }
        >
          {rowData.locations[0].level_1}
        </PrefixSuffixFieldTableWrapper>
      );
      return rowData.is_multiple_locations
        ? content
        : rowData.locations[0].level_1;
    },
    label: i18n.t("table.ticket.object_level_1"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_2",
    getValueCallback: (rowData) => rowData.locations[0].level_2,
    label: i18n.t("table.ticket.object_level_2"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_3",
    getValueCallback: (rowData) => rowData.locations[0].level_3,
    label: i18n.t("table.ticket.object_level_3"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_4",
    getValueCallback: (rowData) => rowData.locations[0].level_4,
    label: i18n.t("table.ticket.object_level_4"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_5",
    getValueCallback: (rowData) => rowData.locations[0].level_5,
    label: i18n.t("table.ticket.object_level_5"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },

  {
    name: "ticket_category",
    getValueCallback: (rowData) => rowData.ticket_category,
    label: i18n.t("table.ticket.ticket_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_category,
      isObjectOption: true,
      optionLabelKey: "tc_name",
      addNewValue: false,
      multiple: true,
    }),
  },

  {
    name: "ticket_status",
    getValueCallback: (rowData) => rowData.ticket_status,
    label: i18n.t("table.ticket.ticket_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_status,
      isObjectOption: true,
      optionLabelKey: "status_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_substatus",
    getValueCallback: (rowData) => rowData.ticket_substatus,
    label: i18n.t("table.ticket.ticket_substatus"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_substatus,
      isObjectOption: true,
      optionLabelKey: "substatus_name",
      addNewValue: false,
      multiple: true,
    }),
  },

  {
    name: "ticket_k",
    getValueCallback: (rowData) => rowData.ticket_k,
    label: i18n.t("table.ticket.ticket_k"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_k,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },

  {
    name: "related_tickets",
    sortable: true,
    getValueCallback: (rowData) => {
      return rowData?.related_tickets ? (
        <div style={{ display: "flex" }}>
          {rowData.related_tickets.map((ticket) => (
            <Typography
              onClick={() =>
                WindowService.openInNewTab(`/ticket_details/${ticket.id}`)
              }
              style={{ margin: 0, color: "var(--primary)" }}
            >
              {ticket.ticket_nr}{" "}
            </Typography>
          ))}
        </div>
      ) : null;
    },
    label: i18n.t("table.ticket.related_tickets"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.related_tickets,
      isObjectOption: true,
      optionLabelKey: "ticket_nr",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "base_ticket_name_for_claim",
    getValueCallback: (rowData) => rowData.base_ticket_for_claim?.ticket_name,
    label: i18n.t("table.ticket.base_ticket_name_for_claim"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "base_ticket_category_for_claim",
    getValueCallback: (rowData) =>
      rowData.base_ticket_for_claim?.ticket_category,
    label: i18n.t("table.ticket.base_ticket_category_for_claim"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_category,
      isObjectOption: true,
      optionLabelKey: "tc_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "sla",
    getValueCallback: (rowData) => rowData.sla,
    sortable: true,
    label: i18n.t("table.ticket.sla"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "sla_lead_time",
    getValueCallback: (rowData) => rowData.sla_lead_time,
    sortable: true,
    label: i18n.t("table.ticket.sla_lead_time"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "sla_delay_time",
    getValueCallback: (rowData) => rowData.sla_delay_time,
    cellStyle: (rowData) => getStyleForSLACell(rowData.sla_delay_time),
    sortable: true,
    label: i18n.t("table.ticket.sla_delay_time"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      allowNegative: true,
    }),
  },
  {
    name: "feedback_rate_name",
    getValueCallback: (rowData) => rowData.feedback_rate_name,
    label: i18n.t("table.feedback.rate_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.rate_name,
      isObjectOption: true,
      optionLabelKey: "rate_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "feedback_created",
    getValueCallback: (rowData) =>
      DateService.convertDateToFormatYYYYMMDD(rowData.feedback_created),
    label: i18n.t("table.feedback.updated"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  {
    name: "feedback_note",
    getValueCallback: (rowData) => rowData.feedback_note,
    label: i18n.t("table.feedback.feedback_note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];
