import i18n from "../../../../i18n";

import { Grid } from "@mui/material";

import { RcpScheduleTableCell } from "./RcpScheduleTableCell";

import { RCP_ACTIVITIES } from "../../../../helpers/constants";

import {
  FREE_DAY,
  WORK_DAY,
  WORK_AFTERNOON,
  WORK_NIGHT,
  EMPTY_CELL_COLOR,
  RCP_PLAN_COLORS,
  RCP_DONE_COLORS,
} from "../Config";

export const RcpScheduleTableLegendHeaders = () => {
  return (
    <Grid item xs={12} container>
      <Grid item xs={4}>
        <RcpScheduleTableCell
          value={i18n.t(`dialog.rcp_schedule_dialog.activity`)}
        />
      </Grid>
      <Grid item xs={4}>
        <RcpScheduleTableCell
          value={i18n.t(`dialog.rcp_schedule_dialog.rcp_plan_full`)}
          bgColor={RCP_PLAN_COLORS[FREE_DAY]}
        />
      </Grid>
      <Grid item xs={4}>
        <RcpScheduleTableCell
          value={i18n.t(`dialog.rcp_schedule_dialog.rcp_done_full`)}
          bgColor={RCP_DONE_COLORS[FREE_DAY]}
          fnColor={EMPTY_CELL_COLOR}
        />
      </Grid>
    </Grid>
  );
};

export const RcpScheduleTableLegendRow = ({ label, colorKey }) => {
  return (
    <Grid item xs={12} container>
      <Grid item xs={4}>
        <RcpScheduleTableCell value={label} />
      </Grid>
      <Grid item xs={4}>
        <RcpScheduleTableCell bgColor={RCP_PLAN_COLORS[colorKey]} />
      </Grid>
      <Grid item xs={4}>
        <RcpScheduleTableCell bgColor={RCP_DONE_COLORS[colorKey]} />
      </Grid>
    </Grid>
  );
};

export const RcpScheduleTableLegend = () => {
  const cofings = [
    {
      label: i18n.t(`dialog.rcp_schedule_dialog.day_shift`),
      colorKey: WORK_DAY,
    },
    {
      label: i18n.t(`dialog.rcp_schedule_dialog.afternoon_shift`),
      colorKey: WORK_AFTERNOON,
    },
    {
      label: i18n.t(`dialog.rcp_schedule_dialog.night_shift`),
      colorKey: WORK_NIGHT,
    },
    {
      label: i18n.t(`dialog.rcp_schedule_dialog.delegation`),
      colorKey: RCP_ACTIVITIES.DELEGATION,
    },
    {
      label: i18n.t(`dialog.rcp_schedule_dialog.vacation`),
      colorKey: RCP_ACTIVITIES.VACATION,
    },
    {
      label: i18n.t(`dialog.rcp_schedule_dialog.sick_leave`),
      colorKey: RCP_ACTIVITIES.SICK_LEAVE,
    },
  ];

  return (
    <Grid container>
      <RcpScheduleTableLegendHeaders />
      {cofings.map((config) => (
        <RcpScheduleTableLegendRow
          label={config.label}
          key={config.colorKey}
          colorKey={config.colorKey}
        />
      ))}
    </Grid>
  );
};
