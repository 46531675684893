import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {
  mailDetailsUrl,
  createMailUrl,
  ownerMailUrl,
  subcontractorMailUrl
} from "../helpers/apiUrls";


export default function useMailService() {
  const axiosPrivate = useAxiosPrivate();


  const getMailDetails = (mailId) => {
    if (mailId){
      return makeRequest(axiosPrivate, mailDetailsUrl(mailId));
    }
    return Promise.resolve([]);
  };

  const createMail = (mailData) =>{
    return makeRequest(axiosPrivate, createMailUrl, {
      method: "POST",
      data: mailData,
    });
  };

  const updateMail = (mailId, mailData) => {
    return makeRequest(axiosPrivate, mailDetailsUrl(mailId), {
      method: "PATCH",
      data: mailData,
    });
  };


  const removeMailOwner = (ownerMailId) => {
    return makeRequest(axiosPrivate, ownerMailUrl(ownerMailId), {
      method: "PATCH",
      data: {owma_active : false},
    });
  };

  const removeMailSubcontractor = (subMailId) => {
    return makeRequest(axiosPrivate, subcontractorMailUrl(subMailId), {
      method: "PATCH",
      data: {suma_active : false},
    });
  };

  return {
    getMailDetails,
    createMail,
    updateMail,
    removeMailOwner,
    removeMailSubcontractor
  };
}
