import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import useUserService from "../../../services/userService";
import TransferForm from "../../form/TransferForm/TransferForm";
import { useForm } from "../../../hooks/useForm";
import { useAsync } from "../../../hooks/useAsync";

// TODO 
// -required fields
// -walidacja długości  tytułu przelewu

function TransferDialog(props) {

    const { t } = useTranslation();

    const {
        getIbanShortData
    } = useUserService();

    const {
        formValue,
        setFormValue,
        onChange,
        onChangeDate,
        onChangeAutocompleteFieldWithObjectOptions,
    } = useForm({
        currency: props.currency?.id, transfer_payer: props.borrower?.id,
        transfer_payer_name: props.borrower?.owner_short_name,
        transfer_receiver: props.lender?.id,
        transfer_receiver_name: props.lender?.owner_short_name,
        currency_name: props.currency?.currency_name
    });


    const lenderBankAccounts = useAsync(
        () => getIbanShortData({ owner: props.lender?.id, currency: props.currency?.id }),
        [props.lender, props.currency]);

    const borrowerBankAccounts = useAsync(
        () => getIbanShortData({ owner: props.borrower?.id, currency: props.currency?.id }),
        [props.lender, props.currency]);

    return (
        <BasicDialog
            open={props.open}
            onClose={() => {
                props.onClose()
            }}
            titleAlign="center"
            contentAlign="center"
            title={props.title ? props.title : t("dialog.add_transfer_dialog.new_transfer")}
            maxWidth="sm"
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
            >
                <Grid item xs={12}>
                    <TransferForm
                        transferData={formValue}
                        lenderBankAccounts={lenderBankAccounts}
                        borrowerBankAccounts={borrowerBankAccounts}
                        onChange={onChange}
                        onChangeDate={onChangeDate}
                        onChangeAutocomplete={onChangeAutocompleteFieldWithObjectOptions}
                    />
                </Grid>
            </Grid>
        </BasicDialog>
    )

}

TransferDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};
TransferDialog.defaultProps = {
    open: false,

};

export default TransferDialog