import { useState, useEffect, useMemo, useRef } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import BoxWithLabel from "../../../../box/BoxWithLabel";
import LoaderWrapper from "../../../../wrapper/LoaderWrapper";

import { useAsync } from "../../../../../hooks/useAsync";

import usePaginationWithSearchParams from "../../../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../../../hooks/useFilterSearchParams";

import useUserService from "../../../../../services/userService";

import UniversalToolBarWithDialogs from "../../../../bar/UniversalToolBarWithDialogs";

import TableService from "../../../../../services/tableService";
import UserTenantTable from "../../../../table/UserTenantTable";
import { exportUserTenantListToFileUrl } from "../../../../../helpers/apiUrls";

import { isSearchParamsObjectEmpty } from "../../../../../helpers/methods";

import {
  USER_TENANT_TABLE_FILTER_PREFIX,
  USER_TENANT_TABLE_NAME,
  USER_TENANT_TABLE_CONFIG,
} from "./TableConfig";

const tableConfig = {
  name: USER_TENANT_TABLE_NAME,
  config: USER_TENANT_TABLE_CONFIG,
};

const RRAdminUserTenantTable = (props) => {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(
    USER_TENANT_TABLE_FILTER_PREFIX,
    undefined,
    100
  );

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefix,
  } = useFilterSearchParams(searchParams);
  const [userTenantsSearchParams, setUserTenantsSearchParams] = useState(
    getSearchParamsByFilterPrefix(USER_TENANT_TABLE_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      USER_TENANT_TABLE_FILTER_PREFIX,
      userTenantsSearchParams,
      setUserTenantsSearchParams
    );
  }, [searchParams]);

  const { getUserTenantList, getUserTenantFilteringData } = useUserService();

  const userTenantData = useAsync(
    () =>
      getUserTenantList({
        ...userTenantsSearchParams,
        page_size: userTenantsSearchParams.page_size || 100,
        uste_rr_visibility: true,
        is_tenant_rr_visibility: true,
      }),
    [userTenantsSearchParams]
  );
  const userTenantFilteringData = useAsync(getUserTenantFilteringData);

  const [userTenantDataLocaly, setUserTenantDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (userTenantData.loading) {
      return;
    }
    setUserTenantDataLocaly(userTenantData.value.results);
    setCountRecords(userTenantData.value.count);
  }, [userTenantData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState({});
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState({});

  const refreshTable = () => {
    setUserTenantDataLocaly(undefined);
    userTenantData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setUserTenantDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const userTenantExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      USER_TENANT_TABLE_CONFIG,
      hiddenColumnsForTables?.[USER_TENANT_TABLE_NAME],
      columnsOrdersForTables?.[USER_TENANT_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[USER_TENANT_TABLE_NAME],
    columnsOrdersForTables?.[USER_TENANT_TABLE_NAME],
    USER_TENANT_TABLE_CONFIG,
  ]);

  const isLoading =
    userTenantDataLocaly === undefined || userTenantFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <UniversalToolBarWithDialogs
          pageName={props.pageName}
          showOpenCloseFilterButton={false}
          showMyFiltersButton={false}
          showSearchButton={false}
          tableConfig={tableConfig}
          filteringData={userTenantFilteringData}
          refreshTable={refreshTable}
          onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
          resetPageNumber={resetPageNumber}
          hiddenColumnsForTables={hiddenColumnsForTables}
          setHiddenColumnsForTables={setHiddenColumnsForTables}
          columnsOrdersForTables={columnsOrdersForTables}
          setColumnsOrdersForTables={setColumnsOrdersForTables}
          filterPrefix={USER_TENANT_TABLE_FILTER_PREFIX}
          showCleanfiltersButton={false}
        />
      </Grid>
      <Grid item xs={12}>
        {hiddenColumnsForTables && (
          <UserTenantTable
            showCleanFilterIcon={true}
            style={{ maxHeight: "60vh" }}
            showFilters={true}
            initialExpandedFilterHeader={true}
            data={userTenantDataLocaly}
            filteringData={userTenantFilteringData}
            filterPrefix={USER_TENANT_TABLE_FILTER_PREFIX}
            countRecords={countRecords}
            page={page}
            onPageChange={handleChangePageWithSearchParams}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            resetPageNumber={resetPageNumber}
            tableConfig={USER_TENANT_TABLE_CONFIG}
            hiddenColumns={hiddenColumnsForTables[USER_TENANT_TABLE_NAME]}
            columnsOrders={columnsOrdersForTables[USER_TENANT_TABLE_NAME]}
            showExportToFileButton={true}
            exportToFileUrl={exportUserTenantListToFileUrl}
            exportToFileSearchParams={{
              ...userTenantsSearchParams,
              uste_rr_visibility: true,
              is_tenant_rr_visibility: true,
            }}
            exportToFileHeaders={userTenantExportHeaders}
            exportToFileFileName={`${t("tabs.rr_admin.contacts")}.xlsx`}
          />
        )}
      </Grid>
    </Grid>
  );
};

RRAdminUserTenantTable.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

RRAdminUserTenantTable.defaultProps = {};

export default RRAdminUserTenantTable;
