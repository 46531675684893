import { useEffect, useState, useRef } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import OfferTable from "../../table/OfferTable/OfferTable";

import OfferDocumentDialog from "../OfferDocumentDialog";

import useOfferService from "../../../services/offerService";

import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import useDialog from "../../../hooks/useDialog";

import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";

import { OFFER_TABLE_FIELDS_CONFIG } from "./TableConfig";

import useFileService from "../../../services/fileService";
import usePermissionService from "../../../services/permissionService";
import { isSearchParamsEmpty } from "../../../helpers/methods";

function OffersForTicketDialog(props) {
  const { t } = useTranslation();
  const [countRecords, setCountRecords] = useState();
  const [offerDataLocaly, setOfferDataLocaly] = useState();

  const { showCreteOfferButtonInOffersForTicketDialog } =
    usePermissionService();

  const [
    openOfferDocumentDialog,
    onOpenOfferDocumentDialog,
    onCloseOfferDocumentDialog,
  ] = useDialog();

  const {
    page: offerDataPage,
    pageSize: offerDataPageSize,
    handleChangePageWithSearchParams: handleChangeOfferDataPage,
    handleChangePageSizeWithSearchParams: handleChangeOfferDataPageSize,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const { getOfferListByTicket, getOffersFilteringData } = useOfferService();

  const offerData = useAsync(
    () => getOfferListByTicket(props.ticketId, searchParams),
    [searchParams]
  );

  const offerFilteringData = useAsync(getOffersFilteringData);

  const {
    getEnclosuresForItemData,
    handleDownloadEnclosure,
    handlePreviewEnclosure,
  } = useFileService();

  const getEnclosuresForItemDataFn = useAsyncFn(getEnclosuresForItemData);

  useEffect(() => {
    if (offerData.loading) {
      return;
    }
    setOfferDataLocaly(offerData.value.results);
    setCountRecords(offerData.value.count);
  }, [offerData.loading]);

  const isLoading =
    offerFilteringData.loading ||
    offerDataLocaly === undefined ||
    countRecords === undefined;

  const selectedOfferDataRef = useRef();
  const enclosuresForSelectedOfferRef = useRef();
  const handleClickPreview = (e, offerId) => {
    selectedOfferDataRef.current = { id: offerId };
    getEnclosuresForItemDataFn
      .execute(offerId, undefined, "offer")
      .then((res) => {
        enclosuresForSelectedOfferRef.current = res;
        onOpenOfferDocumentDialog();
      });
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={`${t("dialog.offers_for_ticket_dialog.offers_for_ticket")} ${
        props.ticketNumber
      }`}
      maxWidth="xl"
    >
      <LoaderWrapper showLoader={isLoading}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowGap={3}
        >
          <Grid item xs={12}>
            <OfferTable
              data={offerDataLocaly}
              onClickPreview={handleClickPreview}
              showCheckbox={false}
              countRecords={countRecords}
              page={offerDataPage}
              onPageChange={handleChangeOfferDataPage}
              rowsPerPage={offerDataPageSize}
              onRowsPerPageChange={handleChangeOfferDataPageSize}
              resetPageNumber={resetPageNumber}
              filteringData={offerFilteringData}
              hiddenColumns={[]}
              showTitle={false}
              tableConfig={OFFER_TABLE_FIELDS_CONFIG}
              showContextMenu={false}
              initialExpandedFilterHeader={!isSearchParamsEmpty(searchParams)}
            />
          </Grid>
          {showCreteOfferButtonInOffersForTicketDialog && !props.readOnly && (
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                size="small"
                onClick={props.onClickSendOffer}
              >
                {t("dialog.offers_for_ticket_dialog.create_offer")}
              </Button>
            </Grid>
          )}

          {openOfferDocumentDialog && (
            <OfferDocumentDialog
              open={openOfferDocumentDialog}
              onClose={onCloseOfferDocumentDialog}
              validatedOffer={selectedOfferDataRef.current}
              enclosures={enclosuresForSelectedOfferRef.current}
              onDownloadEnclosure={handleDownloadEnclosure}
              onPreviewEnclosure={handlePreviewEnclosure}
              showBottomButtonGrid={false}
              readOnly={true}
            />
          )}
        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
}

OffersForTicketDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ticketId: PropTypes.string,
  ticketNumber: PropTypes.number,
  onClickSendOffer: PropTypes.func,
  readOnly: PropTypes.bool,
};
OffersForTicketDialog.defaultProps = {
  open: false,
};

export default OffersForTicketDialog;
