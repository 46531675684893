import PropTypes from "prop-types";

import i18n from "../../../i18n";

import SplitButton from "../SplitButton";

function SelectObjectCategorySubcategoryButton(props) {
  const filterOptions = [
    {
      label: i18n.t("button.select_category_subcategory.category"),
      key: props.categoryFilterKey,
      callback: () => props.onChangeFilterValue(props.categoryFilterKey),
    },
    {
      label: i18n.t("button.select_category_subcategory.subcategory"),
      key: props.subcategoryFilterKey,
      callback: () => props.onChangeFilterValue(props.subcategoryFilterKey),
    },
  ];

  return (
    <SplitButton
      selectedItemKey={props.selectedFilterKey}
      withChangeSelectedItem={true}
      color={props.color}
      options={filterOptions}
    />
  );
}

SelectObjectCategorySubcategoryButton.propTypes = {
  categoryFilterKey: PropTypes.string,
  subcategoryFilterKey: PropTypes.string,
  selectedFilterKey: PropTypes.string,
  onChangeFilterValue: PropTypes.func,
  color: PropTypes.string,
};

SelectObjectCategorySubcategoryButton.defaultProps = {
  color: "primary",
};

export default SelectObjectCategorySubcategoryButton;
