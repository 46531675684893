import { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { useSearchParams } from "react-router-dom";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";

import { NAVIGATION_DRAWER_TYPE_ADMIN } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import {
  getTablePropsByName,
  STD_TICKET_TABLE_NAME,
  STD_ORDER_TABLE_NAME,
  STD_TICKET_TABLE_PREFIX,
  STD_ORDER_TABLE_PREFIX,
} from "./TableConfig";
import StdTicketStdOrderTableWithToolBar from "./StdTicketStdOrderTableWithToolBar";

const SELECTED_TABLE_KEY = "selected_table";
const SELECTED_TABLE_DEFAULT_KEY = STD_TICKET_TABLE_NAME;

export default function AdminLocationsPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  //   const selectedTable = useMemo(
  //     () =>
  //       getFieldValueFromSearchParams(searchParams, OBJECT_LEVEL_KEY) ||
  //       DEFUALT_OBJECT_LEVEL,
  //     [searchParams]
  //   );

  const [selectedTable, setSelectedTable] = useState(STD_TICKET_TABLE_NAME);

  const tableProps = useMemo(
    () => getTablePropsByName(selectedTable),
    [selectedTable]
  );

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_ADMIN}
    >
      <DefaultPageWrapper titleKey={"admin_std_tickets_orders"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
          marginTop={1}
        >
          <Grid item xs={12}>
            <StdTicketStdOrderTableWithToolBar
              pageName={props.pageName}
              setSelectedTable={setSelectedTable}
              tableName={tableProps.tableName}
              tableConfig={tableProps.tableConfig}
              tableFilterPrefix={tableProps.tableFilterPrefix}
              tableConfigForUserPreferencesDialog={
                tableProps.configForUserPreferenceDialog
              }
            />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
