import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import DateField from "../../field/DateField/DateField";
import AutocompleteField from "../../field/AutocompleteField";

import TrueFalseSelectField from "../../field/TrueFalseSelectField";

export default function OrderChartToolBarForm(props) {
  const { t } = useTranslation();

  const getFormContent = () => (
    <>
      {props.showSelectTicketCategory && (
        <Grid item xs={12} md={props.isVerticalForm ? 12 : 2.5}>
          <AutocompleteField
            name="ticket_category"
            multiple={true}
            optionLabelKey={"tc_name"}
            value={props.formValue.ticket_category}
            options={props.filteringData.value?.ticket_category}
            label={t("bar.order_chart_bar.ticket_category")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            limitTags={props.isVerticalForm ? undefined : 0}
          />
        </Grid>
      )}
      {props.showSelectOrderKt && (
        <Grid
          item
          xs={12}
          md={
            props.isVerticalForm
              ? 12
              : !props.showSelectTicketCategory
              ? 4
              : 1.5
          }
        >
          <AutocompleteField
            name="order_kt"
            multiple={true}
            optionLabelKey={"full_name"}
            isObjectOption={true}
            value={props.formValue.order_kt}
            options={props.filteringData.value?.order_kt}
            label={t("bar.order_chart_bar.order_kt")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            limitTags={props.isVerticalForm ? undefined : 0}
            required
          />
        </Grid>
      )}
      {props.showSelectOrderT && (
        <Grid
          item
          xs={12}
          md={
            props.isVerticalForm
              ? 12
              : !props.showSelectTicketCategory
              ? 4
              : 1.5
          }
        >
          <AutocompleteField
            name="order_t"
            multiple={true}
            optionLabelKey={"full_name"}
            isObjectOption={true}
            value={props.formValue.order_t}
            options={props.filteringData.value?.order_t}
            label={t("bar.order_chart_bar.order_t")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            limitTags={props.isVerticalForm ? undefined : 0}
            required
          />
        </Grid>
      )}
      {props.showSelectSkill && (
        <Grid
          item
          xs={12}
          md={
            props.isVerticalForm
              ? 12
              : !props.showSelectTicketCategory
              ? 4
              : 1.5
          }
        >
          <AutocompleteField
            name="skill"
            multiple={true}
            optionLabelKey={"skill_name"}
            isObjectOption={true}
            value={props.formValue.skill}
            options={props.filteringData.value?.skill}
            label={t("bar.order_chart_bar.skill")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            limitTags={props.isVerticalForm ? undefined : 0}
            required
          />
        </Grid>
      )}
      {props.showTenant && (
        <Grid item xs={12} md={props.isVerticalForm ? 12 : 2}>
          <AutocompleteField
            name="tenant"
            multiple={true}
            isObjectOption={true}
            optionLabelKey={"tenant_short_name"}
            value={props.formValue.tenant}
            options={props.filteringData.value?.tenant}
            label={t("bar.order_chart_bar.tenant")}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            limitTags={props.isVerticalForm ? undefined : 0}
          />
        </Grid>
      )}
      {props.isVerticalForm && props.showOpenCloseFilter && (
        <Grid item xs={12}>
          <TrueFalseSelectField
            name="is_open"
            label={t("button.open_close_filter_button.open")}
            value={props.formValue?.is_open}
            valuesAsBool
            addEmptyOptions={false}
            onChange={props.onChange}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={props.isVerticalForm ? 12 : 3}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <DateField
          name={"created_start"}
          label={t("bar.order_chart_bar.created_start")}
          value={props.formValue.created_start}
          onChange={props.onChangeDateConvertedToDateTimeWithZeroHourOfDate}
          showClearIcon={false}
          maxDate={
            props.formValue.created_end
              ? props.formValue.created_end
              : undefined
          }
        />
        <div style={{ marginInline: "5px" }}>{"-"}</div>
        <DateField
          name={"created_end"}
          label={t("bar.order_chart_bar.created_end")}
          value={props.formValue.created_end}
          onChange={props.onChangeDateConvertedToDateTimeWithEndHourOfDate}
          showClearIcon={false}
          minDate={
            props.formValue.created_start
              ? props.formValue.created_start
              : undefined
          }
        />
      </Grid>
    </>
  );

  if (props.onlyFormContent) return getFormContent();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      rowSpacing={1}
    >
      {getFormContent()}
    </Grid>
  );
}

OrderChartToolBarForm.propTypes = {
  formValue: PropTypes.object,
  filteringData: PropTypes.object,
  setFormValue: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  onChangeDateConvertedToDateTimeWithZeroHourOfDate: PropTypes.func,
  onChangeDateConvertedToDateTimeWithEndHourOfDate: PropTypes.func,
  isVerticalForm: PropTypes.bool,
  onlyFormContent: PropTypes.bool,
  showSelectTicketCategory: PropTypes.bool,
  showSelectOrderKt: PropTypes.bool,
  showSelectTenant: PropTypes.bool,
  showSelectSkill: PropTypes.bool,
  showSelectOrderT: PropTypes.bool,
  showClearFiltersIcon: PropTypes.bool,
  showOpenCloseFilter: PropTypes.bool,
};

OrderChartToolBarForm.defaultProps = {
  isVerticalForm: false,
  onlyFormContent: true,
  showMyFiltersButton: true,
  showSelectTicketCategory: true,
  showSelectTimeRange: false,
  showSelectObjectLevel2: false,
  showTicketOwner: false,
  showSelectOrderT: false,
  showSelectSkill: false,
  showOpenCloseFilterButton: true,
  showClearFiltersIcon: true,
};
