import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { getFieldValueFromSearchParams } from "../../../helpers/methods";

import useFillterButton from "../../../hooks/useFilterButton";
import DateField from "../../field/DateField/DateField";
import DateService from "../../../services/dateService";

function DateFilterSelectField(props) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams(
    props.initialSearchParams
  );

  const { handleClickFilterButton } = useFillterButton(props.filterPrefix);

  const filterValue = useMemo(
    () => getFieldValueFromSearchParams(searchParams, props.filterKey),
    [searchParams]
  );

  const handleClickFilter = (filterValue) => {
    handleClickFilterButton(
      DateService.getISOStringZeroHourOfDate(filterValue),
      props.filterKey
    );
  };

  return (
    <DateField
      label={"data"}
      value={filterValue}
      onChange={(e) => {
        handleClickFilter(e.target.value);
      }}
      showClearIcon={true}
    />
  );
}

DateFilterSelectField.propTypes = {
  filterKey: PropTypes.string,
  filterPrefix: PropTypes.string,
  initialSearchParams: PropTypes.object,
};

DateFilterSelectField.defaultProps = {
  filterKey: "date_start",
};

export default DateFilterSelectField;
