import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useForm } from "../../../hooks/useForm";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import FloatField from "../../base/FloatField/FloatField";
import AutocompleteField from "../../field/AutocompleteField";
import TrueFalseSelectField from "../../field/TrueFalseSelectField/trueFalseSelectField";

import useOrderService from "../../../services/orderService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import {
  getErrorMsg,
  isEmptyValue,
  convertFlatListFromBackendToTranslatedOptionObject,
} from "../../../helpers/methods";

import {
  DIALOG_PREVIEW_MODE,
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
} from "../../../helpers/constants";

const REQUIRED_FIELDS = [
  "std_order_name",
  "std_order_need",
  "std_order_kt_need",
  "std_order_paid",
  //   "std_order_active",
  "sla_value",
  "sla_unit",
];

const FIELDS_TO_SAVE = [...REQUIRED_FIELDS];

const StdOrderDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const dialogTitle = useMemo(() => {
    if (props.title) return props.title;

    return props.dialogMode === DIALOG_CREATE_MODE
      ? t("dialog.std_order.add_new_std_order")
      : props.dialogMode === DIALOG_EDIT_MODE
      ? t("dialog.std_order.edit_std_order")
      : t("dialog.std_order.std_order_details");
  }, [props.dialogMode, props.title]);

  const isReadOnly = useMemo(
    () => props.readOnly || props.dialogMode === DIALOG_PREVIEW_MODE,
    [props.readOnly, props.dialogMode]
  );

  const fieldsToSave = useMemo(
    () => props.fieldsToSave || FIELDS_TO_SAVE,
    [props.fieldsToSave]
  );

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm();

  const {
    getStdOrderAdminDetailsData,
    getStdOrderAdminFilteringData,
    createStdOrder,
    updateStdOrder,
  } = useOrderService();

  const stdOrderInitialData = useAsync(() => {
    if (props.dialogMode === DIALOG_CREATE_MODE) {
      return Promise.resolve({
        // std_order_active: true,
      });
    }

    return getStdOrderAdminDetailsData(props.stdOrderId);
  }, [props.stdOrderId, props.dialogMode]);

  const prepareInitialDataForFormValue = (initialData) => {
    let { sla, ...initialDataTemp } = { ...initialData };

    if (sla) {
      initialDataTemp["sla_value"] = sla["sla_value"];
      initialDataTemp["sla_unit"] = sla["sla_unit"];
    }

    return initialDataTemp;
  };

  useEffect(() => {
    setFormValue(prepareInitialDataForFormValue(stdOrderInitialData.value));
  }, [stdOrderInitialData.loading]);

  const prepareDataToSend = () => {
    const dataToSend = {};
    for (const [key, value] of Object.entries(formValue)) {
      if (fieldsToSave.includes(key)) {
        dataToSend[key] = value;
      }
    }
    return dataToSend;
  };

  const stdOrderFilteringData = useAsync(getStdOrderAdminFilteringData);

  const updateStdOrderFn = useAsyncFn(updateStdOrder);
  const addStdOrderFn = useAsyncFn(createStdOrder);
  const submitFn = useMemo(
    () =>
      props.dialogMode === DIALOG_CREATE_MODE
        ? addStdOrderFn
        : updateStdOrderFn,
    [props.dialogMode]
  );

  const handleSubmit = (dataToSend) => {
    submitFn
      .execute(dataToSend, props.stdOrderId)
      .then((res) => {
        const successMsg =
          props.dialogMode === DIALOG_CREATE_MODE
            ? t("snackbar_alert.std_order_added")
            : t("snackbar_alert.std_order_updated");
        snackbarAlert.openSuccessSnackbarAlert(successMsg);
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isFormValid =
    formValue &&
    REQUIRED_FIELDS.every((field) => !isEmptyValue(formValue[field]));

  const isLoading = formValue === undefined || stdOrderFilteringData.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={2}
        columnSpacing={2}
      >
        <Grid item xs={12}>
          <TextFieldFormControl
            name="std_order_name"
            required
            value={formValue.std_order_name}
            label={t("dialog.std_order.std_order_name")}
            readOnly={isReadOnly}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TrueFalseSelectField
            name={"std_order_need"}
            label={t("dialog.std_order.std_order_need")}
            value={formValue.std_order_need}
            onChange={onChange}
            addEmptyOptions={false}
            valuesAsBool
            readOnly={isReadOnly}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TrueFalseSelectField
            name={"std_order_kt_need"}
            label={t("dialog.std_order.std_order_kt_need")}
            value={formValue.std_order_kt_need}
            onChange={onChange}
            addEmptyOptions={false}
            valuesAsBool
            readOnly={isReadOnly}
          />
        </Grid>
        {/* <Grid item xs={12} sm={4}>
          <TrueFalseSelectField
            name={"std_order_active"}
            label={t("dialog.std_order.std_order_active")}
            value={formValue.std_order_active}
            onChange={onChange}
            addEmptyOptions={false}
            valuesAsBool
            readOnly={isReadOnly}
            required
          />
        </Grid> */}
        <Grid item xs={12}>
          <TrueFalseSelectField
            name={"std_order_paid"}
            label={t("dialog.std_order.std_order_paid")}
            value={formValue.std_order_paid}
            onChange={onChange}
            addEmptyOptions={false}
            valuesAsBool
            readOnly={isReadOnly}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FloatField
            name={"sla_value"}
            label={t("dialog.std_order.sla_value")}
            value={formValue.sla_value}
            onChange={onChange}
            decimalPlaces={0}
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteField
            name="sla_unit"
            value={formValue.sla_unit}
            options={convertFlatListFromBackendToTranslatedOptionObject(
              stdOrderFilteringData.value.sla_unit
            )}
            isObjectOption={true}
            label={t("dialog.std_order.sla_unit")}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            required
          />
        </Grid>
        {!isReadOnly && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={addStdOrderFn.loading || updateStdOrderFn.loading}
              disabled={!isFormValid}
              onClick={() => handleSubmit(prepareDataToSend())}
            >
              {t("save")}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      showTopFullScreenButton={false}
      title={dialogTitle}
      maxWidth="xs"
      showDialogActions
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

StdOrderDialog.propTypes = {
  dialogMode: PropTypes.oneOf([
    DIALOG_CREATE_MODE,
    DIALOG_EDIT_MODE,
    DIALOG_PREVIEW_MODE,
  ]),
  readOnly: PropTypes.bool,
  stdOrderId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onSubmitCallback: PropTypes.func,
  fieldsToSave: PropTypes.array,
};

StdOrderDialog.defaultProps = {
  open: false,
};

export default StdOrderDialog;
