import i18n from "../../../../../i18n";

import { AUTOCOMPLETE_FIELD_TYPE } from "../../../../../helpers/constants";

export const TENANT_TABLE_NAME = "short_user_tenant_data_table";
export const TENANT_TABLE_FILTER_PREFIX = "locations__tenant";
export const TENANT_TABLE_CONFIG = [
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant_short_name,
    label: i18n.t("table.user_tenant_table.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    other_export_field_name: "tenant_short_name",
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
];
