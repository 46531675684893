import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../ConfirmationDialog/confirmationDialog";



const UnsavedDataDialog = (props) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      open={props.open}
      onYes={props.onYes}
      onNo={props.onNo}
      title={t("dialog.unsaved_data_dialog.unsaved_data")}
      content={t("dialog.unsaved_data_dialog.are_you_sure_to_leave_the_window")}
      maxWidth={"xs"}
      titleSx={{ color: "var(--alert)" }}
      contentSx={{ fontFamily: "Roboto",textAlign: "justify" }}
      btnYesColor={"error"}
      btnNoColor={"secondary"}
      showDialogContentDividers={false}
    />
  );
};

UnsavedDataDialog.propTypes = {
  open: PropTypes.bool,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
};

UnsavedDataDialog.defaultProps = {
  open: false,
};

export default UnsavedDataDialog;
