import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../hooks/useForm";
import { isEmptyValue } from "../../../helpers/methods";
import DateField from "../../field/DateField/DateField";

const CreateCertificateForm = (props) => {
  const requiredFields = [
    "certificate_name",
    "certificate_institution",
    "certificate_date",
  ];

  const { t } = useTranslation();

  const { formValue, onChange, onChangeDate } = useForm({});

  const isFormValid = () => {
    return requiredFields.every(
      (fieldName) => !isEmptyValue(formValue[fieldName])
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"certificate_name"}
          label={t(`form.certificate_create_form.certificate_name`)}
          value={formValue.certificate_name}
          onChange={onChange}
          required
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"certificate_institution"}
          label={t(`form.certificate_create_form.certificate_institution`)}
          value={formValue.certificate_institution}
          onChange={onChange}
          required
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <DateField
          name="certificate_date"
          label={t("form.certificate_create_form.certificate_date")}
          value={formValue.certificate_date}
          readOnly={props.readOnly}
          onChange={onChangeDate}
          required
        />
      </Grid>

      {!props.readOnly && (
        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={props.isLoading}
            disabled={!isFormValid()}
            onClick={() => props.onSubmit(formValue)}
          >
            {t("create")}
          </LoadingButton>
        </Grid>
      )}
    </Grid>
  );
};

CreateCertificateForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
};

CreateCertificateForm.defaultProps = {
  readOnly: false,
};

export default CreateCertificateForm;
