import React, { useCallback, useImperativeHandle, useState } from "react";
import ItemLevel from "./ItemLevel/ItemLevel";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  isEmptyValue,
  removeElementsInArrayByIndex,
} from "../../../helpers/methods";
import useLocationService from "../../../services/locationService";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

const ItemRecord = React.memo(React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [unfillRequiredFields, setUnfillRequiredFields] = useState([]);
  const { getEmptyLocationLevel, getLevelFieldId } = useLocationService();

  useImperativeHandle(ref, () => ({
    checkIfRequiredFieldsAreFill,
  }));

  const onChangeItem = useCallback(
    (itemLevels) => {
      props.onChangeItem(itemLevels);
    },
    [props.onChangeItem]
  );

  const onChangeItemLevel = (value_key, level) => {
    var itemLevels = [...props.itemLevels];
    const indexLevel = level - 1;
    if (itemLevels.length > level) {
      itemLevels = removeElementsInArrayByIndex(itemLevels, indexLevel);
    }
    itemLevels[indexLevel].id = value_key["id"];
    itemLevels.push(getEmptyLocationLevel(value_key["id"], level + 1));
    onChangeItem(itemLevels);
  };




  const checkIfRequiredFieldsAreFill = () => {
    if (props.readOnly) return [];
    let unfillFieldsTemp = [];

    for (const location_level of props.itemLevels) {
      if (
        isEmptyValue(location_level.id) && location_level.visible
      ) {
        unfillFieldsTemp.push(
          getLevelFieldId(0, location_level.level - 1)
        );
      }
    }

    setUnfillRequiredFields(unfillFieldsTemp);
    return unfillFieldsTemp;
  };

  return (
    <Grid container spacing={1}>
      {!props.readOnly &&
      <Grid item xs={0.5}>
        <IconButton
          style={{
            border: "3px solid var(--primary)",
            marginLeft: "auto",
          }}
          onClick={() => props.onClickSearchItem()}
        >
          <SearchIcon fontSize="small" sx={{ color: "var(--primary)" }} />
        </IconButton>
      </Grid>}
      <Grid item xs={props.readOnly ? 12 :11.5}>
        <Grid container columns={{ sm: 16, xs: 12 }} spacing={1}>
          {props.itemLevels.map((locationLevel, _index) => (
            <ItemLevel
              readOnly={props.readOnly}
              onCleanPart={props.onCleanPart}
              onCleanService={props.onCleanService}
              level={_index + 1}
              value={locationLevel.id}
              partValue={props.partValue}
              serviceValue={props.serviceValue}
              itemParent={locationLevel.parent}
              defaultFirstLevelItemName={props.defaultFirstLevelItemName}
              locationLevel={locationLevel}
              onChange={onChangeItemLevel}
              onChangeAutocompleteFieldWithObjectOptions={props.onChangeAutocompleteFieldWithObjectOptions}
              required={props.required}
              errorField={unfillRequiredFields.includes(
                getLevelFieldId(0, _index)
              )}
              helperTextField={
                unfillRequiredFields.includes(
                  getLevelFieldId(0, _index)
                ) && t("field_required")
              }
              showMaterialLabel={props.showMaterialLabel}
              showServiceLabel={props.showServiceLabel}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
})
);
ItemRecord.propTypes = {
  itemLevels: PropTypes.array,
  onChangeItemLevel: PropTypes.func,
  showLocationNumber: PropTypes.bool,
  unfillRequiredFields: PropTypes.array,
  readOnly: PropTypes.bool,
};

ItemRecord.defaultProps = {
  itemLevels: [],
  showLocationNumber: false,
  unfillRequiredFields: [],
  readOnly: false,
};

export default ItemRecord;
