import React, { useCallback, useEffect } from "react"
import PropTypes from 'prop-types';
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next";
import BasicDialog from "../../base/BasicDialog";
import PurchasingTreeItemForm from "../../form/PurchasingTreeItemForm"
import useItemService from "../../../services/itemService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { getErrorMsg, isEmptyValue } from "../../../helpers/methods";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

export default function PurchasingTreeItemDialog(props) {

    const { t } = useTranslation();
    const snackbarAlert = useSnackbarAlert();
    const requiredFields = ["item_number", "item_name"]

    const {
        getItemData,
        createItem,
        updateItem
    } = useItemService()

    const createItemFn = useAsyncFn(createItem);
    const updateItemFn = useAsyncFn(updateItem);

    const itemData = useAsync(() => {
        if (props.branchId) {
            return getItemData(props.branchId)
        }
        return Promise.resolve({})
    }, [])

    const parentItemData = useAsync(() => {
        if (props.parentBranchId) {
            return getItemData(props.parentBranchId)
        }
        return Promise.resolve({})
    }, [])

    const {
        formValue: parentFormValue,
        setFormValue: setParentFormVale
    } = useForm({})


    const {
        formValue: itemFormValue,
        setFormValue: setItemFormValue,
        onChange
    } = useForm({})


    useEffect(() => {
        if (itemData.loading) {
            return;
        }
        if (itemData.value) {
            setItemFormValue(itemData.value)
        }
    }, [itemData.loading]);

    useEffect(() => {
        if (parentItemData.loading) {
            return;
        }
        if (parentItemData.value) {
            setParentFormVale(parentItemData.value)
            setItemFormValue({'item_number' : parentItemData.value.next_item_number})
        }
    }, [parentItemData.loading]);

    const preprareDataToSave = (data) =>{
        return {...data, 'item_parent' : props.parentBranchId}
    }

    const isValid = itemFormValue ? requiredFields.every(
        (fieldName) => !isEmptyValue(itemFormValue[fieldName])
    ) : false

    const onSubmit = useCallback(() => {
        if (props.branchId) {
            updateItemFn
            .execute(props.branchId, preprareDataToSave(itemFormValue)) 
            .then((res) => {
              snackbarAlert.openSuccessSnackbarAlert(
                t("snackbar_alert.item_updated")
              );
              props.onClose(true);
            })
            .catch((err) => {
              snackbarAlert.openErrorSnackbarAlert(
                getErrorMsg(err.data),
                t("snackbar_alert.occurred_error_during_item_updating")
              );
            });
        } else {
            createItemFn
            .execute(preprareDataToSave(itemFormValue))
            .then((res) => {
              snackbarAlert.openSuccessSnackbarAlert(
                t("snackbar_alert.item_created")
              );
              props.onClose(true);
            })
            .catch((err) => {
              snackbarAlert.openErrorSnackbarAlert(
                getErrorMsg(err.data),
                t("snackbar_alert.occurred_error_during_item_creating")
              );
            });
        }
    
      }, [itemFormValue]);

    return (
        <BasicDialog
            open={props.open}
            onClose={() =>props.onClose()}
            titleAlign="center"
            contentAlign="center"
            title={props.branchId ? t(`dialog.purchasing_tree_item_dialog.edit_branch`) : t(`dialog.purchasing_tree_item_dialog.add_branch`)}
            maxWidth="sm"
            showDialogActions
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
            >
                <Grid item xs={12}>
                    <PurchasingTreeItemForm
                        branchId={props.branchId}
                        parentBranchId={props.parentBranchId}
                        parentFormValue={parentFormValue}
                        itemFormValue={itemFormValue}
                        onChange={onChange} />
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        loading={false}
                        disabled={!isValid}
                        onClick={onSubmit}
                    >
                        {t("save")}
                    </LoadingButton>
                </Grid>
            </Grid>
        </BasicDialog>
    )

}

PurchasingTreeItemDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    branchId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]
    ),
    parentBranchId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]
    ),
    openSnackbarAlert: PropTypes.func,
}

PurchasingTreeItemDialog.defaultProps = {
    open: false
}
