import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import ItemRecord from "../../other/ItemRecord/ItemRecord";
import AutocompleteField from "../../field/AutocompleteField";
import { isEmptyValue } from "../../../helpers/methods";
import FloatField from "../../base/FloatField/FloatField";
import PropTypes from "prop-types"
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import AddButton from "@mui/icons-material/Add";
import DateField from "../../field/DateField/DateField";

const PoFvForm = (props
) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={2}>
        <TextFieldFormControl
          name="fv_nr"
          label={t("form.po_fv_form.fv_number")}
          value={props.formValue?.fv_nr}
          readOnly={props.readOnly}
          onChange={props.onChange}
          required
        />
      </Grid>
      <Grid item xs={2}>
        <DateField
          name="fv_date"
          label={t("form.po_fv_form.fv_date")}
          value={props.formValue?.fv_date}
          readOnly={props.readOnly}
          onChange={props.onChangeDate}
          required
        />
      </Grid>
      <Grid item xs={2}>
        <DateField
          name="fv_date_sales"
          label={t("form.po_fv_form.fv_date_sales")}
          value={props.formValue?.fv_date_sales}
          readOnly={props.readOnly}
          onChange={props.onChangeDate}
        />
      </Grid>

      <Grid item xs={3}>
        <AutocompleteField
          name="fv_issuer"
          label={t(`form.po_fv_form.fv_issuer`)}
          value={props.formValue?.fv_issuer}
          options={props.selectData?.value?.fv_issuer}
          isObjectOption={true}
          optionLabelKey={"subcontractor_short_name"}
          addNewValue={false}
          onChange={props.onChangeAutocomplete}
          readOnly={props.readOnly}
          required
        />
      </Grid>
      <Grid item xs={3}>
        <AutocompleteField
          name="fv_payer"
          label={t(`form.po_fv_form.fv_payer`)}
          value={props.formValue?.fv_payer}
          options={props.selectData?.value?.fv_payer}
          isObjectOption={true}
          optionLabelKey={"owner_short_name"}
          addNewValue={false}
          onChange={props.onChangeAutocomplete}
          readOnly={props.readOnly}
          required
        />
      </Grid>


      <Grid item xs={2}>
        <FloatField
          name="fv_net"
          readOnly={props.readOnly}
          value={props.formValue?.fv_net}
          label={t("form.po_fv_form.fv_net")}
          onChange={props.onChange}
          required
          suffix={"zł"}
        />
      </Grid>
      <Grid item xs={2}>
        <FloatField
          name="fv_vat"
          value={props.formValue?.fv_vat}
          label={t("form.po_fv_form.fv_vat")}
          disabled
          suffix={"zł"}
        />
      </Grid>
      <Grid item xs={2}>
        <FloatField
          name="fv_gross"
          value={props.formValue?.fv_gross}
          label={t("form.po_fv_form.fv_gross")}
          disabled
          suffix={"zł"}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFieldFormControl
          name="fv_blueDew"
          label={t("form.po_fv_form.fv_blueDew")}
          value={props.formValue?.fv_blueDew}
          readOnly={props.readOnly}
          onChange={props.onChange}
        />
      </Grid>
      <Grid item xs={3}>
        <DateField
          name="fv_date_payment"
          label={t("form.po_fv_form.fv_date_payment")}
          value={props.formValue?.fv_date_payment}
          readOnly={props.readOnly}
          onChange={props.onChangeDate}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="fv_desc"
          label={t("form.po_fv_form.fv_desc")}
          value={props.formValue?.fv_desc}
          readOnly={props.readOnly}
          onChange={props.onChange}
          multiline
          rows= {2}
          resize={"vertical"}
        />
      </Grid>
    </Grid>
  );
};

PoFvForm.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeAutocomplete: PropTypes.func,
  readOnly: PropTypes.bool,
  selectData : PropTypes.array
};

PoFvForm.defaultProps = {
  readOnly: false,
  selectData: []
};

export default PoFvForm;
