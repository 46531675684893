import React, { useRef } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import MiniButton from "../../base/MiniButton/MiniButton";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import useFormService from "../../../services/formService";
import { useAsync } from "../../../hooks/useAsync";

import QuestionAdminForm from "../../form/QuestionAdminForm";

import QuestionAdminDialog from "../../dialog/QuestionAdminDialog";
import useDialogWithId from "../../../hooks/useDialogWithId";

import {
  DIALOG_CREATE_MODE,
  DIALOG_EDIT_MODE,
} from "../../../helpers/constants";

const QuestionForQuestionList = React.memo((props) => {
  const { t } = useTranslation();

  const {
    getQuestionAdminListForFirstQuestion,
    getQuestionAdminFilteringData,
    getQuestionAnswerAdminFilteringData,
  } = useFormService();

  const questionList = useAsync(() => {
    if (!props.questionId) return Promise.resolve([]);
    return getQuestionAdminListForFirstQuestion(props.questionId);
  }, [props.questionId]);

  const questionAdminFilteringData = useAsync(getQuestionAdminFilteringData);
  const questionAnswerAdminFilteringData = useAsync(
    getQuestionAnswerAdminFilteringData
  );

  const [
    openQuestionDialog,
    questionId,
    onOpenQuestionDialog,
    onCloseQuestionDialog,
  ] = useDialogWithId();

  const questionDialogModeRef = useRef();
  const handleOpenQuestionDialogInCreateMode = () => {
    questionDialogModeRef.current = DIALOG_CREATE_MODE;
    onOpenQuestionDialog(undefined);
  };

  const handleOpenQuestionDialogInEditMode = (questionId) => {
    questionDialogModeRef.current = DIALOG_EDIT_MODE;
    onOpenQuestionDialog(questionId);
  };

  const getQuestionDialogCallback = () => {
    if (props.onSumbitQuestion) {
      props.onSumbitQuestion();
    }
    questionList.refetch();
  };

  const isLoading =
    questionList.loading ||
    questionAdminFilteringData.loading ||
    questionAnswerAdminFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item xs={12} sm={4} />
      <Grid item xs={12} sm={4} textAlign={"center"}>
        <Typography variant="h6" className="capitalize-first-letter">
          {t("form.question_form.questions")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} textAlign={"end"}>
        <MiniButton
          variant="contained"
          size="small"
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={handleOpenQuestionDialogInCreateMode}
        >
          {t("form.question_form.add_question")}
        </MiniButton>
      </Grid>
      <Grid item xs={12}>
        {questionList.value.map((question, idx) => (
          <QuestionAdminForm
            key={idx}
            formValue={question}
            awswersFormValue={question.answers}
            readOnly={true}
            questionFilteringData={questionAdminFilteringData.value}
            questionAnswerFilteringData={questionAnswerAdminFilteringData.value}
            onClickEdit={() => handleOpenQuestionDialogInEditMode(question.id)}
            showIndex={true}
            index={idx + 1}
          />
        ))}
      </Grid>
      {openQuestionDialog && (
        <QuestionAdminDialog
          open={openQuestionDialog}
          onClose={onCloseQuestionDialog}
          dialogMode={questionDialogModeRef.current}
          onSubmitCallback={getQuestionDialogCallback}
          questionId={questionId}
        />
      )}
    </Grid>
  );
});

QuestionForQuestionList.propTypes = {
  questionId: PropTypes.string,
  onSumbitQuestion: PropTypes.func,
};

QuestionForQuestionList.defaultProps = {};

export default QuestionForQuestionList;
