import { useAsync } from "../../../hooks/useAsync";
import useUserService from "../../../services/userService";
import BasicDialog from "../../base/BasicDialog"
import AutocompleteField from "../../field/AutocompleteField"
import PropTypes from "prop-types";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useForm } from "../../../hooks/useForm";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { isEmptyValue } from "../../../helpers/methods";

const ChooseUserBehalfCreateTicketDialog = (props) => {

    const SELECT_DISPLAY_USER_KEY = 'select_display_user'

    const {t} = useTranslation();

    const {
        formValue,
        onChangeAutocompleteFieldWithObjectOptions
    } = useForm({user: props.user});

    const [userSelectList, setUserSelectList] = useState()

    const { getUserBehalfCreateTicketSelectList } = useUserService();
    const userBehalfCreateTicketSelectList = useAsync(getUserBehalfCreateTicketSelectList);

    useEffect(()=>{
        let usersTemp;
        if(!userBehalfCreateTicketSelectList.loading && userBehalfCreateTicketSelectList.value !== undefined){
            usersTemp = [...userBehalfCreateTicketSelectList.value]  
            usersTemp.forEach((user)=>{
                const dataListField = ['mail', 'tenant']
                let dataList = [];
                for(const field of dataListField){
                    if(!isEmptyValue(user[field])){
                        dataList.push(user[field])
                    }
                }
                user[SELECT_DISPLAY_USER_KEY] = `${user.full_name} (${dataList.join('; ')})` 
            })
        }
        setUserSelectList(usersTemp)
    }, [userBehalfCreateTicketSelectList.value, userBehalfCreateTicketSelectList.loading])

    return (
        <BasicDialog
            title={t("dialog.choose_user_on_behalf_create_dialog.select_person")}
            open={props.open}
            onClose={props.onClose}
            maxWidth={"sm"}
            titleAlign="center"
        >
            <LoaderWrapper showLoader={userBehalfCreateTicketSelectList.loading || userSelectList === undefined}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <AutocompleteField
                            label={t("dialog.choose_user_on_behalf_create_dialog.send_on_behalf_of")}
                            options={userSelectList || []}
                            value={formValue.user}
                            name="user"
                            onChange={onChangeAutocompleteFieldWithObjectOptions}
                            optionLabelKey={SELECT_DISPLAY_USER_KEY}
                            addNewValue={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" size="small" fullWidth onClick={()=>{props.onSubmit(userBehalfCreateTicketSelectList?.value.find(x=>x.id===formValue.user))}}>
                            {t("confirm")}
                        </Button>
                    </Grid>
                </Grid>
            </LoaderWrapper>
        </BasicDialog>
    )
}

ChooseUserBehalfCreateTicketDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    user: PropTypes.string
};

ChooseUserBehalfCreateTicketDialog.defaultProps = {

};

export default ChooseUserBehalfCreateTicketDialog;