import { useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid, Button } from "@mui/material";

import BasicDialog from "../../base/BasicDialog";

import useDialog from "../../../hooks/useDialog";
import UserFiltersTable from "../../table/UserFiltersTable";

import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useFilterService from "../../../services/filterService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";

import { useAuth } from "../../../context/auth";

import FilterDialog, {
  EDIT_MODE,
  CREATE_MODE,
  PREVIEW_MODE,
} from "../../base/FilterDialog/FilterDialog";
import useFilterSearchParams from "../../../hooks/useFilterSearchParams";

const UserFilterDialog = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const snackbarAlert = useSnackbarAlert();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    getSearchParamsFromDictByFilterPrefix,
    getOtherSearchParamsByFilterPrefix,
    setPrefixForSearchDict,
  } = useFilterSearchParams(searchParams);

  const { getUserFilters, createOrUpdateFilter, deleteFilter } =
    useFilterService();
  const userFilters = useAsync(() => {
    const searchParamsForUserFilters = {
      filter_page: props.pageName,
      user: user.user_id,
    };
    if (props.filterPrefix !== undefined) {
      searchParamsForUserFilters.filter_prefix = props.filterPrefix;
    }
    return getUserFilters(searchParamsForUserFilters);
  }, [user.user_id, props.pageName, props.filterPrefix]);
  const createOrUpdateFilterFn = useAsyncFn(createOrUpdateFilter);
  const deleteFilterFn = useAsyncFn(deleteFilter);

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const selectedFilterValue = useRef(null);
  const selectedFilterDetails = useRef(null);
  const filterDialogMode = useRef(null);

  const getSelectedFilter = (filterId) =>
    userFilters.value.find((filter) => filter.id === filterId);

  const setFilter = (filterValue) => {
    if (props.filterPrefix !== undefined) {
      selectedFilterValue.current = setSearchParams({
        ...getOtherSearchParamsByFilterPrefix(props.filterPrefix),
        ...setPrefixForSearchDict(props.filterPrefix, filterValue),
      });
    } else {
      setSearchParams(filterValue);
    }
  };

  const handleClickSet = (filterId) => {
    const selectedFilter = getSelectedFilter(filterId);
    const filterValue = selectedFilter.filter_value;
    setFilter(filterValue);
    props.onClose();
  };

  const handleClickPreview = (filterId) => {
    const selectedFilter = getSelectedFilter(filterId);
    if (props.filterPrefix !== undefined) {
      selectedFilterValue.current = setPrefixForSearchDict(
        props.filterPrefix,
        selectedFilter.filter_value
      );
    } else {
      selectedFilterValue.current = selectedFilter.filter_value;
    }
    selectedFilterDetails.current = {
      filter_name: selectedFilter.filter_name,
      filter_is_default: selectedFilter.filter_is_default,
    };
    filterDialogMode.current = PREVIEW_MODE;

    onOpenFilterDialog();
  };

  const handleClickEdit = (filterId) => {
    const selectedFilter = getSelectedFilter(filterId);
    if (props.filterPrefix !== undefined) {
      selectedFilterValue.current = setPrefixForSearchDict(
        props.filterPrefix,
        selectedFilter.filter_value
      );
    } else {
      selectedFilterValue.current = selectedFilter.filter_value;
    }
    selectedFilterDetails.current = {
      id: selectedFilter.id,
      filter_name: selectedFilter.filter_name,
      filter_is_default: selectedFilter.filter_is_default,
    };
    filterDialogMode.current = EDIT_MODE;
    onOpenFilterDialog();
  };

  const handleClickCreate = () => {
    selectedFilterDetails.current = {
      filter_name: "",
      filter_is_default: null,
      filter_page: props.pageName,
    };
    filterDialogMode.current = CREATE_MODE;
    onOpenFilterDialog();
  };

  const handleCloseFilterDialog = () => {
    selectedFilterValue.current = null;
    selectedFilterDetails.current = null;

    onCloseFilterDialog();
  };

  const prepareDataToSend = (data) => {
    var tempFilterForm = { ...data.filter_value };
    tempFilterForm = getSearchParamsFromDictByFilterPrefix(
      props.filterPrefix,
      tempFilterForm,
      props.excludedFields
    );
    return { ...data, filter_value: { ...tempFilterForm } };
  };
  const handleCreateOrUpdateFilter = (dataToSend) => {
    if (props.filterPrefix !== undefined) {
      dataToSend.filter_prefix = props.filterPrefix;
    }
    createOrUpdateFilterFn
      .execute(prepareDataToSend(dataToSend))
      .then((res) => {
        if (res.status === 201) {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.filter_added")
          );
        } else
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.filter_updated")
          );

        userFilters.refetch();
        setFilter(res.data.filter_value);
        if (res.data.filter_is_default) {
          props.onClose();
        }
        handleCloseFilterDialog();
      })
      .catch((error) => {
        if (error.status === 400) {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_saving_changes")
          );
        } else {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.server_error")
          );
        }
      });
  };
  const handleDeteleFilter = (filterId) => {
    deleteFilterFn
      .execute(filterId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.filter_deleted")
        );
        const deletedFilter = getSelectedFilter(filterId);
        userFilters.refetch();
        setFilter({});
        if (deletedFilter.filter_is_default) {
          props.onClose();
        }
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_delete_filter")
        );
      });
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={t("dialog.user_filters.my_filters")}
      maxWidth="md"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        <Grid item xs={12}>
          <UserFiltersTable
            isLoading={userFilters.loading}
            userFilterData={userFilters.value}
            onClickDelete={handleDeteleFilter}
            onClickPreview={handleClickPreview}
            onClickEdit={handleClickEdit}
            onClickSet={handleClickSet}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClickCreate}
          >
            {t("dialog.user_filters.add_new_filter")}
          </Button>
        </Grid>
      </Grid>
      <FilterDialog
        open={openFilterDialog}
        onClose={handleCloseFilterDialog}
        filterForm={props.filterForm}
        formValue={selectedFilterValue.current}
        filterDetails={selectedFilterDetails.current}
        dialogMode={filterDialogMode.current}
        onSubmit={handleCreateOrUpdateFilter}
        isDataSaving={createOrUpdateFilterFn.loading}
        filterPrefix={props.filterPrefix}
      />
    </BasicDialog>
  );
};

UserFilterDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  filterForm: PropTypes.element,
  pageName: PropTypes.string,
  filterPrefix: PropTypes.string,
  excludedFields: PropTypes.array,
};

UserFilterDialog.defaultProps = {
  open: false,
};

export default UserFilterDialog;
