import { Grid } from "@mui/material";
import React, { useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import Question from "../Question/Question";
import {
  isEmptyValue,
  removeElementsInArrayByIndex,
} from "../../../helpers/methods";
import useFormService from "../../../services/formService";
import { useTranslation } from "react-i18next";

const QuestionForm = React.memo(
  React.forwardRef((props, ref) => {
    const { t } = useTranslation();

    const { getEmptyQuestionAnswerObject } = useFormService();

    const [unfillRequiredFields, setUnfillRequiredFields] = useState([]);

    useImperativeHandle(ref, () => ({
      checkIfRequiredFieldsAreFill, // Wartość, którą chcemy udostępnić
    }));

    const getQuestionFieldId = (questionId) => {
      return `question_${questionId}`;
    };

    const onChangeAnswer = (e, questionObject, answerObject) => {
      var questionAnswers = [...props.question_answers];
      const questionAnswerIndex = questionAnswers.findIndex(
        (x) => x.question === questionObject.id
      );
      questionAnswers = removeElementsInArrayByIndex(
        questionAnswers,
        questionAnswerIndex
      );
      var questionAnswer = questionAnswers[questionAnswerIndex];
      questionAnswer.answer = e.target.value;
      if (
        !isEmptyValue(answerObject.quan_nextQ) &&
        answerObject.quan_nextQ_is_active
      ) {
        questionAnswers.push(
          getEmptyQuestionAnswerObject(answerObject.quan_nextQ)
        );
      }
      props.onChangeQuestionAnswers(e, questionAnswers);
    };

    const checkIfRequiredFieldsAreFill = () => {
      let unfillFieldsTemp = [];
      for (const questionAnswer of props.question_answers) {
        if (isEmptyValue(questionAnswer.answer)) {
          unfillFieldsTemp.push(getQuestionFieldId(questionAnswer.question));
        }
      }
      setUnfillRequiredFields(unfillFieldsTemp);
      return unfillFieldsTemp;
    };

    return (
      <Grid container spacing={1}>
        {props.question_answers.map(
          (question_answer) =>
            !isEmptyValue(question_answer.question) && (
              <Grid item xs={12}>
                <Question
                  questionId={question_answer.question}
                  isQuestionnaire={props.isQuestionnaire}
                  answer={question_answer.answer}
                  onChange={onChangeAnswer}
                  required
                  error={unfillRequiredFields.includes(
                    getQuestionFieldId(question_answer.question)
                  )}
                  helperText={
                    unfillRequiredFields.includes(
                      getQuestionFieldId(question_answer.question)
                    ) && t("field_required")
                  }
                  readOnly={props.readOnly}
                />
              </Grid>
            )
        )}
      </Grid>
    );
  })
);

QuestionForm.propTypes = {
  question_answers: PropTypes.array,
  onChangeQuestionAnswers: PropTypes.func,
  readOnly: PropTypes.bool,
  isQuestionnaire: PropTypes.bool,
};

QuestionForm.defaultProps = {
  question_answers: [],
  isQuestionnaire: false,
};

export default QuestionForm;
