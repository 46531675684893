import OfferReportBasePage, { CHART_TYPE_BAR } from "../OffersReportBasePage/";

export default function OffersPerLocationReportPage(props) {
  return (
    <OfferReportBasePage
      chartType={CHART_TYPE_BAR}
      pageName={props.pageName}
      pageTitleKey={"offers_per_location_report"}
      groupBy={"offer_status"}
      labelBy={"location"}
      showSelectObjectLevel2={true}
      showSelectTenant={false}
      showSelectReportType={false}
      showSelectOfferStatus={true}
      showTotalIndicator={false}
    />
  );
}

OffersPerLocationReportPage.propTypes = {};
