import i18n from "../../i18n";

import {
  AUTOCOMPLETE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
} from "../../helpers/constants";

import DateService from "../../services/dateService";

import { getStyleForSLACell } from "../../helpers/styles";

export const FEEDBACK_TABLE = "feedback_sla_dialog";

export const FEEDBACK_TABLE_FIELDS_CONFIG = [
  {
    name: "ticket_id",
    getValueCallback: (rowData) => rowData.ticket_id,
    onlyData: true,
  },
  {
    name: "ticket_nr",
    getValueCallback: (rowData) => rowData.ticket_nr,
    label: i18n.t("table.feedback.ticket_nr"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.ticket_tenant,
    label: i18n.t("table.feedback.ticket_tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "100px" },
    }),
    includeExcludedFilter: true,
  },
  {
    name: "ticket_owner",
    getValueCallback: (rowData) => rowData.ticket_owner,
    label: i18n.t("table.feedback.ticket_owner"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_owner,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_k",
    getValueCallback: (rowData) => rowData.ticket_k,
    label: i18n.t("table.ticket.ticket_k"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_k,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_category",
    getValueCallback: (rowData) => rowData.ticket_category,
    label: i18n.t("table.feedback.ticket_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_category,
      isObjectOption: true,
      optionLabelKey: "tc_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_name",
    getValueCallback: (rowData) => rowData.ticket_name,
    label: i18n.t("table.feedback.ticket_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "ticket_desc_creator",
    getValueCallback: (rowData) => rowData.ticket_desc_creator,
    label: i18n.t("table.feedback.ticket_desc_creator"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "ticket_created",
    getValueCallback: (rowData) =>
      DateService.convertDateToFormatYYYYMMDD(rowData.ticket_created),
    label: i18n.t("table.feedback.ticket_created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "rate_name",
    getValueCallback: (rowData) => rowData.rate_name,
    label: i18n.t("table.feedback.rate_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.rate_name,
      isObjectOption: true,
      optionLabelKey: "rate_name",
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
  {
    name: "updated",
    getValueCallback: (rowData) =>
      DateService.convertDateToFormatYYYYMMDD(rowData.updated),
    label: i18n.t("table.feedback.updated"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "feedback_note",
    getValueCallback: (rowData) => rowData.feedback_note,
    label: i18n.t("table.feedback.feedback_note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "sla",
    getValueCallback: (rowData) => rowData.sla,
    sortable: true,
    label: i18n.t("table.feedback.sla"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "sla_lead_time",
    getValueCallback: (rowData) => rowData.sla_lead_time,
    sortable: true,
    label: i18n.t("table.feedback.sla_lead_time"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "sla_delay_time",
    getValueCallback: (rowData) => rowData.sla_delay_time,
    cellStyle: (rowData) => getStyleForSLACell(rowData.sla_delay_time),
    sortable: true,
    label: i18n.t("table.feedback.sla_delay_time"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];
