import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import { useAsync } from "../../../../hooks/useAsync";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import useDialog from "../../../../hooks/useDialog";
import TableService from "../../../../services/tableService";
import FilterTable from "../../../table/FilterTable";
import { useEmptyArrayMemo } from "../../../../hooks/useEmptyArrayMemo";
import { useSearchParams } from "react-router-dom";
import useFilterSearchParams from "../../../../hooks/useFilterSearchParams";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import SearchButton from "../../../button/SearchButton/SearchButton";
import FilterDialog from "../../../base/FilterDialog";
import UniversalFilterForm from "../../../form/UniversalFilterForm";
import useDialogWithId from "../../../../hooks/useDialogWithId";
import { POST_ARCHIVE_TABLE_CONFIG } from "./TableConfig";
import useCorrespondenceAdminService from "../../../../services/correspondenceAdminService";
import CreatePostArchiveDialog from "../../../dialog/CreatePostArchivesDialog/CreatePostArchivesDialog";


const POST_ARCHIVE_FILTER_PREFIX = "post_archive";

const CorrespondenceAdminPostArchiveTab = (props) => {
  const { t } = useTranslation();
  const [postArchivesLocal, setPostArchivesLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [postArchiveTableSearchParams, setPostArchiveTableSearchParams] = useState({});

  const { setNewItemSearchParamsIfAreChanged, clearSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);

  const { emptyArrayMemo } = useEmptyArrayMemo();
  const { getPostArchivesList } = useCorrespondenceAdminService(); 

  const onCleanFiltersInFilterDialog = () => {
    setSearchParams(clearSearchParamsByFilterPrefix(POST_ARCHIVE_FILTER_PREFIX));
  };

  const [
    openPostArchiveDialog,
    postArchiveChangeId,
    onOpenPostArchiveDialog,
    onClosePostArchiveDialog,
    exportChangeArchiveStatus
  ] = useDialogWithId();

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
  } = usePaginationWithSearchParams(POST_ARCHIVE_FILTER_PREFIX);

  const postArchiveData = useAsync(
    () => getPostArchivesList(postArchiveTableSearchParams),
    [postArchiveTableSearchParams]
  );

  useEffect(() => {
    if (postArchiveData.loading) {
      return;
    }
    setPostArchivesLocal(postArchiveData.value.results);
    setCountRecords(postArchiveData.value.count);
  }, [postArchiveData.loading]);

  const isLoading = postArchivesLocal === undefined|| props.filteringData.loading;

  const handleOpenPostArchiveDialog = useCallback(
    (e, postArchiveId) => {
      e.stopPropagation();
      onOpenPostArchiveDialog(postArchiveId);
    },
    []
  );

  const refreshTable = () => {
    setPostArchivesLocal([]);
    postArchiveData.refetch();
  };

  const data = useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        POST_ARCHIVE_TABLE_CONFIG,
        postArchivesLocal ? postArchivesLocal : emptyArrayMemo,
        emptyArrayMemo,
        emptyArrayMemo,
        handleOpenPostArchiveDialog,
      ),
    [postArchivesLocal, POST_ARCHIVE_TABLE_CONFIG]
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      POST_ARCHIVE_FILTER_PREFIX,
      postArchiveTableSearchParams,
      setPostArchiveTableSearchParams
    );
  }, [searchParams]);

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={1}
    >
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
          onClick={(e) => onOpenPostArchiveDialog(undefined)}
        >
          {t("tabs.correspondence_admin.add_post_archive")}
        </Button>
      </Grid>
      <Grid item sm={9} />
      <Grid item xs={12} sm={1}>
        <SearchButton onClickSearch={onOpenFilterDialog} />
      </Grid>
      <Grid item xs={12}>
        <FilterTable
          data={data}
          showCheckbox={false}
          showCleanFilterIcon={true}
          countRecords={countRecords}
          page={page}
          onPageChange={handleChangePageWithSearchParams}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          filteringData={props.filteringData}
          style={{ maxHeight: "vh" }}
          tableConfig={POST_ARCHIVE_TABLE_CONFIG}
          showContextMenu={false}
          filterPrefix={POST_ARCHIVE_FILTER_PREFIX}
          headersConfig={TableService.getHeadersConfigForCollapsibleTable(
            POST_ARCHIVE_TABLE_CONFIG,
            props.filteringData || emptyArrayMemo,
            emptyArrayMemo,
            emptyArrayMemo,
          )}
        />
      </Grid>
      {openFilterDialog &&
        <FilterDialog
          open={openFilterDialog}
          onCleanFilters={onCleanFiltersInFilterDialog}
          onClose={onCloseFilterDialog}
          resetPageNumber={resetPageNumber}
          filterPrefix={POST_ARCHIVE_FILTER_PREFIX}
          filterForm={
            <UniversalFilterForm
              filteringData={props.filteringData || emptyArrayMemo}
              filtersConfig={POST_ARCHIVE_TABLE_CONFIG}
              includeOpenCloseFilter={false}
            />
          }
        />
      }
      {openPostArchiveDialog &&
        <CreatePostArchiveDialog
          archiveId={postArchiveChangeId}
          open={openPostArchiveDialog}
          onClose={onClosePostArchiveDialog}
          onRefetchData={refreshTable}
          filteringData={props.filteringData}
        />
      }
    </Grid>
  );
};

CorrespondenceAdminPostArchiveTab.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

CorrespondenceAdminPostArchiveTab.defaultProps = {};

export default CorrespondenceAdminPostArchiveTab;
