import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useForm } from "../../../hooks/useForm";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";

import useTicketService from "../../../services/ticketService";
import useLocationService from "../../../services/locationService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import { getErrorMsg } from "../../../helpers/methods";

const StdTicketAutomatDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const {
    formValue,
    setFormValue,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm();

  const {
    getStdTicketAutomatAdminDetailsData,
    getStdTicketAutomatAdminFilteringData,
    updateStdTicketAutomat,
  } = useTicketService();

  const { getObjectSelectList } = useLocationService();
  const objectsLevel2 = useAsync(() =>
    getObjectSelectList({ object_level: 2 })
  );

  const stdTicketAutomatInitialData = useAsync(() => {
    return getStdTicketAutomatAdminDetailsData(props.ticketCategoryId);
  }, [props.ticketCategoryId]);

  const stdTicketAutomatDataToCopyFn = useAsyncFn(() => {
    if (!formValue || !formValue?.tc_to_copy) return Promise.resolve(null);
    return getStdTicketAutomatAdminDetailsData(formValue?.tc_to_copy);
  }, [formValue?.tc_to_copy]);

  useEffect(() => {
    if (stdTicketAutomatInitialData.loading) return;

    let tempFormValue = {};
    for (const [key, value] of Object.entries(
      stdTicketAutomatInitialData.value.std_ticket_automats
    )) {
      tempFormValue[key] = value.ticautomat_k?.id;
    }

    setFormValue(tempFormValue);
  }, [stdTicketAutomatInitialData.loading]);

  useEffect(() => {
    if (
      stdTicketAutomatDataToCopyFn.loading ||
      !stdTicketAutomatDataToCopyFn.value
    )
      return;

    let tempFormValue = {
      tc_to_copy: formValue.tc_to_copy,
      k_to_copy: formValue.k_to_copy,
    };
    for (const [key, value] of Object.entries(
      stdTicketAutomatDataToCopyFn.value.std_ticket_automats
    )) {
      tempFormValue[key] = value.ticautomat_k?.id;
    }

    setFormValue(tempFormValue);
  }, [stdTicketAutomatDataToCopyFn.loading]);

  const prepareDataToSend = () => {
    let dataToSend = {};
    for (const [key, value] of Object.entries(formValue)) {
      if (
        key === "tc_to_copy" ||
        key === "k_to_copy" ||
        formValue[key] ===
          stdTicketAutomatInitialData.value?.std_ticket_automats[key]
            ?.ticautomat_k?.id
      ) {
        continue;
      }

      dataToSend[key] = value;
    }

    return dataToSend;
  };

  const updateStdTicketAutomatFn = useAsyncFn(updateStdTicketAutomat);
  const stdTicketFilteringData = useAsync(
    getStdTicketAutomatAdminFilteringData
  );

  const handleCopyKToAllObject = () => {
    let tempFormValue = {
      tc_to_copy: formValue.tc_to_copy,
      k_to_copy: formValue.k_to_copy,
    };
    for (const key of Object.keys(
      stdTicketAutomatInitialData.value.std_ticket_automats
    )) {
      tempFormValue[key] = formValue.k_to_copy;
    }

    setFormValue(tempFormValue);
  };

  const handleSubmit = (dataToSend) => {
    updateStdTicketAutomatFn
      .execute(dataToSend, props.ticketCategoryId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.std_ticket_automats_updated")
        );
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isFormValid = formValue !== undefined;

  const generateStdTicketAutomatFields = () => {
    return objectsLevel2.value.map((object) => (
      <Grid
        item
        xs={12}
        key={object.id}
        container
        spacing={2}
        justifyContent={"center"}
      >
        <Grid item xs={6}>
          <TextFieldFormControl
            value={object.object_name}
            readOnly
            inputStyle={{
              "-webkit-text-fill-color": "black",
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteField
            multiple={false}
            optionValueKey={"id"}
            optionLabelKey={"full_name"}
            name={object.id}
            label={t("dialog.std_ticket_automat.k")}
            value={formValue[object.id]}
            options={stdTicketFilteringData.value.ticautomat_k}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
          />
        </Grid>
      </Grid>
    ));
  };

  const isLoading =
    formValue === undefined ||
    stdTicketFilteringData.loading ||
    stdTicketAutomatDataToCopyFn.loading ||
    objectsLevel2.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        rowGap={1}
        columnSpacing={2}
      >
        <Grid item xs={12}>
          <TextFieldFormControl
            value={stdTicketAutomatInitialData.value.tc_name}
            inputStyle={{
              "-webkit-text-fill-color": "black",
              "text-align": "center",
            }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={9}>
          <AutocompleteField
            multiple={false}
            optionValueKey={"id"}
            optionLabelKey={"tc_name"}
            label={t(
              "dialog.std_ticket_automat.copy_responsible_from_selected_category"
            )}
            name={"tc_to_copy"}
            value={formValue.tc_to_copy}
            options={stdTicketFilteringData.value.ticket_category}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
          />
        </Grid>
        <Grid item xs={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={stdTicketAutomatDataToCopyFn.loading}
            disabled={!formValue.tc_to_copy}
            onClick={() =>
              stdTicketAutomatDataToCopyFn.execute(formValue.tc_to_copy)
            }
          >
            {t("confirm")}
          </LoadingButton>
        </Grid>
        <Grid item xs={9}>
          <AutocompleteField
            multiple={false}
            optionValueKey={"id"}
            label={t("dialog.std_ticket_automat.assign_one_k")}
            optionLabelKey={"full_name"}
            name={"k_to_copy"}
            value={formValue.k_to_copy}
            options={stdTicketFilteringData.value.ticautomat_k}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
          />
        </Grid>
        <Grid item xs={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={stdTicketAutomatDataToCopyFn.loading}
            disabled={!formValue.k_to_copy}
            onClick={handleCopyKToAllObject}
          >
            {t("confirm")}
          </LoadingButton>
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={1}
          marginTop={1}
          sx={{ maxHeight: "50vh", overflowY: "auto" }}
        >
          {generateStdTicketAutomatFields()}
        </Grid>
        <Grid item xs={12} marginTop={2}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={updateStdTicketAutomatFn.loading}
            disabled={!isFormValid}
            onClick={() => handleSubmit(prepareDataToSend())}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      showTopFullScreenButton={false}
      title={t("dialog.std_ticket_automat.assign_k_to_category")}
      maxWidth="sm"
      showDialogActions
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

StdTicketAutomatDialog.propTypes = {
  ticketCategoryId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onSubmitCallback: PropTypes.func,
};

StdTicketAutomatDialog.defaultProps = {
  open: false,
};

export default StdTicketAutomatDialog;
