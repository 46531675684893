import React from "react";
import PropTypes from "prop-types";
import TableService from "../../../services/tableService";
import FilterTable from "../FilterTable";

export default function TicketCategoryTable(props) {
  const data = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        props.tableConfig,
        props.data,
        props.hiddenColumns,
        props.columnsOrders,
        props.onClickEdit
      ),
    [props.data, props.hiddenColumns, props.columnsOrders]
  );

  return (
    <FilterTable
      showChangesCircle={props.showChangesCircle}
      showDetailsIcon={props.showDetailsIcon}
      checkedAll={props.checkedAll}
      onClickRow={props.onClickRow}
      showCheckbox={props.showCheckbox}
      onClickCheck={props.onClickCheck}
      onClickCheckAll={props.onClickCheckAll}
      data={data}
      filteringData={props.filteringData}
      countRecords={props.countRecords}
      page={props.page}
      onPageChange={props.onPageChange}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      rowsPerPageOptions={props.rowsPerPageOptions}
      resetPageNumber={props.resetPageNumber}
      headersConfig={TableService.getHeadersConfigForCollapsibleTable(
        props.tableConfig,
        props.filteringData,
        props.hiddenColumns,
        props.columnsOrders
      )}
      selectedItemId={props.selectedTicketId}
      style={props.style}
      filterPrefix={props.filterPrefix}
      showCleanFilterIcon={props.showCleanFilterIcon}
      showContextMenu={props.showContextMenu}
      contextMenuActions={props.contextMenuActions}
      showExportToFileButton={props.showExportToFileButton}
      exportToFileUrl={props.exportToFileUrl}
      exportToFileSearchParams={props.exportToFileSearchParams}
      exportToFileHeaders={props.exportToFileHeaders}
      exportToFileFileName={props.exportToFileFileName}
      showFilters={props.showFilters}
      excludedKeysForClearSearchParams={props.excludedKeysForClearSearchParams}
      initialExpandedFilterHeader={props.initialExpandedFilterHeader}
      withPagination={props.withPagination}
    />
  );
}

TicketCategoryTable.propTypes = {
  data: PropTypes.array,
  showCheckbox: PropTypes.bool,
  onClickCheck: PropTypes.func,
  onClickCheckAll: PropTypes.func,
  onClickEdit: PropTypes.func,
  style: PropTypes.object,
  hiddenColumns: PropTypes.array,
  columnsOrders: PropTypes.array,
  tableConfig: PropTypes.object,
  filterPrefix: PropTypes.string,
  showFilters: PropTypes.bool,
  showCleanFilterIcon: PropTypes.bool,
  showContextMenu: PropTypes.bool,
  contextMenuActions: PropTypes.arrayOf(PropTypes.object),
  showDetailsIcon: PropTypes.bool,
  showChangesCircle: PropTypes.func,
  showExportToFileButton: PropTypes.bool,
  exportToFileUrl: PropTypes.string,
  exportToFileSearchParams: PropTypes.object,
  exportToFileHeaders: PropTypes.array,
  exportToFileFileName: PropTypes.string,
  excludedKeysForClearSearchParams: PropTypes.array,
  initialExpandedFilterHeader: PropTypes.bool,
  withPagination: PropTypes.bool,
  filteringData: PropTypes.object,
};

TicketCategoryTable.defaultProps = {
  hiddenColumns: [],
  data: [],
  showFilters: true,
  showCheckbox: false,
  showCleanFilterIcon: false,
  showContextMenu: false,
  showDetailsIcon: false,
  contextMenuActions: [],
  showChangesCircle: false,
};
