import { Button, Divider, Grid, Paper } from "@mui/material";
import React, { useState, useEffect, useMemo, useRef } from "react";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import { useAuth } from "../../../context/auth";
import AutocompleteField from "../../field/AutocompleteField";
import useTicketService from "../../../services/ticketService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import TicketCardCategoriesTabs from "../../tabs/TicketCardCategoriesTabs";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import LocationRecords from "../../other/LocationRecords";
import useLocationService from "../../../services/locationService";
import useFormService from "../../../services/formService";
import QuestionForm from "../../other/QuestionForm";
import LoadingButton from "@mui/lab/LoadingButton";
import SelectFieldService from "../../../services/selectFieldService";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import FileUploadList from "../../other/FileUploadList";
import { EXTERNAL_TYPE } from "../../../helpers/constants";
import usePermissionService from "../../../services/permissionService";
import ChooseUserBehalfCreateTicketDialog from "../../dialog/ChooseUserBehalfCreateTicketDialog";
import useDialog from "../../../hooks/useDialog";
import useFileService from "../../../services/fileService";
import useFieldValidation from "../../../hooks/useFieldValidation";
import useField from "../../../hooks/useField";
import useUserService from "../../../services/userService";
import useFileOnMemoryData from "../../../hooks/useFileOnMemoryData";

const CreateTicketForm = React.memo((props) => {
  const { t } = useTranslation();

  const { user } = useAuth();

  const questionFormRef = useRef();

  const locationRecordsRef = useRef();

  const [
    openChooseUserBehalfCreateTicketDialog,
    onOpenChooseUserBehalfCreateTicketDialog,
    onCloseChooseUserBehalfCreateTicketDialog,
  ] = useDialog();

  const handleOpenChooseUserBehalfCreateTicketDialog = () => {
    if (props.readOnly) return;
    onOpenChooseUserBehalfCreateTicketDialog();
  };

  const { showReportOnBehalfButton, showAllObjectsAndLevels } =
    usePermissionService();

  const {
    getTicketCategoryShortData,
    getStdTicketShortData,
    getTicketDataForCopyTicket,
    getTicketDataForClaimTicket,
  } = useTicketService();

  const { getUserDefaultLocation, getUserDefaultLocationForTicketOM } =
    useUserService();
  const {
    getEmptyLocationElement,
    getChosenHighestLevelObject,
    prepareLocationDataFromBackendForLocationRecordsComponent,
  } = useLocationService();

  const { getEmptyQuestionAnswerObject, getAnswersForQuestion } =
    useFormService();

  const [formValue, setFormValue] = useState({});

  const { downloadFileByBlobUrl } = useFileService();

  const [locations, setLocations] = useState([]);

  const [stdTickets, setTickets] = useState();

  const [unfillRequiredFields, setUnfillRequiredFields] = useState([]);

  const [chosenStdTicket, setChosenStdTicket] = useState();

  const { getUnfillRequiredFields } = useFieldValidation();

  const { setFocusForFieldById } = useField();

  const categories = useAsync(
    () => getTicketCategoryShortData({ tc_active: !props.readOnly }),
    [props.readOnly]
  );

  const stdTicketsAsync = useAsync(() => getStdTicketShortData(), []);

  const defaultTicketOMLocationAsyncFn = useAsyncFn(
    getUserDefaultLocationForTicketOM
  );

  const [
    localFiles,
    onAddFile,
    onUpdateFile,
    onDeleteFile,
    prepareFilesToSend,
  ] = useFileOnMemoryData("enclosure", props.initialData?.enclosures || []);

  const onDownloadEnclosure = (enclosureId, enclosureName, index) => {
    const enclosure = localFiles[index];
    downloadFileByBlobUrl(enclosure.blob_url, enclosureName);
  };

  const isClaim = props.ticticKind === "claim";
  const isQuestionnaire =
    props.readOnly && !props.ticticKind && props.parentTicketId != null;

  const defaultLocationAsync = useAsync(() => {
    if (formValue.ticket_om === undefined && !isClaim && !props.initialData) {
      return getUserDefaultLocation();
    }
    return Promise.resolve(null);
  }, [formValue.ticket_om]);

  const parentTicketData = useAsync(() => {
    if (props.initialData) return Promise.resolve(props.initialData);

    if (!props.parentTicketId) return Promise.resolve(null);

    if (isClaim) return getTicketDataForClaimTicket(props.parentTicketId);

    return getTicketDataForCopyTicket(props.parentTicketId, {
      is_questionnaire: isQuestionnaire,
    });
  }, [props.parentTicketId, props.ticticKind]);

  const dataIsPopulated = useRef(false);

  useEffect(() => {
    if (parentTicketData.loading || !parentTicketData.value) return;

    const { id, locations, ...formData } = parentTicketData.value;

    setFormValue(formData);

    setLocations(
      prepareLocationDataFromBackendForLocationRecordsComponent(locations)
    );

    dataIsPopulated.current = true;
  }, [parentTicketData.loading]);

  useEffect(() => {
    if (stdTicketsAsync.value) {
      const stdTicketsNotIsEndList = stdTicketsAsync.value.filter(
        (x) => x.std_ticket_is_std === true && x.std_ticket_is_end_list !== true
      );
      const stdTicketIsEndList = stdTicketsAsync.value.filter(
        (x) => x.std_ticket_is_std === true && x.std_ticket_is_end_list === true
      );
      const notIsStdTicket = stdTicketsAsync.value.filter(
        (x) => x.std_ticket_is_std === false
      );
      setTickets([
        ...stdTicketsNotIsEndList,
        ...stdTicketIsEndList,
        ...notIsStdTicket,
      ]);
    } else {
      setTickets(stdTicketsAsync.value);
    }
  }, [stdTicketsAsync.value]);

  useEffect(() => {
    if (stdTickets) {
      const stdTicket = stdTickets.find((x) => x.id === formValue.std_ticket);
      setChosenStdTicket(stdTicket);

      // prevent overide question answers in overriding
      if (props.initialData?.question_answers) {
        return;
      }

      // prevent overide question answers when copy ticket
      if (dataIsPopulated.current === true && !props.initialData) {
        dataIsPopulated.current = false;
        return;
      }

      setFormValue((formTemp) => ({
        ...formTemp,
        question_answers:
          stdTicket?.std_ticket_1question &&
          stdTicket?.std_ticket_1question_is_active
            ? [getEmptyQuestionAnswerObject(stdTicket?.std_ticket_1question)]
            : [],
      }));
    }
  }, [formValue.std_ticket, stdTickets]);

  useEffect(() => {
    if (defaultLocationAsync.loading) {
      return;
    }
    if (
      defaultLocationAsync.value &&
      !props.readOnly &&
      !props.parentTicketId
    ) {
      setLocations(
        prepareLocationDataFromBackendForLocationRecordsComponent(
          defaultLocationAsync.value.default_location,
          false
        )
      );
    }
  }, [defaultLocationAsync.loading]);

  useEffect(() => {
    if (
      formValue.ticket_om &&
      !isClaim &&
      !props.initialData &&
      !props.readOnly
    ) {
      setLocations([]);
      defaultTicketOMLocationAsyncFn
        .execute(formValue.ticket_om.id)
        .then((res) => {
          setLocations(
            prepareLocationDataFromBackendForLocationRecordsComponent(
              res.default_location,
              false
            )
          );
        })
        .catch((error) => {
          setLocations([getEmptyLocationElement()]);
        });
    }
  }, [formValue.ticket_om]);

  const onChangeLocations = (locations) => {
    setLocations(locations);
  };

  const onSelectCategory = (category) => {
    if (isClaim) return;

    var stdTicket;
    const stdTicketForCategory = stdTickets.filter(
      (x) => x.ticket_category === category.id
    );
    if (stdTicketForCategory.length === 1) {
      stdTicket = stdTicketForCategory[0];
    }
    setFormValue((formTemp) => ({
      ...formTemp,
      ticket_name: stdTicket ? stdTicket.std_ticket_name : undefined,
      std_ticket: stdTicket ? stdTicket.id : undefined,
      ticket_category: category.id,
    }));
  };

  const onChangeFormValue = (e) => {
    const { name, value } = e.target;
    setFormValue((formTemp) => ({ ...formTemp, [name]: value }));
  };

  const onChangeStdTicketAutocomplete = (e, value) => {
    const category = categories.value.find(
      (x) => x.id === value.ticket_category
    );
    setFormValue((formTemp) => ({
      ...formTemp,
      std_ticket: value.id,
      ticket_category: category.id,
      ticket_name: value.std_ticket_name,
    }));
  };

  const onChangeStdTicketSelect = (e, value) => {
    setFormValue((formTemp) => ({
      ...formTemp,
      std_ticket: value.id,
      ticket_name: value.std_ticket_name,
    }));
  };

  const onChangeQuestionAnswers = (e, questionAnswers) => {
    setFormValue((formTemp) => ({
      ...formTemp,
      question_answers: questionAnswers,
    }));
  };

  const showLoading = () => {
    return (
      categories.loading ||
      stdTicketsAsync.loading ||
      stdTickets === undefined ||
      parentTicketData.loading ||
      defaultLocationAsync.loading
    );
  };

  const getUnfillRequiredFieldsLocal = () => {
    let requiredFields = [
      "std_ticket",
      "ticket_category",
      "ticket_name",
      "ticket_desc_creator",
    ];
    if (chosenStdTicketIsNotStd && !isClaim) {
      requiredFields.push("tikcet_contact");
    }

    let unfillFieldsTemp = getUnfillRequiredFields(requiredFields, formValue);
    // for (const location of locations) {
    //   if (isEmptyValue(location[0].id)) {
    //     return false;
    //   }
    // }
    if (locationRecordsRef?.current?.checkIfRequiredFieldsAreFill) {
      unfillFieldsTemp = [
        ...unfillFieldsTemp,
        ...locationRecordsRef.current.checkIfRequiredFieldsAreFill(),
      ];
    }
    if (questionFormRef?.current?.checkIfRequiredFieldsAreFill) {
      unfillFieldsTemp = [
        ...unfillFieldsTemp,
        ...questionFormRef.current.checkIfRequiredFieldsAreFill(),
      ];
    }

    // return true;
    return unfillFieldsTemp;
  };

  const checkIfRequiredFieldsAreFill = () => {
    const unfillFieldsTemp = getUnfillRequiredFieldsLocal();
    setUnfillRequiredFields(unfillFieldsTemp);
    if (unfillFieldsTemp.length > 0) {
      setFocusForFieldById(unfillFieldsTemp[0]);
      return false;
    }
    return true;
  };

  const prepareDataToSend = () => {
    var objects = [];
    for (const locationLevels of locations) {
      objects.push(getChosenHighestLevelObject(locationLevels));
    }
    var preparedData = {
      std_ticket: formValue.std_ticket,
      ticket_name: formValue.ticket_name,
      ticket_desc_creator: formValue.ticket_desc_creator,
      objects: objects,
      answers_for_question: isClaim
        ? []
        : getAnswersForQuestion(formValue.question_answers),
      enclosures: prepareFilesToSend(localFiles),
      tikcet_contact: formValue.tikcet_contact,
    };
    if (showReportOnBehalfButton) {
      preparedData.ticket_om = formValue.ticket_om?.id;
    }
    if (props.ticticKind) {
      preparedData.tictic_kind = {
        ticket_id: props.parentTicketId,
        tictic_kind: props.ticticKind,
      };
    }
    return preparedData;
  };

  const onSubmit = () => {
    if (checkIfRequiredFieldsAreFill()) {
      props.onSubmit(prepareDataToSend());
    }
  };

  const chosenStdTicketIsNotStd = useMemo(
    () => chosenStdTicket && !chosenStdTicket?.std_ticket_is_std,
    [chosenStdTicket]
  );

  const onSubmitChooseUserBehalfCreateTicketDialog = (user) => {
    setFormValue((formValueTemp) => ({ ...formValueTemp, ticket_om: user }));
    onCloseChooseUserBehalfCreateTicketDialog();
  };

  const getStdTicketOptions = () => {
    return isClaim
      ? stdTickets
      : stdTickets && stdTickets.filter((x) => !x.std_ticket_is_claim);
  };

  const getTicketOMforLocationRecords = () =>
    props.initialData || props.readOnly ? undefined : formValue.ticket_om;

  const ticketCategoriesHeight = props.fullScreen ? "auto" : "172px";

  return (
    <LoaderWrapper showLoader={showLoading()}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12} sm={8}>
              <AutocompleteField
                label={t("form.ticket_create_form.what_is_ticket_about")}
                variant={"outlined"}
                options={getStdTicketOptions()}
                optionLabelKey={"std_ticket_name"}
                addNewValue={false}
                onChange={onChangeStdTicketAutocomplete}
                value={formValue.std_ticket}
                disableClearable={true}
                readOnly={
                  props.readOnly ||
                  isClaim ||
                  props.readOnlyFields.includes("std_ticket")
                }
                allwaysShowOptions={
                  stdTickets
                    ? stdTickets.filter(
                        (x) =>
                          x.std_ticket_is_std === false &&
                          x.std_ticket_is_claim === false
                      )
                    : []
                }
                error={unfillRequiredFields.includes("std_ticket")}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} sm={showReportOnBehalfButton ? 6 : 12}>
                  <TextFieldFormControl
                    label={t("form.ticket_create_form.reporter")}
                    readOnly={true}
                    value={
                      props.readOnly ? formValue.ticket_owner : user.full_name
                    }
                  />
                </Grid>
                {showReportOnBehalfButton && (
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      fullWidth
                      size="small"
                      onClick={handleOpenChooseUserBehalfCreateTicketDialog}
                    >
                      {formValue.ticket_om
                        ? formValue.ticket_om.full_name
                        : t("form.ticket_create_form.report_on_behalf_of")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <TicketCardCategoriesTabs
              categories={
                categories.value
                  ? isClaim
                    ? categories.value.filter((x) => x.tc_is_claim === true)
                    : categories.value.filter((x) => x.tc_is_std === true)
                  : []
              }
              onSelectCategory={onSelectCategory}
              selectedCategoryId={formValue.ticket_category}
              readOnly={
                props.readOnly ||
                props.readOnlyFields.includes("ticket_category")
              }
              height={ticketCategoriesHeight}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <LocationRecords
            locations={locations}
            onChangeLocations={onChangeLocations}
            ref={locationRecordsRef}
            readOnly={
              props.readOnly ||
              isClaim ||
              props.readOnlyFields.includes("locations")
            }
            maxAllowedLevelNumber={
              props.maxAllowedLevelNumber
                ? props.maxAllowedLevelNumber
                : showAllObjectsAndLevels
                ? 5
                : 4
            }
            createTicketLocation={true}
            showAllObjectsAndLevels={showAllObjectsAndLevels}
            locationLoading={defaultLocationAsync.loading}
            ticketOm={getTicketOMforLocationRecords()}
            showAddLocationButton={!props.allowOnlySingleLocation}
            showSelectObjectLevelButton={true}
            objectLevelForSearchDialog={4}
          />
        </Grid>
        {formValue.ticket_category && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <TextFieldFormControl
                    label={t("form.ticket_create_form.ticket_title")}
                    value={formValue?.ticket_name}
                    required
                    onChange={onChangeFormValue}
                    name="ticket_name"
                    fullWidth
                    isInvalid={unfillRequiredFields.includes("ticket_name")}
                    helperText={
                      unfillRequiredFields.includes("ticket_name") &&
                      t("field_required")
                    }
                    disabled={props.readOnly || isClaim}
                  />
                </Grid>
                <Grid item></Grid>
              </Grid>
            </Grid>
            {!isClaim && (
              <Grid item xs={12}>
                <AutocompleteField
                  label={t(
                    "form.ticket_create_form.specify_what_the_ticket_is_about"
                  )}
                  required
                  variant={"outlined"}
                  options={
                    stdTickets
                      ? stdTickets.filter(
                          (x) => x.ticket_category === formValue.ticket_category
                        )
                      : []
                  }
                  optionLabelKey={"std_ticket_name"}
                  value={formValue.std_ticket}
                  onChange={onChangeStdTicketSelect}
                  addNewValue={false}
                  disableClearable={false}
                  error={unfillRequiredFields.includes("std_ticket")}
                  helperText={
                    unfillRequiredFields.includes("std_ticket") &&
                    t("field_required")
                  }
                  readOnly={
                    props.readOnly ||
                    isClaim ||
                    props.readOnlyFields.includes("std_ticket")
                  }
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <QuestionForm
                question_answers={formValue.question_answers}
                onChangeQuestionAnswers={onChangeQuestionAnswers}
                ref={questionFormRef}
                readOnly={props.readOnly}
                isQuestionnaire={isQuestionnaire}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormControl
                label={t("form.ticket_create_form.comment")}
                multiline
                rows={3}
                name={"ticket_desc_creator"}
                onChange={onChangeFormValue}
                value={formValue.ticket_desc_creator}
                required={true}
                isInvalid={unfillRequiredFields.includes("ticket_desc_creator")}
                helperText={
                  unfillRequiredFields.includes("ticket_desc_creator") &&
                  t("field_required")
                }
                disabled={props.readOnly}
              />
            </Grid>
            {formValue.std_ticket && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {props.showInfoAboutPaidTicket && (
                    <Grid item xs={12} sm={4}>
                      <TextFieldFormControl
                        label={t("form.ticket_create_form.is_paid")}
                        options={SelectFieldService.getOptionsYesOrNo()}
                        variant="outlined"
                        readOnly
                        value={
                          chosenStdTicket?.std_ticket_paid !== undefined
                            ? t(chosenStdTicket?.std_ticket_paid)
                            : t("no")
                        }
                      />
                    </Grid>
                  )}
                  {chosenStdTicketIsNotStd && !isClaim && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        display={{ xs: "none", lg: "block" }}
                      ></Grid>
                      <Grid item xs={12} sm={4}>
                        <TextFieldFormControl
                          label={t("form.ticket_create_form.phone_number")}
                          value={formValue?.tikcet_contact}
                          disabled={props.readOnly}
                          onChange={onChangeFormValue}
                          name="tikcet_contact"
                          fullWidth
                          required
                          isInvalid={unfillRequiredFields.includes(
                            "tikcet_contact"
                          )}
                          helperText={
                            unfillRequiredFields.includes("tikcet_contact") &&
                            t("field_required")
                          }
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            )}
            {!props.readOnly && (
              <Grid item xs={12}>
                <FileUploadList
                  addEnclosureButtonProps={{ size: "mini" }}
                  defaultEnclosureType={EXTERNAL_TYPE}
                  enclosures={localFiles}
                  onDownloadEnclosure={onDownloadEnclosure}
                  onAddEnclosure={onAddFile}
                  onDeleteEnclosure={onDeleteFile}
                  onUpdateEnclosure={onUpdateFile}
                />
              </Grid>
            )}
          </>
        )}
        {!props.readOnly && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={onSubmit}
              loading={props.loadingSubmitButton}
            >
              {t("create")}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
      {openChooseUserBehalfCreateTicketDialog && (
        <ChooseUserBehalfCreateTicketDialog
          open={openChooseUserBehalfCreateTicketDialog}
          onClose={onCloseChooseUserBehalfCreateTicketDialog}
          onSubmit={onSubmitChooseUserBehalfCreateTicketDialog}
          user={formValue.ticket_om?.id}
        />
      )}
    </LoaderWrapper>
  );
});

CreateTicketForm.propTypes = {
  parentTicketId: PropTypes.string,
  ticticKind: PropTypes.oneOf(["action", "visibility", "claim"]),
  onSubmit: PropTypes.func,
  loadingSubmitButton: PropTypes.bool,
  showInfoAboutPaidTicket: PropTypes.bool,
  readOnly: PropTypes.bool,
  fullScreen: PropTypes.bool,
  initialData: PropTypes.object,
  maxAllowedLevelNumber: PropTypes.number,
  readOnlyFields: PropTypes.readOnlyFields,
  allowOnlySingleLocation: PropTypes.bool,
};

CreateTicketForm.defaultProps = {
  showInfoAboutPaidTicket: false,
  fullScreen: false,
  readOnlyFields: [],
};

export default CreateTicketForm;
