import React from "react";

import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import { Grid, Typography } from "@mui/material";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import TrueFalseSelectField from "../../field/TrueFalseSelectField/trueFalseSelectField";
import DateField from "../../field/DateField/DateField";

import DateService from "../../../services/dateService";

import { convertFlatListFromBackendToTranslatedOptionObject } from "../../../helpers/methods";

import LocationRecords from "../../other/LocationRecords";

const OverridingForm = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item xs={6}>
        <TextFieldFormControl
          name={"overriding_nr"}
          readOnly={true}
          label={t("form.overriding.overriding_nr")}
          value={props.formValue.overriding_nr}
        />
      </Grid>
      <Grid item xs={6}>
        <DateField
          name={"overriding_date_plan"}
          label={t("form.overriding.overriding_date_plan")}
          value={DateService.convertDateToFormatYYYYMMDD(
            props.formValue.overriding_date_plan
          )}
          onChange={props.onChangeDate}
          required
          minDate={props.readOnly ? undefined : new Date()}
          readOnly={
            props.readOnly ||
            props.readOnlyFields.includes("overriding_date_plan")
          }
          showClearIcon={false}
        />
      </Grid>

      {/* <Grid item xs={6}>
        <TrueFalseSelectField
          value={props.formValue.is_closed}
          valuesAsBool
          label={t("form.overriding.is_closed")}
          readOnly={true}
        />
      </Grid> */}
      <Grid item xs={6}>
        <AutocompleteField
          name="overriding_subkind"
          value={props.formValue.overriding_subkind}
          options={convertFlatListFromBackendToTranslatedOptionObject(
            props.filteringData.value.subkind
          )}
          readOnly={
            props.readOnly ||
            props.readOnlyFields.includes("overriding_subkind")
          }
          isObjectOption={true}
          label={t("form.overriding.overriding_subkind")}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteField
          name="tenant"
          value={props.formValue.tenant}
          options={props.filteringData.value.tenant}
          optionLabelKey={"tenant_short_name"}
          isObjectOption={true}
          label={t("form.overriding.tenant")}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          readOnly={props.readOnly || props.readOnlyFields.includes("tenant")}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <LocationRecords
          locations={props.formValue.location}
          onChangeLocations={props.onChangeLocation}
          readOnly={props.readOnly || props.readOnlyFields.includes("location")}
          maxAllowedLevelNumber={4}
          showAddLocationButton={false}
        />
      </Grid>
      <Grid item xs={12} textAlign={"start"} display={"flex"} marginBottom={0}>
        <Typography variant="subtitle2" className="capitalize-first-letter">
          {t("form.overriding.overriding_contact")}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <TextFieldFormControl
          name={"overriding_contact_full_name"}
          label={t("form.overriding.overriding_contact_full_name")}
          value={props.formValue.overriding_contact_full_name}
          onChange={props.onChange}
          readOnly={
            props.readOnly ||
            props.readOnlyFields.includes("overriding_contact_full_name")
          }
        />
      </Grid>
      <Grid item xs={4}>
        <TextFieldFormControl
          name={"overriding_contact_email"}
          label={t("form.overriding.overriding_contact_email")}
          value={props.formValue.overriding_contact_email}
          onChange={props.onChange}
          readOnly={
            props.readOnly ||
            props.readOnlyFields.includes("overriding_contact_email")
          }
        />
      </Grid>
      <Grid item xs={4}>
        <TextFieldFormControl
          name={"overriding_contact_phone"}
          label={t("form.overriding.overriding_contact_phone")}
          value={props.formValue.overriding_contact_phone}
          onChange={props.onChange}
          readOnly={
            props.readOnly ||
            props.readOnlyFields.includes("overriding_contact_phone")
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"overriding_note"}
          label={t("form.overriding.overriding_note")}
          value={props.formValue.overriding_note}
          onChange={props.onChange}
          readOnly={
            props.readOnly || props.readOnlyFields.includes("overriding_note")
          }
          multiline
          rows={2}
        />
      </Grid>
    </Grid>
  );
});

OverridingForm.propTypes = {
  formValue: PropTypes.object,
  filteringData: PropTypes.object,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.array,
  onChange: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
};

OverridingForm.defaultProps = {
  readOnly: false,
};

export default OverridingForm;
