import { useState, useEffect, useMemo } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import LoaderWrapper from "../../../components/wrapper/LoaderWrapper";
import { useAsync } from "../../../hooks/useAsync";

import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../hooks/useFilterSearchParams";

import useRcpService from "../../../services/rcpService";
import DateService from "../../../services/dateService";

import UniversalToolBarWithDialogs from "../../../components/bar/UniversalToolBarWithDialogs";

import TableService from "../../../services/tableService";
import RcpTimeTrackingTable from "../../../components/table/RcpTimeTrackingTable";
import RcpTimeTrackingSummaryTable from "../../../components/table/RcpTimeTrackingSummaryTable";

import {
  exportRcpTimeTrackingToFileUrl,
  exportRcpTimeTrackingSummaryToFileUrl,
} from "../../../helpers/apiUrls";

import { isDictsAreEqual } from "../../../helpers/methods";

import {
  RCP_TIME_TRACKING_TABLE,
  RCP_TIME_TRACKING_CONFIG,
  RCP_TIME_TRACKING_SUMMARY_CONFIG,
  RCP_TIME_TRACKING_PREFIX,
} from "./TablesConfigs";

import {
  DEAULT_VALUE_FOR_SEARCH_PARAMS_SUMMARY_PAGE_KEY,
  DEAULT_VALUE_FOR_SUMMARY_PAGE_KEY,
  SEARCH_PARAMS_PAGE_KEYS,
  SEARCH_PARAMS_SUMMARY_PAGE_KEY,
  SEARCH_PARAMS_SUMMARY_PAGE_SIZE_KEY,
} from "../constants";

const tableConfig = {
  name: RCP_TIME_TRACKING_TABLE,
  config: RCP_TIME_TRACKING_CONFIG,
};

const RcpTimeTrackingTables = (props) => {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams(RCP_TIME_TRACKING_PREFIX);

  const {
    page: summaryPage,
    pageSize: summaryPageSize,
    handleChangePageWithSearchParams: handleChangeSummaryPageWithSearchParams,
    handleChangeRowsPerPage: handleChangeSummaryRowsPerPage,
    resetPageNumber: resetSummaryPageNumber,
    handelChangeSearchParams: handleChangeSearchParamsForSetNewSummaryPageValue,
    getFilterFieldKey,
  } = usePaginationWithSearchParams(
    RCP_TIME_TRACKING_PREFIX,
    undefined,
    undefined,
    SEARCH_PARAMS_SUMMARY_PAGE_KEY,
    SEARCH_PARAMS_SUMMARY_PAGE_SIZE_KEY
  );

  const { setNewItemSearchParamsIfAreChanged, getSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);

  const [rcpSearchParams, setRcpSearchParams] = useState(
    getSearchParamsByFilterPrefix(RCP_TIME_TRACKING_PREFIX)
  );

  const checkIfSetSummaryPageToDefaultValue = (searchParamsToSet) =>
    rcpSearchParams[SEARCH_PARAMS_SUMMARY_PAGE_KEY] &&
    rcpSearchParams[SEARCH_PARAMS_SUMMARY_PAGE_KEY][0] !==
      DEAULT_VALUE_FOR_SUMMARY_PAGE_KEY &&
    !isDictsAreEqual(
      searchParamsToSet,
      rcpSearchParams,
      SEARCH_PARAMS_PAGE_KEYS
    );

  const handleSetRcpSearchParams = (searchParamsToSet) => {
    if (checkIfSetSummaryPageToDefaultValue(searchParamsToSet)) {
      searchParamsToSet[SEARCH_PARAMS_SUMMARY_PAGE_KEY] =
        DEAULT_VALUE_FOR_SUMMARY_PAGE_KEY;
      handleChangeSearchParamsForSetNewSummaryPageValue({
        [getFilterFieldKey(SEARCH_PARAMS_SUMMARY_PAGE_KEY)]:
          DEAULT_VALUE_FOR_SEARCH_PARAMS_SUMMARY_PAGE_KEY,
      });
    }
    setRcpSearchParams(searchParamsToSet);
  };

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      RCP_TIME_TRACKING_PREFIX,
      rcpSearchParams,
      handleSetRcpSearchParams
    );
  }, [searchParams]);

  const { getRcpTimeTracking, getRcpPlanFilteringData } = useRcpService();

  const rcpData = useAsync(() => {
    const rcpSearchParamsTemp = {
      ...rcpSearchParams,
      hours_timezone_offset: DateService.getHoursTimezoneOffset(),
    };
    return getRcpTimeTracking(rcpSearchParamsTemp);
  }, [rcpSearchParams]);
  const rcpFilteringData = useAsync(getRcpPlanFilteringData);

  const [rcpDataLocaly, setRcpDataLocaly] = useState();
  const [summaryDataLocaly, setSummaryDataLocaly] = useState();

  const [countRecords, setCountRecords] = useState();
  const [summaryCountRecords, setSummaryCountRecords] = useState();

  useEffect(() => {
    if (rcpData.loading) {
      return;
    }
    setSummaryDataLocaly(rcpData.value.summary);
    setSummaryCountRecords(rcpData.value.summary_count);

    setRcpDataLocaly(rcpData.value.results);
    setCountRecords(rcpData.value.count);
  }, [rcpData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setRcpDataLocaly(undefined);
    rcpData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setRcpDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const rcpExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      RCP_TIME_TRACKING_CONFIG,
      hiddenColumnsForTables?.[RCP_TIME_TRACKING_TABLE],
      columnsOrdersForTables?.[RCP_TIME_TRACKING_TABLE]
    );
  }, [
    RCP_TIME_TRACKING_CONFIG,
    hiddenColumnsForTables?.[RCP_TIME_TRACKING_TABLE],
    columnsOrdersForTables?.[RCP_TIME_TRACKING_TABLE],
  ]);

  const summaryExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      RCP_TIME_TRACKING_SUMMARY_CONFIG,
      []
    );
  }, [RCP_TIME_TRACKING_SUMMARY_CONFIG]);

  const isLoading =
    rcpDataLocaly === undefined ||
    summaryDataLocaly === undefined ||
    rcpFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid container spacing={1} rowGap={1}>
      <Grid item xs={12}>
        <div
          style={{ display: "flex", whiteSpace: "nowrap", marginBottom: "5px" }}
        >
          <Typography variant="h6">
            {t("page.rcp_time_tracking.table_of_completed_activities")}
          </Typography>
          <UniversalToolBarWithDialogs
            pageName={props.pageName}
            style={{ justifyContent: "flex-end" }}
            mdGridButton={2}
            showOpenCloseFilterButton={false}
            showMyFiltersButton={false}
            showSearchButton={false}
            showCleanfiltersButton={false}
            tableConfig={tableConfig}
            filteringData={rcpFilteringData}
            refreshTable={refreshTable}
            onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
            resetPageNumber={resetPageNumber}
            hiddenColumnsForTables={hiddenColumnsForTables}
            setHiddenColumnsForTables={setHiddenColumnsForTables}
            columnsOrdersForTables={columnsOrdersForTables}
            setColumnsOrdersForTables={setColumnsOrdersForTables}
            filterPrefix={RCP_TIME_TRACKING_PREFIX}
          />
        </div>
        {hiddenColumnsForTables && (
          <RcpTimeTrackingTable
            showCleanFilterIcon={true}
            style={{ maxHeight: "60vh" }}
            showFilters={true}
            data={rcpDataLocaly}
            filteringData={rcpFilteringData.value}
            filterPrefix={RCP_TIME_TRACKING_PREFIX}
            countRecords={countRecords}
            page={page}
            onPageChange={handleChangePageWithSearchParams}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            resetPageNumber={resetPageNumber}
            tableConfig={RCP_TIME_TRACKING_CONFIG}
            hiddenColumns={hiddenColumnsForTables[RCP_TIME_TRACKING_TABLE]}
            columnsOrders={columnsOrdersForTables[RCP_TIME_TRACKING_TABLE]}
            showExportToFileButton={true}
            exportToFileUrl={exportRcpTimeTrackingToFileUrl}
            exportToFileSearchParams={rcpSearchParams}
            exportToFileHeaders={rcpExportHeaders}
            exportToFileFileName={`${t(
              "page.rcp_time_tracking.completed_activities"
            )}.xlsx`}
          />
        )}
      </Grid>
      <Grid item xs={8}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6">
            {t("page.rcp_time_tracking.summary")}
          </Typography>
        </div>
        <RcpTimeTrackingSummaryTable
          showCleanFilterIcon={false}
          style={{ maxHeight: "60vh" }}
          showFilters={false}
          data={summaryDataLocaly}
          countRecords={summaryCountRecords}
          page={summaryPage}
          pageKey={SEARCH_PARAMS_SUMMARY_PAGE_KEY}
          pageSizeKey={SEARCH_PARAMS_SUMMARY_PAGE_SIZE_KEY}
          onPageChange={handleChangeSummaryPageWithSearchParams}
          rowsPerPage={summaryPageSize}
          onRowsPerPageChange={handleChangeSummaryRowsPerPage}
          resetPageNumber={resetSummaryPageNumber}
          filterPrefix={RCP_TIME_TRACKING_PREFIX}
          tableConfig={RCP_TIME_TRACKING_SUMMARY_CONFIG}
          showExportToFileButton={true}
          exportToFileUrl={exportRcpTimeTrackingSummaryToFileUrl}
          exportToFileSearchParams={rcpSearchParams}
          exportToFileHeaders={summaryExportHeaders}
          exportToFileFileName={`${t(
            "page.rcp_time_tracking.summary_of_schedule_execution"
          )}.xlsx`}
        />
      </Grid>
    </Grid>
  );
};

RcpTimeTrackingTables.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

RcpTimeTrackingTables.defaultProps = {};

export default RcpTimeTrackingTables;
