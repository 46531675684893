import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import LocationRecords from "../../other/LocationRecords";
import BasicDialog from "../../base/BasicDialog";
import LoadingButton from "@mui/lab/LoadingButton";

import useLocationService from "../../../services/locationService";

const ChangeLocalizationDialog = (props) => {
  const { t } = useTranslation();
  const {
    prepareLocationDataToSend,
    prepareLocationDataFromBackendForLocationRecordsComponent,
  } = useLocationService();

  const [locationsLocaly, setLocationsLocaly] = useState(
    () =>
      props.preparedLocations ||
      prepareLocationDataFromBackendForLocationRecordsComponent(props.locations)
  );

  const onSubmit = () => {
    if (props.onSubmitWithRawData) {
      props.onSubmitWithRawData(locationsLocaly);
      props.onClose();
      return;
    }

    props.onSubmit(prepareLocationDataToSend(locationsLocaly), props.onClose);
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={t("dialog.change_localization.change_localization")}
      maxWidth="md"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        <Grid item xs={12}>
          <LocationRecords
            locations={locationsLocaly}
            onChangeLocations={setLocationsLocaly}
            showAddLocationButton={props.allowToAddMultipleLocations}
            maxAllowedLevelNumber={props.maxAllowedLevelNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            size="small"
            disabled={props.validateFn && !props.validateFn(locationsLocaly)}
            onClick={onSubmit}
          >
            {t("dialog.change_localization.update_localization")}
          </LoadingButton>
        </Grid>
      </Grid>
    </BasicDialog>
  );
};

ChangeLocalizationDialog.propTypes = {
  itemId: PropTypes.string,
  locations: PropTypes.array,
  preparedLocations: PropTypes.array,
  onSubmit: PropTypes.func,
  onSubmitWithRawData: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  allowToAddMultipleLocations: PropTypes.bool,
  maxAllowedLevelNumber: PropTypes.number,
  validateFn: PropTypes.func,
};

ChangeLocalizationDialog.defaultProps = {
  open: false,
  allowToAddMultipleLocations: true,
};

export default ChangeLocalizationDialog;
