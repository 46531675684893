import BasicDialog from "../../base/BasicDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { INTERNAL_TYPE } from "../../../helpers/constants";
import EnclosureCollapsibleTable from "../../table/EnclosureCollapsibleTable";
import useTicketService from "../../../services/ticketService";
import { useAsync } from "../../../hooks/useAsync";
import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";

function CopyEnclosureDialog(props) {
  const { t } = useTranslation();
  const [countRecords, setCountRecords] = useState();
  const [ticketDataLocaly, setTicketDataLocaly] = useState();

  const {
    page: ticketDataPage,
    pageSize: ticketDataPageSize,
    handleChangePageWithSearchParams: handleChangeTicketDataPage,
    handleChangePageSizeWithSearchParams: handleChangeTicketDataPageSize,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const { getTicketWithExistEnclosuresData, getTicketEnclosuresFilteringData } =
    useTicketService();

  const ticketData = useAsync(
    () => getTicketWithExistEnclosuresData(searchParams),
    [searchParams]
  );

  const ticketFilteringData = useAsync(getTicketEnclosuresFilteringData);

  useEffect(() => {
    if (ticketData.loading) {
      return;
    }
    setTicketDataLocaly(ticketData.value.results);
    setCountRecords(ticketData.value.count);
  }, [ticketData.loading]);

  const handleChangeRowsPerPage = (e) => {
    handleChangeTicketDataPageSize(e, parseInt(e.target.value, 10));
  };

  const openedTicketIdRef = useRef();

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
        setSearchParams({});
      }}
      titleAlign="center"
      contentAlign="center"
      title={t("dialog.copy_enclosure_dialog.copy_enclosure")}
      maxWidth="md"
    >
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <EnclosureCollapsibleTable
            data={ticketDataLocaly ? ticketDataLocaly : []}
            checkedAll={false}
            onClickRow={() => undefined}
            countRecords={countRecords}
            page={ticketDataPage}
            onPageChange={handleChangeTicketDataPage}
            rowsPerPage={ticketDataPageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            resetPageNumber={resetPageNumber}
            filteringData={ticketFilteringData ? ticketFilteringData : {}}
            hiddenColumns={[]}
            selectedTicketId={openedTicketIdRef.current}
            style={{ maxHeight: "75vh" }}
            onDownload={props.onDownload}
            onPreview={props.onPreview}
            onCopy={(id) => {
              props.onCopy(id);
              setSearchParams({});
            }}
          />
        </Grid>
      </Grid>
    </BasicDialog>
  );
}

CopyEnclosureDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  defaultEnclosureType: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  showExtendedAddEnclosureDialog: PropTypes.bool,
  onPreview: PropTypes.func,
  onDownload: PropTypes.func,
  onCopy: PropTypes.func,
};
CopyEnclosureDialog.defaultProps = {
  open: false,
  defaultEnclosureType: INTERNAL_TYPE,
  showExtendedAddEnclosureDialog: false,
};

export default CopyEnclosureDialog;
