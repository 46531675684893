import { useEffect, useState } from "react";
import usePagination from "./usePagination";
import { useSearchParams } from "react-router-dom";
import {
  getObjectFromSearchParams,
  getFieldValueFromSearchParams,
  getFilterSearchParamsKey,
  getFilterFieldKeyByNameAndPrefix,
} from "../helpers/methods";
import { useCallback } from "react";

export default function usePaginationWithSearchParams(
  filterPrefix = undefined,
  initialParams = undefined,
  defaultPageSize = 10,
  pageKey = "page",
  pageSizeKey = "page_size"
) {
  const [searchParams, setSearchParams] = useSearchParams(initialParams);
  const filterKey = getFilterSearchParamsKey(filterPrefix);
  const [page, handleChangePage, resetPageNumber] = usePagination(
    filterPrefix
      ? getFieldValueFromSearchParams(searchParams, `${filterKey}${pageKey}`)
      : getFieldValueFromSearchParams(searchParams, pageKey)
  );

  const getFilterFieldKey = useCallback(
    (fieldName) => {
      return getFilterFieldKeyByNameAndPrefix(fieldName, filterPrefix);
    },
    [filterPrefix]
  );

  const handleChangePageWithSearchParams = (e, pageNumber) => {
    handleChangePage(e, pageNumber);
    let pageFilterKey = getFilterFieldKey(pageKey);
    handelChangeSearchParams({ [pageFilterKey]: pageNumber });
  };

  const [pageSize, setPageSize] = useState(() =>
    filterPrefix
      ? getFieldValueFromSearchParams(
          searchParams,
          `${filterKey}${pageSizeKey}`
        ) || defaultPageSize
      : getFieldValueFromSearchParams(searchParams, pageSizeKey) ||
        defaultPageSize
  );

  const handleChangePageSizeWithSearchParams = (e, pageSize) => {
    pageSize = parseInt(e.target.value);
    resetPageNumber();
    setPageSize(pageSize);
    let pageFilterKey = getFilterFieldKey(pageKey);
    let pageFilterSizeKey = getFilterFieldKey(pageSizeKey);
    handelChangeSearchParams({
      [pageFilterKey]: 1,
      [pageFilterSizeKey]: pageSize,
    });
  };

  const handelChangeSearchParams = (dataToChange) => {
    setSearchParams((prev) => {
      const tempCurrSearchParamsObject = {
        ...getObjectFromSearchParams(searchParams),
      };

      return { ...tempCurrSearchParamsObject, ...dataToChange };
    });
  };

  const handleChangeRowsPerPage = (e) => {
    handleChangePageSizeWithSearchParams(
      e,
      parseInt(e.target.value, defaultPageSize)
    );
  };

  return {
    page,
    pageSize,
    setPageSize,
    handelChangeSearchParams,
    handleChangePageWithSearchParams,
    handleChangePageSizeWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
    getFilterFieldKey,
  };
}
