import { Button, Grid, Typography } from "@mui/material";
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseBox from "../../base/BaseBox/baseBox";
import RackSellLocationForm from "../../form/RackSellLocationForm";

export default function RackShellBox({ warehouseLocations, onAddWarLocation, onEditWarLocation, onRemoveWarLocation, readOnly }) {
    const { t } = useTranslation();

    return (
        <BaseBox>
            <Grid container spacing={1}>
                <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
                    <Typography
                        variant="subtitle2"
                        className="capitalize-first-letter"
                    >
                        {t("other.rack_shell_form_box.warehouse_structure")}
                    </Typography>
                </Grid>
                <Grid item sm={6} xs={6} />
                {warehouseLocations?.map((location, index) => {
                    return (
                        <RackSellLocationForm
                            index={index}
                            location={location}
                            onRemoveWarLocation={onRemoveWarLocation}
                            onEditWarLocation={onEditWarLocation}
                            readOnly={readOnly}
                        />
                    )
                })
                }
                <Grid item xs={12}>

                    {!readOnly &&
                        <Button
                            fullWidth
                            variant="text"
                            size="small"
                            onClick={onAddWarLocation}
                        >
                            {t("other.rack_shell_form_box.add_structure")}
                        </Button>
                    }
                </Grid>
            </Grid>
        </BaseBox>
    );
}

RackShellBox.propTypes = {
    readOnly: PropTypes.bool,
    warehouseLocations: PropTypes.array,
    onAddWarLocation: PropTypes.func,
    onRemoveWarLocation: PropTypes.func,
}


RackShellBox.defaultProps = {
    readOnly: true,
    warehouseLocations: []
}
