import React from "react";

import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import DateField from "../../../field/DateField/DateField";

import DateService from "../../../../services/dateService";
import i18n from "../../../../i18n";

import { useAuth } from "../../../../context/auth";
import { isUserOm } from "../../../../helpers/userPermissions";

const DateForm = ({
  createdDate,
  finishPlannedDateFieldName,
  finishPlannedDate,
  isAllowedToChangeFinishPlannedDate,
  onChangeFinishPlannedDate,
}) => {
  const { user } = useAuth();
  const isOm = isUserOm(user);
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12} sm={isOm ? 12 : 6}>
        <DateField
          label={i18n.t("form.date_form.created_date")}
          value={DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
            createdDate
          )}
          showClearIcon={false}
          readOnly
        />
      </Grid>
      {!isOm && (
        <Grid item xs={12} sm={6}>
          <DateField
            label={i18n.t("form.date_form.deadline")}
            name={finishPlannedDateFieldName}
            value={DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
              finishPlannedDate
            )}
            onChange={onChangeFinishPlannedDate}
            readOnly={!isAllowedToChangeFinishPlannedDate}
            showClearIcon={false}
          />
        </Grid>
      )}
    </Grid>
  );
};

DateForm.propTypes = {
  createdDate: PropTypes.string,
  finishPlannedDate: PropTypes.string,
  finishPlannedDateFieldName: PropTypes.string,
  isAllowedToChangeFinishPlannedDate: PropTypes.bool,
  onChangeFinishPlannedDate: PropTypes.func,
};

DateForm.defaultProps = {
  isAllowedToChangeFinishPlannedDate: false,
};

export default DateForm;
