import i18n from "../i18n";

const SelectFieldService = {
  convertListToSelectOptions: function (list, translatePrefix = "") {
    var options = [];
    for (const element of list) {
      options.push({
        id: element,
        name: i18n.t(`${translatePrefix}${element}`),
      });
    }
    return options.sort((a, b) => a.name.localeCompare(b.name));
  },

  getOptionsYesOrNo: function () {
    return [
      { id: true, name: i18n.t("yes") },
      { id: false, name: i18n.t("no") },
    ];
  },

  getLabelOptionsTrueOrFalse: function (value) {
    return { true: i18n.t("true"), false: i18n.t("false") }[value];
  },

  getLabelOptionsYesOrNo: function (value) {
    return { true: i18n.t("yes"), false: i18n.t("no") }[value];
  },
};

export default SelectFieldService;
