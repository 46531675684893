import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { isEmptyValue } from "../../../helpers/methods";
import usePhoneService from "../../../services/phoneService";
import PurchaseLineStatusForm from "../../form/PurchaseLineStatusForm/PurchaseLineStatusForm";
import usePurchaseService from "../../../services/purchaseService";
import { PURCHASE_LINE_DELIVERED_STATUS } from "../../../helpers/constants";


const PurchaseLineStatusDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [dataAreUpdating, setDataAreUpdating] = useState(false)
  const [requiredFields, setRequiredFields] = useState(["poli_status"])



  const {
    formValue,
    setFormValue,
    onChange,
  } = useForm({});
  
  useEffect(() => {
    if(formValue?.poli_status === PURCHASE_LINE_DELIVERED_STATUS){
      setRequiredFields(["poli_status", "poli_price_real"])
    }else{
      setRequiredFields(["poli_status"])
    }
  }, [formValue]);

  const { 
    updatePoLineData,
    getChangeStatusList
 } = usePurchaseService();

 const changeStatusList = useAsync(getChangeStatusList)
 const updatePoLineDataFn = useAsyncFn(updatePoLineData)


  const onSubmit = useCallback(() => {
    setDataAreUpdating(true)
    updatePoLineDataFn
        .execute(props.poLineId, formValue)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.status_changed")
          );
          setDataAreUpdating(false)
          props.onClose();
          props.onRefetch();

        })
        .catch((error) => {
          setDataAreUpdating(false)
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_status_change")
          );
        });
    
  }, [formValue]);

  const isValid = formValue ? requiredFields.every(
    (fieldName) => !isEmptyValue(formValue[fieldName])
  ) : false



  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={ t(`dialog.purchase_line_status_dialog.change_status`)}
      maxWidth="xs"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        {changeStatusList.loading  ?
          <LoaderWrapper isLoading={true} />
          :
          <>
            <Grid item xs={12}>
              <PurchaseLineStatusForm
                formValue={formValue}
                onChange={onChange}
                allowedStatuses= {changeStatusList.value? changeStatusList.value : []}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={dataAreUpdating}
                disabled={!isValid}
                onClick={onSubmit}
              >
                {t("save")}
              </LoadingButton>
            </Grid>
          </>
        }
      </Grid>
    </BasicDialog>
  );
};

PurchaseLineStatusDialog.propTypes = {
  poLineId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

PurchaseLineStatusDialog.defaultProps = {
  open: false,
};

export default PurchaseLineStatusDialog;
