import { useCallback, useMemo } from "react";
import { isEmptyValue, isEmptyArray } from "../helpers/methods";

const useFieldValidation = () => {
  const getUnfillRequiredFields = useCallback(
    (requiredFields, formValue, arrayFields) => {
      let unfillFields = [];
      for (const field of requiredFields) {
        if (arrayFields && arrayFields.includes(field)) {
          if (
            (Array.isArray(formValue[field]) &&
              isEmptyArray(formValue[field])) ||
            isEmptyValue(formValue[field])
          ) {
            unfillFields.push(field);
          }
        } else if (isEmptyValue(formValue[field])) {
          unfillFields.push(field);
        }
      }
      return unfillFields;
    },
    []
  );

  return {
    getUnfillRequiredFields,
  };
};

export default useFieldValidation;
