import { useState, useEffect, useMemo, useRef } from "react";

import PropTypes from "prop-types";

import { Grid, Button, Typography } from "@mui/material";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useAsync } from "../../../hooks/useAsync";
import useRcpService from "../../../services/rcpService";

import useFilterSearchParams from "../../../hooks/useFilterSearchParams";
import DateService from "../../../services/dateService";

import { useTranslation } from "react-i18next";

import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import {
  isSearchParamsObjectEmpty,
  getFieldValueFromSearchParams,
  getFilterFieldKeyByNameAndPrefix,
} from "../../../helpers/methods";

import { RcpScheduleTableHeaders } from "./Components/RcpScheduleTableHeaders";
import { RcpScheduleTableLegend } from "./Components/RcpScheduleTableLegend";
import { RcpScheduleTableBody } from "./Components/RcpScheduleTableBody";

import SelectRcpPlanRcpDoneButton from "../../button/SelectRcpPlanRcpDoneButton";

import useDialog from "../../../hooks/useDialog";
import CreateScheduleDialog from "../../dialog/CreateScheduleDialog";

import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { centerVericalAlignIconStyle } from "../../../helpers/styles";

import "./RcpGraphicSchedule.css";
import { useReactToPrint } from "react-to-print";

const RcpGraphicSchedule = (props) => {
  const { t } = useTranslation();

  const { searchParams } = usePaginationWithSearchParams(props.filterPrefix);

  const { setNewItemSearchParamsIfAreChanged, getSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);

  const rcpKind = useMemo(
    () =>
      getFieldValueFromSearchParams(
        searchParams,
        getFilterFieldKeyByNameAndPrefix("rcp_kind", props.filterPrefix)
      ),
    [searchParams]
  );

  const [rcpScheduleSearchParams, setRcpScheduleSearchParams] = useState(
    getSearchParamsByFilterPrefix(props.filterPrefix)
  );

  useEffect(() => {
    if (
      isSearchParamsObjectEmpty(
        getSearchParamsByFilterPrefix(props.filterPrefix)
      )
    ) {
      return;
    }

    setNewItemSearchParamsIfAreChanged(
      props.filterPrefix,
      rcpScheduleSearchParams,
      setRcpScheduleSearchParams,
      ["rcp_kind"]
    );
  }, [searchParams]);

  const { getRcpSchedule } = useRcpService();

  const rcpScheduleData = useAsync(() => {
    if (
      isSearchParamsObjectEmpty(rcpScheduleSearchParams) ||
      !rcpScheduleSearchParams.month
    )
      return Promise.resolve({});

    let tempRcpScheduleSearchParams = { ...rcpScheduleSearchParams };

    const { zeroHour, endHour } =
      DateService.getISOStringZeroAndEndHoursOfMonthAndYear(
        new Date(Date.parse(tempRcpScheduleSearchParams.month))
      );
    tempRcpScheduleSearchParams.day_start = zeroHour;
    tempRcpScheduleSearchParams.day_end = endHour;
    tempRcpScheduleSearchParams.hours_timezone_offset =
      DateService.getHoursTimezoneOffset();

    return getRcpSchedule({
      ...tempRcpScheduleSearchParams,
    });
  }, [rcpScheduleSearchParams]);

  const rcpScheduleTableToPrintRef = useRef();
  const handlePrintRcpScheduleTable = useReactToPrint({
    content: () => rcpScheduleTableToPrintRef.current,
  });

  const [selectedRcpType, setSelectedRcpType] = useState(() => "plan");

  const [
    openCreateScheduleDialog,
    onOpenCreateScheduleDialog,
    onCloseCreateScheduleDialog,
  ] = useDialog();

  const handleCloseCreateScheduleDialog = (refresh) => {
    if (refresh) {
      rcpScheduleData.refetch();
    } else {
      onCloseCreateScheduleDialog();
    }
  };

  const isLoading = !openCreateScheduleDialog && rcpScheduleData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  if (!rcpScheduleData.value?.headers || !rcpScheduleData.value?.data)
    return null;

  return (
    <Grid container>
      <Grid
        ref={rcpScheduleTableToPrintRef}
        item
        xs={12}
        container
        spacing={0}
        className={"rcp"}
        sx={{
          overflowY: "auto",
          fontSize: "12px",
          maxHeight: "55vh",
          userSelect: "none",
        }}
      >
        <style type="text/css" media="print">
          {"@page { size: landscape; margin: 2mm !important }"}
        </style>
        <Grid item xs={12} className="rcp-title">
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            style={{ margin: 0, color: "var(--primary)" }}
          >
            {`${t(
              "dialog.rcp_schedule_dialog.schedule"
            )} ${DateService.getMonthAndYearStringFromDayString(
              rcpScheduleSearchParams?.month[0]
            )}`}
          </Typography>
        </Grid>
        <RcpScheduleTableHeaders
          headers={rcpScheduleData.value?.headers}
          rcpKind={rcpKind}
        />
        <RcpScheduleTableBody
          skills={rcpScheduleData.value?.data}
          numDays={Object.keys(rcpScheduleData.value?.headers).length}
          rcpKind={rcpKind}
        />
      </Grid>
      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        sx={{ fontSize: "12px", marginTop: "20px" }}
      >
        <Grid item xs={2}>
          <RcpScheduleTableLegend />
        </Grid>
        <Grid
          item
          xs={2}
          container
          justifyContent={"flex-end"}
          alignContent={"space-between"}
          direction={"row"}
        >
          <Grid item xs={12} sm={10} textAlign={"left"}>
            <SelectRcpPlanRcpDoneButton
              onClick={onOpenCreateScheduleDialog}
              onChangeKeyValue={setSelectedRcpType}
              startIcon={
                <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
              }
              selectedKey={selectedRcpType}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              size="small"
              onClick={handlePrintRcpScheduleTable}
            >
              {t("dialog.rcp_schedule_dialog.print")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {openCreateScheduleDialog && (
        <CreateScheduleDialog
          open={openCreateScheduleDialog}
          onClose={handleCloseCreateScheduleDialog}
          kind={selectedRcpType}
          backdropColor={"rgba(0, 0, 0, 0)"}
        />
      )}
    </Grid>
  );
};

RcpGraphicSchedule.propTypes = {
  filterPrefix: PropTypes.string,
};

RcpGraphicSchedule.defaultProps = {
  filterPrefix: "rcp_schedule",
};

export default RcpGraphicSchedule;
