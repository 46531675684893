import React from "react";

import { OliviaFooter } from "../../media/footer";

const PageFooter = () => {
  return (
    <img src={OliviaFooter} height={100} style={{ marginInline: "left" }} />
  );
};

export default PageFooter;
