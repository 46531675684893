import { useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { Grid, Typography, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import TimeDurationSelectField from "../../field/TimeDurationSelectField";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import BaseBox from "../../base/BaseBox/baseBox";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";

export default function TicketOrderUpperForm(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const itemName =
    props.itemType === "order"
      ? props.formValue.order_name
      : props.formValue.ticket_name;

  const [itemNameLocaly, setItemNameLocaly] = useState(itemName);

  const onChangeTextFieldLocaly = (e) => {
    setItemNameLocaly(e.target.value);
  };

  const handleChangeItemName = (e) => {
    if (e.target.value !== itemName) {
      props.onChange(e);
    }
  };

  const getCategoryOptions = () => {
    return props.isTicketClaim || props.itemType === "order"
      ? props.filteringData.ticket_category
      : props.filteringData.ticket_category.filter(
          (tc) => tc.tc_is_claim === false
        );
  };

  const getBackUrl = () =>
    props.itemType === "order" ? "/my_orders/" : "/my_tickets/";

  const getBackUrlTooltipLabel = () =>
    props.itemType === "order"
      ? t("form.ticket_order_details_form.my_orders")
      : t("form.ticket_order_details_form.my_tickets");

  const getTicketDetailsUrl = () =>
    `/ticket_details/${props.formValue.ticket_id}/`;

  const getTicketDetailsUrlTooltipLabel = () => {
    return t("form.ticket.ticket_details");
  };

  return (
    <BaseBox>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12} sx={{ marginBottom: "5px" }}>
          <div style={{ position: "absolute" }}>
            <IconButton color="primary" onClick={() => navigate(getBackUrl())}>
              <Tooltip title={getBackUrlTooltipLabel()}>
                <KeyboardBackspaceIcon />
              </Tooltip>
            </IconButton>
          </div>
          <Typography
            variant="h6"
            className="capitalize-first-letter"
            color={"primary"}
          >
            {props.itemType === "ticket"
              ? t("form.ticket_order_details_form.ticket")
              : t("form.ticket_order_details_form.order")}
            {props.itemType === "order" && (
              <IconButton
                color="primary"
                onClick={() => navigate(getTicketDetailsUrl())}
              >
                <Tooltip title={getTicketDetailsUrlTooltipLabel()}>
                  <OpenInBrowserIcon
                    fontSize="small"
                    style={{ marginBottom: "5px" }}
                  />
                </Tooltip>
              </IconButton>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFieldFormControl
            label={
              props.itemType === "ticket"
                ? t("form.ticket_order_details_form.ticket_nr")
                : t("form.ticket_order_details_form.order_nr")
            }
            value={
              props.itemType === "ticket"
                ? props.formValue.ticket_nr
                : props.formValue.order_nr
            }
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <AutocompleteField
            name="ticket_category"
            label={t("form.ticket_order_details_form.ticket_category")}
            value={props.formValue.ticket_category}
            options={getCategoryOptions()}
            isObjectOption={true}
            optionLabelKey={"tc_name"}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            disableClearable
            readOnly={
              props.readOnly ||
              props.isTicketClaim ||
              props.itemType === "order"
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFieldFormControl
            label={
              props.itemType === "order"
                ? t("form.ticket_order_details_form.order_name")
                : t("form.ticket_order_details_form.ticket_name")
            }
            name={props.itemType === "order" ? "order_name" : "ticket_name"}
            value={itemNameLocaly}
            readOnly={props.readOnly || props.isTicketClaim}
            onChange={onChangeTextFieldLocaly}
            onBlur={handleChangeItemName}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <AutocompleteField
            label={t("form.ticket_order_details_form.priority")}
            name={
              props.itemType === "order" ? "order_priority" : "ticket_priority"
            }
            value={
              props.itemType === "order"
                ? props.formValue.order_priority
                : props.formValue.ticket_priority
            }
            options={
              props.itemType === "order"
                ? props.filteringData.order_priority
                : props.filteringData.ticket_priority
            }
            isObjectOption={true}
            optionLabelKey={
              props.itemType === "order" ? "ordpri_name" : "ticpri_name"
            }
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            disableClearable
            readOnly={props.readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFieldFormControl
            label={t("form.ticket_order_details_form.status")}
            value={
              props.itemType === "order"
                ? props.formValue.status
                : props.formValue.ticket_status
            }
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFieldFormControl
            label={t("form.ticket_order_details_form.substatus")}
            value={props.formValue.ticket_substatus}
            readOnly={true}
          />
        </Grid>
        {props.itemType === "order" && (
          <>
            <Grid item xs={12} sm={6}>
              <AutocompleteField
                label={t("form.item_details.order_type")}
                name="order_type"
                value={props.formValue.order_type}
                options={props.filteringData.order_type}
                isObjectOption={true}
                optionLabelKey={"ordtyp_type"}
                onChange={props.onChangeAutocompleteFieldWithObjectOptions}
                readOnly={props.readOnly}
                disableClearable
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TimeDurationSelectField
                label={t("form.item_details.order_std_time_value")}
                name="order_std_time_value"
                value={props.formValue.order_std_time_value}
                onChange={props.onChange}
                readOnly={props.readOnly}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <TextFieldFormControl
            name="ticket_desc_creator"
            label={t("form.ticket_order_details_form.ticket_desc_creator")}
            value={props.formValue.ticket_desc_creator}
            readOnly={true}
            multiline={true}
            resize={"vertical"}
            inputStyle={{
              "-webkit-text-fill-color": "black",
            }}
          />
        </Grid>
        {props.itemType === "order" && (
          <Grid item xs={12}>
            <TextFieldFormControl
              name="order_desc_creator"
              label={t("form.ticket_order_details_form.order_desc_creator")}
              value={props.formValue.order_desc_creator}
              readOnly={true}
              multiline={true}
              resize={"vertical"}
              inputStyle={{
                "-webkit-text-fill-color": "black",
              }}
            />
          </Grid>
        )}
      </Grid>
    </BaseBox>
  );
}

TicketOrderUpperForm.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  filteringData: PropTypes.object,
  readOnly: PropTypes.bool,
  isTicketClaim: PropTypes.bool,
  itemType: PropTypes.oneOf(["ticket", "order"]),
};

TicketOrderUpperForm.defaultProps = {
  formValue: {},
  readOnly: false,
};
