import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useFieldValidation from "../../../hooks/useFieldValidation";
import useWarehouseService from "../../../services/warehouseService";
import WarehouseDetailsForm from "../../form/WarehouseDetailsForm";
import LocationRecords from "../../other/LocationRecords";
import useLocationService from "../../../services/locationService";
import RackShellBox from "../../box/RackShellBox";
import useDialog from "../../../hooks/useDialog";
import WarehouseLocationDetailsDialog from "../WarehouseLocationDetailsDialog";

const WarehouseDetailsDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const { getUnfillRequiredFields } = useFieldValidation();

  const [
    openWarLocDialog,
    onOpenWarLocDialog,
    handleCloseWarLocDialog,
  ] = useDialog();


  const selectedLocationId = useRef();
  const selectedLocationData = useRef();
  const selectedLocationIndex = useRef();

  const {
    getChosenHighestLevelObject,
    prepareLocationDataFromBackendForLocationRecordsComponent
  } = useLocationService();

  const locationRecordsRef = useRef();
  const [locations, setLocations] = useState([]);

  const [unfillRequiredFields, setUnfillRequiredFields] = useState([]);

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions
  } = useForm({});


  const {
    getWarehouseDetails,
    updateWarehouse,
    createWarehouse,
    getWarehouseLocationLists,
    updateWarehouseLocation
  } = useWarehouseService();


  const updateWarehouseFn = useAsyncFn(updateWarehouse)
  const createWarehouseFn = useAsyncFn(createWarehouse)
  const refetchLocationsFn = useAsyncFn(getWarehouseLocationLists)
  const updateWarehouseLocationFn = useAsyncFn(updateWarehouseLocation);

  const onCloseWarLocDialog = useCallback(() => {
    selectedLocationId.current = undefined
    selectedLocationData.current = undefined
    selectedLocationIndex.current = undefined
    handleCloseWarLocDialog()
  }, [])

  const onEditWarLoc = useCallback((warLocId, index) => {
    if (props.warehouseId) {
      selectedLocationId.current = warLocId
    } else {
      selectedLocationData.current = formValue['warehouse_location'][index]
      selectedLocationIndex.current = index
    }
    onOpenWarLocDialog()
  }, [props.warehouseId, selectedLocationId.current, formValue])

  const onRemoveWarLoc = useCallback((warLocId, index) => {
    if (warLocId) {
      onHideWarLoc(warLocId)
    } else {
      setFormValue((formValue) => {
        let locations = [...formValue.warehouse_location];
        locations.splice(index, 1);
        formValue['warehouse_location'] = locations
        return { ...formValue };

      });
    }
  }, [formValue])

  const onHideWarLoc = useCallback((warLocId) => {
    updateWarehouseLocationFn
      .execute(warLocId, { 'warloc_active': false })
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.warehouse_structure_updated")
        );
        onRefetchWarLocations()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_warehouse_structure_updating")
        );
      });
  }, [formValue])


  const warehouseDetailsData = useAsync(
    () => {
      if (props.warehouseId) {
        return getWarehouseDetails(props.warehouseId)
      }
      return Promise.resolve({})

    },
    [props.warehouseId]);

  useEffect(() => {
    if (warehouseDetailsData.loading) {
      return;
    }
    if (warehouseDetailsData.value) {
      setFormValue(warehouseDetailsData.value);
      setLocations(prepareLocationDataFromBackendForLocationRecordsComponent(warehouseDetailsData.value.object));
    }
  }, [warehouseDetailsData.loading]);

  const getUnfillRequiredFieldsLocal = () => {
    let requiredFields = [
      "warehouse_name",
      "owner",
      "warehouse_responsible",
    ];

    let unfillFieldsTemp = getUnfillRequiredFields(requiredFields, formValue);

    if (locationRecordsRef?.current?.checkIfRequiredFieldsAreFill) {
      unfillFieldsTemp = [
        ...unfillFieldsTemp,
        ...locationRecordsRef.current.checkIfRequiredFieldsAreFill(),
      ];
    }

    return unfillFieldsTemp;
  };

  const checkIfRequiredFieldsAreFill = () => {
    const unfillFieldsTemp = getUnfillRequiredFieldsLocal();
    setUnfillRequiredFields(unfillFieldsTemp);
    if (unfillFieldsTemp.length > 0) {
      return false;
    }
    return true;
  };

  const onPrepareData = (data) => {
    data['warehouse_object'] = getChosenHighestLevelObject(locations[0])
    return data
  }


  const onAddLocationForNewWarehouse = useCallback((locsData, index) => {
    let tempLocs = formValue.warehouse_location !== undefined ? formValue.warehouse_location : []
    let updatedLocs =[]
    if (index!== undefined){
      tempLocs[index] = locsData
      updatedLocs = [...tempLocs]
    }else{
      updatedLocs = [...tempLocs, locsData]
    }
    setFormValue((formValue) => ({
      ...formValue,
      ['warehouse_location']: [...updatedLocs],
    }));
  }, [formValue])

  const onChangeLocations = (locations) => {
    setLocations(locations);
  };

  const onRefetchWarLocations = () => {
    if (props.warehouseId) {
      refetchLocationsFn.execute({ 'warehouse': props.warehouseId })
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.warehouse_updated")
          );
          setFormValue((formValue) => ({
            ...formValue,
            ['warehouse_location']: [...res],
          }));

        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_warehouse_refreshing")
          );
        });
    }
  }

  const onSubmit = useCallback(() => {
    if (checkIfRequiredFieldsAreFill()) {
      if (props.warehouseId) {
        updateWarehouseFn
          .execute(props.warehouseId, onPrepareData(formValue))
          .then((res) => {
            props.filteringData.refetch()
            props.onRefetchData()
            snackbarAlert.openSuccessSnackbarAlert(
              t("snackbar_alert.warehouse_updated")
            );
            props.onClose();
          })
          .catch((error) => {
            snackbarAlert.openErrorSnackbarAlert(
              t("snackbar_alert.occurred_error_during_warehouse_updating")
            );
          });
      } else {
        createWarehouseFn
          .execute(onPrepareData(formValue))
          .then((res) => {
            props.filteringData.refetch()
            props.onRefetchData()
            snackbarAlert.openSuccessSnackbarAlert(
              t("snackbar_alert.warehouse_created")
            );
            props.onClose();
          })
          .catch((error) => {
            snackbarAlert.openErrorSnackbarAlert(
              t("snackbar_alert.occurred_error_during_warehouse_creating")
            );
          });
      }
    }

  }, [formValue, unfillRequiredFields, locations]);

  const readOnly = warehouseDetailsData.value?.warehouse_active === false
  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.warehouseId ? t(`dialog.warehouse_details_dialog.warehouse_edit`) : t(`dialog.warehouse_details_dialog.create_warehouse`)}
      maxWidth="lg"
      showDialogActions
    >

      <LoaderWrapper showLoader={warehouseDetailsData.loading} >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12}>
            <WarehouseDetailsForm
              readOnly={readOnly}
              warehouseId={props.warehouseId}
              warehouseData={formValue}
              onChange={onChange}
              onChangeAutocomplete={onChangeAutocompleteFieldWithObjectOptions}
              filteringData={props.filteringData}
              unfillRequiredFields={unfillRequiredFields}
            />
          </Grid>
          <Grid item xs={12}>
            <LocationRecords
              locations={locations}
              onChangeLocations={onChangeLocations}
              ref={locationRecordsRef}
              readOnly={readOnly}
              maxAllowedLevelNumber={5}
              createTicketLocation={false}
              showAllObjectsAndLevels={true}
              showAddLocationButton={false}
            />
          </Grid>
          <Grid item xs={12}>
            < RackShellBox
              readOnly={readOnly}
              warehouseLocations={formValue?.warehouse_location}
              onAddWarLocation={onOpenWarLocDialog}
              onEditWarLocation={onEditWarLoc}
              onRemoveWarLocation={onRemoveWarLoc}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={updateWarehouseFn.loading || createWarehouseFn.loading}
              onClick={onSubmit}
            >
              {props.warehouseId ? t("save") : t("create")}
            </LoadingButton>
          </Grid>

        </Grid>

      </LoaderWrapper>

      {!props.readOnly && openWarLocDialog &&
        <WarehouseLocationDetailsDialog
          warLocationId={selectedLocationId.current}
          warehouseLocationData={selectedLocationData.current}
          tableIndex = {selectedLocationIndex.current}
          open={openWarLocDialog}
          warehouseId={props.warehouseId}
          onRefetch={onRefetchWarLocations}
          onClose={onCloseWarLocDialog}
          onAddLocationForNewWarehouse={onAddLocationForNewWarehouse}
        />
      }
    </BasicDialog>
  );
};

WarehouseDetailsDialog.propTypes = {
  warehouseId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  onRefetchData: PropTypes.func,
  filteringData: PropTypes.array,
};

WarehouseDetailsDialog.defaultProps = {
  open: false,
  filteringData: []
};

export default WarehouseDetailsDialog;
