import { Button } from "@mui/material";

function MiniButton(props){

    return (
        <Button size="small" className="mini-button" {...props}>
            {props.children}
        </Button>
    )
}

export default MiniButton;