import { useEffect } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import useDocsService from "../../../services/docsService";

import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useForm } from "../../../hooks/useForm";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import BasicDialog from "../../base/BasicDialog";

import TextFieldFormControl from "../../field/TextFieldFormControl";

import { isEmptyValue } from "../../../helpers/methods";

function DocsUserNoteDialog(props) {
  const { t } = useTranslation();

  const snackbarAlert = useSnackbarAlert();

  const isEditMode = props.docsUserNoteId != null;

  const getDialogTitle = () => {
    if (isEditMode) return t("dialog.docs_user_note.edit_note");
    return t("dialog.docs_user_note.add_note");
  };

  const { formValue, setFormValue, onChange } = useForm();

  const {
    getDocsUserNote,
    updateDocsUserNote,
    deleteDocsUserNote,
    createDocsUserNote,
  } = useDocsService();

  const createDocsUserNoteFn = useAsyncFn(createDocsUserNote);
  const updateDocsUserNoteFn = useAsyncFn(updateDocsUserNote);
  const deleteDocsUserNoteFn = useAsyncFn(deleteDocsUserNote);

  const docsUserNoteData = useAsync(() => {
    if (props.docsUserNoteId) return getDocsUserNote(props.docsUserNoteId);

    return Promise.resolve({
      docs: props.docsId,
      user: props.userId,
    });
  }, [props.docsUserNoteId]);

  useEffect(() => {
    if (docsUserNoteData.loading) return;

    setFormValue(docsUserNoteData.value);
  }, [docsUserNoteData.loading]);

  const submitFn = !isEditMode
    ? createDocsUserNoteFn
    : !isEmptyValue(formValue?.docs_user_note)
    ? updateDocsUserNoteFn
    : deleteDocsUserNoteFn;

  const onSubmit = () => {
    submitFn
      .execute(formValue, props.docsUserNoteId)
      .then(() => {
        if (props.onSubmit) props.onSubmit();
        props.onClose();
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.user_docs_note_updated")
        );
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_user_docs_note_saving")
        );
      });
  };
  const isFormValid = isEditMode || formValue?.docs_user_note;

  const isLoading = docsUserNoteData.loading;

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={getDialogTitle()}
      maxWidth="xs"
      showTopFullScreenButton={false}
    >
      <LoaderWrapper showLoader={isLoading}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <TextFieldFormControl
              name="docs_user_note"
              //   label={t("dialog.docs_user_note.docs_user_note")}
              value={formValue?.docs_user_note}
              resize={"vertical"}
              onChange={onChange}
              multiline
              rows={10}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              disabled={!isFormValid}
              variant="contained"
              onClick={onSubmit}
              loading={submitFn.loading}
              fullWidth
            >
              {t("dialog.docs_user_note.save")}
            </LoadingButton>
          </Grid>
        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
}

DocsUserNoteDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  docsUserNoteId: PropTypes.string,
  docsId: PropTypes.string,
  userId: PropTypes.string,
};
DocsUserNoteDialog.defaultProps = {
  open: false,
};

export default DocsUserNoteDialog;
