import React from "react";
import FullWidthTabs from "../FullWidthTabs/FullWidthTabs";
import TicketCardCategories from "../../other/TicketCardCategories";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";

const TicketCardCategoriesTabs = React.memo((props) => {
  const { t } = useTranslation();

  // return (
  //     <FullWidthTabs
  //         items={
  //             [
  //                 {
  //                     label: t("tabs.ticket_card_categories_tabs.service_request"),
  //                     content: (
  //                         <TicketCardCategories
  //                             categories={props.categories.filter(x => x.is_service_request === true)}
  //                             onCardClick={props.onSelectCategory}
  //                             selectedCategoryId={props.selectedCategoryId}
  //                         />
  //                     )
  //                 },
  //                 {
  //                     label: t("tabs.ticket_card_categories_tabs.additional_works"),
  //                     content: (
  //                         <TicketCardCategories
  //                             categories={props.categories.filter(x => x.is_additional_work === true)}
  //                             onCardClick={props.onSelectCategory}
  //                             selectedCategoryId={props.selectedCategoryId}
  //                         />
  //                     )
  //                 }
  //             ]
  //         }

  //     />
  // )
  return (
    <Box sx={{ width: "100%", marginTop: 1 }}>
      <TicketCardCategories
        categories={props.categories}
        onCardClick={props.onSelectCategory}
        selectedCategoryId={props.selectedCategoryId}
        readOnly={props.readOnly}
        height={props.height}
      />
    </Box>
  );
});

TicketCardCategoriesTabs.propTypes = {
  categories: PropTypes.array,
  onSelectCategory: PropTypes.func,
  selectedCategoryId: PropTypes.object,
  readOnly: PropTypes.bool,
  height: PropTypes.bool,
};

TicketCardCategoriesTabs.defaultProps = {
  categories: [],
};

export default TicketCardCategoriesTabs;
