import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import TrueFalseSelectField from "../../field/TrueFalseSelectField/trueFalseSelectField";
import { Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { convertFlatListFromBackendToTranslatedOptionObject } from "../../../helpers/methods";
import QuestionAnswerAdminForm, {
  INACTIVE_ANSWER_COLOR,
  ANSWER_BORDER_COLOR,
} from "../QuestionAnswerAdminForm/QuestionAnswerAdminForm";

import { IconButton } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

import { blendHexWithOpacity } from "../../../helpers/methods";

const INACTIVE_QUESTION_COLOR = blendHexWithOpacity(INACTIVE_ANSWER_COLOR, 0.5);
const QUESTION_BORDER_COLOR = ANSWER_BORDER_COLOR;

const QuestionAdminForm = (props) => {
  const { t } = useTranslation();

  const handleOnDragEnd = ({ source, destination }) => {
    if (!destination) return;

    props.onChangeAnswerOrder(source.index, destination.index);
  };

  const getFormStyle = () => ({
    border: `1px solid ${
      !props.formValue.question_active
        ? INACTIVE_QUESTION_COLOR
        : QUESTION_BORDER_COLOR
    }`,
    borderRadius: "20px",
    padding: "10px",
    marginBlockEnd: "20px",
    backgroundColor: !props.formValue.question_active
      ? INACTIVE_QUESTION_COLOR
      : null,
  });

  const getQuestionAnswerIcon = () =>
    props.readOnly ? (
      <FiberManualRecordIcon
        fontSize="small"
        style={{
          color: "var(--primary)",
          zIndex: 100,
          marginRight: "10px",
        }}
      />
    ) : (
      <DragIndicatorIcon
        style={{
          cursor: "grab",
          color: "var(--primary)",
          zIndex: 100,
          marginRight: "10px",
        }}
      />
    );

  const getDraggableQuestionAnswerForm = (answer, idx) => (
    <Draggable
      key={answer.id}
      draggableId={answer.id}
      index={idx}
      isDragDisabled={props.readOnly}
    >
      {(draggableProvided, draggableSnapshot) => {
        return (
          <div
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            style={{
              ...draggableProvided.draggableProps.style,
              left: "auto !important",
              top:
                props.dialogContentScrollPosition > 0
                  ? draggableProvided.draggableProps.style.top - 25
                  : "auto !important",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBlock: "20px",
              }}
            >
              {getQuestionAnswerIcon()}
              <QuestionAnswerAdminForm
                readOnly={props.readOnly}
                formValue={answer}
                filteringData={props.questionAnswerFilteringData}
                idx={idx}
                onChange={props.onChangeAnswersInArrayForm}
                onChangeAutocompleteFieldWithObjectOptions={
                  props.onChangeAnswersAutocompleteFieldWithObjectOptionsInArrayForm
                }
              />
            </div>
          </div>
        );
      }}
    </Draggable>
  );

  const getDragAndDropContext = () => (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId={"answers"}>
        {(droppableProvided) => (
          <div
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {props.awswersFormValue &&
              props.awswersFormValue.map((answer, idx) =>
                getDraggableQuestionAnswerForm(answer, idx)
              )}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <Grid
      container
      spacing={1}
      justifyContent={"flex-end"}
      alignItems={"center"}
      marginY={1}
      style={props.readOnly ? getFormStyle() : null}
    >
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {props.readOnly && props.onClickEdit && (
              <IconButton onClick={props.onClickEdit}>
                <Typography
                  variant="h5"
                  gutterBottom
                  component="div"
                  style={{ margin: 0, color: "var(--primary)" }}
                >
                  {`${props.index})`}
                </Typography>
              </IconButton>
            )}
          </div>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12} sm={8} container spacing={1}>
              <Grid item xs={12}>
                <TextFieldFormControl
                  name={"question_text"}
                  readOnly={props.readOnly}
                  label={t("form.question_form.question_text")}
                  value={props.formValue.question_text}
                  onChange={props.onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormControl
                  name={"question_text_eng"}
                  readOnly={props.readOnly}
                  label={t("form.question_form.question_text_eng")}
                  value={props.formValue.question_text_eng}
                  onChange={props.onChange}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} container spacing={1}>
              <Grid item xs={12}>
                <AutocompleteField
                  name="question_type"
                  label={t("form.question_form.question_type")}
                  value={props.formValue.question_type}
                  options={convertFlatListFromBackendToTranslatedOptionObject(
                    props.questionFilteringData?.question_type
                  )}
                  readOnly={true}
                  isObjectOption={true}
                  addNewValue={false}
                  onChange={props.onChangeAutocompleteFieldWithObjectOptions}
                />
              </Grid>
              <Grid item xs={12}>
                <TrueFalseSelectField
                  name={"question_active"}
                  value={props.formValue.question_active}
                  valuesAsBool
                  onChange={props.onChange}
                  label={t("form.question_form.question_active")}
                  addEmptyOptions={false}
                  readOnly={props.readOnly}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={props.readOnly ? 11.9 : 12}>
        {getDragAndDropContext()}
      </Grid>
    </Grid>
  );
};

QuestionAdminForm.propTypes = {
  formValue: PropTypes.object,
  awswersFormValue: PropTypes.object,
  questionFilteringData: PropTypes.object,
  questionAnswerFilteringData: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  onAnswersChangeInArrayForm: PropTypes.func,
  onChangeAnswersAutocompleteFieldWithObjectOptionsInArrayForm: PropTypes.func,
  onClickEdit: PropTypes.func,
  index: PropTypes.number,
  onChangeAnswerOrder: PropTypes.func,
};

QuestionAdminForm.defaultProps = {
  readOnly: false,
};

export default QuestionAdminForm;
