import { Grid, Paper } from "@mui/material";
import React, { useRef, useEffect } from "react";
import CardIconWithText from "../CardIconWithText";
import PropTypes from "prop-types";

export const TicketCardCategories = React.memo((props) => {
  const seletedTicketCardCategoryRef = useRef();

  const isSelectedCategory = (category) =>
    category.id === props.selectedCategoryId;

  useEffect(() => {
    if (seletedTicketCardCategoryRef.current) {
      seletedTicketCardCategoryRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [props.selectedCategoryId, seletedTicketCardCategoryRef.current]);

  return (
    <Paper elevation={0} sx={{ height: props.height, overflow: "scroll" }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
        padding={1}
      >
        {props.categories
          .filter((category) =>
            props.readOnly ? isSelectedCategory(category) : true
          )
          .map((category, _index) => (
            <Grid
              item
              xs={6}
              sm={2}
              ref={
                isSelectedCategory(category)
                  ? seletedTicketCardCategoryRef
                  : null
              }
            >
              <CardIconWithText
                label={category.tc_name}
                icon={category.tc_icon}
                isSelected={isSelectedCategory(category)}
                onClick={
                  props.readOnly ? () => {} : () => props.onCardClick(category)
                }
              />
            </Grid>
          ))}
      </Grid>
    </Paper>
  );
});

TicketCardCategories.propTypes = {
  categories: PropTypes.array,
  onCardClick: PropTypes.func,
  selectedCategoryId: PropTypes.string,
  readOnly: PropTypes.bool,
  height: PropTypes.string,
};

TicketCardCategories.defaultProps = {
  categories: [],
  height: "172px",
};

export default TicketCardCategories;
