import i18n from "../../../../../i18n";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

import { AUTOCOMPLETE_FIELD_TYPE } from "../../../../../helpers/constants";

export const OBJECT_TENANT_TABLE_NAME = "object_tenant_table";
export const OBJECT_TENANT_TABLE_FILTER_PREFIX = "locations__object_tenant";
export const OBJECT_TENANT_TABLE_CONFIG = [
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.object_tenant_table.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_1",
    getValueCallback: (rowData) => rowData.level_1,
    label: i18n.t("table.object_tenant_table.level_1"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    other_export_field_name: "level_1",
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_2",
    getValueCallback: (rowData) => rowData.level_2,
    label: i18n.t("table.object_tenant_table.level_2"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    other_export_field_name: "level_2",
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_3",
    getValueCallback: (rowData) => rowData.level_3,
    label: i18n.t("table.object_tenant_table.level_3"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    other_export_field_name: "level_3",
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
];
