import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useAsyncFn } from "../../../hooks/useAsync";
import useOrderService from "../../../services/orderService";
import useTicketService from "../../../services/ticketService";
import BasicDialog from "../../base/BasicDialog";

import { useTranslation } from "react-i18next";
import CreateOrderForm from "../../form/CreateOrderForm";
import PropTypes from "prop-types";

const CreateOrderDialog = (props) => {
  const { t } = useTranslation();
  const { createOrder } = useOrderService();

  const snackbarAlert = useSnackbarAlert();

  const createOrderFn = useAsyncFn(createOrder);
  const onSubmit = (newOrderData) => {
    createOrderFn
      .execute(newOrderData)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.order_created")
        );
        if (props.onSubmit) {
          props.onSubmit(res);
        }
        props.onClose(true);
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_creating_new_order")
        );
      });
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      titleAlign="center"
      title={t("dialog.create_order_dialog.new_order")}
    >
      <CreateOrderForm
        onSubmit={onSubmit}
        ticketId={props.ticketId}
        loadingSubmitButton={createOrderFn.loading}
      />
    </BasicDialog>
  );
};

CreateOrderDialog.propTypes = {
  open: PropTypes.bool,
  ticketId: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

CreateOrderDialog.defaultProps = {};

export default CreateOrderDialog;
