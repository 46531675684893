import { useTranslation } from "react-i18next";

import TabsNavigationBar from "../../TabsNavigationBar";

function DunReportsNavigationBuidlingSubBar(props) {
  const { t } = useTranslation();
  return (
    <TabsNavigationBar
      pages={[
        {
          label: t("headers.tickets_per_day"),
          link: "/tickets_per_location_day_report/",
        },
        {
          label: t("headers.tickets_open_close"),
          link: "/tickets_open_close_report/",
        },
      ]}
    />
  );
}

DunReportsNavigationBuidlingSubBar.propTypes = {};

export default DunReportsNavigationBuidlingSubBar;
