import { useState, useEffect, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import useDialog from "../../hooks/useDialog";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";
import TableService from "../../services/tableService";
import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import useBasicDrawer from "../../hooks/useBasicDrawer";
import OfferTable from "../../components/table/OfferTable/OfferTable";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import useOfferService from "../../services/offerService";
import DetailsDrawer from "../../components/drawer/UniversalDetailsDrawer/UniversalDetailsDrawer";
import OfferConfiguratorDialog from "../../components/dialog/OfferConfiguratorDialog";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import {
  OFFER_TABLE_FIELDS_CONFIG,
  OFFER_TABLE_NAME,
  TICKET_OFFER_TABLE_FIELDS_CONFIG,
  TICKET_OFFER_TABLE_NAME,
} from "../OffersManagmentPage/PageTablesConfig";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import TuneIcon from "@mui/icons-material/Tune";
import { centerVericalAlignIconStyle } from "../../helpers/styles";
import AcceptOfferButton from "../../components/button/AcceptOfferButton";
import useTicketService from "../../services/ticketService";
import useFilterSearchParams from "../../hooks/useFilterSearchParams";
import TicketTable from "../../components/table/TicketTable";
import OfferDecrementForm from "../../components/form/OfferDecrementForm/OfferDecrementForm";
import { NAVIGATION_DRAWER_TYPE_OFFERS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import { overflowButtonStyle } from "../../helpers/styles";

const TABLE_CONFIGS = [
  { name: TICKET_OFFER_TABLE_NAME, config: TICKET_OFFER_TABLE_FIELDS_CONFIG },
  { name: OFFER_TABLE_NAME, config: OFFER_TABLE_FIELDS_CONFIG },
];

const OFFER_TABLE_FILTER_PREFIX = "offertable";

const TICKET_TABLE_FILTER_PREFIX = "tickettable";

export default function OffersRRAcceptPage(props) {
  const { pageName } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [ticketDataLocaly, setTicketDataLocaly] = useState();
  const [countTicketRecords, setCountTicketRecords] = useState();

  const [offerTableTitle, setOfferTableTitle] = useState(undefined);
  const [offersDataLocaly, setOffersDataLocaly] = useState([]);
  const [countOfferRecords, setCountOfferRecords] = useState();

  const [openDetailsDrawer, setOpenDetailsDrawer] = useBasicDrawer(false);
  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();
  const [chosenOfferDetails, setChosenOfferDetails] = useState(undefined);

  const [selectedView, setSelectedView] = useState("offer-accept");

  const [ticketTableSearchParams, setTicketTableSearchParams] = useState({});
  const [offerTableSearchParams, setOfferTableSearchParams] = useState({});

  const [selectedOfferId, setSelectedOfferId] = useState();

  const openedTicketIdRef = useRef();
  const openedOfferIdRef = useRef();
  const selectedOfferIdToConfiguratorRef = useRef();

  const { setNewItemSearchParamsIfAreChanged } =
    useFilterSearchParams(searchParams);

  const [searchParamsFromPage, setSearchParamsFromPage] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(searchParams.entries());
  });

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      TICKET_TABLE_FILTER_PREFIX,
      ticketTableSearchParams,
      setTicketTableSearchParams
    );
    setNewItemSearchParamsIfAreChanged(
      OFFER_TABLE_FILTER_PREFIX,
      offerTableSearchParams,
      setOfferTableSearchParams
    );
  }, [searchParams]);

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const [
    openOfferConfiguratorDialog,
    onOpenOfferConfiguratorDialog,
    onCloseOfferConfiguratorDialog,
  ] = useDialog();

  const {
    page: ticketDataPage,
    pageSize: ticketDataPageSize,
    handleChangePageWithSearchParams: handleChangeTicketDataPage,
    handleChangePageSizeWithSearchParams: handleChangeTicketDataPageSize,
    resetTicketPageNumber,
  } = usePaginationWithSearchParams(TICKET_TABLE_FILTER_PREFIX);

  const {
    page: offerDataPage,
    pageSize: offerDataPageSize,
    handleChangePageWithSearchParams: handleChangeOfferDataPage,
    handleChangePageSizeWithSearchParams: handleChangeOfferDataPageSize,
    resetOfferPageNumber,
  } = usePaginationWithSearchParams(
    selectedView === "ticket-offers" ? OFFER_TABLE_FILTER_PREFIX : undefined
  );

  const {
    getAllOffersList,
    getOffersFilteringData,
    getOfferDrawerDetailsData,
    getOfferListToRRAccept,
    getOfferListByTicket,
  } = useOfferService();

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const { getTicketOfferData, getTicketOffersFilteringData } =
    useTicketService();

  const offersData = useAsync(
    () => getOffersDataBySelectedView(searchParams),
    [searchParams, selectedView]
  );

  const ticketData = useAsync(
    () => getTicketDataBySelectedView(),
    [ticketTableSearchParams, selectedView]
  );

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const getOffersDataBySelectedView = () => {
    switch (selectedView) {
      case "offer-accept":
        return getOfferListToRRAccept(searchParams);

      case "all-offers":
        return getAllOffersList(searchParams);
      case "ticket-offers":
        return Promise.resolve([]);
    }
  };

  const getTicketDataBySelectedView = () => {
    switch (selectedView) {
      case "offer-accept":
      case "all-offers":
        return Promise.resolve([]);
      case "ticket-offers":
        return getTicketOfferData(ticketTableSearchParams);
    }
  };

  const ticketFilteringData = useAsync(getTicketOffersFilteringData);

  const offersByTicketFn = useAsyncFn(getOfferListByTicket);

  const offerFilteringData = useAsync(getOffersFilteringData);

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const offersDetailsFn = useAsyncFn(getOfferDrawerDetailsData);

  useEffect(() => {
    if (ticketData.loading) {
      return;
    }
    setTicketDataLocaly(ticketData.value.results);
    setCountTicketRecords(ticketData.value.count);
  }, [ticketData.loading]);

  useEffect(() => {
    if (offersData.loading) {
      return;
    }
    setOffersDataLocaly(offersData.value.results);
    setCountOfferRecords(offersData.value.count);
  }, [offersData.loading]);

  useEffect(() => {
    if (userPreferencesForPage.loading) {
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [TICKET_OFFER_TABLE_NAME, OFFER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [TICKET_OFFER_TABLE_NAME, OFFER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);

  const handleChangeTicketRowsPerPage = (e) => {
    handleChangeTicketDataPageSize(e, parseInt(e.target.value, 10));
  };

  const handleChangeOfferRowsPerPage = (e) => {
    handleChangeOfferDataPageSize(e, parseInt(e.target.value, 10));
  };

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        onCloseTableColumnVisibilityDialog();

        if (selectedView === "ticket-offers") {
          setTicketDataLocaly(undefined);
          ticketData.refetch();
        } else {
          setOffersDataLocaly(undefined);
          offersData.refetch();
        }
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const handleClickTicketRow = (ticketId) => {
    let selectedTicket = ticketDataLocaly.find(
      (ticket) => ticket.id === ticketId
    );

    if (!ticketId || ticketId === openedTicketIdRef.current) {
      openedTicketIdRef.current = null;
      setOfferTableTitle(undefined);
      setOffersDataLocaly([]);
      setCountOfferRecords(0);
      setOpenDetailsDrawer(false);
    } else {
      openedOfferIdRef.current = null;
      setOpenDetailsDrawer(false);
      openedTicketIdRef.current = ticketId;
      setOfferTableTitle(
        `${t("page.offers_page.offers_for_ticket_nr")} ${
          selectedTicket.ticket_nr
        }`
      );
      offersByTicketFn
        .execute(ticketId, offerTableSearchParams)
        .then((data) => {
          setOffersDataLocaly(data.results);
          setCountOfferRecords(data.count);
        });
    }
  };

  const setDefaultView = () => {
    openedOfferIdRef.current = null;
    setOpenDetailsDrawer(false);
    setSelectedOfferId(undefined);
  };

  const handleClickOfferRow = (offerId) => {
    if (!offerId || offerId === openedOfferIdRef.current) {
      setDefaultView();
    } else {
      openedOfferIdRef.current = offerId;
      setSelectedOfferId(offerId);
      if (selectedView === "all-offers" || selectedView === "ticket-offers") {
        setOpenDetailsDrawer(true);
        offersDetailsFn.execute(openedOfferIdRef.current).then((data) => {
          setChosenOfferDetails(data);
        });
      }
    }
  };

  const onAcceptOffer = useCallback(() => {
    setDefaultView();
    offersData.refetch();
  }, []);

  const handleClickVisibilityState = (filterKey) => {
    switch (filterKey) {
      case "offer-accept":
        setSelectedView("offer-accept");
        openedOfferIdRef.current = null;
        setOpenDetailsDrawer(false);
        setOfferTableTitle(undefined);
        break;
      case "all-offers":
        openedOfferIdRef.current = null;
        setSelectedView("all-offers");
        setOpenDetailsDrawer(false);
        setOfferTableTitle(undefined);
        break;
      case "ticket-offers":
        openedOfferIdRef.current = null;
        openedTicketIdRef.current = null;
        setSelectedView("ticket-offers");
        setOpenDetailsDrawer(false);
        break;
    }
  };

  const handleOpenOffer = (offerId) => {
    selectedOfferIdToConfiguratorRef.current = offerId;
    onOpenOfferConfiguratorDialog();

    const newSearchParams = new URLSearchParams(window.location.search);
    setSearchParamsFromPage(Object.fromEntries(newSearchParams.entries()));
    setSearchParams({});
  };

  const handleToCloseConfiguratorOffer = (setParams = true) => {
    onCloseOfferConfiguratorDialog();
    selectedOfferIdToConfiguratorRef.current = null;
    openedOfferIdRef.current = null;
    setSelectedOfferId(undefined);
    setOpenDetailsDrawer(false);
    if (setParams) {
      setSearchParams({ ...searchParamsFromPage });
      setSearchParamsFromPage({});
    }
    offersData.refetch();
  };

  const filterChoiceButtonOptions = [
    {
      label: t("button.ticket_offer_button.offers_to_accept"),
      key: "offer-accept",
      callback: () => handleClickVisibilityState("offer-accept"),
    },
    {
      label: t("button.ticket_offer_button.all_offers"),
      key: "all-offers",
      callback: () => handleClickVisibilityState("all-offers"),
    },
    {
      label: t("button.ticket_offer_button.tickets_with_offers"),
      key: "ticket-offers",
      callback: () => handleClickVisibilityState("ticket-offers"),
    },
  ];

  const offerTableContextMenuActions = [
    {
      label: t("page.offers_join_page.open_offer"),
      callback: (offerId) => handleOpenOffer(offerId),
      icon: <ZoomInIcon fontSize="medium" />,
    },
  ];

  const isTicketTableLoading =
    userPreferencesForPage.loading ||
    hiddenColumnsForTables === undefined ||
    ticketFilteringData.loading ||
    ticketDataLocaly === undefined ||
    countTicketRecords === undefined;

  const isOfferTableLoading =
    offersData.loading ||
    userPreferencesForPage.loading ||
    hiddenColumnsForTables === undefined ||
    offerFilteringData.loading ||
    offersDataLocaly === undefined ||
    countOfferRecords === undefined;

  const getOfferTable = (prefix = undefined, style = {}) => {
    return (
      <OfferTable
        data={offersDataLocaly ? offersDataLocaly : []}
        showCheckbox={false}
        onClickRow={handleClickOfferRow}
        countRecords={countOfferRecords}
        page={offerDataPage}
        onPageChange={handleChangeOfferDataPage}
        rowsPerPage={offerDataPageSize}
        onRowsPerPageChange={handleChangeOfferRowsPerPage}
        resetPageNumber={resetOfferPageNumber}
        filteringData={offerFilteringData}
        hiddenColumns={
          hiddenColumnsForTables ? hiddenColumnsForTables[OFFER_TABLE_NAME] : []
        }
        columnsOrders={
          columnsOrdersForTables
            ? columnsOrdersForTables[OFFER_TABLE_NAME]
            : undefined
        }
        selectedOfferId={openedOfferIdRef.current}
        showTitle={offerTableTitle ? true : false}
        title={offerTableTitle}
        style={style}
        tableConfig={OFFER_TABLE_FIELDS_CONFIG}
        filterPrefix={prefix}
        showContextMenu={true}
        contextMenuActions={offerTableContextMenuActions}
      />
    );
  };

  const getTabbleConentBySelectedView = () => {
    switch (selectedView) {
      case "offer-accept":
        return (
          <LoaderWrapper showLoader={isOfferTableLoading}>
            {getOfferTable(undefined, { maxHeight: "40vh" })}
            {selectedOfferId ? (
              <OfferDecrementForm
                offerId={selectedOfferId}
                formMode={"acceptRR"}
                onAcceptOffer={onAcceptOffer}
              />
            ) : (
              <Typography
                textAlign="center"
                variant="overline"
                display="block"
                gutterBottom
                style={{ margin: 0, fontSize: "20px", color: "var(--primary)" }}
              >
                {t("page.offers_rr_accept_page.choose_offer_to_show_details")}
              </Typography>
            )}
          </LoaderWrapper>
        );
      case "all-offers":
        return (
          <LoaderWrapper showLoader={isOfferTableLoading}>
            {getOfferTable()}
          </LoaderWrapper>
        );
      case "ticket-offers":
        return (
          <LoaderWrapper showLoader={isTicketTableLoading}>
            <TicketTable
              data={ticketDataLocaly}
              onClickRow={handleClickTicketRow}
              countRecords={countTicketRecords}
              page={ticketDataPage}
              onPageChange={handleChangeTicketDataPage}
              rowsPerPage={ticketDataPageSize}
              onRowsPerPageChange={handleChangeTicketRowsPerPage}
              resetPageNumber={resetTicketPageNumber}
              filteringData={ticketFilteringData}
              hiddenColumns={
                hiddenColumnsForTables
                  ? hiddenColumnsForTables[TICKET_OFFER_TABLE_NAME]
                  : []
              }
              columnsOrders={
                columnsOrdersForTables
                  ? columnsOrdersForTables[TICKET_OFFER_TABLE_NAME]
                  : undefined
              }
              selectedTicketId={openedTicketIdRef.current}
              style={{ maxHeight: "40vh" }}
              tableConfig={TICKET_OFFER_TABLE_FIELDS_CONFIG}
              filterPrefix={TICKET_TABLE_FILTER_PREFIX}
              showCleanFilterIcon={true}
              showContextMenu={false}
            />
            {openedTicketIdRef.current ? (
              <LoaderWrapper showLoader={isOfferTableLoading}>
                {getOfferTable(OFFER_TABLE_FILTER_PREFIX, {
                  maxHeight: "40vh",
                })}
              </LoaderWrapper>
            ) : (
              <Typography
                textAlign="center"
                variant="overline"
                display="block"
                gutterBottom
                style={{ margin: 0, fontSize: "20px", color: "var(--primary)" }}
              >
                {t("page.offers_page.choose_ticket_to_show_offers")}
              </Typography>
            )}
          </LoaderWrapper>
        );
    }
  };

  return (
    <DetailsDrawer
      openDrawer={openDetailsDrawer}
      setOpenDrawer={setOpenDetailsDrawer}
      itemData={chosenOfferDetails}
      itemType={"offer"}
      isLoading={offersDetailsFn.loading}
      readOnly={true}
    >
      <NavigationDrawer
        drawerType={NAVIGATION_DRAWER_TYPE_OFFERS}
        pageName={pageName}
      >
        <DefaultPageWrapper titleKey={"accept_offers"}>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={1}
          >
            <Grid item xs={2} style={{ marginBlock: "5px" }}>
              <Button
                fullWidth
                variant="contained"
                size="small"
                startIcon={<TuneIcon sx={centerVericalAlignIconStyle} />}
                onClick={onOpenTableColumnVisibilityDialog}
                sx={overflowButtonStyle}
              >
                {t("bar.offer_tool_bar.adjust_table")}
              </Button>
            </Grid>
            <Grid item xs={8} />
            <Grid item xs={2} style={{ marginBlock: "5px" }}>
              <AcceptOfferButton
                options={filterChoiceButtonOptions}
                selectedView={selectedView}
              />
            </Grid>
            <Grid item xs={12}>
              {getTabbleConentBySelectedView()}
            </Grid>
          </Grid>

          {openTableColumnVisibilityDialog && (
            <TableColumnVisibilityDialog
              open={openTableColumnVisibilityDialog}
              onClose={onCloseTableColumnVisibilityDialog}
              onSubmit={handleUpdateUserPreferences}
              tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
                selectedView === "ticket-offers"
                  ? TABLE_CONFIGS
                  : [
                      {
                        name: OFFER_TABLE_NAME,
                        config: OFFER_TABLE_FIELDS_CONFIG,
                      },
                    ],
                columnsOrdersForTables
              )}
              tablesHiddenColumns={hiddenColumnsForTables}
              isLoading={userPreferencesForPage.loading}
            />
          )}

          {openOfferConfiguratorDialog && (
            <OfferConfiguratorDialog
              open={openOfferConfiguratorDialog}
              offerId={selectedOfferIdToConfiguratorRef.current}
              dialogMode={
                selectedView === "offer-accept" ? "acceptRR" : "preview"
              }
              filteringData={offerFilteringData.value}
              onClose={() => handleToCloseConfiguratorOffer()}
            />
          )}
        </DefaultPageWrapper>
      </NavigationDrawer>
    </DetailsDrawer>
  );
}
