import React, { useState , forwardRef, useImperativeHandle } from 'react';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import useWarehouseService from "../../../../services/warehouseService";
import { useAsync } from "../../../../hooks/useAsync";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import TransactionTable from "../../../table/TransactionTable";
import { TRANSACTION_MOVE_TABLE_CONFIG } from "./TableConfig";
import TransactionDialog from "../../../dialog/TransactionDialog";
import { TRANSACTION_KIND_MOVE } from "../../../../helpers/constants";
import TransactionMoveForm from '../../../form/TransactionMoveForm';
import { transactionMoveExportDataUrl } from '../../../../helpers/apiUrls';



const WarehouseTransactionMoveTab = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [hasPermissionToEdit, setHasPermissionToEdit] = useState(true);

  const {
    page: transactionMovePage,
    pageSize: transactionMovePageSize,
    handleChangePageWithSearchParams: handleChangeTransactionMoveDataPage,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const { getTransactionMoveData } = useWarehouseService()


  const transactionMoveList = useAsync(
    () => {
      return getTransactionMoveData(searchParams)
    },
    [searchParams]
  )

  useImperativeHandle(ref, () => ({
    onRefetchData() {
      transactionMoveList.refetch()
    },
  }));
 
  return (
    <Grid container spacing={1}>
      {hasPermissionToEdit &&
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
            onClick={props.onOpenTransactionDialog}
          >
            {t("tabs.warehouse_transaction_tabs.new_transaction_move")}
          </Button>
        </Grid>}
      <Grid item xs={12}>
        <TransactionTable
          transactionFilteringData={props.transactionFilteringData}
          tableConfig={TRANSACTION_MOVE_TABLE_CONFIG}
          transactionData={transactionMoveList}
          page={transactionMovePage}
          pageSize={transactionMovePageSize}
          handleChangePageWithSearchParams={handleChangeTransactionMoveDataPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          exportToFileUrl={transactionMoveExportDataUrl}
          exportToFileSearchParams={searchParams}
        />
      </Grid>
      {
        props.openTransactionDialog &&
        <TransactionDialog
        open={props.openTransactionDialog}
        title={t("tabs.warehouse_transaction_tabs.new_transaction_move")}
        onClose={props.onCloseTransactionDialog}
        transactionKind= {TRANSACTION_KIND_MOVE}
        extraRequiredFields = {["warehouse_receiving"]}
        onSubmit = {props.onSubmit}
        isDataSaving= {props.isSaving}
          transactionForm = {
            <TransactionMoveForm />}
            />
      }
    </Grid>
  );
});

WarehouseTransactionMoveTab.propTypes = {
  readOnly: PropTypes.bool,
  transactionType : PropTypes.string
};

WarehouseTransactionMoveTab.defaultProps = {};

export default WarehouseTransactionMoveTab;
