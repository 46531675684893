import i18n from "../../../i18n";
import DateService from "../../../services/dateService";
import SelectFieldService from "../../../services/selectFieldService";

export const OFFER_LOGS_TABLE_FIELDS_CONFIG = [
  {
    name: "created",
    getValueCallback: (rowData) => DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.offer_logs_table.changes_date"),
  },
  {
    name: "offdatlog_field",
    getValueCallback: (rowData) =>  i18n.t(`table.offer_logs_table.${rowData.offdatlog_field}`),
    label: i18n.t("table.offer_logs_table.offdatlog_field"),
  },
  {
    name: "offdatlog_oldvalue",
    getValueCallback: (rowData) => rowData.offdatlog_oldvalue,
    label: i18n.t("table.offer_logs_table.offdatlog_oldvalue"),
  },
  {
    name: "offdatlog_value",
    getValueCallback: (rowData) => rowData.offdatlog_value,
    label: i18n.t("table.offer_logs_table.offdatlog_value"),
  },
  {
    name: "offer_cr_is_rejected",
    getValueCallback: (rowData) => SelectFieldService.getLabelOptionsTrueOrFalse(rowData.offer_cr_is_rejected),
    label: i18n.t("table.offer_logs_table.offer_cr_is_rejected"),
  },
  {
    name: "user",
    getValueCallback: (rowData) => rowData.user,
    label: i18n.t("table.offer_logs_table.user"),
  },
];

export const SETTLEMENT_LOGS_TABLE_FIELDS_CONFIG =[
  {
    name: "tenant_full_name",
    getValueCallback: (rowData) => rowData.tenant_full_name,
    label: i18n.t("table.settlements_table.payer_full_name"),
  },
  {
    name: "address",
    getValueCallback: (rowData) => rowData.address,
    label: i18n.t("table.settlements_table.payer_address"),
  },
  {
    name: "tenant_nip",
    getValueCallback: (rowData) => rowData.tenant_nip,
    label: i18n.t("table.settlements_table.nip"),
  },
  {
    name: "settlement_po",
    getValueCallback: (rowData) => rowData.settlement_po_old,
    label: i18n.t("table.settlements_table.po"),
  },
  {
    name: "settlement_payment_rate",
    getValueCallback: (rowData) => rowData.settlement_payment_rate_old,
    label: i18n.t("table.settlements_table.payment_rate"),
  },
  {
    name: "settlement_netto",
    getValueCallback: (rowData) => rowData.settlement_netto_old,
    label: i18n.t("table.settlements_table.quotation_net"),
  },
  {
    name: "settlement_vat",
    getValueCallback: (rowData) => rowData.settlement_vat_old,
    label: i18n.t("table.settlements_table.quotation_vat"),
  },
  {
    name: "settlement_brutto",
    getValueCallback: (rowData) => rowData.settlement_brutto_old,
    label: i18n.t("table.settlements_table.quotation_brutto"),
  },
  {
    name: "offer_cr_is_rejected",
    getValueCallback: (rowData) => SelectFieldService.getLabelOptionsTrueOrFalse(rowData.offer_cr_is_rejected),
    label: i18n.t("table.offer_logs_table.offer_cr_is_rejected"),
  },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.created),
    label: i18n.t("table.settlements_table.created"),
  },
  {
    name: "user",
    getValueCallback: (rowData) => rowData.user,
    label: i18n.t("table.offer_logs_table.user"),
  },
];