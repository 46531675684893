import React from "react";
import { useTranslation } from "react-i18next"
import { Grid } from "@mui/material"
import TextFieldFormControl from "../../field/TextFieldFormControl";

export default function PurchasingTreeBranchForm(props) {

    const { t } = useTranslation();
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
        >
            {props.parentBranchId &&
                <>
                    <Grid item xs={4}>
                        <TextFieldFormControl
                            label={t("form.purchasing_tree_branch_form.item_parent_number")}
                            value={props.parentFormValue?.item_number}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextFieldFormControl
                            label={t("form.purchasing_tree_branch_form.item_parent_name")}
                            value={props.parentFormValue?.item_name}
                            disabled
                        />
                    </Grid>
                </>}
            <Grid item xs={4}>
                <TextFieldFormControl
                    name={"item_number"}
                    label={t("form.purchasing_tree_branch_form.item_number")}
                    value={props.itemFormValue?.item_number}
                    onChange={props.onChange}
                    disabled = {props.parentBranchId !== undefined}
                />
            </Grid>
            <Grid item xs={8}>
                <TextFieldFormControl
                    name={"item_name"}
                    label={t("form.purchasing_tree_branch_form.item_name")}
                    value={props.itemFormValue?.item_name}
                    onChange={props.onChange}
                />
            </Grid>
        </Grid>
    )
}

PurchasingTreeBranchForm.propTypes = {

}

PurchasingTreeBranchForm.defaultProps = {
}
