import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import { useAsync } from "../../../../hooks/useAsync";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import useDialog from "../../../../hooks/useDialog";
import { POST_SUBCATEGORY_TABLE_CONFIG } from "./TableConfig";
import TableService from "../../../../services/tableService";
import FilterTable from "../../../table/FilterTable";
import { useEmptyArrayMemo } from "../../../../hooks/useEmptyArrayMemo";
import { useSearchParams } from "react-router-dom";
import useFilterSearchParams from "../../../../hooks/useFilterSearchParams";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import SearchButton from "../../../button/SearchButton/SearchButton";
import FilterDialog from "../../../base/FilterDialog";
import UniversalFilterForm from "../../../form/UniversalFilterForm";
import useDialogWithId from "../../../../hooks/useDialogWithId";

import useCorrespondenceAdminService from "../../../../services/correspondenceAdminService";
import CreatePostSubcategoryDialog from "../../../dialog/CreatePostSubcategoryDialog/CreatePostSubcategoryDialog";

const POST_SUBCATEGORY_FILTER_PREFIX = "post_subcategory";

const CorrespondenceAdminPostSubcategoryTab = (props) => {
  const { t } = useTranslation();
  const [postSubcategoriesLocal, setPostSubcategoriesLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [postSubcategoryTableSearchParams, setPostSubcategoryTableSearchParams] = useState({});

  const {
    setNewItemSearchParamsIfAreChanged,
    clearSearchParamsByFilterPrefix
  } = useFilterSearchParams(searchParams);

  const { emptyArrayMemo } = useEmptyArrayMemo();
  const { getPostSubcategoryAllList } = useCorrespondenceAdminService();

  const onCleanFlitersInFilterDialog = () => {
    setSearchParams(clearSearchParamsByFilterPrefix(POST_SUBCATEGORY_FILTER_PREFIX));
  };

  const [
    openPostSubcategoryDialog,
    postSubcategoryChangeId,
    onOpenPostSubcategoryDialog,
    onClosePostSubcategoryDialog,
  ] = useDialogWithId();

  const [
    openFilterDialog,
    onOpenFilterDialog,
    onCloseFilterDialog
  ] = useDialog();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
  } = usePaginationWithSearchParams(POST_SUBCATEGORY_FILTER_PREFIX);

  const postSubcategoryData = useAsync(
    () => getPostSubcategoryAllList(postSubcategoryTableSearchParams),
    [postSubcategoryTableSearchParams]
  );

  useEffect(() => {
    if (postSubcategoryData.loading) {
      return;
    }
    setPostSubcategoriesLocal(postSubcategoryData.value.results);
    setCountRecords(postSubcategoryData.value.count);
  }, [postSubcategoryData.loading]);

  const isLoading = postSubcategoriesLocal === undefined || props.filteringData.loading;

  const handleOpenPostSubcategoryDialog = useCallback(
    (e, postSubcategoryId) => {
      e.stopPropagation();
      onOpenPostSubcategoryDialog(postSubcategoryId);
    },
    []
  );

  const refreshTable = () => {
    setPostSubcategoriesLocal([]);
    postSubcategoryData.refetch();
  };

  const data = useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        POST_SUBCATEGORY_TABLE_CONFIG,
        postSubcategoriesLocal ? postSubcategoriesLocal : emptyArrayMemo,
        emptyArrayMemo,
        emptyArrayMemo,
        handleOpenPostSubcategoryDialog,
      ),
    [postSubcategoriesLocal, POST_SUBCATEGORY_TABLE_CONFIG]
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      POST_SUBCATEGORY_FILTER_PREFIX,
      postSubcategoryTableSearchParams,
      setPostSubcategoryTableSearchParams
    );
  }, [searchParams]);



  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={1}
    >
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
          onClick={(e) => onOpenPostSubcategoryDialog(undefined)}
        >
          {t("tabs.correspondence_admin.add_post_subcategory")}
        </Button>
      </Grid>
      <Grid item sm={9} />
      <Grid item xs={12} sm={1}>
        <SearchButton onClickSearch={onOpenFilterDialog} />
      </Grid>
      <Grid item xs={12}>
        <FilterTable
          data={data}
          showCheckbox={false}
          showCleanFilterIcon={true}
          countRecords={countRecords}
          page={page}
          onPageChange={handleChangePageWithSearchParams}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          filteringData={props.filteringData}
          style={{ maxHeight: "vh" }}
          tableConfig={POST_SUBCATEGORY_TABLE_CONFIG}
          showContextMenu={false}
          filterPrefix={POST_SUBCATEGORY_FILTER_PREFIX}
          headersConfig={TableService.getHeadersConfigForCollapsibleTable(
            POST_SUBCATEGORY_TABLE_CONFIG,
            props.filteringData || emptyArrayMemo,
            emptyArrayMemo,
            emptyArrayMemo,
          )}
        />
      </Grid>
      {openFilterDialog &&
        <FilterDialog
          open={openFilterDialog}
          onCleanFilters={onCleanFlitersInFilterDialog}
          onClose={onCloseFilterDialog}
          resetPageNumber={resetPageNumber}
          filterPrefix={POST_SUBCATEGORY_FILTER_PREFIX}
          filterForm={
            <UniversalFilterForm
              filteringData={props.filteringData || emptyArrayMemo}
              filtersConfig={POST_SUBCATEGORY_TABLE_CONFIG}
              includeOpenCloseFilter={false}
            />
          }
        />
      }
      {openPostSubcategoryDialog &&
        <CreatePostSubcategoryDialog
          postSubcategoryId={postSubcategoryChangeId}
          open={openPostSubcategoryDialog}
          onClose={onClosePostSubcategoryDialog}
          onRefetchData={refreshTable}
          filteringData={props.filteringData}
        />
      }
    </Grid>
  );
};

CorrespondenceAdminPostSubcategoryTab.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

CorrespondenceAdminPostSubcategoryTab.defaultProps = {};

export default CorrespondenceAdminPostSubcategoryTab;
