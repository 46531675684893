import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid} from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import SelectField from "../../field/SelectField";
import { UNIQUE_ERROR_TEXT } from "../../../helpers/constants";

const AddressDetailsForm= (props) => {
  const { t } = useTranslation();
  
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={1}
    >
      <Grid item xs={12}>
        <TextFieldFormControl
          name="address_zip"
          required
          value={props.addressData?.address_zip}
          label={t("form.address_details_form.address_zip")}
          onChange={props.onChange}
          helperText = {props.isZipCodeValid? "" :t("form.address_details_form.uncorrect_zip_code")}
          helperTextStyle = {UNIQUE_ERROR_TEXT.helperTextStyle}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="address_city"
          value={props.addressData?.address_city}
          label={t("form.address_details_form.address_city")}
          onChange={props.onChange}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="address_street"
          value={props.addressData?.address_street}
          label={t("form.address_details_form.address_street")}
          onChange={props.onChange}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="address_building"
          value={props.addressData?.address_building}
          label={t("form.address_details_form.address_building")}
          onChange={props.onChange}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="address_apartment"
          value={props.addressData?.address_apartment}
          label={t("form.address_details_form.address_apartment")}
          onChange={props.onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          label={t("form.address_details_form.address_kind")}
          name="address_kind"
          required
          value={props.addressData?.address_kind}
          options={props.addressKinds}
          isObjectOption={false}
          onChange={props.onChange}
        />
      </Grid>
    </Grid>
  );
};

AddressDetailsForm.propTypes = {
  addressData: PropTypes.object,
  addressKinds: PropTypes.array,
  onChange : PropTypes.func
};

AddressDetailsForm.defaultProps = {
  addressKinds: [],
};

export default AddressDetailsForm;
