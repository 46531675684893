import { coordinateToTimeRatio } from 'react-calendar-timeline/lib/lib/utility/calendar'
import {
    getSumOffset,
    getSumScroll,
} from 'react-calendar-timeline/lib/lib/utility/dom-helpers'
import { isEmptyValue } from '../helpers/methods'
import { useCallback } from 'react'

export default function useTimelineService() {
    const handleItemDrop = (e, timelineRef, scrollRef, data, itemType="order") => {
        scrollRef = scrollRef.current
        const {
            canvasTimeStart,
            visibleTimeStart,
            visibleTimeEnd,
            groupTops,
            width,
            groups,
            height,
        } = timelineRef.current.state

        const canvasWidth = width * 3
        const zoom = visibleTimeEnd - visibleTimeStart
        const canvasTimeEnd = zoom * 3 + canvasTimeStart
        const ratio = coordinateToTimeRatio(
            canvasTimeStart,
            canvasTimeEnd,
            canvasWidth,
        )

        const { offsetLeft, offsetTop } = getSumOffset(scrollRef)
        const { scrollLeft, scrollTop } = getSumScroll(scrollRef)
        const { pageX, pageY } = e

        const x = pageX - offsetLeft + scrollLeft
        const y = pageY - offsetTop + scrollTop

        const start = x * ratio + canvasTimeStart

        let groupIndex = ''
        for (const key of Object.keys(groupTops)) {
            const groupTop = groupTops[key]
            if (y > groupTop && height >= y) {
                groupIndex = key
            } else {
                break
            }
        }

        if (groupIndex === '' || pageX < offsetLeft || pageX > offsetLeft + width) {
            return
        }
        return {
            data,
            start,
            group: groups[groupIndex]
        }
    }

    const calculatePixelPerMinute = useCallback((visibleTimeStart, visibleTimeEnd, timelineWidth) => {
        if(!isEmptyValue(visibleTimeStart) &&  !isEmptyValue(visibleTimeEnd) && !isEmptyValue(timelineWidth)){
            const minutes = (visibleTimeEnd - visibleTimeStart) / (1000 * 60) //time in minute
            return timelineWidth / minutes
        }
        return undefined

    }, [])

    return {
        handleItemDrop,
        calculatePixelPerMinute
    }
}