import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SelectField from "../SelectField";
import { getTimeInHHMMFormatFromMinutes } from "../../../helpers/methods";
import DateService from "../../../services/dateService";

function TimeDurationSelectField({
  name,
  label,
  value,
  onChange,
  variant,
  minMinuteValue,
  maxMinuteValue,
  minsStep,
  idIncludeTimeOffset,
  hoursAsId,
  withClearIcon,
  readOnly,
  ...rest
}) {
  let timeOptions = useMemo(() => {
    let timeTempOptions = [];

    const getFormattedId = (mins) => {
      let formattedId = mins;
      if (idIncludeTimeOffset)
        formattedId = DateService.getMinutesIncludeTimeZoneOffset(formattedId);
      if (hoursAsId) formattedId = DateService.getHoursFromMinutes(formattedId);
      return formattedId;
    };

    for (let mins = minMinuteValue; mins <= maxMinuteValue; mins += minsStep) {
      timeTempOptions.push({
        name: getTimeInHHMMFormatFromMinutes(mins),
        id: getFormattedId(mins),
      });
    }
    return timeTempOptions;
  }, [minMinuteValue, maxMinuteValue]);

  const handleClear = (e) => {
    e.target.value = "";
    e.target.name = name;
    onChange(e);
  };

  return (
    <SelectField
      name={name}
      label={label}
      value={value}
      options={timeOptions}
      isObjectOption={true}
      variant={variant}
      onChange={onChange}
      onClear={withClearIcon ? handleClear : null}
      readOnly={readOnly}
      {...rest}
    />
  );
}

TimeDurationSelectField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  variant: "outlined",
  minMinuteValue: PropTypes.number,
  maxMinuteValue: PropTypes.number,
  hoursAsId: PropTypes.bool,
  idIncludeTimeOffset: PropTypes.bool,
  readOnly: PropTypes.bool,
  minsStep: PropTypes.number,
  withClearIcon: PropTypes.bool,
};

TimeDurationSelectField.defaultProps = {
  minMinuteValue: 0,
  maxMinuteValue: 24 * 60,
  hoursAsId: false,
  minsStep: 15,
  idIncludeTimeOffset: false,
  withClearIcon: false,
};

export default TimeDurationSelectField;
