import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../ConfirmationDialog/confirmationDialog";



const CancelOfferDialog = (props) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      open={props.open}
      onYes={props.onYes}
      onNo={props.onNo}
      title={t("dialog.cancel_offer_dialog.cancel_offer")}
      content={t("dialog.cancel_offer_dialog.are_you_sure_to_cancel_offer")}
      maxWidth={"xs"}
      titleSx={{ color: "var(--alert)" }}
      contentSx={{ fontFamily: "Roboto",textAlign: "center" }}
      btnYesColor={"error"}
      btnNoColor={"secondary"}
      showDialogContentDividers={false}
    />
  );
};

CancelOfferDialog.propTypes = {
  open: PropTypes.bool,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
};

CancelOfferDialog.defaultProps = {
  open: false,
};

export default CancelOfferDialog;
