import { Grid } from "@mui/material";
import { useAuth } from "../../../context/auth";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import useOrderService from "../../../services/orderService";
import OrderPlannerTable from "../../table/OrderPlannerTable";
import PropTypes from "prop-types";
import hasUserPermission, { IS_ADMIN } from "../../../helpers/userPermissions";
import UniversalToolBar from "../../bar/UniversalToolBar";
import useDialog from "../../../hooks/useDialog";
import TableColumnVisibilityDialog from "../../dialog/TableColumnVisibilityDialog";
import TableService from "../../../services/tableService";
import { useEffect, useMemo, useRef, useState } from "react";
import { ORDER_TABLE_NAME } from "../../table/OrderTable/OrderTable";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useTranslation } from "react-i18next";
import useUserPreferencesService from "../../../services/userPreferencesService";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import FilterDialog from "../../base/FilterDialog";
import { ORDER_TABLE_FIELDS_CONFIG } from "../../../page/PlannerPage/PageTablesConfig";
import UniversalFilterForm from "../../form/UniversalFilterForm";
import NextPlanIcon from "@mui/icons-material/NextPlan";
import PlannedUnplannedFilterButton from "../../button/PlannedUnplannedFilterButton";
import UserFilterDialog from "../../dialog/UserFilterDialog";
import React from "react";
import { useImperativeHandle } from "react";
import { useCallback } from "react";
import AllocateTaskDialog from "../../dialog/AllocateTaskDialog";
import { STATUS_KIND } from "../../../helpers/constants";
import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../hooks/useFilterSearchParams";
import MultipleLocalizationDialog from "../../dialog/MutlipleLocalizationDialog/MultipleLocalizationDialog";
import { isObjectEmpty } from "../../../helpers/methods";

const ORDER_TABLE_FILTER_PREFIX = "ordertable";

const PlannerOrders = React.forwardRef((props, ref) => {
  const { user } = useAuth();
  const { pageName } = props;

  useImperativeHandle(ref, () => ({
    getOrders,
    refetchOrders: ordersData.refetch,
  }));

  const [taskAllocatedStatus] = useState({
    status_name: "Alokowany",
    id: "1cb8629b-5119-4620-a437-86c88a0244cb",
    status_kind: STATUS_KIND.ALLOCATED,
  });

  const snackbarAlert = useSnackbarAlert();

  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams(ORDER_TABLE_FILTER_PREFIX);
  const { setNewItemSearchParamsIfAreChanged, getSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);
  const [orderTableSearchParams, setOrderTableSearchParams] = useState(() =>
    getSearchParamsByFilterPrefix(ORDER_TABLE_FILTER_PREFIX)
  );

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const [
    openUserFiltersDialog,
    onOpenUserFiltersDialog,
    onCloseUserFiltersDialog,
  ] = useDialog();

  const {
    getUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    updateUserPreferencesForPage,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const [allocateTaskDialogData, setAllocateTaskDialogData] = useState({});

  const { getOrderPlanerData, getOrderFilteringData } = useOrderService();

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  useEffect(() => {
    if (userPreferencesForPage.loading) {
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [ORDER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [ORDER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      ORDER_TABLE_FILTER_PREFIX,
      orderTableSearchParams,
      setOrderTableSearchParams
    );
  }, [searchParams]);

  const ordersData = useAsync(() => {
    var orderTableSearchParamsTemp = { ...orderTableSearchParams };
    orderTableSearchParamsTemp.is_open = true;
    if (!hasUserPermission([IS_ADMIN], user)) {
      orderTableSearchParamsTemp.leader_order_kt = user.user_id;
    }
    return getOrderPlanerData(orderTableSearchParamsTemp);
  }, [orderTableSearchParams]);

  const [orderDataLocaly, setOrderDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (ordersData.loading) {
      return;
    }
    setOrderDataLocaly(ordersData.value.results);
    setCountRecords(ordersData.value.count);
  }, [ordersData.loading]);

  useEffect(() => {
    if (props.selectedOrderId) {
      setOrderDataLocaly((prevData) => {
        return prevData.map((rowData) => {
          if (rowData.id === props.selectedOrderId)
            return {
              ...rowData,
              has_changes: false,
            };
          return rowData;
        });
      });
    }
  }, [props.selectedOrderId]);

  const getOrders = useCallback(() => {
    return orderDataLocaly;
  }, [orderDataLocaly]);

  const orderFilteringData = useAsync(getOrderFilteringData);

  const tableConfig = useMemo(
    () => [{ name: ORDER_TABLE_NAME, config: props.tableFieldConfigs }],
    [props.tableFieldConfigs]
  );

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const fullRefreshTable = () => {
    setOrderDataLocaly(undefined);
    ordersData.refetch();
  };

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        fullRefreshTable();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const onOrderDrop = (e, _id) => {
    props.onOrderDrop(
      e,
      orderDataLocaly.find((x) => x.id === _id)
    );
  };

  const extraButtonList = useMemo(() => {
    return [
      <PlannedUnplannedFilterButton filterPrefix={ORDER_TABLE_FILTER_PREFIX} />,
    ];
  }, []);

  const onOpenAllocateTaskDialog = useCallback((extraDialogData) => {
    setAllocateTaskDialogData({ open: true, ...extraDialogData });
  }, []);

  const onCloseAllocateTaskDialog = useCallback(() => {
    setAllocateTaskDialogData({});
  }, []);

  const onAllocateTask = useCallback(
    (orderId) => {
      const order = getOrders().find((x) => x.id === orderId);
      onOpenAllocateTaskDialog({ order: order });
    },
    [onOpenAllocateTaskDialog, getOrders]
  );

  const contextMenuActions = useMemo(
    () => [
      {
        label: t("page.planner_page.allocate"),
        callback: onAllocateTask,
        icon: <NextPlanIcon fontSize="small" />,
        visible: true,
      },
    ],
    [onAllocateTask, t]
  );

  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();

  const clickedOrderMultipleLocalization = useRef();
  const handleOpenMultipleLocalizationDialog = useCallback(
    (e, orderId) => {
      e.stopPropagation();
      clickedOrderMultipleLocalization.current = getOrders().find(
        (order) => order.id === orderId
      ).locations;
      onOpenMultipleLocalizationDialog();
    },
    [onOpenMultipleLocalizationDialog, getOrders]
  );

  const handleCloseMultipleLocalizationDialog = () => {
    clickedOrderMultipleLocalization.current = null;
    onCloseMultipleLocalizationDialog();
  };

  const onSubmitAllocateTaskDialog = useCallback(
    (order, taskData) => {
      props.onAllocateTask(order, { status: taskAllocatedStatus, ...taskData });
      onCloseAllocateTaskDialog();
    },
    [props.onAllocateTask, onCloseAllocateTaskDialog]
  );

  const getUniversalFilterForm = () => (
    <UniversalFilterForm
      filteringData={orderFilteringData}
      filtersConfig={props.tableFieldConfigs}
      includeOpenCloseFilter={true}
      openCloseFilterName={t(
        "button.planned_unplanned_filter_button.planned/unplanned"
      )}
      openCloseFilterKey={"is_planned"}
      trueOptionLabel={t("button.planned_unplanned_filter_button.planned")}
      falseOptionLabel={t("button.planned_unplanned_filter_button.unplanned")}
      includeHasChangesDotFilter={true}
    />
  );

  return (
    <LoaderWrapper
      showLoader={orderFilteringData.loading || orderDataLocaly == undefined}
    >
      <Grid container>
        <Grid item xs={12}>
          <UniversalToolBar
            style={{ marginBlock: "5px" }}
            itemType={"order"}
            showMassActionButton={false}
            showCreateButton={false}
            showOpenCloseFilterButton={false}
            showCleanfiltersButton={false}
            onClickAdjustTable={onOpenTableColumnVisibilityDialog}
            onClickMyFilters={onOpenUserFiltersDialog}
            mdGridButton={12 / 3 - 0.5}
            onClickSearch={onOpenFilterDialog}
            extraButtonList={extraButtonList}
            filterPrefix={ORDER_TABLE_FILTER_PREFIX}
          />
        </Grid>
        <Grid item xs={12}>
          <OrderPlannerTable
            orders={orderDataLocaly}
            showChangesCircle={true}
            tableConfig={props.tableFieldConfigs}
            draggableRows={props.draggableRows}
            onDropRow={onOrderDrop}
            onDragStartRow={props.onDragStartRow}
            hiddenColumns={
              hiddenColumnsForTables
                ? hiddenColumnsForTables[ORDER_TABLE_NAME]
                : []
            }
            columnsOrders={
              columnsOrdersForTables
                ? columnsOrdersForTables[ORDER_TABLE_NAME]
                : undefined
            }
            filteringData={orderFilteringData}
            filterPrefix={ORDER_TABLE_FILTER_PREFIX}
            onClickRow={props.onClickTableRow}
            showContextMenu={props.showContextMenuTable}
            contextMenuActions={contextMenuActions}
            selectedItemId={props.selectedOrderId}
            showCleanFilterIcon={true}
            countRecords={countRecords}
            page={page}
            onPageChange={handleChangePageWithSearchParams}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            resetPageNumber={resetPageNumber}
            onClickMultipleLocationAlert={handleOpenMultipleLocalizationDialog}
          />
        </Grid>
        {openTableColumnVisibilityDialog && (
          <TableColumnVisibilityDialog
            open={openTableColumnVisibilityDialog}
            onClose={onCloseTableColumnVisibilityDialog}
            onSubmit={handleUpdateUserPreferences}
            tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
              tableConfig,
              columnsOrdersForTables
            )}
            tablesHiddenColumns={hiddenColumnsForTables}
            isLoading={userPreferencesForPage.loading}
          />
        )}
        <FilterDialog
          open={openFilterDialog}
          resetPageNumber={resetPageNumber}
          onClose={onCloseFilterDialog}
          filterForm={getUniversalFilterForm()}
          filterPrefix={ORDER_TABLE_FILTER_PREFIX}
        />

        {openUserFiltersDialog && (
          <UserFilterDialog
            open={openUserFiltersDialog}
            onClose={onCloseUserFiltersDialog}
            pageName={pageName}
            filterForm={getUniversalFilterForm()}
            filterPrefix={ORDER_TABLE_FILTER_PREFIX}
          />
        )}
        {allocateTaskDialogData.open && (
          <AllocateTaskDialog
            {...allocateTaskDialogData}
            onClose={onCloseAllocateTaskDialog}
            onSubmit={onSubmitAllocateTaskDialog}
          />
        )}
        {openMultipleLocalizationDialog &&
          clickedOrderMultipleLocalization.current && (
            <MultipleLocalizationDialog
              open={openMultipleLocalizationDialog}
              onClose={handleCloseMultipleLocalizationDialog}
              localizationData={clickedOrderMultipleLocalization.current}
              itemType={"order"}
            />
          )}
      </Grid>
    </LoaderWrapper>
  );
});

PlannerOrders.propTypes = {
  tableFieldConfigs: PropTypes.object,
  onOrderDrop: PropTypes.func,
  onDragStartRow: PropTypes.func,
  pageName: PropTypes.string,
  style: PropTypes.object,
  filterPrefix: PropTypes.string,
  onClickTableRow: PropTypes.func,
  showContextMenuTable: PropTypes.bool,
  contextMenuTableActions: PropTypes.array,
  onAllocateTask: PropTypes.func,
  selectedOrderId: PropTypes.string,
};

PlannerOrders.defaultProps = {
  tableFieldConfigs: ORDER_TABLE_FIELDS_CONFIG,
  pageName: "planner_page",
  showContextMenuTable: true,
};

export default PlannerOrders;
