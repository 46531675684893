import i18n from "../../../i18n";
import { TEXT_FIELD_TYPE } from "../../../helpers/constants";




export const CLAUSES_TABLE_FIELDS_CONFIG = [
  {
    name: "clause_name",
    getValueCallback: (rowData) => rowData.clause_name,
    label: i18n.t("table.clause_table.clause_name"),
    dataType: TEXT_FIELD_TYPE,
    editable: false
  },
  {
    name: "clause_text",
    getValueCallback: (rowData) => rowData.clause_text,
    label: i18n.t("table.clause_table.clause_text"),
    dataType: TEXT_FIELD_TYPE,
    editable: false
  },
];