import { Grid } from "@mui/material";

const PrefixSuffixFieldTableWrapper = (props) => {
  return (
    <Grid
      display={"flex"}
      alignItems={props.alignItems}
      justifyContent={props.justifyContent}
    >
      {props.prefix}
      {props.children}
      {props.suffix}
    </Grid>
  );
};

PrefixSuffixFieldTableWrapper.propTypes = {};

PrefixSuffixFieldTableWrapper.defaultProps = {
  alignItems: "center",
  justifyContent: "center",
};

export default PrefixSuffixFieldTableWrapper;
