import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useAsync } from "../../../hooks/useAsync";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import AutocompleteField from "../../field/AutocompleteField";
import { Button, Grid } from "@mui/material";
import useLocationService from "../../../services/locationService";

const SelectObjectLevelDialog = (props) => {
  const { t } = useTranslation();

  const [selectedObject, setSelectedObject] = useState();

  const { getObjectSelectList } = useLocationService();

  const objectSelectList = useAsync(
    () => getObjectSelectList({ object_level: props.objectLevel }),
    [props.objectLevel]
  );

  const handleChangeSelectedObject = (
    e,
    value_object,
    value_key,
    state_value_name
  ) => {
    setSelectedObject(value_object[value_key]);
  };

  const handleSubmit = () => {
    props.onSubmit(selectedObject);
  };

  const isLoading = objectSelectList.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <>
        <Grid item xs={12}>
          <AutocompleteField
            name={"object_level"}
            label={t(`dialog.select_object_level.level_${props.objectLevel}`)}
            value={selectedObject}
            options={objectSelectList.value}
            isObjectOption={true}
            optionLabelKey={"object_name_with_code"}
            addNewValue={false}
            onChange={handleChangeSelectedObject}
            required
          />
        </Grid>
        <Button
          sx={{ marginTop: "20px" }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          disabled={!selectedObject}
        >
          {t("confirm")}
        </Button>
      </>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      maxWidth="sm"
      title={t(
        `dialog.select_object_level.select_object_level_${props.objectLevel}`
      )}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

SelectObjectLevelDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  objectLevel: PropTypes.number,
};

SelectObjectLevelDialog.defaultProps = {
  objectLevel: 4,
};

export default SelectObjectLevelDialog;
