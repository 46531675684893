import React, { useRef, useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useAsync } from "../../../hooks/useAsync";
import useOrderService from "../../../services/orderService";
import useTicketService from "../../../services/ticketService";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import CollapsibleTable from "../CollapsibleTable";
import TableService from "../../../services/tableService";
import TicketTable from "../TicketTable";

import {
  OVERRIDING_TABLE_CONFIG,
  OVERRIDING_TABLE_NAME,
  TICKET_TABLE_CONFIG,
  TICKET_TABLE_NAME,
} from "../../../page/ReleasesPage/TableConfig";

const InnerTicketTable = React.memo(
  ({
    id,
    selectedTicketId,
    onClickTableRow,
    tableConfig,
    hiddenColumns,
    columnsOrders,
  }) => {
    const [ticketDataLocaly, setTicketDataLocaly] = useState(undefined);
    const { getTicketsForOverriding } = useTicketService();
    const ticketData = useAsync(() => getTicketsForOverriding(id), [id]);

    useEffect(() => {
      if (!ticketData.loading) {
        setTicketDataLocaly(ticketData.value);
      }
    }, [ticketData.loading]);

    return (
      <LoaderWrapper showLoader={ticketData.loading}>
        {ticketDataLocaly && (
          <TicketTable
            showChangesCircle={false}
            showChangesCircleFilter={false}
            showDetailsIcon={true}
            data={ticketDataLocaly}
            showCheckbox={false}
            showCleanFilterIcon={false}
            selectedTicketId={selectedTicketId}
            onClickRow={onClickTableRow}
            hiddenColumns={hiddenColumns}
            columnsOrders={columnsOrders}
            tableConfig={tableConfig}
            showFilters={false}
            withPagination={false}
            showCounterRecords={false}
          />
        )}
      </LoaderWrapper>
    );
  }
);

const OverridingCollapsibleTable = React.memo((props) => {
  const data = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        OVERRIDING_TABLE_CONFIG,
        props.data,
        props.hiddenColumns[OVERRIDING_TABLE_NAME],
        props.columnsOrders[OVERRIDING_TABLE_NAME],
        props.onClickEdit,
        props.onClickPreview
      ).map((config) => ({
        ...config,
        updatedInnerElementData: props.selectedInnerItemId,
      })),
    [
      props.data,
      props.hiddenColumns[OVERRIDING_TABLE_NAME],
      props.columnsOrders[OVERRIDING_TABLE_NAME],
    ]
  );

  return (
    <CollapsibleTable
      showChangesCircle={props.showChangesCircle}
      checkedAll={props.checkedAll}
      showCheckbox={props.showCheckbox}
      showDetailsIcon={props.showDetailsIcon}
      showCleanFilterIcon={props.showCleanFilterIcon}
      onClickRow={props.onClickRow}
      onClickCheckAll={props.onClickCheckAll}
      data={data}
      filteringData={props.filteringData}
      countRecords={props.countRecords}
      page={props.page}
      onPageChange={props.onPageChange}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      rowsPerPageOptions={props.rowsPerPageOptions}
      resetPageNumber={props.resetPageNumber}
      headersConfig={TableService.getHeadersConfigForCollapsibleTable(
        OVERRIDING_TABLE_CONFIG,
        props.filteringData,
        props.hiddenColumns[OVERRIDING_TABLE_NAME],
        props.columnsOrders[OVERRIDING_TABLE_NAME]
      )}
      CollapsibleElement={
        <InnerTicketTable
          updatedInnerElement={props.selectedInnerItemId}
          hiddenColumns={props.hiddenColumns[TICKET_TABLE_NAME]}
          columnsOrders={props.columnsOrders[TICKET_TABLE_NAME]}
          tableConfig={TICKET_TABLE_CONFIG}
          selectedTicketId={props.selectedInnerItemId}
          onClickTableRow={props.onClickInnerTableRow}
        />
      }
      style={props.style}
      filterPrefix={props.filterPrefix}
      isSelectedOuterItem={false}
      selectedInnerItemId={props.selectedInnerItemId}
      showExportToFileButton={props.showExportToFileButton}
      exportToFileUrl={props.exportToFileUrl}
      exportToFileSearchParams={props.exportToFileSearchParams}
      exportToFileHeaders={props.exportToFileHeaders}
      exportToFileFileName={props.exportToFileFileName}
    />
  );
});

OverridingCollapsibleTable.propTypes = {
  showDetailsIcon: PropTypes.bool,
  checkedAll: PropTypes.bool,
  onClickCheck: PropTypes.func,
  onClickCheckAll: PropTypes.func,
  onClickRow: PropTypes.func,
  showCleanFilterIcon: PropTypes.bool,
  data: PropTypes.array,
  filteringData: PropTypes.object,
  countRecords: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  resetPageNumber: PropTypes.func,
  hiddenColumns: PropTypes.object,
  columnsOrders: PropTypes.object,
  style: PropTypes.object,
  filterPrefix: PropTypes.string,
  selectedInnerItemId: PropTypes.string,
  onClickInnerTableRow: PropTypes.func,
  showChangesCircle: PropTypes.func,
  showExportToFileButton: PropTypes.bool,
  exportToFileUrl: PropTypes.string,
  exportToFileSearchParams: PropTypes.object,
  exportToFileHeaders: PropTypes.array,
  exportToFileFileName: PropTypes.string,
};

OverridingCollapsibleTable.defaultProps = {
  hiddenColumns: {},
  columnsOrders: {},
  showDetailsIcon: false,
  showCleanFilterIcon: false,
  showChangesCircle: false,
  showCheckbox: false,
};

export default OverridingCollapsibleTable;
