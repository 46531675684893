import { useState, useEffect, useCallback, useRef, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import {
  FEEDBACK_TABLE,
  FEEDBACK_TABLE_FIELDS_CONFIG,
} from "./PageTablesConfig";

import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import UniversalToolBar from "../../components/bar/UniversalToolBar";

import useFeedbackService from "../../services/feedbackService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { NAVIGATION_DRAWER_TYPE_OFFERS, NAVIGATION_DRAWER_TYPE_RR } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import useDialog from "../../hooks/useDialog";
import FilterDialog from "../../components/base/FilterDialog";
import UserFilterDialog from "../../components/dialog/UserFilterDialog";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";

import UniversalFilterForm from "../../components/form/UniversalFilterForm";

import TableService from "../../services/tableService";

import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import FeedbackTable from "../../components/table/FeedbackTable";
import { exportFeedbackSlaToFileUrl } from "../../helpers/apiUrls";

import ChartService from "../../services/chartService";
import VerticalBarChart from "../../components/charts/VerticalBarChart/VerticalBarChart";

import RRPanelToolBar from "../RRPanelPage/RRPanelToolBar";
import OpenCloseFilterButton from "../../components/button/OpenCloseFilterButton";

import { getObjectFromSearchParams } from "../../helpers/methods";

import { getNpsIndicatorColor } from "../../helpers/styles";

const TABLE_CONFIGS = [
  { name: FEEDBACK_TABLE, config: FEEDBACK_TABLE_FIELDS_CONFIG },
];

export default function FeedbackManagmentPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [feedbackDataLocaly, setFeedbackDataLocaly] = useState(undefined);
  const [rateByCount, setRateByCount] = useState(undefined);
  const [npsIndicator, setNpsIndicator] = useState(undefined);
  const [countRecords, setCountRecords] = useState();
  const {
    page: feedbackDataPage,
    pageSize: feedbackDataPageSize,
    handleChangePageWithSearchParams: handleChangeFeedbackDataPage,
    handleChangePageSizeWithSearchParams: handleChangeFeedbackDataPageSize,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(undefined, undefined, 100);

  const handleChangeRowsPerPage = (e) => {
    handleChangeFeedbackDataPageSize(e, parseInt(e.target.value, 100));
  };

  const { getFeedbackSlaData, getFeedbackFilteringData } = useFeedbackService();

  const feedbackData = useAsync(() => {
    let tempSearchParams = { ...getObjectFromSearchParams(searchParams) };
    tempSearchParams.page_size = tempSearchParams.page_size || 100;
    return getFeedbackSlaData(tempSearchParams);
  }, [searchParams]);

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const fullRefreshTable = () => {
    setFeedbackDataLocaly(undefined);
    setRateByCount(undefined);
    feedbackData.refetch();
  };

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();
  useEffect(() => {
    if (userPreferencesForPage.loading) {
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [FEEDBACK_TABLE],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [FEEDBACK_TABLE],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        fullRefreshTable();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const feedbackFilteringData = useAsync(getFeedbackFilteringData);

  useEffect(() => {
    if (feedbackData.loading) {
      return;
    }
    setFeedbackDataLocaly(feedbackData.value.results);
    setRateByCount(feedbackData.value.rate_by_count);
    setNpsIndicator(feedbackData.value.nps);
    setCountRecords(feedbackData.value.count);
  }, [feedbackData.loading]);

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const [
    openUserFiltersDialog,
    onOpenUserFiltersDialog,
    onCloseUserFiltersDialog,
  ] = useDialog();

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const onCleanFlitersInFilterDialog = () => {
    setFeedbackDataLocaly(undefined);
    setRateByCount(undefined);
    setCountRecords(undefined);
  };

  const isLoading =
    userPreferencesForPage.loading ||
    hiddenColumnsForTables === undefined ||
    feedbackFilteringData.loading ||
    feedbackDataLocaly === undefined ||
    npsIndicator === undefined ||
    rateByCount == undefined ||
    countRecords === undefined;

  const feedbackExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      FEEDBACK_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[FEEDBACK_TABLE],
      columnsOrdersForTables?.[FEEDBACK_TABLE]
    );
  }, [
    hiddenColumnsForTables?.[FEEDBACK_TABLE],
    columnsOrdersForTables?.[FEEDBACK_TABLE],
    FEEDBACK_TABLE_FIELDS_CONFIG,
  ]);

  const handleClickBarChart = ({ label: rateName }) => {
    const rateId = feedbackFilteringData.value.rate_name.find(
      (rate) => rate.rate_name === rateName
    )?.id;

    if (rateId)
      setSearchParams((prev) => ({
        ...prev,
        rate_name: rateId,
      }));
  };

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_RR}
    >
      <DefaultPageWrapper titleKey={"feedback"}>
        <LoaderWrapper showLoader={isLoading}>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <RRPanelToolBar />
            </Grid>
            {rateByCount && (
              <Grid item xs={12} style={{ height: "50vh" }}>
                <Typography
                  color={getNpsIndicatorColor(npsIndicator)}
                  style={{
                    position: "absolute",
                    marginTop: "10px",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  {t("page.feedback_management_page.nps_indicator")}
                  {": "}
                  {npsIndicator}
                </Typography>
                <VerticalBarChart
                  data={ChartService.prepareDataFromDict(
                    rateByCount,
                    ChartService.prepareRedGreenBackgroundColorsGradientForData(
                      rateByCount
                    )
                  )}
                  titleText={t(
                    "page.feedback_management_page.evaluation_summary_of_closed_tickets"
                  )}
                  onClickBar={handleClickBarChart}
                  legendDisplay={false}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <UniversalToolBar
                style={{ marginBlock: "5px" }}
                showMassActionButton={false}
                onClickSearch={onOpenFilterDialog}
                onClickMyFilters={onOpenUserFiltersDialog}
                onClickAdjustTable={onOpenTableColumnVisibilityDialog}
                showOpenCloseFilterButton={false}
                showCreateButton={false}
                mdGridExtraButton={2.5}
                extraButtonList={[
                  <OpenCloseFilterButton
                    openCloseFilterLabel={t(
                      "page.feedback_management_page.tenant_rr_all"
                    )}
                    closeFilterLabel={t(
                      "page.feedback_management_page.tenat_rr_not_visibility"
                    )}
                    openFilterLabel={t(
                      "page.feedback_management_page.tenat_rr_visibility"
                    )}
                    openFilterKey={"tenant_rr_visibility"}
                  />,
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <FeedbackTable
                data={feedbackDataLocaly}
                showCleanFilterIcon={false}
                showDetailsIcon={true}
                countRecords={countRecords}
                page={feedbackDataPage}
                onPageChange={handleChangeFeedbackDataPage}
                rowsPerPage={feedbackDataPageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
                resetPageNumber={resetPageNumber}
                filteringData={feedbackFilteringData}
                hiddenColumns={
                  hiddenColumnsForTables
                    ? hiddenColumnsForTables[FEEDBACK_TABLE]
                    : []
                }
                columnsOrders={
                  columnsOrdersForTables
                    ? columnsOrdersForTables[FEEDBACK_TABLE]
                    : undefined
                }
                style={{ maxHeight: "75vh" }}
                tableConfig={FEEDBACK_TABLE_FIELDS_CONFIG}
                showExportToFileButton={true}
                exportToFileUrl={exportFeedbackSlaToFileUrl}
                exportToFileSearchParams={searchParams}
                exportToFileHeaders={feedbackExportHeaders}
                exportToFileFileName={`feedback.xlsx`}
                initialExpandedFilterHeader={true}
                dataKeyToRunDetailsMethod={"ticket_id"}
                itemDetailsLinkPrefix={"ticket_details"}
              />
            </Grid>
          </Grid>
          <FilterDialog
            open={openFilterDialog}
            onCleanFilters={onCleanFlitersInFilterDialog}
            onClose={onCloseFilterDialog}
            resetPageNumber={resetPageNumber}
            filterForm={
              <UniversalFilterForm
                filteringData={feedbackFilteringData}
                filtersConfig={FEEDBACK_TABLE_FIELDS_CONFIG}
                includeOpenCloseFilter={true}
                openCloseFilterName={t(
                  "page.feedback_management_page.tenat_rr_visibility"
                )}
                openCloseFilterKey={"tenant_rr_visibility"}
              />
            }
          />
          {openUserFiltersDialog && (
            <UserFilterDialog
              open={openUserFiltersDialog}
              onClose={onCloseUserFiltersDialog}
              pageName={pageName}
              filterForm={
                <UniversalFilterForm
                  filteringData={feedbackFilteringData}
                  filtersConfig={FEEDBACK_TABLE_FIELDS_CONFIG}
                  includeOpenCloseFilter={true}
                  openCloseFilterName={t(
                    "page.feedback_management_page.tenat_rr_visibility"
                  )}
                  openCloseFilterKey={"tenant_rr_visibility"}
                />
              }
            />
          )}
          {openTableColumnVisibilityDialog && (
            <TableColumnVisibilityDialog
              open={openTableColumnVisibilityDialog}
              onClose={onCloseTableColumnVisibilityDialog}
              onSubmit={handleUpdateUserPreferences}
              tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
                TABLE_CONFIGS,
                columnsOrdersForTables
              )}
              tablesHiddenColumns={hiddenColumnsForTables}
              isLoading={userPreferencesForPage.loading}
            />
          )}
        </LoaderWrapper>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
