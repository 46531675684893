import i18n from "../../i18n";

import {
  TEXT_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
} from "../../helpers/constants";

import { getStyleForSLACell } from "../../helpers/styles";
import DateService from "../../services/dateService";

export const ORDER_SLA_TABLE_NAME = "order";

export const ORDER_SLA_TABLE_FIELDS_CONFIG = [
  {
    name: "order_nr",
    getValueCallback: (rowData) => rowData.order_nr,
    label: i18n.t("table.order.order_nr"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "order_name",
    getValueCallback: (rowData) => rowData.order_name,
    label: i18n.t("table.order.order_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
  },
  {
    name: "ticket_category",
    getValueCallback: (rowData) => rowData.ticket_category,
    label: i18n.t("table.order.ticket_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_category,
      isObjectOption: true,
      optionLabelKey: "tc_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_owner",
    getValueCallback: (rowData) => rowData.ticket_owner,
    label: i18n.t("table.order.ticket_owner"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_owner,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_desc_creator",
    getValueCallback: (rowData) => rowData.ticket_desc_creator,
    label: i18n.t("table.order.ticket_desc_creator"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: true,
  },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.order.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "order_date_closeSTD_real",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.order_date_closeSTD_real
      ),
    label: i18n.t("table.order.order_date_closeSTD_real"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },

  {
    name: "status",
    getValueCallback: (rowData) => rowData.status,
    label: i18n.t("table.order.status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.status,
      isObjectOption: true,
      optionLabelKey: "status_name",
      addNewValue: false,
      multiple: true,
    }),
  },

  {
    name: "order_kt",
    getValueCallback: (rowData) => rowData.order_kt,
    label: i18n.t("table.order.order_kt"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.order_kt,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "skill",
    getValueCallback: (rowData) => rowData.skill,
    label: i18n.t("table.order.skill"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.skill,
      isObjectOption: true,
      optionLabelKey: "skill_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "order_t",
    getValueCallback: (rowData) => rowData.order_t,
    label: i18n.t("table.order.order_t"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.order_t,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.order.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "sla",
    getValueCallback: (rowData) => rowData.sla,
    sortable: true,
    label: i18n.t("table.order.sla"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "sla_lead_time",
    getValueCallback: (rowData) => rowData.sla_lead_time,
    sortable: true,
    label: i18n.t("table.order.sla_lead_time"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "sla_delay_time",
    getValueCallback: (rowData) => rowData.sla_delay_time,
    cellStyle: (rowData) => getStyleForSLACell(rowData.sla_delay_time),
    sortable: true,
    label: i18n.t("table.order.sla_delay_time"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      allowNegative: true,
    }),
  },
];
