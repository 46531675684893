import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  DATE_RANGE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import DateService from "../../services/dateService";

export const MY_TICKET_OFFER_TABLE_NAME = "my_ticket_offer";

export const MY_TICKET_OFFER_TABLE_FIELDS_CONFIG = [
  {
    name: "ticket_nr",
    getValueCallback: (rowData) => rowData.ticket_nr,
    label: i18n.t("table.ticket.ticket_nr"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
  },
  {
    name: "ticket_owner",
    getValueCallback: (rowData) => rowData.ticket_owner,
    label: i18n.t("table.ticket.ticket_owner"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_owner,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_category",
    getValueCallback: (rowData) => rowData.ticket_category,
    label: i18n.t("table.ticket.ticket_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_category,
      isObjectOption: true,
      optionLabelKey: "tc_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_name",
    getValueCallback: (rowData) => rowData.ticket_name,
    label: i18n.t("table.ticket.ticket_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.ticket.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  {
    name: "ticket_status",
    getValueCallback: (rowData) => rowData.ticket_status,
    label: i18n.t("table.ticket.ticket_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_status,
      isObjectOption: true,
      optionLabelKey: "status_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "ticket_substatus",
    getValueCallback: (rowData) => rowData.ticket_substatus,
    label: i18n.t("table.ticket.ticket_substatus"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_substatus,
      isObjectOption: true,
      optionLabelKey: "substatus_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "ticket_k",
    getValueCallback: (rowData) => rowData.ticket_k,
    label: i18n.t("table.ticket.ticket_k"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_k,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
];

export const MY_OFFER_TABLE_NAME = "my_offer";

export const MY_OFFER_TABLE_FIELDS_CONFIG = [
  {
    name: "offer_nr",
    getValueCallback: (rowData) => rowData.offer_nr,
    label: i18n.t("table.offer.offer_nr"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
  },
  {
    name: "offer_status",
    getValueCallback: (rowData) => rowData.offer_status,
    label: i18n.t("table.offer.offer_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.offer_status,
      isObjectOption: true,
      optionLabelKey: "off_status_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "200px" },
    }),
  },
  {
    name: "offer_date_send_om",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.offer_date_send_om
      ),
    label: i18n.t("table.offer.date_send_om"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  {
    name: "offer_date_terminated",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.offer_date_terminated
      ),
    label: i18n.t("table.offer.offer_date_terminated"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  {
    name: "offer_date_accept_om",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.offer_date_accept_om
      ),
    label: i18n.t("table.offer.offer_date_accept_om"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  {
    name: "offer_date_reject_om",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.offer_date_reject_om
      ),
    label: i18n.t("table.offer.offer_date_reject_om"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },

  {
    name: "creator",
    getValueCallback: (rowData) => rowData.creator,
    label: i18n.t("table.offer.creator"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.creator,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "offer_quatation_net",
    getValueCallback: (rowData) => rowData.offer_quatation_net,
    label: i18n.t("table.offer.offer_quatation_net"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "75px" },
    }),
  },
];

export const SETTLEMENT_OM_TABLE_FIELDS_CONFIG = [
  {
    name: "tenant_full_name",
    getValueCallback: (rowData) => rowData.tenant_full_name,
    label: i18n.t("table.settlements_table.payer_full_name"),
    dataType: TEXT_FIELD_TYPE,
    style: { minWidth: "250px" },
  },
  {
    name: "address",
    getValueCallback: (rowData) => rowData.address,
    label: i18n.t("table.settlements_table.payer_address"),
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "tenant_nip",
    getValueCallback: (rowData) => rowData.tenant_nip,
    label: i18n.t("table.settlements_table.nip"),
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "settlement_po",
    getValueCallback: (rowData) => rowData.settlement_po,
    label: i18n.t("table.settlements_table.po"),
    dataType: TEXT_FIELD_TYPE,
    editable: true,
  },
  {
    name: "settlement_payment_rate",
    getValueCallback: (rowData) => rowData.settlement_payment_rate,
    label: i18n.t("table.settlements_table.payment_rate"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "settlement_netto",
    getValueCallback: (rowData) => rowData.settlement_netto,
    label: i18n.t("table.settlements_table.quotation_net"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "settlement_vat",
    getValueCallback: (rowData) => rowData.settlement_vat,
    label: i18n.t("table.settlements_table.quotation_vat"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "settlement_brutto",
    getValueCallback: (rowData) => rowData.settlement_brutto,
    label: i18n.t("table.settlements_table.quotation_brutto"),
    dataType: NUMERIC_FIELD_TYPE,
  },
];
