import React from "react";
import PropTypes from "prop-types";

import CircleIcon from "@mui/icons-material/Circle";

import { IconButton } from "@mui/material";

function HasChangesDotFilterForm(props) {
  const handleChange = (e, value) => {
    e.target = { name: props.name, value: value };
    props.onChange(e);
  };

  const getDotFilter = () => {
    if (props.value === true || props.value === "true")
      return (
        <IconButton
          disabled={props.readOnly}
          onClick={(e) => handleChange(e, undefined)}
        >
          <CircleIcon color="success" fontSize="small" />
        </IconButton>
      );

    return (
      <IconButton
        disabled={props.readOnly}
        onClick={(e) => handleChange(e, true)}
      >
        <CircleIcon fontSize="small" color="disabled" />
      </IconButton>
    );
  };

  return getDotFilter();
}

HasChangesDotFilterForm.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

HasChangesDotFilterForm.defaultProps = {};

export default HasChangesDotFilterForm;
