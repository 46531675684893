import DateField from "../DateField/DateField";

export default function DateFilterField(props) {
 
  return (
   <DateField
   {...props}
   setEmptyString = {true}
   />
  );

}

DateFilterField.propTypes = {

};

DateFilterField.defaultProps = {

};
