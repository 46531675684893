import { useCallback } from "react";
import PropTypes from "prop-types";
import MyMessage from "../../messages/MyMessage/MyMessage";
import OtherMessage from "../../messages/OtherMessage";
import { Grid, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useAuth } from "../../../context/auth";
import { useTranslation } from "react-i18next";

function MessagesWindow(props) {
  const { t } = useTranslation();

  const { user } = useAuth();

  const messagesEndRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [props.messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ block: "end" });
  };

  const isMyMessage = (message) => {
    return message.message_sender === user.user_id && !message.is_log;
  };


  const getStyle = () =>{
  let style = {

    overflow: "auto",
    padding: " 12px",
  }
  if (!props.isFullScreen){
    style = {...style,     ...props.messageWindowHeight,}
  }
  return style
  }

  const getMessageWindowByMessageType = useCallback((message, index, props) => {
    if (isMyMessage(message)) {
      return (
        <Grid container justifyContent="flex-end" alignItems="flex-end">
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={10}>
            <MyMessage
              message={message}
              showContextMenu={props.showContextMenu}
              contextMenuActions={props.contextMenuActions}
              show={props.canHideMessages}
            />
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12} sm={10}>
            <OtherMessage
              message={message}
              showContextMenu={props.showContextMenu}
              contextMenuActions={props.contextMenuActions}
              show={props.canHideMessages || !message.is_log}
            />
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
        </Grid>
      );
    }
  }, []);

  return (
    <div
      style={getStyle()}
    >
      {props.messages.length > 0 ? (
        <Grid container direction="column-reverse">
          {props.messages.map((message, index) => {
            return getMessageWindowByMessageType(message, index, props);
          })}
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="subtitle1" color="primary">
            {" "}
            {t("window.messages_window.no_messages")}{" "}
          </Typography>
        </Grid>
      )}
      <div ref={messagesEndRef} />
    </div>
  );
}

MessagesWindow.propTypes = {
  messages: PropTypes.object,
  messageWindowHeight: PropTypes.object,
  showContextMenu: PropTypes.bool,
  canHideMessages :PropTypes.bool,
  isFullScreen : PropTypes.bool
}

MessagesWindow.defaultProps = {
  messages: {},
  messageWindowHeight: { height: 400 },
  canHideMessages : false,
  isFullScreen : false
};

export default MessagesWindow;
