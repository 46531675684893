import { useState, useEffect, useRef } from "react";

import { useSnackbarAlert } from "../../context/snackbarAlert";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import TicketOrderDetailsForm from "../../components/form/TicketOrderDetailsForm";
import UniversalCommunicationLogDialog from "../../components/dialog/UniversalCommunicationLogDialog";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import useOrderService from "../../services/orderService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import { useTranslation } from "react-i18next";
import OrderRelatedTablesTabs from "../../components/tabs/OrderRelatedTablesTabs";
import { Helmet } from "react-helmet";

function OrderDetailsPage(props) {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const { orderId } = useParams();
  const { getOrderDetailsData, getOrderFilteringData, updateOrderData } =
    useOrderService();

  const orderData = useAsync(() => getOrderDetailsData(orderId), [orderId]);
  const orderFilteringData = useAsync(getOrderFilteringData);

  const isLoading = orderData.loading || orderFilteringData.loading;

  const updateOrderDataFn = useAsyncFn(updateOrderData);
  const onUpdateOrderDataPromise = (orderId, dataToSend) => {
    return updateOrderDataFn
      .execute(orderId, dataToSend)
      .then((data) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.order_data_updated")
        );
        return Promise.resolve(data);
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_updating_order_data")
        );
        return Promise.reject(error);
      });
  };

  const handleCloseEnclosureDialog = (existsChanges) => {
    if (existsChanges) orderData.refetch();
  };

  const [comunicationDialogHeight, setComunnicationDialogHeight] =
    useState(475);
  const leftFormRef = useRef();

  useEffect(() => {
    if (
      leftFormRef.current &&
      !isLoading &&
      leftFormRef?.current.clientHeight > 0
    ) {
      setComunnicationDialogHeight(leftFormRef.current.clientHeight - 180);
    }
  }, [leftFormRef.current, isLoading]);

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  const readOnly = orderData.value.is_closed;
  const isOrderFinallyClosed = orderData.value.is_finally_closed;

  const tagTitle = `MySolvy | ${t("tag_title.order_details")} ${
    orderData.value?.order_nr
  }`;

  return (
    <>
      <Helmet>
        <title>{tagTitle}</title>
      </Helmet>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        rowSpacing={1}
        columnSpacing={1}
        alignItems="flex-start"
      >
        <Grid item xs={12} lg={7} ref={leftFormRef}>
          <TicketOrderDetailsForm
            itemType={"order"}
            filteringData={orderFilteringData.value}
            itemData={orderData.value}
            onUpdateDataPromise={onUpdateOrderDataPromise}
            onRefetchData={orderData.refetch}
            readOnly={readOnly}
            isItemFinallyClosed={isOrderFinallyClosed}
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <UniversalCommunicationLogDialog
            showDialogAsForm
            itemId={orderData.value.id}
            itemType={"order"}
            showExtendedAddEnclosureDialog={true}
            onCloseEnclosureDialog={handleCloseEnclosureDialog}
            messageWindowHeight={comunicationDialogHeight}
            inputMessageFormShowOnlyAttachmentsIcon={true}
            inputMessageFormGridJustifyContent={"space-around"}
            inputMessageFormEnterButtonGridSmGrid={1.5}
          />
        </Grid>
        <Grid item xs={12}>
          <OrderRelatedTablesTabs
            orderId={orderData.value.id}
            ticketId={orderData.value.ticket_id}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
    </>
  );
}

OrderDetailsPage.propTypes = {};

export default OrderDetailsPage;
