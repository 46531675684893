import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FullWidthTabs from "../FullWidthTabs/FullWidthTabs";
import { useTranslation } from "react-i18next";
import { useAsync } from "../../../hooks/useAsync";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import useWarehouseService from "../../../services/warehouseService";
import { STOCK_TRANSACTION_TABLE_FIELDS_CONFIG } from "../../table/StockTransactionTable/StockTransactionTable";
import { MATERIAL_RESERVATIONS_TABLE_FIELDS_CONFIG } from "../../table/MaterialReservationTable/MaterialReservationTable";
import StockTransactionFilterTable from "../../table/StockTransactionFilterTable/StockTransactionFilterTable";
import MaterialReservationFilterTable from "../../table/MaterialReservationFilterTable/MaterialReservationFilterTable";
import { useSearchParams } from "react-router-dom";
import useFilterSearchParams from "../../../hooks/useFilterSearchParams";
import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import { useEmptyArrayMemo } from "../../../hooks/useEmptyArrayMemo";



const TRANSACTION_FILTER_PREFIX = "trans";

const RESERVATION_FILTER_PREFIX = "reserv";

const StockTransactionTableTab = (props) => {
  const { getTransactionFilteringData, getTransactionByPartData} = useWarehouseService();
  
  const {emptyArrayMemo} = useEmptyArrayMemo()
  const [searchParams, setSearchParams] = useSearchParams();
  const [transactionsSearchParams, setTransactionsSearchParams] = useState({});
  const [transactionsLocal, setTransactionsLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();
  

  const { setNewItemSearchParamsIfAreChanged } =
    useFilterSearchParams(searchParams);

    const {
      page: transactionDataPage,
      pageSize: transactionDataPageSize,
      handleChangePageWithSearchParams: handleChangeTransactionDataPage,
      handleChangeRowsPerPage: handleChangeTransactionRowsPerPage,
      resetTransactionPageNumber,
    } = usePaginationWithSearchParams(TRANSACTION_FILTER_PREFIX);

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      TRANSACTION_FILTER_PREFIX,
      transactionsSearchParams,
      setTransactionsSearchParams
    );
  }, [searchParams]);

  const transactionData = useAsync(
    () => { if (props.partId){
      return getTransactionByPartData(props.partId, transactionsSearchParams);
    }
    return Promise.resolve([]);
  },
    [props.partId, transactionsSearchParams]
  );

  const transactionFilteringData = useAsync(getTransactionFilteringData)

  useEffect(() => {
    if (transactionData.loading) {
      return;
    }
    setTransactionsLocal(transactionData .value.results);
    setCountRecords(transactionData .value.count);
  }, [transactionData.loading]);

  if (transactionData.loading) return <LoaderWrapper showLoader={true} />;

  return (
    <div style={{ textAlign: "left" }}>
      <StockTransactionFilterTable
        showChangesCircle={false}
        showDetailsIcon={false}
        data={transactionsLocal}
        showCheckbox={false}
        showCleanFilterIcon={true}
        hiddenColumns={emptyArrayMemo}
        tableConfig={STOCK_TRANSACTION_TABLE_FIELDS_CONFIG}
        filteringData={transactionFilteringData}
        showFilters={true}
        withPagination={true}
        countRecords={countRecords}
        page={transactionDataPage}
        onPageChange={handleChangeTransactionDataPage}
        rowsPerPage={transactionDataPageSize}
        onRowsPerPageChange={handleChangeTransactionRowsPerPage}
        resetPageNumber={resetTransactionPageNumber}
        filterPrefix={TRANSACTION_FILTER_PREFIX}
      />
    </div>
  );
};
const MaterialReservationTableTab = (props) => {
  const { getTransactionFilteringData, getReservationByPartData } = useWarehouseService();
  const [searchParams, setSearchParams] = useSearchParams();
  const {emptyArrayMemo} = useEmptyArrayMemo()
  const [reservationSearchParams, setReservationSearchParams] = useState({});
  const [reservationsLocal, setReservationsLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();
  
  const { setNewItemSearchParamsIfAreChanged } =
    useFilterSearchParams(searchParams);

    const {
      page: reservationDataPage,
      pageSize: reservationDataPageSize,
      handleChangePageWithSearchParams: handleChangeReservationDataPage,
      handleChangeRowsPerPage: handleChangeReservationRowsPerPage,
      resetReservationPageNumber,
    } = usePaginationWithSearchParams(RESERVATION_FILTER_PREFIX);

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      RESERVATION_FILTER_PREFIX,
      reservationSearchParams,
      setReservationSearchParams
    );
  }, [searchParams]);

  const reservationData = useAsync(
    () => { if (props.partId){
      return getReservationByPartData(props.partId, reservationSearchParams);
    }
    return Promise.resolve([]);
  },
    [props.partId, reservationSearchParams]
  );

  const transactionFilteringData = useAsync(getTransactionFilteringData)

  useEffect(() => {
    if (reservationData.loading) {
      return;
    }
    setReservationsLocal(reservationData.value.results);
    setCountRecords(reservationData.value.count);
  }, [reservationData.loading]);

  if (reservationData.loading) return <LoaderWrapper showLoader={true} />;
  
  return (
    <div style={{ textAlign: "left" }}>
      <MaterialReservationFilterTable
        showChangesCircle={false}
        showDetailsIcon={false}
        data={reservationsLocal}
        showCheckbox={false}
        showCleanFilterIcon={true}
        hiddenColumns={emptyArrayMemo}
        tableConfig={MATERIAL_RESERVATIONS_TABLE_FIELDS_CONFIG}
        filteringData={transactionFilteringData}
        showFilters={true}
        withPagination={true}
        countRecords={countRecords}
        page={reservationDataPage}
        onPageChange={handleChangeReservationDataPage}
        rowsPerPage={reservationDataPageSize}
        onRowsPerPageChange={handleChangeReservationRowsPerPage}
        resetPageNumber={resetReservationPageNumber}
        filterPrefix={RESERVATION_FILTER_PREFIX}
      /> 
    </div>
  );

};

const StockTransactionRelatedTablesTabs = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <FullWidthTabs
      items={[
        {
          label: t("tabs.stock_transaction_related_tables.warehouse_transactions"),
          content: (
            <StockTransactionTableTab
              partId={props.partId}
              readOnly={props.readOnly}
            />
          ),
        },
        {
          label: t("tabs.stock_transaction_related_tables.material_reservations"),
          content: (
            <MaterialReservationTableTab
              partId={props.partId}
              readOnly={props.readOnly}
            />
          ),
        },
      ]}
    />
  );
});

StockTransactionRelatedTablesTabs.propTypes = {
  partId: PropTypes.string,
  readOnly: PropTypes.bool,
};

StockTransactionRelatedTablesTabs.defaultProps = {
  readOnly: false,
};

export default StockTransactionRelatedTablesTabs;
