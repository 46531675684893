import { useState } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { useAsync } from "../../../hooks/useAsync";

import useTicketService from "../../../services/ticketService";
import BasicDialog from "../../base/BasicDialog";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import AutocompleteField from "../../field/AutocompleteField";
import { Button, Grid } from "@mui/material";

const SelectTicketDialog = (props) => {
  const { t } = useTranslation();

  const [selectedTicket, setSelectedTicket] = useState();
  const { getTicketSelectList } = useTicketService();

  const ticketSelectList = useAsync(() => getTicketSelectList(props.searchParams), [props.searchParams]);

  const onChangeAutocompleteFieldWithObjectOptions = (
    e,
    value_object,
    value_key,
    state_value_name
  ) => {
    const newFormValue = Array.isArray(value_object)
      ? value_object.map((option) =>
          typeof option === "object" ? option[value_key] : option
        )
      : value_object[value_key];
    setSelectedTicket(newFormValue);
  };
  const isLoading = ticketSelectList.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />; 

    return (
      <>
        <Grid item xs={12}>
          <AutocompleteField
            name="ticket"
            label={t("other.related_ticket_record.ticket")}
            value={selectedTicket}
            options={ticketSelectList ? ticketSelectList.value : []}
            isObjectOption={true}
            optionLabelKey={"ticket_descriptive_name"}
            addNewValue={false}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            required
          />
        </Grid>
        <Button
          sx={{ marginTop: "20px" }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => props.onSubmit(selectedTicket)}
          disabled={!selectedTicket}
        >
          {t("confirm")}
        </Button>
      </>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      titleAlign="center"
      maxWidth="sm"
      title={t("dialog.select_ticket_dialog.select_ticket")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

SelectTicketDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ticketId: PropTypes.string,
  searchParams : PropTypes.object
};

SelectTicketDialog.defaultProps = {
};

export default SelectTicketDialog;
