import PropTypes from "prop-types";

import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

function TabsNavigationBar(props) {
  const location = useLocation();

  return (
    <Box sx={{ width: "100%", marginTop: 1 }}>
      <TabContext value={props.multipleLocationKey || location.pathname}>
        <TabList sx={{ marginBottom: 1 }}>
          {props.pages.map((page) => (
            <Tab
              key={page.link}
              label={page.label}
              value={page.link}
              sx={{ fontWeight: "bold" }}
              onClick={() => window.location.replace(page.link)}
            />
          ))}
        </TabList>
      </TabContext>
    </Box>
  );
}

TabsNavigationBar.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  multipleLocationKey: PropTypes.string,
};

TabsNavigationBar.defaultProps = {};

export default TabsNavigationBar;
