import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import AddButton from "@mui/icons-material/Add";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import AutocompleteField from "../../field/AutocompleteField";
import DateField from "../../field/DateField/DateField";

export default function CertDataForm(props) {
  const { t } = useTranslation();




  const showLoading = () => props.certificateSelectList.loading

  if (showLoading()) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={props.readOnly ? 12 : 11}>
        <AutocompleteField
          name="certificate"
          label={t(`form.cert_data_form.certificate`)}
          value={props.formValue.certificate}
          options={props.certificateSelectList?.value}
          isObjectOption={true}
          optionLabelKey={"certificate_name"}
          addNewValue={false}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          required
          disabled={props.readOnly}
        />
      </Grid>
      {!props.readOnly &&
        <Grid item xs={1} style={{paddingLeft:"0"}}>
          <IconButtonWithTooltip
            style={{ border: "3px solid var(--secondary)" }}
            title={t(`form.cert_data_form.create_certificate`)}
            onClick={props.onOpenCreateCertificate}
          >
            <AddButton
              fontSize="medium"
              sx={{
                color: "var(--secondary)",
              }}
            />
          </IconButtonWithTooltip> 
        </Grid>
      }
      <Grid item xs={6}>
        <DateField
          name="certification_date"
          label={t("form.cert_data_form.certification_date")}
          value={props.formValue.certification_date}
          readOnly={props.readOnly}
          onChange={props.onChangeDate}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <DateField
          name="certification_validation"
          label={t("form.cert_data_form.certification_validation")}
          value={props.formValue.certification_validation}
          readOnly={props.readOnly}
          onChange={props.onChangeDate}
          required
        />
      </Grid>

    </Grid>
  );
}

CertDataForm.propTypes = {
  onChangeDate: PropTypes.func,
  formValue: PropTypes.object,
  readOnly: PropTypes.bool,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
};

CertDataForm.defaultProps = {
  formValue: {},
  readOnly: false,
};
