import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../../context/auth";
import hasUserPermission from "../../../helpers/userPermissions";

const ProtectedRoute = ({ permissionList = [] }) => {
  const { user } = useAuth();

  return !hasUserPermission(permissionList, user) ? (
    <Navigate to="/" />
  ) : (
    <Outlet />
  );
};

export default ProtectedRoute;
