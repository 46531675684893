import { useState, useEffect, useMemo, useRef, useCallback } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { NAVIGATION_DRAWER_TYPE_REPORTS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import useOrderService from "../../services/orderService";
import { useAsync } from "../../hooks/useAsync";

import OrderFilterTable from "../../components/table/OrderFilterTable";
import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";
import OrderChartToolBar from "../../components/bar/OrderChartToolBar";

import TableService from "../../services/tableService";

import ChartService from "../../services/chartService";
import DateService from "../../services/dateService";
import VerticalBarChart from "../../components/charts/VerticalBarChart/VerticalBarChart";

import CollapseOrExpandButton from "../../components/other/CollapseOrExpandButton";

import { exportOrdersSlaToFileUrl } from "../../helpers/apiUrls";
import { getObjectFromSearchParams } from "../../helpers/methods";

import InfoButton from "../../components/button/InfoButton";
import ChartInformationDialog from "../../components/dialog/ChartInformationDialog";
import useDialog from "../../hooks/useDialog";

import {
  ORDER_SLA_TABLE_FIELDS_CONFIG,
  ORDER_SLA_TABLE_NAME,
} from "./TableConfig";

const TABLE_CONFIGS = [
  { name: ORDER_SLA_TABLE_NAME, config: ORDER_SLA_TABLE_FIELDS_CONFIG },
];

export default function OrdersReportBasePage(props) {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams();

  const { getOrdersSlaDataWithDistribution, getOrderSlaFilteringData } =
    useOrderService();

  const orderSlaData = useAsync(() => {
    let tempSearchParams = {
      ...getObjectFromSearchParams(searchParams),
    };

    tempSearchParams.group_by_key = props.groupBy;
    tempSearchParams.label_by_key = props.labelBy;
    tempSearchParams.hours_timezone_offset =
      DateService.getHoursTimezoneOffset();
    return getOrdersSlaDataWithDistribution(tempSearchParams);
  }, [searchParams]);

  const orderSlaFilteringData = useAsync(() => getOrderSlaFilteringData());

  const [orderSlaDataLocaly, setOrderSlaDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  const [orderDistribution, setOrderDistribution] = useState(undefined);

  useEffect(() => {
    if (orderSlaData.loading) {
      setOrderDistribution(undefined);
      return;
    }
    setOrderSlaDataLocaly(orderSlaData.value.results);
    setCountRecords(orderSlaData.value.count);
    setOrderDistribution(orderSlaData.value.orders_distribution);
  }, [orderSlaData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] =
    useState(undefined);
  const [columnsOrdersForTables, setColumnsOrdersForTables] =
    useState(undefined);

  const refreshTable = () => {
    setOrderSlaDataLocaly(undefined);
    setOrderDistribution(undefined);
    orderSlaData.refetch();
  };

  const [openChartInfoDialog, onOpenChartInfoDialog, onCloseChartInfoDialog] =
    useDialog();

  const orderSlaExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      ORDER_SLA_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[ORDER_SLA_TABLE_NAME],
      columnsOrdersForTables?.[ORDER_SLA_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[ORDER_SLA_TABLE_NAME],
    columnsOrdersForTables?.[ORDER_SLA_TABLE_NAME],
    ORDER_SLA_TABLE_FIELDS_CONFIG,
  ]);

  const isMultipleGroupsChart = orderDistribution?.is_multiple_groups_chart;

  const [chartIsFullScreen, setChartFullScreen] = useState(false);

  const getUniveralToolBarExtraButtonList = () => {
    let buttonList = [
      <CollapseOrExpandButton
        isExpanded={chartIsFullScreen}
        onChangeIsExpanded={setChartFullScreen}
      />,
    ];

    if (props.showChartInformationDialog) {
      buttonList = [
        <InfoButton
          label={t("chart_description")}
          onClick={onOpenChartInfoDialog}
        />,
        ...buttonList,
      ];
    }

    return buttonList;
  };

  const getExtraButtonListMdGrid = () => {
    let buttonListMdGrid = [0.5];
    if (props.showChartInformationDialog) {
      buttonListMdGrid = [1.5, ...buttonListMdGrid];
    }

    return buttonListMdGrid;
  };

  const isLoading =
    orderSlaDataLocaly === undefined || orderSlaFilteringData.loading;

  return (
    <NavigationDrawer
      pageName={props.pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_REPORTS}
    >
      <DefaultPageWrapper titleKey={props.pageTitleKey}>
        <LoaderWrapper showLoader={isLoading}>
          <Grid
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={1}
          >
            {props.showNavigationBar && (
              <Grid item xs={12}>
                {props.NavigationBar}
              </Grid>
            )}
            <Grid item xs={12}>
              <OrderChartToolBar
                pageName={props.pageName}
                showSelectTicketCategory={props.showSelectTicketCategory}
                showSelectTenant={props.showSelectTenant}
                showSelectOrderKt={props.showSelectOrderKt}
                showSelectSkill={props.showSelectSkill}
                showSelectOrderT={props.showSelectOrderT}
                tableFilteringData={orderSlaFilteringData}
                tableFieldConfigs={ORDER_SLA_TABLE_FIELDS_CONFIG}
              />
            </Grid>
            {orderDistribution && (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    height: chartIsFullScreen
                      ? props.chartHeightInFullScreen
                      : props.chartHeight,
                  }}
                >
                  <VerticalBarChart
                    data={{
                      labels: orderDistribution.labels,
                      datasets:
                        ChartService.prepareDataForSingleOrMultiGroupsChart(
                          orderDistribution.data,
                          isMultipleGroupsChart
                        ),
                    }}
                    legendDisplay={isMultipleGroupsChart}
                    titleDisplay={false}
                    dataLabelsDisplay={true}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} marginY={1}>
              <UniversalToolBarWithDialogs
                pageName={props.pageName}
                tableConfig={TABLE_CONFIGS}
                filteringData={orderSlaFilteringData}
                refreshTable={refreshTable}
                resetPageNumber={resetPageNumber}
                hiddenColumnsForTables={hiddenColumnsForTables}
                setHiddenColumnsForTables={setHiddenColumnsForTables}
                columnsOrdersForTables={columnsOrdersForTables}
                setColumnsOrdersForTables={setColumnsOrdersForTables}
                showMassActionButton={false}
                showCreateButton={false}
                showOpenCloseFilterButton={true}
                showCleanfiltersButton={false}
                showMyFiltersButton={false}
                showSearchButton={false}
                extraButtonList={getUniveralToolBarExtraButtonList()}
                extraButtonListMdGrid={getExtraButtonListMdGrid()}
              />
            </Grid>
            <Grid item xs={12}>
              {hiddenColumnsForTables &&
                columnsOrdersForTables &&
                !chartIsFullScreen && (
                  <OrderFilterTable
                    showCleanFilterIcon={false}
                    showFilters={true}
                    showDetailsIcon={true}
                    data={orderSlaDataLocaly}
                    filteringData={orderSlaFilteringData}
                    countRecords={countRecords}
                    page={page}
                    onPageChange={handleChangePageWithSearchParams}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    resetPageNumber={resetPageNumber}
                    tableConfig={ORDER_SLA_TABLE_FIELDS_CONFIG}
                    hiddenColumns={hiddenColumnsForTables[ORDER_SLA_TABLE_NAME]}
                    columnsOrders={columnsOrdersForTables[ORDER_SLA_TABLE_NAME]}
                    style={{ maxHeight: "65vh" }}
                    showExportToFileButton={true}
                    exportToFileUrl={exportOrdersSlaToFileUrl}
                    exportToFileSearchParams={searchParams}
                    exportToFileHeaders={orderSlaExportHeaders}
                    exportToFileFileName={`${t(
                      "page.order_management_page.orders"
                    ).replace(" ", "_")}.xlsx`}
                    itemDetailsLinkPrefix={"order_details"}
                  />
                )}
            </Grid>
            {openChartInfoDialog && (
              <ChartInformationDialog
                open={openChartInfoDialog}
                onClose={onCloseChartInfoDialog}
                content={props.chartDescrtiption}
              />
            )}
          </Grid>
        </LoaderWrapper>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}

OrdersReportBasePage.propTypes = {
  labelBy: PropTypes.string,
  groupBy: PropTypes.string,
  showSelectTicketCategory: PropTypes.bool,
  showSelectOrderKt: PropTypes.bool,
  showSelectTenant: PropTypes.bool,
  showSelectOrderT: PropTypes.bool,
  showSelectSkill: PropTypes.bool,
  pageName: PropTypes.string,
  pageTitleKey: PropTypes.string,
  NavigationBar: PropTypes.element,
  showNavigationBar: PropTypes.bool,
  chartHeight: PropTypes.string,
  chartHeightInFullScreen: PropTypes.string,
};

OrdersReportBasePage.defaultProps = {
  chartHeight: "45vh",
  chartHeightInFullScreen: "72vh",
};
