import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid, Button } from "@mui/material";

const UNCONFIGURED_STD_TICKET_BUTTON_SX = {
  bgcolor: "primary.light",
  "&:hover": { bgcolor: "primary.main" },
};
const CONFIGURED_STD_TICKET_BUTTON_SX = {
  bgcolor: "success.light",
  "&:hover": { bgcolor: "success.light" },
};
const CONFIGURED_LOCAL_STD_TICKET_BUTTON_SX = {
  bgcolor: "success.dark",
  "&:hover": { bgcolor: "success.dark" },
};

const OverridingStdTicketButton = ({ label, disabled, buttonSx, onClick }) => (
  <Button
    fullWidth
    variant="contained"
    onClick={disabled ? () => {} : onClick}
    sx={buttonSx}
    style={{ padding: 20, height: "100%" }}
  >
    {label}
  </Button>
);

const OverridingStdTicketForm = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="stretch"
      alignItems="strech"
      spacing={4}
    >
      {props.stdTicketConfig.map((config) => {
        const isConfigured = props.stdTicketConfigured.includes(
          config.std_ticket_id
        );
        const isConfiguredLocal = props.stdTicketConfiguredLocal.includes(
          config.std_ticket_id
        );
        const getButtonSx = () => {
          if (isConfiguredLocal) return CONFIGURED_LOCAL_STD_TICKET_BUTTON_SX;
          if (isConfigured) return CONFIGURED_STD_TICKET_BUTTON_SX;
          return UNCONFIGURED_STD_TICKET_BUTTON_SX;
        };
        return (
          <Grid item xs={12} md={4}>
            <OverridingStdTicketButton
              label={config.std_ticket_label}
              disabled={props.readOnly || isConfigured}
              buttonSx={getButtonSx()}
              onClick={() => props.onClick(config)}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

OverridingStdTicketForm.propTypes = {
  stdTicketConfig: PropTypes.array,
  stdTicketConfigured: PropTypes.array,
  stdTicketConfiguredLocal: PropTypes.array,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
};

OverridingStdTicketForm.defaultProps = {
  stdTicketConfig: [],
  stdTicketConfigured: PropTypes.array,
  stdTicketConfiguredLocal: PropTypes.array,
  onClick: () => {},
};

export default OverridingStdTicketForm;
