import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect, useMemo, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import AgreementDetailsForm from "../../form/AgreementDetailsForm/AgreementDetailsForm";
import useFieldValidation from "../../../hooks/useFieldValidation";
import useAgreementService from "../../../services/agreementService";
import { getErrorMsg, isEmptyArray, isEmptyObject, isEmptyValue } from "../../../helpers/methods";


const AgreementDetailsDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const { getUnfillRequiredFields } = useFieldValidation();
  const [isLocationFormatValid, setIsLocationFormatValid] = useState(true);
  const [isExistLocationForm, setExistLocationForm,] = useState(true);

  const [agreementSubkindSelectList, setAgreementSubkindSelectList] = useState(props.filteringData.value.agreement_subkind)
 
  const locationFormatRegex = /^\d{3}[_a-zA-Z]?$/;
  
  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions
  } = useForm({"agreement_location_number" : "___", "agreement_is_tenant": true});


  useEffect(() => {
    if (formValue?.agreement_location_number !== "___") {
      setIsLocationFormatValid(validateLocationNumber(formValue?.agreement_location_number));
    }
  }, [formValue.agreement_location_number]);
  
  const {
    getAgreementDetails,
    getAgreementsSelectList,
    getNextAgreementNumber,
    getAgreementBinders,
    updateAgreementDetails,
    createAgreement,
    getAgreementSubkindsSelectList} = useAgreementService();


  const updateAgreementDetailsFn = useAsyncFn(updateAgreementDetails)
  const createAgreementFn = useAsyncFn(createAgreement)
  const getAgreementSubkindsSelectListFn = useAsyncFn(getAgreementSubkindsSelectList)


  const requiredFields = useMemo(() => {
    return [
      "agreement_title",
      "owner",
      "agreement_kind",
      "agreement_subkind",
      "agreement_date_sign",
      "agreement_location_prefix",
      "agreement_location_binder",
      "agreement_location_number"
    ];
  }, []);

  const agreementDetailsData = useAsync(
    () => {
      if (props.agreementId) {
      return getAgreementDetails(props.agreementId)
      }
      return Promise.resolve({})
  },[props.agreementId]);

  const ownerPrefixBinderList = useAsync(
    () => {
      if (formValue?.owner && formValue?.agreement_location_prefix && isExistLocationForm) {
      return getAgreementBinders({"owner" : formValue.owner, "agreement_location_prefix" : formValue.agreement_location_prefix })
      }
      return Promise.resolve([])
  },[formValue?.owner, formValue?.agreement_location_prefix,]);

  const nextLocationNumberList = useAsync(
    () => {
      if (formValue?.owner && formValue?.agreement_location_prefix && formValue?.agreement_location_prefix && formValue?.agreement_location_binder && props.agreementId === undefined && isExistLocationForm ) {
      return getNextAgreementNumber({"owner" : formValue.owner, "agreement_location_prefix" : formValue.agreement_location_prefix, "agreement_location_binder" : formValue.agreement_location_binder})
      }
      return Promise.resolve({})
  },[formValue?.owner, formValue?.agreement_location_prefix, formValue?.agreement_location_binder, isExistLocationForm]);



    const agreementSelectList = useAsync(() => {
      if((formValue.owner !==undefined && !isEmptyArray(formValue.owner)) || (formValue.tenant !==undefined && !isEmptyArray(formValue.tenant)) || !isEmptyValue(formValue.agreement_individual_person)){
        let searchDict = {}
        if (formValue.owner){
          searchDict['owner'] =formValue.owner
        }
        if(formValue.tenant){
          searchDict["tenant"] = formValue.tenant
        }
        if(formValue.agreement_individual_person){
          searchDict["agreement_individual_person"] = formValue.agreement_individual_person
        }
       
        if (props.agreementId){
          searchDict["exclude"] = props.agreementId
        }
        return getAgreementsSelectList(searchDict)
      }
     return getAgreementsSelectList(props.agreementId? {"exclude": props.agreementId} : {})
    },
     [formValue?.owner, formValue?.tenant, formValue?.agreement_individual_person]);

  useEffect(() => {
    if (agreementDetailsData.loading) {
      return;
    }
    if (agreementDetailsData.value && !isEmptyObject(agreementDetailsData.value )) {
      setFormValue(agreementDetailsData.value);
      setAgreementSubkindSelectList(agreementDetailsData.value.agreement_subkind_list)
      setIsLocationFormatValid(validateLocationNumber(agreementDetailsData.value.agreement_location_number));
    }
  }, [agreementDetailsData.loading]);




  useEffect(() => {
    if (nextLocationNumberList.loading) {
      return;
    }
    if (nextLocationNumberList.value && !isEmptyObject(nextLocationNumberList.value ) ) {
      setFormValue((prev) => ({...prev, ...nextLocationNumberList.value}));

    }
  }, [nextLocationNumberList.loading, formValue?.agreement_location_binde]);


  const onChangeAgreementKind = (e, value_object, value_key, state_value_name)=>{
    setFormValue((formValue) => ({ ...formValue, ['agreement_subkind']: undefined }));
    onChangeAutocompleteFieldWithObjectOptions(e, value_object, value_key, state_value_name)
    if (value_object.id) {
      getAgreementSubkindsSelectListFn.execute({'agreement_kind' : value_object.id})    
          .then((res) => {
            setAgreementSubkindSelectList(res)
          })
    }else{
      setAgreementSubkindSelectList([])
    }
  }



  const onChangeLocal = useCallback((e) => {
    onChange(e)
    const { name } = e.target
    if (name === "agreement_location_prefix") {
      setFormValue((formValue) => ({ ...formValue, agreement_location_binder: undefined, agreement_location_number:"___"}));
      setExistLocationForm(true)
    } else if(name === "agreement_is_tenant"){
      setFormValue((formValue) => ({ ...formValue, tenant: [], agreement_individual_person: null}));
    }

  }, []);

  const onChangeOwner = (e, value_object, value_key, state_value_name)=>{
    setFormValue((formValue) => ({ ...formValue, ['parent']: undefined }));
    onChangeAutocompleteFieldWithObjectOptions(e, value_object, value_key, state_value_name)
  }

  const isLoadingButtonDisabled = useMemo(() => {
    return getUnfillRequiredFields(requiredFields, formValue).length > 0 || !isLocationFormatValid || ((formValue.tenant === undefined || formValue.tenant.length === 0) && isEmptyValue(formValue.agreement_individual_person));
  }, [requiredFields, formValue, getUnfillRequiredFields, isLocationFormatValid]);


  const onAddNewLocation = useCallback(() =>{
    setExistLocationForm(false);
    setFormValue((formValue) => ({ ...formValue, agreement_location_binder: undefined, agreement_location_number:"001"}));
  }, [])

  const onCancelAddNewLocation = useCallback(() =>{
    setExistLocationForm(true);
    setFormValue((formValue) => ({ ...formValue, agreement_location_binder: undefined, agreement_location_number:"____"}));
  }, [])

  const prepareData = (data) =>{
    let tempData = {...data}
    tempData['new_binder'] = !isExistLocationForm
    return tempData
  }
  const onSubmit = useCallback(() => {
    if (props.agreementId) {
      updateAgreementDetailsFn
        .execute(props.agreementId, prepareData(formValue))
        .then((res) => {
          props.filteringData.refetch()
          props.onRefetchData()
          props.onRefetchDetails()
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.agreement_updated")
          );
          props.onClose();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(error.data),
            t("snackbar_alert.occurred_error_during_agreement_updating")
          );
        });
    } else {
      createAgreementFn
        .execute(prepareData(formValue))
        .then((res) => {
          props.filteringData.refetch()
          props.onRefetchData()
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.agreement_created")
          );
          props.onClose();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(error.data),
            t("snackbar_alert.occurred_error_during_agreement_creating")
          );

        });
    }

  }, [formValue, isExistLocationForm]);

  const validateLocationNumber = (locationNumber) => {
    return locationFormatRegex.test(locationNumber);
  };


  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.agreementId ? t(`dialog.agreements_details_dialog.agreement_details`) : t(`dialog.agreements_details_dialog.create_agreement`)}
      maxWidth="md"
      showDialogActions
    >

      <LoaderWrapper showLoader={agreementDetailsData.loading} >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12}>
            {agreementDetailsData.value &&
            <AgreementDetailsForm
              readOnly={props.readOnly}
              agreementData={formValue}
              agreementId = {props.agreementId}
              onChange={ onChangeLocal}
              onChangeDate={onChangeDate}
              onChangeOwner={onChangeOwner}
              onAddNewLocation={onAddNewLocation}
              onCancelAddNewLocation={onCancelAddNewLocation}
              isExistLocationForm={isExistLocationForm}
              onChangeAutocompleteFieldWithObjectOptions={onChangeAutocompleteFieldWithObjectOptions}
              filteringData={props.filteringData}
              agreementSubkindSelectList={agreementSubkindSelectList}
              agreementParentsList={agreementSelectList?.value ? agreementSelectList?.value : []}
              onChangeAgreementKind={onChangeAgreementKind}
              isLocationFormatValid= {isLocationFormatValid || agreementDetailsData.loading}
              locationBindersByOwner={ownerPrefixBinderList?.value}
            />}
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={updateAgreementDetailsFn.loading || createAgreementFn.loading}
              disabled={isLoadingButtonDisabled}
              onClick={onSubmit}
            >
              {props.agreementId ? t("save") : t("create")}
            </LoadingButton>
          </Grid>

        </Grid>

      </LoaderWrapper>

    </BasicDialog>
  );
};

AgreementDetailsDialog.propTypes = {
  agreementId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool
};

AgreementDetailsDialog.defaultProps = {
  open: false,
};

export default AgreementDetailsDialog;
