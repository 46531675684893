import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import TenantDetailsForm from "../../form/TenantDetailsForm";

import useUserService from "../../../services/userService";
import { useAsync } from "../../../hooks/useAsync";

const TenantDetailsDialog = (props) => {
  const { t } = useTranslation();

  const { getTenantDetails } = useUserService();
  const tenantDetailsData = useAsync(
    () => getTenantDetails(props.tenantId),
    [props.tenantId]
  );

  const isLoading = tenantDetailsData.loading;

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={t(`dialog.tenant_details.${props.title}`)}
      maxWidth="sm"
      showDialogActions
    >
      <LoaderWrapper showLoader={isLoading}>
        <TenantDetailsForm formValue={tenantDetailsData.value} />
      </LoaderWrapper>
    </BasicDialog>
  );
};

TenantDetailsDialog.propTypes = {
  tenantId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  readOnly: PropTypes.bool,
};

TenantDetailsDialog.defaultProps = {
  open: false,
  title: "tenant",
};

export default TenantDetailsDialog;
