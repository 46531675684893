import BasicDialog from "../../base/BasicDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import OfferSettlementsForm from "../../form/OfferSettlementsForm";
import { SETTLEMENT_TABLE_FIELDS_CONFIG } from "../../form/OfferSettlementsForm/FormTablesConfig";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { CLAUSES_TABLE_FIELDS_CONFIG } from "./ClauseTablesConfig";
import { useCalendarState } from "@mui/x-date-pickers/internals";
import OfferClauseForm from "../../form/OfferClauseForm/OfferClauseForm";
import useOfferService from "../../../services/offerService";
import { useAsync } from "../../../hooks/useAsync";

function ClausesDialog(props) {
  const stateRef = useRef();
  const { t } = useTranslation();
  const [clausesLocal, setClausesLocal] = useState([]);

  const [checkedAll, setCheckedAll] = useState(false);

  stateRef.clausesLocal= clausesLocal

  const {
    getCalusesList
  } = useOfferService()

  const clauses = useAsync(
    () => getCalusesList(),
    []
);

useEffect(() => {
  if (clauses.loading) {
    return;
  }
  setClausesLocal(clauses.value);
}, [clauses.loading]);

const handleClickCheck = useCallback((id) => {

  setClausesLocal((prevData) => {
    let selectedRow = { ...prevData.find((rowData) => rowData.id === id) };
    selectedRow.selected = !selectedRow.selected;
    return prevData.map((rowData) => {
      if (rowData.id === id) return selectedRow;
      return rowData;
    });
  });

}, [  stateRef.clausesLocal]);

const getSeletedClauses = () =>{
 return stateRef.clausesLocal.filter((clause) => clause.selected).map((clause) => clause); 
}

  const onSubmitButton = useCallback(() => {
      props.onSubmit(getSeletedClauses())
      onClose()
  }, [stateRef.clausesLocal])

  const handleClickCheckAll = useCallback(
    (value) => {
      let currValue = !checkedAll;
      setCheckedAll(currValue);

      setClausesLocal((prev) => {
        return prev.map((rowData) => ({
          ...rowData,
          selected: currValue,
        }));
      });
    },
    [checkedAll]
  );



  const handleUnCheckAll = () => {
    setCheckedAll(false);
    setClausesLocal((prev) => {
      return prev?.map((rowData) => ({
        ...rowData,
        selected: false,
      }));
    });
  };

  const onClose = useCallback(() =>{
    handleUnCheckAll()
    props.onClose()
  }, [])

  const isLoading = clauses.loading

  if (isLoading) return <LoaderWrapper showLoader={true} />
  
  return (
    <BasicDialog
      open={props.open}
      onClose={onClose}
      titleAlign="center"
      title={t("dialog.clauses_dialog.clauses")}
      maxWidth="xl"
      showTopActionButton={false}
      showBottomActionButton={false}
      bottomActionStyle={{ padding: "10px" }}
      showCustomFooter={true}
      showDialogActions={false}
    >
        <OfferClauseForm
          showCheckbox={props.showCheckbox}
          checkedAll={checkedAll}
          onClickCheckAll={handleClickCheckAll}
          onClickCheck={handleClickCheck}
          data={  stateRef.clausesLocal}
          tableConfig={CLAUSES_TABLE_FIELDS_CONFIG}
          readOnly={false}
          showAddButon={false}
          showContextMenu={props.showContextMenu}
          contextMenuActions={props.contextMenuActions}
          showSubmitButton={true}
          onSubmitButton={onSubmitButton}
          allowClickCheckbox = {true}
        />
    </BasicDialog>
  );
}

ClausesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  data: PropTypes.array,
  onHandleSettlementAddEmptyRow: PropTypes.func,
  onChangeAutocomplete: PropTypes.func,
  showContextMenu: PropTypes.bool,
  contextMenuActions: PropTypes.func,
  isRequiredeFieldsValid: PropTypes.bool,
  sumSettlementRatesIsCorrectly: PropTypes.bool,
  showCheckbox : PropTypes.bool,
};

ClausesDialog.defaultProps = {
  open: false,
  readOnly: true,
  data: [],
  isRequiredeFieldsValid: true,
  sumSettlementRatesIsCorrectly: true,
  onDataChange: PropTypes.func,
  showContextMenu: false,
  contextMenuActions: [],
  showCheckbox : true
};

export default ClausesDialog;
