import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect, useMemo, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useFieldValidation from "../../../hooks/useFieldValidation";
import ItemRecord from "../../other/ItemRecord/ItemRecord";
import useItemService from "../../../services/itemService";
import FloatField from "../../base/FloatField/FloatField";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import BoxWithTypography from "../../box/BoxWithTypography/BoxWithTypography";
import usePurchaseService from "../../../services/purchaseService";
import useDialog from "../../../hooks/useDialog";
import SelectPartDialog from "../SelectPartDialog";
import useOfferService from "../../../services/offerService";
import { MATERIAL_MAIN_BRANCH_NAME, MANAGE_PARTS_PERMISSION } from "../../../helpers/constants";
import { centerVericalAlignIconStyle } from "../../../helpers/styles";
import CreateItemDialog from "../CreateItemDialog";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import useCheckPermission from "../../../hooks/usePermission";



const PoLineDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const {
    prepareItemDataFromBackendForItemRecordComponent,
    getEmptyItemElement
  } = useItemService()



  const {
    getPartItemRecordById
  } = useOfferService();

  const getPartItemRecordByIdFn = useAsyncFn(getPartItemRecordById)


  const [
    openSelectPartDialog,
    onOpenSelectPartDialog,
    onCloseSelectPartDialog,
  ] = useDialog();

  
  const [
    openCreateItemDialog,
    onOpenCreateItemDialog,
    onCloseCreateItemDialog,
  ] = useDialog();


  const { getUnfillRequiredFields } = useFieldValidation();
  const [items, setItems] = useState(getEmptyItemElement());

  const [hasPermissionToCreate] = useCheckPermission(MANAGE_PARTS_PERMISSION)

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions
  } = useForm({ "po": props.poId });

  const { createPoLine } = usePurchaseService();
  const createPoLineFn = useAsyncFn(createPoLine)

  const onChangeLevelItem = (item, index = 0) => {
    var items_temp = [...items];
    items_temp[index] = item;
    setItems(item);
  };

  const onChangeAutocompleteLocal = useCallback((...inputProps) => {
    onChangeAutocompleteFieldWithObjectOptions(...inputProps)
    const [event, valueObject, valueKey, name, index] = inputProps

    setFormValue((tempForm) => ({ ...tempForm, "poli_unit": valueObject["part_unit"] }))
  }, [formValue])

  const requiredFields = useMemo(() => {
    return [
      "part"
    ];
  }, []);



  const setItemBranchWithMaterial = useCallback((selectedPartId) => {
    getPartItemRecordByIdFn.execute(selectedPartId)
      .then((result) => {
        setItems(prepareItemDataFromBackendForItemRecordComponent(result.item))
        setFormValue((prev) => ({ ...prev, 'part': result.id, "poli_unit": result.part_unit }))
        onCloseSelectPartDialog()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_get_item_data")
        );
      })

  }, [items, formValue])


  const onCleanPart = useCallback(() => {
    setFormValue((tempForm) => ({ ...tempForm, "part": undefined, "poli_unit": undefined }))
  }, [formValue])

  const isButtonDisabled = useMemo(() => {
    return getUnfillRequiredFields(requiredFields, formValue).length > 0;
  }, [requiredFields, formValue, getUnfillRequiredFields]);

  const onSubmit = useCallback(() => {
    createPoLineFn.execute(formValue)
      .then((res) => {

        props.onUpdatePoLinesLocal(res)
        props.onClose()
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.po_line_created")
        );
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(t(`snackbar_alert.${error.data.error_code}`)
        );
      });
  }, [formValue])

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={t(`dialog.po_line_dialog.add_po_line`)}
      maxWidth="xl"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        {hasPermissionToCreate &&
          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              variant="contained"
              size="small"
              startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
              onClick={onOpenCreateItemDialog}
            >
              {t("dialog.po_line_dialog.add_material")}
            </Button>
          </Grid>}

        <Grid item xs={12}>
          <BoxWithTypography
            style={{ padding: "10px" }}
            label={t(`form.item_form.purchase_tree`)}
            component={"div"}
          >
            <ItemRecord
              itemLevels={items}
              onChangeItem={onChangeLevelItem}
              onChangeAutocompleteFieldWithObjectOptions={onChangeAutocompleteLocal}
              partValue={formValue['part']}
              onCleanPart={onCleanPart}
              showMaterialLabel={true}
              onClickSearchItem={onOpenSelectPartDialog}
              defaultFirstLevelItemName={MATERIAL_MAIN_BRANCH_NAME}
            />
          </BoxWithTypography>
        </Grid>

        <Grid item xs={3}>
          <FloatField
            name="poli_amount"
            onChange={onChange}
            value={formValue?.poli_amount}
            label={t("dialog.po_line_dialog.poli_amount")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldFormControl
            name="poli_unit"
            onChange={onChange}
            value={formValue.poli_unit ? t(`other.units.${formValue.poli_unit}`) : undefined}
            label={t("dialog.po_line_dialog.poli_unit")}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormControl
            name="poli_note"
            onChange={onChange}
            value={formValue?.poli_note}
            label={t("dialog.po_line_dialog.poli_note")}
          />
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={false}
            disabled={isButtonDisabled}
            onClick={onSubmit}
          >
            {t(`dialog.po_line_dialog.save_line`)}
          </LoadingButton>
        </Grid>
      </Grid>

      {openSelectPartDialog &&
        <SelectPartDialog
          open={openSelectPartDialog}
          onClose={onCloseSelectPartDialog}
          onSubmit={setItemBranchWithMaterial}
        />
      }
      
      {openCreateItemDialog && (
          <CreateItemDialog
            open={openCreateItemDialog}
            onClose={onCloseCreateItemDialog}
            onRefetchData={()=>undefined}
            itemType={'part'}
          />
        )}
    </BasicDialog>
  );
};

PoLineDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

PoLineDialog.defaultProps = {
  open: false,
};

export default PoLineDialog;
