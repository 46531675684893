import PropTypes from "prop-types";

import i18n from "../../../../i18n";

import { Grid, IconButton, Typography, Tooltip } from "@mui/material";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const ItemObserverForm = ({
  isItemObserved = false,
  itemType = "ticket",
  onStartObservingItem,
  onStopObservingItem,
}) => {
  const getLabelForStartObserItem = () =>
    itemType === "ticket" || itemType === "ticket_order"
      ? i18n.t("form.item_observer.start_observing_ticket")
      : null;

  const getLabelForStopObserItem = () =>
    itemType === "ticket" || itemType === "ticket_order"
      ? i18n.t("form.item_observer.stop_observing_ticket")
      : null;

  const getLabelForObserItem = () =>
    itemType === "ticket" || itemType === "ticket_order"
      ? i18n.t("form.item_observer.you_observ_this_ticket")
      : null;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      {isItemObserved && (
        <Grid
          item
          container
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="h6"
            className="capitalize-first-letter"
            color={"var(--brand-green)"}
          >
            {getLabelForObserItem()}
          </Typography>
          <Tooltip title={getLabelForStopObserItem()}>
            <IconButton
              aria-label="delete"
              size="small"
              sx={{ padding: 0, paddingLeft: 0.5, color: "var(--alert)" }}
              onClick={onStopObservingItem}
            >
              <RemoveCircleIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      {!isItemObserved && (
        <Grid
          item
          container
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant="h6" className="capitalize-first-letter">
            {getLabelForStartObserItem()}
          </Typography>
          <IconButton
            aria-label="delete"
            size="small"
            sx={{ padding: 0, paddingLeft: 0.5, color: "var(--primary)" }}
            onClick={onStartObservingItem}
          >
            <AddCircleOutlineOutlinedIcon fontSize="medium" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

ItemObserverForm.propTypes = {
  isPaid: PropTypes.bool,
  showIsPaid: PropTypes.bool,
  isAllowedToChangeIsPaid: PropTypes.bool,
  onChangeIsPaid: PropTypes.func,
  isAccepted: PropTypes.bool,
  statusName: PropTypes.string,
  substatusName: PropTypes.string,
  categoryId: PropTypes.string,
  categoryOptions: PropTypes.array,
  onChangeCategory: PropTypes.func,
  isCategoryAllowedToChange: PropTypes.bool,
  priorityId: PropTypes.string,
  priorityOptions: PropTypes.array,
  onChangePriority: PropTypes.func,
  isPriorityAllowedToChange: PropTypes.bool,
  ticketDescCreator: PropTypes.string,
  orderDescCreator: PropTypes.string,
  itemName: PropTypes.string,
  onChangeOrderStdTimeValue: PropTypes.func,
  isAllowedToChangeItemName: PropTypes.bool,
  orderStdTimeValue: PropTypes.number,
  onChangeOrderStdTimeValue: PropTypes.func,
  isAllowedToChangeOrderStdTimeValue: PropTypes.bool,
  orderType: PropTypes.string,
  orderTypeOptions: PropTypes.array,
  onChangeOrderType: PropTypes.func,
  isAllowedToChangeOrderType: PropTypes.bool,
  itemType: PropTypes.oneOf(["order", "ticket", "task"]),
  isAllowedToChangeTicketDescCreator: PropTypes.bool,
  isAllowedToChangeOrderDescCreator: PropTypes.bool,
  isAllowedToChangeTaskDescCreator: PropTypes.bool,
};

ItemObserverForm.defaultProps = {
  showIsPaid: true,
  isAllowedToChangeTicketDescCreator: false,
  isAllowedToChangeOrderDescCreator: false,
  isAllowedToChangeTaskDescCreator: false,
};

export default ItemObserverForm;
