import i18n from "../../../i18n";

import {
  TEXT_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
} from "../../../helpers/constants";

import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

export const OBJECT_CATEGORY_TABLE_NAME = "object_category_table";
export const OBJECT_SUBCATEGORY_TABLE_NAME = "object_subcategory_table";

export const OBJECT_CATEGORY_TABLE_PREFIX = "object_category_prefix";
export const OBJECT_SUBCATEGORY_TABLE_PREFIX = "object_subcategory_prefix";

export const OBJECT_CATEGORY_TABLE_CONFIG = [
  {
    name: "objcat_code",
    getValueCallback: (rowData) => rowData.objcat_code,
    label: i18n.t("table.object_category.object_category_code"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.object_category_code,
      isObjectOption: true,
      optionLabelKey: "objcat_code",
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
  {
    name: "objcat_name",
    getValueCallback: (rowData) => rowData.objcat_name,
    label: i18n.t("table.object_category.object_category_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.object_category_name,
      isObjectOption: true,
      optionLabelKey: "objcat_name",
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
    ),
    label: i18n.t("edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
];

export const OBJECT_SUBCATEGORY_TABLE_CONFIG = [
  {
    name: "parent__objcat_code",
    getValueCallback: (rowData) => rowData.parent__objcat_code,
    label: i18n.t("table.object_category.object_category_code"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.object_category_code,
      isObjectOption: true,
      optionLabelKey: "objcat_code",
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
  {
    name: "parent__objcat_name",
    getValueCallback: (rowData) => rowData.parent__objcat_name,
    label: i18n.t("table.object_category.object_category_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.object_category_name,
      isObjectOption: true,
      optionLabelKey: "objcat_name",
      addNewValue: false,
      multiple: true,
    }),

    sortable: true,
  },
  {
    name: "objcat_code",
    getValueCallback: (rowData) => rowData.objcat_code,
    label: i18n.t("table.object_category.object_subcategory_code"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.object_subcategory_code,
      isObjectOption: true,
      optionLabelKey: "objcat_code",
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
  {
    name: "objcat_name",
    getValueCallback: (rowData) => rowData.objcat_name,
    label: i18n.t("table.object_category.object_subcategory_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.object_subcategory_name,
      isObjectOption: true,
      optionLabelKey: "objcat_name",
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
    ),
    label: i18n.t("edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
];

export const getTablePropsByName = (tableName) => {
  switch (tableName) {
    case OBJECT_CATEGORY_TABLE_NAME:
      return {
        tableName: OBJECT_CATEGORY_TABLE_NAME,
        tableConfig: OBJECT_CATEGORY_TABLE_CONFIG,
        tableFilterPrefix: OBJECT_CATEGORY_TABLE_PREFIX,
      };
    case OBJECT_SUBCATEGORY_TABLE_NAME:
      return {
        tableName: OBJECT_SUBCATEGORY_TABLE_NAME,
        tableConfig: OBJECT_SUBCATEGORY_TABLE_CONFIG,
        tableFilterPrefix: OBJECT_SUBCATEGORY_TABLE_PREFIX,
      };
    default:
      return undefined;
  }
};
