import PropTypes from "prop-types";
import i18n from "../../../i18n";

import { IconButton } from "@mui/material";

import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import BaseTable from "../../base/BaseTable/BaseTable";
import SelectFieldService from "../../../services/selectFieldService";
import DateService from "../../../services/dateService";

const headers = [
  i18n.t("table.user_filters.filter_name"),
  i18n.t("table.user_filters.filter_is_default"),
  i18n.t("table.user_filters.last_updated"),
  i18n.t("table.user_filters.set"),
  i18n.t("table.user_filters.preview"),
  i18n.t("table.user_filters.edit"),
  i18n.t("table.user_filters.delete"),
];

function prepareData(
  data,
  onClickSet,
  onClickEdit,
  onClickPreview,
  onClickDelete
) {
  let preparedData = [];
  for (const row of data) {
    preparedData.push({
      rowId: row.id,
      data: [
        row.filter_name,
        SelectFieldService.getLabelOptionsTrueOrFalse(row.filter_is_default),
        DateService.convertDatetimeFromBackendToFormatYYYYMMDD(row.updated),
        <IconButton onClick={(e) => onClickSet(row.id)}>
          <ControlPointOutlinedIcon color="primary" />
        </IconButton>,
        <IconButton onClick={(e) => onClickPreview(row.id)}>
          <VisibilityOutlinedIcon color="primary" />
        </IconButton>,
        <IconButton onClick={(e) => onClickEdit(row.id)}>
          <ModeEditOutlineOutlinedIcon color="primary" />
        </IconButton>,
        <IconButton onClick={(e) => onClickDelete(row.id)}>
          <DeleteOutlineOutlinedIcon color="primary" />
        </IconButton>,
      ],
    });
  }
  return preparedData;
}

const UserFilterTable = (props) => {
  return (
    <BaseTable
      showLoader={props.isLoading}
      headers={headers}
      style={props.style}
      rows={prepareData(
        props.userFilterData || [],
        props.onClickSet,
        props.onClickEdit,
        props.onClickPreview,
        props.onClickDelete
      )}
    />
  );
};

UserFilterTable.propTypes = {
  userFilterData: PropTypes.array,
  onClickSet: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickPreview: PropTypes.func,
  onClickDelete: PropTypes.func,
  style: PropTypes.object,
  isLoading: PropTypes.bool,
};

UserFilterTable.defaultProps = {
  isLoading: false,
};

export default UserFilterTable;
