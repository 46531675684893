import React from "react";
import PropTypes from "prop-types";
import { useState, useEffect, useCallback, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import useGuaranteeService from "../../services/guaranteeService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import GuaranteeScopeTable from "../../components/table/GuaranteeScopeTable";
import {
  GUARANTEE_SCOPE_TABLE,
  GUARANTEE_SCOPE_TABLE_FIELDS_CONFIG,
  GUARANTEE_SCOPE_TABLE_FILTER_PREFIX,
} from "./PageTablesConfig";
import useFilterSearchParams from "../../hooks/useFilterSearchParams";

import GuaranteeScopeDialog from "../../components/dialog/GuaranteeScopeDialog";
import LoadingDialog from "../../components/dialog/LoadingDialog/LoadingDialog";
import useDialog from "../../hooks/useDialog";

function GuaranteeScopeSubpage(props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const snackbarAlert = useSnackbarAlert();
  const { t } = useTranslation();

  const [guaranteeScopeDataLocaly, setGuaranteScopeDataLocaly] = useState();
  const [countGuaranteeScopeRecords, setCountGuaranteeScopeRecords] =
    useState();

  const [guaranteeScopeTableSearchParams, setGuaranteeScopeTableSearchParams] =
    useState({});

  const {
    page: guaranteeScopeDataPage,
    pageSize: guaranteeScopeDataPageSize,
    handleChangePageWithSearchParams: handleChangeGuaranteeScopeDataPage,
    handleChangePageSizeWithSearchParams:
      handleChangeGuaranteeScopeDataPageSize,
    resetPageNumber: resetGuaranteeScopePageNumber,
  } = usePaginationWithSearchParams(GUARANTEE_SCOPE_TABLE_FILTER_PREFIX);

  const {
    getGuaranteeScopeData,
    getGuaranteeScopeFilteringData,
    sendGuaranteeScopeManualReminder,
  } = useGuaranteeService();

  const { setNewItemSearchParamsIfAreChanged } =
    useFilterSearchParams(searchParams);

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      GUARANTEE_SCOPE_TABLE_FILTER_PREFIX,
      guaranteeScopeTableSearchParams,
      setGuaranteeScopeTableSearchParams
    );
  }, [searchParams]);

  const guaranteeScopesData = useAsync(
    () => getGuaranteeScopeData(guaranteeScopeTableSearchParams),
    [guaranteeScopeTableSearchParams]
  );

  const guaranteeScopeFilteringData = useAsync(getGuaranteeScopeFilteringData);

  useEffect(() => {
    if (guaranteeScopesData.loading) {
      return;
    }
    setGuaranteScopeDataLocaly(guaranteeScopesData.value.results);
    setCountGuaranteeScopeRecords(guaranteeScopesData.value.count);
  }, [guaranteeScopesData.loading]);

  const handleChangeGuaranteeScopeRowsPerPage = (e) => {
    handleChangeGuaranteeScopeDataPageSize(e, parseInt(e.target.value, 10));
  };

  const [
    openGuaranteeScopeDialog,
    onOpenGuaranteeScopeDialog,
    onCloseGuaranteeScopeDialog,
  ] = useDialog();

  const [
    openSendingReminderLoadingDialog,
    onOpenSendingReminderLoadingDialog,
    onCloseSendingReminderLoadingDialog,
  ] = useDialog();

  const clickedGuaranteeScopeToEdit = useRef();
  const handleClickEditGuaranteeScope = useCallback(
    (e, guaranteeId) => {
      clickedGuaranteeScopeToEdit.current = guaranteeScopeDataLocaly.find(
        (guarantee) => guarantee.id === guaranteeId
      ).id;
      onOpenGuaranteeScopeDialog();
    },
    [guaranteeScopeDataLocaly]
  );

  const fullRefreshTable = () => {
    setGuaranteScopeDataLocaly(undefined);
    guaranteeScopesData.refetch();
  };

  const sendGuaranteeScopeManualReminderFn = useAsyncFn(
    sendGuaranteeScopeManualReminder
  );

  const handleSendGuaranteeScopeManualReminder = (guaranteeScopeId) => {
    onOpenSendingReminderLoadingDialog();
    sendGuaranteeScopeManualReminderFn
      .execute(guaranteeScopeId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.reminder_sended")
        );
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_sending_reminder")
        );
      })
      .finally(() => {
        onCloseSendingReminderLoadingDialog();
      });
  };

  const isLoading =
    guaranteeScopeFilteringData.loading ||
    guaranteeScopeDataLocaly === undefined ||
    countGuaranteeScopeRecords === undefined;

  return (
    <LoaderWrapper showLoader={isLoading}>
      <GuaranteeScopeTable
        data={guaranteeScopeDataLocaly ? guaranteeScopeDataLocaly : []}
        showCheckbox={false}
        countRecords={countGuaranteeScopeRecords}
        page={guaranteeScopeDataPage}
        onPageChange={handleChangeGuaranteeScopeDataPage}
        rowsPerPage={guaranteeScopeDataPageSize}
        onRowsPerPageChange={handleChangeGuaranteeScopeRowsPerPage}
        resetPageNumber={resetGuaranteeScopePageNumber}
        filteringData={guaranteeScopeFilteringData}
        hiddenColumns={
          props.hiddenColumnsForTables
            ? props.hiddenColumnsForTables[GUARANTEE_SCOPE_TABLE]
            : []
        }
        showTitle={false}
        tableConfig={GUARANTEE_SCOPE_TABLE_FIELDS_CONFIG}
        filterPrefix={GUARANTEE_SCOPE_TABLE_FILTER_PREFIX}
        showContextMenu={false}
        onClickEdit={handleClickEditGuaranteeScope}
        onClickSendReminderManualy={handleSendGuaranteeScopeManualReminder}
      />
      {openGuaranteeScopeDialog && (
        <GuaranteeScopeDialog
          guaranteeScopeId={clickedGuaranteeScopeToEdit.current}
          open={openGuaranteeScopeDialog}
          onClose={onCloseGuaranteeScopeDialog}
          onSubmit={fullRefreshTable}
        />
      )}
      {openSendingReminderLoadingDialog && (
        <LoadingDialog
          dialogTitle={t("page.guarantee_page.reminder_is_sending")}
          open={openSendingReminderLoadingDialog}
        />
      )}
    </LoaderWrapper>
  );
}

GuaranteeScopeSubpage.propTypes = {
  hiddenColumnsForTables: PropTypes.object,
  columnsOrdersForTables: PropTypes.object,
};

export default GuaranteeScopeSubpage;
