/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
  auth: {
    clientId: "4af0d08b-9cfa-4f32-a590-19a9f6d93383", // TicketApp-Frontent App ID in Azure AD.
    authority:
      "https://login.microsoftonline.com/0890753e-b61a-4f88-bb8f-5d27e1306a5f", // https://login.microsoftonline.com/${AZURE_TENANT_ID}"
    // redirectUri: "/login/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/login/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const loginRequest = {
  // TicketApp-Backend App ID in Azure AD
  scopes: ["api://0f8c5df0-0a0d-404d-bfc8-c5b57de58cb5/read"],
};
