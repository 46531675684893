import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useTranslation } from "react-i18next";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";

const BoardForm = ({
  index,
  board,
  onEditBoard,
  onRemoveBoard,
  readOnly }
) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={readOnly ? 9 : 7.8} onClick={() => onEditBoard(board?.id)}>
        <TextFieldFormControl
          value={board?.full_name}
          label={board?.board_role}
          readOnly = {readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <TrueFalseSelectField
          value={board?.has_mysolvy_account}
          valuesAsBool = {true}
          label={"MySolvy"}
          readOnly = {true}
        />
      </Grid>
      {!readOnly &&
        <Grid item xs={0.5}>
          <IconButtonWithTooltip
            onClick={(e) => {
              e.stopPropagation();
              onRemoveBoard(board?.id, index)
            }}
            title={t("other.board_form_box.remove_board")}
          >
            <RemoveCircleIcon color="secondary" />
          </IconButtonWithTooltip>
        </Grid>
      }
    </>
  );
};

BoardForm.propTypes = {
  board: PropTypes.object,
  onBoardChange: PropTypes.func,
  onBoardRemove: PropTypes.func
};

BoardForm.defaultProps = {
};

export default BoardForm;
