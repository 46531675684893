import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import PropTypes from "prop-types";
import "./SnackbarAlert.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarAlert(props) {
  const { open, onClose, severity, alertMessage, autoHideDuration } = props;
  var [vertical, horizontal] = ["bottom", "right"];
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ width: "100%", whiteSpace: 'pre-line'  }}
        className="capitalize-first-letter"
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
}

SnackbarAlert.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  severity: PropTypes.string,
  alertMessage: PropTypes.string,
  autoHideDuration: PropTypes.number,
};

SnackbarAlert.defaultProps = {
  autoHideDuration: 6000,
};
