import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import RRAdminSearchBar from "../../../bar/RRAdminSearchBar/RRAdminSearchBar";
import RRAdminObjectTenantTable from "./RRAdminObjectTenantTable";
import RRAdminTenantTable from "./RRAdminTenantTable/";
import RRAdminUserTenantTable from "./RRAdminUserTenantTable";

import { TENANT_TABLE_FILTER_PREFIX } from "./RRAdminTenantTable/TableConfig";
import { USER_TENANT_TABLE_FILTER_PREFIX } from "./RRAdminUserTenantTable/TableConfig";
import { OBJECT_TENANT_TABLE_FILTER_PREFIX } from "./RRAdminObjectTenantTable/TableConfig";

const TABLE_FILTER_PREFIXES = [
  TENANT_TABLE_FILTER_PREFIX,
  OBJECT_TENANT_TABLE_FILTER_PREFIX,
  USER_TENANT_TABLE_FILTER_PREFIX,
];

const SEARCH_BAR_FILTER_PREFIX = "search_bar";

const LOCATION_SEARCH_MAX_LEVEL = 3;

const RRAdminLocationsTab = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="flex-start"
      alignItems="strech"
      rowSpacing={2}
      columnSpacing={2}
    >
      <Grid item xs={12}>
        <RRAdminSearchBar
          pageName={props.pageName}
          tableFilterPrefixes={TABLE_FILTER_PREFIXES}
          locationSearchMaxLevel={LOCATION_SEARCH_MAX_LEVEL}
          filterPrefix={SEARCH_BAR_FILTER_PREFIX}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <RRAdminObjectTenantTable
          pageName={props.pageName}
          locationLastLevel={LOCATION_SEARCH_MAX_LEVEL}
          searchPrefixes={[SEARCH_BAR_FILTER_PREFIX, ...TABLE_FILTER_PREFIXES]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <RRAdminTenantTable
          pageName={props.pageName}
          searchPrefixes={[SEARCH_BAR_FILTER_PREFIX, ...TABLE_FILTER_PREFIXES]}
        />
      </Grid>
      <Grid item xs={12}>
        <RRAdminUserTenantTable pageName={props.pageName} />
      </Grid>
    </Grid>
  );
};

RRAdminLocationsTab.propTypes = {
  readOnly: PropTypes.bool,
  pageName: PropTypes.string,
};

RRAdminLocationsTab.defaultProps = {
  readOnly: false,
};

export default RRAdminLocationsTab;
