import Grid from "@mui/material/Grid";
import TitleTypography from "../../base/TitleTypography/TitleTypography";

import "../../../index.css";

export default function LogoBar() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className="margin-bottom-medium"
    >
      <Grid item sm={2} xs={5}>
        {/* TODO Place to put image */}
        {/* <img className="" src={pkbx_logo} alt="" height={43} /> */}
      </Grid>
      <Grid item sm={8} xs={2} />
      <Grid item sm={2} xs={5}>
        <TitleTypography titleKey={"pom"} />
      </Grid>
    </Grid>
  );
}
