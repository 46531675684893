import { useState } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {  useSearchParams } from "react-router-dom";

function FullWidthTabs(props) {
  const [value, setValue] = useState("0");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(props.cleanFilters){
      setSearchParams({})
    }
  };

  return (
    <Box sx={{ width: "100%", marginTop: 1 }}>
      <TabContext value={value}>
        <TabList onChange={handleChange} sx={{ marginBottom: 1 }}>
          {props.items.map((item, index) => (
            <Tab
              key={index}
              label={item.label}
              value={index.toString()}
              sx={{ fontWeight: "bold" }}
            />
          ))}
        </TabList>
        {props.items.map((item, index) => (
          <TabPanel key={index} sx={{ padding: 0 }} value={index.toString()}>
            {item.content}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}

FullWidthTabs.propTypes = {
  cleanFilters : PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.element,
    })
  ),
};

FullWidthTabs.defaultProps = {
  cleanFilters: false,
};

export default FullWidthTabs;
