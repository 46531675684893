import React from "react";
import { useRef } from "react";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import DateTimeField from "../../../field/DateTimeField";

import FieldWithLabel from "../../../field/FieldWithLabel";
import i18n from "../../../../i18n";

const TaskDateTimeForm = (props) => {
  const formValueRef = useRef(props.formValue);
  const onChangeFormValueLocaly = (e) => {
    formValueRef.current = {
      ...formValueRef.current,
      [e.target.name]: e.target.value.toISOString(),
    };
  };

  const onClose = async (e) => {
    e.target.value = formValueRef.current[e.target.name];
    props.onChange(e);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.task.task_date_startT_plan")}
          field={
            <DateTimeField
              name="task_date_startT_plan"
              value={formValueRef.current.task_date_startT_plan}
              showClearIcon={false}
              readOnly={props.readOnly}
              onChange={onChangeFormValueLocaly}
              onClose={onClose}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.task.task_date_stopT_plan")}
          field={
            <DateTimeField
              name="task_date_stopT_plan"
              value={formValueRef.current.task_date_stopT_plan}
              minDateTime={
                formValueRef.current.task_date_startT_plan
                  ? new Date(formValueRef.current.task_date_startT_plan)
                  : undefined
              }
              showClearIcon={false}
              readOnly={props.readOnly}
              onChange={onChangeFormValueLocaly}
              onClose={onClose}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.task.task_date_startT_real")}
          field={
            <DateTimeField
              name="task_date_startT_real"
              value={formValueRef.current.task_date_startT_real}
              showClearIcon={false}
              readOnly={true}
              onChange={onChangeFormValueLocaly}
              onClose={onClose}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.task.task_date_stopT_real")}
          field={
            <DateTimeField
              name="task_date_stopT_real"
              value={formValueRef.current.task_date_stopT_real}
              showClearIcon={false}
              readOnly={true}
              onChange={onChangeFormValueLocaly}
              onClose={onClose}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

TaskDateTimeForm.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

TaskDateTimeForm.defaultProps = {
  readOnly: false,
};

export default TaskDateTimeForm;
