import { useEffect, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";

import { useForm } from "../../../hooks/useForm";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";

import useGuaranteeService from "../../../services/guaranteeService";
import GuaranteeScopesForm from "../../form/GuaranteeScopesForm";

import useLocationService from "../../../services/locationService";
import LocationRecords from "../../other/LocationRecords";

import GuaranteeForm from "../../form/GuaranteeForm";

import BasicDialog from "../../base/BasicDialog";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { isEmptyValue } from "../../../helpers/methods";
import BaseBox from "../../base/BaseBox/baseBox";
import { Typography } from "@mui/material";

import useDialog from "../../../hooks/useDialog";
import SubcontractorDetailsDialog from "../SubcontractorDetailsDialog/SubcontractorDetailsDialog";

const requiredGuaranteeFields = [
  "owner",
  "subcontractor",
  "guarantee_kind",
  "guarantee_sector",
  "guarantee_value",
  "guarantee_date_contract",
  "guarantee_date_protocol",
  "guarantee_scope_installation",
  "guarantee_warranty",
];

const requiredGuaranteeScopeFields = [
  "guascope_date_close",
  "guascope_kind",
  "guascope_responsible",
];

const allGuaranteeScopeFields = [
  ...requiredGuaranteeScopeFields,
  "guascope_desc",
];

const GuaranteeDialog = (props) => {
  const { t } = useTranslation();

  const snackbarAlert = useSnackbarAlert();

  const {
    getChosenHighestLevelObject,
    prepareLocationDataFromBackendForLocationRecordsComponent,
    getEmptyLocationElement,
  } = useLocationService();

  const {
    getGuaranteeDetailsData,
    getGuaranteeFilteringData,
    createOrUpdateGuarantee,
    getEmptyGuaranteeScope,
  } = useGuaranteeService();

  const guaranteeDetailsData = useAsync(() => {
    if (!props.guaranteeId)
      return Promise.resolve({
        locations: undefined,
        guarantee_scopes: [getEmptyGuaranteeScope()],
      });
    return getGuaranteeDetailsData(props.guaranteeId);
  }, [props.guaranteeId]);

  const guaranteeFilteringData = useAsync(getGuaranteeFilteringData);

  const createOrUpdateGuaranteeFn = useAsyncFn(createOrUpdateGuarantee);

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm(undefined);

  const {
    formValue: guaranteeScopes,
    setFormValue: setGuaranteeScopes,
    onChangeInArrayForm: onChangeGuaranteeScopesInArray,
    onChangeDateInArrayForm: onChangeGuaranteeScopesDateInArray,
    onChangeAutocompleteFieldWithObjectOptionsInArrayForm:
      onChangeGuaranteeScopesAutocompleteFieldWithObjectOptionsInArray,
  } = useForm(undefined);

  const [locations, setLocations] = useState(undefined);

  useEffect(() => {
    if (guaranteeDetailsData.loading) return;

    const { locations, guarantee_scopes, ...formValue } =
      guaranteeDetailsData.value;

    setLocations(
      prepareLocationDataFromBackendForLocationRecordsComponent(locations)
    );
    setGuaranteeScopes(guarantee_scopes);
    setFormValue(formValue);
  }, [guaranteeDetailsData.loading]);

  const [
    openSuboctractorDialog,
    onOpenSubcontractorDialog,
    onCloseSubcotractorDialog,
  ] = useDialog();

  const isFormValid = () => {
    for (const location of locations) {
      if (isEmptyValue(location[0]?.id)) {
        return false;
      }
    }

    for (const fieldName of requiredGuaranteeFields) {
      if (isEmptyValue(formValue[fieldName])) {
        return false;
      }
    }

    for (const guaranteeScope of guaranteeScopes) {
      for (const fieldName of requiredGuaranteeScopeFields)
        if (isEmptyValue(guaranteeScope[fieldName])) {
          return false;
        }
    }

    return true;
  };

  const getDialogTitle = () => {
    if (props.guaranteeId) {
      let title = props.readOnly
        ? t("dialog.guarantee_dialog.guarantee")
        : t("dialog.guarantee_dialog.update_guarantee");
      return `${title} ${formValue?.guarantee_nr || ""}`;
    }
    return t("dialog.guarantee_dialog.create_guarantee");
  };

  const isLoading =
    guaranteeDetailsData.loading ||
    guaranteeFilteringData.loading ||
    formValue === undefined ||
    guaranteeScopes === undefined ||
    locations === undefined;

  const prepareDataToSend = () => {
    let objects = [];
    for (const locationLevels of locations) {
      let lastLocationObjectId = getChosenHighestLevelObject(locationLevels);
      objects.push(lastLocationObjectId);
    }

    let preparedGuaranteeScopes = [];
    for (const gs of guaranteeScopes) {
      if (!gs.id) preparedGuaranteeScopes.push(gs);
      else {
        const gsOld = guaranteeDetailsData.value.guarantee_scopes.find(
          (_gs) => _gs.id === gs.id
        );
        for (const fieldName of allGuaranteeScopeFields) {
          if (gs[fieldName] !== gsOld[fieldName]) {
            preparedGuaranteeScopes.push({ ...gs, is_edited: true });
          }
        }
        preparedGuaranteeScopes.push(gs);
      }
    }
    for (const gs of guaranteeDetailsData.value.guarantee_scopes) {
      if (!guaranteeScopes.find((_gs) => _gs.id === gs.id))
        preparedGuaranteeScopes.push({ ...gs, is_deleted: true });
    }

    return {
      ...formValue,
      objects,
      guarantee_scopes: preparedGuaranteeScopes,
    };
  };

  const onSubmit = () => {
    createOrUpdateGuaranteeFn
      .execute(props.guaranteeId, prepareDataToSend())
      .then((res) => {
        if (res.status === 201)
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.guarantee_added")
          );
        else
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.guarantee_updated")
          );
        props.onSubmit();
        props.onClose();
      })
      .catch((error) => {
        if (error.status === 400) {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_saving_changes")
          );
        } else {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.server_error")
          );
        }
      });
  };

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12}>
          <BaseBox>
            <LocationRecords
              locations={locations}
              onChangeLocations={setLocations}
              readOnly={props.readOnly}
            />
          </BaseBox>
        </Grid>
        <Grid item xs={12}>
          <BaseBox>
            <GuaranteeForm
              formValue={formValue}
              filteringData={guaranteeFilteringData.value}
              onChange={onChange}
              onChangeDate={onChangeDate}
              onChangeAutocompleteFieldWithObjectOptions={
                onChangeAutocompleteFieldWithObjectOptions
              }
              readOnly={props.readOnly}
              onOpenSubcontractorDialog={onOpenSubcontractorDialog}
            />
          </BaseBox>
        </Grid>

        <Grid item xs={12}>
          <BaseBox>
            <Typography className="capitalize-first-letter" variant="h6">
              {t("dialog.guarantee_dialog.guarantee_scopes")}
            </Typography>
            <GuaranteeScopesForm
              guaranteeScopes={guaranteeScopes}
              onChange={onChangeGuaranteeScopesInArray}
              onChangeAutocompleteFieldWithObjectOptions={
                onChangeGuaranteeScopesAutocompleteFieldWithObjectOptionsInArray
              }
              onChangeDate={onChangeGuaranteeScopesDateInArray}
              onChangeGuaranteeScopeArray={setGuaranteeScopes}
              readOnly={props.readOnly}
            />
          </BaseBox>
        </Grid>
        {!props.readOnly && (
          <Grid item xs={12}>
            <LoadingButton
              sx={{ marginTop: "20px" }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={onSubmit}
              loading={createOrUpdateGuaranteeFn.loading}
              disabled={props.readOnly || !isFormValid()}
            >
              {t("save")}
            </LoadingButton>
          </Grid>
        )}
        {openSuboctractorDialog && (
          <SubcontractorDetailsDialog
            open={openSuboctractorDialog}
            onClose={onCloseSubcotractorDialog}
            readOnly={props.readOnly}
            onRefetchData={guaranteeFilteringData.refetch}
          />
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      titleAlign="center"
      title={getDialogTitle()}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

GuaranteeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  guaranteeId: PropTypes.string,
  readOnly: PropTypes.bool,
};

GuaranteeDialog.defaultProps = {
  readOnly: false,
};

export default GuaranteeDialog;
