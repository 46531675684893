import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import GuaranteeScopeForm from "../GuaranteeScopeForm";
import useGuaranteeService from "../../../services/guaranteeService";
import { useAsync } from "../../../hooks/useAsync";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

const GuaranteeScopesForm = React.memo((props) => {
  const { getEmptyGuaranteeScope, getGuaranteeScopeFilteringData } =
    useGuaranteeService();

  const guaranteeScopeFilteringData = useAsync(getGuaranteeScopeFilteringData);

  const onChange = (index) => {
    return (e) => props.onChange(index, e);
  };

  const onChangeDate = (index) => {
    return (e) => props.onChangeDate(index, e);
  };

  const onChangeAutocompleteFieldWithObjectOptions = (index) => {
    return (...inputProps) =>
      props.onChangeAutocompleteFieldWithObjectOptions(index, ...inputProps);
  };
  const onDeleteGuaranteeScope = (index) => {
    let guaranteeScopes = [...props.guaranteeScopes];
    guaranteeScopes.splice(index, 1);
    props.onChangeGuaranteeScopeArray(guaranteeScopes);
  };

  const onAddGuarnateeScope = () => {
    let guaranteeScopes = [...props.guaranteeScopes];
    guaranteeScopes.push(getEmptyGuaranteeScope());
    props.onChangeGuaranteeScopeArray(guaranteeScopes);
  };

  const isLoading = guaranteeScopeFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid container spacing={0.5}>
      {props.guaranteeScopes.map((guaranteeScope, index) => {
        return (
          <Grid item xs={12} key={index}>
            <GuaranteeScopeForm
              readOnly={props.readOnly}
              index={index}
              formValue={guaranteeScope}
              filteringData={guaranteeScopeFilteringData.value}
              onChange={onChange(index)}
              onChangeAutocompleteFieldWithObjectOptions={onChangeAutocompleteFieldWithObjectOptions(
                index
              )}
              onChangeDate={onChangeDate(index)}
              onAddGuarnateeScope={onAddGuarnateeScope}
              onDeleteGuaranteeScope={onDeleteGuaranteeScope}
              showButtonAddGuarnateeScope={!props.readOnly && index === 0}
              showButtonDeleteGuaranteeScope={
                !props.readOnly && props.guaranteeScopes.length > 1
              }
              showScopeNumber={props.guaranteeScopes.length > 1}
              showFormWithButtonsAndTitle={true}
            />
          </Grid>
        );
      })}
    </Grid>
  );
});
GuaranteeScopesForm.propTypes = {
  guaranteeScopes: PropTypes.array,
  onChange: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  onChangeGuaranteeScopeArray: PropTypes.func,
  readOnly: PropTypes.bool,
};

GuaranteeScopesForm.defaultProps = {
  readOnly: false,
};

export default GuaranteeScopesForm;
