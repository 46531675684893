export const RcpScheduleTableCell = ({
  bgColor,
  fnColor,
  value,
  style,
} = {}) => {
  return (
    <div
      style={{
        background: bgColor,
        color: fnColor,
        border: "1px solid black",
        width: "100%",
        height: "100%",
        whiteSpace: "break-spaces",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      {value}
    </div>
  );
};
