import { useState, useEffect, useMemo, useRef, useCallback } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { NAVIGATION_DRAWER_TYPE_REPORTS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import useOfferService from "../../services/offerService";
import { useAsync } from "../../hooks/useAsync";

import OfferTable from "../../components/table/OfferTable/OfferTable";
import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";
import OfferChartToolBar from "../../components/bar/OfferChartToolBar";

import TableService from "../../services/tableService";

import ChartService from "../../services/chartService";
import DateService from "../../services/dateService";
import VerticalBarChart from "../../components/charts/VerticalBarChart/VerticalBarChart";

import CollapseOrExpandButton from "../../components/other/CollapseOrExpandButton";

import { exportOfferToFileUrl } from "../../helpers/apiUrls";
import { getObjectFromSearchParams } from "../../helpers/methods";

import DunReportsNavigationBar from "../../components/bar/DunReportsNavigationBar";
import DunReportsNavigationOffersSubBar from "../../components/bar/DunReportsNavigationBar/DunReportsNavigationOffersSubBar";

import { OFFER_TABLE_FIELDS_CONFIG, OFFER_TABLE_NAME } from "./TableConfig";
import PieChart from "../../components/charts/PieChart/PieChart";

const TABLE_CONFIGS = [
  {
    name: OFFER_TABLE_NAME,
    config: OFFER_TABLE_FIELDS_CONFIG,
  },
];

export const CHART_TYPE_BAR = "bar_chart";
export const CHART_TYPE_PIE = "pie_chart";

export default function OfferReportBasePage(props) {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams();

  const { getOfferDistribution, getOffersFilteringData } = useOfferService();

  const offerData = useAsync(() => {
    let tempSearchParams = {
      ...getObjectFromSearchParams(searchParams),
    };
    tempSearchParams.chart_type = props.chartType;
    tempSearchParams.group_by_key = props.groupBy;
    tempSearchParams.label_by_key =
      props.labelBy || tempSearchParams.label_by_key;

    tempSearchParams.hours_timezone_offset =
      DateService.getHoursTimezoneOffset();
    return getOfferDistribution(tempSearchParams);
  }, [searchParams]);

  const offerFilteringData = useAsync(() =>
    getOffersFilteringData({ include_objects_level_2: true })
  );

  const [offerDataLocaly, setOfferDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  const [offerDistribution, setOfferDistribution] = useState();
  const totalIndicator = offerDistribution?.total_indicator;
  const isMultipleGroupsChart = offerDistribution?.is_multiple_groups_chart;

  useEffect(() => {
    if (offerData.loading) {
      setOfferDistribution(undefined);
      return;
    }
    setOfferDataLocaly(offerData.value.results);
    setOfferDistribution(offerData.value.offers_distribution);
    setCountRecords(offerData.value.count);
  }, [offerData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] =
    useState(undefined);
  const [columnsOrdersForTables, setColumnsOrdersForTables] =
    useState(undefined);

  const refreshTable = () => {
    setOfferDataLocaly(undefined);
    setOfferDistribution(undefined);
    offerData.refetch();
  };

  const offerExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      OFFER_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[OFFER_TABLE_NAME],
      columnsOrdersForTables?.[OFFER_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[OFFER_TABLE_NAME],
    columnsOrdersForTables?.[OFFER_TABLE_NAME],
    OFFER_TABLE_FIELDS_CONFIG,
  ]);

  const getExportSearchParams = () => {
    const tempSearchParams = { ...getObjectFromSearchParams(searchParams) };
    return tempSearchParams;
  };

  const getExtraButtonListMdGrid = () => {
    return [0.5];
  };

  const [chartIsFullScreen, setChartFullScreen] = useState(false);

  const getUniveralToolBarExtraButtonList = () => {
    return [
      <CollapseOrExpandButton
        isExpanded={chartIsFullScreen}
        onChangeIsExpanded={setChartFullScreen}
      />,
    ];
  };

  const isLoading = offerDataLocaly === undefined || offerFilteringData.loading;

  return (
    <NavigationDrawer
      pageName={props.pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_REPORTS}
    >
      <DefaultPageWrapper titleKey={props.pageTitleKey}>
        <LoaderWrapper showLoader={isLoading}>
          <Grid
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={1}
          >
            <Grid item xs={12}>
              <DunReportsNavigationBar
                multipleLocationKey={"/offers_per_location_report/"}
              />
            </Grid>
            <Grid item xs={12}>
              <DunReportsNavigationOffersSubBar />
            </Grid>
            <Grid item xs={12}>
              <OfferChartToolBar
                pageName={props.pageName}
                showSelectObjectLevel2={props.showSelectObjectLevel2}
                showSelectTenant={props.showSelectTenant}
                showSelectReportType={props.showSelectReportType}
                showSelectOfferStatus={props.showSelectOfferStatus}
                tableFilteringData={offerFilteringData}
                tableFieldConfigs={OFFER_TABLE_FIELDS_CONFIG}
              />
            </Grid>
            {offerDistribution && (
              <Grid
                item
                xs={12}
                style={{ height: chartIsFullScreen ? "67vh" : "40vh" }}
              >
                {props.chartType === CHART_TYPE_BAR && (
                  <VerticalBarChart
                    data={{
                      labels: offerDistribution.labels,
                      datasets:
                        ChartService.prepareDataForSingleOrMultiGroupsChart(
                          offerDistribution.data,
                          isMultipleGroupsChart
                        ),
                    }}
                    legendDisplay={isMultipleGroupsChart}
                    titleDisplay={false}
                    dataLabelsDisplay={true}
                  />
                )}
                {props.chartType === CHART_TYPE_PIE && (
                  <PieChart
                    data={{
                      labels: offerDistribution.labels,
                      datasets:
                        ChartService.prepareDataForSingleOrMultiGroupsChart(
                          offerDistribution.data,
                          isMultipleGroupsChart
                        ),
                    }}
                    dataLabelsUnit={props.chartDataLabelsUnit}
                    legendDisplay={props.showChartLegend}
                    dataLabelsFormater={props.dataLabelsFormater}
                    titleDisplay={false}
                  />
                )}
                {props.showTotalIndicator && (
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {totalIndicator?.label}
                    {": "}
                    {totalIndicator?.value}
                  </Typography>
                )}
              </Grid>
            )}
            <Grid item xs={12} marginY={1}>
              <UniversalToolBarWithDialogs
                pageName={props.pageName}
                tableConfig={TABLE_CONFIGS}
                filteringData={offerFilteringData}
                refreshTable={refreshTable}
                resetPageNumber={resetPageNumber}
                hiddenColumnsForTables={hiddenColumnsForTables}
                setHiddenColumnsForTables={setHiddenColumnsForTables}
                columnsOrdersForTables={columnsOrdersForTables}
                setColumnsOrdersForTables={setColumnsOrdersForTables}
                showMassActionButton={false}
                showCreateButton={false}
                showOpenCloseFilterButton={false}
                showCleanfiltersButton={false}
                showMyFiltersButton={false}
                showSearchButton={false}
                extraButtonList={getUniveralToolBarExtraButtonList()}
                extraButtonListMdGrid={getExtraButtonListMdGrid()}
              />
            </Grid>
            <Grid item xs={12}>
              {hiddenColumnsForTables &&
                columnsOrdersForTables &&
                !chartIsFullScreen && (
                  <OfferTable
                    showCleanFilterIcon={false}
                    showFilters={true}
                    showDetailsIcon={true}
                    data={offerDataLocaly}
                    filteringData={offerFilteringData}
                    countRecords={countRecords}
                    page={page}
                    onPageChange={handleChangePageWithSearchParams}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    resetPageNumber={resetPageNumber}
                    tableConfig={OFFER_TABLE_FIELDS_CONFIG}
                    hiddenColumns={hiddenColumnsForTables[OFFER_TABLE_NAME]}
                    columnsOrders={columnsOrdersForTables[OFFER_TABLE_NAME]}
                    style={{ maxHeight: "65vh" }}
                    showExportToFileButton={true}
                    exportToFileUrl={exportOfferToFileUrl}
                    exportToFileSearchParams={getExportSearchParams()}
                    exportToFileHeaders={offerExportHeaders}
                    exportToFileFileName={`${t("headers.offers").replace(
                      " ",
                      "_"
                    )}.xlsx`}
                    showFavorite={false}
                    dataKeyToRunDetailsMethod={"ticket_id"}
                    itemDetailsLinkPrefix={"ticket_details"}
                  />
                )}
            </Grid>
          </Grid>
        </LoaderWrapper>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}

OfferReportBasePage.propTypes = {
  chartType: PropTypes.string,
  totalIndicatorLabel: PropTypes.string,
  groupBy: PropTypes.string,
  labelBy: PropTypes.string,
  pageName: PropTypes.string,
  pageTitleKey: PropTypes.string,
  showSelectObjectLevel2: PropTypes.bool,
  showSelectTenant: PropTypes.bool,
  showSelectReportType: PropTypes.bool,
  showSelectOfferStatus: PropTypes.bool,
  showTotalIndicator: PropTypes.bool,
  chartDataLabelsUnit: PropTypes.string,
};
