import { Button, Grid, Typography } from "@mui/material";
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseBox from "../../base/BaseBox/baseBox";
import PhoneForm from "../../form/PhoneForm";

export default function PhoneFormBox({ phones, onEditPhone, onAddPhone, onRemovePhone, readOnly, subcontractorId }) {
    const { t } = useTranslation();

    return (
        <BaseBox>
            <Grid container spacing={1}>
                <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
                    <Typography
                        variant="subtitle2"
                        className="capitalize-first-letter"
                    >
                        {subcontractorId ? t(`other.phone_form_box.subcontractor_phones`) : t(`other.phone_form_box.owner_phones`)}
                    </Typography>
                </Grid>
                <Grid item sm={6} xs={6} />
                {phones?.map((phone, index) => {
                    return (
                        <PhoneForm
                            phone={phone}
                            index={index}
                            onEditPhone={onEditPhone}
                            onRemovePhone={onRemovePhone}
                            readOnly={readOnly}
                            subcontractorId ={subcontractorId }
                        />
                    )
                })
                }
                <Grid item xs={12}>
                    
                {!readOnly &&
                    <Button
                        fullWidth
                        variant="text"
                        size="small"
                        onClick={onAddPhone}
                    >
                        {t("other.phone_form_box.add_phone")}
                    </Button>}
                </Grid>
            </Grid>
        </BaseBox>
    );
}

PhoneFormBox.propTypes = {
    phones : PropTypes.array,
    onEditPhone : PropTypes.func,
    onAddPhone : PropTypes.func,
    onRemovePhone : PropTypes.func,
    readOnly: PropTypes.bool
}


PhoneFormBox.defaultProps = {
    phones : [],
    readOnly : true
}
