import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import ChooseLanguage from "../../base/ChooseLanguage";
import { OliviaLogo } from "../../../media/logo";
import hasUserPermission, { isAADUser } from "../../../helpers/userPermissions";
import { useAuth } from "../../../context/auth";
import { useNavigate } from "react-router-dom";

import { isAppTesting } from "../../../helpers/methods";
import { APP_STAGE_BAR_HEIGHT } from "../../../helpers/constants";

import PropTypes from "prop-types";

export const openMiniDrawerWidth = 240;
export const closeMiniDrawerWidth = 65;

const openedMixin = (theme) => ({
  // transition: theme.transitions.create("width", {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.enteringScreen,
  // }),
  overflowX: "hidden",
  width: openMiniDrawerWidth,
});

const closedMixin = (theme) => ({
  // transition: theme.transitions.create(["margin", "width"], {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),
  overflowX: "hidden",
  width: closeMiniDrawerWidth,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: openMiniDrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const ActionIconButton = ({
  label,
  icon,
  callback,
  isOpenDrawer,
  isActive,
  disabled = false,
}) => {
  return (
    <ListItem
      disablePadding
      sx={{
        display: "block",
        color: isActive ? "var(--primary) !important" : null,
      }}
    >
      <Tooltip title={!isOpenDrawer ? label : ""} placement={"left"}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: isOpenDrawer ? "initial" : "center",
            px: 2.5,
          }}
          onClick={callback}
          disabled={disabled}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isOpenDrawer ? 3 : "auto",
              justifyContent: "center",
              color: isActive ? "var(--primary) !important" : null,
            }}
          >
            {icon}
          </ListItemIcon>

          <ListItemText
            primary={label}
            sx={{ display: isOpenDrawer ? "block" : "none" }}
            primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
          />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};

export default function MiniDrawer(props) {
  const { t } = useTranslation();

  const [openDrawer, setOpenDrawer] = React.useState(() =>
    !props.canHideDrawer ? true : props.initialOpen
  );
  const toggleDrawer = () => {
    setOpenDrawer((prev) => !prev);
  };

  const width = openDrawer
    ? `calc(100% - ${openMiniDrawerWidth}px)`
    : `calc(100% - ${closeMiniDrawerWidth}px)`;

  const { user } = useAuth();
  const navigate = useNavigate();
  const getLogoButton = () => {
    const logo = (
      <img
        className=""
        src={OliviaLogo}
        alt=""
        height={43}
        style={{ marginInline: "auto" }}
      />
    );

    if (!isAADUser(user)) {
      return logo;
    }

    return (
      <IconButton
        onClick={() => navigate("/")}
        style={{ marginInline: "auto" }}
      >
        <Tooltip
          title={openDrawer ? t("drawer.mini_drawer.starting_panel") : ""}
          placement={"left"}
        >
          {logo}
        </Tooltip>
      </IconButton>
    );
  };

  return (
    <Grid container>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={openDrawer}
        PaperProps={{
          sx: {
            marginTop: isAppTesting() ? APP_STAGE_BAR_HEIGHT : undefined,
          },
        }}
      >
        <DrawerHeader>
          {openDrawer && getLogoButton()}

          {props.canHideDrawer && (
            <IconButton onClick={toggleDrawer}>
              {openDrawer === false ? (
                <ChevronRightIcon color={"primary"} />
              ) : (
                <ChevronLeftIcon color={"primary"} />
              )}
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />
        {openDrawer && props.showLangugeOptions && (
          <>
            <ChooseLanguage position={"relative"} />
            <Divider />
          </>
        )}
        {props.showTopDrawerActionIcons && (
          <>
            <List>
              {props.topDrawerActionIcons.map((content, index) => (
                <ActionIconButton
                  key={index}
                  isOpenDrawer={openDrawer}
                  {...content}
                />
              ))}
            </List>
            <Divider />
          </>
        )}
        {props.showMiddleDrawerActionIcons && (
          <>
            <List>
              {props.middleDrawerActionIcons.map((content, index) => (
                <ActionIconButton
                  key={index}
                  isOpenDrawer={openDrawer}
                  {...content}
                />
              ))}
            </List>
            <Divider />
          </>
        )}
        {props.showBottomDrawerActionIcons && (
          <List>
            {props.bottomDrawerActionIcons.map((content, index) => (
              <ActionIconButton
                key={index}
                isOpenDrawer={openDrawer}
                {...content}
              />
            ))}
          </List>
        )}
      </Drawer>
      <div style={{ width: width }}>{props.children}</div>
    </Grid>
  );
}

MiniDrawer.propTypes = {
  showTopDrawerActionIcons: PropTypes.bool,
  showLangugeOptions: PropTypes.bool,
  topDrawerActionIcons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.element,
      callback: PropTypes.func,
      isActive: PropTypes.bool,
    })
  ),
  showMiddleDrawerActionIcons: PropTypes.bool,
  middleDrawerActionIcons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.element,
      callback: PropTypes.func,
      isActive: PropTypes.bool,
    })
  ),
  showBottomDrawerActionIcons: PropTypes.bool,
  bottomDrawerActionIcons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.element,
      callback: PropTypes.func,
      isActive: PropTypes.bool,
    })
  ),
  canHideDrawer: PropTypes.bool,
  initialOpen: PropTypes.bool,
};

MiniDrawer.defaultProps = {
  showTopDrawerActionIcons: true,
  showMiddleDrawerActionIcons: true,
  showBottomDrawerActionIcons: true,
  canHideDrawer: true,
  initialOpen: false,
  showLangugeOptions: true,
};
