import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import {
  messageUpdateUrl,
  messagesForTicketUrl,
  messagesForOrderUrl,
  messagesForTicketIcludeOrdersUrl,
} from "../helpers/apiUrls";
import { prepareUrlWithQueryParams } from "../helpers/methods";

export default function useCommunicationService() {
  const axiosPrivate = useAxiosPrivate();

  const getMessagesForItemData = (
    itemId,
    searchParams = {},
    itemType = "ticket"
  ) => { 
    if (itemType === "ticket")
      return getMessagesForTicketInludesOrdersData(itemId, searchParams);
    if (itemType === "order")
      return getMessagesForOrderData(itemId, searchParams);
    if (itemType === "ticket_order")
      return getMessagesForTicketInludesOrdersData(itemId, searchParams);
      return Promise.resolve([]);
  };



  const getMessagesForOrderData = (orderId, searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(messagesForOrderUrl(orderId), searchParams)
    );
  };

  const getMessagesForTicketInludesOrdersData = (
    ticketId,
    searchParams = {}
  ) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        messagesForTicketIcludeOrdersUrl(ticketId),
        searchParams
      )
    );
  };

  const sendNewMessageInItem = (itemId, newMessage, itemType) => {
    if (itemType === "ticket" || itemType === "ticket_order")
      return sendNewMessageInTicket(itemId, newMessage);
    if (itemType === "order") return sendNewMessageInOrder(itemId, newMessage);
    return null;
  };

  const sendNewMessageInTicket = (ticketId, newMessage) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(messagesForTicketUrl(ticketId)),
      {
        method: "POST",
        data: newMessage,
      }
    );
  };

  const sendNewMessageInOrder = (orderId, newMessage) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(messagesForOrderUrl(orderId)),
      {
        method: "POST",
        data: newMessage,
      }
    );
  };

  const udpateMessageData = (messageId, message) => {
    return makeRequest(axiosPrivate, messageUpdateUrl(messageId), {
      method: "PATCH",
      data: message,
    });
  };

  return {
    getMessagesForItemData,
    sendNewMessageInItem,
    sendNewMessageInTicket,
    udpateMessageData,
  };
}
