import { useMemo } from "react";


export const useEmptyArrayMemo = () => {
    const emptyArrayMemo = useMemo(()=>(
        []
      ), [])

    return {
        emptyArrayMemo
    };
};

