import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import BasicDialog from "../../base/BasicDialog";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useAsync } from "../../../hooks/useAsync";

import RoleTable from "../../table/RoleTable";

import useRoleService from "../../../services/roleService";

import { ROLE_DIALOG_TABLE_FIELDS_CONFIG } from "./TableConfig";

const RoleDescriptionDialog = (props) => {
  const { t } = useTranslation();

  const { getRoleDescriptionData } = useRoleService();

  const roleData = useAsync(getRoleDescriptionData);

  const [roleDataLocaly, setRoleDataLocaly] = useState();

  useEffect(() => {
    if (roleData.loading) {
      return;
    }
    setRoleDataLocaly(roleData.value);
  }, [roleData.loading]);

  const isLoading = roleDataLocaly === undefined;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <RoleTable
            showFilters={false}
            data={roleDataLocaly}
            tableConfig={ROLE_DIALOG_TABLE_FIELDS_CONFIG}
            hiddenColumns={[]}
            // style={{ maxHeight: "35vh" }}
            showExportToFileButton={false}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      maxWidth={"sm"}
      title={t("dialog.role_description.role_description")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

RoleDescriptionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

RoleDescriptionDialog.defaultProps = {};

export default RoleDescriptionDialog;
