import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

import TuneIcon from "@mui/icons-material/Tune";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import GuaranteeGuaranteeScopeButton from "../../button/GuaranteeGuaranteeScopeButton";
import {
  centerVericalAlignIconStyle,
  overflowButtonStyle,
} from "../../../helpers/styles";

export default function GuaranteeToolBar(props) {
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      columnSpacing={1}
      rowSpacing={2}
      style={props.style}
    >
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<TuneIcon sx={centerVericalAlignIconStyle} />}
          onClick={props.onClickAdjustTable}
          sx={overflowButtonStyle}
        >
          {t("bar.guarantee_tool_bar.adjust_table")}
        </Button>
      </Grid>

      <Grid item xs={6} sx={{ display: { xs: "none", sm: "flex" } }} />
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={
            <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
          }
          onClick={props.onClickCreateGuarantee}
          sx={overflowButtonStyle}
        >
          {t("bar.guarantee_tool_bar.create_guarantee")}
        </Button>
      </Grid>
      <Grid item xs={12} sm={2}>
        <GuaranteeGuaranteeScopeButton
          options={props.filterOptions}
          isGuaranteeTableView={props.isGuaranteeTableView}
          sx={overflowButtonStyle}
        />
      </Grid>
    </Grid>
  );
}

GuaranteeToolBar.propTypes = {
  style: PropTypes.object,
  onClickAdjustTable: PropTypes.func,
  onClickCreateGuarantee: PropTypes.func,
  setVisibilityGuaranteeTableView: PropTypes.func,
  isGuaranteeTableView: PropTypes.bool,
};

GuaranteeToolBar.defaultProps = {
  isGuaranteeTableView: true,
  massActionButtonOptions: [],
};
