import { useAsync, useAsyncFn } from "./useAsync";
import { useState, useEffect } from "react";
import { useSnackbarAlert } from "../context/snackbarAlert";
import { useTranslation } from "react-i18next";
import { getRandomString } from "../helpers/methods";
import useDocsService from "../services/docsService";


const useCertsData = (
  itemId,
  itemType = "part"
) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [localCerts, setLocalCerts] = useState([]);
  const [loadingCerts, setLoadingCerts] = useState(true);
  const [isSavedCorrectly, setIsSaveCorrectly] = useState(false)

  const [searchParams, setSearchParams] = useState({});

  const {
    getCertsForItemData,
    saveManyCerts,
    updateCertData,
    handleDownloadCert,
    handlePreviewCert
  } = useDocsService();

  const certsData = useAsync(
    () => getCertsForItemData(itemId, searchParams, itemType),
    [itemId, searchParams, itemType]
  );

  const saveCertsFn = useAsyncFn(saveManyCerts);
  const updateCertsFn = useAsyncFn(updateCertData);

  useEffect(() => {
    if (!certsData.loading) {
      setLocalCerts(certsData.value);
    }
    setLoadingCerts(certsData.loading);
  }, [certsData.loading]);


  const prepareCertToHide = (certsId) => {
    return {
      id: certsId,
      certification_active: false,
    };
  };

  const saveCerts = async(newCerts) => {
    setLoadingCerts(true)
      await saveCertsFn
        .execute(prepareDataToSend(newCerts),  searchParams)
        .then((res) => {
          setLoadingCerts(false) 
          setLocalCerts(res)
          setIsSaveCorrectly(true)
        })
        .catch(() => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_certs_saving")
          );
          setLoadingCerts(false) 
        });
}

  const updateCerts = (updateData) => {
    updateCertsFn
      .execute(updateData.id, updateData)
      .then((res) => {
        setIsSaveCorrectly(true)
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.certs_updated")
        );
        certsData.refetch()
      })
      .catch((err) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_certs_updating")
        );
      });
  };


  const hideCerts= (certsId) => {
    updateCertsFn
      .execute(certsId, prepareCertToHide(certsId))
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.certs_deleted")
        );
        certsData.refetch()
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_certs_deleting")
        );
      });
  };

  const prepareDataToSend = (certs) =>{
    for (let cert of certs){
      if (itemType === "part") {
        cert["part"] = itemId;
      } 
      cert['token_data'] = {
        'file_ori_name' :  cert['cert_name'],
        'unique_index' : getRandomString(),
        'content_type' : cert['file'].type
      }
    }
    return  certs
  }
  const onDownloadCert = (certsId, certsName) => {
    handleDownloadCert(certsId, certsName);
  };

  const onPreviewCert = (certsId, index) => {
    if (certsId){
      handlePreviewCert(certsId);
    }
  };



  return {
    certs: localCerts,
    setCerts: setLocalCerts,
    isCertsLoading: loadingCerts,
    isSavedCorrectly,
    setIsSaveCorrectly,
    refetchCerts : certsData.refetch, 
    updateCerts,
    saveCerts,
    hideCerts,
    onDownloadCert,
    onPreviewCert
  };
};

export default useCertsData;
