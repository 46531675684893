import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

import TextFieldFormControl from "../../field/TextFieldFormControl";

import { useAuth } from "../../../context/auth";
import { useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "../../../msalConfig";
import { AAD_MAIL_DOMAIN } from "../../../helpers/constants";

import { getErrorMsg } from "../../../helpers/methods";

import LoaderWrapper from "../../wrapper/LoaderWrapper/LoaderWrapper";

import "./LoginForm.css";
import { useLocation } from "react-router-dom";

export default function LoginForm(props) {
  const { t } = useTranslation();

  const location = useLocation();

  const [email, setEmail] = useState("");

  const snackbarAlert = useSnackbarAlert();

  const { instance, inProgress } = useMsal();

  const { redirectToMyOliviaLoginPage, aadLogin } = useAuth();
  const aadLoginFn = useAsyncFn(aadLogin);

  const isFormValid = () => {
    return email.includes("@");
  };

  const isLoading = aadLoginFn.loading || inProgress !== InteractionStatus.None;

  const onLogin = () => {
    if (email.includes(AAD_MAIL_DOMAIN)) onAadLogin();
    else onMyOliviaLogin();
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.HandleRedirect) {
      instance
        .handleRedirectPromise()
        .then((response) => {
          if (response) {
            aadLoginFn
              .execute(response.accessToken, response.state)
              .then(() => {
                snackbarAlert.openSuccessSnackbarAlert(
                  t("snackbar_alert.logged_in")
                );
              })
              .catch((error) => {
                snackbarAlert.openErrorSnackbarAlert(
                  getErrorMsg(error.data),
                  t("snackbar_alert.occurred_error_during_logging_in")
                );
              });
          }
        })
        .catch((err) => console.log(err));
    }
  }, [inProgress]);

  const onAadLogin = () => {
    instance.loginRedirect({
      ...loginRequest,
      state: location.state?.from?.pathname,
    });
  };

  const onMyOliviaLogin = () => {
    redirectToMyOliviaLoginPage(email);
  };

  const onClickEnter = (e) => {
    if (e.key === "Enter") {
      onLogin();
    }
  };

  return (
    <LoaderWrapper showLoader={isLoading}>
      <Paper elevation={3} style={props.style} className={"p-10"}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography
              style={{ textTransform: "uppercase", color: "var(--primary)" }}
              variant="h5"
              display="block"
              gutterBottom
            >
              {t("form.auth.logging")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormControl
              label={t("form.auth.email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => onClickEnter(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              // loading={isLoading}
              fullWidth
              type="submit"
              onClick={onLogin}
              disabled={!isFormValid() || isLoading}
              variant="contained"
            >
              {t("form.auth.login_in")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
    </LoaderWrapper>
  );
}

LoginForm.propTypes = {
  style: PropTypes.object,
};
