import Grid from "@mui/material/Grid";

import LogoBar from "../../components/bar/LogoBar";
import ExternalLoginForm from "../../components/form/ExternalLoginForm";
import PageFooter from "../../components/pageFooter";

export default function ExternalLoginPage(props) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item sm={12} xs={12}>
        <LogoBar />
      </Grid>
      <Grid item sm={2} xs={0} />
      <Grid item sm={8} xs={12}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          style={{ minHeight: "75vh" }}
        >
          <ExternalLoginForm style={{ maxWidth: "600px" }} />
        </Grid>
      </Grid>
      <Grid item sm={2} xs={0} />
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          className="margin-top-medium"
        ></Grid>
      </Grid>
      <Grid item xs={12}>
        <PageFooter />
      </Grid>
    </Grid>
  );
}
