import { useState, useEffect, useMemo, useRef } from "react";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import DetailsDrawer from "../../components/drawer/UniversalDetailsDrawer/UniversalDetailsDrawer";

import { NAVIGATION_DRAWER_TYPE_RELEASES } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import { useAsync } from "../../hooks/useAsync";

import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";

import useFilterSearchParams from "../../hooks/useFilterSearchParams";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import useDialogWithId from "../../hooks/useDialogWithId";
import OverridingDialog from "../../components/dialog/OverridingDialog";
import StdTicketAutomatDialog from "../../components/dialog/StdTicketAutomatDialog";

import TableService from "../../services/tableService";
import useTicketService from "../../services/ticketService";

import OverridingCollapsibleTable from "../../components/table/OverridingCollapsibleTable";
import useTicketOrderDetailsDrawer from "../../hooks/useTicketOrderDetailsDrawer";

import {
  OVERRIDING_TABLE_CONFIG,
  OVERRIDING_TABLE_NAME,
  TICKET_TABLE_CONFIG,
  TICKET_TABLE_NAME,
} from "./TableConfig";

import { exportOverridingDataToFileUrl } from "../../helpers/apiUrls";
import { getObjectFromSearchParams } from "../../helpers/methods";

import {
  DIALOG_PREVIEW_MODE,
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
} from "../../helpers/constants";

const tableConfigForUserPreferencesDialog = [
  {
    name: OVERRIDING_TABLE_NAME,
    config: OVERRIDING_TABLE_CONFIG,
  },
  {
    name: TICKET_TABLE_NAME,
    config: TICKET_TABLE_CONFIG,
  },
];

export default function ReleasesPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();

  const {
    page,
    pageSize,
    setPageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const {
    getOverridingData,
    getOverridingFilteringData,
    getTicketFilteringData,
  } = useTicketService();

  const [isDialogWithParamsClosed, setIsDialogWithParamsClosed] =
    useState(true);

  const overridingData = useAsync(
    () =>
      getOverridingData(
        {
          ...getObjectFromSearchParams(searchParams),
          overriding_kind: "hand_over",
        },
        isDialogWithParamsClosed
      ),
    [searchParams]
  );

  const overridingFilteringData = useAsync(getOverridingFilteringData);

  const ticketFilteringData = useAsync(getTicketFilteringData);

  const [overridingDataLocaly, setOverridingDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (overridingData.loading) {
      return;
    }
    setOverridingDataLocaly(overridingData.value.results);
    setCountRecords(overridingData.value.count);
  }, [overridingData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setOverridingDataLocaly(undefined);
    overridingData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setOverridingDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const exportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      OVERRIDING_TABLE_CONFIG,
      hiddenColumnsForTables?.[OVERRIDING_TABLE_NAME],
      columnsOrdersForTables?.[OVERRIDING_TABLE_NAME]
    );
  }, [
    OVERRIDING_TABLE_CONFIG,
    hiddenColumnsForTables?.[OVERRIDING_TABLE_NAME],
    columnsOrdersForTables?.[OVERRIDING_TABLE_NAME],
  ]);

  const [
    openOverridingDialog,
    overridingId,
    onOpenOverridingDialog,
    onCloseOverridingDialog,
  ] = useDialogWithId();

  const handleUpdateOverridingData = () => {
    setOverridingDataLocaly(undefined);
    overridingData.refetch();
  };

  const {
    openDetailsDrawer,
    handleToggleDetailsDrawer,
    handleOpenTicketDetailsDrawer,
    itemType,
    filteringData,
    itemData,
    isLoading: isDrawerLoading,
    onUpdateDataPromise,
    onRefetchData,
    openedTicketIdRef,
    setOpenDetailsDrawer,
  } = useTicketOrderDetailsDrawer(
    overridingData,
    setOverridingDataLocaly,
    ticketFilteringData
  );

  const handleRefetchTicketData = () => {
    // onRefetchData();
    // if Ticket Drawer not readOnly
    handleUpdateOverridingData();
    setOpenDetailsDrawer(false);
  };

  const overridingDialogModeRef = useRef();
  const handleOpenOverridingDialogInCreateMode = () => {
    overridingDialogModeRef.current = DIALOG_CREATE_MODE;
    onOpenOverridingDialog(undefined);
  };

  const handleOpenOverridingDialogInEditMode = (overridingId) => {
    overridingDialogModeRef.current = DIALOG_EDIT_MODE;
    onOpenOverridingDialog(overridingId);
  };

  const handleOpenOverridingDialogInPreviewMode = (overridingId) => {
    overridingDialogModeRef.current = DIALOG_PREVIEW_MODE;
    onOpenOverridingDialog(overridingId);
  };

  const isLoading =
    overridingDataLocaly === undefined || overridingFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <DetailsDrawer
      openDrawer={openDetailsDrawer}
      setOpenDrawer={handleToggleDetailsDrawer}
      itemData={itemData}
      filteringData={filteringData}
      itemType={itemType}
      isLoading={isDrawerLoading}
      onUpdateDataPromise={onUpdateDataPromise}
      onOpenDialogParamsAction={setIsDialogWithParamsClosed}
      onRefetchData={handleRefetchTicketData}
      onRefetchTableData={handleUpdateOverridingData}
      availableActionButtons={["enclosures", "questionnaire"]}
      readOnly={true}
    >
      <NavigationDrawer
        pageName={pageName}
        drawerType={NAVIGATION_DRAWER_TYPE_RELEASES}
      >
        <DefaultPageWrapper titleKey={"releases"}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={1}
            marginTop={1}
          >
            <Grid item xs={12}>
              <UniversalToolBarWithDialogs
                pageName={props.pageName}
                createButtonLabel={t("bar.tool_bar.create_overriding")}
                onClickCreateItem={handleOpenOverridingDialogInCreateMode}
                showMyFiltersButton={true}
                showOpenCloseFilterButton={true}
                showCreateButton={true}
                tableConfig={tableConfigForUserPreferencesDialog}
                filteringData={overridingFilteringData}
                refreshTable={refreshTable}
                onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
                resetPageNumber={resetPageNumber}
                hiddenColumnsForTables={hiddenColumnsForTables}
                setHiddenColumnsForTables={setHiddenColumnsForTables}
                columnsOrdersForTables={columnsOrdersForTables}
                setColumnsOrdersForTables={setColumnsOrdersForTables}
              />
            </Grid>
            {hiddenColumnsForTables && (
              <Grid item xs={12}>
                <OverridingCollapsibleTable
                  data={overridingDataLocaly}
                  countRecords={countRecords}
                  page={page}
                  showCleanFilterIcon={true}
                  onPageChange={handleChangePageWithSearchParams}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  selectedInnerItemId={openedTicketIdRef.current}
                  onClickInnerTableRow={handleOpenTicketDetailsDrawer}
                  resetPageNumber={resetPageNumber}
                  filteringData={overridingFilteringData}
                  hiddenColumns={hiddenColumnsForTables}
                  columnsOrders={columnsOrdersForTables}
                  onClickEdit={handleOpenOverridingDialogInEditMode}
                  onClickPreview={handleOpenOverridingDialogInPreviewMode}
                  style={{ maxHeight: "80vh" }}
                  showExportToFileButton={true}
                  exportToFileUrl={exportOverridingDataToFileUrl}
                  exportToFileSearchParams={searchParams}
                  exportToFileHeaders={exportHeaders}
                  exportToFileFileName={`${t("page.releases.overriding")}.xlsx`}
                />
              </Grid>
            )}
            {openOverridingDialog && (
              <OverridingDialog
                open={openOverridingDialog}
                onClose={onCloseOverridingDialog}
                dialogMode={overridingDialogModeRef.current}
                onSubmitCallback={handleUpdateOverridingData}
                overridingId={overridingId}
              />
            )}
          </Grid>
        </DefaultPageWrapper>
      </NavigationDrawer>
    </DetailsDrawer>
  );
}
