import { useState } from "react";

const usePagination = (defaultPageNumber = 1) => {
  const [pageNumber, setPageNumber] = useState(defaultPageNumber);

  const handleChangePage = (e, pageNumber) => {
    setPageNumber(pageNumber);
  };

  const resetPageNumber = () => {
    setPageNumber(1);
  };

  return [pageNumber, handleChangePage, resetPageNumber];
};

export default usePagination;
