import PropTypes from "prop-types";

import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import useUserService from "../../../services/userService";
import useSkillService from "../../../services/skillService";
import { useAsync } from "../../../hooks/useAsync";

import LoaderWrapper from "../../../components/wrapper/LoaderWrapper";
import AutocompleteField from "../../../components/field/AutocompleteField";
import DateField from "../../field/DateField/DateField";

import { useForm } from "../../../hooks/useForm";

import useFilterSearchParams from "../../../hooks/useFilterSearchParams";

import {
  getSearchParamsObjectForFilterPrefix,
  getObjectWithoutKeys,
} from "../../../helpers/methods";

export default function RcpTimeTrackingSearchBar(props) {
  const { t } = useTranslation();

  const { getUserHasSkill } = useUserService();
  const { getSkillSelectList } = useSkillService();

  const userList = useAsync(() =>
    getUserHasSkill({ only_employee: true, roles: "role_is_t" })
  );
  const skillList = useAsync(getSkillSelectList);

  const [searchParams, setSearchParams] = useSearchParams();
  const { getSearchParamsByFilterPrefix, clearSearchParamsByFilterPrefixes } =
    useFilterSearchParams(searchParams);

  const {
    formValue,
    setFormValue,
    onChangeDateConvertedToDateTimeWithZeroHourOfDate,
    onChangeDateConvertedToDateTimeWithEndHourOfDate,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm(getSearchParamsByFilterPrefix(props.filterPrefix));

  const handleChangeSkill = (...inputProps) => {
    setFormValue((prev) => getObjectWithoutKeys(prev, ["employee"]));
    onChangeAutocompleteFieldWithObjectOptions(...inputProps);
  };

  const getEmployeeSelectList = () => {
    if (skillList.loading || userList.loading) return [];
    if (!formValue.skill) return userList.value;
    return userList.value.filter((user) =>
      user.skill.some((skill) => formValue.skill.includes(skill))
    );
  };

  const getSearchParamsObjectsForFilterPrefixes = () => {
    let searchParamsObject = {};
    for (let tableFilterPrefix of props.tableFilterPrefixes) {
      searchParamsObject = {
        ...searchParamsObject,
        ...getSearchParamsObjectForFilterPrefix(formValue, tableFilterPrefix),
      };
    }
    return searchParamsObject;
  };

  const handleSearch = () => {
    setSearchParams(() => ({
      ...clearSearchParamsByFilterPrefixes(
        [props.filterPrefix, ...props.tableFilterPrefixes],
        props.excludedKeysForClearFilters
      ),
      ...getSearchParamsObjectsForFilterPrefixes(),
      ...getSearchParamsObjectForFilterPrefix(formValue, props.filterPrefix),
    }));
  };

  const handleCleanFilters = () => {
    setFormValue(() => ({}));
    setSearchParams(() =>
      clearSearchParamsByFilterPrefixes(
        [props.filterPrefix, ...props.tableFilterPrefixes],
        props.excludedKeysForClearFilters
      )
    );
  };

  const isLoading = skillList.loading || userList.loading;

  return (
    <LoaderWrapper showLoader={isLoading}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        columnSpacing={2}
        rowSpacing={2}
        marginY={1}
      >
        <Grid item xs={12} md={3}>
          <AutocompleteField
            multiple={true}
            optionValueKey={"id"}
            optionLabelKey={"skill_name"}
            name="skill"
            value={formValue?.skill}
            options={skillList.value}
            label={t("bar.rcp_time_tracking.skill")}
            onChange={handleChangeSkill}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <AutocompleteField
            multiple={true}
            optionValueKey={"id"}
            optionLabelKey={"full_name"}
            name="employee"
            value={formValue?.employee}
            options={getEmployeeSelectList()}
            label={t("bar.rcp_time_tracking.employee")}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <DateField
            name={"day_start"}
            label={t("bar.rcp_time_tracking.day_start")}
            value={formValue?.day_start}
            onChange={onChangeDateConvertedToDateTimeWithZeroHourOfDate}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <DateField
            name={"day_end"}
            label={t("bar.rcp_time_tracking.day_end")}
            value={formValue?.day_end}
            onChange={onChangeDateConvertedToDateTimeWithEndHourOfDate}
          />
        </Grid>
        <Grid
          container
          columnSpacing={2}
          item
          xs={12}
          justifyContent={"flex-end"}
        >
          <Grid item xs={12} md={2}>
            <Button
              variant="contained"
              color="success"
              size="small"
              fullWidth
              onClick={handleSearch}
            >
              {t("search")}
            </Button>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              onClick={handleCleanFilters}
            >
              {t("clean_filters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </LoaderWrapper>
  );
}

RcpTimeTrackingSearchBar.propTypes = {
  style: PropTypes.object,
  tableFilterPrefixes: PropTypes.array,
  filterPrefix: PropTypes.string,
  excludedKeysForClearFilters: PropTypes.array,
};

RcpTimeTrackingSearchBar.defaultProps = {
  filterPrefix: "search_bar",
  tableFilterPrefixes: ["rcp_time"],
  excludedKeysForClearFilters: [],
};
