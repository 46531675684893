import TicketsFeedbackReportBasePage, {
  CHART_TYPE_NPS_DISTRIBUTION,
} from "../TicketsFeedbackReportBasePage";

export default function TicketNpsDistributionReportPage(props) {
  return (
    <TicketsFeedbackReportBasePage
      chartType={CHART_TYPE_NPS_DISTRIBUTION}
      pageName={props.pageName}
      pageTitleKey={"nps_per_building"}
      showNps={false}
      showSelectObjectLevel2={true}
      showSelectTenant={false}
    />
  );
}

TicketNpsDistributionReportPage.propTypes = {};
