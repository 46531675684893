import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import { useAsync } from "../../../../hooks/useAsync";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import FilterTable from "../../../table/FilterTable";
import { useEmptyArrayMemo } from "../../../../hooks/useEmptyArrayMemo";
import TableService from "../../../../services/tableService";
import { useSearchParams } from "react-router-dom";
import useFilterSearchParams from "../../../../hooks/useFilterSearchParams";
import { USER_WAREHOUSE_PERMISSION_TABLE_CONFIG } from "./TableConfig";
import useUserService from "../../../../services/userService";
import useDialog from "../../../../hooks/useDialog";
import UserWarehousePermissionDialog from "../../../dialog/UserWarehousePermissionDialog";
import useWarehouseService from "../../../../services/warehouseService";

const  WAREHOUSE_PERMISSION_FILTER_PREFIX= "war_perm"

const WarehouseAdminPermissionsTab = (props) => {
  const { t } = useTranslation();
  const [warPermissionLocal, setWarPermissionLocal] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [warPermTableSearchParams, setWarPermTableSearchParams] = useState({});
  const [selectedRowData, setSelectedRowData] = useState();
  const [dialogType, setDialogType] = useState();


  const { setNewItemSearchParamsIfAreChanged,
    clearSearchParamsByFilterPrefix
  } =
    useFilterSearchParams(searchParams);

  const {
    getTransactionWarehousePermissionList
  } = useUserService();

  const warPermissionList = useAsync(() =>getTransactionWarehousePermissionList(warPermTableSearchParams),
[warPermTableSearchParams])

  useEffect(() => {
    if (warPermissionList.loading) {
      return;
    }
    setWarPermissionLocal(warPermissionList.value);

  }, [warPermissionList.loading]);

   


  const [
    openUserWarehousePermDialog,
    onOpenUserWarehousePermDialog,
    onCloseUserWarehousePermDialog
  ] = useDialog();

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      WAREHOUSE_PERMISSION_FILTER_PREFIX,
      warPermTableSearchParams,
      setWarPermTableSearchParams
    );
  }, [searchParams]);


  const { emptyArrayMemo } = useEmptyArrayMemo()


  const handleOpenEditPermissionDialog = useCallback(
    (e, rowId, dialogType) => {
      e.stopPropagation();
      setSelectedRowData(warPermissionLocal.find(perm => perm.id === rowId))
      onOpenUserWarehousePermDialog();
      setDialogType(dialogType)
    },
    [selectedRowData, warPermissionLocal]
  );

  const handleCloseEditPermissionDialog  = useCallback(
    () => {
      setSelectedRowData(undefined)
      onCloseUserWarehousePermDialog();
      setDialogType(undefined)
    },
    [selectedRowData]
  );

  const isLoading = false

  const data = React.useMemo(
    () =>

      TableService.getPreparedDataForCollapsibleTable(
        USER_WAREHOUSE_PERMISSION_TABLE_CONFIG,
        warPermissionLocal ? warPermissionLocal : emptyArrayMemo,
        emptyArrayMemo,
        emptyArrayMemo,
        handleOpenEditPermissionDialog
      ),

    [warPermissionLocal, USER_WAREHOUSE_PERMISSION_TABLE_CONFIG]
  );

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid       
    item
    container
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    rowSpacing={1}
  >
    <Grid item xs={12} sm={2}>
      <Button
        fullWidth
        variant="contained"
        size="small"
        startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
        onClick={onOpenUserWarehousePermDialog}
      >
        {t("tabs.warehouse_admin_tab.add_transaction_permission")}
      </Button>
    </Grid>
    <Grid item sm={9} />
    <Grid item xs={12}>
        <FilterTable
          data={data}
          showCheckbox={false}
          showCleanFilterIcon={true}
          filteringData={props.filteringData}
          style={{ maxHeight: "vh" }}
          tableConfig={USER_WAREHOUSE_PERMISSION_TABLE_CONFIG}
          showContextMenu={false}
          withPagination = {false}
          filterPrefix={WAREHOUSE_PERMISSION_FILTER_PREFIX}
          headersConfig={TableService.getHeadersConfigForCollapsibleTable(
            USER_WAREHOUSE_PERMISSION_TABLE_CONFIG,
            props.filteringData || emptyArrayMemo,
            emptyArrayMemo,
            emptyArrayMemo,
          )}
        />
      </Grid>
      {
        openUserWarehousePermDialog &&
        <UserWarehousePermissionDialog 
        open={openUserWarehousePermDialog}
        onClose={handleCloseEditPermissionDialog}
        filteringData = {props.filteringData}
        onRefetchData = {warPermissionList.refetch}
        userId = {selectedRowData?.user_id}
        warehouseId = {selectedRowData?.warehouse_id}
        permissions={selectedRowData?.permissions}
        dialogType= {dialogType}
        />
      }
    </Grid>
  );
};

WarehouseAdminPermissionsTab.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

WarehouseAdminPermissionsTab.defaultProps = {};

export default WarehouseAdminPermissionsTab;
