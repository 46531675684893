import BasicDialog from "../../base/BasicDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import PdfWindow from "../../window/PdfWindow/PdfWindow";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import useFileService from "../../../services/fileService";
import FileUploadList from "../../other/FileUploadList";
import BaseBox from "../../base/BaseBox/baseBox";
import BottomOfferGenerateButtonsBox from "../../box/BottomOfferGenerateButtonsBox";
import BottomMyOfferAcceptButtonsBox from "../../box/BottomMyOfferAcceptButtonsBox";
import useOfferService from "../../../services/offerService";

function OfferDocumentDialog(props) {
  const { t } = useTranslation();


  const [pdfUrl, setPdfUrl] = useState(undefined);

  const [fileIsEmpty, setFileIsEmpty] = useState(false);

  const { downloadFileByBlobUrl } = useFileService();

  const { getOfferPdfDocumentToPreview, handleDownloadOfferDocument } = useOfferService()

  useEffect(() => {
    if (pdfUrl === undefined) {
      getPdfUrl()
    }
  }, [props.validatedOffer.id, pdfUrl]);

  const getPdfUrl = async () => {
    let data = await getOfferPdfDocumentToPreview(props.validatedOffer.id)
    setPdfUrl(data)
    if (data === undefined) {
      setFileIsEmpty(true)
    }
  }



  const getBottomButtonGrid = () => {
    if (!props.showBottomButtonGrid) {
      return null;
    }
    if (props.isMyOfferPage) {
      return (
        <BottomMyOfferAcceptButtonsBox
          selectedOffer={props.validatedOffer}
          onAcceptOfferByOm={props.onAcceptOfferByOm}
          onRejectOfferByOm={props.onRejectOfferByOm}
          fileIsEmpty={fileIsEmpty}
          hasCrs = {props.validatedOffer.has_crs}
          downloadFileByBlobUrl={downloadFileByBlobUrl}
          pdfUrl={pdfUrl}
          onClose={props.onClose}
          readOnly={props.readOnly}
          isOfferCR = {props.isOfferCR}
          loading={props.loading}
          offerId={props.validatedOffer.id}
          onOpenHistoryLogDialog={props.onOpenHistoryLogDialog}
        />
      );
    } else {
      return (
        <BottomOfferGenerateButtonsBox
          validatedOffer={props.validatedOffer}
          onSendOfferToRR={props.onSendOfferToRR}
          onSendOfferToOM={props.onSendOfferToOM}
          fileIsEmpty={fileIsEmpty}
          downloadFileByBlobUrl={handleDownloadOfferDocument}
          pdfUrl={pdfUrl}
          onClose={props.onClose}
          loading={props.loading}
          readOnly={props.readOnly}
        />
      );
    }
  };

  const isLoading = pdfUrl === undefined;


  return (
    <>
      <BasicDialog
        open={props.open}
        onClose={props.onClose}
        titleAlign="center"
        title={t("dialog.generate_offer_dialog.offer_document")}
        maxWidth="lg"
        showTopActionButton={false}
        showBottomActionButton={true}
        bottomActionStyle={{ padding: "10px" }}
        showCustomFooter={false}
        showDialogActions={false}
        bottomActionButton={getBottomButtonGrid()}
      >
        {!fileIsEmpty ? (
          <LoaderWrapper showLoader={isLoading}>
            <PdfWindow url={pdfUrl} />
          </LoaderWrapper>
        ) : (
          <>{t("dialog.generate_offer_dialog.document_offer_is_none")}</>
        )}

        <BaseBox>
          <FileUploadList
            enclosures={props.enclosures}
            canRemoveEnclosures={false}
            readOnly={true}
            onDownloadEnclosure={props.onDownloadEnclosure}
            onPreviewEnclosure={props.onPreviewEnclosure}
          />
        </BaseBox>
      </BasicDialog>

    </>
  );
}

OfferDocumentDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  offerId: PropTypes.string,
  readOnly: PropTypes.bool,
  validatedOffer: PropTypes.object,
  onSendOfferToRR: PropTypes.func,
  onSendOfferToOM: PropTypes.func,
  enclosures: PropTypes.array,
  isMyOfferPage: PropTypes.bool,
  onAcceptOfferByOm: PropTypes.func,
  onRejectOfferByOm: PropTypes.func,
  showBottomButtonGrid: PropTypes.bool,
  isOfferCR : PropTypes.bool
};

OfferDocumentDialog.defaultProps = {
  open: false,
  readOnly: true,
  validatedOffer: {},
  enclosures: [],
  isMyOfferPage: true,
  showBottomButtonGrid: true,
  isOfferCR : false
};

export default OfferDocumentDialog;
