import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import SelectField from "../../field/SelectField/SelectField";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import TrueFalseSelectField from "../../field/TrueFalseSelectField";
import { getExposureTypesList } from "../../../helpers/methods";
import EnclosuresWindow from "../../window/EnclosuresWindow";
import { MAX_ALLOWED_FILE_SIZE_IN_MB, MAX_FILENAME_LENGTH_CHARS } from "../../../helpers/constants";

export default function AddEnclosureForm(props) {
  const { t } = useTranslation();


  const getTranslatedEnclosurePartList = (list) => {
    let translatedList = []
    if (list) {
      for (let kind of list) {
        translatedList.push({ 'key': kind, 'name': t(`form.add_enclosure_form.${kind}`) })
      }
    }
    return translatedList
  }


  const validated = props.enclosures.length > 0
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >

      <Grid item xs={12} sm={props.allowCopyEnclosure ? 6 : 12}>
        <Button size="medium" variant="contained" component="label" sx={{ width: "-moz-available" }} fullWidth>
          {t("form.add_enclosure_form.insert_from_disc")}
          <input
            type="file"
            hidden
            onChange={props.onChooseFile}
            multiple
          />
        </Button>
      </Grid>
      {props.allowCopyEnclosure &&
        <Grid item xs={12} sm={6}>
          <Button size="medium" variant="contained" component="label" onClick={props.onCopyFromOther} sx={{ width: "-moz-available" }} fullWidth>
            {t("form.add_enclosure_form.copy_enclosure")}
          </Button>
        </Grid>}
      <Grid item xs={12}>
        <Typography variant="subtitle2" > {t("form.add_enclosure_form.max_file_size") + ` ${MAX_ALLOWED_FILE_SIZE_IN_MB}MB`} </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" > {t(`form.add_enclosure_form.max_file_name_${MAX_FILENAME_LENGTH_CHARS}_chars`)} </Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <EnclosuresWindow
          enclosureWindowStyle={{}}
          enclosures={props.enclosures}
          showCreator={false}
          onDownload={props.onDownload}
          onPreview={props.onPreview}
          onDeleteEnclosure={props.onDeleteEnclosure}
          showAddToFavorite={false}
          showPublishButton={false}
          showDetailsButton={false}
          showDownloadButton={false}
          canHideEnclosures={true}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="enclosure_note"
          label={t("form.add_enclosure_form.enclosure_note")}
          value={props.formValue.enclosure_note}
          multiline={true}
          rows={4}
          onChange={props.onChange}
        />
      </Grid>
      {props.showSubcontractorSelect &&
        <Grid item xs={12} sm={6}>
          <TrueFalseSelectField
            name="enclosure_subcontractor"
            label={t("form.enclosure_details_form.visible_for_subcontractor")}
            value={props.formValue.enclosure_subcontractor ? props.formValue.enclosure_subcontractor : false}
            onChange={props.onChange}
            valuesAsBool={true}
            addEmptyOptions={false}
          />
        </Grid>
      }
      {props.showEnclosureType &&
        <Grid item xs={12} sm={6}>
          <SelectField
            name="enclosure_type"
            label={t("form.add_enclosure_form.enclosure_type")}
            value={props.formValue.enclosure_type}
            options={getExposureTypesList()}
            optionLabel={"name"}
            optionKey={'key'}
            addNewValue={false}
            onChange={props.onChange}
          />
        </Grid>}
      {props.showEnclosurePartKind &&
        <Grid item xs={12} sm={6}>
          <SelectField
            name="enpa_kind_part"
            label={t("form.add_enclosure_form.enclosure_part_kind")}
            value={props.formValue.enpa_kind_part}
            options={getTranslatedEnclosurePartList(props.enclosurePartKindList)}
            optionLabel={"name"}
            optionKey={'key'}
            addNewValue={false}
            onChange={props.onChange}
          />
        </Grid>}

      <Grid item xs={12}>
        <Button
          disabled={!validated}
          variant="contained"
          onClick={props.onSubmit}
          component="label"
          fullWidth
          startIcon={<FileUploadIcon />}
        >
          {props.submitButtonLabel ? props.submitButtonLabel : t("form.add_enclosure_form.save_file")}
        </Button>
      </Grid>

    </Grid>
  );
}

AddEnclosureForm.propTypes = {
  onChange: PropTypes.func,
  formValue: PropTypes.object,
  submitButtonLabel: PropTypes.string,
  onCopyFromOther: PropTypes.func,
  showSubcontractorSelect: PropTypes.bool,
  showEnclosureType: PropTypes.bool,
  showEnclosurePartKind: PropTypes.bool,
  enclosurePartKindList: PropTypes.array,
  allowCopyEnclosure: PropTypes.bool
};

AddEnclosureForm.defaultProps = {
  formValue: {},
  showSubcontractorSelect: true,
  showEnclosureType: true,
  showEnclosurePartKind: false,
  enclosurePartKindList: [],
  allowCopyEnclosure: true
};
