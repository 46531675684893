import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import { filterDataUrl } from "../helpers/apiUrls";
import { prepareUrlWithQueryParams } from "../helpers/methods";

export default function useFilterService() {
  const axiosPrivate = useAxiosPrivate();

  const getUserFilters = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(filterDataUrl, searchParams)
    );
  };

  const createOrUpdateFilter = (formValue) => {
    const { id, ...dataToSend } = formValue;
    if (!id) return createFilter(dataToSend);
    return updateFilter(id, dataToSend);
  };

  const createFilter = (dataToSend) => {
    return axiosPrivate(filterDataUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateFilter = (id, dataToSend) => {
    return axiosPrivate(`${filterDataUrl}${id}/`, {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const deleteFilter = (id) => {
    return axiosPrivate(`${filterDataUrl}${id}/`, {
      method: "DELETE",
    });
  };

  return { getUserFilters, createOrUpdateFilter, deleteFilter };
}
