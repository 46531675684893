import FilterTable from "../FilterTable";
import i18n from "../../../i18n";
import React, { useState } from "react";
import TableService from "../../../services/tableService";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  TEXT_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
} from "../../../helpers/constants";

const UserHasSkillTable = ({
  usersHasSkills,
  filteringData,
  filterPrefix,
  initialExpandedFilterHeader,
  canEdit,
  onClickEdit,
  countRecords,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  resetPageNumber,
}) => {
  const [tableConfig] = useState([
    {
      name: "",
      getValueCallback: (rowData) => {
        return (
          <>
            {canEdit ? (
              <IconButton
                onClick={() => {
                  onClickEdit(rowData);
                }}
              >
                <ModeEditIcon fontSize="small" />
              </IconButton>
            ) : (
              <></>
            )}
          </>
        );
      },
      label: "",
      filterType: undefined,
      getFilterFieldConfigCallback: (filteringData) => undefined,
      allowChangeVisibility: false,
    },
    {
      name: "full_name",
      getValueCallback: (rowData) => rowData.full_name,
      label: i18n.t("table.user_has_skill.employee"),
      filterType: TEXT_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => undefined,
      allowChangeVisibility: false,
      sortable: true,
    },
    {
      name: "skills",
      getValueCallback: (rowData) => {
        return rowData.userskill.map((obj) => obj.skill.skill_name).join(", ");
      },
      label: i18n.t("table.user_has_skill.team"),
      filterType: AUTOCOMPLETE_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => ({
        options: filteringData,
        isObjectOption: true,
        optionLabelKey: "skill_name",
        multiple: true,
      }),
      allowChangeVisibility: false,
    },
  ]);

  const skillHasUserTableData = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        tableConfig,
        usersHasSkills || [],
        [],
        undefined
      ),
    [usersHasSkills, tableConfig]
  );

  return (
    <FilterTable
      data={skillHasUserTableData || []}
      headersConfig={TableService.getHeadersConfigForCollapsibleTable(
        tableConfig,
        filteringData,
        [],
        undefined
      )}
      withPagination={true}
      showCheckbox={false}
      filteringData={filteringData}
      filterPrefix={filterPrefix}
      showCleanFilterIcon={true}
      countRecords={countRecords}
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={onRowsPerPageChange}
      resetPageNumber={resetPageNumber}
      initialExpandedFilterHeader={initialExpandedFilterHeader}
    />
  );
};

UserHasSkillTable.propTypes = {
  usersHasSkills: PropTypes.array,
  filteringData: PropTypes.array,
  filterPrefix: PropTypes.string,
  countRecords: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  resetPageNumber: PropTypes.func,
  initialExpandedFilterHeader: PropTypes.bool,
};

UserHasSkillTable.defaultProps = {
  filteringData: [],
  initialExpandedFilterHeader: false,
};

export default UserHasSkillTable;
