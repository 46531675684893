import { AUTOCOMPLETE_FIELD_TYPE, BOOLEAN_FIELD_TYPE, DATETIME_TO_DATE_RANGE_FIELD_TYPE, NUMERIC_RANGE_FIELD_TYPE, TEXT_FIELD_TYPE } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import DateService from "../../../../services/dateService";
import SelectFieldService from "../../../../services/selectFieldService";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';

export const RESERVATION_TABLE_NAME = "reservation_table";
export const RESERVATION_TABLE_CONFIG = [
  {
    name: "warehouse",
    getValueCallback: (rowData) => rowData.warehouse,
    label: i18n.t("table.transaction_table.warehouse_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.warehouses,
      isObjectOption: true,
      optionLabelKey: "warehouse_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "part",
    getValueCallback: (rowData) => rowData.part,
    label: i18n.t("table.transaction_table.part_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "ticket_nr",
    getValueCallback: (rowData) => rowData.ticket_nr,
    label: i18n.t("table.transaction_table.ticket_nr"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "reservation_amount",
    getValueCallback: (rowData) => rowData.reservation_amount,
    label: i18n.t("table.transaction_table.reservation_amount"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "part_unit",
    getValueCallback: (rowData) => i18n.t(`other.units.${rowData.part_unit}`),
    label: i18n.t("table.transaction_table.part_unit"), 
  },
  {
    name: "reservation_closed",
    getValueCallback: (rowData) => 
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.reservation_closed),
    label: i18n.t("table.transaction_table.reservation_closed"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "created",
    getValueCallback: (rowData) => 
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.transaction_table.reservation_created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: () => undefined,
  },
  {
    name: "user",
    getValueCallback: (rowData) => rowData.user,
    label: i18n.t("table.transaction_table.operator"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.users,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "reservation_closed_date",
    getValueCallback: (rowData) => rowData.reservation_closed_date,
    label: i18n.t("table.transaction_table.reservation_closed_date"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: () => undefined,
  },
  {
    name: "cancel",
    getValueCallback: (rowData, onClickCancel) => {
      const content = (
        <DoNotDisturbAltIcon
          onClick={(e) => onClickCancel(e, rowData.id)}
        />
      );
      return rowData.allow_cancel ? content : "";
    },
    label: i18n.t("table.transaction_table.reservation_cancel"),
    filterType: null,
    excludedFromExport: true,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }
];
