import { useTranslation } from "react-i18next";

import TabsNavigationBar from "../TabsNavigationBar";

function DunReportsNavigationBar(props) {
  const { t } = useTranslation();
  return (
    <TabsNavigationBar
      multipleLocationKey={props.multipleLocationKey}
      pages={[
        {
          label: t("headers.buildings"),
          link: "/tickets_per_location_day_report/",
        },
        {
          label: t("headers.feedback"),
          link: "/tickets_per_feedback_report/",
        },
        {
          label: t("headers.people"),
          link: "/tickets_by_ticket_k_report/",
        },
        {
          label: t("headers.offers"),
          link: "/offers_per_location_report/",
        },
      ]}
    />
  );
}

DunReportsNavigationBar.propTypes = {};

export default DunReportsNavigationBar;
