import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useAsyncFn } from "../../../hooks/useAsync";
import useTicketService from "../../../services/ticketService";
import BasicDialog from "../../base/BasicDialog";
import CreateTicketForm from "../../form/CreateTicketForm/CreateTicketForm";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import useDialog from "../../../hooks/useDialog";
import ConfirmationDialog from "../ConfirmationDialog/confirmationDialog";
import { useState, useCallback, useRef } from "react";
import NewTicketInformationDialog from "../NewTicketInformationDialog";

const CreateTicketDialog = (props) => {
  const { t } = useTranslation();
  const { createTicket } = useTicketService();

  const snackbarAlert = useSnackbarAlert();

  const [
    openConfirmationDialog,
    onOpenConfirmationDialog,
    onCloseConfirmationDialog,
  ] = useDialog();

  const [
    openNewTicketInformationDialog,
    onOpenNewTicketInformationDialog,
    onCloseNewTicketInformationDialog,
  ] = useDialog();

  const getDialogTitle = () => {
    if (props.readOnly) {
      return t("dialog.create_ticket_dialog.questionnaire");
    }
    if (props.ticticKind === "claim")
      return t("dialog.create_ticket_dialog.claim_ticket");
    return t("dialog.create_ticket_dialog.new_ticket");
  };

  const handleCloseNewTicketInformationDialog = (resData) => {
    props.onClose(true, resData);
    onCloseNewTicketInformationDialog();
  };

  const createTicketFn = useAsyncFn(createTicket);
  const newTicketDataRef = useRef();
  const [isDialogHidden, setIsDialogHidden] = useState(false);
  const onSubmit = (newTicketData) => {
    if (props.onSubmitReplacement) {
      props.onSubmitReplacement(newTicketData);
      props.onClose();
      return;
    }

    createTicketFn
      .execute(newTicketData)
      .then((resData) => {
        newTicketDataRef.current = resData;
        onOpenNewTicketInformationDialog();
        setIsDialogHidden(true);
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_creating_new_ticket")
        );
      });
  };

  const handleClose = () => {
    if (props.readOnly) {
      return props.onClose();
    }
    onOpenConfirmationDialog();
  };

  const handleCloseConfirmationDialog = (confirmClose) => {
    onCloseConfirmationDialog();
    // cancel create ticket
    if (confirmClose) props.onClose(props.returnTrueIfTicketCanceled);
  };

  const [fullScreen, setFullScreen] = useState(false);
  const setFullScreenCallback = useCallback((prev) => setFullScreen(!prev), []);

  return (
    <BasicDialog
      open={props.open}
      hide={isDialogHidden}
      onClose={handleClose}
      titleAlign="center"
      title={getDialogTitle()}
      onToggleFullScreen={setFullScreenCallback}
    >
      <CreateTicketForm
        onSubmit={onSubmit}
        initialData={props.initialData}
        loadingSubmitButton={createTicketFn.loading}
        parentTicketId={props.parentTicketId}
        ticticKind={props.ticticKind}
        readOnly={props.readOnly}
        readOnlyFields={props.readOnlyFields}
        maxAllowedLevelNumber={props.maxAllowedLevelNumber}
        allowOnlySingleLocation={props.allowOnlySingleLocation}
        fullScreen={fullScreen}
      />
      {openConfirmationDialog && (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onNo={() => handleCloseConfirmationDialog(false)}
          onYes={() => handleCloseConfirmationDialog(true)}
          title={t("dialog.create_ticket_dialog.alert")}
          content={t(
            "dialog.create_ticket_dialog.are_you_sure_to_close_ticket_without_saving"
          )}
        />
      )}
      {openNewTicketInformationDialog && (
        <NewTicketInformationDialog
          open={openNewTicketInformationDialog}
          onClose={() =>
            handleCloseNewTicketInformationDialog(newTicketDataRef.current)
          }
          ticketNumber={newTicketDataRef.current.ticket_nr}
        />
      )}
    </BasicDialog>
  );
};

CreateTicketDialog.propTypes = {
  open: PropTypes.bool,
  readOnly: PropTypes.bool,
  onClose: PropTypes.func,
  returnTrueIfTicketCanceled: PropTypes.bool,
  parentTicketId: PropTypes.string,
  ticticKind: PropTypes.oneOf(["action", "visibility", "claim"]),
  onSubmitReplacement: PropTypes.func,
  initialData: PropTypes.object,
  readOnlyFields: PropTypes.array,
  maxAllowedLevelNumber: PropTypes.number,
  allowOnlySingleLocation: PropTypes.bool,
};

CreateTicketDialog.defaultProps = {};

export default CreateTicketDialog;
