import React from "react";

import PropTypes from "prop-types";

import { Grid, Typography, IconButton } from "@mui/material";

import TextFieldFormControl from "../../../field/TextFieldFormControl";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

import useDialog from "../../../../hooks/useDialog";
import WarningAlertIcon from "../../../other/WarningAlertIcon";
import MultipleLocalizationDialog from "../../../dialog/MutlipleLocalizationDialog";

import ChangeLocalizationDialog from "../../../dialog/ChangeLocalizationDialog";
import i18n from "../../../../i18n";

import FieldWithLabel from "../../../field/FieldWithLabel";
import BaseBox from "../../../base/BaseBox/baseBox";

const LocalizationForm = ({
  withTitle = true,
  inBox = false,
  title = i18n.t("form.localization_form.localization"),
  titleVariant = "subtitle",
  isAllowedToChangeLocalization,
  itemForChangeLocalizationId,
  onChangeLocalization,
  isMultipleLocalization,
  allowToAddMultipleLocations = true,
  localizationData,
  itemType,
  boxHeight,
  maxAllowedLevelNumber
}) => {
  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();

  const [
    openChangeLocalizationDialog,
    onOpenChangeLocalizationDialog,
    onCloseChangeLocalizationDialog,
  ] = useDialog();

  const formContent = (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      {withTitle && (
        <Grid alignItems="center" display={"flex"} item>
          <Typography
            variant={titleVariant}
            className="capitalize-first-letter"
            color={"primary"}
          >
            {title}
          </Typography>
          {isAllowedToChangeLocalization && (
            <IconButton onClick={onOpenChangeLocalizationDialog}>
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
          )}
          {isMultipleLocalization && (
            <WarningAlertIcon
              onClick={onOpenMultipleLocalizationDialog}
              style={{ cursor: "pointer" }}
            />
          )}
        </Grid>
      )}
      {1 <= maxAllowedLevelNumber &&
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.localization_form.level_1")}
          field={
            <TextFieldFormControl
              value={localizationData?.[0].level_1?.object_name}
              readOnly
            />
          }
        />
      </Grid>}
      {2 <= maxAllowedLevelNumber &&
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.localization_form.level_2")}
          field={
            <TextFieldFormControl
              value={localizationData?.[0].level_2?.object_name}
              readOnly
            />
          }
        />
      </Grid>}
      {3 <= maxAllowedLevelNumber &&
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.localization_form.level_3")}
          field={
            <TextFieldFormControl
              value={localizationData?.[0].level_3?.object_name}
              readOnly
            />
          }
        />
      </Grid>}
      {4 <= maxAllowedLevelNumber &&
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.localization_form.level_4")}
          field={
            <TextFieldFormControl
              value={localizationData?.[0].level_4?.object_name}
              readOnly
            />
          }
        />
      </Grid>}
      {5 <= maxAllowedLevelNumber &&
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.localization_form.level_5")}
          field={
            <TextFieldFormControl
              value={localizationData?.[0].level_5?.object_name}
              readOnly
            />
          }
        />
      </Grid>}
      {openMultipleLocalizationDialog && (
        <MultipleLocalizationDialog
          open={openMultipleLocalizationDialog}
          onClose={onCloseMultipleLocalizationDialog}
          isLevelObjectData={true}
          localizationData={localizationData}
          itemType={itemType}
          maxAllowedLevelNumber = {maxAllowedLevelNumber}
        />
      )}
      {openChangeLocalizationDialog && (
        <ChangeLocalizationDialog
          open={openChangeLocalizationDialog}
          onClose={onCloseChangeLocalizationDialog}
          locations={localizationData}
          itemId={itemForChangeLocalizationId}
          onSubmit={onChangeLocalization}
          allowToAddMultipleLocations={allowToAddMultipleLocations}
        />
      )}
    </Grid>
  );

  return inBox ? (
    <BaseBox style={{ height: boxHeight }}>{formContent}</BaseBox>
  ) : (
    formContent
  );
};

LocalizationForm.propTypes = {
  withTitle: PropTypes.bool,
  titleVariant: PropTypes.string,
  inBox: PropTypes.bool,
  boxHeight: PropTypes.string,
  title: PropTypes.string,
  itemForChangeLocalizationId: PropTypes.string,
  isAllowedToChangeLocalization: PropTypes.bool,
  localizationData: PropTypes.array,
  isMultipleLocalization: PropTypes.bool,
  onChangeLocalization: PropTypes.func,
  itemType: PropTypes.oneOf(["ticket", "order"]),
  allowToAddMultipleLocations: PropTypes.bool,
  maxAllowedLevelNumber : PropTypes.number
};

LocalizationForm.defaultProps = {
  itemType: "ticket",
  allowToAddMultipleLocations: true,
  maxAllowedLevelNumber : 5
};

export default LocalizationForm;
