import React from "react";

import PropTypes from "prop-types";

import FlagIcon from "@mui/icons-material/Flag";

const FlagMarker = ({ id, flagName, flagColor }) => {
  return (
    <div style={{ display: "flex" }}>
      <FlagIcon style={{ color: flagColor }} />
      {flagName}
    </div>
  );
};

FlagMarker.propTypes = {
  id: PropTypes.string,
  flagName: PropTypes.string,
  flagColor: PropTypes.string,
};

export default FlagMarker;
