import PropTypes from "prop-types";

import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import useUserService from "../../services/userService";
import { useAsync } from "../../hooks/useAsync";

import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import TextFieldFormControl from "../../components/field/TextFieldFormControl";
import AutocompleteField from "../../components/field/AutocompleteField";
import DateField from "../../components/field/DateField/DateField";

import { useForm } from "../../hooks/useForm";

import useFilterSearchParams from "../../hooks/useFilterSearchParams";

import { getSearchParamsObjectForFilterPrefix } from "../../helpers/methods";

export default function MyDocumentsExternalSearchBar(props) {
  const { t } = useTranslation();

  const { getUserSelectList } = useUserService();
  const userList = useAsync(() =>
    getUserSelectList({ only_external_docs_authors: true })
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const { getSearchParamsByFilterPrefix, clearSearchParamsByFilterPrefixes } =
    useFilterSearchParams(searchParams);

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm(getSearchParamsByFilterPrefix(props.filterPrefix));

  const getSearchParamsObjectsForFilterPrefixes = () => {
    let searchParamsObject = {};
    for (let tableFilterPrefix of props.tableFilterPrefixes) {
      searchParamsObject = {
        ...searchParamsObject,
        ...getSearchParamsObjectForFilterPrefix(formValue, tableFilterPrefix),
      };
    }
    return searchParamsObject;
  };

  const handleSearch = () => {
    setSearchParams(() => ({
      ...clearSearchParamsByFilterPrefixes(
        [props.filterPrefix, ...props.tableFilterPrefixes],
        props.excludedKeysForClearFilters
      ),
      ...getSearchParamsObjectForFilterPrefix(formValue, props.filterPrefix),
      ...getSearchParamsObjectsForFilterPrefixes(),
    }));
  };

  const handleCleanFilters = () => {
    setFormValue(() => ({}));
    setSearchParams(() =>
      clearSearchParamsByFilterPrefixes(
        [props.filterPrefix, ...props.tableFilterPrefixes],
        props.excludedKeysForClearFilters
      )
    );
  };

  const isLoading = userList.loading;

  return (
    <LoaderWrapper showLoader={isLoading}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        columnSpacing={2}
        rowSpacing={2}
      >
        <Grid item xs={12} md={1.75}>
          <TextFieldFormControl
            name="docs_name"
            value={formValue?.docs_name}
            label={t("table.enclosure.enclosure_name")}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} md={1.75}>
          <TextFieldFormControl
            name="docs_note"
            value={formValue?.docs_note}
            label={t("table.enclosure.enclosure_note")}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} md={2.5}>
          <AutocompleteField
            multiple={true}
            optionValueKey={"id"}
            optionLabelKey={"full_name"}
            name="user"
            value={formValue?.user}
            options={userList?.value}
            label={t("table.enclosure.author")}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            limitTags={1}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <DateField
            name={"created_start"}
            label={t("table.enclosure.created_start")}
            value={formValue?.created_start}
            onChange={onChangeDate}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <DateField
            name={"created_end"}
            label={t("table.enclosure.created_end")}
            value={formValue?.created_end}
            onChange={onChangeDate}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button
            variant="contained"
            color="success"
            size="small"
            fullWidth
            onClick={handleSearch}
          >
            {t("search")}
          </Button>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            onClick={handleCleanFilters}
          >
            {t("clean_filters")}
          </Button>
        </Grid>
      </Grid>
    </LoaderWrapper>
  );
}

MyDocumentsExternalSearchBar.propTypes = {
  style: PropTypes.object,
  tableFilterPrefixes: PropTypes.array,
  filterPrefix: PropTypes.string,
  excludedKeysForClearFilters: PropTypes.array,
};

MyDocumentsExternalSearchBar.defaultProps = {
  filterPrefix: "search_bar",
  tableFilterPrefixes: [],
  excludedKeysForClearFilters: [],
};
