import React from "react";
import PropTypes from "prop-types";

import { Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import {
  overflowButtonStyle,
  centerVericalAlignIconStyle,
} from "../../../helpers/styles";

function InfoButton(props) {
  return (
    <Button
      fullWidth
      variant="contained"
      size="small"
      endIcon={<InfoIcon sx={centerVericalAlignIconStyle} />}
      onClick={props.onClick}
      sx={overflowButtonStyle}
    >
      {props.label}
    </Button>
  );
}

InfoButton.propTypes = {
  label: PropTypes.string,
};

export default InfoButton;
