import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import PropTypes from "prop-types";

import LoaderWrapper from "../../wrapper/LoaderWrapper/LoaderWrapper";
import AutocompleteField from "../../field/AutocompleteField";

import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import useFlagService from "../../../services/flagService";

import { useForm } from "../../../hooks/useForm";

function AddFlagForm(props) {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const { formValue, onChangeAutocompleteFieldWithObjectOptions } = useForm({});

  const { getFlagsForItems, addFlagForItems, deleteFlagFromItems } =
    useFlagService();
  const flagsData = useAsync(
    () => getFlagsForItems(props.itemType),
    [props.itemType]
  );

  const addFlagsToItemsFn = useAsyncFn(addFlagForItems);
  const deleteFlagFromItemsFn = useAsyncFn(deleteFlagFromItems);
  const submitFn =
    props.formMode === "add" ? addFlagsToItemsFn : deleteFlagFromItemsFn;

  const handleSubmit = () => {
    submitFn
      .execute(props.itemIds, formValue.flags, props.itemType)
      .then((data) => {
        const successMsg =
          props.formMode === "add"
            ? t("snackbar_alert.flags_added")
            : t("snackbar_alert.flags_deleted");
        snackbarAlert.openSuccessSnackbarAlert(successMsg);
        props.onSubmit();
      })
      .catch((error) => {
        const errorMsg =
          props.formMode === "add"
            ? t("snackbar_alert.occurred_error_during_added_flags")
            : t("snackbar_alert.occurred_error_during_deleted_flags");
        snackbarAlert.openErrorSnackbarAlert(errorMsg);
      });
  };

  const isFormValid = formValue.flags;

  if (flagsData.loading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={1}
      columnSpacing={2}
    >
      <Grid item xs={12}>
        <AutocompleteField
          name="flags"
          label={t("form.add_flag.add_flags")}
          value={formValue.flags}
          options={flagsData.value}
          optionLabelKey={"flag_name"}
          isObjectOption={true}
          addNewValue={false}
          onChange={onChangeAutocompleteFieldWithObjectOptions}
          multiple={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <LoadingButton
          disabled={!isFormValid}
          loading={submitFn.loading}
          type={"submit"}
          fullWidth
          onClick={handleSubmit}
          variant="contained"
        >
          {t("confirm")}
        </LoadingButton>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LoadingButton
          fullWidth
          onClick={props.onCancel}
          disabled={submitFn.loading}
          variant="contained"
          color="secondary"
        >
          {t("cancel")}
        </LoadingButton>
      </Grid>
    </Grid>
  );
}

AddFlagForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  itemIds: PropTypes.arrayOf(PropTypes.string),
  itemType: PropTypes.oneOf(["ticket", "order"]),
  formMode: PropTypes.oneOf(["add", "delete"]),
};

AddFlagForm.defaultProps = {
  itemType: "ticket",
  formMode: "add",
};

export default AddFlagForm;
