import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect, useMemo, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useFieldValidation from "../../../hooks/useFieldValidation";
import useGuaranteeService from "../../../services/guaranteeService";
import AgreementGuaranteeForm from "../../form/AgreementGuaranteeForm/AgreementGuaranteeForm";


const AgreementGuaranteeDetailsDialog= (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const { getUnfillRequiredFields } = useFieldValidation();
  const [isLocationFormatValid, setIsLocationFormatValid] = useState(true);

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate
  } = useForm({'agreement' : props.agreementId, 'guarantee_location' : "ZABEZ/___/__"});

  const locationFormatRegex = /^ZABEZ\/(?:[^_]*_){0,3}[^_]{0,4}\/\d{2}$/;

  useEffect(() => {
    if (formValue.guarantee_location) {
      setIsLocationFormatValid(validateLocationFormat(formValue.guarantee_location));
    }
  }, [formValue]);


  const validateLocationFormat = (location) => {
    return locationFormatRegex.test(location);
  };

  const {
    getGuaranteeAgreementDetailsData,
    createGuarantee,
    updateGuarantee,
    getNextGuaranteeNumber } = useGuaranteeService();

  const updateGuaranteeFn = useAsyncFn(updateGuarantee)
  const createGuaranteeFn = useAsyncFn(createGuarantee)


  const requiredFields = useMemo(() => {
    return [
      'guarantee_value',
      'guarantee_validity',
      'guarantee_category',
      'guarantee_date_contract',
      'guarantee_date_close'
    ];
  }, []);

  const guaranteeAgreementDetailsData = useAsync(
    () => {
      if (props.guaranteeId){
        return getGuaranteeAgreementDetailsData(props.guaranteeId)
      }else{
        if (props.agreementId){
          return getNextGuaranteeNumber ({'agreement' : props.agreementId})
        }
      }
      return Promise.resolve({})
    },
    [props.guaranteeId, props.agreementId]
  );



  useEffect(() => {
    if (guaranteeAgreementDetailsData.loading) {
      return;
    }
    
    if (props.guaranteeId&& guaranteeAgreementDetailsData.value) {
      setFormValue(guaranteeAgreementDetailsData.value);
      setIsLocationFormatValid(validateLocationFormat(guaranteeAgreementDetailsData.value.guarentee_location));
    }else if(guaranteeAgreementDetailsData.value){
      let value = guaranteeAgreementDetailsData.value
      setFormValue((tempForm) => ({...tempForm, ...value}));
    }
  }, [guaranteeAgreementDetailsData.loading]);



  const isLoadingButtonDisabled = useMemo(() => {
    return getUnfillRequiredFields(requiredFields, formValue).length > 0;
  }, [requiredFields, formValue, getUnfillRequiredFields]);

  const prepareDataToSend = (guaranteeData) =>
  {
    delete guaranteeData['agreement']
    return guaranteeData
  }

  const onSubmit = useCallback(() => {
    if (props.guaranteeId) {
      updateGuaranteeFn
        .execute(props.guaranteeId, prepareDataToSend (formValue))
        .then((res) => {
          if (props.onRefetchDetails){
            props.onRefetchDetails()
          }
          if(props.onRefetchData){
            props.onRefetchData()
          }
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.guarantee_agreement_updated")
          );
          props.onClose();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_guarantee_agreement_updating")
          );
        });
    } else {
      createGuaranteeFn
        .execute(formValue)
        .then((res) => {
          if (props.onRefetchDetails){
            props.onRefetchDetails()
          }
          if(props.onRefetchData){
            props.onRefetchData()
          }
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.guarantee_agreement_created")
          );
          props.onClose();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_guarantee_agreement_creating")
          );
        });
    }

  }, [formValue]);


  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.guaranteeId ? t(`dialog.agreements_guarantee_details_dialog.guarantee_details`) : t(`dialog.agreements_guarantee_details_dialog.create_guarantee`)}
      maxWidth="xs"
      showDialogActions
    >

      <LoaderWrapper showLoader={guaranteeAgreementDetailsData.loading} >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12}>
            <AgreementGuaranteeForm
              readOnly={props.readOnly}
              agreementGuaranteeData={formValue}
              onChange={onChange}
              onChangeDate={onChangeDate}
              filteringData={props.filteringData}
              isLocationFormatValid= {isLocationFormatValid || guaranteeAgreementDetailsData.loading}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={updateGuaranteeFn.loading || createGuaranteeFn.loading}
              disabled={isLoadingButtonDisabled || !isLocationFormatValid}
              onClick={onSubmit}
            >
              {props.guaranteeId ? t("save") : t("create")}
            </LoadingButton>
          </Grid>

        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
};

AgreementGuaranteeDetailsDialog.propTypes = {
  agreementId: PropTypes.string,
  guaranteeId : PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool
};

AgreementGuaranteeDetailsDialog.defaultProps = {
  open: false,
};

export default AgreementGuaranteeDetailsDialog;
