import FilterTable from "../FilterTable";
import i18n from "../../../i18n";
import React, { useState } from "react";
import TableService from "../../../services/tableService";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  TEXT_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
} from "../../../helpers/constants";

const SkillHasUserTable = ({
  skillHasUser,
  filteringData,
  filterPrefix,
  initialExpandedFilterHeader,
  onClickEdit,
  countRecords,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  resetPageNumber,
}) => {
  const [tableConfig] = useState([
    {
      name: "",
      getValueCallback: (rowData) => (
        <IconButton
          onClick={() => {
            onClickEdit(rowData);
          }}
        >
          <ModeEditIcon fontSize="small" />
        </IconButton>
      ),
      label: "",
      filterType: undefined,
      getFilterFieldConfigCallback: (filteringData) => undefined,
      allowChangeVisibility: false,
    },
    {
      name: "skill_name",
      getValueCallback: (rowData) => rowData.skill_name,
      label: i18n.t("table.skill_has_user.skill"),
      filterType: TEXT_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => undefined,
      allowChangeVisibility: false,
      sortable: true,
    },
    {
      name: "skill_desc",
      getValueCallback: (rowData) => rowData.skill_desc,
      label: i18n.t("table.skill_has_user.description"),
      filterType: TEXT_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => undefined,
      allowChangeVisibility: false,
    },
    {
      name: "leaders",
      getValueCallback: (rowData) => {
        return rowData.leaders.map((obj) => obj.full_name).join(", ");
      },
      label: i18n.t("table.skill_has_user.leaders"),
      filterType: AUTOCOMPLETE_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => ({
        options: filteringData.leaders,
        isObjectOption: true,
        optionLabelKey: "full_name",
        multiple: true,
      }),
      allowChangeVisibility: false,
    },
    {
      name: "employees",
      getValueCallback: (rowData) => {
        return rowData.employees.map((obj) => obj.full_name).join(", ");
      },
      label: i18n.t("table.skill_has_user.employees"),
      filterType: AUTOCOMPLETE_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => ({
        options: filteringData.employees,
        isObjectOption: true,
        optionLabelKey: "full_name",
        multiple: true,
      }),
      allowChangeVisibility: false,
    },
  ]);

  const skillHasUserTableData = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        tableConfig,
        skillHasUser || [],
        [],
        undefined
      ),
    [skillHasUser, tableConfig]
  );

  return (
    <FilterTable
      data={skillHasUserTableData || []}
      headersConfig={TableService.getHeadersConfigForCollapsibleTable(
        tableConfig,
        filteringData,
        [],
        undefined
      )}
      withPagination={true}
      showCheckbox={false}
      filteringData={filteringData}
      filterPrefix={filterPrefix}
      showCleanFilterIcon={true}
      countRecords={countRecords}
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={onRowsPerPageChange}
      resetPageNumber={resetPageNumber}
      initialExpandedFilterHeader={initialExpandedFilterHeader}
    />
  );
};

SkillHasUserTable.propTypes = {
  skillHasUser: PropTypes.object,
  filteringData: PropTypes.array,
  filterPrefix: PropTypes.string,
  countRecords: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  resetPageNumber: PropTypes.func,
  initialExpandedFilterHeader: PropTypes.bool,
};

SkillHasUserTable.defaultProps = {
  filteringData: [],
  initialExpandedFilterHeader: false,
};

export default SkillHasUserTable;
