import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import { UNIQUE_ERROR_TEXT } from "../../../helpers/constants";

const MailInputForm = ({
  mail,
  isMailValid,
  onChange }
) => {

  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"mail_alias"}
          value={mail?.mail_alias}
          label={t(`form.mail_input_form.mail_alias`)}
          onChange={onChange}
          helperText = {isMailValid ? "" :t("form.mail_input_form.uncorrect_mail")}
          helperTextStyle = {UNIQUE_ERROR_TEXT.helperTextStyle}
        />
      </Grid>
    </>
  );
};

MailInputForm.propTypes = {
  mail: PropTypes.object,
  isMailValid : PropTypes.bool,
  onChange: PropTypes.func
};

MailInputForm.defaultProps = {
  isMailValid : true
};

export default MailInputForm;
