import { Navigate } from "react-router-dom";
import { useAuth } from "../../../context/auth";
import hasUserPermission, {
  IS_ADMIN,
  IS_USERS_ADMIN,
  IS_TENANTS_ADMIN,
  IS_OBJECTS_ADMIN,
  IS_STD_TICKETS_ADMIN,
  IS_AUTO_TICKETS_ADMIN,
} from "../../../helpers/userPermissions";

const AdminPanelRedirectRoute = () => {
  const { user } = useAuth();
  let redirectLink = "/login/";

  if (hasUserPermission([IS_USERS_ADMIN], user)) redirectLink = "/admin_users/";
  else if (hasUserPermission([IS_TENANTS_ADMIN], user))
    redirectLink = "/admin_tenants/";
  else if (hasUserPermission([IS_OBJECTS_ADMIN], user))
    redirectLink = "/admin_locations/";
  else if (hasUserPermission([IS_STD_TICKETS_ADMIN], user))
    redirectLink = "/admin_std_tickets_orders/";
  else if (hasUserPermission([IS_AUTO_TICKETS_ADMIN], user))
    redirectLink = "/admin_std_tickets_orders_automat/";
  else if (hasUserPermission([IS_ADMIN], user)) redirectLink = "/admin_users/";

  return <Navigate to={redirectLink} />;
};

export default AdminPanelRedirectRoute;
