export const SEARCH_PARAMS_PAGE_KEY = "page";
export const SEARCH_PARAMS_PAGE_SIZE_KEY = "page_size";
export const SEARCH_PARAMS_SUMMARY_PAGE_KEY = "summary_page";
export const SEARCH_PARAMS_SUMMARY_PAGE_SIZE_KEY = "summary_page_size";

export const SEARCH_PARAMS_PAGE_KEYS = [
  SEARCH_PARAMS_PAGE_KEY,
  SEARCH_PARAMS_PAGE_SIZE_KEY,
  SEARCH_PARAMS_SUMMARY_PAGE_KEY,
  SEARCH_PARAMS_SUMMARY_PAGE_SIZE_KEY,
];

export const DEAULT_VALUE_FOR_SUMMARY_PAGE_KEY = "1";
export const DEAULT_VALUE_FOR_SEARCH_PARAMS_SUMMARY_PAGE_KEY = ["1"];
