import i18n from "../i18n";

import { BOOLEAN_FIELD_TYPE } from "../helpers/constants";

import { reverseLookupForTrueFalseSelectField } from "../components/field/TrueFalseSelectField/trueFalseSelectField";

const UserEmailNotificationsService = {
  getEmailNotificationsNameLabelPairs: function () {
    return [
      {
        name: "new_ticket_behalf_on_om",
        label: i18n.t("table.user_email_notifications.new_ticket_behalf_on_om"),
      },
      {
        name: "change_name",
        label: i18n.t("table.user_email_notifications.change_name"),
      },
      {
        name: "change_location",
        label: i18n.t("table.user_email_notifications.change_location"),
      },
      {
        name: "change_ticket_k",
        label: i18n.t("table.user_email_notifications.change_ticket_k"),
      },
      {
        name: "change_status",
        label: i18n.t("table.user_email_notifications.change_status"),
      },
      {
        name: "new_message",
        label: i18n.t("table.user_email_notifications.new_message"),
      },
      {
        name: "change_offer_status",
        label: i18n.t("table.user_email_notifications.change_offer_status"),
      },
      {
        name: "change_order_responsible",
        label: i18n.t(
          "table.user_email_notifications.change_order_responsible"
        ),
      },
      {
        name: "send_offer_to_join",
        label: i18n.t("table.user_email_notifications.send_offer_to_join"),
      },
    ];
  },

  emailNotificationNamesForOm: [
    "new_ticket_behalf_on_om",
    "change_name",
    "change_location",
    "change_status",
    "new_message",
  ],

  getEmailNotificationsNameLabelPairsForOMs: function () {
    return this.getEmailNotificationsNameLabelPairs().filter((en) =>
      this.emailNotificationNamesForOm.includes(en.name)
    );
  },

  getEmailNotificationsTableConfig: function (
    emailNotificationsNameLabelPairs
  ) {
    return emailNotificationsNameLabelPairs.map((notification) => ({
      ...notification,
      headerSx: { whiteSpace: "normal" },
      getValueCallback: (rowData) =>
        reverseLookupForTrueFalseSelectField(
          rowData.notifications_visibility[notification.name]
        ),
      filterType: BOOLEAN_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => undefined,
    }));
  },

  getEmailNotificationsTableConfigForOMs: function () {
    return this.getEmailNotificationsTableConfig(
      this.getEmailNotificationsNameLabelPairsForOMs()
    );
  },
};

export default UserEmailNotificationsService;
