import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import MenuBar from "../../bar/MenuBar/MenuBar";
import PageFooter from "../../pageFooter/PageFooter";
import { Helmet } from "react-helmet";
import TITLE_DICT from "../../base/TitleTypography/titleDict";

export default function DefaultPageWrapper(props) {
  const getTagTitle = () => {
    let tagTitle = "MySolvy";

    if (props.tagTitle) {
      tagTitle += " | " + props.tagTitle;
    } else if (props.titleKey) {
      tagTitle += " | " + TITLE_DICT[props.titleKey]?.label;
    }

    return tagTitle;
  };

  return (
    <>
      <Helmet>
        <title>{getTagTitle()}</title>
      </Helmet>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12}>
          <MenuBar style={{ margin: 0 }} titleKey={props.titleKey} />
        </Grid>
        <Grid item xs={12} minHeight={{ xs: "70vh", md: "75vh" }}>
          {props.children}
        </Grid>
        <Grid item xs={12} textAlign={"left"}>
          <PageFooter />
        </Grid>
      </Grid>
    </>
  );
}

DefaultPageWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired),
  ]),
  titleKey: PropTypes.string.isRequired,
  tagTitle: PropTypes.string.isRequired,
};

DefaultPageWrapper.defaultProps = {};
