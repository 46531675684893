import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid} from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";

const WarLocationForm = (props) => {
  const { t } = useTranslation();
  
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={1}
    >
      <Grid item xs={12}>
        <TextFieldFormControl
          name="warloc_rack_nr"
          value={props.locationData?.warloc_rack_nr}
          label={t("form.war_location_form.rack_nr")}
          onChange={props.onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="warloc_rack_name"
          value={props.locationData?.warloc_rack_name}
          label={t("form.war_location_form.rack_name")}
          onChange={props.onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="warloc_shell_nr"
          value={props.locationData?.warloc_shell_nr}
          label={t("form.war_location_form.shell_nr")}
          onChange={props.onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="warloc_shell_name"
          value={props.locationData?.warloc_shell_name}
          label={t("form.war_location_form.shell_name")}
          onChange={props.onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="warloc_note"
          value={props.locationData?.warloc_note}
          label={t("form.war_location_form.note")}
          onChange={props.onChange}
          multiline
          rows={4}
          resize={"vertical"}
        />
      </Grid>

    </Grid>
  );
};

WarLocationForm.propTypes = {
  locationData: PropTypes.object,
  onChange : PropTypes.func
};

WarLocationForm.defaultProps = {
};

export default WarLocationForm
