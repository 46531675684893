import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import "./SelectField.css";
import { IconButton, OutlinedInput } from "@mui/material";
import ValidateDataService from "../../../services/validatateDataService";
import ClearIcon from "@mui/icons-material/Clear";
import FormHelperText from "@mui/material/FormHelperText";

export default function SelectField(props) {
  const axiosPrivate = useAxiosPrivate();
  const [options, setOptions] = useState(props.options);

  useEffect(() => {
    if (props.options_url !== undefined) {
      axiosPrivate
        .get(props.options_url)
        .then((result) => {
          setOptions(result.data);
        })
        .catch(() => {});
    }
  }, [props.options_url]);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const getOptionsWithoutExcluded = () => {
    if (options !== undefined && props.excludedOptions.length > 0) {
      return options.filter(function (obj) {
        return !props.excludedOptions.includes(obj[optionKey]);
      });
    }
    return options;
  };

  const getOptionValue = (option) => {
    if (props.isObjectOption) {
      return option[props.optionKey];
    }
    return option;
  };

  const checkValueIsInOptions = (value) => {
    return options.findIndex((x) => getOptionValue(x) === value) > -1;
  };
  const {
    onChange,
    value,
    label,
    optionLabel,
    optionKey,
    name,
    size,
    disabled,
    required,
    addEmptyOptions,
    emptyOptionsLabel,
    notShowIfEmptyOptions,
  } = props;

  if (
    notShowIfEmptyOptions === true &&
    options !== undefined &&
    getOptionsWithoutExcluded().length === 0
  ) {
    //not show element if options are empty
    return null;
  }

  const getValueForSelectField = () => {
    if (!props.multiple) return checkValueIsInOptions(value) ? value : "";
    if (value instanceof Array) return value;
    return ValidateDataService.isEmptyValue(value) ? [] : [value];
  };

  return (
    <FormControl
      fullWidth={props.fullWidth}
      size={size}
      style={{ textAlign: "left" }}
      disabled={disabled || props.readOnly}
      required={required}
      onBlur={props.onBlur}
      variant={props.variant}
      error={props.error}
      helperText={props.helperText}
    >
      <InputLabel id="select-label">
        {label.charAt(0).toUpperCase() + label.slice(1)}
      </InputLabel>
      <Select
        labelId="select-label"
        id={props.id ? props.id : props.name}
        value={getValueForSelectField()}
        label={label}
        onChange={(e) => {
          e.target.onChange = true;
          onChange(e, options);
        }}
        name={name}
        inputProps={{ readOnly: props.readOnly }}
        multiple={props.multiple}
        input={
          props.multiple && props.variant !== "standard" ? (
            <OutlinedInput label={label}
            error={props.error}
            helperText={props.helperText} />
          ) : undefined
        }
        style={props.style}
        helperTextStyle={props.helperTextStyle}
        onClose={props.onClose}
        sx={props.sx}
        onBlur={props.onBlur}
        ref={props.ref}
        open={props.open}
        error={props.error}
        helperText={props.helperText}
        endAdornment={
          !props.readOnly &&
          props.onClear &&
          value && (
            <IconButton
              size={props.size}
              sx={{ marginRight: 2.5 }}
              onClick={props.onClear}
            >
              <ClearIcon fontSize="inherit" />
            </IconButton>
          )
        }
      >
        {![undefined, null, ""].includes(value) && addEmptyOptions && (
          <MenuItem key={"null"} value={null}>
            {emptyOptionsLabel}
          </MenuItem>
        )}
        {getOptionsWithoutExcluded().map((item, index) => (
          <MenuItem
            key={index}
            value={props.isObjectOption ? item[optionKey] : item}
          >
            {props.isObjectOption ? item[optionLabel] : item}
          </MenuItem>
        ))}
      </Select>
      {props.helperText && (
        <FormHelperText
          id="component-helper-text"
          error={props.error}
          style={props.helperTextStyle}
        >
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

SelectField.propTypes = {
  options: PropTypes.array,
  optionLabel: PropTypes.string,
  optionKey: PropTypes.string,
  onChange: PropTypes.func,
  options_url: PropTypes.string,
  label: PropTypes.string,
  noOptionsText: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.any,
  ]),
  size: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  addEmptyOptions: PropTypes.bool,
  emptyOptionsLabel: PropTypes.string,
  notShowIfEmptyOptions: PropTypes.bool,
  excludedOptions: PropTypes.array,
  readOnly: PropTypes.bool,
  isObjectOption: PropTypes.bool,
  multiple: PropTypes.bool,
  onBlur: PropTypes.func,
  onClose: PropTypes.func,
  inputStyle: PropTypes.object,
  sx: PropTypes.object,
  onClear: PropTypes.func,
  helperText: PropTypes.string,
  ref: PropTypes.any,
  open: PropTypes.bool,
  variant: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.bool,
};

SelectField.defaultProps = {
  options: [],
  optionLabel: "name",
  optionKey: "id",
  options_url: undefined,
  label: "",
  value: "",
  size: "small",
  disabled: false,
  required: false,
  fullWidth: true,
  addEmptyOptions: false,
  notShowIfEmptyOptions: false,
  excludedOptions: [],
  readOnly: false,
  isObjectOption: true,
  multiple: false,
  onBlur: () => {},
  onClose: () => {},
  style: {},
  sx: {},
  helperText: "",
  variant: "outlined",
};
