import React from "react";
import WarningIcon from "@mui/icons-material/Warning";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";

const WarningAlertIcon = React.memo((props) => {
  return (
    <Tooltip
      title={props.tooltipTitle}
      style={props.style}
      onClick={props.onClick}
      arrow
    >
      <WarningIcon color="warning" />
    </Tooltip>
  );
});

WarningAlertIcon.propTypes = {
  tooltipTitle: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

WarningAlertIcon.defaultProps = {
  tooltipTitle: undefined,
  onClick: undefined,
};

export default WarningAlertIcon;
