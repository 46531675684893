import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer, {
  NAVIGATION_DRAWER_TYPE_GUARANTEE,
} from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import useDialog from "../../hooks/useDialog";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";
import TableService from "../../services/tableService";
import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import GuaranteeToolBar from "../../components/bar/GuaranteeToolBar";
import {
  GUARANTEE_TABLE,
  GUARANTEE_SCOPE_TABLE,
  GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE,
  GUARANTEE_GUARANTEE_SCOPE_TABLE_CONFIGS,
  GUARANTEE_SCOPE_TABLE_CONFIGS,
} from "./PageTablesConfig";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import GuaranteeGuaranteeScopeSubpage from "./GuaranteeGuaranteeScopeSubpage";
import GuaranteeScopeSubpage from "./GuaranteeScopeSubpage";
import GuaranteeDialog from "../../components/dialog/GuaranteeDialog";

export default function GuaranteePage(props) {
  const { pageName } = props;
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const snackbarAlert = useSnackbarAlert();

  const [isGuaranteeTableView, setIsGuaranteeTableView] = useState(true);

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const [openGuaranteeDialog, onOpenGuaranteeDialog, onCloseGuaranteeDialog] =
    useDialog();

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const handleChangeTablesView = (isGuaranteeTableViewVisibility) => {
    setIsGuaranteeTableView(isGuaranteeTableViewVisibility);
  };

  const handleClickVisibilityState = (filterKey) => {
    switch (filterKey) {
      case "guarantee":
        handleChangeTablesView(true);
        setSearchParams({});
        break;
      case "guarantee_scope":
        handleChangeTablesView(false);
        setSearchParams({});
        break;
      default:
        setSearchParams({});
    }
  };

  const filterChoiceButtonOptions = [
    {
      label: t("page.guarantee_page.guarantee"),
      key: "guarantee",
      callback: () => handleClickVisibilityState("guarantee"),
    },
    {
      label: t("page.guarantee_page.guarantee_scope"),
      key: "guarantee_scope",
      callback: () => handleClickVisibilityState("guarantee_scope"),
    },
  ];

  const isLoading = userPreferencesForPage.loading;

  const getPageContent = () =>
    isGuaranteeTableView ? (
      <GuaranteeGuaranteeScopeSubpage
        hiddenColumnsForTables={getHiddenColumnFormValueFromBackend(
          [GUARANTEE_TABLE, GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE],
          userPreferencesForPage.value ? userPreferencesForPage.value : []
        )}
        columnsOrdersForTables={getColumnOrdersFormValueFromBackend(
          [GUARANTEE_TABLE, GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE],
          userPreferencesForPage.value ? userPreferencesForPage.value : []
        )}
      />
    ) : (
      <GuaranteeScopeSubpage
        hiddenColumnsForTables={getHiddenColumnFormValueFromBackend(
          [GUARANTEE_SCOPE_TABLE],
          userPreferencesForPage.value ? userPreferencesForPage.value : []
        )}
        columnsOrdersForTables={getColumnOrdersFormValueFromBackend(
          [GUARANTEE_SCOPE_TABLE],
          userPreferencesForPage.value ? userPreferencesForPage.value : []
        )}
      />
    );

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_GUARANTEE}
    >
      <DefaultPageWrapper titleKey={"guarantee_management"}>
        <LoaderWrapper showLoader={isLoading}>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <GuaranteeToolBar
                style={{ marginBlock: "5px" }}
                onClickAdjustTable={onOpenTableColumnVisibilityDialog}
                onClickCreateGuarantee={onOpenGuaranteeDialog}
                filterOptions={filterChoiceButtonOptions}
                isGuaranteeTableView={isGuaranteeTableView}
              />
            </Grid>
            <Grid item xs={12}>
              {getPageContent()}
            </Grid>
          </Grid>
          {openGuaranteeDialog && (
            <GuaranteeDialog
              open={openGuaranteeDialog}
              onClose={onCloseGuaranteeDialog}
              onSubmit={userPreferencesForPage.refetch}
            />
          )}

          {openTableColumnVisibilityDialog && (
            <TableColumnVisibilityDialog
              open={openTableColumnVisibilityDialog}
              onClose={onCloseTableColumnVisibilityDialog}
              onSubmit={handleUpdateUserPreferences}
              tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
                isGuaranteeTableView
                  ? GUARANTEE_GUARANTEE_SCOPE_TABLE_CONFIGS
                  : GUARANTEE_SCOPE_TABLE_CONFIGS,
                getColumnOrdersFormValueFromBackend(
                  isGuaranteeTableView
                    ? [GUARANTEE_TABLE, GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE]
                    : [GUARANTEE_SCOPE_TABLE],
                  userPreferencesForPage.value
                )
              )}
              tablesHiddenColumns={getHiddenColumnFormValueFromBackend(
                isGuaranteeTableView
                  ? [GUARANTEE_TABLE, GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE]
                  : [GUARANTEE_SCOPE_TABLE],
                userPreferencesForPage.value
              )}
              isLoading={userPreferencesForPage.loading}
            />
          )}
        </LoaderWrapper>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
