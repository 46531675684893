import { Button, Grid } from "@mui/material";
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LoadingButton from "@mui/lab/LoadingButton";

export default function BottomMyOfferAcceptButtonsBox(props) {
  const { t } = useTranslation();


  return (
    <Grid container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      style={{ marginBottom: "10px" }}>
      <Grid item xs={2.4}>

        <Button
          fullWidth
          variant="contained"
          disabled={props.loading}
          color={props.readOnly ? "primary" : "alternate"}
          onClick={props.onAcceptOfferByOm}>
          {props.readOnly ? t("dialog.generate_offer_dialog.show_payers") : props.isOfferCR ? t("dialog.generate_offer_dialog.accept_cr") : t("dialog.generate_offer_dialog.accept_offer")}
        </Button>

      </Grid>
      {props.hasCrs &&  
      <Grid item xs={2.4}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={props.loading}
          onClick={() => props.onOpenHistoryLogDialog(props.selectedOffer.id)}
        >
          {t("dialog.generate_offer_dialog.show_offer_changes")}
        </Button>
      </Grid>
}
      <Grid item xs={2.4}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={props.fileIsEmpty || props.loading}
          onClick={() => props.downloadFileByBlobUrl(props.pdfUrl, props.selectedOffer.offer_nr)}
        >
          {t("dialog.generate_offer_dialog.download_offer_document")}
        </Button>
      </Grid>
      <Grid item xs={2.4}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={props.loading}
          onClick={props.onClose}>
          {t("close")}
        </Button>
      </Grid>
      {!props.readOnly &&
        <Grid item xs={2.4}>
          <LoadingButton
            fullWidth
            variant="contained"
            color="error"
            loading={props.loading}
            onClick={props.onRejectOfferByOm} >
            {props.isOfferCR ? t("dialog.generate_offer_dialog.reject_cr") : t("dialog.generate_offer_dialog.reject_offer")}
          </LoadingButton>
        </Grid>
      }
    </Grid>
  );
}

BottomMyOfferAcceptButtonsBox.propTypes = {
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  validatedOffer: PropTypes.object,
  onSendOfferToRR: PropTypes.func,
  onSendOfferToOM: PropTypes.func,
  downloadFileByBlobUrl: PropTypes.func,
  fileIsEmpty: PropTypes.bool,
  pdfUrl: PropTypes.string,
  hasCrs : PropTypes.bool
}


BottomMyOfferAcceptButtonsBox.defaultProps = {
  readOnly: true,
  validatedOffer: {},
  fileIsEmpty: true,
  hasCrs : false

}
