import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import TextFieldFormControl from "../../field/TextFieldFormControl";

export default function TicketShortDetailsForm(props) {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12} sm={1.5}>
        <TextFieldFormControl
          name="ticket_nr"
          label={t("form.ticket.ticket_nr")}
          value={props.formValue.ticket_nr}
          readOnly={true}
        />
      </Grid>
      <Grid item xs={12} sm={2.5}>
        <TextFieldFormControl
          name="ticket_category"
          label={t("form.ticket.ticket_category")}
          value={props.formValue.ticket_category}
          readOnly={props.readOnly}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextFieldFormControl
          name="ticket_name"
          label={t("form.ticket.ticket_name")}
          value={props.formValue.ticket_name}
          readOnly={true}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextFieldFormControl
          name="ticket_priority"
          label={t("form.ticket.ticket_priority")}
          value={props.formValue.ticket_priority}
          readOnly={props.readOnly}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextFieldFormControl
          name="ticket_status"
          label={t("form.ticket.ticket_status")}
          value={props.formValue.ticket_status}
          readOnly={props.readOnly}
        />
      </Grid>
      {!props.readOnly && (
        <Grid item xs={12}>
          <TextFieldFormControl
            name="ticket_desc_creator"
            label={t("form.ticket.ticket_desc_res")}
            value={props.formValue.ticket_desc_creator}
            readOnly={true}
          />
        </Grid>
      )}
    </Grid>
  );
}

TicketShortDetailsForm.propTypes = {
  formValue: PropTypes.object,
  filteringData: PropTypes.object,
  readOnly: PropTypes.bool,
};

TicketShortDetailsForm.defaultProps = {
  formValue: {},
  readOnly: true,
};
