import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { isEmptyValue } from "../../../helpers/methods";
import useAddressService from "../../../services/addressService";
import AddressDetailsForm from "../../form/AddressDetailsForm/AddressDetailsForm";

const AddressDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const isZipFormatValid = true
  // const [isZipFormatValid, setIsZipFormatValid] = useState(true);
  const requiredFields = [
    "address_zip",
    "address_city",
    "address_street",
    "address_building",
    "address_kind",
  ];

  const zipFormatRegex = /^[0-9]{2}-[0-9]{3}$/;

  const { formValue, setFormValue, onChange } = useForm({});

  const {
    getAddressDetails,
    createAddress,
    updateAddress,
    getAddressFilteringData,
  } = useAddressService();

  const updateAddressFn = useAsyncFn(updateAddress);
  const createAddressFn = useAsyncFn(createAddress);

  const addressDetailsData = useAsync(() => {
    if (props.addressData) {
      return Promise.resolve(props.addressData);
    }
    return getAddressDetails(props.addressId);
  }, [props.addressId, props.addressData]);

  const addressFilteringData = useAsync(() => getAddressFilteringData(), []);

  useEffect(() => {
    if (addressDetailsData.loading) {
      return;
    }

    setFormValue(addressDetailsData.value);
  }, [addressDetailsData.loading]);

  // useEffect(() => {
  //   if (formValue.address_zip) {
  //     setIsZipFormatValid(validateZipFormat(formValue.address_zip));
  //   } else {
  //     setIsZipFormatValid(true);
  //   }
  // }, [formValue]);

  const prepareNewAddressData = () => {
    let tempAddress = formValue;
    tempAddress["owner"] = props.ownerId;
    tempAddress["subcontractor"] = props.subcontractorId;
    return tempAddress;
  };

  const onSubmit = useCallback(() => {
    if (props.onSubmitReplacement) {
      props.onSubmitReplacement(formValue);
      props.onClose();
      return;
    }

    if (props.addressId) {
      updateAddressFn
        .execute(props.addressId, formValue)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.address_updated")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_address_updating")
          );
        });
    } else {
      if (props.ownerId || props.subcontractorId) {
        createAddressFn
          .execute(prepareNewAddressData())
          .then((res) => {
            snackbarAlert.openSuccessSnackbarAlert(
              t("snackbar_alert.address_created")
            );
            props.onClose();
            props.onRefetch();
          })
          .catch((error) => {
            snackbarAlert.openErrorSnackbarAlert(
              t("snackbar_alert.occurred_error_during_address_creating")
            );
          });
      } else {
        props.onAddAddresForNewOwner(formValue);
        props.onClose();
      }
    }
  }, [formValue]);

  const isValid = formValue
    ? requiredFields.every(
        (fieldName) => !isEmptyValue(formValue[fieldName])
      ) && isZipFormatValid
    : false;

  const validateZipFormat = (zipCode) => {
    return zipFormatRegex.test(zipCode);
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={
        props.addressId || props.addressData
          ? t(`dialog.address_details_dialog.address_details`)
          : t(`dialog.address_details_dialog.create_address`)
      }
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        {addressDetailsData.loading || addressFilteringData.loading ? (
          <LoaderWrapper isLoading={true} />
        ) : (
          <>
            <Grid item xs={12}>
              <AddressDetailsForm
                addressData={formValue}
                onChange={onChange}
                isZipCodeValid={isZipFormatValid}
                addressKinds={addressFilteringData.value.address_kinds}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={updateAddressFn.loading}
                disabled={!isValid}
                onClick={onSubmit}
              >
                {props.addressId || props.addressData ? t("save") : t("create")}
              </LoadingButton>
            </Grid>
          </>
        )}
      </Grid>
    </BasicDialog>
  );
};

AddressDialog.propTypes = {
  ownerId: PropTypes.string,
  addressId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitReplacement: PropTypes.func,
  addressData: PropTypes.object,
};

AddressDialog.defaultProps = {
  open: false,
};

export default AddressDialog;
