import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import BaseBox from "../../base/BaseBox/baseBox";


const OfferContentForm = (props) => {

  const { t } = useTranslation();

  return (
    <BaseBox>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
          <Typography
            variant="subtitle2"
            className="capitalize-first-letter"
          >
            {t(`form.offer_content_form.offer_text`)}
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6} />
        <Grid item xs={12}>
          <TextFieldFormControl
            name={'offer_title'}
            label={t(`form.offer_content_form.offer_title`)}
            value={props.offer_title}
            onChange={props.onChange}
            disabled={props.readOnly}
            multiline={true}
            rows={2}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormControl
            name={'offer_content'}
            label={t(`form.offer_content_form.offer_content`)}
            value={props.offer_content}
            onChange={props.onChange}
            disabled={props.readOnly}
            multiline={true}
            rows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormControl
            name={'offer_exclusion'}
            label={t(`form.offer_content_form.offer_exclusion`)}
            value={props.offer_exclusion}
            onChange={props.onChange}
            disabled={props.readOnly}
            multiline={true}
            rows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormControl
            name={'offer_comments'}
            label={t(`form.offer_content_form.offer_comments`)}
            value={props.offer_comments}
            onChange={props.onChange}
            disabled={props.readOnly}
            multiline={true}
            rows={3}
          />
        </Grid>
      </Grid>
    </BaseBox>
  );
};

OfferContentForm.propTypes = {
  onSubmit: PropTypes.func,
  ticketId: PropTypes.string,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  onOpenAddNewSupplier: PropTypes.func,
  subcontractorSelectList: PropTypes.array
};

OfferContentForm.defaultProps = {
  readOnly: false,
  subcontractorSelectList: []
};

export default OfferContentForm;
