import { useState, useCallback, useMemo } from "react";

import {
  getObjectFromSearchParams,
  getFieldValueFromSearchParams,
} from "../helpers/methods";

import {
  ORDERING_DIRECTION_ASC,
  ORDERING_DIRECTION_DESC,
} from "../helpers/constants";

import usePaginationWithSearchParams from "./usePaginationWithSearchParams";

export default function usePaginationWithSearchParamsAndOrdering(
  filterPrefix = undefined,
  initialParams = undefined
) {
  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangePageSizeWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
    getFilterFieldKey,
  } = usePaginationWithSearchParams(filterPrefix, initialParams);

  const sortParams = useMemo(() => {
    const ordering = getFieldValueFromSearchParams(
      searchParams,
      getFilterFieldKey("ordering")
    );
    if (!ordering) return {};
    if (ordering.startsWith("-")) {
      return {
        sortColumn: ordering.substring(1),
        sortOrder: ORDERING_DIRECTION_DESC,
      };
    }
    return {
      sortColumn: ordering,
      sortOrder: ORDERING_DIRECTION_ASC,
    };
  }, [searchParams]);

  const handleSort = useCallback(
    (sortColumn, sortOrder) => {
      resetPageNumber();
      setSearchParams((prev) => {
        const currSearchParamsObject = getObjectFromSearchParams(searchParams);
        let ordering = sortColumn;
        if (sortOrder === ORDERING_DIRECTION_DESC) {
          ordering = `-${ordering}`;
        }
        return {
          ...currSearchParamsObject,
          [getFilterFieldKey("ordering")]: ordering,
          [getFilterFieldKey("page")]: 1,
        };
      });
    },
    [searchParams]
  );

  return {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangePageSizeWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
    getFilterFieldKey,
    sortParams,
    handleSort,
  };
}
