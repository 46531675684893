import { Grid } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";

import { NAVIGATION_DRAWER_TYPE_RR } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import RRAdminTabs from "../../components/tabs/RRAdminTabs/RRAdminTabs";

import { EDIT_RR_PANEL } from "../../helpers/constants";
import useCheckPermission from "../../hooks/usePermission";

import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

export default function RRAdminPage(props) {
  const { pageName } = props;

  const [hasPermissionToEditRRPanel, isLoading] =
    useCheckPermission(EDIT_RR_PANEL);

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_RR}
    >
      <DefaultPageWrapper titleKey={"rr_admin"}>
        <LoaderWrapper showLoader={isLoading}>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={1}
          >
            <Grid item xs={12}>
              <RRAdminTabs
                pageName={pageName}
                readOnly={!hasPermissionToEditRRPanel}
              />
            </Grid>
          </Grid>
        </LoaderWrapper>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
