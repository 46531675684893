import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useEffect, useState } from "react";
import { INTERNAL_TYPE, MAX_ALLOWED_FILE_SIZE_IN_MB, MAX_FILENAME_LENGTH_CHARS } from "../../../helpers/constants";
import EnclosuresWindow from "../../window/EnclosuresWindow";

export default function AddEnclosureSimplyForm(props) {

  const { t } = useTranslation();


  useEffect(() => {
    props.formValue["enclosure_type"] = props.enclosureType
    props.formValue["enclosure_subcontractor"] = false
  }, [props.formValue]);
  

  const validated = props.enclosures.length > 0
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >

      <Grid item xs={12} sm={12}>
        <Button size="medium" variant="contained" component="label" fullWidth>
          {t("form.add_enclosure_form.select_file")}
          <input
            type="file"
            hidden
            onChange={props.onChooseFile}
            multiple
          />
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" > {t("form.add_enclosure_form.max_file_size") + ` ${ MAX_ALLOWED_FILE_SIZE_IN_MB}MB`} </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" > {t(`form.add_enclosure_form.max_file_name_${MAX_FILENAME_LENGTH_CHARS}_chars`)} </Typography>
      </Grid>

      <Grid item xs={12}>
        <EnclosuresWindow
          enclosureWindowStyle={{}}
          enclosures={props.enclosures}
          showCreator={false}
          onDownload={props.onDownload}
          onPreview={props.onPreview}
          onDeleteEnclosure={props.onDeleteEnclosure}
          showAddToFavorite={false}
          showPublishButton = {false}
          showDetailsButton = {false}
          showDownloadButton = {false}
          canHideEnclosures={true}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="enclosure_note"
          label={t("form.add_enclosure_form.enclosure_note")}
          value={props.formValue.enclosure_note}
          multiline={true}
          rows={4}
          onChange={props.onChange}
        />
      </Grid>


      <Grid item xs={12}>
        <Button
          disabled={!validated}
          variant="contained"
          onClick={props.onSubmit}
          component="label"
          fullWidth
          startIcon={<FileUploadIcon />}
        >
          {props.submitButtonLabel ? props.submitButtonLabel : t("form.add_enclosure_form.save_file")}
        </Button>
      </Grid>

    </Grid>
  );
}

AddEnclosureSimplyForm.propTypes = {
  onChange: PropTypes.func,
  formValue: PropTypes.object,
  submitButtonLabel: PropTypes.string,
  enclosureType: PropTypes.string
};

AddEnclosureSimplyForm.defaultProps = {
  formValue: {},
  showEnclosureType: false,
  showSubcontractorSelect: false,
  enclosureType: INTERNAL_TYPE
};
