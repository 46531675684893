import { useState } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import BasicDialog from "../../base/BasicDialog";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import AutocompleteField from "../../field/AutocompleteField";
import { Button, Grid } from "@mui/material";

const SelectPoDialog = (props) => {
  const { t } = useTranslation();

  const [selectedPo, setSelectedPo] = useState();


  const onChangeAutocompleteFieldWithObjectOptions = (
    e,
    value_object,
    value_key,
    state_value_name
  ) => {

      setSelectedPo(value_object);
  };


  const getDialogContent = () => {
    if (props.loading) return <LoaderWrapper showLoader={true} />; 
    return (
      <>
        <Grid item xs={12}>
          <AutocompleteField
            name="po"
            label={t("dialog.select_po_dialog.purchase_order_number")}
            value={selectedPo}
            options={props.selectList}
            isObjectOption={true}
            optionLabelKey={"po_nr"}
            addNewValue={false}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            required
          />
        </Grid>
        <Button
          sx={{ marginTop: "20px" }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => props.onSubmit(selectedPo)}
          disabled={!selectedPo}
        >
          {t("confirm")}
        </Button>
      </>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      titleAlign="center"
      maxWidth="sm"
      title={t("dialog.select_po_dialog.select_purchase")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

SelectPoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectList : PropTypes.array,
  loading : PropTypes.bool
}

SelectPoDialog.defaultProps = {
  selectList : [],
  loading : true
};

export default SelectPoDialog;
