import { useState } from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PasswordField from "../../field/PasswordField";
import LoadingButton from "@mui/lab/LoadingButton";

import TextFieldFormControl from "../../field/TextFieldFormControl";

import { useAuth } from "../../../context/auth";
import { useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import "./ExternalLoginForm.css";

export default function ExternalLoginForm(props) {
  const { t } = useTranslation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const snackbarAlert = useSnackbarAlert();

  const { login } = useAuth();
  const loginFn = useAsyncFn(login);

  const isFormValid = () => {
    return username.length === 0 || password.length === 0;
  };

  const isLoading = loginFn.loading;

  const onLogin = () => {
    loginFn
      .execute(username, password)
      .then(() => {
        snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.logged_in"));
      })
      .catch((error) => {
        if (error.status === 401) {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.bad_credentials")
          );
        } else {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.server_error")
          );
        }
      });
  };

  return (
    <Paper elevation={3} style={props.style} className={"p-10"}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={1}
      >
        <Grid item xs={12}>
          <Typography
            style={{ textTransform: "uppercase", color: "var(--primary)" }}
            variant="h5"
            display="block"
            gutterBottom
          >
            {t("form.auth.logging")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormControl
            label={t("form.auth.login")}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            label={t("form.auth.password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={isLoading}
            fullWidth
            type="submit"
            onClick={onLogin}
            disabled={isFormValid()}
            variant="contained"
          >
            {t("form.auth.login_in")}
          </LoadingButton>
        </Grid>
      </Grid>
    </Paper>
  );
}

ExternalLoginForm.propTypes = {
  style: PropTypes.object,
};
