import { useTranslation } from "react-i18next";
import { centerVericalAlignIconStyle } from "../../../helpers/styles";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { useSearchParams } from "react-router-dom";
import useFillterButton from "../../../hooks/useFilterButton";
import { useMemo } from "react";
import { getFieldValueFromSearchParams } from "../../../helpers/methods";
import SplitButton from "../SplitButton";
import PropTypes from "prop-types";
import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";

const PlannedUnplannedFilterButton = (props) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const { handleClickFilterButton } = useFillterButton(props.filterPrefix);
  const { getFilterFieldKey } = usePaginationWithSearchParams(
    props.filterPrefix
  );

  const plannedFilterKey = useMemo(
    () => getFilterFieldKey("planned"),
    [getFilterFieldKey]
  );

  const unplannedFilterKey = useMemo(
    () => getFilterFieldKey("unplanned"),
    [getFilterFieldKey]
  );

  const plannedUnplannedFilterKey = useMemo(
    () => getFilterFieldKey("planned_unplanned"),
    [getFilterFieldKey]
  );

  const filterOptions = [
    {
      label: t("button.planned_unplanned_filter_button.planned/unplanned"),
      key: plannedUnplannedFilterKey,
      callback: () => handleClickFilter(undefined),
    },
    {
      label: t("button.planned_unplanned_filter_button.unplanned"),
      key: unplannedFilterKey,
      callback: () => handleClickFilter(false),
      startIcon: <FilterAltOffIcon sx={centerVericalAlignIconStyle} />,
    },
    {
      label: t("button.planned_unplanned_filter_button.planned"),
      key: plannedFilterKey,
      callback: () => handleClickFilter(true),
      startIcon: <FilterAltIcon sx={centerVericalAlignIconStyle} />,
    },
  ];

  const selectedFilterKey = useMemo(() => {
    const filterValue = getFieldValueFromSearchParams(
      searchParams,
      getFilterFieldKey(props.plannedFilterKey)
    );
    if (filterValue === "true") return plannedFilterKey;
    if (filterValue === "false") return unplannedFilterKey;
    return plannedUnplannedFilterKey;
  }, [searchParams]);

  const handleClickFilter = (filterValue) => {
    handleClickFilterButton(filterValue, props.plannedFilterKey);
  };

  return (
    <SplitButton
      selectedItemKey={selectedFilterKey}
      withChangeSelectedItem={true}
      options={filterOptions}
    />
  );
};

PlannedUnplannedFilterButton.propTypes = {
  plannedFilterKey: PropTypes.string,
  filterPrefix: PropTypes.string,
};

PlannedUnplannedFilterButton.defaultProps = {
  plannedFilterKey: "is_planned",
};

export default PlannedUnplannedFilterButton;
