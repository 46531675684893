import { useState, useEffect, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import useDialog from "../../hooks/useDialog";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";
import TableService from "../../services/tableService";
import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import useBasicDrawer from "../../hooks/useBasicDrawer";
import OfferTable from "../../components/table/OfferTable/OfferTable";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import useOfferService from "../../services/offerService";
import DetailsDrawer from "../../components/drawer/UniversalDetailsDrawer/UniversalDetailsDrawer";
import { useAuth } from "../../context/auth";
import OfferConfiguratorDialog from "../../components/dialog/OfferConfiguratorDialog";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import {
  OFFER_TABLE_FIELDS_CONFIG,
  OFFER_TABLE_NAME,
} from "../OffersManagmentPage/PageTablesConfig";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import TuneIcon from "@mui/icons-material/Tune";
import { centerVericalAlignIconStyle } from "../../helpers/styles";
import JoinOfferButton from "../../components/button/JoinOfferButton/JoinOfferButton";
import { NAVIGATION_DRAWER_TYPE_OFFERS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import { overflowButtonStyle } from "../../helpers/styles";

export default function JoinOffersPage(props) {
  const { pageName } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [offersDataLocaly, setOffersDataLocaly] = useState([]);
  const [countOfferRecords, setCountOfferRecords] = useState();

  const [hasChangesOnDialog, setHasChangesOnDialog] = useState(false);

  const [openDetailsDrawer, setOpenDetailsDrawer] = useBasicDrawer(false);
  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();
  const [chosenOfferDetails, setChosenOfferDetails] = useState(undefined);

  const [isJoinOfferView, setIsJoinOfferView] = useState(true);

  const openedOfferIdRef = useRef();
  const selectedOfferIdToConfiguratorRef = useRef();

  const [searchParamsFromPage, setSearchParamsFromPage] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(searchParams.entries());
  });

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const [
    openOfferConfiguratorDialog,
    onOpenOfferConfiguratorDialog,
    onCloseOfferConfiguratorDialog,
  ] = useDialog();

  const {
    page: offerDataPage,
    pageSize: offerDataPageSize,
    handleChangePageWithSearchParams: handleChangeOfferDataPage,
    handleChangePageSizeWithSearchParams: handleChangeOfferDataPageSize,
    resetOfferPageNumber,
  } = usePaginationWithSearchParams();

  const {
    getOffersFilteringData,
    getOfferDrawerDetailsData,
    updateOfferData,
    getOfferJoinPageDataByView,
  } = useOfferService();

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const offersData = useAsync(
    () => getOfferJoinPageDataByView(isJoinOfferView, searchParams),
    [isJoinOfferView, searchParams]
  );

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const offerFilteringData = useAsync(getOffersFilteringData);

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const offersDetailsFn = useAsyncFn(getOfferDrawerDetailsData);

  const updateOfferDataFn = useAsyncFn(updateOfferData);

  useEffect(() => {
    if (offersData.loading) {
      return;
    }
    setOffersDataLocaly(offersData.value.results);
    setCountOfferRecords(offersData.value.count);
  }, [offersData.loading]);

  useEffect(() => {
    if (userPreferencesForPage.loading) {
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [OFFER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [OFFER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);

  const handleChangeOfferRowsPerPage = (e) => {
    handleChangeOfferDataPageSize(e, parseInt(e.target.value, 10));
  };

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        setOffersDataLocaly(undefined);
        offersData.refetch();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const handleClickOfferRow = (offerId) => {
    if (!offerId || offerId === openedOfferIdRef.current) {
      openedOfferIdRef.current = null;
      setOpenDetailsDrawer(false);
    } else {
      openedOfferIdRef.current = offerId;
      setOpenDetailsDrawer(true);
      offersDetailsFn.execute(openedOfferIdRef.current).then((data) => {
        setChosenOfferDetails(data);
      });
    }
  };

  const handleClickVisibilityState = (filterKey) => {
    switch (filterKey) {
      case "my-offers":
        setIsJoinOfferView(true);
        break;
      case "all-offers":
        setIsJoinOfferView(false);
        break;
      default:
        setIsJoinOfferView(true);
    }
  };

  const handleOpenOffer = (offerId) => {
    selectedOfferIdToConfiguratorRef.current = offerId;
    onOpenOfferConfiguratorDialog();

    const newSearchParams = new URLSearchParams(window.location.search);
    setSearchParamsFromPage(Object.fromEntries(newSearchParams.entries()));
    setSearchParams({});
  };

  const handleToCloseConfiguratorOffer = useCallback((setParams = true) => {
    onCloseOfferConfiguratorDialog();
    selectedOfferIdToConfiguratorRef.current = null;
    openedOfferIdRef.current = null;
    setOpenDetailsDrawer(false);
    if (setParams) {
      setSearchParams({ ...searchParamsFromPage });
      setSearchParamsFromPage({});
    }
    offersData.refetch();
  }, []);

  const filterChoiceButtonOptions = [
    {
      label: t("button.ticket_offer_button.all_offers"),
      key: "all-offers",
      callback: () => handleClickVisibilityState("all-offers"),
    },
    {
      label: t("button.ticket_offer_button.my_offers"),
      key: "my-offers",
      callback: () => handleClickVisibilityState("my-offers"),
    },
  ];

  const offerTableContextMenuActions = [
    {
      label: t("page.offers_join_page.open_offer"),
      callback: (offerId) => handleOpenOffer(offerId),
      icon: <ZoomInIcon fontSize="medium" />,
    },
  ];

  const isOfferTableLoading =
    offersData.loading ||
    userPreferencesForPage.loading ||
    hiddenColumnsForTables === undefined ||
    columnsOrdersForTables === undefined ||
    offerFilteringData.loading ||
    offersDataLocaly === undefined ||
    countOfferRecords === undefined;

  if (isOfferTableLoading) {
    return <LoaderWrapper isLoading={isOfferTableLoading}></LoaderWrapper>;
  }

  return (
    <DetailsDrawer
      openDrawer={openDetailsDrawer}
      setOpenDrawer={setOpenDetailsDrawer}
      itemData={chosenOfferDetails}
      itemType={"offer"}
      isLoading={offersDetailsFn.loading}
      readOnly={true}
    >
      <NavigationDrawer
        drawerType={NAVIGATION_DRAWER_TYPE_OFFERS}
        pageName={pageName}
      >
        <DefaultPageWrapper titleKey={"join_offers"}>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={1}
          >
            <Grid item xs={2} style={{ marginBlock: "5px" }}>
              <Button
                fullWidth
                variant="contained"
                size="small"
                startIcon={<TuneIcon sx={centerVericalAlignIconStyle} />}
                onClick={onOpenTableColumnVisibilityDialog}
                sx={overflowButtonStyle}
              >
                {t("bar.offer_tool_bar.adjust_table")}
              </Button>
            </Grid>
            <Grid item xs={8} />
            <Grid item xs={2} style={{ marginBlock: "5px" }}>
              <JoinOfferButton
                options={filterChoiceButtonOptions}
                isJoinOffersTableView={isJoinOfferView}
              />
            </Grid>
            <Grid item xs={12}>
              <OfferTable
                data={offersDataLocaly ? offersDataLocaly : []}
                showCheckbox={false}
                onClickRow={handleClickOfferRow}
                countRecords={countOfferRecords}
                page={offerDataPage}
                onPageChange={handleChangeOfferDataPage}
                rowsPerPage={offerDataPageSize}
                onRowsPerPageChange={handleChangeOfferRowsPerPage}
                resetPageNumber={resetOfferPageNumber}
                filteringData={offerFilteringData}
                hiddenColumns={
                  hiddenColumnsForTables
                    ? hiddenColumnsForTables[OFFER_TABLE_NAME]
                    : []
                }
                columnsOrders={
                  columnsOrdersForTables
                    ? columnsOrdersForTables[OFFER_TABLE_NAME]
                    : undefined
                }
                selectedOfferId={openedOfferIdRef.current}
                showTitle={false}
                tableConfig={OFFER_TABLE_FIELDS_CONFIG}
                filterPrefix={undefined}
                showContextMenu={true}
                contextMenuActions={offerTableContextMenuActions}
              />
            </Grid>
          </Grid>

          {openTableColumnVisibilityDialog && (
            <TableColumnVisibilityDialog
              open={openTableColumnVisibilityDialog}
              onClose={onCloseTableColumnVisibilityDialog}
              onSubmit={handleUpdateUserPreferences}
              tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
                [{ name: OFFER_TABLE_NAME, config: OFFER_TABLE_FIELDS_CONFIG }],
                columnsOrdersForTables
              )}
              tablesHiddenColumns={hiddenColumnsForTables}
              isLoading={userPreferencesForPage.loading}
            />
          )}

          {openOfferConfiguratorDialog && (
            <OfferConfiguratorDialog
              open={openOfferConfiguratorDialog}
              offerId={selectedOfferIdToConfiguratorRef.current}
              dialogMode={isJoinOfferView ? "joinOffer" : "preview"}
              filteringData={offerFilteringData.value}
              onClose={handleToCloseConfiguratorOffer}
            />
          )}
        </DefaultPageWrapper>
      </NavigationDrawer>
    </DetailsDrawer>
  );
}
