import BasicDialog from "../../base/BasicDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DetailsDrawer from "../../drawer/UniversalDetailsDrawer/UniversalDetailsDrawer";
import { useCallback, useEffect, useRef, useMemo, useState } from "react";
import {
  DETAILS_DRAWER_WIDTH,
  EXTERNAL_TYPE,
  MINI_DETAILS_DRAWER_WIDTH,
  OFFER_ACCEPED_BY_RR,
  OFFER_ACCEPED_INTERNAL,
  OFFER_CANCEL_ACTION,
  OFFER_JOINED_ACTION,
  OFFER_JOIN_ACTION,
  OFFER_SEND_TO_OM,
  OFFER_SEND_TO_RR,
  TEXT_FIELD_TYPE,
} from "../../../helpers/constants";
import useOfferService from "../../../services/offerService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import useTicketService from "../../../services/ticketService";
import FunctionsDrawer, { FUNCTION_DRAWER_TYPE_OFFERS } from "../../drawer/FunctionsDrawer/FunctionsDrawer";
import { openMiniDrawerWidth } from "../../drawer/MiniDrawer/MiniDrawer";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useForm } from "../../../hooks/useForm";
import useDialog from "../../../hooks/useDialog";
import CreateItemDialog from "../CreateItemDialog";
import UnsavedDataDialog from "../UnsavedDataDialog/UnsavedDataDialog";
import useOfferServiceData from "../../../hooks/useOfferServiceData";
import useOfferPartData from "../../../hooks/useOfferPartData";
import OfferItemTable from "../../table/OfferItemTable";
import { Grid, Typography } from "@mui/material";
import FileUploadList from "../../other/FileUploadList";
import OfferContentForm from "../../form/OfferContentForm/OfferContentForm";
import useEnclosuresData from "../../../hooks/useEnclosuresData";
import BaseBox from "../../base/BaseBox/baseBox";
import useOfferTableService from "../../../services/offerTableService";
import OfferDiscountForm from "../../form/OfferDiscountForm";
import OfferClauseForm from "../../form/OfferClauseForm/OfferClauseForm";
import CancelOfferDialog from "../CancelOfferDialog";
import SelectUserDialog from "../SelectUserDialog";
import NotesDialog from "../NotesDialog/NotesDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import OfferDocumentDialog from "../OfferDocumentDialog";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import OfferTableSummaryForm from "../../form/OfferTableSummaryForm/OfferTableSummaryForm";
import {
  OFFER_CLAUSE_TABLE_FIELDS_CONFIG,
  OFFER_ITEM_SUMMARY_TABLE_FIELDS_CONFIG,
  OFFER_PARTS_TABLE_FIELDS_CONFIG,
  OFFER_SERVICES_TABLE_FIELDS_CONFIG,
} from "./DialogTablesConfig";
import useSettlementData from "../../../hooks/useSettlementData";
import { SETTLEMENT_TABLE_FIELDS_CONFIG } from "../../form/OfferSettlementsForm/FormTablesConfig";
import OfferSettlementDialog from "../OfferSettlementDialog/OfferSettlementDialog";
import useUserService from "../../../services/userService";
import useSettlementTableService from "../../../services/settlementTableService";
import OfferSaveErrorDialog from "../OfferSaveErrorDialog";
import ClausesDialog from "../ClausesDialog";
import useClausesData from "../../../hooks/useClausesData";
import useFieldValidation from "../../../hooks/useFieldValidation";
import { isEmptyValue } from "../../../helpers/methods";
import IsTestingAppWrapper from "../../wrapper/IsTestingAppWrapper/IsTestingAppWrapper";
import SelectItemByTreeDialog from "../SelectItemByTreeDialog";
import OfferDataRequestDialog from "../OfferDataRequestDialog";


function OfferConfiguratorDialog(props) {
  const { t } = useTranslation();

  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);

  const snackbarAlert = useSnackbarAlert();

  const [createItemType, setCreateItemType] = useState();

  const [totalItemsCost, setTotalItemsCost] = useState([]);

  const [isReadOnly, setIsReadOnly] = useState(true);

  const [serviceTableIsLoading, setServiceTableIsLoading] = useState(false);

  const [unfillRequiredFields, setUnfillRequiredFields] = useState([]);

  const [isEmptyOfferCost, setIsEmptyOfferCost] = useState(false);

  const [dialogMode, setDialogMode] = useState(props.dialogMode);

  const [isAllowedToCopyForNewVersion, setIsAllowedToCopyForNewVersion] = useState(false);

  const [isAllowedToCreateInnerOffer, setIsAllowedToCreateInnerOffer] = useState(false);

  const [isAllowedToChangePayers, setIsAllowedToChangePayers] = useState(false);

  const [serviceOfferTableConfigLocal, setServiceOfferTableConfigLocal] = useState(OFFER_SERVICES_TABLE_FIELDS_CONFIG)
  const [partOfferTableConfigLocal, setPartOfferTableConfigLocal] = useState(OFFER_PARTS_TABLE_FIELDS_CONFIG)
  const [isAllowedToCancel, setIsAllowedToCancel] = useState(false);

  const [isAllowedToRequestChangeData, setIsAllowedToRequestChangeData] = useState(false);

  const [hasChangesOnDialog, setHasChangesOnDialog] = useState(false);

  const [isSavingData, setIsSavingData] = useState(false);

  const [localOfferId, setLocalOfferId] = useState(props.offerId);

  const {
    getOfferFieldsNameToSave 
} = useOfferTableService();

  const {
    getOfferDetailsData,
    getExtendServicesSelectListData,
    getExtendPartsSelectListData,
    createNewOffer,
    updateOfferData,
    genereteOffer,
    createNewOfferVersion,
    createCopyOfferForUpdate,
    createInnerOffer,
  } = useOfferService();

  const {
    enclosures,
    saveEnclosures,
    onPreviewEnclosure,
    onDownloadEnclosure,
    onDeletePermanentlyOfferEnclosure,
    updateOfferEnclosure,
  } = useEnclosuresData(localOfferId, undefined, "offer");

  const { calculateItemsTotalCost } = useOfferTableService();

  const { setSettlementCalculatedData } = useSettlementTableService();

  const serviceSelectList = useAsync(
    () => getExtendServicesSelectListData(),
    []
  );
  const partSelectList = useAsync(() => getExtendPartsSelectListData(), []);

  const [
    openCreateItemDialog,
    onOpenCreateItemDialog,
    onCloseCreateItemDialog,
  ] = useDialog();

  const [
    openUnsavedDataDialog,
    onOpenUnsavedDataDialog,
    handleCloseUnsavedDataDialog,
  ] = useDialog();

  const [
    openCancelOfferDialog,
    onOpenCancelOfferDialog,
    handleCloseCancelOfferDialog,
  ] = useDialog();

  const [
    openSelectUserDialog,
    onOpenSelectUserDialog,
    handleCloseSelectUserDialog,
  ] = useDialog();

  const [openNoteDialog, onOpenNoteDialog, handleCloseNoteDialog] = useDialog();
  const [openSearchByTreeDialog, onOpenSearchByTreeDialog, handleCloseSearchByTreeDialog] = useDialog();

  const [
    openDocumentOfferDialog,
    onOpenDocumentOfferDialog,
    handleCloseDocumentOfferDialog,
  ] = useDialog();

  const [
    openSaveErrorOfferDialog,
    onOpenSaveErrorOfferDialog,
    handleCloseSaveErrorOfferDialog,
  ] = useDialog();

  const [
    openSettlementOfferDialog,
    onOpenSettlementOfferDialog,
    handleCloseSettlementOfferDialog,
  ] = useDialog();

  const [openClausesDialog, onOpenClausesDialog, handleCloseClausesDialog] =
    useDialog();

    const [
      openChangeDataRequestDialog,
      onOpenChangeDataRequestDialog,
      handleCloseChangeDataRequestDialog,
    ] = useDialog();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptionsAndCallback,
  } = useForm({});

  const { getTicketOfferInitData } = useTicketService();

  const { getAllTenantSettlementsData, getInternalTenantSettlementsData } = useUserService();

  const offerDetails = useAsync(
    () => getOfferDataForOffer(),
    [localOfferId, dialogMode]
  );

  const { getUnfillRequiredFields } = useFieldValidation();

  const payersSelectList = useAsync(() => {
    if (formValue.offer_internal === undefined) {
      return Promise.resolve([])
    }
    else if (formValue.offer_internal) {
      return getInternalTenantSettlementsData()
    } else {
      return getAllTenantSettlementsData()
    }
  }, [formValue.offer_internal]);

  const createNewOfferFn = useAsyncFn(createNewOffer);

  const updateOfferDataFn = useAsyncFn(updateOfferData);

  const offerGenerateFn = useAsyncFn(genereteOffer);

  const createNewOfferVersionFn = useAsyncFn(createNewOfferVersion);

  const createCopyOfferForUpdateFn = useAsyncFn(createCopyOfferForUpdate);

  const createInnerOfferFn = useAsyncFn(createInnerOffer)

  useEffect(() => {
    if (offerDetails.loading) {
      return;
    }
    setFormValue(offerDetails.value);
    if (dialogMode !== "preview" && dialogMode !== "acceptRR") {
      setIsReadOnly(offerDetails.value.is_read_only || offerDetails.value.ticket_is_closed);
      setIsAllowedToCopyForNewVersion(offerDetails.value.is_allowed_to_copy_for_update);
      setIsAllowedToCreateInnerOffer(offerDetails.value.is_allowed_to_create_inner_offer);
      setIsAllowedToChangePayers(offerDetails.value.is_allowed_to_change_payers)
      setIsAllowedToCancel(offerDetails.value.is_allow_to_cancel)
      setIsAllowedToRequestChangeData(offerDetails.value.is_allow_to_request_change_data)
    }
    if (dialogMode === "newOffer") {
      setSettlementData(offerDetails.value.settlements);
    }
    setDetailsDrawerOpen(true);
  }, [offerDetails.loading]);


  useEffect(() => {
    if (!isReadOnly) {
      setServiceOfferTableConfigLocal(OFFER_SERVICES_TABLE_FIELDS_CONFIG);
    } else {
      let tempConfig = OFFER_SERVICES_TABLE_FIELDS_CONFIG
      let serviceConfig = {
        name: "service",
        getValueCallback: (rowData) => rowData.service_name,
        label: t("table.offer_services.service"),
        dataType: TEXT_FIELD_TYPE,
        editable: false,
        required: false
      }
      let newConfig = [...tempConfig.slice(0,2), serviceConfig, ...tempConfig.slice(3)]
      setServiceOfferTableConfigLocal([...newConfig]);
    }
  }, [isReadOnly]);


  useEffect(() => {
    if (!isReadOnly) {
      setPartOfferTableConfigLocal(OFFER_PARTS_TABLE_FIELDS_CONFIG);
    } else {
      let tempConfig = OFFER_PARTS_TABLE_FIELDS_CONFIG
      let partConfig = {
        name: "part",
        getValueCallback: (rowData) => rowData.part_name,
        label: t("table.offer_parts.part"),
        dataType: TEXT_FIELD_TYPE,
        editable: false,
        required: false
      }
      let newConfig = [...tempConfig.slice(0,2), partConfig, ...tempConfig.slice(3)]
      setPartOfferTableConfigLocal([...newConfig]);
    }
  }, [isReadOnly]);


  const {
    offerServiceData,
    setOfferServiceData,
    offerServiceSummaryRow,
    onChangeServiceAutocompleteField,
    onChangeServiceBySelectDialog,
    onChangeServiceTableData,
    onHandleServiceAddEmptyRow,
    getServiceOffersToSend,
    handleRemoveOfferFromService,
    isOfferServiceTableValid,
    refetchOfferServiceData,
  } = useOfferServiceData(
    localOfferId,
    setHasChangesOnDialog,
    isReadOnly
  );

  const {
    offerPartData,
    setOfferPartData,
    offerPartSummaryRow,
    onChangePartAutocompleteField,
    onChangePartBySelectDialog,
    onChangePartTableData,
    onHandlePartAddEmptyRow,
    getPartOffersToSend,
    handleRemoveOfferFromPart,
    isOfferPartTableValid,
    refetchOfferPartData,
  } = useOfferPartData(
    localOfferId,
    setHasChangesOnDialog,
    isReadOnly
  );

  const {
    offerSettlementData,
    setSettlementData,
    onChangeSettlementAutocompleteField,
    onChangeSettlementTableData,
    onHandleSettlementAddEmptyRow,
    requiredSettlementFields,
    handleRemoveSettlementFromList,
    getSettlementsToSend,
    isSettlementTableValid,
    isSettlementSumRateValid,
    refetchSettlementData,
  } = useSettlementData(
    localOfferId,
    SETTLEMENT_TABLE_FIELDS_CONFIG,
    totalItemsCost,
    dialogMode === "preview",
    undefined,
    dialogMode
  );

  const {
    clausesData,
    handleRemoveClausesFromList,
    onAddClausesTableData,
    getClausesToSend,
    refetchClauseData,
    clauseLoading,
  } = useClausesData(localOfferId);

  useEffect(() => {
    if (offerServiceSummaryRow && offerPartSummaryRow) {
      setTotalItemsCost(
        calculateItemsTotalCost(offerServiceSummaryRow, offerPartSummaryRow)
      );
    }
  }, [offerServiceSummaryRow, offerPartSummaryRow]);

  const getOfferDataForOffer = () => {
    if (dialogMode === "newOffer") {
      setOfferServiceData([]);
      setOfferPartData([]);
      return getTicketOfferInitData(props.ticketId);
    } else {
      return getOfferDetailsData(localOfferId);
    }
  };

  const onChangeAutoCompleted = (name, value) => {
    setFormValue((prev) => ({ ...prev, [name]: value }));
    setHasChangesOnDialog(true);
  };
  const onChangeOfferDetails = (e) => {
    onChange(e)
    setHasChangesOnDialog(true);
  };

  const onChangeTerminatedDate = (e) => {
    onChangeDate(e)
    setHasChangesOnDialog(true);
  };

  const onCloseWithoutSavingData = useCallback(() => {
    if (hasChangesOnDialog || dialogMode === "newOffer") {
      onOpenUnsavedDataDialog();
    } else {
      props.onClose();
      setHasChangesOnDialog(false);
    }
  }, [props.onClose, props.hasChangesOnDialog]);

  const onSaveEditedData = useCallback(() => {
    handleToSaveChangesInConfiguratorOffer(onPrepareDataToSave());
  }, [
    offerServiceData,
    clausesData,
    offerPartData,
    formValue,
    offerSettlementData,
    isSettlementTableValid,
    isSettlementSumRateValid,
  ]);

  const onOfferUpdate = (offerId, data, saveDialog) => {
    if (isSettlementTableValid && isSettlementSumRateValid || data.action === OFFER_CANCEL_ACTION) {
      if (isReadOnly && isAllowedToChangePayers && data.action !== OFFER_CANCEL_ACTION) {
        onGenerateOffer(onPrepareDataToSave());
      } else {
        setIsSavingData(true);
        updateOfferDataFn
          .execute(offerId, data)
          .then((res) => {
            snackbarAlert.openSuccessSnackbarAlert(
              t("snackbar_alert.offer_data_updated")
            );
            setHasChangesOnDialog(false);
            setIsEmptyOfferCost(false);
            setUnfillRequiredFields([]);
            if (saveDialog) {
              props.onClose();
            } else {
              offerDetails.refetch();
              refetchOfferServiceData();
              refetchOfferPartData();
              refetchSettlementData();
              refetchClauseData();
              setIsSavingData(false);
            }
          })
          .catch((error) => {
            setIsSavingData(false);
            snackbarAlert.openErrorSnackbarAlert(
              t("snackbar_alert.occurred_error_during_updating_offer")
            );
          });
      }
    } else {
      onOpenSaveErrorOfferDialog();
    }
  };

  const onRequestChangeUpdate = (requestChangeData) => {
    onGenerateOffer(requestChangeData);
    
  }


  const onCreateOffer = (data) => {
    if (isSettlementTableValid && isSettlementSumRateValid) {
      setIsSavingData(true);
      createNewOfferFn
        .execute(data)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.created_new_offer")
          );
          setLocalOfferId(res.id);
          setDialogMode("existOffer")
          setHasChangesOnDialog(false);
          setIsSavingData(false);
        })
        .catch((error) => {
          setIsSavingData(false);
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_creating_new_offer")
          );
        });
    } else {
      onOpenSaveErrorOfferDialog();
    }
  };

  const onGenerateOffer = (data) => {
    if (isSettlementTableValid && isSettlementSumRateValid) {
      setIsSavingData(true);
      updateOfferDataFn
        .execute(data.offer_details.id, data)
        .then((res) => {
          setHasChangesOnDialog(false);
          offerDetails.refetch();
          refetchOfferServiceData();
          refetchOfferPartData();
          refetchSettlementData();
          refetchClauseData();
          offerGenerateFn
            .execute(data.offer_details.id)
            .then((res) => {
              setFormValue(res);
            handleCloseChangeDataRequestDialog()
              onOpenDocumentOfferDialog();
              setIsSavingData(false);
            })
            .catch((error) => {
              setIsSavingData(false);
              snackbarAlert.openErrorSnackbarAlert(
                t("snackbar_alert.occurred_error_during_generate_offer")
              );
            });
        })
        .catch((error) => {
          setIsSavingData(false);
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_updating_offer")
          );
        });
    } else {
      onOpenSaveErrorOfferDialog();
    }
  };

  const checkIsCorrectlyValidatedOffer = () => {
    let tempUnfilledFields = getUnfillRequiredFieldsLocal();
    setUnfillRequiredFields(tempUnfilledFields);
    return tempUnfilledFields.length < 1;
  };

  const validateOfferTerminatedDate = () => {
    if (formValue.offer_date_terminated) {
      let today = new Date();
      let terminatedDate = new Date(formValue.offer_date_terminated);
      return terminatedDate > today
    }
    return true
  }

  const getUnfillRequiredFieldsLocal = () => {
    let requiredFields = ["account", "owner"];

    let unfillFieldsTemp = getUnfillRequiredFields(requiredFields, formValue);

    return unfillFieldsTemp;
  };

  const handleToSaveChangesInConfiguratorOffer = (data, saveDialog = false) => {
    if (data.offer_details.id) {
      onOfferUpdate(data.offer_details.id, data, saveDialog);
    } else {
      onCreateOffer(data);
    }
  };

  const onConfirmCancelOffer = useCallback(() => {
    handleToSaveChangesInConfiguratorOffer(
      onPrepareDataToSave(OFFER_CANCEL_ACTION)
    );
    handleCloseCancelOfferDialog();
  }, [
    offerServiceData,
    clausesData,
    offerPartData,
    formValue,
    offerSettlementData,
    isSettlementTableValid,
    isSettlementSumRateValid,
  ]);

  const onSaveDataAndSendToJoin = useCallback(
    (userId) => {
      let dataToSend = onPrepareDataToSave(OFFER_JOIN_ACTION);
      dataToSend.offer_details["inputer"] = userId;
      handleToSaveChangesInConfiguratorOffer(dataToSend);
      handleCloseSelectUserDialog();
    },
    [
      offerServiceData,
      clausesData,
      offerPartData,
      formValue,
      offerSettlementData,
      isSettlementTableValid,
      isSettlementSumRateValid,
    ]
  );

  const onSaveDataAndSendJoined = useCallback(() => {
    handleToSaveChangesInConfiguratorOffer(
      onPrepareDataToSave(OFFER_JOINED_ACTION),
      true
    );
  }, [
    offerServiceData,
    clausesData,
    offerPartData,
    formValue,
    offerSettlementData,
    isSettlementTableValid,
    isSettlementSumRateValid,
  ]);

  const handleSendOfferToRR = useCallback(() => {
    handleToSaveChangesInConfiguratorOffer(
      onPrepareDataToSave(OFFER_SEND_TO_RR)
    );
    handleCloseDocumentOfferDialog();
  }, [
    offerServiceData,
    clausesData,
    offerPartData,
    formValue,
    offerSettlementData,
    isSettlementTableValid,
    isSettlementSumRateValid,
  ]);

  const handleSendOfferToOM = useCallback(() => {
    handleToSaveChangesInConfiguratorOffer(
      onPrepareDataToSave(formValue.offer_internal ? OFFER_ACCEPED_INTERNAL : OFFER_SEND_TO_OM)
    );
    handleCloseDocumentOfferDialog();
  }, [
    offerServiceData,
    clausesData,
    offerPartData,
    formValue,
    offerSettlementData,
    isSettlementTableValid,
    isSettlementSumRateValid,
  ]);

  const onAcceptOfferAndSendBack = useCallback(() => {
    handleToSaveChangesInConfiguratorOffer(
      onPrepareDataToSave(OFFER_ACCEPED_BY_RR),
      true
    );
  }, [
    offerServiceData,
    clausesData,
    offerPartData,
    formValue,
    offerSettlementData,
    isSettlementTableValid,
    isSettlementSumRateValid,
  ]);

  const onCreateNewVersionOfOffer = useCallback(() => {
    createNewOfferVersionFn
      .execute(localOfferId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.new_offer_version_created")
        );
        setLocalOfferId(res.id);
      })
      .catch((error) => {
        setIsSavingData(false);
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_offer_version_creating")
        );
      });
  }, [localOfferId]);

  const onCreateCopyForUpdateOffer = useCallback(() => {
    createCopyOfferForUpdateFn
      .execute(localOfferId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.created_new_copy")
        );
        setLocalOfferId(res.id);
      })
      .catch((error) => {
        setIsSavingData(false);
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_creating_new_copy")
        );
      });
  }, [localOfferId]);

  const onCreateInnerOffer = useCallback(() => {
    createInnerOfferFn
      .execute(localOfferId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.created_inner_offer")
        );
        setLocalOfferId(res.id);
      })
      .catch((error) => {
        setIsSavingData(false);
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_creating_inner_offer")
        );
      });
  }, [localOfferId]);

  const onOpenGenerateOfferDialog = useCallback(() => {
    let fieldsAreFilled = checkIsCorrectlyValidatedOffer()
    let terminatedDateCorrect = validateOfferTerminatedDate()

    if (fieldsAreFilled && terminatedDateCorrect) {
      onGenerateOffer(onPrepareDataToSave());
    }

    if (!fieldsAreFilled) {
      snackbarAlert.openWarningSnackbarAlert(
        t("snackbar_alert.not_all_required_fields_are_filled")
      );
    }
    if (!terminatedDateCorrect) {
      snackbarAlert.openWarningSnackbarAlert(
        t("snackbar_alert.terminated_date_has_to_be_later_than_today")
      );
    }

  }, [
    offerServiceData,
    clausesData,
    offerPartData,
    formValue,
    totalItemsCost,
    unfillRequiredFields,
    offerSettlementData,
    isSettlementTableValid,
    isSettlementSumRateValid,
  ]);

  const onClickAddNewService = useCallback(() => {
    setCreateItemType("service");
    onOpenCreateItemDialog();
  }, []);

  const onOpenSettlementDialog = useCallback(() => {
    if (offerSettlementData && totalItemsCost) {
      let tempSetlements = setSettlementCalculatedData(
        offerSettlementData,
        totalItemsCost[0],
        requiredSettlementFields
      );
      setSettlementData(tempSetlements);
      onOpenSettlementOfferDialog();
    }
  }, [totalItemsCost, offerSettlementData]);

  const onClickAddNewPart = useCallback(() => {
    setCreateItemType("part");
    onOpenCreateItemDialog();
  }, []);

  const onPrepareDataToSave = (action = undefined) => {
    let dataToSend = {};
    dataToSend.offer_details = formValue;
    dataToSend.offer_details.offer_date_terminated = formValue.offer_date_terminated === "" ? null : formValue.offer_date_terminated
    dataToSend.action = action;
    dataToSend.offer_service_content = getServiceOffersToSend();
    dataToSend.offer_part_content = getPartOffersToSend();
    dataToSend.settlements = getSettlementsToSend();
    dataToSend.clauses = getClausesToSend();
    return dataToSend;
  };


  const onChangeNote = useCallback(
    (formValue) => {
      if (clickedItemPrefix.current === "seof") {
        onChangeServiceTableData(
          "seof_note",
          formValue.note,
          clickedItemIndex.current
        );
      } else {
        onChangePartTableData(
          "paof_note",
          formValue.note,
          clickedItemIndex.current
        );
      }
      onCloseNoteDialog();
    },
    [offerServiceData, offerPartData]
  );

  const onCloseNoteDialog = useCallback(() => {
    handleCloseNoteDialog();
    clickedItemToShowComment.current = null;
    clickedItemPrefix.current = null;
    clickedItemIndex.current = null;
  });

  const clickedItemToShowComment = useRef();
  const clickedItemPrefix = useRef();
  const clickedItemIndex = useRef();

  const onHasCommentToService = useCallback(
    (idx) => {
      clickedItemToShowComment.current = offerServiceData[idx];
      clickedItemPrefix.current = "seof";
      clickedItemIndex.current = idx;
      onOpenNoteDialog();
    },
    [onOpenNoteDialog, offerServiceData]
  );

  const onHasCommentToPart = useCallback(
    (idx) => {
      clickedItemToShowComment.current = offerPartData[idx];
      clickedItemPrefix.current = "paof";
      clickedItemIndex.current = idx;
      onOpenNoteDialog();
    },
    [onOpenNoteDialog, offerPartData]
  );

  const onOpenServiceTree = useCallback(
    (idx) => {
      clickedItemPrefix.current = "seof";
      clickedItemIndex.current = idx;
      onOpenSearchByTreeDialog();
    },
    [clickedItemPrefix, clickedItemIndex]
  );

  const onOpenPartTree = useCallback(
    (idx) => {
      clickedItemPrefix.current = "paof";
      clickedItemIndex.current = idx;
      onOpenSearchByTreeDialog();
    },
    [clickedItemPrefix, clickedItemIndex]
  );

  const onCloseSelectTreeDialog = useCallback(
    (itemId) => {
      if(clickedItemPrefix.current === "seof"){
        let service = serviceSelectList.value.services.find(s => s.id === itemId)
        onChangeServiceBySelectDialog(clickedItemIndex.current, service)
      }else{
        let part = partSelectList.value.parts.find(s => s.id === itemId)
        onChangePartBySelectDialog(clickedItemIndex.current, part)
      }
      clickedItemPrefix.current = undefined;
      clickedItemIndex.current = undefined
      handleCloseSearchByTreeDialog();
    },
    [ clickedItemPrefix, clickedItemIndex, serviceSelectList, partSelectList]
  );

  const serviceTableContextMenuActions = useMemo(
    () => [
      {
        label: t("dialog.offer_configurator_dialog.add_comment"),
        callback: onHasCommentToService,
        icon: <ModeEditIcon fontSize="small" />,
        visible: true,
      },
      {
        label: t("dialog.offer_configurator_dialog.remove_element"),
        callback: handleRemoveOfferFromService,
        icon: <DeleteIcon fontSize="small" />,
        visible: true,
      },
    ],
    [handleRemoveOfferFromService]
  );

  const partTableContextMenuActions = useMemo(
    () => [
      {
        label: t("dialog.offer_configurator_dialog.add_comment"),
        callback: onHasCommentToPart,
        icon: <ModeEditIcon fontSize="small" />,
        visible: true,
      },
      {
        label: t("dialog.offer_configurator_dialog.remove_element"),
        callback: handleRemoveOfferFromPart,
        icon: <DeleteIcon fontSize="small" />,
      },
    ],
    [handleRemoveOfferFromPart]
  );

  const settlementTableContextMenuActions = useMemo(
    () => [
      {
        label: t("dialog.offer_configurator_dialog.remove_element"),
        callback: handleRemoveSettlementFromList,
        icon: <DeleteIcon fontSize="small" />,
      },
    ],
    [handleRemoveSettlementFromList]
  );

  const clausesTableContextMenuActions = useMemo(
    () => [
      {
        label: t("dialog.offer_configurator_dialog.remove_element"),
        callback: handleRemoveClausesFromList,
        icon: <DeleteIcon fontSize="small" />,
      },
    ],
    [handleRemoveClausesFromList]
  );

  const addNewEnclosureFromUser = useCallback(
    (enclosures, commonData) => {
      if (commonData === undefined) {
        commonData = [];
      }
      saveEnclosures(enclosures, commonData);
    },
    [localOfferId]
  );

  const previewEnclosure = useCallback(
    (enclosureId) => {
      onPreviewEnclosure(enclosureId);
    },
    [localOfferId]
  );

  const downloadEnclosure = useCallback(
    (enclosureId, enclosureName) => {
      onDownloadEnclosure(enclosureId, enclosureName);
    },
    [localOfferId]
  );

  const deleteEnclosure = useCallback(
    (enclosureId) => {
      onDeletePermanentlyOfferEnclosure(enclosureId);
    },
    [localOfferId]
  );

const updateEnclosureCallback = useCallback(
  (enclosure) => {
    enclosure.enof_note = enclosure.enclosure_note;
    updateOfferEnclosure(enclosure);
  },
  [localOfferId]
);


  const isLoading =
    offerDetails.value === undefined ||
    offerServiceData === undefined ||
    offerPartData === undefined ||
    serviceSelectList.value === undefined ||
    partSelectList.value === undefined ||
    clausesData === undefined ||
    serviceTableIsLoading;


  return (
    <>
      <BasicDialog
        open={props.open}
        titleAlign="center"
        contentAlign="center"
        title={t("dialog.offer_configurator_dialog.offer_configurator")}
        allwaysFullScreen={true}
        dialogSx={{
          width: detailsDrawerOpen
            ? `calc(100% - ${DETAILS_DRAWER_WIDTH}px)`
            : `calc(100% - ${MINI_DETAILS_DRAWER_WIDTH}px)`,
        }}
        showCustomFooter={false}
        showDialogActions={false}
        titleSx={{ height: `${64}px`, marginLeft: `${openMiniDrawerWidth}px` }}
        showTopCloseButton={false}
      >
        <IsTestingAppWrapper />
        <DetailsDrawer
          openDrawer={detailsDrawerOpen}
          setOpenDrawer={setDetailsDrawerOpen}
          itemData={formValue}
          itemType={detailsDrawerOpen ? "offer" : "mini"}
          isLoading={offerDetails.loading}
          showMiniDrawer={true}
          drawerWidth={
            detailsDrawerOpen ? DETAILS_DRAWER_WIDTH : MINI_DETAILS_DRAWER_WIDTH
          }
          isPageDrawer={false}
          onChangeOfferDetails={onChangeOfferDetails}
          onChangeDate={onChangeTerminatedDate}
          onChangeAutocompletedOfferDetails={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            onChangeAutoCompleted
          )}
          dialogMode={dialogMode}
          filteringData={props.filteringData}
          readOnly={isReadOnly}
          unfillRequiredFields={unfillRequiredFields}
          onDecrementPayers={onOpenSettlementDialog}
          settlements={offerSettlementData}
          showSettlements={true}
          allowEditKNote={true}
        showLogsButton = {true}
        >
          <LoaderWrapper showLoader={isLoading}>
            <FunctionsDrawer
              drawerType={FUNCTION_DRAWER_TYPE_OFFERS}
              readOnly={isReadOnly}
              disableSaveButton={
                !isOfferServiceTableValid ||
                !isOfferPartTableValid ||
                isLoading ||
                isSavingData
              }
              onClose={onCloseWithoutSavingData}
              onSaveOfferChanges={onSaveEditedData}
              onCancelOffer={onOpenCancelOfferDialog}
              onSendToJoinOffer={onOpenSelectUserDialog}
              onSendAfterJoin={onSaveDataAndSendJoined}
              onGenerateOffer={onOpenGenerateOfferDialog}
              onAcceptRR={onAcceptOfferAndSendBack}
              onCreateNewVersion={onCreateNewVersionOfOffer}
              onCreateCopyOffer={onCreateCopyForUpdateOffer}
              onCreateInnerOffer={onCreateInnerOffer}
            onRequestChangeData={onOpenChangeDataRequestDialog}
              onOfferPdfViewer={onOpenDocumentOfferDialog}
              dialogMode={dialogMode}
              disablePreviewOffer={
                dialogMode === "newOffer" ||
                isEmptyValue(formValue?.file_uploader_original)
              }
              isSavingData={isSavingData}
              isUpdateToNewOfferButtonActive={isAllowedToCopyForNewVersion}
              isAllowedToCreateInnerOffer={isAllowedToCreateInnerOffer}
              isAllowedToChangePayers={isAllowedToChangePayers}
            isAllowedToCancel={isAllowedToCancel}
            isAllowedToRequestChangeData={isAllowedToRequestChangeData}
            >
              <Grid container rowSpacing={2} columnSpacing={1}>
                <Grid item xs={12}>
                  <OfferItemTable
                    data={offerServiceData}
                    summaryDataRow={offerServiceSummaryRow}
                    onDataChange={onChangeServiceTableData}
                    onChangeAutocomplete={onChangeServiceAutocompleteField}
                    tableConfig={serviceOfferTableConfigLocal}
                    onAddtitionalMethod={[onOpenServiceTree, onHasCommentToService]}
                    filteringData={serviceSelectList.value}
                    readOnly={isReadOnly || isSavingData}
                    showContextMenu={
                      !isReadOnly || dialogMode === "joinOffer"
                    }
                    contextMenuActions={serviceTableContextMenuActions}
                    onClickAddItemToAutocompletedField={onClickAddNewService}
                    onHandleAddEmptyRow={onHandleServiceAddEmptyRow}
                    isValid={isOfferServiceTableValid}
                    style={{ maxHeight: "30vh" }}
                    dialogMode={dialogMode}
                    isLoading={isSavingData}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OfferItemTable
                    data={offerPartData}
                    summaryDataRow={offerPartSummaryRow}
                    onDataChange={onChangePartTableData}
                    onChangeAutocomplete={onChangePartAutocompleteField}
                    tableConfig={partOfferTableConfigLocal}
                    onAddtitionalMethod={[onOpenPartTree, onHasCommentToPart]}
                    filteringData={partSelectList.value}
                    readOnly={isReadOnly || isSavingData}
                    showContextMenu={
                      !isReadOnly || dialogMode === "joinOffer"
                    }
                    contextMenuActions={partTableContextMenuActions}
                    onClickAddItemToAutocompletedField={onClickAddNewPart}
                    onHandleAddEmptyRow={onHandlePartAddEmptyRow}
                    isValid={isOfferPartTableValid}
                    style={{ maxHeight: "30vh" }}
                    dialogMode={dialogMode}
                    isLoading={isSavingData}
                  />
                </Grid>
                <Grid item xs={4}>
                  <BaseBox>
                    <FileUploadList
                      addEnclosureButtonProps={{ size: "mini" }}
                      defaultEnclosureType={EXTERNAL_TYPE}
                      enclosures={enclosures}
                      onAddEnclosure={addNewEnclosureFromUser}
                      onPreviewEnclosure={previewEnclosure}
                      onDownloadEnclosure={downloadEnclosure}
                      onDeleteEnclosure={deleteEnclosure}
                      canRemoveEnclosures={
                        !isReadOnly && dialogMode !== "joinOffer"
                      }
                      onUpdateEnclosure={updateEnclosureCallback}
                      readOnly={
                        (isReadOnly && dialogMode !== "joinOffer") ||
                        dialogMode === "newOffer" ||
                        isSavingData
                      }
                    />
                  </BaseBox>
                </Grid>
                <Grid item xs={2}>
                  <OfferDiscountForm
                    data={formValue}
                    filteringData={props.filteringData}
                    onChange={onChangeOfferDetails}
                    onChangeAutocompletedOfferDetails={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
                      onChangeAutoCompleted
                    )}
                    readOnly={isReadOnly || isSavingData}
                  />
                </Grid>
                <Grid item xs={6}>
                  <OfferTableSummaryForm
                    tableConfig={OFFER_ITEM_SUMMARY_TABLE_FIELDS_CONFIG}
                    data={totalItemsCost}
                    offerCostIsEqualZero={isEmptyOfferCost}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OfferContentForm
                    onChange={onChangeOfferDetails}
                    readOnly={isReadOnly || isSavingData}
                    offer_title={formValue?.offer_title}
                    offer_content={formValue?.offer_content}
                    offer_exclusion={formValue?.offer_exclusion}
                    offer_comments={formValue?.offer_comments}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BaseBox>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        sm={6}
                        xs={6}
                        textAlign={"start"}
                        display={"flex"}
                      >
                        <Typography
                          variant="subtitle2"
                          className="capitalize-first-letter"
                        >
                          {t(`form.offer_clause_form.clause`)}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} xs={6} />
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            width: "100%",
                          }}
                        >
                          {/* {!isReadOnly && (
                            <AddButton onClick={onOpenClausesDialog} />
                          )} */}
                          <OfferClauseForm
                            data={clausesData}
                            readOnly={isReadOnly}
                            tableConfig={OFFER_CLAUSE_TABLE_FIELDS_CONFIG}
                            // showContextMenu={!isReadOnly}
                            showContextMenu={false}
                            contextMenuActions={clausesTableContextMenuActions}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </BaseBox>
                </Grid>
              </Grid>
            </FunctionsDrawer>
          </LoaderWrapper>
        </DetailsDrawer>
      </BasicDialog>
      {openCreateItemDialog && (
        <CreateItemDialog
          open={openCreateItemDialog}
          onClose={onCloseCreateItemDialog}
          itemType={createItemType}
          onRefetchData={
            createItemType === "service"
              ? serviceSelectList.refetch
              : partSelectList.refetch
          }
        />
      )}
      {openUnsavedDataDialog && (
        <UnsavedDataDialog
          open={openUnsavedDataDialog}
          onYes={() => props.onClose(true, false)}
          onNo={handleCloseUnsavedDataDialog}
        />
      )}
      {openCancelOfferDialog && (
        <CancelOfferDialog
          open={openCancelOfferDialog}
          onYes={onConfirmCancelOffer}
          onNo={handleCloseCancelOfferDialog}
        />
      )}
      {openSelectUserDialog && (
        <SelectUserDialog
          open={openSelectUserDialog}
          onClose={handleCloseSelectUserDialog}
          onSubmit={onSaveDataAndSendToJoin}
          searchParams={"roles=role_is_kt&roles=role_is_tb"}
        />
      )}

      {openDocumentOfferDialog && (
        <OfferDocumentDialog
          open={openDocumentOfferDialog}
          onClose={handleCloseDocumentOfferDialog}
          validatedOffer={formValue}
          readOnly={isReadOnly}
          onSendOfferToRR={handleSendOfferToRR}
          onSendOfferToOM={handleSendOfferToOM}
          enclosures={enclosures}
          isMyOfferPage={false}
          onPreviewEnclosure={previewEnclosure}
          onDownloadEnclosure={downloadEnclosure}
          loading={updateOfferDataFn.loading}
        />
      )}
      {openNoteDialog && (
        <NotesDialog
          open={openNoteDialog}
          onClose={onCloseNoteDialog}
          showFilename={false}
          note={
            clickedItemToShowComment.current[
            `${clickedItemPrefix.current}_note`
            ]
          }
          readOnly={dialogMode === "joinOffer" ? false : isReadOnly}
          onSubmit={onChangeNote}
        />
      )}
      {openSearchByTreeDialog && (
        <SelectItemByTreeDialog
          open={openSearchByTreeDialog}
          onClose={handleCloseSearchByTreeDialog}
          itemType ={clickedItemPrefix.current === 'seof' ? "service" : "part"}
    
          onSubmit={onCloseSelectTreeDialog}
        />
      )}
      {openSaveErrorOfferDialog && (
        <OfferSaveErrorDialog
          open={openSaveErrorOfferDialog}
          onClose={handleCloseSaveErrorOfferDialog}
          isTableValid={isSettlementTableValid}
          isSumValid={isSettlementSumRateValid}
        />
      )}
      {openSettlementOfferDialog && (
        <OfferSettlementDialog
          open={openSettlementOfferDialog}
          onClose={handleCloseSettlementOfferDialog}
          data={offerSettlementData}
          readOnly={!isAllowedToChangePayers}
          filteringData={payersSelectList.value}
          onChangeAutocomplete={onChangeSettlementAutocompleteField}
          onHandleAddEmptyRow={onHandleSettlementAddEmptyRow}
          contextMenuActions={settlementTableContextMenuActions}
          onDataChange={onChangeSettlementTableData}
          isTableValid={isSettlementTableValid}
          isSumValid={isSettlementSumRateValid}
          tableConfig={SETTLEMENT_TABLE_FIELDS_CONFIG}
          showConfirmationForm={false}
        />
      )}
      {openClausesDialog && (
        <ClausesDialog
          open={openClausesDialog}
          onClose={handleCloseClausesDialog}
          onSubmit={onAddClausesTableData}
          showContextMenu={false}
          showCheckbox={true}
        />
      )}
    {openChangeDataRequestDialog &&
    <OfferDataRequestDialog 
    open = {openChangeDataRequestDialog}
    onClose={handleCloseChangeDataRequestDialog}
    filteringData={payersSelectList.value}
    offerId={formValue?.id}
    totalItemsCost={totalItemsCost}
    dialogMode = {dialogMode}
    contextMenuActions={settlementTableContextMenuActions}
    offerData={formValue}
    offerFilteringData={props.filteringData}
    onSubmit={(data) =>onRequestChangeUpdate(data)}
    isOfferCRLoading = {updateOfferDataFn.loading || offerGenerateFn.loading}
    />
    }
    </>
  );
}

OfferConfiguratorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  offerId: PropTypes.string,
  dialogMode: PropTypes.oneOf([
    "newOffer",
    "existOffer",
    "joinOffer",
    "acceptRR",
    "preview",
  ]),
  ticketId: PropTypes.string,
  onSaveOfferChanges: PropTypes.func,
  filteringData: PropTypes.array,
  onCancelOffer: PropTypes.func,
  onSendOfferToJoin: PropTypes.func,
  onGenerateOffer: PropTypes.func,
};

OfferConfiguratorDialog.defaultProps = {
  open: false,
  dialogMode: "newOffer",
};

export default OfferConfiguratorDialog;
