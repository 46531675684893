import { useTranslation } from "react-i18next";
import {   poFilteringDataUrl, poUrl, poDetailsDataUrl, poLinesDataByPoUrl, poRemoveDocUrl, poLineUrl, poLineDetailsUrl, poLineChangeStatusUrl, fvUrl, fvFilteringDataUrl, poSelectDataUrl, poFlatListUrl, fvFlatListUrl } from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { prepareUrlWithQueryParams } from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useFileService from "./fileService";
import { useSnackbarAlert } from "../context/snackbarAlert";

export default function usePurchaseService() {
    const axiosPrivate = useAxiosPrivate();

    const { uploadAttachments  } =
    useFileService()
    

    const { t } = useTranslation();
    const snackbarAlert = useSnackbarAlert();

      const createPo = (poData) =>{
        return makeRequest(axiosPrivate, poUrl, {
          method: "POST",
          data: poData,
        });
      }

      const createPoLine = (poLineData) =>{
        return makeRequest(axiosPrivate, poLineUrl, {
          method: "POST",
          data: poLineData,
        });
      }

      const getPoListData = (searchParams = {}) => {
        return makeRequest(axiosPrivate, prepareUrlWithQueryParams(poUrl, searchParams));
      };

      const getPoFlatListData = (searchParams = {}) => {
        return makeRequest(axiosPrivate, prepareUrlWithQueryParams(poFlatListUrl, searchParams));
      };


      const getPoDetailsData = (poId) => {
        return makeRequest(axiosPrivate, poDetailsDataUrl(poId));
      };

      const updatePoData = (poId, poData) => {
        return makeRequest(axiosPrivate, poDetailsDataUrl(poId), {
          method: "PATCH",
          data: poData,
        });
      };

      const removePoDocs = (poId) => {
        return makeRequest(axiosPrivate, poRemoveDocUrl(poId), {
          method: "DELETE",
        });
      };


      const getPoLinesByPoListData = (poId, searchParams = {}) => {
        return makeRequest(axiosPrivate, prepareUrlWithQueryParams(poLinesDataByPoUrl(poId), searchParams));
      };

      const getPoFilteringData = () => {
        return makeRequest(axiosPrivate, poFilteringDataUrl);
      };

      const getPoSelectData = (searchParams = {}) => {
        return makeRequest(axiosPrivate, prepareUrlWithQueryParams(poSelectDataUrl, searchParams));
      };


      const updatePoLineData = (poLineId, poLineData) => {
        return makeRequest(axiosPrivate, poLineDetailsUrl(poLineId), {
          method: "PATCH",
          data: poLineData,
        });
      };

      const removePoLine = (poLineId) => {
        return makeRequest(axiosPrivate, poLineDetailsUrl(poLineId), {
          method: "DELETE",
        });
      };

      const getChangeStatusList = () => {
        return makeRequest(axiosPrivate, poLineChangeStatusUrl);
      };

      const getFVs = (searchParams = {}) => {
        return makeRequest(axiosPrivate, prepareUrlWithQueryParams(fvUrl, searchParams));
      };

      const getFVsFlatList = (searchParams = {}) => {
        return makeRequest(axiosPrivate, prepareUrlWithQueryParams(fvFlatListUrl, searchParams));
      };

      const getFVFilteringData = () => {
        return makeRequest(axiosPrivate, fvFilteringDataUrl);
      };

      const createFv = async  (fvData) =>{
        const files = fvData.files;
        delete fvData.files;

        let results = await uploadAttachments(files, {});
        let uploadedData = results["uploadedData"];
        let showWaring = results["showWaring"];
        fvData.files = uploadedData;

        return new Promise(async (resolve, reject) => {
          await makeRequest(axiosPrivate, fvUrl, {
            method: "POST",
            data: fvData,
          })
            .then((result) => {
              resolve(result);
              if (showWaring) {
                snackbarAlert.openWarningSnackbarAlert(
                  t("snackbar_alert.occurred_warning_during_upload_file")
                );
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }

      

    return {
        createPo,
        createPoLine,
        getPoListData,
        getPoFlatListData,
        getPoDetailsData,
        updatePoData,
        removePoDocs,
        getPoFilteringData,
        getPoSelectData,
        getPoLinesByPoListData,
        updatePoLineData,
        removePoLine,
        getChangeStatusList,
        getFVs,
        getFVsFlatList,
        getFVFilteringData,
        createFv
    }
}

