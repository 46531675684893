import { useState, useEffect, useMemo, useRef } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync } from "../../../hooks/useAsync";

import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../hooks/useFilterSearchParams";

import useLocationService from "../../../services/locationService";

import ObjectCreateUpdateDialog from "../../dialog/ObjectCreateUpdateDialog";
import useDialogWithId from "../../../hooks/useDialogWithId";

import ObjectCategoryTableDialog from "../../dialog/ObjectCategoryTableDialog";
import useDialog from "../../../hooks/useDialog";

import UniversalToolBarWithDialogs from "../../bar/UniversalToolBarWithDialogs";

import ObjectTable from "../../table/ObjectTable";
import TableService from "../../../services/tableService";

import { exportObjectAdminDataToFileUrl } from "../../../helpers/apiUrls";

import SelectObjectLevelButton from "../../button/SelectObjectLevelButton";

import {
  getFilterSearchParamsKeyForField,
  isSearchParamsObjectEmpty,
} from "../../../helpers/methods";

import DialpadIcon from "@mui/icons-material/Dialpad";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

import {
  centerVericalAlignIconStyle,
  overflowButtonStyle,
} from "../../../helpers/styles";

import {
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
} from "../../../helpers/constants";

const ObjectTableWithToolBar = (props) => {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    setPageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(props.tableFilterPrefix);

  const tableConfigForUserPreferencesDialog = useMemo(
    () => ({
      name: props.tableName,
      config: props.tableConfig,
    }),
    [props.tableName, props.tableConfig]
  );

  const [
    openObjectCategoryDialog,
    onOpenObjectCategoryDialog,
    onCloseObjectCategoryDialog,
  ] = useDialog();

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefix,
  } = useFilterSearchParams(searchParams);
  const [objectSearchParams, setObjectSearchParams] = useState(
    getSearchParamsByFilterPrefix(props.tableFilterPrefix)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      props.tableFilterPrefix,
      objectSearchParams,
      setObjectSearchParams
    );
  }, [searchParams, props.tableFilterPrefix]);

  const { getObjectAdminData, getObjectAdminFilteringData } =
    useLocationService();

  const getObjectSearchParamsWithObjectLevel = () => ({
    ...objectSearchParams,
    [getFilterSearchParamsKeyForField(
      props.objectLevelKey,
      props.filterPrefix
    )]: props.objectLevel,
  });

  const objectData = useAsync(() => {
    return getObjectAdminData(getObjectSearchParamsWithObjectLevel());
  }, [objectSearchParams, props.objectLevel]);
  const objectFiltringData = useAsync(() =>
    getObjectAdminFilteringData({ include_locations: true })
  );

  const [objectDataLocaly, setObjectDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (objectData.loading) {
      return;
    }
    setObjectDataLocaly(objectData.value.results);
    setCountRecords(objectData.value.count);
  }, [objectData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setObjectDataLocaly(undefined);
    objectData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setObjectDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const objectExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      props.tableConfig,
      hiddenColumnsForTables?.[props.tableName],
      columnsOrdersForTables?.[props.tableName]
    );
  }, [
    props.tableConfig,
    hiddenColumnsForTables?.[props.tableName],
    columnsOrdersForTables?.[props.tableName],
  ]);

  const [openObjectDialog, objectId, onOpenObjectDialog, onCloseObjectDialog] =
    useDialogWithId();

  const objectDialogModeRef = useRef();
  const handleOpenObjectDialogInCreateMode = () => {
    objectDialogModeRef.current = DIALOG_CREATE_MODE;
    onOpenObjectDialog(undefined);
  };

  const handleOpenObjectDialogInEditMode = (objectId) => {
    objectDialogModeRef.current = DIALOG_EDIT_MODE;
    onOpenObjectDialog(objectId);
  };

  const getObjectDialogCallback = () => {
    if (
      objectDialogModeRef.current === DIALOG_CREATE_MODE &&
      !isSearchParamsObjectEmpty(objectSearchParams)
    ) {
      setSearchParams(clearSearchParamsByFilterPrefix(props.tableFilterPrefix));
    } else {
      objectData.refetch();
    }
  };

  const handleCloseObjectCategoryDialog = (existsChanges) => {
    onCloseObjectCategoryDialog();

    if (existsChanges) {
      objectFiltringData.refetch();
    }
  };

  const handleChangeObjectLevel = (filterValue) => {
    setObjectDataLocaly(undefined);
    setObjectSearchParams({});
    setPageSize(10);
    setSearchParams({
      ...clearSearchParamsByFilterPrefix(
        props.tableFilterPrefix,
        undefined,
        false
      ),
      [getFilterSearchParamsKeyForField(
        props.objectLevelKey,
        props.filterPrefix
      )]: filterValue,
    });
  };

  const isLoading =
    objectDataLocaly === undefined || objectFiltringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  const getExtraButtonListForToolBar = () => {
    let extraButtonList = [
      <Button
        color="success"
        onClick={handleOpenObjectDialogInCreateMode}
        variant="contained"
        size="small"
        startIcon={
          <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
        }
        sx={overflowButtonStyle}
        fullWidth
      >
        {t("page.admin_locations.add_location")}
      </Button>,
    ];

    if (props.showObjectCategoryDialog) {
      extraButtonList.push(
        <Button
          color="primary"
          onClick={onOpenObjectCategoryDialog}
          variant="contained"
          size="small"
          startIcon={<DialpadIcon sx={centerVericalAlignIconStyle} />}
          sx={overflowButtonStyle}
          fullWidth
        >
          {t("page.admin_locations.categories")}
        </Button>
      );
    }

    if (props.showSelectObjectLevelButton) {
      extraButtonList.push(
        <SelectObjectLevelButton
          onClickFilter={handleChangeObjectLevel}
          color="primary"
          defaultLevelFilterKey={props.defaultObjectLevel}
          filterKey={props.objectLevelKey}
          filterPrefix={props.filterPrefix}
        />
      );
    }

    return extraButtonList;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <UniversalToolBarWithDialogs
          pageName={props.pageName}
          showOpenCloseFilterButton={false}
          tableConfig={tableConfigForUserPreferencesDialog}
          filteringData={objectFiltringData}
          refreshTable={refreshTable}
          onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
          resetPageNumber={resetPageNumber}
          hiddenColumnsForTables={hiddenColumnsForTables}
          setHiddenColumnsForTables={setHiddenColumnsForTables}
          columnsOrdersForTables={columnsOrdersForTables}
          setColumnsOrdersForTables={setColumnsOrdersForTables}
          filterPrefix={props.tableFilterPrefix}
          extraButtonList={getExtraButtonListForToolBar()}
        />
      </Grid>
      <Grid item xs={12}>
        {hiddenColumnsForTables && (
          <ObjectTable
            showCleanFilterIcon={true}
            style={{ maxHeight: "65vh" }}
            showFilters={true}
            data={objectDataLocaly}
            filteringData={objectFiltringData}
            filterPrefix={props.tableFilterPrefix}
            countRecords={countRecords}
            page={page}
            onPageChange={handleChangePageWithSearchParams}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onClickEdit={handleOpenObjectDialogInEditMode}
            resetPageNumber={resetPageNumber}
            tableConfig={props.tableConfig}
            hiddenColumns={hiddenColumnsForTables[props.tableName]}
            columnsOrders={columnsOrdersForTables[props.tableName]}
            showExportToFileButton={true}
            exportToFileUrl={exportObjectAdminDataToFileUrl}
            exportToFileSearchParams={getObjectSearchParamsWithObjectLevel()}
            exportToFileHeaders={objectExportHeaders}
            exportToFileFileName={`${t("page.admin_locations.locations")}.xlsx`}
          />
        )}
      </Grid>
      {openObjectDialog && (
        <ObjectCreateUpdateDialog
          open={openObjectDialog}
          onClose={onCloseObjectDialog}
          onSubmitCallback={getObjectDialogCallback}
          objectId={objectId}
          dialogMode={objectDialogModeRef.current}
          objectLevel={props.objectLevel}
        />
      )}
      {openObjectCategoryDialog && (
        <ObjectCategoryTableDialog
          open={openObjectCategoryDialog}
          onClose={handleCloseObjectCategoryDialog}
        />
      )}
    </Grid>
  );
};

ObjectTableWithToolBar.propTypes = {
  pageName: PropTypes.string,
  showObjectCategoryDialog: PropTypes.bool,
  showObjectSelectLevelButton: PropTypes.bool,
  tableName: PropTypes.string,
  tableConfig: PropTypes.object,
  tableFilterPrefix: PropTypes.string,
  filterPrefix: PropTypes.string,
  objectLevelKey: PropTypes.string,
  objectLevel: PropTypes.string,
  defaultObjectLevel: PropTypes.string,
};

ObjectTableWithToolBar.defaultProps = {
  showObjectCategoryDialog: true,
  showSelectObjectLevelButton: true,
  objectLevelKey: "object_level",
  defaultObjectLevel: "5",
};

export default ObjectTableWithToolBar;
