import i18n from "../../../../i18n";

import {
  TEXT_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
} from "../../../../helpers/constants";

import UserEmailNotificationsService from "../../../../services/userEmailNotificationsService";

export const USER_EMAIL_NOTIFICATIONS_TABLE_NAME = "user_email_notifications";
export const USER_EMAIL_NOTIFICATIONS_TABLE_FILTER_PREFIX =
  "nofications__user_email_notifications";
export const USER_EMAIL_NOTIFICATIONS_TABLE_CONFIG = [
  {
    name: "first_name",
    getValueCallback: (rowData) => rowData.first_name,
    label: i18n.t("table.user_email_notifications.first_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "last_name",
    getValueCallback: (rowData) => rowData.last_name,
    label: i18n.t("table.user_email_notifications.last_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.user_email_notifications.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  ...UserEmailNotificationsService.getEmailNotificationsTableConfigForOMs(),
];
