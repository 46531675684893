import { Grid, IconButton, Typography, CircularProgress } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";

import PropTypes from "prop-types";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { isAppTesting } from "../../helpers/methods";
import { APP_STAGE_BAR_HEIGHT } from "../../helpers/constants";

const MIN_HIEGHT = "48px";

export default function BasicDrawer(props) {
  const getDrawerContent = () => (
    <>
      <AppBar
        style={{
          position: "relative",
          textAlign: "center",
          minHeight: MIN_HIEGHT,
          overflow: "hidden",
        }}
      >
        <Toolbar style={{ minHeight: MIN_HIEGHT, overflow: "hidden" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item sm={1}>
              {props.open ? (
                <IconButton onClick={props.onClose} style={{ color: "#fff" }}>
                  {props.arrowIcon ? <KeyboardArrowRightIcon /> : null}
                </IconButton>
              ) : (
                props.showMiniDrawer && (
                  <IconButton onClick={props.onOpen} style={{ color: "#fff" }}>
                    {props.arrowIcon ? <KeyboardArrowLeftIcon /> : null}
                  </IconButton>
                )
              )}
            </Grid>
            <Grid item sm={11}>
              <Typography variant="h6" align={"center"}>
                {props.title}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ overflowY: "auto" }}
      >
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </>
  );

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={props.open || props.showMiniDrawer}
      PaperProps={{
        sx: {
          width: props.drawerWidth,
          borderColor: "primary.main",
          marginTop: isAppTesting() ? APP_STAGE_BAR_HEIGHT : undefined,
        },
      }}
    >
      {props.isLoading ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        getDrawerContent()
      )}
    </Drawer>
  );
}

BasicDrawer.propTypes = {
  title: PropTypes.string,
  arrowIcon: PropTypes.bool,
  drawerWidth: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  isLoading: PropTypes.bool,
  showMiniDrawer: PropTypes.bool,
};

BasicDrawer.defaultProps = {
  isLoading: false,
  showMiniDrawer: false,
};
