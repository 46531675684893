import { useTranslation } from "react-i18next";
import BasicDialog from "../../base/BasicDialog";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import SelectField from "../../field/SelectField";
import useRcpService from "../../../services/rcpService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useCallback, useMemo } from "react";
import { useForm } from "../../../hooks/useForm";
import { isEmptyValue } from "../../../helpers/methods";
import AutocompleteField from "../../field/AutocompleteField";
import DateField from "../../field/DateField/DateField";
import DateService from "../../../services/dateService";
import {
  RCP_ACTIVITIES,
  RCP_SHIFTS,
  TIME_OPTIONS_HOURS,
} from "../../../helpers/constants";
import LoadingButton from "@mui/lab/LoadingButton";
import useUserService from "../../../services/userService";
import useFieldValidation from "../../../hooks/useFieldValidation";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { getErrorMsg } from "../../../helpers/methods";
import TrueFalseSelectField from "../../field/TrueFalseSelectField/trueFalseSelectField";

const CreateScheduleDialog = ({
  open,
  onClose,
  kind = "plan",
  dialogMode = "create_mass",
  initialFormValue = {},
  backdropColor,
}) => {
  const { t } = useTranslation();

  const isRcpPlan = kind === "plan";
  const prefixField = isRcpPlan ? "rcpplan" : "rcpdone";

  const isCreateMassDialogMode = dialogMode === "create_mass";

  const getDialogTitle = () => {
    const suffix = t(`tabs.management_teams.schedule_${kind}`);
    if (isCreateMassDialogMode)
      return `${t(
        "dialog.create_schedule_dialog.mass_adding_hours"
      )} (${suffix})`;
    return `${t("dialog.create_schedule_dialog.edit_schedule")} (${suffix})`;
  };

  const getSubmitButtonLabel = () => {
    if (isCreateMassDialogMode)
      return t("dialog.create_schedule_dialog.add_to_schedule");
    return t("dialog.create_schedule_dialog.update_schedule");
  };

  const {
    getRcpActivityChoices,
    massCreateOrUpdateRcpPlan,
    updateRcpPlan,
    massCreateOrUpdateRcpDone,
    updateRcpDone,
  } = useRcpService();

  const { getEmployeesSelectList } = useUserService();

  const { getUnfillRequiredFields } = useFieldValidation();

  const snackbarAlert = useSnackbarAlert();

  const rcpActivityChoices = useAsync(getRcpActivityChoices);
  const employeesSelectList = useAsync(getEmployeesSelectList);

  const rcpActivityChoicesOptions = useMemo(() => {
    let optionsTemp;
    if (rcpActivityChoices.value) {
      optionsTemp = [];
      for (const rcpChoice of rcpActivityChoices.value) {
        optionsTemp.push({
          id: rcpChoice,
          name: t(`table.schedule.${rcpChoice}`),
        });
      }
    }
    return optionsTemp;
  }, [t, rcpActivityChoices.value]);

  const rcpShiftChoicesOptions = useMemo(() => {
    let optionsTemp;
    optionsTemp = [];
    for (const rcpChoice of Object.values(RCP_SHIFTS)) {
      optionsTemp.push({
        id: rcpChoice,
        name: t(`table.schedule.${rcpChoice}`),
      });
    }
    return optionsTemp;
  }, [t]);

  const getFieldName = useCallback(
    (name, withPrefix = true) => {
      if (withPrefix && !isEmptyValue(prefixField)) {
        return `${prefixField}_${name}`;
      }
      return name;
    },
    [prefixField]
  );

  const {
    formValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm(
    isCreateMassDialogMode
      ? { [getFieldName("shift")]: RCP_SHIFTS.DAY, exclude_free_days: true }
      : initialFormValue
  );

  const isWorkActivity = useMemo(() => {
    return (
      formValue[getFieldName("activity")] &&
      formValue[getFieldName("activity")] === RCP_ACTIVITIES.WORK
    );
  }, [formValue, getFieldName]);

  const showAmountHoursPerDayField = useMemo(() => {
    return formValue[getFieldName("activity")] && !isWorkActivity;
  }, [isWorkActivity, formValue, getFieldName]);

  const massCreateOrUpdateScheduleFn = useAsyncFn(
    (...params) =>
      isRcpPlan
        ? massCreateOrUpdateRcpPlan(...params)
        : massCreateOrUpdateRcpDone(...params),
    [kind]
  );
  const updateRcpFn = useAsyncFn(
    (...params) =>
      isRcpPlan ? updateRcpPlan(...params) : updateRcpDone(...params),
    [kind]
  );

  const onChangeDateStart = (e) => {
    onChangeDate(e);
    if (formValue[getFieldName("date_stop")] === undefined) {
      e.target.name = getFieldName("date_stop");
      onChangeDate(e);
    }
  };

  const requiredFields = useMemo(() => {
    let requiredFieldsTemp = [
      "employees",
      getFieldName("shift"),
      getFieldName("activity"),
      getFieldName("date_start"),
      getFieldName("date_stop"),
    ];
    if (isWorkActivity) {
      requiredFieldsTemp = [...requiredFieldsTemp, "hour_start", "hour_stop"];
    } else {
      requiredFieldsTemp = [...requiredFieldsTemp, getFieldName("hours")];
    }
    return requiredFieldsTemp;
  }, [isWorkActivity, getFieldName]);

  const isSubmitButtonDisabled = useMemo(() => {
    return getUnfillRequiredFields(requiredFields, formValue).length > 0;
  }, [requiredFields, formValue, getUnfillRequiredFields]);

  const prepareDataToSend = useCallback(
    (dateRange) => {
      let dataToSend = [];
      const extraFields = isWorkActivity ? [] : [getFieldName("hours")];
      const hourStart = isWorkActivity
        ? parseInt(formValue["hour_start"].split(":")[0])
        : undefined;
      const hourStop = isWorkActivity
        ? parseInt(formValue["hour_stop"].split(":")[0])
        : undefined;
      for (let dateStart of dateRange) {
        if (isWorkActivity) {
          dateStart.setHours(hourStart);
        } else {
          dateStart.setHours(
            new Date(
              DateService.getISOStringZeroHourOfDate(dateStart)
            ).getHours()
          );
        }
      }
      const employees = Array.isArray(formValue.employees)
        ? formValue.employees
        : [formValue.employees];

      for (const employeeId of employees) {
        for (const dateStart of dateRange) {
          let dateStop = new Date(dateStart);
          if (isWorkActivity) {
            dateStop.setHours(hourStop);
            if (hourStop < hourStart) {
              dateStop.setDate(dateStop.getDate() + 1);
            }
            // } else {
            //   dateStop.setHours(
            //     new Date(
            //       DateService.getISOStringleEndHourOfDate(dateStop)
            //     ).getHours() + 1
            //   );
          }
          let oneDayData = {
            employee: employeeId,
            [getFieldName("activity")]: formValue[getFieldName("activity")],
            [getFieldName("shift")]: formValue[getFieldName("shift")],
            [getFieldName("date_start")]: dateStart.toISOString(),
            [getFieldName("date_stop")]: dateStop.toISOString(),
          };
          for (const extraField of extraFields) {
            oneDayData[extraField] = formValue[extraField];
          }
          dataToSend.push(oneDayData);
        }
      }
      return isCreateMassDialogMode ? dataToSend : dataToSend[0];
    },

    [formValue, getFieldName, isWorkActivity]
  );

  const submitFn = isCreateMassDialogMode
    ? massCreateOrUpdateScheduleFn
    : updateRcpFn;
  const onSubmit = useCallback(() => {
    const dateRange = DateService.generateDateRanges(
      formValue[getFieldName("date_start")],
      formValue[getFieldName("date_stop")]
    );
    const dataToSave = prepareDataToSend(dateRange);
    const submitFnParams = isCreateMassDialogMode
      ? [dataToSave, formValue.exclude_free_days]
      : [dataToSave, formValue.id];

    submitFn
      .execute(...submitFnParams)
      .then((result) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.saved_changes")
        );
        onClose(true);
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  }, [
    getFieldName,
    formValue,
    prepareDataToSend,
    massCreateOrUpdateScheduleFn,
    updateRcpFn,
  ]);

  const dateStartMinDate = useMemo(
    () => (isRcpPlan ? (isCreateMassDialogMode ? new Date() : null) : null),
    [isRcpPlan, isCreateMassDialogMode]
  );
  const dateStartMaxDate = useMemo(
    () => (isRcpPlan ? null : isCreateMassDialogMode ? new Date() : null),
    [isRcpPlan, isCreateMassDialogMode]
  );
  const dateStopMinDate = useMemo(
    () =>
      isCreateMassDialogMode
        ? isRcpPlan
          ? formValue[getFieldName("date_start")] || new Date()
          : formValue[getFieldName("date_start")]
        : null,
    [isRcpPlan, isCreateMassDialogMode, formValue[getFieldName("date_start")]]
  );
  const dateStopMaxDate = useMemo(
    () => (isRcpPlan ? null : isCreateMassDialogMode ? new Date() : null),
    [isRcpPlan, isCreateMassDialogMode]
  );

  return (
    <BasicDialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth={"sm"}
      titleAlign="center"
      title={getDialogTitle()}
      showTopFullScreenButton={false}
      backdropColor={backdropColor}
    >
      <LoaderWrapper
        showLoader={
          rcpActivityChoices.loading ||
          rcpActivityChoicesOptions === undefined ||
          employeesSelectList.loading ||
          employeesSelectList.value === undefined
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AutocompleteField
              multiple={isCreateMassDialogMode}
              optionValueKey={"id"}
              optionLabelKey={"full_name"}
              name="employees"
              value={formValue.employees}
              options={employeesSelectList.value}
              label={t("form.create_skill.employees")}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <SelectField
              options={rcpActivityChoicesOptions}
              name={getFieldName("activity")}
              value={formValue[getFieldName("activity")]}
              onChange={onChange}
              label={t("table.schedule.type")}
              required
            />
          </Grid>

          {/* days  */}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <DateField
                  name={getFieldName("date_start")}
                  label={t("table.schedule.day_start")}
                  value={DateService.convertDateToFormatYYYYMMDD(
                    formValue[getFieldName("date_start")]
                  )}
                  onChange={onChangeDateStart}
                  required
                  minDate={dateStartMinDate}
                  maxDate={dateStartMaxDate}
                  readOnly={!isCreateMassDialogMode}
                  showClearIcon={false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateField
                  name={getFieldName("date_stop")}
                  label={t("table.schedule.day_stop")}
                  value={DateService.convertDateToFormatYYYYMMDD(
                    formValue[getFieldName("date_stop")]
                  )}
                  onChange={onChangeDate}
                  required
                  minDate={dateStopMinDate}
                  maxDate={dateStopMaxDate}
                  readOnly={!isCreateMassDialogMode}
                  showClearIcon={false}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* hours  */}
          {formValue[getFieldName("activity")] === RCP_ACTIVITIES.WORK && (
            <>
              <Grid item xs={12} sm={4}>
                <SelectField
                  options={TIME_OPTIONS_HOURS}
                  value={formValue.hour_start}
                  isObjectOption={false}
                  onChange={onChange}
                  label={t("table.schedule.time_start")}
                  name="hour_start"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectField
                  options={TIME_OPTIONS_HOURS}
                  value={formValue.hour_stop}
                  isObjectOption={false}
                  onChange={onChange}
                  label={t("table.schedule.time_end")}
                  name="hour_stop"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectField
                  options={rcpShiftChoicesOptions}
                  name={getFieldName("shift")}
                  value={formValue[getFieldName("shift")]}
                  onChange={onChange}
                  label={t("table.schedule.shift")}
                  required
                />
              </Grid>
            </>
          )}
          {/*hours per day */}
          {showAmountHoursPerDayField && (
            <Grid item xs={12}>
              <TextFieldFormControl
                label={t("table.schedule.amount_hours_per_day")}
                name={getFieldName("hours")}
                value={formValue[getFieldName("hours")]}
                type={"number"}
                onChange={onChange}
                min={0}
              />
            </Grid>
          )}
          {isCreateMassDialogMode && (
            <Grid item xs={12} sm={12}>
              <TrueFalseSelectField
                value={formValue.exclude_free_days}
                valuesAsBool
                onChange={onChange}
                label={t("table.schedule.exclude_free_days")}
                name={"exclude_free_days"}
                addEmptyOptions={false}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <LoadingButton
              size="small"
              loading={submitFn.loading}
              variant="contained"
              fullWidth
              disabled={isSubmitButtonDisabled}
              onClick={onSubmit}
            >
              {getSubmitButtonLabel()}
            </LoadingButton>
          </Grid>
        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
};

export default CreateScheduleDialog;
