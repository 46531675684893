import { useState, useContext, createContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useDialog from "../hooks/useDialog";
import UnsavedDataDialog from "../components/dialog/UnsavedDataDialog";

export const DataSavedContext = createContext({});

export const DataSavedProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isDataSaved, setIsDataSaved] = useState(true);
  const [redirectLink, setRedirectLink] = useState();

  useEffect(() => {
    setIsDataSaved(true);
    setRedirectLink();
  }, [location.pathname]);

  const [
    openUnsavedDataDialog,
    handleOpenUnsavedDataDialog,
    handleCloseUnsavedDataDialog,
  ] = useDialog();

  const handleOpenUnsavedDataDialogWithLoacationCheck = (link) => {
    if (link === location.pathname) return;
    handleOpenUnsavedDataDialog();
    setRedirectLink(link);
  };

  const handleConfirmRedirect = () => {
    navigate(redirectLink);
    handleCloseUnsavedDataDialog();
  };

  const contextData = {
    isDataSaved,
    setIsDataSaved,
    handleOpenUnsavedDataDialog: handleOpenUnsavedDataDialogWithLoacationCheck,
  };

  return (
    <DataSavedContext.Provider value={contextData}>
      {children}
      {openUnsavedDataDialog && (
        <UnsavedDataDialog
          open={openUnsavedDataDialog}
          onYes={handleConfirmRedirect}
          onNo={handleCloseUnsavedDataDialog}
        />
      )}
    </DataSavedContext.Provider>
  );
};

export const useDataSaved = () => {
  return useContext(DataSavedContext);
};
