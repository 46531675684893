import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";

const WarehouseDetailsForm = (props) => {
  const { t } = useTranslation();



  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={6}>
        <TextFieldFormControl
          name="warehouse_name"
          readOnly={props.readOnly}
          required
          value={props.warehouseData?.warehouse_name}
          label={t("form.warehouse_details_form.warehouse_name")}
          onChange={props.onChange}
          isInvalid={props.unfillRequiredFields.includes("warehouse_name")}
          helperText={
            props.unfillRequiredFields.includes("warehouse_name") &&
            t("field_required")
          }
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteField
          name={"warehouse_parent"}
          label={t("form.warehouse_details_form.warehouse_parent")}
          value={props.warehouseData?.warehouse_parent}
          options={props.filteringData?.value?.warehouses}
          isObjectOption={true}
          optionLabelKey={"warehouse_name"}
          onChange={props.onChangeAutocomplete}
          addNewValue={false}
        />
      </Grid>


      <Grid item xs={6}>
        <AutocompleteField
          name={"owner"}
          label={t("form.warehouse_details_form.owner")}
          value={props.warehouseData?.owner}
          options={props.filteringData?.value?.owners}
          isObjectOption={true}
          optionLabelKey={"owner_short_name"}
          onChange={props.onChangeAutocomplete}
          addNewValue={false}
          required
          error={props.unfillRequiredFields.includes("owner")}
          helperText={ 
            props.unfillRequiredFields.includes("owner") &&
            t("field_required")
          }
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteField
          label={t("form.warehouse_details_form.warehouse_responsible")}
          name="warehouse_responsible"
          value={props.warehouseData?.warehouse_responsible}
          options={props.filteringData?.value?.users}
          isObjectOption={true}
          optionLabelKey={"full_name"}
          onChange={props.onChangeAutocomplete}
          readOnly={props.readOnly}
          required
          error={props.unfillRequiredFields.includes("warehouse_responsible")}
          helperText={ 
            props.unfillRequiredFields.includes("warehouse_responsible") &&
            t("field_required")
          }
        />
      </Grid>

      <Grid item xs={6}>
        {props.warehouseId && !props.readOnly &&
          <TrueFalseSelectField
            name="warehouse_active"
            valuesAsBool
            value={props.warehouseData?.warehouse_active}
            label={t("form.warehouse_details_form.warehouse_active")}
            onChange={props.onChange}
            addEmptyOptions={false}
          />}
      </Grid>

      <Grid item xs={12}>
        <TextFieldFormControl
          name="warehouse_scope"
          multiline
          rows={4}
          readOnly={props.readOnly}
          value={props.warehouseData?.warehouse_scope}
          label={t("form.warehouse_details_form.warehouse_scope")}
          onChange={props.onChange}
          resize={"vertical"}
        />
      </Grid>


    </Grid>
  );
};

WarehouseDetailsForm.propTypes = {
  warehouseData: PropTypes.object,
  readOnly: PropTypes.bool,
  filteringData: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAutocomplete: PropTypes.func
};

WarehouseDetailsForm.defaultProps = {
  readOnly: false,
  filteringData: { value: [] },
};

export default WarehouseDetailsForm
