import { useRef, useCallback } from "react";

import PropTypes from "prop-types";
import i18n from "../../../i18n";

import BaseTable from "../../base/BaseTable/BaseTable";
import SelectFieldService from "../../../services/selectFieldService";
import TableService from "../../../services/tableService";

import WarningAlertIcon from "../../other/WarningAlertIcon/WarningAlertIcon";
import PrefixSuffixFieldTableWrapper from "../../wrapper/PrefixSuffixFieldTableWrapper";

import useDialog from "../../../hooks/useDialog";
import MultipleLocalizationDialog from "../../dialog/MutlipleLocalizationDialog";

import { AUTOCOMPLETE_FIELD_TYPE } from "../../../helpers/constants";

export const ORDER_TABLE_NAME = "order";

export const ORDER_TABLE_FIELDS_CONFIG = [
  {
    name: "order_nr",
    getValueCallback: (rowData) => rowData.order_nr,
    label: i18n.t("table.order.order_nr"),
    filterType: undefined,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
  },
  {
    name: "order_name",
    getValueCallback: (rowData) => rowData.order_name,
    label: i18n.t("table.order.order_name"),
    filterType: undefined,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
  },
  {
    name: "order_desc_creator",
    getValueCallback: (rowData) => rowData.order_desc_creator,
    label: i18n.t("table.order.order_desc_creator"),
    filterType: undefined,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "status",
    getValueCallback: (rowData) => rowData.status,
    label: i18n.t("table.order.status"),
    filterType: undefined,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "order_priority",
    getValueCallback: (rowData) => rowData.order_priority,
    label: i18n.t("table.order.order_priority"),
    filterType: undefined,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "objects_level_1",
    getValueCallback: (rowData, onClickMultipleLocationAlert) => {
      return rowData.is_multiple_locations ? (
        <PrefixSuffixFieldTableWrapper
          prefix={
            <WarningAlertIcon
              style={{ cursor: "pointer" }}
              onClick={(e) => onClickMultipleLocationAlert(e, rowData.id)}
            />
          }
        >
          {rowData.locations[0].level_1}
        </PrefixSuffixFieldTableWrapper>
      ) : (
        rowData.locations[0].level_1
      );
    },
    label: i18n.t("table.ticket.object_level_1"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_2",
    getValueCallback: (rowData) => rowData.locations[0].level_2,
    label: i18n.t("table.ticket.object_level_2"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_3",
    getValueCallback: (rowData) => rowData.locations[0].level_3,
    label: i18n.t("table.ticket.object_level_3"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_4",
    getValueCallback: (rowData) => rowData.locations[0].level_4,
    label: i18n.t("table.ticket.object_level_4"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_5",
    getValueCallback: (rowData) => rowData.locations[0].level_5,
    label: i18n.t("table.ticket.object_level_5"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "order_kt",
    getValueCallback: (rowData) => rowData?.order_kt?.full_name,
    label: i18n.t("table.order.order_kt"),
    filterType: undefined,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "skill",
    getValueCallback: (rowData) => rowData?.skill,
    label: i18n.t("table.order.skill"),
    filterType: undefined,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "order_kt_need",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.order_kt_need),
    label: i18n.t("table.order.order_kt_need"),
    filterType: undefined,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];

const OrderTable = (props) => {
  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();

  const clickedOrderMultipleLocalization = useRef();
  const handleOpenMultipleLocalizationDialog = useCallback(
    (e, orderId) => {
      e.stopPropagation();
      clickedOrderMultipleLocalization.current = props.orderData.find(
        (order) => order.id === orderId
      ).locations;
      onOpenMultipleLocalizationDialog();
    },
    [onOpenMultipleLocalizationDialog, props.orderData]
  );

  const handleCloseMultipleLocalizationDialog = () => {
    clickedOrderMultipleLocalization.current = null;
    onCloseMultipleLocalizationDialog();
  };

  return (
    <>
      <BaseTable
        headers={TableService.getHeaderLables(
          ORDER_TABLE_FIELDS_CONFIG,
          props.hiddenColumns,
          props.columnsOrders
        )}
        showNoRecordsPaper={props.showNoRecordsPaper}
        style={props.style}
        rows={TableService.getPreparedDataForBaseTable(
          ORDER_TABLE_FIELDS_CONFIG,
          props.orderData,
          props.hiddenColumns,
          props.columnsOrders,
          handleOpenMultipleLocalizationDialog
        )}
      />
      {openMultipleLocalizationDialog &&
        clickedOrderMultipleLocalization.current && (
          <MultipleLocalizationDialog
            open={openMultipleLocalizationDialog}
            onClose={handleCloseMultipleLocalizationDialog}
            localizationData={clickedOrderMultipleLocalization.current}
            itemType={"order"}
          />
        )}
    </>
  );
};

OrderTable.propTypes = {
  orderData: PropTypes.object,
  style: PropTypes.object,
  hiddenColumns: PropTypes.array,
  columnsOrders: PropTypes.array,
  showNoRecordsPaper: PropTypes.bool,
};

OrderTable.defaultProps = {
  hiddenColumns: [],
  showNoRecordsPaper: true,
};

export default OrderTable;
