import DunReportsNavigationBar from "../../components/bar/DunReportsNavigationBar";
import DunReportsNavigationBuidlingSubBar from "../../components/bar/DunReportsNavigationBar/DunReportsNavigationBuildingSubBar";

import PropTypes from "prop-types";

import TicketsReportBasePage from "../TicketsReportBasePage";

export default function TicketsPerLocationAndDayReportPage(props) {
  return (
    <TicketsReportBasePage
      pageName={props.pageName}
      pageTitleKey={"tickets_open_close"}
      groupBy={"open_close"}
      labelBy={"location"}
      showSelectTimeRange={false}
      showOnlySelectDay={false}
      showTicketOwner={false}
      chartHeight={"40vh"}
      chartHeightInFullScreen={"67vh"}
      showSelectObjectLevel2={true}
      NavigationBar={
        <>
          <DunReportsNavigationBar
            multipleLocationKey={"/tickets_per_location_day_report/"}
          />
          <DunReportsNavigationBuidlingSubBar />
        </>
      }
      showNavigationBar={true}
      showOpenCloseFilterButton={true}
      showTenantVisibilityButton={true}
      showSelectCategoryButton={false}
    />
  );
}

TicketsPerLocationAndDayReportPage.propTypes = {};
