import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import AutocompleteField from "../../../../components/field/AutocompleteField";

export default function LocationSearchRecords(props) {
  const { t } = useTranslation();

  return (
    <Grid container item columnSpacing={1}>
      {Array.from(
        { length: props.locationSearchMaxLevel },
        (x, i) => i + 1
      ).map((level) => (
        <Grid item xs={12} md={12 / props.locationSearchMaxLevel} key={level}>
          <AutocompleteField
            multiple={true}
            isObjectOption={false}
            name={`objects_level_${level}`}
            value={props.formValue[`objects_level_${level}`]}
            options={props.filteringData[`objects_level_${level}`]}
            label={t(`bar.rr_admin_search_bar.level_${level}`)}
            onChange={props.onChange}
          />
        </Grid>
      ))}
    </Grid>
  );
}

LocationSearchRecords.propTypes = {
  locationSearchMaxLevel: PropTypes.number,
  formValue: PropTypes.object,
  filteringData: PropTypes.object,
  onChange: PropTypes.func,
};

LocationSearchRecords.defaultProps = {
  locationSearchMaxLevel: 5,
};
