import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../context/snackbarAlert";

import { useAsync, useAsyncFn } from "./useAsync";

import useDialog from "./useDialog";
import useUserPreferencesService from "../services/userPreferencesService";

import TableService from "../services/tableService";

function useUserTablePreferences({
  pageName,
  refreshTable,
  tablesConfigs,
  setHiddenColumnsForTables,
  columnsOrdersForTables,
  setColumnsOrdersForTables,
} = {}) {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  useEffect(() => {
    if (userPreferencesForPage.loading) {
      setHiddenColumnsForTables(undefined);
      setColumnsOrdersForTables(undefined);
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [...tablesConfigs.map((tbConfing) => tbConfing.name)],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [...tablesConfigs.map((tbConfing) => tbConfing.name)],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        // refreshTable();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  return {
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
    handleUpdateUserPreferences,
    loadingUserPreferences: userPreferencesForPage.loading,
    tablesConfigs: TableService.getTableConfigsForTableColumnVisibilityDialog(
      tablesConfigs,
      columnsOrdersForTables
    ),
  };
}

export default useUserTablePreferences;
